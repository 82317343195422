import {
  addvacation,
  deletevacation,
  getallvacationlist,
  getemployeevacationbalancepage,
  getemployeevacationchangelist,
  getemployeevacationdetaillist,
  setvacationstatus,
  updateempvacationremaindays,
  updatevacation,
  importemployeevacationdata,
  // 考勤列表
  getemployeeattendancepage,
  updateemployeeattendancedata,
  asyncemployeeclockdata,
  getmyattendancepage,
  getattendanceapplytypelist,
  lunchattendanceapply,
  hrupdateattendanceapplyauditpass,
  auditattendanceprocess,
  commitattendanceprovematerial,
  getattendanceprocesstree,
  auditattendancerevoke,
  deleteattendanceapply,
  hrrevokeattendanceapplyauditpass,
  getallattendancepage,
} from '@/_serveice/attendance/index';
import { getemployeeoutclockpage } from '../../_serveice/attendance';

export default {
  namespace: 'attendance',

  state: {
    allVacationList: [],
    vacationChangeList: [],
    vacationEditData: {},
    holidayDetail: {},

    vacationbalancePage: {},
    vacationbalanceTable: {},
    vacationbalanceSearchForm: {},
    allList: {},
    allListSearchForm: {},

    vacationDetailList: [],
    // 考勤列表
    attendanceList: {},
    attendanceListSearchForm: {},
    //我的考勤申请列表
    myattendanceApplyList: [],
    myattendanceApplyListSearchForm: {},
    applyTypeList: [],
    processList: {},
    outClockLick: {},
    outClockLickSearchForm: {},
  },

  effects: {
    // 页面列表--------------------------
    *getallvacationlist({ payload, reset }, { put, call, select }) {
      const res = yield call(getallvacationlist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            allVacationList: res.data,
          },
        });
      }
    },
    *updatevacation({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? updatevacation : addvacation, payload);
      if (res?.success) {
        yield put({
          type: 'getallvacationlist',
        });
        return true;
      }
      return false;
    },
    *deletevacation({ payload }, { put, call }) {
      const res = yield call(deletevacation, payload);
      if (res?.success) {
        yield put({
          type: 'getallvacationlist',
        });
        return true;
      }
    },
    *setvacationstatus({ payload }, { put, call }) {
      const res = yield call(setvacationstatus, payload);
      if (res?.success) {
        yield put({
          type: 'getallvacationlist',
        });
        return true;
      }
    },
    *getemployeevacationbalancepage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ attendance }) => attendance.vacationbalanceSearchForm,
      );
      let vacationbalanceSearchForm = {
        ...searchData,
        ...payload,
        deptId: payload?.deptId ?? searchData.deptId ?? 1,
      };
      if (reset) {
        vacationbalanceSearchForm = payload;
      }
      vacationbalanceSearchForm.deptId ??= 1;
      const res = yield call(
        getemployeevacationbalancepage,
        vacationbalanceSearchForm,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            vacationbalancePage: res.data?.pagination,
            vacationbalanceTable: res.data?.vacationList,
            vacationbalanceSearchForm,
          },
        });
      }
    },
    *updateempvacationremaindays({ payload }, { put, call }) {
      const res = yield call(updateempvacationremaindays, payload);
      if (res?.success) {
        yield put({
          type: 'getemployeevacationdetaillist',
          payload: {
            empId: payload.empId,
          },
        });
        return true;
      }
    },
    *getemployeevacationchangelist({ payload, reset }, { put, call, select }) {
      const res = yield call(getemployeevacationchangelist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            vacationChangeList: res.data,
          },
        });
        return res.data;
      }
    },
    *getemployeevacationdetaillist({ payload, reset }, { put, call, select }) {
      const res = yield call(getemployeevacationdetaillist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            vacationDetailList: res.data,
          },
        });
        return res.data;
      }
    },

    // 考勤列表
    *getemployeeattendancepage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ attendance }) => attendance.attendanceListSearchForm,
      );
      let attendanceListSearchForm = { ...searchData, ...payload };
      if (reset) {
        attendanceListSearchForm = payload;
      }
      const res = yield call(
        getemployeeattendancepage,
        attendanceListSearchForm,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            attendanceList: res.data,
            attendanceListSearchForm,
          },
        });
      }
    },
    *updateemployeeattendancedata({ payload }, { put, call }) {
      const res = yield call(updateemployeeattendancedata, payload);
      if (res?.success) {
        yield put({
          type: 'getemployeeattendancepage',
        });
        return true;
      }
    },
    *asyncemployeeclockdata({ payload }, { put, call }) {
      const res = yield call(asyncemployeeclockdata, payload);
      if (res?.success) {
        // yield put({
        //   type: 'getemployeeattendancepage',
        // });
        return true;
      }
    },
    //分页查询我的考勤列表
    *getmyattendancepage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ attendance }) => attendance.myattendanceApplyListSearchForm,
      );
      let myattendanceApplyListSearchForm = { ...searchData, ...payload };
      if (reset) {
        myattendanceApplyListSearchForm = payload;
      }
      const res = yield call(
        getmyattendancepage,
        myattendanceApplyListSearchForm,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            myattendanceApplyList: res.data,
            myattendanceApplyListSearchForm,
          },
        });
      }
    },
    *getattendanceapplytypelist({ payload, reset }, { put, call, select }) {
      const res = yield call(getattendanceapplytypelist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            applyTypeList: res.data,
          },
        });
        return res.data;
      }
    },
    *lunchattendanceapply({ payload }, { put, call }) {
      const res = yield call(lunchattendanceapply, payload);
      if (res?.success) {
        // yield put({
        //   type: 'getmyattendancepage',
        // });
        return true;
      }
    },
    *hrupdateattendanceapplyauditpass({ payload }, { put, call }) {
      const res = yield call(hrupdateattendanceapplyauditpass, payload);
      if (res?.success) {
        return true;
      }
    },
    *auditattendanceprocess({ payload }, { put, call }) {
      const res = yield call(auditattendanceprocess, payload);
      if (res?.success) {
        return true;
      }
    },
    *commitattendanceprovematerial({ payload }, { put, call }) {
      const res = yield call(commitattendanceprovematerial, payload);
      if (res?.success) {
        return true;
      }
    },
    *getattendanceprocesstree({ payload }, { put, call, select }) {
      const processList = yield select(
        ({ attendance }) => attendance.processList,
      );
      const res = yield call(getattendanceprocesstree, payload);
      if (res?.success) {
        payload.processApplyId &&
          (processList[payload.processApplyId] = res.data);
        yield put({
          type: 'save',
          payload: {
            processList,
          },
        });
        return res.data;
      }
    },
    *deleteattendanceapply({ payload, loader = true }, { put, call }) {
      const res = yield call(deleteattendanceapply, payload);
      if (res?.success && loader) {
        yield put({
          type: 'getmyattendancepage',
        });
        return true;
      }
    },
    *hrrevokeattendanceapplyauditpass({ payload }, { put, call }) {
      const res = yield call(hrrevokeattendanceapplyauditpass, payload);
      if (res?.success) {
        yield put({
          type: 'getallattendancepage',
        });
        return true;
      }
    },
    *auditattendancerevoke({ payload }, { put, call }) {
      const res = yield call(auditattendancerevoke, payload);
      if (res?.success) {
        yield put({
          type: 'usercenter/getcompleteitemnewpage',
        });
        return true;
      }
    },
    *getallattendancepage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ attendance }) => attendance.allListSearchForm,
      );
      let allListSearchForm = {
        ...searchData,
        ...payload,
        deptId: payload?.deptId ?? searchData.deptId,
      };
      if (reset) {
        allListSearchForm = payload;
      }
      const res = yield call(getallattendancepage, allListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            allList: res.data,
            allListSearchForm,
          },
        });
      }
    },
    *getemployeeoutclockpage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ attendance }) => attendance.outClockLickSearchForm,
      );
      let outClockLickSearchForm = {
        ...searchData,
        ...payload,
        deptId: payload?.deptId ?? searchData.deptId,
      };
      if (reset) {
        outClockLickSearchForm = payload;
      }
      const res = yield call(getemployeeoutclockpage, outClockLickSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            outClockLick: res.data,
            outClockLickSearchForm,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
