import { MessageBox, Button, Table, Row, Col, Tag, Alert } from 'kenshin';
import TablePro from '@/components/TablePro';
import { useEffect, useRef, useState } from 'react';
import { flat } from '@/_util/util';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportemposaindexdata } from '@/_serveice/performance/osa';
import styles from './styles.less';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

/** 处理指标的数据项 */
function dataSouceChildOptions(childList, kpi) {
  let child =
    childList?.map?.((item) => {
      item.kpi = kpi;
      if (item.childList) {
        let childList = dataSouceChildOptions(item.childList, kpi);
        return [{ ...item, childList: undefined }, ...childList];
      }
      return [{ ...item, childList: undefined }];
    }) || [];
  return flat(child, 2);
}

/** 处理第一次dataSource */
function dataSouceOptions(dataSource) {
  if (Array.isArray(dataSource)) {
    let newDataSource = dataSource.map((item) => {
      item.kpi = `${item.code}：${
        item.osaIndexCatgName ?? item.osaIndexItemName
      }`;
      if (item.childList) {
        item.childList = dataSouceChildOptions(
          item.childList,
          `${item.code}：${item.osaIndexCatgName}`,
        );
      }
      return item;
    });
    return flat(
      newDataSource.map((item) => item.childList || [item]),
      2,
    );
  }
  return [];
}

function filterDuplicateNames(array, value) {
  let acc = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i].kpi !== value) break;
    acc.push(array[i]);
  }
  return acc;
}

/** 计算总分 */
const getCountScore = (arr, itemKey, dirKey) => {
  return arr.reduce((pre, cur) => pre + (cur[dirKey] ?? cur[itemKey]), 0);
};

/** 根据当前节点获取需要判断的总分 */
const nodeTokey = {
  SCORE_ONE_NODE: 'selfCountScore',
  SCORE_WEEK_PLAN_ONE_NODE: 'directCountScore',
  SCORE_TWO_NODE: 'directCountScore',
  SCORE_THREE_NODE: 'busiCountScore',
};

const PreviewScore = ({
  visible,
  onCancel,
  onSave,
  dataSource = [],
  data = {},
}) => {
  const [list, setList] = useState();
  const showAlert = useRef(false);

  useEffect(() => {
    if (visible) {
      let weight = getCountScore(dataSource, 'weight', 'weight');
      let selfCountScore = getCountScore(
        dataSource,
        'osaIndexItemSelfScore',
        'osaIndexCatgSelfScore',
      );
      let directCountScore = getCountScore(
        dataSource,
        'osaIndexItemDirectLeaderScore',
        'osaIndexCatgDirectLeaderScore',
      );
      let busiCountScore = getCountScore(
        dataSource,
        'osaIndexItemBusiLeaderScore',
        'osaIndexCatgBusiLeaderScore',
      );
      if (
        { selfCountScore, directCountScore, busiCountScore }[
          nodeTokey[data.curnNodeNumber]
        ] < 80
      ) {
        showAlert.current = true;
      } else {
        showAlert.current = false;
      }
      let countItem = {
        id: 'count',
        osaIndexItemName: '总分',
        weight,
        osaIndexCatgSelfScore: selfCountScore,
        osaIndexCatgDirectLeaderScore: directCountScore,
        osaIndexCatgBusiLeaderScore: busiCountScore,
      };
      setList([...dataSource, countItem]);
    }
  }, [visible]);

  const hasShowScoreNode = [
    'WEEK_PLAN_END_NODE',
    'OSA_END_NODE',
    'HR_CONFIRM_WEEK_PLAN_NODE',
    'HR_CONFIRM_NODE',
  ];

  const createScoreRender = (key) => {
    return (value, row) => {
      let score = value ?? row[key];
      let scoreDiff = score - row.weight;
      let scoreEl = (
        <div
          className={['score-diff', scoreDiff < 0 ? 'error' : 'success'].join(
            ' ',
          )}
        >
          {scoreDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
          {+Math.abs(scoreDiff).toFixed(2)} 分
        </div>
      );
      if (isNaN(score)) return '-';
      return (
        <>
          <span className="old-score">{score} 分</span> {scoreEl}
        </>
      );
    };
  };

  const columns = [
    {
      dataIndex: 'kpi',
      title: '关键绩效目标（KPI）',
      fixed: 'left',
      width: 200,
      render(value, row, index) {
        let code = [row.code, ' '].filter(Boolean).join('.');
        return `${code}${row.osaIndexCatgName ?? row.osaIndexItemName}`;
      },
    },
    {
      dataIndex: 'weight',
      title: '权重(%)',
      width: 100,
      render: (val) => `${val}%`,
    },
    data?.osaWeekType == 3
      ? {
          dataIndex: 'osaIndexItemSelfScore',
          title: '自评分',
          width: 80,
          render: createScoreRender('osaIndexCatgSelfScore'),
        }
      : null,
    {
      dataIndex: 'osaIndexItemDirectLeaderScore',
      title: '上级评分',
      width: 100,
      render: createScoreRender('osaIndexCatgDirectLeaderScore'),
    },
    data?.osaWeekType == 3
      ? {
          dataIndex: 'osaIndexItemBusiLeaderScore',
          title: '业务负责人评分',
          width: 140,
          render: createScoreRender('osaIndexCatgBusiLeaderScore'),
        }
      : null,
  ].filter(Boolean);

  const header = (
    <div className="p-s-header">
      <Row>
        <Col span={5}>
          <div>
            <span>员工姓名：</span>
            {data.userInfo?.value}
            <div className="p-s-tag success">被考核人</div>
          </div>
          <div>
            <span>所属部门：</span>
            {data.osaMuDept}
          </div>
          <div>
            <span>直接主管：</span>
            {data.directLeader?.value}
            <div className="p-s-tag primary">考核人</div>
          </div>
        </Col>
        <Col span={5}>
          <div>
            <span>职位名称：</span>
            {data.userInfo?.extendData}
          </div>
          <div>
            <span>业务负责人：</span>
            {data.busiLeader?.value}
          </div>
          <div>
            <span>考核类型：</span>
            {
              {
                1: '试用期转正评估',
                2: '绩效评估',
                3: '绩效改进评估',
                4: '个人发展计划',
              }[data.osaType]
            }
          </div>
        </Col>
        <Col span={10}>
          <span>评估周期：</span>
          {moment(data.osaBeginTime).format('YYYY年MM月DD日')} 至
          {moment(data.osaEndTime).format('YYYY年MM月DD日')}
        </Col>
      </Row>
    </div>
  );
  return (
    <MessageBox
      width="95%"
      title="审核确认"
      destroyOnClose
      style={{ top: 20 }}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
        <Button key="ok" type="primary" onClick={onSave}>
          确认
        </Button>,
      ]}
    >
      <div className={styles.PreviewScore}>
        {header}
        <TablePro
          rowKey={'id'}
          bordered
          pagination={false}
          columns={columns}
          curColumns
          columnsOptions={false}
          scrollX
          childrenColumnName="childList"
          dataSource={list}
        />
        {showAlert.current && (
          <Alert
            title="当前总分少于80分，请仔细确认一下分数"
            type="warning"
            showIcon
            style={{ marginTop: 20 }}
          />
        )}
      </div>
    </MessageBox>
  );
};

export default PreviewScore;
