import request from '@/_util/require';

let version = 'v1';
// 客户分析
export async function getCustomerCharts(options) {
  return request(
    `/${version}/waper/erp/pc/customer/statistics/customeraanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export async function getCustomerListById(options) {
  return request(
    `/${version}/waper/erp/pc/customer/statistics/customeranalysisstatproject`,
    {
      method: 'POST',
      data: options,
    },
  );
}
export async function getCustomerList(options) {
  return request(
    `/${version}/waper/erp/pc/customer/statistics/customeranalystatpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 项目分析
// export async function getProjectCharts(options) {
//   return request(
//     `/${version}/waper/erp/pc/project/statistics/projectanalysisstatlist`,
//     {
//       method: 'POST',
//       data: options,
//     },
//   );
// }

// export async function getProjectListById(options) {
//   return request(
//     `/${version}/waper/erp/pc/project/statistics/projectanalysisstatworkpackage`,
//     {
//       method: 'POST',
//       data: options,
//     },
//   );
// }
// export async function getProjectList(options) {
//   return request(
//     `/${version}/waper/erp/pc/project/statistics/projectanalysisstatpage`,
//     {
//       method: 'POST',
//       data: options,
//     },
//   );
// }

// 收款分析
export async function getCollectCharts(options) {
  return request(
    `/${version}/waper/erp/pc/contcollect/statistics/contcollectanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export async function getCollectListById(options) {
  return request(
    `/${version}/waper/erp/pc/contcollect/statistics/contcollectprojectanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}
export async function getCollectList(options) {
  return request(
    `/${version}/waper/erp/pc/contcollect/statistics/contcollectanalysisstatpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 成本分析 --项目
export async function getCostProjectCharts(options) {
  return request(
    `/${version}/waper/erp/pc/project/statistics/projectanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export async function getCostProjectList(options) {
  return request(
    `/${version}/waper/erp/pc/project/statistics/projectanalysisstatpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
export async function getCostProjectListById(options) {
  return request(
    `/${version}/waper/erp/pc/project/statistics/projectanalysisstatworkpackage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 成本分析 --部门
export async function getCostDepCharts(options) {
  return request(
    `/${version}/waper/erp/pc/dept/statistics/deptanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export async function getCostDepList(options) {
  return request(
    `/${version}/waper/erp/pc/dept/statistics/deptanalysisstatpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
export async function getCostDepListById(options) {
  return request(
    `/${version}/waper/erp/pc/dept/statistics/deptemployeeanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 成本分析 --员工
export async function getCostEmpCharts(options) {
  return request(
    `/${version}/waper/erp/pc/employee/statistics/empanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export async function getCostEmpList(options) {
  return request(
    `/${version}/waper/erp/pc/employee/statistics/empanalysisstatpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
export async function getCostEmpListById(options) {
  return request(
    `/${version}/waper/erp/pc/employee/statistics/empprojectanalysisstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 客户分析导出
export const downloadForCustomer = `/${version}/waper/erp/pc/customer/statistics/exportcuststatlist`;
// 收款分析导出
export const downloadForCollect = `/${version}/waper/erp/pc/contcollect/statistics/exportcontcollectstatlist`;
// 员工分析导出
export const downloadForEmp = `/${version}/waper/erp/pc/employee/statistics/exportempstatlist`;
// 部门分析导出
export const downloadForDept = `/${version}/waper/erp/pc/dept/statistics/exportdeptstatlist`;
// 项目分析导出
export const downloadForProject = `/${version}/waper/erp/pc/project/statistics/exportprojectstatlist`;

/** 查询员工上报明细统计列表 */
export async function getEmpRptDetailStat(options) {
  return request(
    `/${version}/waper/erp/pc/employee/rptstat/getemprptdetailstat`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询员工上报统计图表 */
export async function getEmpRptStat(options) {
  return request(`/${version}/waper/erp/pc/employee/rptstat/getemprptstat`, {
    method: 'POST',
    data: options,
  });
}

// 分页查询员工上报明细统计列表
export async function getEmpRptDetailStatPage(options) {
  return request(
    `/${version}/waper/erp/pc/employee/rptstat/getemprptdetailstatpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 查询所有员工上报统计图表 */
export async function getAllEmpRptStat(options) {
  return request(`/${version}/waper/erp/pc/employee/rptstat/getallemprptstat`, {
    method: 'POST',
    data: options,
  });
}

// 查询所有员工上报明细统计列表
export async function getAllEmpRptDetailStat(options) {
  return request(
    `/${version}/waper/erp/pc/employee/rptstat/getallemprptdetailstat`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 分页查询员工上报明细统计列表
export async function getEmpRptDetailList(options) {
  return request(
    `/${version}/waper/erp/pc/employee/rptstat/getemprptdetaillist`,
    {
      method: 'GET',
      params: options,
    },
  );
}

/** 查询部门上报明细统计列表 */
export async function getDepRptDetailStat(options) {
  return request(
    `/${version}/waper/erp/pc/dept/rptstat/getdeptrptdetailstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询部门上报统计图表 */
export async function getDepRptStat(options) {
  return request(`/${version}/waper/erp/pc/dept/rptstat/getdeptrptstat`, {
    method: 'POST',
    data: options,
  });
}

/** 查询所有部门上报明细统计列表 */
export async function getAllDepRptDetailStat(options) {
  return request(
    `/${version}/waper/erp/pc/dept/rptstat/getalldeptrptdetailstatlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询所有部门上报统计图表 */
export async function getAllDepRptStat(options) {
  return request(`/${version}/waper/erp/pc/dept/rptstat/getalldeptrptstat`, {
    method: 'POST',
    data: options,
  });
}

/** 查询季度绩效整体统计数据 */
export async function queryosaquaterallstatdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/statistics/queryosaquaterallstatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询季度绩效部门统计数据 */
export async function queryosaquaterdeptstatdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/statistics/queryosaquaterdeptstatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询季度绩效员工统计数据 */
export async function queryosaquaterempstatdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/statistics/queryosaquaterempstatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询周计划绩效整体统计数据 */
export async function queryosaweekplanallstatdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/statistics/queryosaweekplanallstatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询周计划绩效部门统计数据 */
export async function queryosaweekplandeptstatdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/statistics/queryosaweekplandeptstatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询周计划绩效员工统计数据 */
export async function queryosaweekplanempstatdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/statistics/queryosaweekplanempstatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询员工首页考勤统计信息 */
export async function getempattendancestatindexdata(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/statistics/getempattendancestatindexdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询公司核心部门列表 */
export async function getcompanymaindeptlist(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/statistics/getcompanymaindeptlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询考勤统计数据 */
export async function findattendancestatdata(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/statistics/findattendancestatdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export const exportosaquarterstatdata = `/${version}/waper/erp/pc/osa/statistics/exportosaquarterstatdata`;

/** 导出考勤统计数据 */
export const exportattendancestatdata = `/${version}/waper/erp/pc/attendance/statistics/exportattendancestatdata`;
