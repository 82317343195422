import React, { useEffect } from 'react';
import {
  ContentState,
  EditorState,
  Modifier,
  AtomicBlockUtils,
} from 'draft-js';
import { Editor as DraftEdit } from 'react-draft-wysiwyg';
// 样式文件
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// 转化为 html（根据自己的需求添加）
import draftjs from 'draftjs-to-html';
import { useReactive } from 'ahooks';
import { request } from 'umi';
import { getOSSMsg } from '@/_serveice/user';
import { OSSImgUrl } from '@/_util/util';
import htmlToDraft from 'html-to-draftjs';
import VideoItem from './VideoItem';

let aaa =
  '<p></p>\n<img src="https://oss.waperidea.cn/A9/waper-erpcustomer/caselist/2023/05/09/00026-481193937.png" alt="undefined" style="height: 100px;width: 100px"/>\n<p>😺</p>\n';
let ossRootDirectoryBase = ossDirUrl;

const Editor = ({ dirUrl = 'customer/caselist', value, onChange }) => {
  useEffect(() => {
    if (value) {
      console.log('value', value);
      let newVal = `<div>${value}</div>`;
      state.editorContent = newVal;
      let contentBlock = htmlToDraft(newVal, (nodeName, node) => {
        if (nodeName == 'video') {
          return {
            type: 'VIDEO',
            mutability: 'MUTABLE',
            data: { src: node.src },
          };
        }
      });
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        state.editorState = editorState;
      }
    }
  }, []);

  const state = useReactive({
    editorContent: '',
    editorState: '',
  });

  const onEditorChange = (value) => {
    state.editorContent = value;
    let htmlStr = draftjs(
      value,
      { trigger: '#', separator: ' ' },
      false,
      (obj) => {
        if (obj.type == 'VIDEO') {
          // 处理视频
          return `<video src="${obj.data.src}" controls style={{ width: '100%' }} ></video>`;
        }
      },
    );
    onChange?.(htmlStr);
  };
  const onEditorStateChange = (value) => {
    state.editorState = value;
  };

  const uploadCallback = async (file) => {
    let name = file.name;
    const ossInfo = await getOSSMsg().then((res) => res.data);
    let fileUrl = `${ossRootDirectoryBase}${dirUrl}/${moment().format(
      'YYYY/MM/DD',
    )}`;
    const { policy, accessId, signature } = ossInfo;

    let resUrl = `${fileUrl}/${name}`;
    let fd = new FormData();
    fd.append('key', resUrl);
    fd.append('policy', policy);
    fd.append('OSSAccessKeyId', accessId);
    fd.append('signature', signature);
    fd.append('file', file);
    fetch(ossUrl, {
      method: 'POST',
      body: fd,
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve({ data: { link: OSSImgUrl(resUrl) } }), 1000);
    });
  };

  // 新建

  function mediaBlockRenderer(block) {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: true,
      };
    }

    return null;
  }

  const Media = (props) => {
    let media;
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const type = entity.getType();
    if (type === 'IMAGE') {
      media = <img src={entity.data.src} />;
    }
    if (type === 'audio') {
      media = <audio />;
    } else if (type === 'VIDEO') {
      media = (
        <video src={entity.data.src} controls style={{ width: '100%' }}></video>
      );
    }
    return media;
  };

  // ----------------------------------------------------------------

  return (
    <div style={{ border: '1px solid #ccc' }}>
      {/* <Button onClick={getData}>获取数据</Button> */}
      <DraftEdit
        blockRendererFn={mediaBlockRenderer}
        editorState={state.editorState}
        onContentStateChange={onEditorChange}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            className: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
        }}
        toolbarCustomButtons={[
          <VideoItem
            dirUrl={dirUrl}
            onChange={(val) =>
              state.editorState.push(state.editorState, val, 'insert-fragment')
            }
          />,
        ]}
      />
    </div>
  );
};

export default Editor;
