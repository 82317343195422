import styles from './index.less';

const StatisticsCard = ({
  size = 'small',
  title,
  desc,
  num,
  icon,
  type = 'primary',
}) => {
  return (
    <div className={`${styles.card} statistics-card ${size}`}>
      <div className="icon">
        <img src={icon} />
      </div>
      <div className="statistics-content">
        <div className="title">{title}</div>
        <div className="msg">
          <span className={`num ${type}`}>{num}</span>
          <span className="unit">次</span>
          <span className="desc">{desc}</span>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCard;
