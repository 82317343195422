import React, { useState, useEffect, useRef } from 'react';
import { Upload, MessageBox as Modal, MessageBox } from 'kenshin';
import { PlusOutlined } from '@ant-design/icons';
// import { getSignature } from '@/services/otherApi';
import request from 'umi-request';
import { getOSSMsg } from '@/_serveice/user';
import './index.less';
import { OSSImgUrl } from '../../_util/util';

let ossRootDirectoryBase = ossDirUrl;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const ImageUpload = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const imgUrl = useRef('');

  const { value, getUrl, onChange, dirUrl, ...resPeops } = props;

  if (!dirUrl && process.env.NODE_ENV == 'development') {
    return (
      <div style={{ color: 'red' }}>请在属性上添加dirUrl属性，例：/file</div>
    );
  }

  useEffect(() => {
    if (value) {
      setFileList([
        {
          status: 'done',
          url: OSSImgUrl(`${value}?time=${+new Date()}`),
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [value]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
  };

  const handleChange = (file) => {
    if (file.file.status == 'removed') {
      MessageBox.confirm({
        title: '确认操作',
        content: '确认删除？',
        onOk: () => {
          onChange?.('');
        },
      });
      return;
    }
    if ((file.file.status = 'success')) {
      setTimeout(() => {
        // oss上传可能会延迟展示
        setFileList([
          {
            status: 'done',
            url: OSSImgUrl(imgUrl.current),
          },
        ]);
      }, 300);
      onChange?.(imgUrl.current);
    }
  };

  const getExtraData = async (file) => {
    // 这里设置参数
    // let name = "test.png"
    let name = file.name;
    const ossInfo = await getOSSMsg().then((res) => res.data);
    let fileUrl = `${ossRootDirectoryBase}${dirUrl}/${moment().format(
      'YYYY/MM/DD',
    )}`;
    const { policy, accessId, signature } = ossInfo;

    let resUrl = `${fileUrl}/${name}`;
    imgUrl.current = resUrl;
    // onChange?.(resUrl);
    // setFileList([
    //   {
    //     status: 'done',
    //     url: resUrl,
    //   },
    // ]);
    return {
      key: `${fileUrl}/${name}`,
      policy,
      OSSAccessKeyId: accessId,
      signature,
    };
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        {...resPeops}
        action={ossUrl}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        data={getExtraData}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{ width: '100%' }}
          src={OSSImgUrl(previewImage)}
        />
      </Modal>
    </>
  );
};

export default ImageUpload;
