import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, Table, Progress, Steps, Row, Col } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import EllipsisTooltip from '@/components/EllipsisTooltip';

const LogList = ({ dispatch, system }) => {
  const tableRef = useRef();

  useEffect(() => {
    dispatch({
      type: 'system/getAllModuleName',
    });
  }, []);

  const columns = [
    {
      dataIndex: 'optEmpName',
      width:150,
      title: '操作人姓名',
    },
    {
      dataIndex: 'optCode',
      title: '操作编码',
      width:200,
    },
    {
      dataIndex: 'optIp',
      title: '操作ip',
      width:200,
    },
    {
      dataIndex: 'optMoudle',
      title: '操作模块',
      width:200,
    },
    {
      dataIndex: 'gmtCreate',
      title: '操作时间',
      width:250,
      render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      dataIndex: 'optDescribe',
      width:200,
      title: '操作描述',
    },
    {
      dataIndex: 'optParams',
      title: '执行参数',
      width:300,
      render:val => <EllipsisTooltip width={300} value={val} />
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '操作人姓名/IP',
      },
    },
    {
      name: 'optMoudle',
      type: 'Select',
      props: {
        placeholder: '操作模块',
        allowClear:true,
        options: system.allModule.map(item => ({label:item,value:item})),
      },
    },
  ];

  return (
    <div>
      <TablePro
        request="system/getOperatorLogPage"
        rowKey={'optLogId'}
        actionRef={(ref) => (tableRef.current = ref)}
        columns={columns}
        curColumns
        searchCloumns={searchCloumns}
        data={system.logList}
      />
    </div>
  );
};

export default connect(({ system }) => {
  return {
    system,
  };
})(LogList);
