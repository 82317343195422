import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Row, Col, Tooltip, Alert } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import TreeData from '../../../project/taskManage/components/TreeData';
import { useUpdateEffect } from 'ahooks';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import '../../rptAudit/index.less';
import { SortAscendingOutlined } from '@ant-design/icons';
import ModalFormPro from '@/components/ModalFormPro';
import SortIcon from '@/_assets/icon/sort.js';
// 结构转换给树组件渲染
const transformTreeData = (arr) => {
  return arr?.map((item) => {
    let children = null;
    if (item.childList) {
      children = transformTreeData(item.childList);
    }
    return {
      children,
      title: `${item.servCatgCode} ${item.servCatgName}`,
      key: item.proServCatgId,
      ...item,
    };
  });
};

const ServCategory = ({ dispatch, usercenter, project, setTableType }) => {
  // 菜单操作
  const [selectRows, setSelectRows] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);

  const [moveVisible, setMoveVisible] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [taskList, setTaskList] = useState([]);

  const modalRef = useRef();

  const projectId = useRef();

  const tableRef = useRef(null);
  const curData = useRef(null); // 存储当前服务类目对应的数据

  useEffect(() => {
    dispatch({
      type: 'project/getusersimpprojectlist',
    });
    // 服务类目
    dispatch({
      type: 'project/getServcatgEasyList',
    });

    return () => {
      dispatch({
        type: 'project/save',
        payload: {
          taskList: {},
          taskListSearchForm: {},
        },
      });
    };
  }, []);

  const showMoveModal = (row) => {
    getProServCatgTree(row.projectId);
    modalRef.current?.form?.setFieldsValue({ projectId: row.projectId });
    setSelectKeys([row.rptDetailId]);
    setMoveVisible(true);
  };

  const columns = [
    {
      dataIndex: 'reportDate',
      width: 150,
      title: '上报日期',
    },
    {
      dataIndex: 'gmtCreate',
      width: 200,
      title: '记录日期',
      render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      key: 'projectName',
      width: 200,
      title: '项目名称',
      render: (row) => `${row.projectName}(${row.contractNumber})`,
    },
    {
      dataIndex: 'proServCatgName',
      width: 200,
      title: '服务类目',
    },
    {
      dataIndex: 'taskName',
      width: 200,
      title: '任务名称',
    },
    {
      key: 'taskTime',
      title: '具体时间段',
      width: 220,
      render: (row) =>
        [
          moment(row.workBeginTime).format('HH:mm:ss'),
          moment(row.workEndTime).format('HH:mm:ss'),
        ].join(' ~ '),
    },
    {
      dataIndex: 'rptWorkTotalHours',
      title: '上报工时',
      width: 100,
    },
    {
      dataIndex: 'rptRemark',
      title: '上报备注',
      width: 220,
      render: (val) => <EllipsisTooltip value={val} width={200} />,
    },
    {
      title: '操作',
      key: 'options',
      width: 100,
      fixed: 'right',
      render: (row) => {
        return (
          <Button type="text" onClick={() => showMoveModal(row)}>
            移动
          </Button>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'taskTime',
      type: 'DatePickerRangePicker',
      props: {
        placeholder: ['任务开始时间', '任务结束时间'],
      },
    },
    {
      name: 'taskName',
      type: 'Input',
      props: {
        placeholder: '任务名称',
      },
    },
  ];

  const extra = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        type="primary"
        onClick={() => {
          setMoveVisible(true);
        }}
      >
        批量移动
      </Button>
      <Button
        type="primary"
        style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
        onClick={() => setTableType(1)}
      >
        {/* <SortAscendingOutlined /> */}
        {SortIcon}
        按上报时间显示
      </Button>
    </div>
  );

  const onSubmit = (payload) => {
    payload.projectId = projectId.current;
    if (payload.taskTime) {
      const [start, end] = payload.taskTime;
      payload.rptBeginTime = moment(start)
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.rptEndTime = moment(end).endOf('d').format('YYYY-MM-DD HH:mm:ss');
    }
    payload.taskTime = undefined;
    if (payload.memberList) {
      payload.memberList = payload.memberList.map((item) => item.split('-')[1]);
    }
  };

  const treeHandleChange = (keys, row) => {
    curData.current = row;
    projectId.current = row.projectId;
    tableRef.current?.reload(
      {
        projectId: row.projectId,
        proServCatgId: row.proServCatgId,
      },
      true,
    );
  };

  /** 根据项目查找工作包 */
  const getProServCatgTree = (projectId) => {
    const { form } = modalRef.current;
    form.setFieldsValue({ proServCatgId: undefined, targetTaskId: undefined });

    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId,
      },
    }).then((data) => {
      setTreeData(transformTreeData(data));
    });
  };

  /** 根据工作包查询任务 */
  const proServChange = (proServCatgId) => {
    dispatch({
      type: 'project/getsimptasklist',
      payload: { proServCatgId },
    }).then((res) => {
      if (res) {
        setTaskList(res);
      }
    });
  };

  const moveFormItem = [
    {
      render: () => (
        <Alert
          title="操作步骤为选择项目——选择工作包——选择任务，如找不到任务请联系项目经理添加人员"
          type="info"
          showIcon
          closable={false}
        />
      ),
      formItemProps: {
        wrapperCol: 20,
      },
    },
    {
      name: 'projectId',
      label: '项目',
      type: 'Select',
      props: {
        placeholder: '选择项目',
        options: project.userProjectSimpList.map((item) => ({
          label: item.projectName,
          value: item.projectId,
        })),
        onChange: getProServCatgTree,
      },
    },
    {
      name: 'proServCatgId',
      label: '工作包',
      type: 'TreeSelect',
      props: {
        placeholder: '选择工作包',
        treeData,
        onChange: proServChange,
      },
    },
    {
      name: 'targetTaskId',
      label: '任务',
      type: 'Select',
      required: '请选择任务',
      props: {
        placeholder: '请选择任务',
        options: taskList.map((item) => ({
          label: item.value,
          value: item.id,
        })),
      },
    },
  ];

  const moveModalSave = ({ targetTaskId }) => {
    dispatch({
      type: 'usercenter/moveemprptdata',
      payload: { targetTaskId, rptDetailIdList: selectKeys },
    }).then((res) => {
      if (res) {
        setMoveVisible(false);
      }
    });
  };

  return (
    <div className="task-audit">
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData onChange={treeHandleChange} />
        </Col>
        <Col span={16}>
          <TablePro
            params={{
              projectId: projectId.current,
              proServCatgId: curData.current?.proServCatgId,
            }}
            request="usercenter/getemprptlistpage"
            rowKey="rptDetailId"
            searchCloumns={searchCloumns}
            columns={columns}
            rowClassName={(row) => (row.taskStatus == 1 ? 'table-success' : '')}
            scrollX
            curColumns
            actionRef={(ref) => (tableRef.current = ref)}
            onSubmit={onSubmit}
            data={usercenter.emprptlistpageList}
            extra={extra}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectKeys,
              onChange: (idList, rows) => {
                setSelectKeys(idList);
                setSelectRows(rows);
              },
            }}
          />
        </Col>
      </Row>
      <ModalFormPro
        width={800}
        title="移动"
        visible={moveVisible}
        edtaFormItem={moveFormItem}
        onSave={moveModalSave}
        ref={modalRef}
        onCancel={() => {
          setMoveVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ usercenter, project }) => {
  return {
    usercenter,
    project,
  };
})(ServCategory);
