import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Row, Col, Tooltip } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import TreeData from './components/TreeData';
import { menberListOptions } from '../../project/list/detailDataTransform';
import { findTree } from '@/components/ServCategoryList';
import { useUpdateEffect } from 'ahooks';
import { exportTaskList } from '@/_serveice/project';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { processapplyvalide } from '@/_serveice/admin/process';
import {
  getCloumns,
  statusFormItem,
  getEdtaFormItem,
  getSearchCloumns,
} from './cloumnsData';

// 修改本页请同步：项目查看=>任务管理

export const taskStatusObj = {
  0: <div className="status-grey">未完成</div>,
  1: <div className="status-green">已完成</div>,
};

const toOptions = (arr = []) => {
  arr ||= [];
  return arr.map((item) => ({
    value: item.proServCatgId,
    title: item.servCatgName,
    children: toOptions(item.childList),
    data: item,
  }));
};

const ServCategory = ({ dispatch, systemOrgani, project }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [employeeAll, setEmployeeAll] = useState([]);
  const [leaderKey, setLeaderKey] = useState('');

  const [selectKey, setSelectKey] = useState([]);

  /** 修改状态 */
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  const [btnDisabled, setBtnDisabled] = useState(false);

  const projectId = useRef();

  const tableRef = useRef(null);
  const curData = useRef(null); // 存储当前服务类目对应的数据

  useEffect(() => {
    dispatch({
      type: 'project/getSimpProjectList',
    });
    // 组织架构
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setEmployeeAll(res);
    });
    // 服务类目
    // dispatch({
    //   type: 'project/getServcatgEasyList',
    // });
    return () => {
      dispatch({
        type: 'project/save',
        payload: {
          taskList: {},
          taskListSearchForm: {},
        },
      });
    };
  }, []);

  useUpdateEffect(() => {
    tableRef.current?.reload();
  }, [employeeAll]);

  const modalRef = useRef({});

  const [servCatgList, setServCatgList] = useState({});
  const getServcatgForProjectId = (projectId) => {
    if (servCatgList[projectId]) {
      return servCatgList[projectId];
    }
    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId,
      },
    }).then((data) => {
      setServCatgList((list) => {
        list[projectId] = toOptions(data);
        return { ...list };
      });
    });
  };

  // 一些从服务类目树带过来的默认数据
  const editShowData = (row) => {
    const {
      servCatgName,
      servCatgLeader,
      servCatgBeginTime,
      servCatgEndTime,
      servCatgWorkPeriod: taskWorkPeriod,
      proServCatgId,
    } = curData.current || {};

    let dbServCatgTeamMemberList =
      row.dbTaskTeamMemberList || curData.current.dbServCatgTeamMemberList;
    let defaultValue = {
      proServCatgId,
      servCatgName,
      addTeamMemberList: dbServCatgTeamMemberList?.map(
        (member) => findTree(employeeAll, member.teamMemberId)?.value,
      ),
      taskTime: [
        moment(row.taskBeginTime || servCatgBeginTime || undefined),
        moment(row.taskEndTime || servCatgEndTime || undefined),
      ],
      taskWorkPeriod,
      ...row,
      taskLeader: findTree(employeeAll, row.taskLeader || servCatgLeader)
        ?.value,
    };
    console.log('defaultValue...', defaultValue);
    return defaultValue;
  };

  const showEditModal = (row = {}) => {
    setEditModalVisible(true);
    let data = { ...editShowData(row) };
    let projectId = data?.projectId || curData.current?.projectId;
    if (projectId) {
      getServcatgForProjectId(projectId);
    }
    setLeaderKey(data.taskLeader);
    setEditModalData(data);
    if (row.taskId) {
      processapplyvalide({ applyBusiId: row.taskId, applyBusiType: 4 }).then(
        (res) => {
          if (!res.data) {
            setBtnDisabled(true);
            message.warn('当前任务存在未完成的流程变更!');
          }
        },
      );
    }
  };

  const delRole = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'project/deleteTask',
          payload,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const addType = (item, payload) => {
    return {
      ...item,
      type: payload.taskLeader == item.teamMemberId ? 1 : 0,
    };
  };

  const editModalSave = ({ servCatgId, ...value }) => {
    if (!value.proServCatgId) {
      message.error('上级类目不能为空');
      return;
    }
    if (!value.addTeamMemberList?.includes(value.taskLeader)) {
      message.error('参与人员必须含有负责人！');
      return;
    }
    let payload = {
      proServCatgId: curData.current?.proServCatgId,
      projectId: curData.current?.projectId || editModalData.projectId,
      taskTeamMemberNum: value.addTeamMemberList.length,
      oldTaskTeamMemberList: [],
      removeTaskTeamMemberList: [],
      ...value,
      taskLeader: value?.taskLeader.split('-')[1],
    };
    if (payload.taskTime) {
      const [start, end] = payload.taskTime;
      payload.taskBeginTime = moment(start).format('YYYY-MM-DD HH:mm:ss');
      payload.taskEndTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
    }
    payload.taskTime = undefined;
    let hasEdit = false;
    if (editModalData.taskId) {
      // 编辑
      hasEdit = true;
      let memberList = menberListOptions({
        dbServCatgTeamMemberList: editModalData.dbTaskTeamMemberList,
        addTeamMemberList: value.addTeamMemberList,
      });
      payload.oldTaskTeamMemberList =
        memberList.thisOldServCatgTeamMemberList.map((item) =>
          addType(item, payload),
        );
      payload.removeTaskTeamMemberList =
        memberList.thisRemoveServCatgTeamMemberList;
      payload.addTaskTeamMemberList =
        memberList.thisNewAddServCatgTeamMemberList.map((item) =>
          addType(item, payload),
        );
      payload.taskId = editModalData.taskId;
    }
    if (!hasEdit && payload.addTeamMemberList) {
      payload.addTaskTeamMemberList = payload.addTeamMemberList?.map(
        (item, index) => ({
          teamMemberId: item.split('-')[1],
          teamMemberSort: index + 1,
          type: payload.taskLeader == item.split('-')[1] ? 1 : 0,
        }),
      );
    }
    payload.addTeamMemberList = undefined;
    payload.servCatgName = undefined;
    // menberListOptions()
    dispatch({
      type: 'project/addTask',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        setBtnDisabled(false);
        message.success('操作成功');
      }
    });
  };

  const columns = getCloumns({
    employeeAll,
    setStatusVisible,
    setStatusData,
    showEditModal,
    delRole,
  });

  /** 修改状态点击确定 */
  const statusSave = (val) => {
    let taskStatus = {
      taskIdList: statusData,
      taskStatus: val.taskStatus,
    };
    dispatch({
      type: 'project/taskStatus',
      payload: taskStatus,
    }).then((res) => {
      if (res) {
        setStatusVisible(false);
        setStatusData(null);
      }
    });
  };
  const leaderChange = (keys) => {
    const { form } = modalRef.current;
    let oldArr = form.getFieldsValue()?.addTeamMemberList || [];
    let lastLeaderIndex = oldArr.findIndex((item) => item == leaderKey);
    if (lastLeaderIndex != -1) oldArr.splice(lastLeaderIndex, 1);
    setLeaderKey(keys);
    oldArr.push(keys);
    oldArr = Array.from(new Set(oldArr));
    form.setFieldsValue({
      addTeamMemberList: oldArr,
    });
  };

  // 弹窗formItem
  const edtaFormItem = getEdtaFormItem({
    servCatgList,
    editModalData,
    curData,
    employeeAll,
    modalRef,
    leaderChange,
    leaderKey,
  });

  const searchCloumns = getSearchCloumns({ employeeAll });

  const batchClose = () => {
    if (selectKey?.length == 0) {
      message.warn('请选择需要完成的任务');
      return;
    }
    // finishTask({ taskIdList: selectKey });
    setStatusData(selectKey);
    setStatusVisible(true);
  };

  const extra = [
    <Button
      type="primary"
      key={btnKeys.add}
      disabled={!curData.current?.projectId}
      onClick={() => showEditModal()}
    >
      新增任务
    </Button>,
    <Button type="primary" key={btnKeys.statusChange} onClick={batchClose}>
      批量修改状态
    </Button>,
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let { taskTime, ...value } = tableRef.current.value;
        let payload = {
          memberList: value.memberList ?? [],
          proServCatgId: value.proServCatgId ?? '',
          projectId: curData.current?.projectId ?? '',
          taskName: value.taskName ?? '',
          taskBeginTime: '',
          taskEndTime: '',
        };
        if (payload.memberList) {
          payload.memberList = payload.memberList.map(
            (item) => item.split('-')[1],
          );
        }
        if (taskTime) {
          payload.taskBeginTime = moment(taskTime[0])
            .startOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
          payload.taskEndTime = moment(taskTime[1])
            .endOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
        }
        let url = `${baseURL}${exportTaskList}${splitParamsFoGet(payload)}`;
        downloadXlsx(url, '任务列表');
      }}
    >
      导出
    </Button>,
  ];

  const onSubmit = (payload) => {
    payload.projectId = projectId.current;
    // if (!payload.projectId) {
    //   return false;
    // }
    if (employeeAll?.length == 0) {
      return false;
    }
    if (payload.taskTime) {
      const [start, end] = payload.taskTime;
      payload.taskBeginTime = moment(start)
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.taskEndTime = moment(end)
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    payload.taskTime = undefined;
    if (payload.memberList) {
      payload.memberList = payload.memberList.map((item) => item.split('-')[1]);
    }
  };

  const treeHandleChange = (keys, row) => {
    curData.current = row;
    projectId.current = row.projectId;
    tableRef.current?.reload(
      {
        projectId: row.projectId,
        proServCatgId: row.proServCatgId,
      },
      true,
    );
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList) => {
      setSelectKey(userIdList);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.taskStatus == 1, // Column configuration not to be checked
    // }),
  };

  return (
    <div>
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData onChange={treeHandleChange} />
        </Col>
        <Col span={16}>
          <TablePro
            params={{
              projectId: projectId.current,
              proServCatgId: curData.current?.proServCatgId,
            }}
            request="project/getTaskPage"
            rowKey="taskId"
            // key={projectId}
            searchCloumns={searchCloumns}
            columns={columns}
            scrollX
            actionRef={(ref) => (tableRef.current = ref)}
            onSubmit={onSubmit}
            data={project.taskList}
            extra={extra}
            childrenColumnName="child"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>

      <ModalFormPro
        width={'80%'}
        title={editModalData?.taskId ? '编辑' : '新增'}
        formProps={{
          layout: 'vertical',
          labelCol: { span: 8 },
          wrapperCol: { span: 12 },
          className: 'inline-form',
          initialValues: {
            servCatgHourOrginCost: 1800,
          },
        }}
        submitDisabled={btnDisabled}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
          setBtnDisabled(false);
        }}
      />

      <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani }) => {
  return {
    project,
    systemOrgani,
  };
})(ServCategory);
