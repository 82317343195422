import { useState, useEffect, useRef } from 'react';
import { Button, Input, Avatar, Empty, message } from 'kenshin';
import {
  addChatHistory,
  chat,
  clearSessionList,
  getChatHistoryBySid,
  getUserSessionList,
  genimages,
  edittext,
  getbillinfo,
  createsession,
} from '../../_serveice/openai';
import styles from './styles.less';
import { useKeyPress, useReactive } from 'ahooks';
import { createEventSource, getUuid, createHistoryMsg } from './_util';
import SessionList from './components/SessionMenu';
import { OSSImgUrl, base64ToBlob, getExtraData } from '@/_util/util';
import Select from '../../_util/components/Select';
import request from 'umi-request';
import ExtraElement from './components/extraElement';
import { SendOutlined } from '@ant-design/icons';

const PLUGIN = {
  /** 聊天 */ CHAT: 1,
  /** 文生图 */ IMAGE: 2,
  /** 纠错 */ RECOVERY: 3,
};

const GPT = () => {
  const [sessions, setSessions] = useState([]);

  const uid = useRef(getUuid());
  const endApi = useRef([]);
  const state = useReactive({
    prompt: '',
    history: [],
    txt: '',
    sse: null,
    disabled: false,
    startTime: '',
    msgId: '',
    success: false,
    baseTxt: '',
    plugin: PLUGIN.CHAT,
  });

  useEffect(() => {
    getSessionList();
    // getbillinfo().then((res) => {
    //   console.log(res);
    // });
  }, []);

  /** 快捷键，ctrl+enter提问 */
  useKeyPress('ctrl.enter', (e) => {
    if (state.disabled) return;
    if ([...e.target.classList]?.includes('prompt')) {
      send();
    }
  });

  /** 获取会话列表 */
  const getSessionList = () => {
    getUserSessionList().then((res) => {
      setSessions(res?.data || []);
    });
  };

  const createSessionItem = () => {
    if (sessions.findIndex((item) => item.sid == uid.current) == -1) {
      return createsession(uid.current.replaceAll('-', ''), {
        sName: '新聊天',
      });
    }
  };

  /** gpt流式回答接收消息 */
  const onmessage = (txt) => {
    let q = state.history[state.history.length - 1][0];
    let a = txt.replace(/<br\/><br\/>tokens：(\d+)/g, '');
    state.history.splice(state.history.length - 1, 1, [q, marked.parse(a)]);
    // 每次回答都滚动到最底部
    toBottomScroll();
  };

  const saveHistory = () => {
    let chatMsgArgs = createHistoryMsg(
      state.history[state.history.length - 1],
      state.msgId,
      state.startTime,
    );
    addChatHistory(uid.current.replaceAll('-', ''), [
      {
        question: chatMsgArgs[0],
        reply: chatMsgArgs[1],
      },
    ]).then((res) => {
      endApi.current = [];
      state.success = false;
      if (sessions.findIndex((item) => item.sid == uid.current) == -1) {
        getSessionList();
      }
    });
  };

  /** 回答完成 */
  const done = () => {
    if (!(endApi.current.includes('stream') && endApi.current.includes('chat')))
      return;
    if (!state.msgId) return;
    state.disabled = false;
    saveHistory();
  };

  /** 流式聊天 */
  const chatStream = async () => {
    if (!state.prompt && !success) return message.warn('请输入提示词');
    state.startTime = moment().format('YYYY-MM-DD HH:mm:ss');
    state.disabled = true;
    let msg = state.prompt;
    state.prompt = '';
    state.history.push([msg, '...']);
    state.sse = await createEventSource({
      uid: uid.current,
      onmessage: onmessage,
      done: () => {
        state.success = true;
        endApi.current.push('stream');
        done();
      },
    });

    await chat(uid.current.replaceAll('-', ''), {
      msg,
    })
      .then((result) => {
        state.msgId = result.data.msgId;
        endApi.current.push('chat');
        done();
      })
      .catch((error) => {
        state.disabled = false;
        console.info('发送问题失败！');
      });
  };

  /** 文生图 */
  const textImage = async () => {
    if (!state.prompt) return message.warn('请输入提示词');
    state.disabled = true;
    let msg = state.prompt;
    state.history.push([msg, '正在绘图中，请稍等。。。。。。']);
    let res = await genimages({ describe: msg, imageType: 1 });
    let formData = new FormData();
    let base64Img = `data:image/jpeg;base64,${res?.data}`;
    let file = base64ToBlob(base64Img);
    console.log(file);
    file.name = `${Date.now()}.png`;
    const { key, policy, OSSAccessKeyId, signature, resUrl } =
      await getExtraData(file, '/openai');

    formData.append('key', key);
    formData.append('policy', policy);
    formData.append('OSSAccessKeyId', OSSAccessKeyId);
    formData.append('signature', signature);
    formData.append('file', file);

    await request.post(ossUrl, {
      data: formData,
    });
    let imgUrl = OSSImgUrl(resUrl);
    state.history[
      state.history.length - 1
    ][1] = `<p>绘图关键词：${msg}<p/><img src="${imgUrl}" />`;
    state.prompt = '';
    state.disabled = false;
    await createSessionItem();
    saveHistory();

    // console.log(url); // Debugging line
  };

  /** 文本纠错 */
  const textRecovery = async () => {
    if (!state.prompt) return message.warn('请输入提示词');
    state.disabled = true;
    await createSessionItem();
    let msg = state.prompt;
    state.history.push([msg, '正在进行纠错，请稍等。。。。。。']);
    let res = await edittext({ editTip: msg, textEditBefore: state.baseTxt });
    state.history[
      state.history.length - 1
    ][1] = `<p>原文本：${state.baseTxt}<p/><p>${res?.data}</p>`;
    state.prompt = '';
    state.disabled = false;
    saveHistory();
  };

  /** 发送 */
  const send = async () => {
    let event = {
      [PLUGIN.CHAT]: chatStream,
      [PLUGIN.IMAGE]: textImage,
      [PLUGIN.RECOVERY]: textRecovery,
    };
    event[state.plugin]?.();
  };

  /** 清空会话列表 */
  const clearSession = () => {
    state.history = [];
    clearSessionList(sessions.map((item) => item.sid)).then((res) => {
      if (res.success) {
        setSessions([]);
      }
    });
  };

  /** 会话列表点击 */
  const sessionChange = (sid) => {
    uid.current = sid;
    getChatHistoryBySid(sid).then((res) => {
      state.history = res?.data.map((item) => [
        item.question?.msgContent,
        item.reply?.msgContent,
      ]);
    });
  };

  /** 滚动到底部 */
  const toBottomScroll = () => {
    const pre = document.querySelector('.chat-box');
    pre.scrollTop = pre.scrollHeight - pre.offsetHeight;
  };

  /** 新会话 */
  const sessionsAdd = () => {
    state.history = [];
    uid.current = getUuid();
  };

  /** 停止生成 */
  const handleStop = () => {
    state.sse?.close?.();
    endApi.current.push('stream');
    done();
    if (sessions.findIndex((item) => item.sid == uid.current) == -1) {
      getSessionList();
    }
  };

  const pluginOptions = [
    { label: '聊天', value: PLUGIN.CHAT },
    { label: '文生图', value: PLUGIN.IMAGE },
    { label: '纠错', value: PLUGIN.RECOVERY },
  ];

  return (
    <div className={styles.gpt}>
      {/* <div className="title">GPT</div> */}
      <div className="gpt-main">
        <SessionList
          add={() => sessionsAdd()}
          list={sessions}
          onChange={sessionChange}
          clear={clearSession}
        />
        <div className="ai-content markdown-body" id="write">
          {state.history.length > 0 && (
            <div className="chat-box">
              {state.history.map((item, index) => (
                <div className="item" key={index}>
                  <div className="me">
                    <Avatar
                      src={
                        OSSImgUrl(
                          JSON.parse(localStorage.getItem('employeeDTO'))
                            ?.empHeadImg,
                        ) || 'https://joeschmoe.io/api/v1/random'
                      }
                    />
                    <div className="q">{item[0]}</div>
                  </div>
                  <div className="a">
                    <Avatar src={require('../../_assets/chat-avatar.png')} />
                    <div
                      className="md-content"
                      dangerouslySetInnerHTML={{ __html: item[1] }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {state.history.length == 0 && (
            <Empty description="请在下方输入问题" />
          )}
          {state.disabled && state.plugin == PLUGIN.CHAT && (
            <div className="stop-icon" onClick={() => handleStop()}>
              <div className="reat"></div>
              停止回答
            </div>
          )}
          <div className="inp">
            <ExtraElement
              visible={[PLUGIN.RECOVERY].includes(state.plugin)}
              value={state.baseTxt}
              onChange={(val) => (state.baseTxt = val)}
            >
              <div style={{ width: '100%' }}>
                <Select
                  showSearch={false}
                  options={pluginOptions}
                  value={state.plugin}
                  onChange={(val) => (state.plugin = val)}
                />
                <Input.TextArea
                  className="prompt"
                  placeholder="请输入内容,按 Ctrl+Enter 提问"
                  value={state.prompt}
                  disabled={state.disabled}
                  onChange={(e) => (state.prompt = e.target.value)}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
                <Button onClick={send} disabled={state.disabled}>
                  <SendOutlined />
                </Button>
              </div>
            </ExtraElement>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GPT;
