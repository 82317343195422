import { Button, Card, Col, Input, message, Row } from 'kenshin';
import Select from '@/_util/components/Select';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import ChooseBusiness from './components/ChooseBusiness';
import { useEffect, useRef, useState } from 'react';
import { todoItemBusiTypeObj } from '@/_util/SelectKey';
import { processapplyvalide } from '@/_serveice/admin/process';
import busiTypeKey from '../_util/busiTypeKey';
import Util from './_unit';

const { OSAEDIT, WEEKEDIT } = busiTypeKey;

const createProcess = ({ process, dispatch, systemOrgani }) => {
  const [chooseVisible, setChooseVisible] = useState(false);
  const [processOptions, setProcessOptions] = useState([]);
  const [busiType, setBusiType] = useState(-1);

  // 确定按钮的loading
  const [createBtnLoading, setCreateBtnLoading] = useState(false);

  const curData = useRef({});
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    key: '',
  });

  useEffect(() => {
    dispatch({
      type: 'process/getsimpprocesslist',
    }).then((res) => {
      setProcessOptions(res);
    });
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    });
  }, []);

  // 点击确定后走什么类型的业务
  const saveCacheData = (params, data, rest = {}) => {
    dispatch({
      type: 'process/save',
      payload: {
        changeInfo: { data: data || curData.current, params, ...rest },
      },
    });
    history.push({
      pathname: '/process/createProcess/changePage',
      query: {
        type: 'process',
      },
    });
    setCreateBtnLoading(false);
  };
  /** 点击确定根据类型操作 */
  const typeOption = Object.fromEntries([
    ...Object.values(Util).map((item) => [
      item.key,
      item.onSubmit?.({
        dispatch,
        curData,
        setCreateBtnLoading,
        saveCacheData,
      }) ?? saveCacheData,
    ]),
    [
      WEEKEDIT,
      Util.OsaEditUtil.onSubmit?.({
        dispatch,
        curData,
        setCreateBtnLoading,
        saveCacheData,
      }) ?? saveCacheData,
    ],
  ]);

  /** 选择后使用的id */
  const chooseId = Object.fromEntries([
    ...Object.values(Util).map((item) => [item.key, item.chooseId]),
    [WEEKEDIT, Util.OsaEditUtil.chooseId],
  ]);

  /** 发起流程 */
  const sendCreate = async () => {
    if (!(formData.name && formData.id)) {
      return message.error('请选择业务！');
    }
    let params = {
      applyBusiId: curData.current[chooseId[busiType]],
      processApplyName: formData.name,
      processId: formData.id,
      busiType,
    };

    setCreateBtnLoading(true);
    typeOption[busiType]?.(params);
  };

  /** 查看详情 */
  const toDetail = (row) => {
    let osaEdit = [OSAEDIT, WEEKEDIT];
    if (!typeOption.hasOwnProperty(row.applyBusiType)) {
      return message.error('暂无当前流程的相关设置，请联系管理员！');
    }
    let pathname = '/process/createProcess/detail';
    if (osaEdit.includes(row.applyBusiType)) {
      pathname = '/performance/selfosa/changosa';
    }

    history.push({
      pathname,
      query: {
        processApplyId: row.processApplyId,
      },
    });
  };

  const headerRender = (
    <div>
      发起任务
      <Button
        type="primary"
        className="flr"
        size="small"
        loading={createBtnLoading}
        onClick={sendCreate}
      >
        确定
      </Button>
    </div>
  );

  const applyStatusObj = {
    '-1': '待提交',
    0: '审核中',
    1: '审核通过',
    2: '审核拒绝',
    3: '终止流程',
  };
  const columns = [
    {
      dataIndex: 'processApplyName',
      title: '流程名称',
    },
    {
      dataIndex: 'applyBusiType',
      title: '业务类型',
      render: (val) => todoItemBusiTypeObj[val],
    },
    {
      dataIndex: 'applyStatus',
      title: '流程状态',
      render: (val) => applyStatusObj[val],
    },
    {
      dataIndex: 'gmtCreate',
      title: '创建时间',
      render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      key: 'options',
      title: '操作',
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => toDetail(row)}>
              详情
            </Button>
          </>
        );
      },
    },
  ];

  const handleChange = (obj) => {
    if (obj.id) {
      const busiType = processOptions.find(
        (item) => item.id == obj.id,
      )?.extendData;
      setBusiType(busiType);
    }
    Object.assign(formData, obj);
    setFormData({ ...formData });
  };

  /** 根据业务类型选择变更流程对应的属性名 */
  const getProps = (data) => {
    if (!data) {
      return '';
    }
    const propsName = Object.fromEntries([
      ...Object.values(Util).map((item) => [item.key, item.propsName(data)]),
      [WEEKEDIT, Util.OsaEditUtil.propsName(data)],
    ]);
    return propsName[busiType];
  };

  const chooseSave = async (data) => {
    /** 能否发起，校验是否有正在审核的流程 */
    let hasNext = await processapplyvalide({
      applyBusiId: data[chooseId[busiType]],
      applyBusiType: busiType,
    }).then((res) => res.data);

    if (!hasNext) {
      return message.warn('当前业务正在审核!');
    }
    setChooseVisible(false);
    curData.current = data;
    handleChange({
      name: `${getProps(data)}变更申请`,
    });
  };
  return (
    <div>
      <Card header={headerRender} style={{ marginBottom: 10 }}>
        <Row>
          <Col flex="100px">业务流程</Col>
          <Col flex="auto">
            <Select
              style={{ width: 200 }}
              onChange={(id) => handleChange({ id })}
              options={processOptions.map((item) => {
                return {
                  label: item.value,
                  value: item.id,
                  disabled:
                    !['8', '9'].includes(item.extendData) &&
                    !eval(item.extendData2),
                };
              })}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col flex="100px">业务名称</Col>
          <Col flex="220px">
            <Input
              value={formData.name}
              style={{ width: 200 }}
              onChange={(e) => handleChange({ name: e.target.value })}
            />
          </Col>
          <Col flex="auto">
            <Button
              type="primary"
              onClick={() => {
                if (!typeOption.hasOwnProperty(busiType)) {
                  return message.error(
                    '暂无当前业务的相关设置，请联系管理员！',
                  );
                }
                Object.values(Util)
                  .find((item) => item.key == busiType)
                  ?.onShow?.({ dispatch });
                if (busiType == WEEKEDIT)
                  Util.OsaEditUtil.onShow?.({ dispatch, type: WEEKEDIT });
                setChooseVisible(true);
              }}
              disabled={!formData.id}
            >
              选择
            </Button>
          </Col>
        </Row>
      </Card>
      <TablePro
        request="process/getprocessapplypage"
        rowKey={'processApplyId'}
        data={process.ProcessApplyPageList}
        columns={columns}
        curColumns
      ></TablePro>

      <ChooseBusiness
        id={formData.id}
        busiType={busiType}
        visible={chooseVisible}
        onCancel={() => setChooseVisible(false)}
        onSave={chooseSave}
        process={process}
      />
    </div>
  );
};

export default connect(({ process, loading, systemOrgani }) => ({
  process,
  systemOrgani,
  loading: loading.effects,
}))(createProcess);
