import React, {
  useEffect,
  useState,
  useRef,
  memo,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, MessageBox, Button, message } from 'kenshin';
import EditItem from './EditItem';
import styles from '../style.less';
import { deepClone } from '@/_util';

const RptModal = memo(
  ({
    selectRows,
    usercenter,
    timeData,
    getHasRptTime,
    visible,
    onCancel,
    loading,
    reportDate,
    onSave,
  }) => {
    let rowArr = selectRows || [];
    let value = Array.from(new Set(rowArr.map((item) => item.projectId))).map(
      (item) => ({
        projectId: item,
        list: rowArr.filter((data) => data.projectId == item),
      }),
    );

    const editRef = useRef([]);
    useEffect(() => {
      if (visible) {
        rseData.current = [];
        map.current = new Map();
      }
    }, [visible]);

    const rseData = useRef();

    let map = useRef(new Map());

    const handleChange = (val, key, obj) => {
      map.current.set(`${obj.projectId}-${obj.taskId}`, { ...val, ...obj });
      rseData.current = Array.from(map.current).map((item) => item[1]);
    };

    const handleSave = () => {
      let error = editRef.current.map((item) => {
        return item.getFieldError('workTime').length > 0;
      });
      let timeList = [
        ...rseData.current,
        ...usercenter.empCurTimesLot.map((item) => ({
          workBeginTime: item.beginDate,
          workEndTime: item.endDate,
        })),
      ];
      for (let i = 0; i < timeList.length; i++) {
        for (let j = i + 1; j < timeList.length; j++) {
          let start1 = new Date(timeList[i].workBeginTime).getTime();
          let end1 = new Date(timeList[i].workEndTime).getTime();
          let start2 = new Date(timeList[j].workBeginTime).getTime();
          let end2 = new Date(timeList[j].workEndTime).getTime();
          if (start1 < end2 && start2 < end1) {
            return message.error(`上报时间存在冲突，请检查！`);
          }
        }
      }

      // 如果错误列表中无true(错误数量>0)则全部通过
      if (!error.includes(true)) {
        onSave?.(rseData.current);
      }
    };
    return (
      <MessageBox
        width="80%"
        title={`${reportDate ? '补' : '上'}报：${moment(reportDate).format(
          'MM-DD',
        )}`}
        destroyOnClose
        visible={visible}
        onCancel={onCancel}
        confirmLoading={loading}
        onOk={handleSave}
      >
        <span>已上报时间段：</span>
        {deepClone(usercenter.empCurTimesLot)
          ?.sort((a, b) => a.beginDate - b.beginDate)
          .map((item, i) => (
            <div key={i} style={{ color: '#4389f9' }}>
              {moment(item.beginDate).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
              {moment(item.endDate).format('YYYY-MM-DD HH:mm:ss')}
              <span style={{ marginLeft: 10, color: '#4389f9' }}>
                {item.remark}
              </span>
            </div>
          ))}
        {value.map((item, baseIndex) => (
          <div key={item.projectId}>
            <Row className={styles.rptCard}>
              <Col flex="200px">
                {
                  usercenter.usercenterList.datas?.find(
                    (pageData) => pageData.projectId == item.projectId,
                  )?.projectName
                }
              </Col>
              <Col flex="200px">
                个人累计工时
                <Tooltip title="个人当前项目所有工作包已上报的累计工时">
                  <QuestionCircleOutlined />
                </Tooltip>
                ：{timeData?.[item.projectId]?.projectTime ?? 0}
              </Col>
              <Col flex="200px">
                今日累计工时
                <Tooltip title="个人当前项目所有工作包今日已上报的累计工时">
                  <QuestionCircleOutlined />
                </Tooltip>
                ：{timeData?.[item.projectId]?.projectCurTime ?? 0}
              </Col>
            </Row>
            {item.list.map((lisItem, index) => {
              let calHeaderl = (
                <Row className={styles.detailHeader}>
                  <Col flex="250px">
                    <span className="c999">
                      {lisItem.servCatgName || lisItem.proServCatgName} &gt;
                      {lisItem.taskName}
                    </span>
                  </Col>
                  <Col flex="250px">
                    累计上报工时：
                    <span className="c999">
                      {timeData?.[item.projectId]?.[
                        `${item.projectId}_${lisItem.proServCatgId}`
                      ] || 0}
                    </span>
                  </Col>
                </Row>
              );

              return (
                <EditItem
                  getHasRptTime={getHasRptTime}
                  key={lisItem.taskId}
                  disabled
                  expand
                  data={lisItem}
                  disabledTime={[...usercenter.empCurTimesLot]}
                  headerEl={calHeaderl}
                  onChange={(val, err) => {
                    handleChange(val, index, {
                      taskId: lisItem.taskId,
                      projectId: item.projectId,
                      proServCatgId: lisItem.proServCatgId,
                    });
                  }}
                  getRef={(ref) => editRef.current.push(ref)}
                />
              );
            })}
          </div>
        ))}
      </MessageBox>
    );
  },
);

export default RptModal;
