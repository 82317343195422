import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, Upload } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { importEmpSalaryUrl, downTemplate } from '@/_serveice/employee/index';
import { empStatusArr } from '../../admin/system/organizational';
import moment from 'moment';

const ServCategory = ({ dispatch, loading, employee_salary, user }) => {
  const [uploadVisible, setUploadVisible] = useState(false);
  let tableRef = useRef();
  let hasUpload = useRef(false);
  const columns = [
    {
      dataIndex: 'empName',
      title: '员工名称',
    },
    {
      dataIndex: 'empNumber',
      title: '员工工号',
    },
    {
      dataIndex: 'empDeptList',
      title: '所属部门',
      render: (val) => val.map((item, index) => <div key={index}>{item}</div>),
    },
    {
      dataIndex: 'gmtModified',
      title: '上传更新时间',
      render: (val) => val && moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      dataIndex: 'empStatus',
      title: '状态',
      render: (val) => empStatusArr[val],
    },
    {
      dataIndex: 'effectDate',
      title: '生效日期',
      render: (val) => val && moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  const extra = (hasbtnList) => (
    <>
      <Button
        type="primary"
        key={btnKeys.import}
        style={{ marginRight: 8 }}
        onClick={() => {
          hasUpload.current = false;
          setUploadVisible(true);
        }}
      >
        导入薪酬
      </Button>
      <Button
        type="primary"
        style={{ marginRight: 8 }}
        onClick={() => window.open(`${baseURL}${downTemplate}`)}
      >
        下载模板
      </Button>
    </>
  );

  const modalRef = useRef();
  const [paramData, setParamData] = useState({});
  const beforeUpload = () => {
    const { form } = modalRef.current;
    let values = form.getFieldValue();
    if (values.effectDate) {
      values.effectDate = moment(values.effectDate).format('YYYY-MM');
    }
    return values;
  };

  const uploadFile = (e) => {
    const { form } = modalRef.current;
    form.validateFields();
    let values = form.getFieldValue();
    if (!values.effectDate) {
      e.stopPropagation();
    }
  };

  const statusFormItem = [
    {
      formItemProps: {
        colon: false,
        wrapperCol: 16,
      },
      render: () => (
        <div style={{ color: '#F95520' }}>
          提示：数据为增量导入，只会导入增量的数量，不会更新原始数据。薪酬数据不会同业务库存在一起，计算时才回去调用，已加密。
        </div>
      ),
    },
    {
      name: 'effectDate',
      type: 'DatePicker',
      label: '日期',
      required: '请输入日期',
      props: {
        picker: 'month',
      },
    },
    // {
    //   name: 'secretKey',
    //   type: 'Input',
    //   label: '秘钥',
    //   required: '请输入秘钥',
    //   props: {
    //     placeholder: '请输入秘钥',
    //   },
    // },
    {
      label: '文件选择',
      render: () => {
        return (
          <Upload
            action={`${baseURL}${importEmpSalaryUrl}`}
            accept=".xlsx"
            data={beforeUpload}
            onSuccess={(res) => {
              if (res?.success) {
                hasUpload.current = true;
                // tableRef?.reload?.();
                message.success('导入成功');
              } else {
                message.error('导入失败');
              }
            }}
            // beforeUpload={beforeUpload}
            headers={{
              accessToken: localStorage.getItem('token'),
              pcDeviceCode: localStorage.getItem('pcDeviceCode'),
              muId: localStorage.getItem('muId'),
            }}
            // onChange={(info) => {
            //   if (info.file.status === 'done') {
            //     tableRef?.reload?.();
            //     setUploadVisible(false);
            //   }
            // }}
          >
            <Button type="primary" onClick={uploadFile}>
              选择文件上传
            </Button>
          </Upload>
        );
      },
    },
  ];

  const editModalSave = (val) => {
    tableRef?.reload?.();
    hasUpload.current = false;
    setUploadVisible(false);
  };

  return (
    <div>
      <TablePro
        request="employee_salary/getEmpSalaryPage"
        rowKey={'empId'}
        columns={columns}
        data={employee_salary.salaryList}
        dataSource={employee_salary.salaryList?.datas}
        extra={extra}
        actionRef={(ref) => (tableRef = ref)}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
      />
      <ModalFormPro
        title={'数据导入'}
        visible={uploadVisible}
        edtaFormItem={statusFormItem}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setUploadVisible(false);
        }}
        footer={<Button onClick={editModalSave}>关闭</Button>}
      />
    </div>
  );
};

export default connect(({ employee_salary, loading, user }) => {
  return {
    user,
    employee_salary,
    loading: loading.effects,
  };
})(ServCategory);
