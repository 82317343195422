import { Tabs } from 'kenshin';
import { useEffect, useState } from 'react';
import Pendding from './_pendingBusiness';
import BusinessLog from './_businessLog';
import { useSelector, useActivate } from 'umi';

const Business = (props) => {
  const [tabKeys, setTabkeys] = useState('1');
  const usercenter = useSelector(({ usercenter }) => usercenter);
  const [count, setCount] = useState({
    todo: 0,
    hasdo: 0,
  });

  useActivate(() => {
    console.log('激活');
  });
  useEffect(() => {
    setCount({
      todo: usercenter.todoSearchNum?.totalCount || 0,
      hasdo: usercenter.hasdoSearchNum?.totalCount || 0,
    });
  }, [
    usercenter.todoSearchNum.totalCount,
    usercenter.hasdoSearchNum.totalCount,
  ]);

  const tabList = [
    { label: `待办(${count.todo})`, value: '1' },
    { label: `已办(${count.hasdo})`, value: '2' },
  ];

  return (
    <div>
      <Tabs activeKey={tabKeys} type="box" onChange={setTabkeys}>
        {tabList.map((item) => (
          <Tabs.TabPane key={item.value} tab={item.label}></Tabs.TabPane>
        ))}
      </Tabs>
      <div>
        {tabKeys == '1' && <Pendding />}
        {tabKeys == '2' && <BusinessLog />}
      </div>
    </div>
  );
};

export default Business;
