import {
  Button,
  Col,
  message,
  Tag,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Timeline,
} from 'kenshin';
import { useState } from 'react';
import styles from './styles.less';
import { timeOptions, infoOptions, INFOKEY } from './staticData';
import { useDispatch } from 'umi';

/** 时间树扁平化 */
export const weekFlat = (list) => {
  if (!list) {
    return [];
  }
  return list
    .map((item) => {
      let arr = item.osaWeekMonthList.map((item) =>
        item.osaWeekDateList.map((item) => [
          item.osaWeekPlanBeginDate,
          item.osaWeekPlanEndDate,
        ]),
      );
      return arr;
    })
    .flat(2);
};
/** 存储每个月对应的周 */
const weekMap = {};

/** */
export const findBetweenTime = (times, searchTime) => {
  let start = moment(times[0]);
  let end = moment(times[1]);
  return moment(searchTime).isBetween(start, end);
};

const Search = ({ time, info, stat, emptype }) => {
  const [osaTime, setOsaTime] = time;

  const [statType, setStatType] = stat;
  const [infoChecked, setInfoChecked] = info;
  const dispatch = useDispatch();
  const [timeType, setTimeType] = useState('');
  const [empType, setEmpType] = emptype;

  const onChange = () => {};
  const timeSearch = ({ time }) => {
    setTimeType(time);
    const [amount, unit] = time.split('-');
    let startTime = moment();
    if (infoChecked == '3') startTime = moment().endOf(unit);
    const start = startTime.subtract(amount, unit).startOf(unit);
    const end = moment().endOf(unit);
    setOsaTime([start, end]);
  };

  const getWeekData = async (year = moment()) => {
    if (weekMap[year]) return weekMap[year];
    let result = await dispatch({
      type: 'performance/getyearweekplandatedata',
      payload: {
        osaWeekPlanYear: year,
      },
    });
    weekMap[year] = weekFlat(result);
    return weekMap[year];
  };

  const timeChange = async (time) => {
    if (infoChecked == INFOKEY.WEEK) {
      // 开始时间
      // let weekData = await getWeekData(time[0].year());
      // let curTime = weekData.find((times) => findBetweenTime(times, time[0]));
      // curTime && (time[0] = moment(curTime[0]));
      // //结束
      // weekData = await getWeekData(time[1].year());
      // curTime = weekData.find((times) => findBetweenTime(times, time[1]));
      // curTime && (time[1] = moment(curTime[1]));
    } else if (time[0]) {
      time[0] = time[0].startOf('quarter');
      time[1] = time[1].endOf('quarter');
    }
    setOsaTime(time);
    setTimeType('');
  };

  return (
    <div className={styles.search}>
      <Row style={{ marginBottom: 10 }}>
        <Col flex="80px">信息类别：</Col>
        <Col flex="auto">
          {infoOptions.map((item) => (
            <Tag.CheckableTag
              style={{ marginLeft: 4 }}
              key={item.value}
              checked={item.value == infoChecked}
              onChange={(checked) => {
                if (
                  item.value == INFOKEY.OSA &&
                  ['4', '5'].includes(statType)
                ) {
                  setStatType('1');
                }
                onChange?.({ osaWeekType: item.value });
                setInfoChecked(item.value);
              }}
            >
              {item.label}
            </Tag.CheckableTag>
          ))}
        </Col>
      </Row>
      <Row style={{ alignItems: 'center', marginBottom: 10 }}>
        <Col flex="80px">日期范围：</Col>
        <Col flex="auto">
          {timeOptions(infoChecked).map((item) => (
            <Tag.CheckableTag
              style={{ marginLeft: 4 }}
              key={item.value}
              checked={item.value == timeType}
              onChange={(checked) => {
                timeSearch({ time: item.value });
              }}
            >
              {item.label}
            </Tag.CheckableTag>
          ))}
          <DatePicker.RangePicker
            picker={infoChecked == INFOKEY.OSA ? 'quarter' : undefined}
            value={osaTime}
            allowClear={false}
            onChange={timeChange}
            style={{ marginLeft: 4 }}
            // panelRender={panelRender}
          />
        </Col>
      </Row>

      <Row style={{ alignItems: 'center' }}>
        <Col flex="80px">用户类型：</Col>
        <Col flex="auto">
          {[
            { label: '全部', value: '-1' },
            { label: '在职', value: '1' },
            { label: '离职', value: '2' },
          ].map((item) => (
            <Tag.CheckableTag
              style={{ marginLeft: 4 }}
              key={item.value}
              checked={item.value == empType}
              onChange={(checked) => {
                setEmpType(item.value);
              }}
            >
              {item.label}
            </Tag.CheckableTag>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Search;
