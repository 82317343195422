import { createDeptColumns } from '../attendanceAnalyse/staticData';

export const INFOKEY = {
  OSA: 1,
  WEEK: 2,
};
export const DATATYPEKEY = {
  ALL: 1,
  DEPT: 2,
  EMP: 3,
};

export const EMPTYPE = {
  ALL: -1,
  ON: 1,
  OFF: 2,
};

/** 季度、半年、一年选项卡 */
export const statTypeOptions = (type) => {
  const result = [
    { label: '季度', value: '1' },
    { label: '半年', value: '2' },
    { label: '一年', value: '3' },
  ];
  if (type == INFOKEY.WEEK) {
    result.unshift({ label: '周', value: '4' }, { label: '月', value: '5' });
  }
  return result;
};
/** 全部、部门、人员选项卡 */
export const dataTypeOptions = [
  { label: '全部', value: DATATYPEKEY.ALL },
  { label: '部门', value: DATATYPEKEY.DEPT },
  { label: '人员', value: DATATYPEKEY.EMP },
];
/** 日期范围 */
export const timeOptions = (type) => {
  let result = [
    { label: '半年', value: '6-month' },
    { label: '一年', value: '0-year' },
    { label: '两年', value: '1-year' },
  ];
  if (type == INFOKEY.WEEK) {
    result.unshift(
      { label: '一个月', value: '0-month' },
      { label: '季度', value: '0-quarter' },
    );
  } else if (type == INFOKEY.OSA) {
    result.push(
      { label: '三年', value: '3-year' },
      { label: '五年', value: '5-year' },
    );
  }
  return result;
};
/** 信息类别 */
export const infoOptions = [
  {
    label: `OSA季度绩效`,
    value: INFOKEY.OSA,
  },
  {
    label: `周计划`,
    value: INFOKEY.WEEK,
  },
];
/** 图表数据转换 */
export const chartTransform = (data) => {
  if (!Array.isArray(data)) return [];
  data.map((item) => {});
};

/** 固定存在的表头数据 */
let exitColumns = {
  姓名: {
    title: '姓名',
    width: 100,
    fixed: 'left',
    dataIndex: 'empName',
  },
  所在部门: {
    title: '所在部门',
    width: 300,
    fixed: 'left',
    dataIndex: 'deptName',
  },
};

/** 根据服务端返回的表头进行渲染 */
export const createTableHeader = (baseList, { dataType, infoChecked }) => {
  if (!Array.isArray(baseList)) return [];
  let columns = baseList.map((item) => {
    if (exitColumns[item]) {
      return exitColumns[item];
    }
    return {
      title: item,
      key: item,
      width: 300,
      children: createDeptColumns(
        item,
        infoChecked,
        dataType == DATATYPEKEY.EMP,
      ),
    };
  });
  return columns;
};

/** 为每个item数据的key加上时间前缀 */
const addBaseStatKey = (item) => {
  let obj = [];
  Object.keys(item).forEach((key) => {
    obj[`${item.statTime}${key}`] = item[key];
  });
  return obj;
};

/** 将数组中的对象合并成一个对象 */
const mergeObj = (arr) => {
  if (!Array.isArray(arr) && arr.length == 0) return [];
  let obj = arr.reduce((pre, cur) => ({ ...pre, ...cur }), {});
  return obj;
};

/** 改造服务端返回的数据源 */
export const createDataSource = (dataSource) => {
  if (!Array.isArray(dataSource) && dataSource.length == 0) return [];
  let result = dataSource.map((item) => {
    if (item.statItemList.length > 0) {
      return mergeObj(
        item.statItemList.map((child) => ({
          ...addBaseStatKey(child),
          ...item,
          statItemList: undefined,
        })),
      );
    }
    return item;
  });
  return result;
};
