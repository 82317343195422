import {
  Button,
  Col,
  message,
  MessageBox,
  Progress,
  Row,
  Tag,
  Tooltip,
} from 'kenshin';
import { history } from 'umi';
import { roleType } from '@/_util/keysType';
import { timerYMD } from '@/_util/componentsUtil';
import MoreButton from '../../../components/moreButton';

export const getTableCloumns = ({
  showEditModal,
  projectStatus,
  showServCateModal,
  setStatusVisible,
  setStatusData,
  showWordHour,
  delRole,
}) => [
  {
    dataIndex: 'projectName',
    fixed: 'left',
    width: 200,
    title: '项目名称',
    // sort:true,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'custCompFullName',
    width: 200,
    title: '客户名称',
    sort: true,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'contractNumber',
    width: 150,
    title: '合同编号',
    sort: true,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'projectAmount',
    width: 150,
    title: '项目金额',
    sort: true,
    render: (val) => (val / 100).toFixed(2),
  },
  {
    key: 'projectTime',
    width: 250,
    title: '项目周期',
    render: (row) => timerYMD([row.projectBeginTime, row.projectEndTime]),
  },
  {
    dataIndex: 'projectCollectAmount',
    width: 150,
    title: '收款金额',
    sort: true,
    render: (val) => +(+val / 100).toFixed(2),
  },
  {
    dataIndex: 'projectActualCollectProgressValue',
    width: 150,
    title: '收款进度',
    sort: true,
    render: (val) => (
      <div className="progress-box">
        <span>{+val}% </span>
        <Progress strokeWidth={6} percentage={val} showText={false} />
      </div>
    ),
  },
  {
    key: 'projectConsumProgress',
    sortKey: 'projectActualConsumProgressValue',
    width: 300,
    title: '消耗进度',
    sort: true,
    // render: (val, row) =>
    //   projectProgress(val, row.projectChangeConsumProgressValue),
    render(row) {
      const status = {
        0: 'exception',
        2: 'success',
      };
      return (
        <>
          <div className="progress-box">
            消耗进度：
            <span>{+row.projectActualConsumProgressValue}% </span>
            <Progress
              status={status[row.projectConsumProgress]}
              strokeWidth={6}
              percentage={row.projectActualConsumProgressValue}
              showText={false}
            />
          </div>
          <div className="progress-box">
            预期进度：
            <span>{+row.projectNormalConsumProgressValue}% </span>
            <Progress
              strokeWidth={6}
              percentage={row.projectNormalConsumProgressValue}
              showText={false}
            />
          </div>
        </>
      );
    },
  },
  {
    dataIndex: 'projectTimeProgressValue',
    width: 150,
    title: '时间进度',
    sort: true,
    render: (val, row) => (
      <>
        <div className="progress-box">
          <span>{+val}% </span>
          <Progress strokeWidth={6} percentage={val} showText={false} />
        </div>
      </>
    ),
  },
  {
    dataIndex: 'projectStatus',
    width: 100,
    title: '项目状态',
    render: (val) => projectStatus[val],
  },
  {
    dataIndex: 'proTeamNum',
    width: 100,
    title: '项目团队',
    render: (val) => `${val} 人`,
  },
  {
    dataIndex: 'projectManagerName',
    width: 100,
    title: '项目经理',
  },
  {
    title: '操作',
    width: 150,
    key: 'options',
    fixed: 'right',
    render: (row) => {
      return (
        <MoreButton>
          {/* <Button type="text" onClick={() => toLowCodeFram(row)}>页面配置</Button> */}
          {row.hasbtnList?.includes(btnKeys.view) && (
            <Button
              type="text"
              onClick={() => {
                let url = `${window.location.origin}/#/project/list/projDetail?projectId=${row.projectId}`;
                window.open(url);
                // history.push({
                //   pathname: '/project/list/projDetail',
                //   query: {
                //     projectId: row.projectId,
                //   },
                // })
              }}
            >
              查看
            </Button>
          )}
          {row.hasbtnList?.includes(btnKeys.edit) &&
            [0, 1].includes(row.projectStatus) && (
              <Button
                type="text"
                onClick={() => showEditModal(row)}
                disabled={!(row.projectStatus == 1 || row.projectStatus == 0)}
              >
                编辑
              </Button>
            )}

          {/* {row.hasbtnList?.includes(btnKeys.edit) &&    // 暂时注释，待几个版本不该回来即可删除 2022-9-2
            [0, 1].includes(row.projectStatus) && (
              <Col span={10}>
                <Button type="text" onClick={() => showServCateModal(row)}>
                  工作包
                </Button>
              </Col>
            )} */}
          {row.hasbtnList?.includes(btnKeys.delete) && (
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delRole({ projectIdList: [row.projectId] })}
            >
              删除
            </Button>
          )}
          {row.hasbtnList?.includes(btnKeys.statusChange) && (
            <Button
              type="text"
              onClick={() => {
                setStatusVisible(true);
                setStatusData([row.projectId]);
              }}
            >
              状态
            </Button>
          )}
          {row.hasbtnList?.includes(btnKeys.statusChange) && (
            <Button
              type="text"
              onClick={() => {
                showWordHour(row);
              }}
            >
              员工上报情况
            </Button>
          )}
        </MoreButton>
      );
    },
  },
];

export const getEditCloumns = ({
  contractMoney,
  contractChange,
  easyContractList,
  systemOrgani,
  rowData,
}) => {
  let employeeAll = systemOrgani.employeeAll;

  if (rowData && rowData.proPreallocationMemberList) {
    let arr = [];

    rowData.proPreallocationMemberList.map((item) => {
      employeeAll.map((employee) => {
        employee.options.map((o) => {
          // console.log("item,o==========",item.teamMemberId,o.value.split('-')[1]);
          if (item && item.teamMemberId == o.value.split('-')[1]) {
            // console.log("getEditCloumns==========arr",o.value.split('-')[1]);
            // return o;
            arr.push(o);
          }
        });
      });
    });

    rowData.proPreallocationMemberList = arr;

    // console.log("getEditCloumns==========arr",arr);

    // rowData.proPreallocationMemberList = employeeAll[0].options;

    // console.log("getEditCloumns==========rowData",rowData.proPreallocationMemberList);
  }

  let projManage = [
    ...(systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER] || []),
  ];
  if (
    projManage.findIndex((item) => item.id == rowData?.projectManager) == -1 &&
    rowData
  ) {
    projManage.push({
      id: rowData.projectManager,
      value: rowData.projectManagerName,
    });
  }
  // console.log("getEditCloumns==========rowData",rowData?.proPreallocationMemberList);
  // console.log("getEditCloumns==========projManage",employeeAll);
  return [
    {
      label: '项目名称',
      name: 'projectName',
      type: 'Input',
      required: '请输入项目名称',
      props: {
        placeholder: '请输入项目名称',
      },
    },
    {
      label: '合同编号',
      name: 'contractId',
      type: 'Select',
      help: `合同剩余金额：${contractMoney / 100}`,
      required: '请输入合同编号',
      props: {
        placeholder: '请输入合同编号',
        disabled: !!rowData?.projectId,
        listHeight: document.body.offsetHeight - 250,
        onChange: contractChange,
        options: easyContractList?.map((item) => ({
          value: `${item.id}-${item.value}`,
          label: `${item.value}(${item.extendData}:${item.extendData2})`,
        })),
      },
    },
    {
      label: '项目周期',
      name: 'projectTime',
      type: 'DatePickerRangePicker',
      required: '请输入项目周期',
      props: {},
    },
    {
      label: '项目经理',
      name: 'projectManager',
      type: 'Select',
      required: '请选择项目经理',
      props: {
        placeholder: '请选择项目经理',
        options: projManage?.map((item) => ({
          label: item.value,
          value: item.id,
        })),
      },
    },
    {
      label: '项目金额',
      name: 'projectAmount',
      type: 'InputNumber',
      required: '请输入项目金额',
      // extra: rowData?.proServCatgDiscTotalAmount
      //   ? `支持修改最小值：${rowData.proServCatgDiscTotalAmount}`
      //   : undefined,
      props: {
        disabled: !!rowData?.projectId,
        placeholder: '单位元',
        min: rowData?.proServCatgDiscTotalAmount || 0,
        stepType: 'inside',
      },
    },
    {
      label: '项目描述',
      name: 'projectDescribtion',
      type: 'TextArea',
      props: {
        placeholder: '请输入项目描述',
      },
    },
    {
      label: '选择参与团队',
      name: 'proPreallocationMemberList',
      type: 'Select',
      required: '请选择参与团队',
      props: {
        mode: 'multiple',
        placeholder: '请选择参与团队',
        options: employeeAll,
      },
    },

    // {
    //   label: '选择参与团队',
    //   name: 'addTeamMemberList',
    //   type: 'Select',
    //   props: {
    //     placeholder: '请选择参与团队',
    //     mode: 'multiple',
    //     checkAlldisabledKey: '999999',
    //     maxTagCount: 'responsive',
    //     options: deepClone(employeeAll).map((item) => {
    //       if (leaderKey?.split('-')[0] == item.value) {
    //         item.options.find((op) => op.value == leaderKey).disabled = true;
    //       }
    //       return item;
    //     }),
    //   },
    // }
  ];
};

export const getSearchCloumns = ({ systemOrgani }) => [
  {
    name: 'key',
    type: 'Input',
    props: {
      placeholder: '项目/客户/合同编号',
    },
  },
  {
    name: 'projectManager',
    type: 'Select',
    label: '项目经理',
    props: {
      placeholder: '项目经理',
      options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
        (item) => ({
          label: item.value,
          value: item.id,
        }),
      ),
    },
  },
  {
    name: 'projectConsumProgress',
    type: 'Select',
    label: '消耗进度',
    props: {
      placeholder: '消耗进度',
      allowClear: true,
      options: [
        { label: '滞后', value: 0 },
        { label: '正常', value: 1 },
        { label: '超前', value: 2 },
      ],
    },
  },
  {
    name: 'projectStatus',
    type: 'Select',
    label: '项目状态',
    props: {
      placeholder: '项目状态',
      options: [
        { label: '未开始', value: 0 },
        { label: '正常', value: 1 },
        { label: '停止', value: 2 },
        { label: '结束', value: 4 },
      ],
    },
  },
];

export const getServCateProps = ({
  servCatgOnceOption,
  proServCatgDiscUnitPirceChange,
  dispatch,
  getServCateList,
  rowData,
}) => {
  return {
    onSave(val, oldRow) {
      // console.log("保存工作包，",val, oldRow);
      return new Promise((reslove, reject) => {
        oldRow ||= {};
        let otherData = {};
        // 编辑
        otherData.servCatgDbcode = oldRow.servCatgDbcode;
        otherData.proServCatgId = oldRow.proServCatgId;
        otherData.proServCatgPantId = oldRow.proServCatgPantId;
        otherData.servCatgCode = oldRow.servCatgCode;
        otherData.servCatgSort = oldRow.servCatgSort;
        val.dbServCatgTeamMemberList = oldRow.dbServCatgTeamMemberList;
        let payload = {
          ...servCatgOnceOption(val),
          ...otherData,
          projectId: rowData.current.projectId,
          childList: undefined,
          servCatgStatus: undefined,
          servCatgAlias: undefined,
          servCatgFinishNum: undefined,
          servCatgLeaderName: undefined,

          // servCatgLevel: undefined,
          // serwCatgDbcode:"123"
          // proservCatgid:"111",
          // proServCatgDiscUnitpirceChange: false,
          // proservCatgPantid: null,
          // dbServCatgList:undefined,
        };

        console.log('...servCatgOnceOption(val),', servCatgOnceOption(val));
        console.log('...otherData,,', otherData);

        if (oldRow.proServCatgId) {
          payload.proServCatgDiscUnitPirceChange =
            proServCatgDiscUnitPirceChange.current;
        }
        return dispatch({
          type: 'project/updataServcat',
          payload,
        })
          .then((res) => {
            if (res) {
              getServCateList(rowData.current.projectId);
              reslove(true);
            }
            reslove(false);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    pirceChange() {
      proServCatgDiscUnitPirceChange.current = true;
    },
    onDelete({ proServCatgId, projectId }) {
      return new Promise((reslove, reject) => {
        dispatch({
          type: 'project/delServcat',
          payload: { proServCatgId, projectId },
        })
          .then((res) => {
            if (res) {
              getServCateList(rowData.current.projectId);
              reslove(true);
            }
          })
          .catch(reject);
      });
    },
    onSort(payload) {
      dispatch({
        type: 'project/sortServcat',
        payload,
      }).then((success) => {
        if (success) {
          getServCateList(rowData.current.projectId);
        }
      });
    },
    rowData: rowData.current,
  };
};
