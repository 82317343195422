import Jsonform from '@/components/JsonForm';
import { MessageBox, Input, Button, message, InputNumber } from 'kenshin';
import { useEffect, useRef } from 'react';
import { useSelector, history, useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { useState } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import { deepClone, flat, downloadXlsx } from '@/_util/util';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { nextNodeIsMine } from '../../OSA/OSAsetting/_unitl';
import {
  exportemposaindexdata,
  queryosadirectbusiscoregrade,
} from '@/_serveice/performance/osa';
import PreviewIndicators from '../../../components/PreviewIndicators';
import { useReactive } from 'ahooks';

const DIR = 1; // 目录
const ITEM = 2; // 指标

const scoreTypeObj = {
  SUMMER_WEEK_PLAN_ONE_NODE: 1, // 周计划-总结
  SCORE_ONE_NODE: 1, // 自评
  SCORE_WEEK_PLAN_ONE_NODE: 2, // 周计划-上级评分
  SCORE_TWO_NODE: 2, // 上级评分
  SCORE_THREE_NODE: 3, // 业务负责人评分
};

// 分数的属性名
const scroKey = {
  SCORE_WEEK_PLAN_ONE_NODE: 'osaIndexItemDirectLeaderScore', // 周计划-上级评分
  SCORE_ONE_NODE: 'osaIndexItemSelfScore', // 自评
  SCORE_TWO_NODE: 'osaIndexItemDirectLeaderScore', // 上级评分
  SCORE_THREE_NODE: 'osaIndexItemBusiLeaderScore', // 业务负责人评分
};

// 备注的属性名
const remarkKey = {
  SCORE_ONE_NODE: 'osaIndexItemSelfScoreRemark', // 自评
  SCORE_TWO_NODE: 'osaIndexItemDirectLeaderScoreRemark', // 上级评分
  SCORE_THREE_NODE: 'osaIndexItemBusiLeaderScoreRemark', // 业务负责人评分
  SCORE_WEEK_PLAN_ONE_NODE: 'osaIndexItemDirectLeaderScoreRemark', // 周计划-上级评分
};

function getAllTreeKeys(tree) {
  if (!Array.isArray(tree)) return [];
  let keys = [];
  return flat(
    tree.map((row) => {
      if (row) {
        if (row.childList) {
          let arr = getAllTreeKeys(row.childList);
          return [...arr, `${row.type}-${row.id}`];
        }
      }
      return `${row.type}-${row.id}`;
    }),
    2,
  );
}

/** 获取总分数 */
function getTotalScore(list) {
  let osaIndexCatgSelfScore = 0;
  let osaIndexCatgDirectLeaderScore = 0;
  let osaIndexCatgBusiLeaderScore = 0;
  list?.forEach((item) => {
    osaIndexCatgSelfScore +=
      item.osaIndexItemSelfScore ?? item.osaIndexCatgSelfScore ?? 0;

    osaIndexCatgDirectLeaderScore +=
      item.osaIndexItemDirectLeaderScore ??
      item.osaIndexCatgDirectLeaderScore ??
      0;

    osaIndexCatgBusiLeaderScore +=
      item.osaIndexItemBusiLeaderScore ?? item.osaIndexCatgBusiLeaderScore ?? 0;
  });
  return {
    osaIndexCatgSelfScore, //  自评总得分
    osaIndexCatgDirectLeaderScore, // 上级总得分
    osaIndexCatgBusiLeaderScore, // 业务负责人总得分
  };
}

// 类似，a ?? b ?? 0
function setItemScore() {
  return Array.from(new Set([...arguments, 0])).filter(
    (item) => item != null && item != undefined,
  )[0];
}

// 统一设置表格里的目录总得分
function setScore(list) {
  list.forEach((node) => {
    if (node.type == DIR) {
      //  只处理目录
      if (node.childList) {
        setScore(node.childList);
        node.osaIndexCatgDirectLeaderScore = node.childList.reduce(
          (pre, item) => {
            pre += setItemScore(
              item.osaIndexItemDirectLeaderScore,
              item.osaIndexCatgDirectLeaderScore,
            );
            return pre;
          },
          0,
        );
        node.osaIndexCatgSelfScore = node.childList.reduce((pre, item) => {
          pre += setItemScore(
            item.osaIndexItemSelfScore,
            item.osaIndexCatgSelfScore,
          );
          return pre;
        }, 0);
        node.osaIndexCatgBusiLeaderScore = node.childList.reduce(
          (pre, item) => {
            pre += setItemScore(
              item.osaIndexItemBusiLeaderScore,
              item.osaIndexCatgBusiLeaderScore,
            );
            return pre;
          },
          0,
        );
      }
    }
  });
}

// 请求的dva Actions
const dispatchType = {
  GETTREE: 'performance/getosauserindextree',
  GETDIRTREE: 'performance/getosauserindexcatgtree',
  UPDATEDIR: 'performance/updateosaemployeeindexcatgdata',
  UPDATEITEM: 'performance/updateosaemployeeindexitemdata',
  DELETEDIR: 'performance/deleteosaemployeeindexcatgdata',
  DELETEITEM: 'performance/deleteosaemployeeindexitemdata',
  ADDTREE: 'performance/addosaemployeeindexdata',
  MOVEDIR: 'performance/moveosaemployeeindexcatgdata',
  MOVEITEM: 'performance/moveosaemployeeindexitemdata',
};
const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

const getCurNodeOptions = (curData) => {
  let obj = { self: false, direct: false, busy: false };
  if (curData.curnNodeNumber == 'SCORE_TWO_NODE' && curData.osaMuId == cuEmpId)
    obj.self = true;
  if (
    curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
    cuEmpId == curData.directLeader?.id
  )
    obj.direct = true;
  if (
    curData.curnNodeNumber == 'GRADE_NODE' &&
    cuEmpId == curData.busiLeader?.id
  )
    obj.busy = true;
  if (
    curData?.curnNodeNumber == 'SCORE_WEEK_PLAN_ONE_NODE' &&
    curData.osaMuId == cuEmpId
  )
    obj.direct = true;
  return obj;
};

const indicators = ({
  dispatchApi = dispatchType,
  // id参数名
  idKey = 'osaTemplateId',
  // 其他id，例如绩效id，默认使用模板id
  idValue, // 对应的id
  disabled, //禁用操作按钮
  superiorShow = true, // 上级是否有权限看到他人评分
  showBtn = true, //显示操作按钮
  onScoreChange, // 评分改变时调用
} = {}) => {
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);
  const curData = performance.curOsaSettingData.rows ?? {};
  const loading = useSelector(({ loading }) => loading.effects);

  const scoreState = useReactive({});
  const ramkState = useReactive({});

  /** 模板id */
  const osaTemplateId = idValue ?? history.location.query.osaTemplateId;

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 弹窗数据
  // const [editModalData, setEditModalData] = useState(null);
  // const [editVisible, setEditVisible] = useState(false);

  useEffect(() => {
    if (osaTemplateId) {
      getDirTree();
      dispatch({
        type: dispatchApi.GETTREE,
        payload: { [idKey]: osaTemplateId },
      });
    }
  }, [history.location.query.osaTemplateId, idValue]);

  // 获取目录树
  const getDirTree = () => {
    dispatch({
      type: dispatchApi.GETDIRTREE,
      payload: {
        [idKey]: osaTemplateId,
      },
    });
  };

  // const showScroSetting = (row) => {
  //   setEditModalData(row);
  //   setEditVisible(true);
  // };

  /** 不允许设置分数时应回显之前的数据 */
  const setOldValue = (value, item) => {
    let oldScoreKey = 'osaIndexItemSelfScore';
    let oldramkKey = 'osaIndexItemSelfScoreRemark';
    if (curData.curnNodeNumber == 'SCORE_THREE_NODE') {
      oldScoreKey = 'osaIndexItemDirectLeaderScore';
      oldramkKey = 'osaIndexItemBusiLeaderScoreRemark';
    }
    if (value.hasOwnProperty('score')) scoreState[item.id] = item[oldScoreKey];
    else ramkState[item.id] = item[oldramkKey];
  };

  /** 是否需要进行校验 */
  const hasCheck = () => {
    let status = getCurNodeOptions(curData);
    if (
      curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
      curData.osaMuId == cuEmpId
    )
      return true;
    if (
      curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
      cuEmpId == curData.directLeader?.id
    )
      return true;
    if (status.busy) return true;
  };

  const scoreOrRemarkChangeTimer = useRef(null); // 防抖标识
  // 评分或者备注修改时调用
  const scoreOrRemarkChange = async (value, item) => {
    let typeKey = {
      SCORE_TWO_NODE: 1,
      SCORE_THREE_NODE: 2,
      GRADE_NODE: 3,
    };
    let hasNext = true;
    if (hasCheck()) {
      hasNext = await queryosadirectbusiscoregrade({
        osaId: curData.osaId,
        type: typeKey[curData.curnNodeNumber],
      }).then((res) => !res.data);
    }
    if (!hasNext) {
      setOldValue(value, item);
      message.error('下个节点评分人已评分，当前不能修改评分或备注');
      return;
    }
    let scoreObj = {};
    let PromisePayloadArr = [];
    nextNodeIsMine(curData, curData.curnNodeNumber, (defaultNode) => {
      let newValue = {};
      let node = defaultNode;
      if (getCurNodeOptions(curData).self) node = 'SCORE_ONE_NODE';
      if (getCurNodeOptions(curData).direct) node = 'SCORE_TWO_NODE';
      if (getCurNodeOptions(curData).busi) node = 'SCORE_THREE_NODE';
      if (value.score !== undefined && value.score !== null) {
        newValue[scroKey[node]] = value.score;
      }
      if (value.remark) {
        newValue[remarkKey[node]] = value.remark;
      }
      // });
      // let node = curData.curnNodeNumber
      Object.assign(item, newValue); // 合并数据
      // 保存表格修改后的分数跟备注
      dispatch({
        type: 'performance/save',
        payload: {
          osaIndicators: [...performance.osaIndicators],
        },
      });
      // 统一设置分数，方便后续获取总分
      setScore(performance.osaIndicators);
      let payload = {
        osaIndexId: item.id,
        scoreType: scoreTypeObj[node],
        osaIndexScore: item[scroKey[node]] ?? 0,
        scoreRemark: item[remarkKey[node]] ?? '',
      };
      if (getCurNodeOptions(curData).self) payload.scoreType = 1;
      else if (getCurNodeOptions(curData).direct) payload.scoreType = 2;
      else if (getCurNodeOptions(curData).busy) payload.scoreType = 3;
      // 准备需要同步调用的评分接口的数据
      PromisePayloadArr.push(payload);
    });

    for (let i = 0; i < PromisePayloadArr.length; i++) {
      // 同步调用总分接口
      const payload = PromisePayloadArr[i];
      await dispatch({
        type: 'performance/osaindexscore',
        payload,
      });
    }

    const {
      osaIndexCatgSelfScore,
      osaIndexCatgDirectLeaderScore,
      osaIndexCatgBusiLeaderScore,
    } = getTotalScore(performance.osaIndicators); // 获取总分

    // 防抖清除定时器
    if (scoreOrRemarkChangeTimer.current) {
      clearTimeout(scoreOrRemarkChangeTimer.current);
      scoreOrRemarkChangeTimer.current = null;
    }
    // 更新总分只需要调用最后一次，只执行一次就行
    scoreOrRemarkChangeTimer.current = setTimeout(() => {
      // 总分的数据
      scoreObj = {
        osaSelfTotalScore: osaIndexCatgSelfScore,
        osaDirectTotalScore: osaIndexCatgDirectLeaderScore,
        osaBusiTotalScore: osaIndexCatgBusiLeaderScore,
      };
      dispatch({
        type: 'performance/updateosaemployeedata',
        payload: {
          ...curData,
          ...scoreObj,
        },
      });

      onScoreChange?.(scoreObj);
    }, 300);
  };

  // 评分的render
  const CreateScoreRender = ({ catgKey, scoreKey, nodeKey = [] }) => {
    return (val, row) => {
      if (
        nodeKey.includes(curData.curnNodeNumber) &&
        row.type == ITEM &&
        showBtn
      ) {
        if (
          curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
          nodeKey.includes('SCORE_ONE_NODE') &&
          curData.osaMuId != cuEmpId
        )
          return val ?? row[catgKey] ?? 0;
        if (
          curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
          !nodeKey.includes('SCORE_ONE_NODE') &&
          cuEmpId != curData.directLeader?.id
        )
          return val ?? row[catgKey] ?? 0;

        if (
          curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
          nodeKey.includes('SCORE_TWO_NODE') &&
          cuEmpId != curData.directLeader?.id
        )
          return val ?? row[catgKey] ?? 0;
        if (
          curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
          !nodeKey.includes('SCORE_TWO_NODE') &&
          cuEmpId != curData.busiLeader?.id
        )
          return val ?? row[catgKey] ?? 0;

        if (getCurNodeOptions(curData).direct) return val ?? row[catgKey] ?? 0;
        let max = row?.weight ?? 100;
        return (
          <InputNumber
            max={max}
            min={0}
            value={scoreState[row.id] ?? row[scoreKey] ?? 0}
            stepType="inside"
            onChange={(val) => (scoreState[row.id] = val)}
            onBlur={(e) => {
              scoreOrRemarkChange(
                { score: +e.target.value > max ? max : +e.target.value },
                row,
              );
            }}
          />
        );
      }
      return val ?? row[catgKey] ?? 0;
    };
  };

  // 备注的render
  const CreateScoreRemarkRender = ({ nodeKey = [] } = {}) => {
    return (val, row) => {
      if (
        nodeKey.includes(curData.curnNodeNumber) &&
        row.type == ITEM &&
        showBtn
      ) {
        if (
          curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
          nodeKey.includes('SCORE_ONE_NODE') &&
          curData.osaMuId != cuEmpId
        )
          return val ?? '-';
        if (
          curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
          !nodeKey.includes('SCORE_ONE_NODE') &&
          cuEmpId != curData.directLeader?.id
        )
          return val ?? '-';

        if (
          curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
          nodeKey.includes('SCORE_TWO_NODE') &&
          cuEmpId != curData.directLeader?.id
        )
          return val ?? '-';
        if (
          curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
          !nodeKey.includes('SCORE_TWO_NODE') &&
          cuEmpId != curData.busiLeader?.id
        )
          return val ?? '-';

        if (getCurNodeOptions(curData).direct) return val ?? row[catgKey] ?? 0;
        return (
          <Input
            value={ramkState[row.id] ?? val}
            style={{ width: 120, minWidth: 120 }}
            onChange={(e) => (ramkState[row.id] = e.target.value)}
            onBlur={(e) => scoreOrRemarkChange({ remark: e.target.value }, row)}
          />
        );
      }
      return val ?? '-';
    };
  };

  // 自评、业务负责人评价控制
  const getOtherScoreCloumns = () => {
    let self = [];
    let direct = [];
    let busi = [];
    if (superiorShow) {
      if (curData.osaWeekType != 1) {
        //  不是周计划
        self = [
          {
            dataIndex: 'osaIndexItemSelfScore',
            title: '评分(自评)',
            width: 120,
            render: (val, row) => val ?? row.osaIndexCatgSelfScore ?? 0,
          },
          {
            dataIndex: 'osaIndexItemSelfScoreRemark',
            title: '备注(自评)',
            width: 150,
            render: (val) => val ?? '-',
          },
        ];
      }
      if (
        hasShowScoreNode.includes(curData.curnNodeNumber) ||
        cuEmpId == curData.busiLeader?.id ||
        cuEmpId == curData.directLeader?.id
      ) {
        direct = [
          {
            dataIndex: 'osaIndexItemDirectLeaderScore',
            title: '评分(上级)',
            width: 120,
            render: (val, row) => val ?? row.osaIndexCatgDirectLeaderScore ?? 0,
          },
          {
            dataIndex: 'osaIndexItemDirectLeaderScoreRemark',
            title: '备注(上级)',
            width: 120,
            render: (val) => val ?? '-',
          },
        ];
      }
      if (
        curData.osaWeekType != 1 &&
        (hasShowScoreNode.includes(curData.curnNodeNumber) ||
          cuEmpId == curData.busiLeader?.id)
      ) {
        busi = [
          {
            dataIndex: 'osaIndexItemBusiLeaderScore',
            title: '评分(业务负责人)',
            width: 150,
            render: (val, row) => val ?? row.osaIndexCatgBusiLeaderScore ?? 0,
          },
          {
            dataIndex: 'osaIndexItemBusiLeaderScoreRemark',
            title: '备注(业务负责人)',
            width: 150,
            render: (val) => val ?? '-',
          },
        ];
      }
    }
    // if(curData)
    return { busi, self, direct };
  };

  const actualChange = async (value, item) => {
    let typeKey = {
      SCORE_TWO_NODE: 1,
      SCORE_THREE_NODE: 2,
      GRADE_NODE: 3,
    };
    let hasNext = true;
    if (hasCheck()) {
      hasNext = await queryosadirectbusiscoregrade({
        osaId: curData.osaId,
        type: typeKey[curData.curnNodeNumber],
      }).then((res) => !res.data);
    }
    if (!hasNext) {
      message.error('下个节点评分人已评分，当前不能修改实际值');
      return;
    }
    Object.assign(item, value); // 合并数据
    dispatch({
      type: 'performance/save',
      payload: {
        osaIndicators: [...performance.osaIndicators],
      },
    });
    dispatch({
      type: 'performance/updateosaempindexactucalvalue',
      payload: {
        ...value,
        osaIndexActualValue: value.osaIndexItemActualValue,
        osaIndexId: item.id,
      },
    });
  };
  const getActualCloumns = () => {
    return [
      {
        dataIndex: 'osaIndexItemActualValue',
        title: '实际值',
        width: 150,
        render(val, row) {
          if (
            curData.osaMuEncryptId == localStorage.getItem('muId') &&
            ['SCORE_ONE_NODE', 'SCORE_TWO_NODE'].includes(
              curData.curnNodeNumber,
            ) &&
            row.type == ITEM &&
            row.osaIndexItemModel == 1 &&
            showBtn
          ) {
            if (
              curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
              !getCurNodeOptions(curData).self
            )
              return val ?? '-';
            return (
              <Input
                defaultValue={val}
                style={{ width: 120, minWidth: 120 }}
                onBlur={(e) =>
                  actualChange({ osaIndexItemActualValue: e.target.value }, row)
                }
              />
            );
          }
          return val ?? '-';
        },
      },
    ];
  };

  const hasShowScoreNode = [
    history.location.query.busiId ? 'HR_PRE_CONFIRM_NODE' : null,
    'WEEK_PLAN_END_NODE',
    'OSA_END_NODE',
    history.location.query.busiId ? 'HR_PRE_CONFIRM_WEEK_PLAN_NODE' : null,
    'HR_CONFIRM_WEEK_PLAN_NODE',
    'HR_CONFIRM_NODE',
  ].filter(Boolean);

  let otherScoreCloumns = getOtherScoreCloumns();
  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      fixed: 'left',
      width: 300,
      render: (row) =>
        `${row.code} ${
          row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        }`,
      // render: (row) => {
      //   return (
      //     <EllipsisTooltip
      //       value={`${row.code} ${
      //         row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
      //       }`}
      //       width={300}
      //     />
      //   );
      // },
    },
    // {
    //   dataIndex: 'remark',
    //   title: '指标描述',
    //   width: 300,
    //   // render: (remark) => {
    //   //   return <EllipsisTooltip value={remark} width={300} />;
    //   // },
    // },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: 120,
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    {
      dataIndex: 'weight',
      title: '权重',
      width: 120,
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'osaIndexItemGoalValueUnit',
      title: '单位',
      width: 120,
    },
    {
      dataIndex: 'osaIndexItemGoalValue',
      title: '目标值',
      width: 120,
    },
    ...getActualCloumns(),
    ...otherScoreCloumns.self,
    ...otherScoreCloumns.direct,
    ...otherScoreCloumns.busi,
  ];

  const extra = () => {
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            // exportemposaindexdata();
            // downloadXlsx("https://test.wdp.waper4.com/v1/waper/erp/pc/osa/employee/exportemposaindexdata?osaId=1", '列表');
            setExpandedRowKeys(
              expandedRowKeys?.length == 0
                ? getAllTreeKeys(performance.osaIndicators)
                : [],
            );
          }}
        >
          {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
        </Button>
        {curData && <PreviewIndicators row={curData} type="primary" />}
      </>
    );
  };

  return (
    <>
      <TablePro
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        extra={extra}
        key={performance.osaIndicators}
        curColumns
        columnsOptions={false}
        scrollX
        scrollY={700}
        loading={loading[dispatchApi.GETTREE]}
        childrenColumnName="childList"
        dataSource={performance.osaIndicators}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />
    </>
  );
};

export default indicators;
