import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import styles from './style.less';

const SortTitle = (props) => {
  const { children, onChange, name, value } = props;
  const [order, setOrder] = useState(-1);
  useEffect(() => {
    setOrder(value);
  }, [value]);

  const handleClick = (orderType) => {
    let resOrder = order == orderType ? -1 : orderType;
    setOrder(resOrder);
    onChange?.({
      name,
      value: resOrder,
    });
  };

  return (
    <div className={styles.sortBox}>
      {children}
      <div className={styles.flexCol}>
        <CaretUpOutlined
          className={order == 1 ? styles.cupIconActive : styles.cupIcon}
          onClick={() => handleClick(1)}
        />
        <CaretDownOutlined
          className={order == 0 ? styles.cupIconActive : styles.cupIcon}
          onClick={() => handleClick(0)}
        />
      </div>
    </div>
  );
};

export default SortTitle;
