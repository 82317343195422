import { Tooltip, Button, Input } from 'kenshin';

const ExtraElement = ({ children, visible, onChange, value }) => {
  const renderTiele = () => {
    return (
      <Input.TextArea
        placeholder="请输入原文本"
        style={{ width: 500 }}
        onChange={(e) => onChange?.(e.target.value)}
        value={value}
      />
    );
  };
  return (
    <>
      <Tooltip
        visible={visible}
        title={renderTiele()}
        overlayStyle={{
          color: '#333',
          maxWidth: 500,
          fontSize: 12,
          lineHeight: '24px',
        }}
        color="#fff"
      >
        <span>{children}</span>
      </Tooltip>
    </>
  );
};

export default ExtraElement;
