import {
  Button,
  Typography,
  message,
  Radio,
  Input,
  Tabs,
  Timeline,
  MessageBox,
} from 'kenshin';
import CollapseCard, { Template } from '../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../../assessment/_keys';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import { exportosachangeindexdata } from '@/_serveice/performance/osa';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import Indicators from './components/indicators';
import JobEvaluation, { scoreTypeKey } from './components/jobEvaluation';
import { floatIndicators } from '../../assessment/OSA/OSAsetting/_unitl';
import { CloseCircleFilled } from '@ant-design/icons';

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

const scoreKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItem',
  SCORE_THREE_NODE: 'osaBusiBonusItem',
  SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectBonusItem',
};
const scoreRemarkKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItemRemark',
  SCORE_THREE_NODE: 'osaBusiBonusItemRemark',
  SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectBonusItemRemark',
};

const isShowConfirmBtn = (row, list, curData) => {
  if (list.length == 0) return false;
  if (history.location.query.processApplyId) return false;
  if (!(list?.length > 0)) return true;
  if (history.location.query.osaId) return false;
  if (history.location.query.busiId) return true;

  if (
    row &&
    (row.osaProcessDataList[2]?.curnNode || row.osaProcessDataList[3]?.curnNode)
  ) {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    return curData.busiLeader?.id == localEmpId;
  }
  if (
    row &&
    (row.osaProcessDataList[0].curnNode || row.osaProcessDataList[1].curnNode)
  ) {
    return true;
  }
  return false;
};
const isShowReturnBtn = (row) => {
  if (!row) return false;
  let curIndex = row.osaProcessDataList.findIndex((item) => item.curnNode);
  if (row.osaProcessDataList?.length == 0 || curIndex == 0 || row.processOver)
    return false;
  if (history.location.query.processApplyId) return false;

  if (curIndex > 0) {
    // 除了第一个节点，都有驳回
    return true;
  }
  if (history.location.query.busiId) {
    return true;
  }
  return false;
};
const isShowCancelBtn = (row, curData) => {
  if (row?.osaProcessDataList?.length == 0) return false;
  const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  if (localEmpId != curData.processApplyMuId) return false;
  return !row?.processOver;
};

const indicatorsKey = {
  DIR: 1,
  ITEM: 2,
};

/** 递归校验目录必须含有一个或多个指标 */
const indicatorsValidate = (list) =>
  list.every((item) => {
    if (item.type == indicatorsKey.DIR && !(item.childList?.length > 0)) {
      return false;
    }
    if (Array.isArray(item.childList)) {
      return indicatorsValidate(item.childList);
    }
    return true;
  });

const addScoreDisabled = (curData, key, processlist) => {
  const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  let disabled =
    curData.oldOsaProcessNodeNumber == 'SCORE_ONE_NODE' ||
    localEmpId != curData.processApplyMuId ||
    !processlist[0]?.curnNode;
  let KeyValue = {
    SCORE_ONE_NODE: curData?.osaMuId == localEmpId,
    SCORE_TWO_NODE: curData?.directLeader?.id == localEmpId,
    SCORE_WEEK_PLAN_ONE_NODE: curData?.directLeader?.id == localEmpId,
    SCORE_THREE_NODE: curData?.busiLeader?.id == localEmpId,
  };
  return !KeyValue[key] || disabled;
};

const levelSetDisabled = (curData, processlist) => {
  const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  if (!processlist?.[0]?.curnNode) return true;
  if (curData.oldOsaProcessNodeNumber == 'SCORE_ONE_NODE') return true;
  if (
    curData.busiLeader?.id !=
    JSON.parse(localStorage.getItem('employeeDTO'))?.empId
  )
    return true;
  if (localEmpId != curData.processApplyMuId) return true;
  return false;
};

const createTemplate = ({ performance, dispatch, loadingEf }) => {
  // 当前页面数据
  let curData = performance.curOsaSettingData?.rows ?? {};
  if (history.location.query.processApplyId) {
    // 查看详情时触发
    curData.osaMuId = undefined;
    curData.directLeader && (curData.directLeader.id = undefined);
    curData.busiLeader && (curData.busiLeader.id = undefined);
  }

  let disabled = true;

  const [editModalVisible, setEditModalVisible] = useState(false);
  const auditResult = useRef(AUDITTYPE.SUCCESS);
  const [scoreActiveKey, setScoreActiveKey] = useState('SCORE_TWO_NODE');
  const [yearNumber, setYearNum] = useState(0);
  const [resultList, setResultList] = useState([]);

  const [refuseinfo, setRefuseinfo] = useState(null);

  useEffect(() => {
    init();
    dispatch({
      type: 'performance/getosaauditsystemdescribelist',
    }).then((list) => {
      // console.log(res);
      if (list) {
        setResultList(
          list.map((item) => ({ label: item.value, value: item.id })),
        );
      }
    });
  }, [history.location.query.busiId, history.location.query.processApplyId]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  const init = async () => {
    const { processApplyId, busiId } = history.location.query;
    if (busiId ?? processApplyId) {
      curData = await dispatch({
        type: 'performance/getosachangeemployeedata',
        payload: {
          busiId: busiId ?? processApplyId,
        },
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                },
              },
            },
          });
          return res;
        }
        return {};
      });
    }
    // else if (history.location.query.osaId) {
    //   curData =
    //     (await dispatch({
    //       type: 'performance/getlastosachangedata',
    //       payload: {
    //         osaId: history.location.query.osaId,
    //       },
    //     })) ?? {};
    // }
    else if (curData.osaId) {
      curData.osaNewId = await dispatch({
        type: 'performance/launchosaweekplanchangeapply',
        payload: {
          osaId: curData.osaId,
        },
      });
    }
    if (curData.osaId && curData.osaNewId) {
      dispatch({
        type: 'performance/getosaemployeeauditscoredata',
        payload: {
          osaId: curData.osaId,
        },
      });
      getProcessList(curData);
    }
    if (curData.osaYearQuarter) {
      // await dispatch({
      //   type: 'performance/getosagradeaenablenum',
      //   payload: { yearQuarter: curData.osaYearQuarter },
      // }).then((res) => {
      //   if (typeof res == 'number') {
      //     let count = res;
      //     if (curData.osaGrade == 'A') {
      //       count += 1;
      //     }
      //     setYearNum(count);
      //   }
      // });
    }
  };

  /** 流程节点列表 */
  const getProcessList = (curData) => {
    dispatch({
      type: 'performance/getosachangeprocesslist',
      payload: {
        osaId: curData.osaNewId,
        todoBusiId: '',
      },
    }).then((res) => {
      if (!res) {
        setTimeout(() => {
          getProcessList(curData);
        }, 500);
      }
      if (res) {
        console.log('refuseinfo==================3', refuseinfo);
        res.osaProcessDataList.map((item) => {
          if (res.backNodeId != null && item.processNodeId === res.backNodeId) {
            console.log('请求拒绝接口了');
            dispatch({
              type: 'performance/getosaweekchangeprocessrefuseinfo',
              payload: {
                osaId: curData?.osaNewId,
                todoBusiId: '',
              },
            }).then((res) => {
              console.log('getosaweekchangeprocessrefuseinfo:curData', curData);
              if (res) {
                setRefuseinfo(res);
              }
            });
          }
        });
      }
    });
  };

  // 基本信息的附加项
  const infoParams = {
    title: '考核类型',
    dataIndex: 'osaType',
    valueType: 'RadioGroup',
    fieldProps: {
      placeholder: '请输入考核方案名称',
      disabled,
      options: [
        { label: '试用期转正评估', value: 1 },
        { label: '绩效评估', value: 2 },
        { label: '绩效改进评估', value: 3 },
        { label: '个人发展计划', value: 4 },
      ],
    },
  };

  const baseCloumns = [
    {
      title: '考核周期',
      valueType: 'RadioGroup',
      fieldProps: {
        disabled: true,
        value: curData.osaWeekType,
        options: [
          {
            label: '周',
            value: 1,
          },
          {
            label: '季度',
            value: 3,
          },
        ],
      },
    },
    {
      title: '考核有效期',
      valueType: 'DatePickerRangePicker',
      fieldProps: {
        disabled,
        value: [moment(curData.osaBeginTime), moment(curData.osaEndTime)],
        placeholder: ['开始时间', '结束时间'],
      },
    },
    {
      title: '考核模式',
      valueType: 'Select',
      extra:
        '在既定时间内完成目标，且有多付出、多贡献、有特别亮点的项目则视情况给予附加分1-20分',
      fieldProps: {
        value: curData.osaScoreRule,
        placeholder: '请选择考核模式',
        disabled,
        options: [
          {
            label: '满分100分制（可向上浮动20%）',
            value: 1,
          },
        ],
      },
    },
  ];

  // 驳回信息
  const rejectInfoParams = [
    {
      title: `${refuseinfo?.backUserName}:`,
      render() {
        return <div>{refuseinfo?.backUserRemark}</div>;
      },
    },
  ];

  const handleCancel = () => {
    if (curData.osaNewId) {
      MessageBox.confirm({
        title: '确认操作',
        content: '确认删除？',
        onOk: () => {
          dispatch({
            type: 'performance/deleteosachangedata',
            payload: {
              osaId: curData.osaNewId,
            },
          }).then((success) => {
            if (success) {
              goback();
            }
          });
        },
      });
    }
  };

  let list =
    performance.osaProcessCache[curData.osaNewId]?.osaProcessDataList ?? [];
  let backNodeId = performance.osaProcessCache[curData.osaNewId]?.backNodeId;
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[curData.osaNewId]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (backNodeId != null && list[index].processNodeId === backNodeId) {
      console.log(111111, backNodeId);
      console.log(222222, list[index].processNodeId);
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    } else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  const hasAuditBtnText = (row) => {
    let textObj = [
      '完成修改',
      '审核绩效',
      '审核绩效',
      '审核绩效',
      '审核绩效',
      '审核绩效',
      '审核绩效',
    ];
    if (curData?.osaWeekType == 1) {
      textObj = [
        '完成修改',
        '审核周计划',
        '审核周计划',
        '审核周计划',
        '审核周计划',
        '审核周计划',
        '审核周计划',
      ];
    }
    if (row && row.osaProcessDataList[0]) {
      let arr = row.osaProcessDataList.filter((item) => item.curnNode);
      let i = row.osaProcessDataList.indexOf(arr[arr.length - 1]);
      return textObj[i];
    }
    return '完成修改';
  };

  const handleExport = (type) => {
    let payload = {
      osaId: curData.osaNewId ?? '',
      type: type ?? '',
      // ...value,
    };
    let url = `${baseURL}${exportosachangeindexdata}${splitParamsFoGet(
      payload,
    )}`;

    downloadXlsx(url);
  };

  const saveCurData = (rows = {}) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows,
        },
      },
    });
  };

  const handleSave = (value) => {
    if (value.hasOwnProperty('score')) {
      value[scoreKey[scoreActiveKey]] = value.score;
      value.score = undefined;
    }
    if (value.hasOwnProperty('remark')) {
      value[scoreRemarkKey[scoreActiveKey]] = value.remark;
      value.remark = undefined;
    }
    saveCurData({
      ...curData,
      ...value,
      score: undefined,
    });
    // 后端保存数据
    dispatch({
      type: 'performance/updateosachangedata',
      payload: {
        ...curData,
        ...value,
      },
    });
  };

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const checkReamk = () => {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    // 校验备注
    let key = {
      OSA_CHANGE_UPDATE_OSA_NODE: 'osaIndexItemSelfScoreRemark', // 自评
      OSA_CHANGE_CONFIRM_OSA_NODE: 'osaIndexItemSelfScoreRemark', // 自评
    };
    if (
      Object.keys(key).includes(curData.curnNodeNumber) &&
      curData.oldOsaProcessNodeNumber != 'SCORE_ONE_NODE' &&
      curData.osaMuId == localEmpId &&
      curData.osaWeekType == 3
    ) {
      // 假如是评分环节
      let emptyList = floatIndicators(performance.osaIndicators).filter(
        (item) => {
          return item.type == 2 && !item[key[curData.curnNodeNumber]];
        },
      );
      if (emptyList.length > 0) {
        let codeStr =
          emptyList.length > 5
            ? ''
            : emptyList.map((item) => item.code).join(',') + '的'; // 备注为空的数量大于5条则不显示具体的code
        message.error(`${codeStr}备注不能为空`);
        return false;
      }
    }
    let remainWeightList = floatIndicators(performance.osaIndicators).filter(
      (item) => item.osaIndexRemainWeight > 0,
    );
    let hasRemainWeight = remainWeightList?.length > 0;
    if (hasRemainWeight) {
      let remainStr = remainWeightList
        .slice(0, 3)
        .map((item) => `${item.code} ${item.osaIndexCatgName}`)
        .join(',');
      if (remainWeightList.length > 3) {
        remainStr += ',...';
      }
      message.error(remainStr + '未分配完权重！');
      return false;
    }
    let countWeight = performance.osaIndicators.reduce(
      (pre, cur) => pre + cur.weight,
      0,
    );
    if (countWeight != 100 && curData?.processApplyMuId == curData?.osaMuId) {
      message.error(`当前权重总和不足100，当前权重：${countWeight}`);
      return false;
    }
    if (localEmpId == curData.osaMuId) {
      let emptyActualValue = floatIndicators(performance.osaIndicators).filter(
        (item) =>
          item.type == 2 &&
          item.osaIndexItemModel == 1 &&
          !item.osaIndexItemActualValue,
      );
      if (emptyActualValue?.length > 0) {
        let actualStr = emptyActualValue
          .slice(0, 3)
          .map((item) => `${item.code} ${item.osaIndexItemName}`)
          .join(',');
        message.error(`${actualStr}的实际值为空`);
        return false;
      }
    }
    return true;
  };

  const HeaderButton = (
    <div className="fixed-right">
      {curData.osaNewId &&
        isShowConfirmBtn(
          performance.osaProcessCache[curData.osaNewId],
          list,
          curData,
        ) && (
          <Button
            type="primary"
            onClick={() => {
              if (!indicatorsValidate(performance.osaIndicators)) {
                message.error('指标目录应含有指标');
                return;
              }
              console.log(111);
              if (!checkReamk()) return;
              console.log(222);
              setEditModalVisible(true);
              auditResult.current = AUDITTYPE.SUCCESS;
            }}
          >
            {hasAuditBtnText(performance.osaProcessCache[curData.osaNewId])}
          </Button>
        )}
      {isShowReturnBtn(performance.osaProcessCache[curData.osaNewId]) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      {curData.osaNewId &&
        isShowCancelBtn(
          performance.osaProcessCache[curData.osaNewId],
          curData,
        ) && (
          <Button
            type="danger"
            loading={loadingEf['performance/deleteosachangedata']}
            onClick={() => handleCancel()}
          >
            删除变更申请
          </Button>
        )}
      {curData.osaNewId &&
        isShowCancelBtn(
          performance.osaProcessCache[curData.osaNewId],
          curData,
        ) && (
          <Button
            type="primary"
            onClick={() => handleExport(curData.osaWeekType ? 2 : 1)}
          >
            导出
          </Button>
        )}
      <Button type="primary" onClick={goback}>
        返回
      </Button>
    </div>
  );

  const edtaFormItem = [
    auditResult.current == AUDITTYPE.ERROR
      ? {
          label: '原因',
          name: 'auditRemarkList',
          type: 'Select',
          props: {
            mode: 'tags',
            options: resultList,
            placeholder: '请输入原因，按enter确定',
          },
        }
      : null,
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
  ].filter(Boolean);

  const editModalSave = async (value) => {
    /** 通过/拒绝原因 */
    let auditRemarkList = [
      ...(value.auditRemarkList ?? []),
      value.auditRemark,
    ]?.map((txt) => {
      let SUCCESSID = 88888888;
      let ERRORID = 99999999;
      let auditRmkDescribe =
        resultList.find((item) => item.value == txt)?.label || txt;
      let auditRmkDescribeId =
        resultList.find((item) => item.value == txt)?.value ??
        (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
      let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
      return {
        auditRmkDescribe,
        auditRmkDescribeId,
        auditRmkType,
      };
    });
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    let payload = {
      ...value,
      auditRemarkList,
      osaId: curData.osaNewId,
      auditResult: auditResult.current,
    };
    let step = 1;
    if (
      curData.curnNodeNumber == 'OSA_CHANGE_AUDIT_OSA_ONE_NODE' &&
      curData.busiLeader?.id == localEmpId
    ) {
      // 假如创建人跟审核人是同一人
      // 假如业务负责人跟上级是同一人，则调用两次审核接口
      step++;
    }
    if (
      [
        'OSA_CHANGE_UPDATE_OSA_NODE',
        'WEEK_PLAN_CHANGE_UPDATE_WEEK_PLAN_NODE',
      ].includes(curData.curnNodeNumber) &&
      localEmpId == curData.osaMuId
    )
      step++;
    let success = true; // 是否成功
    for (let i = 0; i < step; i++) {
      // 调用次数
      await dispatch({
        type: 'performance/osaChangeAuditosaprocess',
        payload,
      }).then((res) => {
        if (!res) {
          success = false;
        }
      });
    }

    // 成功之后执行
    if (success) {
      setEditModalVisible(false);
      clearCache();
      goback();
      // debugger
      // if (curData.curnNodeNumber == 'OSA_CHANGE_UPDATE_OSA_NODE' && localEmpId == curData.osaMuId) {
      //   curData.curnNodeNumber = "OSA_CHANGE_CONFIRM_OSA_NODE"
      //   getProcessList(curData);
      // } else {
      //   goback();
      // }
    }
  };

  const extraScoreCloumns = [
    {
      render() {
        return (
          <Tabs
            activeKey={scoreActiveKey}
            titleText="卡片标题"
            type="card"
            onChange={setScoreActiveKey}
          >
            {curData.osaWeekType == 1 && (
              <Tabs.TabPane
                tab="直属上级"
                key="SCORE_WEEK_PLAN_ONE_NODE"
              ></Tabs.TabPane>
            )}
            {curData.osaWeekType == 3 && (
              <>
                <Tabs.TabPane
                  tab="直属上级"
                  key="SCORE_TWO_NODE"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab="业务负责人"
                  key="SCORE_THREE_NODE"
                ></Tabs.TabPane>
              </>
            )}
          </Tabs>
        );
      },
    },
    {
      title: '加分分值',
      dataIndex: 'score',
      valueType: 'InputNumber',
      fieldProps: {
        style: { width: 200 },
        stepType: 'inside',
        disabled: addScoreDisabled(curData, scoreActiveKey, list),
        min: 0,
        max: 100,
        placeholder: '请输入分数:0~20',
      },
    },
    {
      title: '原因描述',
      dataIndex: 'remark',
      valueType: 'TextArea',
      fieldProps: {
        autoSize: { minRows: 2, maxRows: 6 },
        disabled: addScoreDisabled(curData, scoreActiveKey, list),
        placeholder: '请输入原因描述',
      },
    },
  ];
  let isWeek = curData.osaWeekType == 1;

  const radioOptions = [
    // {
    //   label: `A(剩余${yearNumber}个)`,
    //   disabled: !yearNumber,
    //   value: 'A',
    // },
    {
      label: `A`,
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
  ];

  const radioChange = (e) => {
    let osaGrade = e.target.value;
    handleSave({ osaGrade });
  };

  const levelTextAreaChange = (e) => {
    let value = e.target.value;
    handleSave({ osaGradeRemark: value });
  };

  return (
    <div className="create-template-page">
      {HeaderButton}
      <Typography.Title level={4}>OSA考核设置</Typography.Title>
      {curData.osaId && (
        <CollapseCard title="常规指标">
          <Indicators
            osaWeekType={curData.osaWeekType}
            tableIdkey="osaId"
            idKey="osaUserId"
            hasEdit={
              curData.osaMuId ==
              JSON.parse(localStorage.getItem('employeeDTO'))?.empId
            }
            idValue={curData.osaNewId}
            onScoreChange={(value) => saveCurData({ ...curData, ...value })}
            processlist={list}
          />
          {/* {getTotalScore(performance.osaIndicators)} */}
          {isWeek ? (
            ''
          ) : (
            <div key={1} style={{ lineHeight: 2 }}>
              <span style={{ fontWeight: 'bold' }}>自评总得分：</span>
              {+curData.osaSelfTotalScore.toFixed(2)}
              {/* {curData.osaIndexCatgSelfScore} */}
            </div>
          )}
          <div key={2} style={{ lineHeight: 2 }}>
            <span style={{ fontWeight: 'bold' }}>上级总得分：</span>
            {+curData.osaDirectTotalScore.toFixed(2)}
            {/* {curData.osaIndexCatgDirectLeaderScore} */}
          </div>
          {isWeek ? (
            ''
          ) : (
            <div key={3} style={{ lineHeight: 2 }}>
              <span style={{ fontWeight: 'bold' }}>业务负责人总得分：</span>
              {/* {osaIndexCatgBusiLeaderScore} */}
              {+curData.osaBusiTotalScore.toFixed(2)}
            </div>
          )}
        </CollapseCard>
      )}

      {curData.osaWeekType == 3 && (
        <CollapseCard title={<>月度策略</>}>
          <Template.MONTHSTRATEGY.default
            // dispatchApi={dispatchApi}
            idKey="osaId"
            osaWeekType={3}
            disabled={
              curData.osaMuId !=
              JSON.parse(localStorage.getItem('employeeDTO'))?.empId
            }
            idValue={curData.osaId}
            newIdValue={curData.osaNewId}
            // showPublicTemplate={true}
          />
        </CollapseCard>
      )}

      <CollapseCard
        title={
          <>
            加分项
            <span style={{ color: '#999', fontSize: 12 }}>（最高上浮20%)</span>
          </>
        }
        cloumns={extraScoreCloumns}
        onSave={handleSave}
        key={scoreActiveKey}
        defaultValue={{
          score: curData[scoreKey[scoreActiveKey]],
          remark: curData[scoreRemarkKey[scoreActiveKey]],
        }}
      />
      <JobEvaluation
        curData={curData}
        onChange={handleSave}
        processlist={list}
      />
      {curData?.processApplyMuId != curData?.osaMuId && (
        <CollapseCard title="综合结论">
          <div>
            考核评级
            <Radio.Group
              style={{ marginLeft: 8 }}
              key={curData.osaGrade}
              disabled={levelSetDisabled(curData, list)}
              defaultValue={curData.osaGrade}
              options={radioOptions}
              onChange={radioChange}
            />
          </div>
          <Input.TextArea
            placeholder="具体原因"
            autoSize={{ minRows: 2, maxRows: 6 }}
            key={curData.osaGradeRemark}
            disabled={levelSetDisabled(curData, list)}
            defaultValue={curData.osaGradeRemark}
            style={{ marginTop: 8 }}
            onBlur={levelTextAreaChange}
          />
        </CollapseCard>
      )}

      {refuseinfo &&
        refuseinfo?.backUserRemark &&
        refuseinfo?.backUserRemark != 'null;' && (
          <CollapseCard
            visible="v"
            title="驳回信息"
            cloumns={Template.INFO.default({
              disabled,
              target: curData,
              arr: rejectInfoParams,
            })}
          />
        )}

      {list?.length > 0 && (
        <CollapseCard
          title={
            <>
              流程图
              <span style={{ color: '#999', fontSize: 12 }}>
                （查看目前处于哪个环节)
              </span>
            </>
          }
        >
          <Timeline>
            {list.map((item, index) => (
              <Timeline.Item
                key={index}
                content={item.processDescribe}
                // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
                {...dots(index)}
              />
            ))}
          </Timeline>
        </CollapseCard>
      )}

      <CollapseCard
        title="基本设置"
        cloumns={Template.INFO.default({
          item: infoParams,
          disabled,
          target: performance.curOsaSettingData?.rows,
        })}
        defaultValue={curData}
      />
      <CollapseCard
        title="方案基本设置"
        cloumns={baseCloumns}
        key={performance.curOsaSettingData?.rows}
        disabled={disabled}
        defaultValue={curData}
      />
      <CollapseCard title="审核流程设置">
        <Template.PROCESS.default
          disabled={disabled}
          defaultValue={curData.osaAuditList}
        />
      </CollapseCard>
      <ModalFormPro
        title="确认绩效"
        width={600}
        btnLoading={loadingEf['performance/osaChangeAuditosaprocess']}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(createTemplate);
