import { Button } from 'kenshin';
import Preview from './Preview';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { deepClone, flat, downloadXlsx } from '@/_util/util';

const PreviewIndicators = ({ row, type = 'text', hasRequest = true }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);

  const showPreview = () => {
    if (!hasRequest) {
      setVisible(true);
      return;
    }
    dispatch({
      type: 'performance/getosauserindextree',
      payload: {
        osaId: row.osaId,
      },
    }).then((res) => {
      if (res) {
        setVisible(true);
      }
    });
  };

  return (
    <>
      <Button type={type} onClick={showPreview}>
        预览
      </Button>
      <Preview
        visible={visible}
        data={row}
        dataSource={deepClone(performance.osaIndicators)}
        onCancel={() => setVisible(false)}
      />
    </>
  );
};

export default PreviewIndicators;
