import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, Table, Progress, Steps, Row, Col } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { roleType } from '@/_util/keysType';
import { projectStatus } from '../../project/list';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportUserPartinProject } from '@/_serveice/usercenter';

const ServCategory = ({ dispatch, systemOrgani, usercenter }) => {
  // 项目(收款|消耗)进度：0：滞后；1：正常；2：超前；
  const projectProgress = (index, val) => {
    let obj = {
      0: <div className="status-red">滞后 {val && `${val}%`}</div>,
      1: <div className="status-green animate">正常 {val && `${val}%`}</div>,
      2: <div className="status-green animate">超前 {val && `${val}%`}</div>,
    };
    return obj[index];
  };

  const tableRef = useRef();

  const columns = [
    {
      dataIndex: 'projectName',
      title: '项目名称',
    },
    {
      dataIndex: 'contractNumber',
      title: '合同编号',
    },
    {
      key: 'projectTime',
      title: '项目周期',
      render: (row) => {
        // 待处理
        return `${moment(row.projectBeginTime).format('YYYY-MM-DD')} ~ ${moment(
          row.projectEndTime,
        ).format('YYYY-MM-DD')}`;
      },
    },
    {
      dataIndex: 'proTeamNum',
      title: '项目团队',
      render: (val) => `${val}人`,
    },
    {
      dataIndex: 'projectManagerName',
      title: '项目经理',
    },
    {
      key: 'projectConsumProgress',
      title: '消耗进度',
      render: (row) => {
        let value = projectProgress(
          row.projectConsumProgress,
          row.projectChangeConsumProgressValue,
        );
        return (
          <div>
            <span>{value} </span>
            <Progress
              strokeWidth={6}
              percentage={+row.projectChangeConsumProgressValue}
              status={row.projectConsumProgress == 0 ? 'exception' : 'success'}
              showText={false}
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'projectStatus',
      title: '项目状态',
      render: (val) => projectStatus[val],
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '项目/合同编号',
      },
    },
    {
      name: 'projectStatus',
      type: 'Select',
      props: {
        placeholder: '状态',
        options: [
          {
            label: '未开始',
            value: 0,
          },
          {
            label: '正常',
            value: 1,
          },
          {
            label: '停止',
            value: 2,
          },
        ],
      },
    },
    {
      name: 'projectManager',
      type: 'Select',
      props: {
        placeholder: '项目经理',
        options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
  ];

  const expandedRowRender = ({ projectId, proServCatgList, projectStatus }) => {
    const statusObj = {
      0: '未完成',
      1: '已完成',
    };
    const columns = [
      { title: '工作包名称', dataIndex: 'servCatgName' },
      {
        title: '服务类目',
        dataIndex: 'servCatgName',
      },
      {
        title: '规划完成日期',
        key: 'servCatgBeginTime',
        render: (row) =>
          row.servCatgBeginTime &&
          `${row.servCatgBeginTime} ~ ${row.servCatgEndTime}`,
      },
      { title: '描述', dataIndex: 'servCatgRemark' },
      {
        title: '状态',
        dataIndex: 'servCatgStatus',
        render: (val) => {
          return statusObj[val];
        },
      },
    ];
    return (
      <Table
        rowKey={(row) => `${projectId}-${row.proServCatgId}`}
        columns={columns}
        dataSource={proServCatgList}
        expandable={{ childrenColumnName: 'childList' }}
        pagination={false}
      />
    );
  };

  const extra = () => (
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let value = tableRef.current.value;
        let payload = {
          key: value.key ?? '',
          projectManager: value.projectManager ?? '',
          projectStatus: value.projectStatus ?? '',
        };
        let url = `${baseURL}${exportUserPartinProject}${splitParamsFoGet(
          payload,
        )}`;
        downloadXlsx(url, '我的项目');
      }}
    >
      导出
    </Button>
  );

  return (
    <div>
      <TablePro
        params={{ muIdString: localStorage.getItem('muId'), enterMethod: 0 }}
        request="usercenter/getUsercenterProjectList"
        rowKey={'projectId'}
        actionRef={(ref) => (tableRef.current = ref)}
        extra={extra}
        columns={columns}
        searchCloumns={searchCloumns}
        data={usercenter.usercenterProjectList}
        expandable={{ expandedRowRender }}
      />
    </div>
  );
};

export default connect(({ usercenter, systemOrgani }) => {
  return {
    usercenter,
    systemOrgani,
  };
})(ServCategory);
