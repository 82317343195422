import { MessageBox, message } from 'kenshin';
import Sheet from './sheet';
import { useEffect } from 'react';
import {
  getTree,
  generateTree,
  setOsaIndexRemainWeight,
  hasDuplicateCodes,
  findToSelect,
  checkWeight,
} from './_tools';
import { useSelector } from 'umi';
import { isShowConfirmBtn } from './../../assessment/OSA/OSAsetting/_unitl';

const EditForExcel = ({
  visible,
  onCancel,
  onSave,
  title,
  dataSource,
  loading,
  isCalculateWeight = true, //是否校验权重
  isMonth = false,
}) => {
  const handleCancel = () => {
    window.luckysheet.exitEditMode();
    onCancel?.();
  };

  const handleOk = () => {
    window.luckysheet.exitEditMode();
    let errorObj = false;
    let codeTree = getTree(window.luckysheet.getAllSheets()[0].data);

    let emptWeight = codeTree.filter(
      (item) => item.weight == undefined || item.weight == null,
    );
    if (emptWeight?.length > 0 && isCalculateWeight) {
      message.error(`权重不能为空，请检查所有权重！`);
      return;
    }
    /** 校验code */
    errorObj = hasDuplicateCodes(codeTree);
    if (typeof errorObj == 'object') {
      message.error(
        `“${errorObj?.value}” 的编码为空或者编码重复${errorObj.code ?? ''}`,
      );
      findToSelect(errorObj?.value);
      return;
    }
    let tree = generateTree(codeTree);
    tree.forEach((item) => {
      item.weight =
        item.childList.reduce((pre, cur) => pre + cur.weight, 0) || item.weight;
    });
    setOsaIndexRemainWeight(tree);
    let countWeights = tree.reduce((pre, cur) => pre + cur.weight, 0);

    console.log('handleOk', codeTree);

    if (countWeights > 100 && isCalculateWeight) {
      message.error(
        `整体的权重超过100，请检查之后再试！权重总和：${countWeights}`,
      );
      return;
    } else {
    }
    errorObj = checkWeight(tree);
    if (typeof errorObj == 'object' && isCalculateWeight) {
      let value = errorObj.osaIndexItemName ?? errorObj.osaIndexCatgName;
      message.error(
        `“${value}” 的权重不足以分配，请检查编码以${errorObj.code}.xxx开头的指标权重`,
      );
      findToSelect(value);
      return;
    }
    // return;
    onSave?.(tree);
  };
  return (
    <MessageBox
      title={title ?? '绩效编辑'}
      width="100%"
      onCancel={handleCancel}
      style={{ top: 20 }}
      maskClosable={false}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      destroyOnClose
      visible={visible}
      okText="保存"
      onOk={handleOk}
      confirmLoading={loading}
    >
      <Sheet dataSource={dataSource} isShowRemark={!isCalculateWeight} />
    </MessageBox>
  );
};

export default EditForExcel;
