import Jsonform from '@/components/JsonForm';
import { MessageBox, Input, Button, message, InputNumber } from 'kenshin';
import { useEffect, useRef } from 'react';
import { useSelector, history, useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { useState } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import { deepClone, flat, debounce } from '@/_util/util';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { nextNodeIsMine } from '../../OSA/OSAsetting/_unitl';
import {
  exportemposaindexdata,
  queryosadirectbusiscoregrade,
} from '@/_serveice/performance/osa';
import PreviewIndicators from '../../../components/PreviewIndicators';
import { useReactive } from 'ahooks';
import {
  scoreTypeObj,
  scroKey,
  remarkKey,
  getAllTreeKeys,
  getTotalScore,
  setItemScore,
  setScore,
  dispatchType,
  getCurNodeOptions,
  compareTree,
  DIR,
  ITEM,
} from './_util';
import { updateosacatgscoredata } from '../../../../../_serveice/performance/osa';

const indicators = ({
  dispatchApi = dispatchType,
  // id参数名
  idKey = 'osaTemplateId',
  // 其他id，例如绩效id，默认使用模板id
  idValue, // 对应的id
  disabled, //禁用操作按钮
  superiorShow = true, // 上级是否有权限看到他人评分
  showBtn = true, //显示操作按钮
  onScoreChange, // 评分改变时调用
} = {}) => {
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);
  const curData = performance.curOsaSettingData.rows ?? {};
  const loading = useSelector(({ loading }) => loading.effects);

  const scoreState = useReactive({});
  const ramkState = useReactive({});

  /** 模板id */
  const osaTemplateId = idValue ?? history.location.query.osaTemplateId;

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 弹窗数据
  // const [editModalData, setEditModalData] = useState(null);
  // const [editVisible, setEditVisible] = useState(false);

  useEffect(() => {
    if (osaTemplateId) {
      getDirTree();
      dispatch({
        type: dispatchApi.GETTREE,
        payload: { [idKey]: osaTemplateId },
      });
    }
  }, [history.location.query.osaTemplateId, idValue]);

  // 获取目录树
  const getDirTree = () => {
    dispatch({
      type: dispatchApi.GETDIRTREE,
      payload: {
        [idKey]: osaTemplateId,
      },
    });
  };

  /** 不允许设置分数时应回显之前的数据 */
  const setOldValue = (value, item) => {
    let oldScoreKey = 'osaIndexItemSelfScore';
    let oldramkKey = 'osaIndexItemSelfScoreRemark';
    if (curData.curnNodeNumber == 'SCORE_THREE_NODE') {
      oldScoreKey = 'osaIndexItemDirectLeaderScore';
      oldramkKey = 'osaIndexItemBusiLeaderScoreRemark';
    }
    if (value.hasOwnProperty('score')) scoreState[item.id] = item[oldScoreKey];
    else ramkState[item.id] = item[oldramkKey];
  };

  /** 是否需要进行校验 */
  const hasCheck = () => {
    const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    if (
      curData.curnNodeNumber == 'SCORE_TWO_NODE' &&
      curData.osaMuId == cuEmpId
    )
      return curData.osaMuId != curData?.directLeader?.id;
    if (
      curData.curnNodeNumber == 'SCORE_THREE_NODE' &&
      cuEmpId == curData.directLeader?.id
    )
      return curData.busiLeader?.id != curData?.directLeader?.id;
    // if (status.busy) return true;
  };

  /** 设置目录具有差异的分数 */
  const setDirScore = (list) => {
    if (Array.isArray(list) && list.length > 0) {
      let dataType = 1;
      let osaIndexCatgList = list.map((item) => {
        return {
          indexCatgId: item.id,
          osaIndexCatgSelfScore: item.osaIndexCatgSelfScore || 0,
          osaIndexCatgDirectLeaderScore:
            item.osaIndexCatgDirectLeaderScore || 0,
          osaIndexCatgBusiLeaderScore: item.osaIndexCatgBusiLeaderScore || 0,
        };
      });
      let payload = { dataType, osaIndexCatgList };
      updateosacatgscoredata(payload);
    }
    // updateosacatgscoredata()
  };

  const scoreOrRemarkChangeTimer = useRef(null); // 防抖标识
  // 评分或者备注修改时调用
  const scoreOrRemarkChange = async (value, item, curnNode) => {
    let typeKey = {
      SCORE_TWO_NODE: 1,
      SCORE_THREE_NODE: 2,
      GRADE_NODE: 3,
    };
    let hasNext = true;
    if (hasCheck()) {
      hasNext = await queryosadirectbusiscoregrade({
        osaId: curData.osaId,
        type: typeKey[curData.curnNodeNumber],
      }).then((res) => !res.data);
    }
    if (!hasNext) {
      setOldValue(value, item);
      message.error('下个节点评分人已评分，当前不能修改评分或备注');
      return;
    }
    let scoreObj = {};
    let PromisePayloadArr = [];
    let beforeTree = deepClone(performance.osaIndicators);
    nextNodeIsMine(curData, curnNode, (defaultNode) => {
      let newValue = {};
      let node = defaultNode;
      // if (getCurNodeOptions(curData).self) node = 'SCORE_ONE_NODE';
      // if (getCurNodeOptions(curData).direct) node = 'SCORE_TWO_NODE';
      // if (getCurNodeOptions(curData).busi) node = 'SCORE_THREE_NODE';
      if (value.score !== undefined && value.score !== null) {
        newValue[scroKey[node]] = value.score;
      }
      if (value.remark !== undefined && value.remark !== null) {
        newValue[remarkKey[node]] = value.remark;
      }
      // });
      // let node = curData.curnNodeNumber
      Object.assign(item, newValue); // 合并数据
      // 保存表格修改后的分数跟备注
      dispatch({
        type: 'performance/save',
        payload: {
          osaIndicators: [...performance.osaIndicators],
        },
      });

      // 统一设置分数，方便后续获取总分
      setScore(performance.osaIndicators);

      // --------------------------------
      let payload = {
        osaIndexId: item.id,
        scoreType: scoreTypeObj[node],
        osaIndexScore: item[scroKey[node]] ?? 0,
        scoreRemark: item[remarkKey[node]] ?? '',
      };

      if (node == 'SCORE_ONE_NODE') payload.scoreType = 1;
      if (node == 'SCORE_TWO_NODE') payload.scoreType = 2;
      if (node == 'SCORE_THREE_NODE') payload.scoreType = 3;
      // 准备需要同步调用的评分接口的数据
      PromisePayloadArr.push(payload);
    });

    for (let i = 0; i < PromisePayloadArr.length; i++) {
      // 同步调用总分接口
      const payload = PromisePayloadArr[i];
      await dispatch({
        type: 'performance/osaindexscore',
        payload,
      });
    }

    const {
      osaIndexCatgSelfScore,
      osaIndexCatgDirectLeaderScore,
      osaIndexCatgBusiLeaderScore,
    } = getTotalScore(performance.osaIndicators); // 获取总分

    // 防抖清除定时器
    if (scoreOrRemarkChangeTimer.current) {
      clearTimeout(scoreOrRemarkChangeTimer.current);
      scoreOrRemarkChangeTimer.current = null;
    }
    // 更新总分只需要调用最后一次，只执行一次就行
    scoreOrRemarkChangeTimer.current = setTimeout(() => {
      // 总分的数据
      scoreObj = {
        osaSelfTotalScore: osaIndexCatgSelfScore,
        osaDirectTotalScore: osaIndexCatgDirectLeaderScore,
        osaBusiTotalScore: osaIndexCatgBusiLeaderScore,
      };
      let afterTree = deepClone(performance.osaIndicators);
      let diffDirScoreList = compareTree(afterTree, beforeTree);
      setDirScore(diffDirScoreList);
      dispatch({
        type: 'performance/updateosaemployeedata',
        payload: {
          ...curData,
          ...scoreObj,
        },
      });

      onScoreChange?.(scoreObj);
    }, 1000);
  };

  // 评分的render
  const CreateScoreRender = ({ catgKey, scoreKey, curNode, nodeKey = [] }) => {
    return (val, row) => {
      if (
        nodeKey.includes(curData.curnNodeNumber) &&
        row.type == ITEM &&
        showBtn
      ) {
        let nodeHas = getCurNodeOptions(curData);
        // 上级评，且包含自评，则是自己修改评分
        if (curNode == 'SCORE_ONE_NODE' && !nodeHas.self)
          return val ?? row[catgKey] ?? 0;
        if (curNode == 'SCORE_TWO_NODE' && !nodeHas.direct)
          return val ?? row[catgKey] ?? 0;
        if (curNode == 'SCORE_THREE_NODE' && !nodeHas.busy)
          return val ?? row[catgKey] ?? 0;

        let max = row?.weight ?? 100;
        return (
          <InputNumber
            max={max}
            min={0}
            value={scoreState[`${row.id}-${curNode}`] ?? row[scoreKey] ?? 0}
            stepType="inside"
            onChange={(val) => (scoreState[`${row.id}-${curNode}`] = val)}
            onBlur={(e) => {
              scoreOrRemarkChange(
                { score: +e.target.value > max ? max : +e.target.value },
                row,
                curNode,
              );
            }}
          />
        );
      }
      // return val ?? row[catgKey] ?? 0;
      return +(+val).toFixed(2) || +(+row[catgKey]).toFixed(2) || '-';
    };
  };

  // 备注的render
  const CreateScoreRemarkRender = ({ nodeKey = [], curNode } = {}) => {
    return (val, row) => {
      if (
        nodeKey.includes(curData.curnNodeNumber) &&
        row.type == ITEM &&
        showBtn
      ) {
        let nodeHas = getCurNodeOptions(curData);
        if (curNode == 'SCORE_ONE_NODE' && !nodeHas.self) return val ?? '-';
        if (curNode == 'SCORE_TWO_NODE' && !nodeHas.direct) return val ?? '-';
        if (curNode == 'SCORE_THREE_NODE' && !nodeHas.busy) return val ?? '-';
        return (
          <Input
            value={ramkState[`${row.id}-${curNode}`] ?? val}
            style={{ width: 120, minWidth: 120 }}
            onChange={(e) =>
              (ramkState[`${row.id}-${curNode}`] = e.target.value)
            }
            onBlur={(e) =>
              scoreOrRemarkChange({ remark: e.target.value }, row, curNode)
            }
          />
        );
      }
      return val ?? '-';
    };
  };

  // 自评、业务负责人评价控制
  const getOtherScoreCloumns = () => {
    let self = [];
    let busi = [];
    if (superiorShow && curData.osaWeekType != 1) {
      self = [
        {
          dataIndex: 'osaIndexItemSelfScore',
          title: '评分(自评)',
          width: 120,
          render: CreateScoreRender({
            scoreKey: 'osaIndexItemSelfScore',
            catgKey: 'osaIndexCatgSelfScore',
            curNode: 'SCORE_ONE_NODE',
            nodeKey: ['SCORE_ONE_NODE', 'SCORE_TWO_NODE'],
          }),
        },
        {
          dataIndex: 'osaIndexItemSelfScoreRemark',
          title: '备注(自评)',
          width: 150,
          render: CreateScoreRemarkRender({
            curNode: 'SCORE_ONE_NODE',
            row: 'osaIndexItemSelfScoreRemark',
            nodeKey: ['SCORE_ONE_NODE', 'SCORE_TWO_NODE'],
          }),
        },
      ];
      busi = [
        {
          dataIndex: 'osaIndexItemBusiLeaderScore',
          title: '评分(业务负责人)',
          width: 150,
          render: CreateScoreRender({
            scoreKey: 'osaIndexItemBusiLeaderScore',
            catgKey: 'osaIndexCatgBusiLeaderScore',
            curNode: 'SCORE_THREE_NODE',
            nodeKey: ['SCORE_THREE_NODE', 'GRADE_NODE'],
          }),
        },
        {
          dataIndex: 'osaIndexItemBusiLeaderScoreRemark',
          title: '备注(业务负责人)',
          width: 150,
          render: CreateScoreRemarkRender({
            curNode: 'SCORE_THREE_NODE',
            remarkKey: 'osaIndexItemBusiLeaderScoreRemark',
            nodeKey: ['SCORE_THREE_NODE', 'GRADE_NODE'],
          }),
        },
      ];
    }
    return { busi, self };
  };

  const actualChange = async (value, item) => {
    let typeKey = {
      SCORE_TWO_NODE: 1,
      SCORE_THREE_NODE: 2,
      GRADE_NODE: 3,
    };
    let hasNext = true;
    if (hasCheck()) {
      hasNext = await queryosadirectbusiscoregrade({
        osaId: curData.osaId,
        type: typeKey[curData.curnNodeNumber],
      }).then((res) => !res.data);
    }
    if (!hasNext) {
      message.error('下个节点评分人已评分，当前不能修改实际值');
      return;
    }
    Object.assign(item, value); // 合并数据
    dispatch({
      type: 'performance/save',
      payload: {
        osaIndicators: [...performance.osaIndicators],
      },
    });
    dispatch({
      type: 'performance/updateosaempindexactucalvalue',
      payload: {
        ...value,
        osaIndexActualValue: value.osaIndexItemActualValue,
        osaIndexId: item.id,
      },
    });
  };
  const getActualCloumns = () => {
    return [
      {
        dataIndex: 'osaIndexItemActualValue',
        title: '实际值',
        width: 150,
        render(val, row) {
          if (
            curData.osaMuEncryptId == localStorage.getItem('muId') &&
            [
              'SCORE_ONE_NODE',
              'SCORE_TWO_NODE',
              'SUMMER_WEEK_PLAN_ONE_NODE',
            ].includes(curData.curnNodeNumber) &&
            row.type == ITEM &&
            row.osaIndexItemModel == 1 &&
            showBtn
          ) {
            if (
              (curData.curnNodeNumber == 'SCORE_WEEK_PLAN_ONE_NODE' ||
                curData.curnNodeNumber == 'SCORE_TWO_NODE') &&
              !getCurNodeOptions(curData).self
            )
              return val ?? '-';
            return (
              <Input
                defaultValue={val}
                style={{ width: 120, minWidth: 120 }}
                onBlur={(e) =>
                  actualChange({ osaIndexItemActualValue: e.target.value }, row)
                }
              />
            );
          }
          return val ?? '-';
        },
      },
    ];
  };

  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      fixed: 'left',
      width: 300,
      render: (row) =>
        `${row.code} ${
          row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        }`,
      // render: (row) => {
      //   return (
      //     <EllipsisTooltip
      //       value={`${row.code} ${
      //         row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
      //       }`}
      //       width={300}
      //     />
      //   );
      // },
    },
    // {
    //   dataIndex: 'remark',
    //   title: '指标描述',
    //   width: 300,
    //   // render: (remark) => {
    //   //   return <EllipsisTooltip value={remark} width={300} />;
    //   // },
    // },
    {
      dataIndex: 'weight',
      title: '权重',
      fixed: 'left',
      width: 120,
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: 120,
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    {
      dataIndex: 'osaIndexItemGoalValueUnit',
      title: '单位',
      width: 120,
    },
    {
      dataIndex: 'osaIndexItemGoalValue',
      title: '目标值',
      width: 120,
    },
    ...getActualCloumns(),
    ...getOtherScoreCloumns().self,
    {
      dataIndex: 'osaIndexItemDirectLeaderScore',
      title: '评分(上级)',
      width: 120,
      render: CreateScoreRender({
        scoreKey: 'osaIndexItemDirectLeaderScore',
        catgKey: 'osaIndexCatgDirectLeaderScore',
        curNode: 'SCORE_TWO_NODE',
        nodeKey: [
          'SCORE_TWO_NODE',
          'SCORE_WEEK_PLAN_ONE_NODE',
          'SCORE_THREE_NODE',
        ],
      }),
    },
    {
      dataIndex: 'osaIndexItemDirectLeaderScoreRemark',
      title: '备注(上级)',
      width: 120,
      render: CreateScoreRemarkRender({
        curNode: 'SCORE_TWO_NODE',
        remarkKey: 'osaIndexItemDirectLeaderScoreRemark',
        nodeKey: [
          'SCORE_TWO_NODE',
          'SCORE_WEEK_PLAN_ONE_NODE',
          'SCORE_THREE_NODE',
        ],
      }),
    },
    ...getOtherScoreCloumns().busi,
  ];
  /** 一键评分 */
  const onceScore = async () => {
    let scoreTypeKey = {
      SCORE_WEEK_PLAN_ONE_NODE: 2,
      SCORE_TWO_NODE: 2,
      SCORE_THREE_NODE: 3,
    };
    let scoreType = scoreTypeKey[curData.curnNodeNumber];
    if (!scoreType) return;
    let payload = {
      osaId: curData.osaId,
      directBusiEqual:
        curData.directLeader?.id == curData.busiLeader?.id ? 1 : 2,
      osaType: curData.osaWeekType,
      scoreType,
    };
    await dispatch({
      type: 'performance/osaindexscorebatch',
      payload,
    });
    let list = await dispatch({
      type: dispatchApi.GETTREE,
    });

    /** 清空响应式 */
    for (const key in scoreState) {
      if (Object.hasOwnProperty.call(scoreState, key)) {
        scoreState[key] = undefined;
      }
    }
    const {
      osaIndexCatgSelfScore,
      osaIndexCatgDirectLeaderScore,
      osaIndexCatgBusiLeaderScore,
    } = getTotalScore(list); // 获取总分
    let scoreObj = {
      osaSelfTotalScore: osaIndexCatgSelfScore,
      osaDirectTotalScore: osaIndexCatgDirectLeaderScore,
      osaBusiTotalScore: osaIndexCatgBusiLeaderScore,
    };
    dispatch({
      type: 'performance/updateosaemployeedata',
      payload: {
        ...curData,
        ...scoreObj,
      },
    });

    onScoreChange?.(scoreObj);
  };

  const getScoreText = () => {
    let text = '一键评分';
    if (curData.curnNodeNumber == 'SCORE_WEEK_PLAN_ONE_NODE') {
      text = '一键满分';
    }
    return text;
  };

  const extra = () => {
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            // exportemposaindexdata();
            // downloadXlsx("https://test.wdp.waper4.com/v1/waper/erp/pc/osa/employee/exportemposaindexdata?osaId=1", '列表');
            setExpandedRowKeys(
              expandedRowKeys?.length == 0
                ? getAllTreeKeys(performance.osaIndicators)
                : [],
            );
          }}
        >
          {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
        </Button>
        {[
          'SCORE_WEEK_PLAN_ONE_NODE',
          'SCORE_TWO_NODE',
          'SCORE_THREE_NODE',
        ].includes(curData.curnNodeNumber) && (
          <Button type="primary" onClick={onceScore}>
            {getScoreText()}
          </Button>
        )}
        {curData && <PreviewIndicators row={curData} type="primary" />}
      </>
    );
  };

  return (
    <>
      <TablePro
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        extra={extra}
        key={performance.osaIndicators}
        curColumns
        columnsOptions={false}
        scrollX
        scrollY={700}
        loading={loading[dispatchApi.GETTREE]}
        childrenColumnName="childList"
        dataSource={performance.osaIndicators}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />
    </>
  );
};

export default indicators;
