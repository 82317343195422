import { connect, history } from 'umi';
import Search from './search';
import { Radio, Button, Checkbox, Tabs } from 'kenshin';
import AnalyseCharts from '@/components/AnalyseCharts';
import { useEffect, useRef, useState } from 'react';
import {
  statTypeOptions,
  dataTypeOptions,
  chartTransform,
  createTableHeader,
  createDataSource,
  DATATYPEKEY,
  INFOKEY,
  getTableColumns,
} from './staticData';
import TablePro from '@/components/TablePro';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportosaquarterstatdata } from '@/_serveice/dataAnalyse';
import styles from './styles.less';
import {
  exportattendancestatdata,
  getcompanymaindeptlist,
} from '../../../_serveice/dataAnalyse';
import { useReactive } from 'ahooks';

const defaultOsaTime = () => {
  return [
    moment().subtract(3, 'quarter').startOf('quarter'),
    moment().endOf('quarter'),
  ];
};

export const getDefaultTime = (str) => {
  const [amount, unit] = str.split('-');
  let startTime = moment();
  const start = startTime.subtract(amount, unit).startOf(unit);
  const end = moment().endOf(unit);
  return [start, end];
};

const dataRule = (arr, key) => {
  const unitobj = {
    userNum: '人',
    userCount: '次',
    days: '天',
  };
  if (!arr[0]) {
    return {};
  }
  const xData = arr.map((item) => `${item.statTime}\n${item.statIndexName}`);
  const getDataForkey = (index) =>
    arr.map((item) => {
      return item?.attendanceStatTypeList?.[index]?.[key] || 0;
    });
  const colorList = [
    '#668ed6',
    '#60acfc',
    '#23c2db',
    '#5bc49f',
    '#9cdc82',
    '#32d3eb',
    '#3fcec7',
    '#b8ee4e',
  ];
  const data = arr[0]?.attendanceStatTypeList.map((item, index) => {
    return {
      name: item.statTypeName,
      type: 'bar',
      color: colorList[index],
      data: getDataForkey(index),
    };
  });
  return {
    xData,
    data,
    unit: unitobj[key],
  };
};

const getTabsOptions = (filterName) => {
  return [
    { label: '人数', value: 'userNum' },
    { label: '人次', value: 'userCount' },
    { label: '时长', value: 'days' },
  ].filter((item) => item.value != filterName);
};

const OsaAnalyse = ({ dataAnalyse, dispatch, loadingEf }) => {
  const payload = useRef({
    attendanceType: -1,
    groupByPeople: 1,
    groupByTime: 1,
    queryBeginTime: getDefaultTime('6-month')[0].format('YYYY-MM-DD'),
    queryEndTime: getDefaultTime('6-month')[1].format('YYYY-MM-DD'),
  });

  const [deptList, setDepList] = useState([]);
  const state = useReactive({
    deptIdList: [],
    exportLoading: false,
    chartKey: 'userNum',
    chartTabsOptions: getTabsOptions(),
  });

  // 生命周期
  useEffect(() => {
    // 核心部门列表
    getcompanymaindeptlist().then((res) => {
      setDepList(
        res.data?.map((item) => ({ label: item.value, value: item.id })),
      );
      state.deptIdList = res.data?.map((item) => item.id);
      getAnalyseData();
    });
  }, []);

  /** 获取所有数据 */
  const getAnalyseData = () => {
    dispatch({
      type: 'dataAnalyse/findattendancestatdata',
      payload: {
        ...payload.current,
        deptIdList: state.deptIdList,
        time: undefined,
      },
    });
  };

  let columns = getTableColumns(dataAnalyse.attendanceTheader);
  const searchChange = (value) => {
    payload.current = {
      ...payload.current,
      ...value,
    };
    onSearch();
  };

  const onSearch = () => {
    if (payload.current.groupByPeople == 3) {
      if (state.chartKey == 'userNum') state.chartKey = 'userCount';
      state.chartTabsOptions = getTabsOptions('userNum');
    } else state.chartTabsOptions = getTabsOptions();
    getAnalyseData();
  };

  /** 导出 */
  const handleExport = () => {
    let params = {
      ...payload.current,
      deptIdList: state.deptIdList,
      time: undefined,
    };
    let url = `${baseURL}${exportattendancestatdata}`;
    state.exportLoading = true;
    downloadXlsx(
      url,
      '考勤分析',
      'post',
      params,
      () => (state.exportLoading = false),
    );
  };

  return (
    <div className={styles.osaAnalyse}>
      <Search onChange={searchChange} />
      <Button onClick={onSearch} className="fix-r" type="primary">
        查询
      </Button>
      <Checkbox.Group //  部门
        options={deptList || []}
        onChange={(e) => (state.deptIdList = e)}
        value={state.deptIdList}
      />
      <Tabs
        activeKey={state.chartKey}
        onChange={(v) => (state.chartKey = v)}
        type="mini"
      >
        {state.chartTabsOptions.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.value} />
        ))}
      </Tabs>
      <AnalyseCharts
        {...dataRule(dataAnalyse.attendanceChat, state.chartKey)}
      />
      <Button
        type="primary"
        key="noKeys"
        style={{ marginLeft: 10, height: 40 }}
        onClick={handleExport}
        loading={state.exportLoading}
      >
        导出
      </Button>
      <TablePro
        columns={columns}
        loading={loadingEf['dataAnalyse/findattendancestatdata']}
        curColumns
        stripe
        columnsOptions={false}
        bordered
        scrollX
        dataSource={dataAnalyse.attendanceData}
        rowKey={(item, index) =>
          [item.deptId, item.statTime, index].filter(Boolean).join('-')
        }
      />
    </div>
  );
};

export default connect(({ dataAnalyse, loading, system }) => ({
  dataAnalyse,
  system,
  loadingEf: loading.effects,
}))(OsaAnalyse);
