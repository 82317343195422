import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, MessageBox } from 'kenshin';
import { getMeun } from '@/_serveice/menu';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import moment from 'moment';
import RoleEdit from './components/RoleEdit';
import ModalFormPro from '@/components/ModalFormPro';
import PowerTree from './components/PowerTree';

const menuConfig = ({ dispatch, loading, system }) => {
  let modalRef = useRef();
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'system/deleteRole',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const columns = [
    {
      title: '角色 ID',
      dataIndex: 'roleId',
      key: 'roleId',
    },
    {
      title: '排序',
      dataIndex: 'roleSort',
      key: 'roleSort',
    },
    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName',
    },
    {
      title: '角色标识',
      dataIndex: 'roleKey',
      key: 'roleKey',
    },
    {
      title: '角色类型',
      dataIndex: 'roleType',
      key: 'roleType',
      render: (type) => (type == 1 ? '系统预设' : '用户定义'),
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.hasbtnList.includes(btnKeys.edit) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                编辑
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.delete) && row.roleType != 1 && (
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => delRole({ roleId: row.roleId })}
              >
                删除
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'roleName',
      type: 'Input',
      props: {
        placeholder: '角色名称',
      },
    },
    {
      name: 'roleType',
      type: 'Select',
      props: {
        placeholder: '角色类型',
        allowClear: true,
        options: [
          { label: '系统预设', value: 1 },
          { label: '用户定义', value: 2 },
        ],
      },
    },
  ];

  const extra = (
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增角色
    </Button>
  );

  const editModalSave = (value) => {
    let hasEdit = true;
    let sortChange = 0;
    if (editModalData?.roleSort != value.roleSort) {
      sortChange = 1;
    }
    let params = {
      ...editModalData,
      ...value,
    };
    if (!editModalData?.roleId) {
      hasEdit = false;
      // 新增
      params = {
        sortChange,
        ...value,
      };
    }
    dispatch({
      type: 'system/editRole',
      hasEdit,
      payload: params,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const edtaFormItem = [
    {
      label: '角色名称',
      name: 'roleName',
      type: 'Input',
      required: '请输入角色名称',
      props: {
        placeholder: '请输入角色名称',
      },
    },
    {
      label: '角色标识',
      name: 'roleKey',
      type: 'Input',
      required: '请输入角色标识',
      props: {
        placeholder: '请输入角色标识',
        disabled: editModalData?.roleId,
      },
    },
    {
      label: '角色排序',
      name: 'roleSort',
      type: 'InputNumber',
      required: '请输入角色排序',
      props: {
        placeholder: '请输入角色排序',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: '角色类型',
      name: 'roleType',
      type: 'Select',
      required: '请输入角色类型',
      props: {
        placeholder: '请输入角色类型',
        disabled: editModalData?.roleId,
        options: [
          { label: '系统预设', value: 1 },
          { value: 2, label: '用户定义' },
        ],
      },
    },
    {
      label: '菜单权限',
      name: 'menuPermIdList',
      render: () => <PowerTree />,
      props: {},
    },
    {
      label: '角色备注',
      name: 'roleRemark',
      type: 'TextArea',
      props: {
        placeholder: '请输入角色备注',
      },
    },
  ];

  return (
    <div>
      <TablePro
        request="system/getroleList"
        version="system"
        rowKey={'roleId'}
        columns={columns}
        searchCloumns={searchCloumns}
        dataSource={system.roleList.datas}
        data={system.roleList}
        extra={extra}
      />
      <ModalFormPro
        title={editModalData?.roleId ? '编辑' : '新增'}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        ref={modalRef}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
      {/* <RoleEdit
        visible={editModalVisible}
        data={editModalData}
        // loading={loading['system/editRole']}
        dispatch={dispatch}
        onCancel={() => {
          setEditModalData({});
          setEditModalVisible(false);
        }}
      /> */}
    </div>
  );
};

export default connect(({ system, loading }) => {
  return {
    system,
    loading: loading.effects,
  };
})(menuConfig);
