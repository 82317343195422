import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';

const menuConfig = ({ dispatch, loading, system, user }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'system/deleteDictype',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = (value) => {
    let payload = { sysDictypeData: { ...value } };
    let hasEdit = false;
    if (editModalData) {
      hasEdit = true;
    }
    dispatch({
      type: 'system/editDictype',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const columns = [
    {
      title: '编码',
      dataIndex: 'dt_id',
      key: 'dt_id',
    },
    {
      title: '名称',
      dataIndex: 'dt_name',
      key: 'dt_name',
    },
    {
      title: '状态',
      dataIndex: 'dt_status',
      key: 'dt_status',
      render: (type) => (
        <Tag type={type == 1 ? 'success' : 'danger'}>
          {type == 1 ? '启用' : '禁用'}
        </Tag>
      ),
    },
    {
      title: '备注',
      dataIndex: 'dt_remarks',
      key: 'dt_remarks',
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.hasbtnList.includes(btnKeys.edit) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                编辑
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.delete) && (
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => delRole({ dt_id: row.dt_id })}
              >
                删除
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '编码/名称/描述',
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '编码',
      name: 'dt_id',
      type: 'InputNumber',
      required: '请输入编码',
      props: {
        placeholder: '请输入编码',
        disabled: (row) => !!row.dt_id,
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: '名称',
      name: 'dt_name',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
    {
      label: '状态',
      name: 'dt_status',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        options: [
          { label: '启用', value: 1 },
          { value: 0, label: '禁用' },
        ],
      },
    },
    {
      label: '备注',
      name: 'dt_remarks',
      type: 'InputTextArea',
      props: {
        placeholder: '请输入备注',
      },
    },
  ];

  const extra = (
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增字典类型
    </Button>
  );

  return (
    <>
      <TablePro
        request="system/getDictypeList"
        version="system"
        rowKey={'dt_id'}
        columns={columns}
        searchCloumns={searchCloumns}
        data={system.dictypeList}
        extra={extra}
      />

      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </>
  );
};

menuConfig.title = '菜单配置';

export default connect(({ system, loading, user }) => {
  return {
    user,
    system,
    loading: loading.effects,
  };
})(menuConfig);
