import request from '@/_util/require';
let version = 'v1';

// 分页查询用户参与项目列表
export async function getUsercenterList(options) {
  return request(
    `/${version}/waper/erp/pc/usercenter/getuserpartinprojectpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 查询用户所在部门的上级列表
export async function getDeptmanagerByMuid(params) {
  return request(`/${version}/waper/erp/pc/employee/getdeptmanagerlistbymuid`, {
    method: 'GET',
    params,
  });
}

// 查询员工项目服务类目工时列表
export async function getProServcatgHourList(options) {
  return request(
    `/${version}/waper/erp/pc/usercenter/getemporoservcatgworkhourlist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 查询
export async function getEmpCurTimesLotList(params) {
  return request(
    `/${version}/waper/erp/pc/usercenter/getemprptcurndaytimeslotlist`,
    {
      method: 'GET',
      params,
    },
  );
}

// 员工上报工作
export async function reportEmpWork(options) {
  return request(`/${version}/waper/erp/pc/usercenter/reportempwork`, {
    method: 'POST',
    data: options,
  });
}
// 分页查询员工上报工作
export async function getEmpWorkRptPage(options) {
  return request(`/${version}/waper/erp/pc/usercenter/getempworkrptpage`, {
    method: 'POST',
    data: options,
  });
}
// 首页查询员工工作上报列表
export async function getEmpWorkRptList(options) {
  return request(`/${version}/waper/erp/pc/usercenter/getempworkrptlistindex`, {
    method: 'POST',
    data: options,
  });
}

// 查询员工工作台信息
export async function getEmpHomeWork(params) {
  return request(`/${version}/waper/erp/pc/usercenter/getstaffworkbench`, {
    method: 'GET',
    params,
  });
}
// 查询管理层工作台信息
export async function getMangeHomeWork(params) {
  return request(`/${version}/waper/erp/pc/usercenter/getmanageworkbench`, {
    method: 'GET',
    params,
  });
}
// 查询财务工作台信息
export async function getFinaceHomeWork(params) {
  return request(`/${version}/waper/erp/pc/usercenter/getfinanceworkbench`, {
    method: 'GET',
    params,
  });
}
// 查询财务角色项目盈利列表
export async function getFinaceHomeTable(params) {
  return request(
    `/${version}/waper/erp/pc/usercenter/getfinanceproprofitlist`,
    {
      method: 'GET',
      params,
    },
  );
}
// 查询财务角色项目执行统计
export async function getFinanceProdCharts(params) {
  return request(`/${version}/waper/erp/pc/usercenter/getfinanceprodostat`, {
    method: 'GET',
    params,
  });
}
// 查询财务角色项目盈利列表统计
export async function getFinanceProdBar(options) {
  return request(
    `/${version}/waper/erp/pc/usercenter/getfinanceproprofitliststat`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 分页查询部门员工工作上报列表
export async function getDeptRptList(options) {
  return request(`/${version}/waper/erp/pc/usercenter/getdeptemprptlistpage`, {
    method: 'POST',
    data: options,
  });
}
// 查询员工工作上报明细
export async function getReportEmpWorkDetail(params) {
  return request(`/${version}/waper/erp/pc/usercenter/getreportempworkdetail`, {
    method: 'GET',
    params,
  });
}
// 分页查询部门员工工作上报列表
export async function updateReportEmpWorkDetail(options) {
  return request(
    `/${version}/waper/erp/pc/usercenter/updatereportempworkdetail`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 分页查询上报任务列表
export async function getRptTaskPage(options) {
  return request(`/${version}/waper/erp/pc/task/getrpttaskpage`, {
    method: 'POST',
    data: options,
  });
}

// 导出用户参与项目列表
export const exportUserPartinProject = `/${version}/waper/erp/pc/usercenter/exportuserpartinproject`;

// 导出工作记录
export const exportEmpRptRcd = `/${version}/waper/erp/pc/usercenter/exportemprptrcd`;

// 导出每日上报
export const exportRptDayTask = `/${version}/waper/erp/pc/task/exportrptdaytask`;

// 导出部门员工上报
export const exportDeptEmpRptRcd = `/${version}/waper/erp/pc/usercenter/exportdeptemprptrcd`;

/** 删除上报明细 */
export async function deletereportworkdetail(params) {
  return request(`/${version}/waper/erp/pc/usercenter/deletereportworkdetail`, {
    method: 'GET',
    params,
  });
}

// 分页查询员工上报列表
export async function getemprptlistpage(options) {
  return request(`/${version}/waper/erp/pc/usercenter/getemprptlistpage`, {
    method: 'POST',
    data: options,
  });
}
// 分页查询员工上报列表
export async function moveemprptdata(options) {
  return request(`/${version}/waper/erp/pc/usercenter/moveemprptdata`, {
    method: 'POST',
    data: options,
  });
}
// 待办事项统计
export async function todoitemstat(options) {
  return request(`/${version}/waper/erp/pc/process/todocomplete/todoitemstat`, {
    method: 'POST',
    data: options,
  });
}
// 待办事项统计
export async function gettodoitemnewpage(options) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/gettodoitemnewpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 已办事项统计
export async function getcompleteitemnewpage(options) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/getcompleteitemnewpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 已办事项统计
export async function completeitemstat(options) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/completeitemstat`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 查询指定年份的休息日和节假日
export async function getholidaybyyear(params) {
  return request(`/${version}/waper/erp/pc/employee/getholidaybyyear`, {
    method: 'GET',
    params,
  });
}
