import { Redirect } from 'umi';

const NoFund = () => {
  return (
    // <>404</>
    <Redirect to="/" />
  );
};
NoFund.title = '404';
export default NoFund;
