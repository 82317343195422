import React, { useState, useEffect, useImperativeHandle } from 'react';
import {
  MessageBox,
  Form,
  Radio,
  Input,
  Select,
  Button,
  message,
  Row,
} from 'kenshin';
import UIType from '@/_util/UIType';
import { useSelector } from 'umi';
import JsonForm from '../JsonForm';

/**
 *
 * @param {Object} data 请求的父级参数
 * @param {Function} onSave 点击确认时执行
 * @param {Function} onCancel 点击取消时执行
 * @param {Array} edtaFormItem FormItem渲染数组
 * @param {Object} formData 不通过请求数据直接渲染
 * @returns
 */

const ModalFormPro = React.forwardRef(
  (
    {
      data,
      visible,
      onCancel,
      onSave,
      dispatch,
      // FormItem渲染数组
      edtaFormItem,
      // 按钮加载状态
      btnLoading,
      // 可操作的数据
      formData,
      // 请求的dva type
      request,
      // 表单属性
      formProps,
      // 是否直接设置传入的值
      isSetValue = true,
      submitDisabled,
      // 其他属性，作为模态窗的属性
      ...props
    },
    ref,
  ) => {
    const FormProps5086669 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ...formProps,
    };

    const [tabLoading, setTabLoading] = useState('');
    const loadingEffects = useSelector((state) => state.loading.effects);

    useEffect(() => {
      // 匹配保存事件中loading按钮对应的dva状态
      let template = onSave?.toString();
      if (template) {
        let typeRule = /type:\s*['|\"][A-Za-z0-9]+\/[A-Za-z0-9]+['|\"]/g;
        let loadingRule = /[A-Za-z0-9]+\/[A-Za-z0-9]+/;
        let loadingStr = template.match(typeRule)?.[0].match(loadingRule);
        setTabLoading(loadingStr?.[0]);
      }
    }, []);

    const [formRef] = Form.useForm();

    const [reqFormData, setFormData] = useState({});
    const [resetKey, setResetKey] = useState(0);

    useEffect(() => {
      if (visible) {
        // 是否通过请求去获取数据
        if (request) {
          dispatch(request).then((res) => {
            formRef.setFieldsValue({ ...res });
            setFormData(res);
          });
        }
        // 通过formData直接回显数据
        if (formData && isSetValue) {
          let data = {};
          Object.keys(formData).map((item) => {
            if (formData[item]) {
              data[item] = formData[item];
            }
          });
          formRef.setFieldsValue({ ...data });
        }
        setResetKey(+new Date());
        return () => {
          formRef.resetFields();
          formRef.setFieldsValue({});
        };
      }
    }, [visible]);

    useImperativeHandle(
      ref,
      () => {
        return {
          form: formRef,
        };
      },
      [],
    );

    const handleOk = () => {
      formRef.validateFields().then((value, err) => {
        if (err) {
          return;
        }
        setFormData({});
        onSave?.(value, { ...formData });
      });
    };

    const formItemRender = (arr) => {
      return arr.map((item, index) => {
        if (item.formItemRender) {
          return item.formItemRender?.();
        }
        let formItemProps = {
          name: item.name,
          label: item.label,
          help: item.help,
          extra: item.extra,
          tooltip: item.tooltip,
          ...(item.formItemProps ?? {}),
        };
        if (item.required) {
          formItemProps.rules = [{ required: true, message: item.required }];
        }

        if (typeof item?.props?.disabled == 'function') {
          item.props.disabled = item.props?.disabled?.(formData || {});
        }
        let props = item?.props;
        if (typeof item?.props == 'function') {
          props = item?.props(reqFormData);
        }

        const Element =
          item?.render?.() ??
          React.createElement(UIType[item.type] || 'div', props);

        return (
          <Form.Item key={index + resetKey} {...formItemProps}>
            {Element}
          </Form.Item>
        );
      });
    };

    const handleCancel = () => {
      setFormData({});
      onCancel?.();
    };
    return (
      <MessageBox
        title={data || formData ? '编辑' : '新增'}
        onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
        destroyOnClose
        visible={visible}
        onOk={handleOk}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            取消
          </Button>,
          ...(onSave
            ? [
                <Button
                  key="ok"
                  type="primary"
                  loading={btnLoading || loadingEffects[tabLoading]}
                  onClick={handleOk}
                  disabled={submitDisabled}
                >
                  确定
                </Button>,
              ]
            : []),
        ]}
        {...props}
      >
        <Form key={resetKey} form={formRef} {...FormProps5086669}>
          {formItemRender(edtaFormItem)}
        </Form>
      </MessageBox>
    );
  },
);

export default ModalFormPro;
