import { useEffect, useState, useMemo, useRef } from 'react';
import { Dropdown, MessageBox, Tree, message, Input } from 'kenshin';
import Select from '@/_util/components/Select';
import '@/components/TreeData/index.less';
import { connect } from 'umi';
import { useMemoizedFn, useUpdateEffect } from 'ahooks';
import { debounce, deepClone } from '@/_util';
import { selectPinyinFilter } from '@/_util/componentsUtil';
import EllipsisTooltip from '@/components/EllipsisTooltip';

const findTree = (fildTree, id = 3) => {
  for (let i = 0; i < fildTree?.length; i++) {
    if (fildTree[i].deptId == id) {
      return {
        pre: fildTree[i - 1],
        cur: fildTree[i],
        next: fildTree[i + 1],
      };
    }
    if (fildTree[i].child) {
      let resData = findTree(fildTree[i].child, id);
      if (resData) {
        return resData;
      }
    }
  }
};

const TreeData = (props) => {
  const { dispatch, project, onChange } = props;
  const curProjectId = useRef();

  const boxRef = useRef();

  // 基础数据，用于搜索
  const baseTreeData = useRef([]);
  // 当前树对应的项目id
  const [treeData, setTreeData] = useState([]);

  const [selectedKeys, setSelectedKeys] = useState([]);

  // 结构转换给树组件渲染
  const transformTreeData = useMemoizedFn((arr) => {
    return arr?.map((item) => {
      let children = null;
      if (item.childList) {
        children = transformTreeData(item.childList);
      }
      return {
        children,
        title: (
          <EllipsisTooltip
            value={`${item.servCatgCode} ${item.servCatgName}`}
            width={boxRef.current.offsetWidth - 60}
            placement="right"
            trigger="click"
          />
        ),
        key: item.proServCatgId,
        ...item,
      };
    });
  });

  // 搜索过滤树
  const filterTree = (arr, value) => {
    return arr.filter((item) => {
      if (item.childList) {
        let children = filterTree(item.children, value);
        item.children = children;
        if (children?.length > 0) {
          return true;
        }
      }
      return (
        item.title?.props.value.includes(value) ||
        selectPinyinFilter(item.title?.props.value, value)
      );
      // return true
    });
  };
  // 搜索事件
  const searchInput = (e) => {
    const { value } = e.target;
    setTreeData(filterTree(deepClone(baseTreeData.current), value));
  };

  // 选择树节点后的回调
  const handleChange = (keys, row) => {
    setSelectedKeys(keys);
    let data =
      keys?.length > 0 ? row?.node : { projectId: curProjectId.current };
    onChange?.(keys, data);
  };

  const projectSelectChange = (projectId) => {
    curProjectId.current = projectId;
    handleChange([]);
    if (!projectId) {
      setTreeData([]);
      return;
    }
    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId,
      },
    }).then((data) => {
      baseTreeData.current = transformTreeData(data);
      setTreeData(transformTreeData(data));
    });
  };

  return (
    <div className="treeData" ref={boxRef} style={{ overflow: 'hidden' }}>
      <Select
        style={{ width: '100%', marginBottom: 10 }}
        placeholder="请选择项目"
        listHeight={document.body.offsetHeight - 200}
        allowClear
        options={project.userProjectSimpList.map((item) => ({
          label: item.projectName,
          value: item.projectId,
        }))}
        onChange={projectSelectChange}
      />
      {treeData?.length > 0 ? (
        <>
          <Input
            placeholder="请输入关键词"
            onChange={debounce(searchInput, 500)}
          />
          <Tree
            defaultExpandAll
            defaultSelectedKeys={['1']}
            treeData={treeData}
            blockNode
            selectedKeys={selectedKeys}
            onSelect={handleChange}
          />
        </>
      ) : (
        '请选择项目'
      )}
    </div>
  );
};

export default connect(({ project }) => ({
  project,
}))(TreeData);
