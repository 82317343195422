import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Col, message, MessageBox, Row, Avatar } from 'kenshin';
import { connect } from 'umi';
import TreeData from '../assessmentList/components/TreeData';
import CardBox from './components/CardBox';
import './index.less';

const Template = ({ dispatch }) => {
  const [treeKay, setTreeKay] = useState('');
  const [treeRow, setTreeRow] = useState({ deptName: '微谱科技公司' });

  const treeHandleChange = (keys, row) => {
    setTreeRow(row);
    setTreeKay(keys[0]);
  };

  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData onChange={treeHandleChange} hideEmp defaultId="1" />
        </Col>
        <Col span={16}>
          <CardBox deptId={treeKay} deptName={treeRow.deptName} />
        </Col>
      </Row>
    </>
  );
};

export default connect(({}) => {
  return {};
})(Template);
