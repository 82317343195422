import { MessageBox, Button, Timeline } from 'kenshin';
import TablePro from '@/components/TablePro';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'umi';
import busiTypeKey, { attendanceKey } from '@/pages/process/_util/busiTypeKey';
import { osaRecord, attendanceRecord } from './_recordUtil';

const { OSA, WEEK, OSAEDIT, WEEKEDIT } = busiTypeKey;

const RecordModal = ({ visible, onCancel, processApplyId, row }) => {
  const dispatch = useDispatch();
  const [timeLineData, setTimeLineData] = useState([]);
  // Redux数据
  const process = useSelector(({ process }) => process);

  useEffect(() => {
    if (visible) {
      if (
        [OSAEDIT, WEEKEDIT].includes(
          row?.todoItemBusiType ?? row?.applyBusiType,
        )
      ) {
        dispatch({
          type: 'performance/getosachangeprocesslist',
          payload: {
            osaId: '',
            todoBusiId: row.todoItemBusiId ?? row.processApplyId,
          },
        }).then((list) => {
          if (list) {
            setTimeLineData(list);
          }
        });
      }
      if ([OSA, WEEK].includes(row?.todoItemBusiType ?? row?.applyBusiType)) {
        osaRecord.getData({ dispatch, row, setTimeLineData });
      }
      if (
        Object.values(attendanceKey).includes(
          row?.todoItemBusiType ?? row?.applyBusiType,
        )
      ) {
        attendanceRecord.getData({ dispatch, row, setTimeLineData });
      }
    }
  }, [visible]);

  const statusObj = {
    0: '待审核',
    1: '审核中',
    2: '审核通过',
    3: '审核拒绝',
  };

  const columns = [
    {
      dataIndex: 'processApplyNodeName',
      title: '流程名称',
    },
    {
      dataIndex: 'processNodeAuditList',
      title: '审核人/提交人',
      render: (list) =>
        list?.map((item) => (
          <div key={item.processAuditId}>
            {item.processAuditName}：{item.processAuditRemark}
          </div>
        )),
    },
    {
      dataIndex: 'processNodeStatus',
      title: '审核状态',
      render: (status) => statusObj[status],
    },
  ];

  const renderChildren = (row) => {
    if (
      Object.values(attendanceKey).includes(
        row?.todoItemBusiType ?? row?.applyBusiType,
      )
    ) {
      return attendanceRecord.com({ timeLineData });
    }
    if (
      [OSA, WEEK, OSAEDIT, WEEKEDIT].includes(
        row?.todoItemBusiType ?? row?.applyBusiType,
      )
    ) {
      return osaRecord.com({ timeLineData });
    }
    return (
      <TablePro
        pagination={false}
        params={{ processApplyId }}
        request="process/getcompleteprocess"
        dataSource={process.completeprocessList}
        columns={columns}
        rowKey={'processApplyChangeId'}
        curColumns
        columnsOptions={false}
      ></TablePro>
    );
  };

  return (
    <MessageBox
      title="审核记录"
      onCancel={onCancel}
      width={1000}
      maskClosable={false}
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      destroyOnClose
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      {renderChildren(row)}
    </MessageBox>
  );
};

export default RecordModal;
