import { TreeSelect } from 'kenshin';
import { memo, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'umi';

const getTreeData = (list) => {
  return list.map((item) => {
    let children;
    if (item.child) {
      children = getTreeData(item.child);
    }
    return {
      title: item.deptName,
      value: item.deptId,
      children,
    };
  });
};

const DeptSelect = (props) => {
  const systemOrgani = useSelector(({ systemOrgani }) => systemOrgani);
  const dispatch = useDispatch();

  useEffect(() => {
    if (systemOrgani.departmentList.length == 0) {
      dispatch({
        type: 'systemOrgani/getDepartmentPagelist',
        payload: {},
      });
    }
  }, []);

  const departmentList = useMemo(
    () =>
      systemOrgani.departmentList?.deptId
        ? getTreeData([systemOrgani.departmentList])
        : [],
    [systemOrgani.departmentList],
  );

  const treeSelectProps = {
    placeholder: '请选择部门',
    treeNodeFilterProp: 'title',
    multiple: true,
    treeDefaultExpandAll: true,
    maxTagCount: 'responsive',
    dropdownStyle: { maxHeight: 600, overflow: 'auto' },
    treeData: departmentList,
    ...props,
  };

  return <TreeSelect {...treeSelectProps} />;
};

export default memo(DeptSelect);
