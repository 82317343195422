import {
  getContractList,
  addContract,
  editContract,
  deleteContract,
  getEasyContractList,
  getContractCollectList,
  getContractCollectRCList,
  addContractCollect,
  editContractCollect,
  delContractCollect,
  getContractById,
  statusChange,
} from '@/_serveice/contract/list/index.js';

export default {
  namespace: 'contract',

  state: {
    contractList: {},
    contractListSearchForm: {},

    easyContractList: [],

    // 合同收款
    contractCollectList: {},
    contractCollectListSearchForm: {},

    contractbyIdList: [],
  },

  effects: {
    // 页面列表--------------------------
    *getContractList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ contract }) => contract.contractListSearchForm,
      );
      let contractListSearchForm = { ...searchData, ...payload };
      if (reset) {
        contractListSearchForm = payload;
      }
      const res = yield call(getContractList, contractListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            contractList: res.data,
            contractListSearchForm,
          },
        });
      }
    },
    *editContract({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editContract : addContract, payload);
      if (res?.success) {
        yield put({
          type: 'getContractList',
        });
        return true;
      }
    },
    *deleteContract({ payload }, { put, call }) {
      const res = yield call(deleteContract, payload);
      if (res?.success) {
        yield put({
          type: 'getContractList',
        });
        return true;
      }
    },
    *statusChange({ payload }, { put, call }) {
      const res = yield call(statusChange, payload);
      if (res?.success) {
        yield put({
          type: 'getContractList',
        });
        return res;
      }
    },
    *getEasyContractList({ payload }, { put, call }) {
      const res = yield call(getEasyContractList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            easyContractList: res.data,
          },
        });
        return true;
      }
    },
    // 页面列表---------------end-----------

    // 合同收款
    *getContractCollectList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ contract }) => contract.contractCollectListSearchForm,
      );
      let contractCollectListSearchForm = { ...searchData, ...payload };
      if (reset) {
        contractCollectListSearchForm = payload;
      }
      const { sort, ...params } = contractCollectListSearchForm;
      const res = yield call(
        sort == 'LIST' ? getContractCollectRCList : getContractCollectList,
        params,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            contractCollectList: res.data,
            contractCollectListSearchForm,
          },
        });
      }
    },
    *editContractCollect({ payload, hasEdit }, { put, call }) {
      const res = yield call(
        hasEdit ? editContractCollect : addContractCollect,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'getContractCollectList',
        });
        return true;
      }
    },
    *delContractCollect({ payload }, { put, call }) {
      const res = yield call(delContractCollect, payload);
      if (res?.success) {
        yield put({
          type: 'getContractCollectList',
        });
        return true;
      }
    },

    *getContractById({ payload }, { put, call }) {
      const res = yield call(getContractById, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            contractbyIdList: res.data || [],
          },
        });
        return res.data || [];
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
