import ContractUtil from './contract';
import CustomerUtil from './customer';
import RoleUtil from './role';
import TaskUtil from './task';
import ProjectUtil from './project';
import OsaEditUtil from './osaEdit';

export default {
  ContractUtil,
  CustomerUtil,
  RoleUtil,
  TaskUtil,
  ProjectUtil,
  OsaEditUtil,
};
