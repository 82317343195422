import React, { useEffect, useRef } from 'react';
import { Button, Form, Input, Table } from 'kenshin';
import './index.less';
import Employee from './components/Employee';
import Finance from './components/Finance';
import AnalyseCharts from '@/components/AnalyseCharts';
import { getRoleType } from '@/_util';

const IndexPage = (props) => {
  // 无token不需要渲染页面
  if (!localStorage.getItem('token')) {
    return null;
  }
  useEffect(() => {
    console.log(111111111);
  },[])
  // let curRoleType = useRef()
  console.log(getRoleType());

  const children = () => {
    if(getRoleType() ==  1){
      return <Finance key='Finance'  />
    }
    return <Employee key='employee' />
  }

  return (
    <>
      {/* <Finance /> */}
      {children()}
    </>
  );
};

IndexPage.title = '财务';

export default IndexPage;
