import { dicLType } from '@/_util/keysType';
import { custStatusObj } from '../../../customer/list';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { projectStatus } from '../../../project/list';
import { roleType } from '@/_util/keysType';

/** 客户 */
export const customerColumns = (system) => [
  { title: '客户名称', dataIndex: 'custCompFullName' },
  {
    title: '客户级别',
    dataIndex: 'custLevel',
    render: (val) =>
      system.dictypeListAll[dicLType.CUSTOMERTYPE]?.find(
        (item) => item.simpleData.id == val,
      )?.simpleData.value,
  },
  {
    title: '合作年限',
    dataIndex: 'custCooperTimeList',
    render: (val) =>
      val?.map((item, i) => {
        return (
          <div key={i}>
            {moment(item.beginTime).format('YYYY-MM-DD')} ~{' '}
            {item.endTime ? moment(item.endTime).format('YYYY-MM-DD') : '至今'}
          </div>
        );
      }),
  },
  // {
  //   title: '合作年限',
  //   dataIndex: 'custCooperTime',
  //   render: (val) => getCustCooperTimeToString(val),
  // },
  { title: '公司电话', dataIndex: 'custCompLandLine' },
  {
    dataIndex: 'formalProjectNum',
    title: '项目情况',
    render: (val) => (!val ? '暂无项目' : `${val}个项目进行中`),
  },
  {
    dataIndex: 'custStatus',
    title: '客户状态',
    render: (val) => custStatusObj[val],
  },
];

export const getCustomerSearchCloumns = ({ system }) => {
  const searchCloumns = [
    {
      name: 'customerName',
      type: 'Input',
      props: {
        placeholder: '客户名称',
      },
    },
    {
      name: 'custStatus',
      type: 'Select',
      props: {
        placeholder: '状态',
        options: Object.keys(custStatusObj).map((item) => ({
          value: item,
          label: custStatusObj[item],
        })),
      },
    },
    {
      name: 'custLevel',
      type: 'Select',
      props: {
        placeholder: '客户级别',
        options: system.dictypeListAll[dicLType.CUSTOMERTYPE]?.map((item) => ({
          label: item?.simpleData.value,
          value: item?.simpleData.id,
        })),
      },
    },
    {
      name: 'custCooperTimeDate',
      type: 'DatePickerRangePicker',
      label: '合作年限',
      props: {
        placeholder: ['开始时间', '结束时间'],
      },
    },
  ];
  return searchCloumns;
};

export const roleCloumns = () => {
  const columns = [
    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName',
    },
    {
      title: '角色标识',
      dataIndex: 'roleKey',
      key: 'roleKey',
    },
    {
      title: '角色类型',
      dataIndex: 'roleType',
      key: 'roleType',
      render: (type) => (type == 1 ? '系统预设' : '用户定义'),
    },
  ];
  return columns;
};

export const contractCloumns = () => {
  // 合同状态
  const contractStatusObj = {
    1: <div className="status-grey">未开启</div>,
    2: <div className="status-green animate">进行中</div>,
    3: <div className="status-grey">已完成</div>,
    4: <div className="status-red">终止</div>,
  };
  const columns = [
    {
      title: '合同名称',
      width: 150,
      fixed: 'left',
      dataIndex: 'contractName',
    },
    {
      title: '客户名称',
      width: 150,
      fixed: 'left',
      dataIndex: 'custCompname',
    },
    {
      title: '合同编号',
      width: 150,
      dataIndex: 'contractNumber',
    },
    {
      dataIndex: 'contractBusiList',
      width: 150,
      title: '商务负责人',
      render: (val) =>
        val?.map((item, i) => <div key={i}>{item.busiAffairs}</div>),
    },
    {
      dataIndex: 'contractStatus',
      width: 150,
      title: '合同状态',
      render: (val) => contractStatusObj[val],
    },
  ];
  return columns;
};

/** 合同搜索json */
export const getContractSearchCloumns = ({ systemOrgani }) => {
  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '合同名称、编号',
      },
    },
    // {
    //   name: 'contractTimeLong',
    //   type: 'DatePickerRangePicker',
    //   label: '合同周期',
    //   props: {},
    // },
    {
      name: 'contractStatus',
      type: 'Select',
      label: '合同状态',
      props: {
        placeholder: '合同状态',
        options: [
          { label: '未开启', value: 1 },
          { label: '进行中', value: 2 },
          { label: '已完成', value: 3 },
          { label: '终止', value: 4 },
        ],
      },
    },
    {
      name: 'busiAffairsIdList',
      type: 'Select',
      label: '归属商务',
      props: {
        placeholder: '商务',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.BUSINESSAFFAIRS]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
    // {
    //   name: 'contractAmountMin',
    //   type: 'InputNumber',
    //   label: '合同最小金额',
    //   props: {
    //     placeholder: '合同最小金额（不含税）',
    //     stepType: 'inside',
    //     style: { width: '100%' },
    //     min: 0,
    //   },
    // },
    // {
    //   name: 'contractAmountMax',
    //   type: 'InputNumber',
    //   label: '合同最大金额',
    //   props: {
    //     placeholder: '合同最大金额（不含税）',
    //     stepType: 'inside',
    //     style: { width: '100%' },
    //     min: 0,
    //   },
    // },
  ];
  return searchCloumns;
};

export const projectCloumns = () => {
  return [
    {
      dataIndex: 'projectName',
      title: '项目名称',
    },
    {
      dataIndex: 'custCompFullName',
      title: '客户名称',
    },
    {
      dataIndex: 'contractNumber',
      width: 150,
      title: '合同编号',
    },
    {
      dataIndex: 'projectStatus',
      title: '项目状态',
      render: (val) => projectStatus[val],
    },

    {
      dataIndex: 'projectManagerName',
      title: '项目经理',
    },
  ];
};

export const getProjectSearchCloumns = ({ systemOrgani }) => [
  {
    name: 'key',
    type: 'Input',
    props: {
      placeholder: '项目/客户/合同编号',
    },
  },
  {
    name: 'projectManager',
    type: 'Select',
    label: '项目经理',
    props: {
      placeholder: '项目经理',
      options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
        (item) => ({
          label: item.value,
          value: item.id,
        }),
      ),
    },
  },
  // {
  //   name: 'projectConsumProgress',
  //   type: 'Select',
  //   label: '消耗进度',
  //   props: {
  //     placeholder: '消耗进度',
  //     allowClear: true,
  //     options: [
  //       { label: '滞后', value: 0 },
  //       { label: '正常', value: 1 },
  //       { label: '超前', value: 2 },
  //     ],
  //   },
  // },
  {
    name: 'projectStatus',
    type: 'Select',
    label: '项目状态',
    props: {
      placeholder: '项目状态',
      options: [
        { label: '未开始', value: 0 },
        { label: '正常', value: 1 },
        { label: '停止', value: 2 },
        { label: '结束', value: 4 },
      ],
    },
  },
];
