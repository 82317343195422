import request from '@/_util/require';
let version = 'v1';

export async function getEmpSalaryPage(options) {
  return request(`/${version}/waper/erp/pc/salary/getempsalarypage`, {
    method: 'POST',
    data: options,
  });
}
export async function importEmpSalary(options) {
  return request(`/${version}/waper/erp/pc/salary/import`, {
    method: 'POST',
    data: options,
  });
}

export const importEmpSalaryUrl = `/${version}/waper/erp/pc/salary/import`;
export const downTemplate = `/template/employee-salary.xlsx`;
