import { dicLType } from '@/_util/keysType';

// 分类options转换成Select的格式
export const SelectOptionsTransform = (arr, parent, { info, datalist }) => {
  if (!Array.isArray(arr)) {
    return undefined;
  }
  return arr.map((item) => {
    let res = {
      label: item?.[info]?.value || item.value,
      value: item?.[info]?.id || `${parent.id}-${item.id}`,
    };
    if (item.hasOwnProperty(datalist)) {
      res.options =
        SelectOptionsTransform(item[datalist], item?.[info], {
          info,
          datalist,
        }) || [];
    }
    return res;
  });
};

// 合同材料
export const contractMaterialListOptions = (
  contractMaterialList,
  arr,
  typeIndex,
) => {
  arr.map((item, index) => {
    let contractMaterialName = item.contractMaterialName || item.name;
    contractMaterialList.push({
      contractMaterial: item.contractMaterial || item.url,
      contractMaterialName,
      contractMaterialSize:
        item.contractMaterialSize || `${(item.size / 1024).toFixed(2)}KB`,
      contractMaterialSort: item.contractMaterialSort || index + 1,
      contractMaterialSuff:
        item.contractMaterialSuff ||
        `.${
          contractMaterialName.split('.')[
            contractMaterialName.split('.').length - 1
          ]
        }`,
      contractMaterialType: item.contractMaterialType || typeIndex,
    });
  });
};

export const findSelectTree = (list, id) => {
  if (Array.isArray(list)) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.dataList) {
        for (let j = 0; j < item.dataList.length; j++) {
          const opt = item.dataList[j];
          if (opt.id == id) {
            return `${item.simpleData.id}-${opt.id}`;
          }
        }
      }
    }
  }
};

// 客户选择根据查找
export const findCustomerOptions = (list, id) => {
  if (Array.isArray(list)) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      for (let j = 0; j < item.options.length; j++) {
        const opt = item.options[j];
        if (opt.value.split('-')[1] == id) {
          return opt.value;
        }
      }
    }
  }
};

// 弹窗文件回显处理
export const fileOptions = (list, index) => {
  let res = list
    ?.filter((item) => item.contractMaterialType == index)
    .map((item) => ({
      uid: item.contractMaterialSort,
      name: item.contractMaterialName,
      status: 'done',
      url: item.contractMaterial,
      ...item,
    }));

  return res;
};

// 批量操作后失败处理
export const batchErrorHandle = (res) => {
  const errType = {
    noAcceptSheetContractIdList: '没有验收单的对应的合同id集合',
    noCloseWorkPackageContractIdList: '未关闭的工作包对应的合同id集合',
    noEndTimeContractIdList: '合同时间未结束对应的合同id集合',
    noFinishAmountContractIdList: '未收款完成对应的合同id集合',
  };
  if (typeof res == 'object') {
    let arr = [];
    Object.keys(errType).map((item) => {
      res[item]?.length > 0 &&
        arr.push({ name: errType[item], value: res[item] });
    });
    let str = arr.map((item, i) => (
      <div key={i}>
        {item.name}：{item.value.join(',')}
      </div>
    ));
    return str;
  }
};

export const beforeShowEditChangeData = (row, { system, customerOptions }) => {
  return {
    ...row,
    // 附件处理回显
    contractMaterialList1: fileOptions(row.contractMaterialList, 1),
    contractMaterialList2: fileOptions(row.contractMaterialList, 2),
    // 类型
    contractType: findSelectTree(
      system.dictypeListAll[dicLType.CONTRACTTYPE],
      row.contractType,
    ),
    // 客户
    contractCustId: findCustomerOptions(
      customerOptions.current,
      row.contractCustId,
    ),
    // 金额
    contractAmount: (row.contractAmount / 100).toFixed(2),
    contractExpectCollectList: row.contractExpectCollectList?.map((item) => ({
      ...item,
      collectAmount: +(item.collectAmount / 100).toFixed(2),
    })),
    // 商务
    contractBusiList: row.contractBusiList?.map(
      (item) => item.busiAffairsId,
      // (item) => item.deptId?`${item.deptId}-${item.busiAffairsId}`:item.busiAffairs,
    ),
    // 时间
    contractTime: [moment(row.contractBeginTime), moment(row.contractEndTime)],
  };
};

export const beforeSaveChangeData = (data) => {
  const {
    contractTime,
    contractMaterialList1,
    contractMaterialList2,
    ...value
  } = data;

  // 金额处理，需 *100
  value.contractAmount = (value.contractAmount * 100).toFixed();

  // 选择器id处理
  value.contractType && (value.contractType = value.contractType.split('-')[1]);
  value.contractCustId &&
    (value.contractCustId = value.contractCustId.split('-')[1]);
  // 商务处理
  value.contractBusiList &&
    (value.contractBusiList = value.contractBusiList.map((item) => ({
      busiAffairsId: item,
      busiAffairs: '',
      //deptId: item.split('-')[0],
    })));
  // 部门处理，单选
  value.contractBelongDept =
    value.contractBelongDept?.[0]?.id || value.contractBelongDept;
  // 项目周期处理转换
  if (contractTime) {
    const [contractBeginTime, contractEndTime] = contractTime;
    value.contractBeginTime = moment(contractBeginTime)
      .startOf('date')
      .format('YYYY-MM-DD HH:mm:ss');
    value.contractEndTime = moment(contractEndTime)
      .endOf('date')
      .format('YYYY-MM-DD HH:mm:ss');
  }
  // 上传的文件处理
  let contractMaterialList = [];
  if (contractMaterialList1) {
    contractMaterialListOptions(contractMaterialList, contractMaterialList1, 1);
  }
  if (contractMaterialList2) {
    contractMaterialListOptions(contractMaterialList, contractMaterialList2, 2);
  }
  value.contractMaterialList = contractMaterialList;

  let payload = { ...value };
  payload.contractExpectCollectList = payload.contractExpectCollectList.map(
    (item) => {
      return {
        ...item,
        collectAmount: +(item.collectAmount * 100).toFixed(2),
      };
    },
  );

  return payload
};
