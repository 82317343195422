import {
  getDepartmentPagelist,
  addDepartment,
  deleteDepartment,
  moveDepartment,
  editDepartment,
  searchSysDeptUser,
  setDeptmanager,
  getOrganiList,
  addOrgani,
  organiStatus,
  editOrgani,
  resetPwd,
  getAllEmployee,
  getRoleKeyObj,
  getemplistbydeptid,
  setemployeebusileader,
  addupdateclocknoticetask,
} from '@/_serveice/admin/system';

// 分类options转换成Select的格式
export const SelectOptionsTransform = (arr, parent, { info, datalist }) => {
  if (!Array.isArray(arr)) {
    return undefined;
  }
  return arr.map((item) => {
    let res = {
      label: item?.[info]?.value || item.value,
      value: item?.[info]?.id || `${parent.id}-${item.id}`,
    };
    if (datalist == null || item[datalist]) {
      res.options = SelectOptionsTransform(item[datalist] || [], item?.[info], {
        info,
        datalist,
      });
    }
    return res;
  });
};

export default {
  namespace: 'systemOrgani',

  state: {
    departmentList: [],
    departmentListSearchForm: {},
    sysDeptUserList: [],

    organiList: [],
    organiListSearchForm: {},

    employeeAll: [],
    ProjectManagerAll: {},
    employeeForDept: {},
  },

  // 类似vue的actions，里面可用执行异步操作
  effects: {
    // 部门 -----------------
    *searchSysDeptUser({ payload }, { put, call, select }) {
      const res = yield call(searchSysDeptUser, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            sysDeptUserList: res.data,
          },
        });
      }
    },
    *getemplistbydeptid({ payload }, { put, call, select }) {
      const employeeForDept = yield select(
        ({ systemOrgani }) => systemOrgani.employeeForDept,
      );
      if (employeeForDept[payload.deptId]) {
        return employeeForDept[payload.deptId];
      }
      const res = yield call(getemplistbydeptid, payload);
      if (res?.success) {
        employeeForDept[payload.deptId] = res.data;
        yield put({
          type: 'save',
          payload: {
            employeeForDept: { ...employeeForDept },
          },
        });
        return res.data;
      }
    },

    *getDepartmentPagelist({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ systemOrgani }) => systemOrgani.departmentListSearchForm,
      );
      let departmentListSearchForm = { ...searchData, ...payload };
      if (reset) {
        departmentListSearchForm = payload;
      }
      const res = yield call(getDepartmentPagelist, departmentListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            departmentList: res.data,
            departmentListSearchForm,
          },
        });
      }
    },
    *editDepartment({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editDepartment : addDepartment, payload);
      if (res?.success) {
        yield put({
          type: 'getDepartmentPagelist',
        });
        return true;
      }
    },
    *deleteDepartment({ payload }, { put, call }) {
      const res = yield call(deleteDepartment, payload);
      if (res?.success) {
        yield put({
          type: 'getDepartmentPagelist',
        });
        return true;
      }
    },
    *moveDepartment({ payload }, { put, call }) {
      const res = yield call(moveDepartment, payload);
      if (res?.success) {
        yield put({
          type: 'getDepartmentPagelist',
        });
        return true;
      }
    },
    *setDeptmanager({ payload }, { put, call }) {
      const res = yield call(setDeptmanager, payload);
      if (res?.success) {
        yield put({
          type: 'getDepartmentPagelist',
        });
        return true;
      }
    },

    // 部门 end ---------------------

    // 员工 -----------------
    *getOrganiList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ systemOrgani }) => systemOrgani.organiListSearchForm,
      );
      let organiListSearchForm = { ...searchData, ...payload };
      if (reset) {
        organiListSearchForm = payload;
      }
      const res = yield call(getOrganiList, organiListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            organiList: res.data,
            organiListSearchForm,
          },
        });
      }
    },
    *editOrgani({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editOrgani : addOrgani, payload);
      if (res?.success) {
        yield put({
          type: 'getOrganiList',
        });
        return true;
      }
    },
    *organiStatus({ payload }, { put, call }) {
      const res = yield call(organiStatus, payload);
      if (res?.success) {
        yield put({
          type: 'getOrganiList',
        });
        return true;
      }
    },
    *resetPwd({ payload }, { put, call }) {
      const res = yield call(resetPwd, payload);
      if (res?.success) {
        return true;
      }
    },
    *setemployeebusileader({ payload }, { put, call }) {
      const res = yield call(setemployeebusileader, payload);
      if (res?.success) {
        yield put({
          type: 'getOrganiList',
        });
        return true;
      }
    },
    *getAllEmployee({ payload }, { put, call }) {
      const res = yield call(getAllEmployee, payload);
      if (res?.success) {
        let employeeAll = SelectOptionsTransform(res.data, null, {
          info: 'deptData',
          datalist: 'employeeList',
        });
        yield put({
          type: 'save',
          payload: {
            employeeAll,
          },
        });
        return employeeAll;
      }
    },
    *addupdateclocknoticetask({ payload }, { put, call }) {
      console.log('addupdateclocknoticetask...', payload);
      const res = yield call(addupdateclocknoticetask, payload);
      if (res?.success) {
        // yield put({
        //   type: 'getOrganiList',
        // });
        return true;
      }
    },
    *getRoleKeyObj({ payload }, { put, call, select }) {
      const res = yield call(getRoleKeyObj, payload);
      const ProjectManagerAll = yield select(
        ({ systemOrgani }) => systemOrgani.ProjectManagerAll,
      );
      if (res?.success) {
        ProjectManagerAll[payload.roleKey] = res.data;
        yield put({
          type: 'save',
          payload: {
            ProjectManagerAll,
          },
        });
        return true;
      }
    },

    // 员工 end ---------------------
  },

  reducers: {
    // 每次保存数据的时候讲state再存一次，避免下一次数据存储的时候把原数据删了
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
