import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';

const menuConfig = ({ dispatch, loading, lowCode, user }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const ref = useRef();

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const [deleVisible, setDeleVisible] = useState(false);
  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'lowCode/deleteProject',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = (value) => {
    let payload = { ...value };
    let hasEdit = false;
    if (editModalData?.projectId) {
      hasEdit = true;
      payload.projectId = editModalData.projectId;
    }
    dispatch({
      type: 'lowCode/editProject',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        message.success('操作成功');
      }
    });
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '编码',
      dataIndex: 'projectCode',
      key: 'projectCode',
    },
    {
      title: '排序',
      dataIndex: 'projectSort',
      key: 'projectSort',
    },
    {
      title: '页面数量',
      dataIndex: 'projectGenpageNum',
      key: 'projectGenpageNum',
    },
    // {
    //   title: '项目模板类型',
    //   dataIndex: 'projectTempTypeId',
    //   key: 'projectTempTypeId',
    // },
    {
      title: '项目负责人',
      dataIndex: 'projectCharge',
      key: 'projectCharge',
    },
    {
      title: '创建时间',
      dataIndex: 'projectCrtTime',
      key: 'projectCrtTime',
      render: (value) => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            {/* <Button type="text" onClick={() => toLowCodeFram(row)}>页面配置</Button> */}
            {row.hasbtnList.includes(btnKeys.add) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                编辑
              </Button>
            )}
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delRole({ projectId: row.projectId })}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'projectCode',
      type: 'Input',
      props: {
        placeholder: '编码',
      },
    },
    {
      name: 'projectName',
      type: 'Input',
      props: {
        placeholder: '项目名称',
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '编码',
      name: 'projectCode',
      type: 'Input',
      required: '请输入编码',
      props: {
        placeholder: '请输入编码',
        disabled: (row) => !!row.projectId,
      },
    },
    {
      label: '排序',
      name: 'projectSort',
      type: 'InputNumber',
      props: {
        placeholder: '请输入排序',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: '名称',
      name: 'projectName',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
    {
      label: '项目类型',
      name: 'projectType',
      type: 'Select',
      props: {
        placeholder: '请选择类型',
        options: [
          { label: '小程序', value: 1 },
          { value: 2, label: 'Web端(H5/PC)' },
        ],
      },
    },
    {
      label: '项目负责人',
      name: 'projectCharge',
      type: 'Input',
      props: {
        placeholder: '多个人使用逗号隔开',
      },
    },
  ];

  const extra = (
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增项目
    </Button>
  );

  return (
    <>
      <TablePro
        request="lowCode/getProjectList"
        rowKey={'projectId'}
        columns={columns}
        searchCloumns={searchCloumns}
        data={lowCode.projectList}
        actionRef={ref}
        extra={extra}
      />

      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </>
  );
};

menuConfig.title = '菜单配置';

export default connect(({ lowCode, loading, user }) => {
  return {
    user,
    lowCode,
    loading: loading.effects,
  };
})(menuConfig);
