import React, { useState, useEffect, useMemo, memo } from 'react';
import { Button, Menu } from 'kenshin';
import * as AntIcon from '@ant-design/icons';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { history, connect, useAliveController } from 'umi';
import { configResponsive, useCreation, useResponsive } from 'ahooks';
import { getTabsName } from '@/_util/util';

configResponsive({
  small: 0,
  middle: 1360,
  large: 1920,
});

const { SubMenu } = Menu;

// 菜单过滤
const menuTransform = (arr) => {
  let res = arr
    ?.filter((item) => item.permType == 1)
    .map((item) => {
      let newObj = {
        id: item.permId,
        title: item.permName,
        path: item.permKey,
        icon: item.permIcon,
      };
      if (item?.child) {
        newObj.child = menuTransform(item.child);
      }
      return newObj;
    });
  return res;
};

// 受控展开菜单分组
const getDefaultOpenKeys = (arr) => {
  let path = history.location.pathname;
  let resArr = [];
  arr
    ?.map((item) => {
      if (item.child && item.child.length > 0) {
        let res = getDefaultOpenKeys(item.child);
        resArr = [...resArr, ...res];
        if (res.length > 0) {
          resArr.unshift(item.sf_alias);
        }
      }
      if (item.sf_alias == path) {
        resArr.unshift(item.sf_alias);
      }
      return null;
    })
    .filter((item) => !!item);
  return resArr;
};

// 根据path查找菜单id
const findMenuByPath = (arr, path) => {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (item.path == path) {
      return item.id;
    }
    if (item.child) {
      let id = findMenuByPath(item.child, path);
      if (id != undefined) {
        return id;
      }
    }
  }
};

const LayoutMenu = memo((props) => {
  const responsive = useResponsive();
  const { clear } = useAliveController();

  // 折叠菜单栏，暂不用，后期可通过ref暴露出组件外
  const [collapsed, setCollapsed] = React.useState(false);
  const { user, dispatch } = props;
  const [menuKey, setMenuKey] = useState(history.location.pathname);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState(
    getDefaultOpenKeys(user.menuList),
  );

  useEffect(() => setCollapsed(!responsive.middle), [responsive.middle]);

  useEffect(() => {
    setDefaultOpenKeys(getDefaultOpenKeys(user.menuList));
  }, [user.menuList]);

  // useEffect无法监听history跳转，使用以下代替
  history.block((location, action) => {
    if (location.pathname.includes('Login')) {
      clear();
    }
    // 存储菜单id，更加菜单id进行权限控制
    let id = findMenuByPath(getMenuList, location.pathname);
    id && localStorage.setItem('curMenuId', id);
    setMenuKey(location.pathname);
    // 设置页签标题
    if (location.pathname.includes('/Login')) {
      document.title = 'WDP 登录';
    } else {
      document.title = getTabsName(location.pathname);
    }
  });
  const getMenuList = useCreation(() =>
    localStorage.getItem('menu')
      ? menuTransform(JSON.parse(localStorage.getItem('menu')) || user.menuList)
      : [],
  );

  // 配个菜单配置返回的antd图标库渲染
  const renderIcon = (iconValue) => {
    if (iconValue) {
      const Icon = React.createElement(AntIcon[iconValue], {});
      return Icon;
    }
    return null;
  };

  const renderChildren = (children) => {
    if (children?.length == 0) {
      return (
        <div style={{ paddingTop: 20 }}>暂无菜单权限，请联系管理员添加</div>
      );
    }
    return children?.map((item, index) => {
      let iconStr = Object.keys(AntIcon).includes(item.icon) ? item.icon : '';
      let Icon = renderIcon(iconStr);
      if (item.child && item.child.length > 0) {
        return (
          <SubMenu
            icon={Icon}
            key={item.path || index}
            title={item.title}
            onTitleClick={(e) => {
              let i = defaultOpenKeys.indexOf(e.key);
              if (i != -1) {
                defaultOpenKeys.splice(i, 1);
              } else {
                defaultOpenKeys.push(e.key);
              }
            }}
          >
            {renderChildren(item.child)}
          </SubMenu>
        );
      }
      return (
        <Menu.Item icon={Icon} key={item.path || index}>
          {item.title || '未定义'}
        </Menu.Item>
      );
    });
  };
  return (
    <div className={`menu ${collapsed ? 'fold' : ''}`}>
      <Menu
        selectedKeys={menuKey}
        mode="inline"
        className="main-menu"
        theme="dark"
        onSelect={(e) => {
          history.push(e.key);
          setMenuKey(e.key);
        }}
        inlineCollapsed={collapsed}
        {...(() => (collapsed ? {} : { openKeys: defaultOpenKeys }))()}
      >
        {renderChildren(getMenuList)}
      </Menu>
      <Menu
        selectedKeys={[]}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        className="collapsed-menu"
        onClick={() => setCollapsed((bool) => !bool)}
      >
        <Menu.Item
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        ></Menu.Item>
      </Menu>
    </div>
  );
});

export default connect(({ user }) => ({ user }))(LayoutMenu);
