import { beforeSaveChangeData } from '@/pages/contract/list/util';
import { dicLType, roleType } from '@/_util/keysType';
import { findDept } from '@/components/ChooseDepartment';
import { flat } from '@/_util';
import CollectBatch from '@/pages/contract/list/components/CollectBatch';
import busiTypeKey from '../../_util/busiTypeKey';
import { cardJSONRender } from './util';
import { Card, Col, Row, Avatar, Tag, Table, Button } from 'kenshin';
import { OSSImgUrl } from '@/_util/util';
import React from 'react';

const formCloumns = ({
  SelectOptionsTransform,
  system,
  customerOptions,
  systemOrgani,
}) => {
  const uploadAccept = 'image/*,.doc,.docx,.pdf';
  const contractBelongYearOptions = [-3, -2, -1, 0, 1] // 归属年份暂定上三年到下一年这几个选项
    .map((item) => moment().add(item, 'years').format('YYYY'))
    .map((item) => ({
      label: item,
      value: item,
    }));
  return [
    {
      title: '合同名称',
      dataIndex: 'contractName',
      valueType: 'Input',
      required: '请输入合同名称',
      fieldProps: {
        placeholder: '请输入合同名称',
      },
    },
    {
      title: '合同编号',
      dataIndex: 'contractNumber',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入编号',
      },
      formItemProps: {
        validateTrigger: 'onBlur',
        rules: [{ required: true, message: '请输入编号' }],
      },
    },
    {
      title: '合同金额',
      dataIndex: 'contractAmount',
      valueType: 'InputNumber',
      required: '请输入合同金额',
      help: '单位：元',
      fieldProps: {
        placeholder: '请输入金额',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      title: '合同税率',
      dataIndex: 'contractTaxRate',
      valueType: 'InputNumber',
      required: '请输入合同税率',
      // help: "单位：元",
      fieldProps: {
        placeholder: '请输入合同税率',
        stepType: 'inside',
        formatter: (value) => `${value}%`,
        max: 100,
        min: 0,
      },
    },
    {
      title: '合同类型',
      dataIndex: 'contractType',
      valueType: 'Select',
      required: '请选择合同类型',
      fieldProps: {
        placeholder: '请选择合同类型',
        options: SelectOptionsTransform(
          system.dictypeListAll[dicLType.CONTRACTTYPE],
          null,
          { info: 'simpleData', datalist: 'dataList' },
        ),
      },
    },
    {
      title: '合同归属年份',
      dataIndex: 'contractBelongYear',
      valueType: 'Select',
      required: '请选择合同归属年份',
      fieldProps: {
        placeholder: '请选择合同归属年份',
        options: contractBelongYearOptions,
      },
    },
    {
      title: '收款批次',
      dataIndex: 'contractExpectCollectList',
      required: '请选择收款批次',
      render: () => <CollectBatch />,
    },
    {
      title: '客户名称',
      dataIndex: 'contractCustId',
      valueType: 'Select',
      required: '请选择客户',
      fieldProps: {
        placeholder: '请选择客户',
        options: customerOptions,
      },
    },
    {
      title: '合同周期',
      dataIndex: 'contractTime',
      valueType: 'DatePickerRangePicker',
      required: '请选择合同周期',
      fieldProps: {},
    },
    {
      title: '合同归属部门',
      dataIndex: 'contractBelongDept',
      valueType: 'ChooseDepartment',
      required: '请选择合同归属部门',
      fieldProps: {
        multiple: false,
      },
    },
    {
      title: '合同归属商务',
      dataIndex: 'contractBusiList',
      valueType: 'Select',
      required: '请选择合同归属商务',
      tooltip: '注意：只有归属商务能看到当前合同',
      fieldProps: {
        placeholder: '请选择合同归属商务',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.BUSINESSAFFAIRS]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
        // disabled: true,
      },
    },
    {
      title: '合同电子版',
      dataIndex: 'contractMaterialList1',
      valueType: 'UploadFile',
      required: '请选择合同电子版',
      fieldProps: {
        mode: 'multiple',
        accept: uploadAccept,
        dirUrl: '/employee/avatar',
        multiple: true,
      },
    },
    {
      title: '验收单电子版',
      dataIndex: 'contractMaterialList2',
      valueType: 'UploadFile',
      fieldProps: {
        mode: 'multiple',
        accept: uploadAccept,
        dirUrl: '/employee/avatar',
        multiple: true,
      },
    },
    {
      title: '合同备注',
      dataIndex: 'contractRemark',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入合同备注',
      },
    },
  ];
};

const getContractData = ({
  values,
  process,
  systemOrgani,
  system,
  customerOptions,
}) => {
  /** 修改前的data */
  let beforeData = process.changeInfo.data;
  // 合同类型
  let contractTypeList = flat(
    system.dictypeListAll[dicLType.CONTRACTTYPE].map((item) => item.dataList),
    2,
  );

  // 获取合同处理后的数据
  let payload = beforeSaveChangeData(values);
  let collectAmount = payload.contractExpectCollectList.reduce(
    (pre, cur) => pre + cur.collectAmount,
    0,
  );

  // 修改前的部门名称
  beforeData.contractBelongDeptStr = findDept(
    [systemOrgani.departmentList],
    beforeData.contractBelongDept,
  )?.deptName;
  // 修改后的部门名称
  payload.contractBelongDeptStr = findDept(
    [systemOrgani.departmentList],
    payload.contractBelongDept,
  )?.deptName;

  // 修改前的合同类型
  beforeData.contractTypeStr = contractTypeList.find(
    (item) => item.id == beforeData.contractType,
  )?.value;
  let custCompname = '';
  customerOptions?.some((item) => {
    custCompname = item?.options.find(
      (option) => option.value?.split('-')[1] === payload.contractCustId,
    )?.label;
    return custCompname;
  });
  payload.custCompname = custCompname;

  // 修改后的合同类型
  payload.contractTypeStr = contractTypeList.find(
    (item) => item.id == payload.contractType,
  )?.value;

  const { busiType, ...otherParams } = process.changeInfo.params;

  return { beforeData, payload, otherParams, collectAmount };
};

/** 初次修改合同 */
const firstSave = (
  values,
  { process, systemOrgani, system, customerOptions },
) => {
  const { beforeData, payload, otherParams, collectAmount } = getContractData({
    values,
    process,
    systemOrgani,
    system,
    customerOptions,
  });
  if (payload.contractAmount < collectAmount) {
    message.error('收款批次的收款金额之和不能大于合同金额！');
    return false;
  }

  let params = {
    ...otherParams,
    // 存储更新前跟更新后的属性
    processApplyDataChangeDTO: {
      // 更新后
      dataChangeBefore: JSON.stringify(beforeData),
      // 更新前
      dataChangeAfter: JSON.stringify({
        ...beforeData,
        ...payload,
        contractBusiList: beforeData.contractBusiList,
      }),
    },
  };
  return params;
};

/** 再次修改合同 */
const reSave = (values, { process, systemOrgani, system, customerOptions }) => {
  const { payload, otherParams, collectAmount } = getContractData({
    values,
    process,
    systemOrgani,
    system,
    customerOptions,
  });

  if (payload.contractAmount < collectAmount) {
    message.error('收款批次的收款金额之和不能大于合同金额！');
    return false;
  }

  let params = {
    ...otherParams,
    dataChangeAfter: JSON.stringify({
      ...JSON.parse(process.changeInfo.params.dataChangeBefore),
      ...payload,
      contractBusiList: process.changeInfo.data.contractBusiList,
    }),
  };
  return params;
};

/** 合同搜索json */
export const searchCloumns = ({ systemOrgani }) => {
  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '合同名称、编号',
      },
    },
    {
      name: 'contractStatus',
      type: 'Select',
      label: '合同状态',
      props: {
        placeholder: '合同状态',
        options: [
          { label: '未开启', value: 1 },
          { label: '进行中', value: 2 },
          { label: '已完成', value: 3 },
          { label: '终止', value: 4 },
        ],
      },
    },
    {
      name: 'busiAffairsIdList',
      type: 'Select',
      label: '归属商务',
      props: {
        placeholder: '商务',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.BUSINESSAFFAIRS]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
  ];
  return searchCloumns;
};

const created = ({ dispatch, setCustomerOptions, SelectOptionsTransform }) => {
  // 部门树
  dispatch({
    type: 'systemOrgani/getDepartmentPagelist',
  });
  // 所有员工
  dispatch({
    type: 'systemOrgani/getAllEmployee',
  });
  // 组织架构
  // dispatch({
  //   type: 'systemOrgani/getAllEmployee',
  // });
  // 所有客户
  dispatch({
    type: 'customer/getAllCustomer',
  }).then((res) => {
    setCustomerOptions(
      SelectOptionsTransform(res, null, {
        info: 'custCategory',
        datalist: 'customerList',
      }),
    );
  });
};

const getCard = (data) => {
  if (Object.keys(data).length == 0) {
    return null;
  }

  const tableCloumns = [
    {
      title: '收款批次',
      dataIndex: 'collectBatch',
    },
    {
      title: '收款金额',
      dataIndex: 'collectAmount',
      render: (val) => {
        if (React.isValidElement(val)) {
          return val;
        }
        return +(val / 100).toFixed(2);
      },
    },
    {
      title: '预计收款日期',
      dataIndex: 'collectDate',
    },
  ];

  const cloumns = [
    {
      label: '合同名称',
      value: 'contractName',
    },
    {
      label: '合同编号',
      value: 'contractNumber',
    },
    {
      label: '合同金额',
      value: 'contractAmount',
      render: (val) => {
        if (React.isValidElement(val)) {
          return val;
        }
        return +(val / 100).toFixed(2);
      },
    },
    {
      label: '合同税率',
      value: 'contractTaxRate',
      render: (val) => `${val}%`,
    },
    {
      label: '合同类型',
      value: 'contractTypeStr', // +++
    },
    {
      label: '合同归属年份',
      value: 'contractBelongYear',
    },
    {
      label: '客户名称',
      value: 'custCompname',
    },
    {
      label: '合同归属部门',
      value: 'contractBelongDeptStr', //++++
    },
    {
      label: '合同周期',
      value: 'contractTime',
      span: 20,
      // render: () => `${data.contractBeginTime} ~ ${data.contractEndTime}`,
      render: () => (
        <>
          {data.contractBeginTime}
          {' ~ '}
          {data.contractEndTime}
        </>
      ),
    },
    {
      label: '合同归属商务',
      value: 'contractBusiList',
      render: (list) => {
        return list?.map((item) => item.busiAffairs);
      },
    },
    {
      label: '合同电子版',
      value: 'contractMaterialList',
      render: (list) =>
        list
          .filter((item) => item.contractMaterialType == 1)
          .map((item, i) => (
            <a key={i} target="_blank" href={OSSImgUrl(item.contractMaterial)}>
              {item.contractMaterialName}
            </a>
          )),
    },
    {
      label: '验收单电子版',
      value: 'contractMaterialList',
      render: (list) =>
        list
          .filter((item) => item.contractMaterialType == 2)
          .map((item, i) => (
            <a key={i} target="_blank" href={OSSImgUrl(item.contractMaterial)}>
              {item.contractMaterialName}
            </a>
          )),
    },
    {
      label: '合同备注',
      span: 20,
      value: 'contractRemark',
    },

    {
      // label: '收款批次',
      span: 20,
      colRender: () => (
        <Table
          rowKey={(_, index) => index}
          columns={tableCloumns}
          pagination={false}
          dataSource={data.contractExpectCollectList || []}
        />
      ),
    },
  ];
  return (
    <Card className="process-card-box">{cardJSONRender(cloumns, data)}</Card>
  );
};

/** 确定 */
const onSubmit = ({
  dispatch,
  curData,
  setCreateBtnLoading,
  saveCacheData,
}) => {
  return (params) => {
    saveCacheData(params);
    setCreateBtnLoading(false);
  };
};

export default {
  key: busiTypeKey.CONTRACT,
  chooseId: 'contractId',
  propsName: (data) => data['contractName'],
  // cloumns,
  searchCloumns,
  formCloumns,
  firstSave,
  reSave,
  created,
  getCard,
  onSubmit,
};
