import Charts from 'echarts-for-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import { getFinanceProdBar } from '@/_serveice/usercenter';
import { DatePicker } from 'kenshin';

const defaultTime = () => [
  moment().subtract(7, 'day').startOf('date'),
  moment().subtract().endOf('date'),
];

const PieShade = (props) => {
  const [chartsData, setChartsData] = useState([]);

  useEffect(() => {
    getChartsData();
  }, []);

  const getChartsData = (param) => {
    param ||= {
      beginTime: defaultTime()[0].format('YYYY-MM-DD'),
      endTime: defaultTime()[1].format('YYYY-MM-DD'),
    };
    getFinanceProdBar(param).then((res) => {
      if (res?.success) {
        setChartsData(res.data);
      }
    });
  };

  const labelRight = {
    position: 'right',
  };

  const seriesData = useCallback(
    (type = 'actualProfitAmount', step = 100) => {
      return (
        chartsData.projectProfitList?.map((item) => ({
          label: labelRight,
          value: +(item[type] / step).toFixed(2),
        })) || []
      );
    },
    [chartsData],
  );
  const yAxisData = useMemo(() => {
    return chartsData.projectProfitList?.map((item) => item.projectName) || [];
  }, [chartsData]);

  let options = {
    title: {
      text: `总盈利金额:${
        (chartsData.profitTotalAmount / 100).toFixed(2) || 0
      }`,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (param) => {
        let name = param[0].name;
        return `<div>
        ${name}
         <div>${param[0].marker} ${param[0].seriesName} ${param[0].value}元</div>
         <div>${param[1].marker} ${param[1].seriesName} ${param[1].value}%</div>
        </div>`;
      },
    },
    grid: {
      top: 80,
      left: 10,
    },
    xAxis: [
      {
        type: 'value',
        position: 'bottom',
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        position: 'top',
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
    ],
    yAxis: {
      type: 'category',
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      data: yAxisData,
    },
    series: [
      {
        name: '实际盈利金额',
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          formatter: '{b}',
        },
        // itemStyle:{
        //   borderRadius:[5,0,0,5],
        // },
        data: seriesData(),
      },
      {
        name: '实际盈利百分比',
        type: 'bar',
        xAxisIndex: 1,
        // xAxisIndex: 2,
        // itemStyle:{
        //   borderRadius:[0,5,5,0],
        // },
        label: {
          show: false,
        },
        data: seriesData('actualProfitPercent', 1),
      },
    ],
  };

  let size = 200;

  const datePickChange = (time) => {
    if (time) {
      let param = {
        beginTime: time[0].format('YYYY-MM-DD'),
        endTime: time[1].format('YYYY-MM-DD'),
      };
      getChartsData(param);
    }
  };

  return (
    <>
      <DatePicker.RangePicker
        defaultValue={defaultTime()}
        onChange={datePickChange}
      />
      <Charts
        notMerge={false}
        option={options}
        lazyUpdate={true}
        style={{
          width: size + 210,
          minHeight: chartsData.projectProfitList?.length * 34 || 400,
          ...(props.style || {}),
        }}
      />
    </>
  );
};

export default PieShade;
