import { useEffect } from 'react';
import { history } from 'umi';
import {
  SelectOptionsTransform,
  beforeShowEditChangeData,
} from '@/pages/contract/list/util';
import busiTypeKey from '../../../_util/busiTypeKey';
const { CONTRACT, PROJECT, TASK, ROLE, OSAEDIT, WEEKEDIT } = busiTypeKey;
import { TypeKey } from '../_util/util';
import {
  filterServCatgList,
  moundSetData,
  memberListFilter,
} from '../_util/detailDataTransform';
import { findTree } from '@/components/ServCategoryList';
import { deepClone } from '@/_util';
import TaskUtil from '../../_unit/task';
import ContractUtil from '../../_unit/contract';
import OsaEditUtil from '../../_unit/osaEdit';

export const usePageEffect = ({
  process,
  dispatch,
  setCustomerOptions,
  SelectOptionsTransform,
  getServcatgForProjectId,
  customerOptions,
  systemOrgani,
  ref,
  system,
}) => {
  const enterType = history.location.query.type;
  useEffect(() => {
    if (process.changeInfo.params?.busiType == TASK)
      TaskUtil.created({ dispatch });
    if (process.changeInfo.params?.busiType == CONTRACT)
      ContractUtil.created({
        dispatch,
        setCustomerOptions,
        SelectOptionsTransform,
      });
    if (process.changeInfo.params?.busiType == PROJECT) {
      // 部门树
      dispatch({
        type: 'contract/getEasyContractList',
      });
      dispatch({
        type: 'project/getServcatgEasyList',
      });
    }
    console.log(process.changeInfo.params?.busiType);
    if (process.changeInfo.params?.busiType == OSAEDIT)
      OsaEditUtil.created({ dispatch });
    if (process.changeInfo.params?.busiType == WEEKEDIT)
      OsaEditUtil.created({ dispatch, type: WEEKEDIT });
  }, []);

  /** 统一设置回显的数据 */
  const setData = (data) => {
    const { form } = ref.current;
    form.setFieldsValue({ ...(data || process.changeInfo.data) });
  };

  /** 项目数据渲染 */
  const projectDataMound = (res) => {
    // let res = curData.current;
    res.projectTime = [
      moment(res.projectBeginTime),
      moment(res.projectEndTime),
    ];
    res.contractId = `${res.contractId}-${res.contractNumber}`;
    // contractChange(res.contractId);
    // let detailData = {
    //   data: deepClone(res.dbServCatgList) || [],
    //   keys: res.dbServCatgList?.map((item) => item.proServCatgId) || [],
    // };
    res.projectAmount = +(res.projectAmount / 100).toFixed(2);
    res.dbServCatgList = moundSetData(res.dbServCatgList, {
      employeeAll: systemOrgani.employeeAll,
      findTree,
    });
    return res;
  };

  useEffect(() => {
    if (process.changeInfo?.data) {
      if (process.changeInfo.params?.busiType == PROJECT) {
        //  项目
        if (enterType == TypeKey.EDITPROCESS) {
          const [start, end] = process.changeInfo.data.projectTime;
          process.changeInfo.data.projectTime = [moment(start), moment(end)];
          process.formData = process.changeInfo.data;
        } else {
          process.formData = projectDataMound(
            deepClone(process.changeInfo.data),
          );
        }
        setData(process.formData);
        return;
      } else if (process.changeInfo.params?.busiType == TASK) {
        //  任务
        getServcatgForProjectId(process.changeInfo.data.projectId, false);
        setData();
      } else if (process.changeInfo.params?.busiType == CONTRACT) {
        // 合同
        let newData = beforeShowEditChangeData(process.changeInfo.data, {
          system,
          customerOptions: { current: customerOptions },
        });
        setData(newData);
      } else if (process.changeInfo.params?.busiType == ROLE) {
        let data = process.changeInfo.data;
        if (data.expireDate) {
          data.expireDate = moment(data.expireDate);
        }
        setData();
      }
    }
  }, [process.changeInfo, customerOptions]);
};
