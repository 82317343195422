import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Col, message, MessageBox, Row, Avatar } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import TreeData from './components/TreeData';
import { useCreation } from 'ahooks';
import { exportEmployee } from '@/_serveice/admin/system';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import MoreButton from '../../../../components/moreButton';

export const empStatusArr = {
  0: <div className="status-red">禁用</div>,
  1: <div className="status-green animate">正常</div>,
  2: <div className="status-grey">离职</div>,
};

const Organizational = ({ dispatch, systemOrgani, system }) => {
  let tableRef = useRef();
  let modalRef = useRef();

  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  // 修改状态
  const [statusVisible, setStatusVisible] = useState(false);
  // 修改业务负责人
  const [chargeVisible, setChargeVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  const [deptId, setDeptId] = useState(1);

  useEffect(() => {
    // 角色列表
    dispatch({
      type: 'system/getAllRoleList',
    });
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    });
  }, []);

  // 显示编辑框
  const showEditModal = (row) => {
    setEditModalVisible(true);
    let roleList = [];
    let empEntryDate = null;
    let empLeaveDate = null;
    if (row) {
      if (row.empEntryDate) empEntryDate = moment(row.empEntryDate);
      if (row.empLeaveDate) empLeaveDate = moment(row.empLeaveDate);
      roleList = row.roleList.map((item) => item?.id);
      setShowManageDeptList(row.empIdentity == 2);
    }
    setEditModalData({ ...row, roleList, empEntryDate, empLeaveDate });
  };

  // 设置业务负责人弹窗
  const showCharge = (row) => {
    setChargeVisible(true);
    setEditModalData(row);
    if (row) {
      setStatusData([row.empId]);
    }
  };

  // 批量修改状态
  const batchStatus = (val) => {
    dispatch({
      type: 'systemOrgani/organiStatus',
      payload: val,
    }).then((res) => {
      if (res) {
        setStatusVisible(false);
        setStatusData(null);
      }
    });
  };

  const statusSave = (val) => {
    let updateEmpStatusDTO = {
      empIdList: statusData,
      empStatus: val.empStatus,
    };
    batchStatus(updateEmpStatusDTO);
  };
  const editModalSave = (resValue) => {
    let {
      returnEmpLoginPwd,
      empBorn,
      roleList,
      empEntryDate,
      empLeaveDate,
      ...restValue
    } = resValue;
    if (empEntryDate) {
      restValue.empEntryDate = moment(empEntryDate)
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (empLeaveDate) {
      restValue.empLeaveDate = moment(empLeaveDate)
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const [empBornCountry, empBornCity, empBornArea] = empBorn ?? [];
    roleList = roleList.map((item) => {
      let row = system.roleAlllist.find((role) => role.value == item);
      return {
        id: row?.value,
        value: row?.label,
      };
    });
    let payload = {
      ...restValue,
      empBornCountry,
      empBornCity,
      empBornArea,
      roleList,
    };
    let hasEdit = false;
    if (editModalData?.empId) {
      hasEdit = true;
      payload.empId = editModalData?.empId;
    }
    dispatch({
      type: 'systemOrgani/editOrgani',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const resetPwd = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认重置密码？',
      onOk: () => {
        dispatch({
          type: 'systemOrgani/resetPwd',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('重置成功');
          }
        });
      },
    });
  };

  const empSexObj = {
    1: '男',
    2: '女',
  };
  const columns = [
    {
      title: '工号',
      dataIndex: 'empNumber',
      fixed: 'left',
      width: 100,
    },
    {
      title: '姓名',
      fixed: 'left',
      dataIndex: 'empName',
      width: 100,
    },
    {
      title: '头像',
      dataIndex: 'empHeadImg',
      width: 100,
      render: (val, row) => (
        <Avatar
          size="large"
          src={val && (val?.startsWith('http') ? val : `${ossUrl}/${val}`)}
        >
          {row.empName}
        </Avatar>
      ),
    },
    {
      dataIndex: 'empJob',
      title: '职务',
      width: 100,
    },
    {
      dataIndex: 'belongDeptList',
      title: '部门',
      width: 150,
      render: (arr) => (
        <React.Fragment>
          {arr?.map((item, i) => (
            <div key={i}>{item.value}</div>
          ))}
        </React.Fragment>
      ),
    },
    {
      dataIndex: 'empMobile',
      title: '手机',
      width: 150,
    },
    {
      dataIndex: 'empEmail',
      title: '邮箱',
      width: 200,
    },
    {
      dataIndex: 'empEntryDate',
      title: '入职日期',
      width: 120,
      render: (val) => (val ? moment(val).format('YYYY-MM-DD') : '-'),
    },
    {
      dataIndex: 'empLeaveDate',
      title: '离职日期',
      width: 120,
      render: (val) => (val ? moment(val).format('YYYY-MM-DD') : '-'),
    },
    {
      dataIndex: 'empSex',
      title: '性别',
      width: 100,
      render: (val) => empSexObj[val],
    },
    {
      dataIndex: 'empStatus',
      title: '状态',
      width: 100,
      render: (val) => empStatusArr[val],
    },
    {
      title: '操作',
      key: 'options',
      width: 130,
      fixed: 'right',
      render: (row) => {
        return (
          <MoreButton>
            {row.hasbtnList?.includes(btnKeys.edit) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                编辑
              </Button>
            )}
            {row.hasbtnList?.includes(btnKeys.statusChange) &&
              row.empStatus != 2 &&
              !row.roleList?.some(
                (item) => item?.extendData == 'SUPERADMIN',
              ) && (
                <Button
                  type="text"
                  onClick={() => {
                    setStatusVisible(true);
                    setStatusData([row.empId]);
                  }}
                >
                  修改状态
                </Button>
              )}
            {row.hasbtnList?.includes(btnKeys.resetPWD) && (
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => resetPwd({ empId: row.empId })}
              >
                重置密码
              </Button>
            )}
            {row.hasbtnList?.includes(btnKeys.editup) && (
              <Button type="text" onClick={() => showCharge(row)}>
                业务负责人
              </Button>
            )}
          </MoreButton>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '姓名/工号/邮箱/职务/电话',
      },
    },
    {
      name: 'empSex',
      type: 'Select',
      props: {
        placeholder: '员工性别',
        allowClear: true,
        options: [
          { label: '男', value: 1 },
          { label: '女', value: 2 },
        ],
      },
    },
    {
      name: 'roleIdList',
      type: 'Select',
      props: {
        placeholder: '角色',
        options: system.roleAlllist,
        mode: 'multiple',
      },
    },
  ];

  const [showManageDeptList, setShowManageDeptList] = useState(false);

  // 弹窗formItem
  const edtaFormItem = useMemo(
    () =>
      [
        {
          label: '姓名',
          name: 'empName',
          type: 'Input',
          required: '请输入姓名',
          props: {
            placeholder: '请输入姓名',
          },
        },
        {
          label: '工号',
          name: 'empNumber',
          type: 'Input',
          required: '请输入工号',
          props: {
            placeholder: '请输入工号',
            disabled: !!editModalData?.empId,
          },
        },
        {
          label: '邮箱',
          name: 'empEmail',
          type: 'Input',
          required: '请输入邮箱',
          props: {
            placeholder: '请输入邮箱',
          },
        },
        {
          label: '联系电话',
          name: 'empMobile',
          type: 'Input',
          required: '请输入联系电话',
          props: {
            placeholder: '请输入联系电话',
          },
        },
        ...(() => {
          if (!editModalData?.empId) {
            return [
              {
                label: '密码',
                name: 'empLoginPwd',
                type: 'Password',
                required: '请输入密码',
                props: {
                  placeholder: '请输入密码',
                },
                formItemProps: {
                  hasFeedback: true,
                },
              },
              {
                label: '确认密码',
                name: 'returnEmpLoginPwd',
                type: 'Password',
                required: '请输入密码',
                props: {
                  placeholder: '请输入密码',
                },
                formItemProps: {
                  hasFeedback: true,
                  rules: [
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('empLoginPwd') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('两次输入的密码不一致！'),
                        );
                      },
                    }),
                  ],
                },
              },
            ];
          }
          return [];
        })(),
        {
          label: '选择角色',
          name: 'roleList',
          type: 'Select',
          required: '请选择角色',
          props: {
            placeholder: '请选择角色',
            options: system.roleAlllist,
            mode: 'multiple',
          },
        },
        {
          label: '性别',
          name: 'empSex',
          type: 'RadioGroup',
          required: '请选择性别',
          props: {
            options: [
              {
                label: '男',
                value: 1,
              },
              {
                label: '女',
                value: 2,
              },
              {
                label: '未知',
                value: 3,
              },
            ],
          },
        },
        {
          label: '入职日期',
          name: 'empEntryDate',
          type: 'DatePicker',
        },
        editModalData?.empId
          ? {
              label: '离职日期',
              name: 'empLeaveDate',
              type: 'DatePicker',
              props: {
                allowClear: editModalData?.empStatus != 2,
              },
            }
          : null,
        {
          label: '所属部门',
          name: 'belongDeptList',
          type: 'ChooseDepartment',
          required: '请选择所属部门',
          props: {},
        },
        {
          label: '员工头像',
          name: 'empHeadImg',
          type: 'UploadImg',
          props: {
            dirUrl: '/employee/avatar',
            accept: 'image/*',
          },
        },
        {
          label: '身份',
          name: 'empIdentity',
          type: 'Select',
          required: '请选择所属部门',
          props: {
            placeholder: '请选择身份',
            onChange: (value) => {
              setShowManageDeptList(value == 2);
            },
            options: [
              {
                label: '员工',
                value: 1,
              },
              {
                label: '上级',
                value: 2,
              },
            ],
          },
        },
        ...(() => {
          if (showManageDeptList) {
            return [
              {
                label: '负责部门',
                name: 'manageDeptList',
                type: 'ChooseDepartment',
                props: {},
              },
            ];
          }
          return [];
        })(),
        {
          label: '职务',
          name: 'empJob',
          type: 'Input',
          props: {
            placeholder: '请输入职务',
          },
        },
      ].filter(Boolean),
    [showManageDeptList, editModalData],
  );

  const extra = [
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增员工
    </Button>,
    <Button
      key={btnKeys.statusChange}
      onClick={() => {
        if (statusData?.length <= 0) {
          message.error('请选择批量操作的员工！');
          return;
        }
        setStatusVisible(true);
      }}
    >
      批量修改员工状态
    </Button>,
    <Button
      key="noKeys"
      onClick={() => {
        let value = tableRef.value;
        let payload = {
          deptId: deptId ?? '',
          empSex: value.empSex ?? '',
          key: value.key ?? '',
        };
        let url = `${baseURL}${exportEmployee}${splitParamsFoGet(payload)}`;
        downloadXlsx(url, '员工列表');
      }}
    >
      导出
    </Button>,
    <Button
      key={btnKeys.editup}
      onClick={() => {
        if (statusData.length == 0) {
          message.warning('请选择员工！');
          return;
        }
        showCharge(null);
      }}
    >
      批量设置业务负责人
    </Button>,
  ];
  const treeHandleChange = (keys) => {
    setDeptId(keys[0]);
    tableRef.reload({ deptId: keys[0] }, true);
  };

  const chargeSave = (payload) => {
    dispatch({
      type: 'systemOrgani/setemployeebusileader',
      payload: {
        busiLeader: payload.busiLeader.split('-')[1],
        muIdList: statusData,
      },
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setStatusData([]);
        setChargeVisible(false);
      }
    });
  };
  const chargeCloumns = [
    {
      label: '负责人',
      name: 'busiLeader',
      type: 'Select',
      required: '请选择负责人',
      props: {
        placeholder: '请选择责任人',
        options: systemOrgani.employeeAll || [],
      },
    },
  ];
  const statusFormItem = [
    {
      label: '状态',
      name: 'empStatus',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        options: [
          {
            label: '禁用',
            value: 0,
          },
          {
            label: '正常',
            value: 1,
          },
          {
            label: '离职',
            value: 2,
          },
        ],
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: statusData,
    onChange: (userIdList) => {
      setStatusData(userIdList);
    },
  };

  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData onChange={treeHandleChange} />
        </Col>
        <Col span={16}>
          <TablePro
            params={{ deptId }}
            request="systemOrgani/getOrganiList"
            version="systemOrgani"
            rowKey={'empId'}
            scrollX
            columns={columns}
            searchCloumns={searchCloumns}
            data={systemOrgani.organiList}
            extra={extra}
            actionRef={(ref) => (tableRef = ref)}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>

      <ModalFormPro
        title={editModalData?.empId ? '编辑' : '新增'}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        ref={modalRef}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />

      <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      />
      <ModalFormPro
        title={'设置业务负责人'}
        visible={chargeVisible}
        edtaFormItem={chargeCloumns}
        onSave={chargeSave}
        onCancel={() => {
          setEditModalData(null);
          setStatusData([]);
          setChargeVisible(false);
        }}
      />
    </>
  );
};

export default connect(({ systemOrgani, system, loading, user }) => {
  return {
    user,
    system,
    systemOrgani,
    loading: loading.effects,
  };
})(Organizational);
