export const attendanceKey = {
  /** 加班申请 */ OVERTIME: 10,
  /** 外出申请 */ OUT: 11,
  /** 忘打卡申请 */ FORGET: 12,
  /** 假期延期申请 */ DELAY: 13,
  /** 考勤撤销申请 */ CANCEL: 14,
  /** 假期申请 */ VACATION: 15,
  /** 假期申请 */ OVERVACATTION: 17,
  /**考勤变更 */ ATTENDANCECHANGE: 16,
};

export default {
  /** 客户 */ CUSTOMER: 1,
  /** 合同 */ CONTRACT: 2,
  /** 项目 */ PROJECT: 3,
  /** 任务 */ TASK: 4,
  /** 角色 */ ROLE: 5,
  /** OSA绩效 */ OSA: 6,
  /** 周计划 */ WEEK: 7,
  /** 绩效修改变更 */ OSAEDIT: 8,
  /**周计划修改变更 */ WEEKEDIT: 9,
  /**考勤变更 */ ATTENDANCECHANGE: 16,
  ...attendanceKey,
};
