import { DatePicker, Button, Tree } from 'kenshin';
import { useEffect, useState } from 'react';
import { connect } from 'umi';
import { getQuarterTime } from './_util';
import WeekTree from './components/weekTree';
import WeekCalendar from './components/WeekCalendar';

const weekTimeConfig = ({ dispatch, performance }) => {
  const [year, setYear] = useState(moment());

  useEffect(() => {
    getWeekPlanDateTree(year.year());
  }, []);

  const getWeekPlanDateTree = (osaWeekPlanYear) => {
    dispatch({
      type: 'performance/getyearweekplandatedata',
      payload: {
        osaWeekPlanYear,
      },
    });
  };

  const dateChange = (date) => {
    setYear(date);
    if (date) {
      getWeekPlanDateTree(date.year());
    }
  };

  const handleInitDate = () => {
    const osaWeekQuarterList = getQuarterTime(year.year());
    dispatch({
      type: 'performance/initweekplanyeardatedata',
      payload: {
        osaWeekPlanYear: year.year(),
        osaWeekQuarterList,
      },
    });
  };

  return (
    <div>
      <div style={{ width: '96%', margin: 'auto' }}>
        <DatePicker onChange={dateChange} value={year} picker="year" />
        <div style={{ marginTop: 10 }}>
          {performance.weekTimeTree.length == 0 && (
            <Button type="primary" onClick={handleInitDate}>
              初始化日期
            </Button>
          )}
          {performance.weekTimeTree.length > 0 && (
            <WeekCalendar
              treeData={performance.weekTimeTree}
              dispatch={dispatch}
              year={year.year()}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default connect(({ performance, system, loading, user }) => {
  return { performance };
})(weekTimeConfig);
