import Jsonform from '@/components/JsonForm';
import { DatePicker, InputNumber } from 'kenshin';
import { useEffect, useRef, useState } from 'react';

const Info = ({ onSave, defaultValue }) => {
  const [days, setDays] = useState(defaultValue ?? {});
  useEffect(() => {
    if (defaultValue) {
      let { osaTemplateEffectiveDate, osaTemplateExpiryDate } = defaultValue;
      osaTemplateEffectiveDate ??= moment().startOf('date');
      osaTemplateExpiryDate ??= moment().endOf('date');
      // setDays([
      //   moment(osaTemplateEffectiveDate),
      //   moment(osaTemplateExpiryDate),
      // ]);
      setDays({
        osaTemplateEffectiveDate,
        osaTemplateExpiryDate,
      });
    }
  }, [defaultValue]);

  // 标识是否有修改数据
  const hasChange = useRef(false);

  const handleChange = ([start, end]) => {
    let osaTemplateEffectiveDate = start.format('YYYY-MM-DD HH:mm:ss');
    let osaTemplateExpiryDate = end.format('YYYY-MM-DD HH:mm:ss');
    let value = {
      osaTemplateEffectiveDate,
      osaTemplateExpiryDate,
    };
    setDays(value);
    hasChange.current = true;
  };

  const handleLeave = () => {
    if (hasChange.current) {
      onSave?.(days);
      hasChange.current = false;
    }
  };
  return (
    <div className="flex-al-center border-card" onMouseLeave={handleLeave}>
      <span style={{ marginRight: 100 }}>模板有效期</span>
      生效日期：
      <DatePicker.RangePicker
        value={[
          moment(days.osaTemplateEffectiveDate),
          moment(days.osaTemplateExpiryDate),
        ]}
        onChange={handleChange}
        showTime={{
          defaultValue: [
            moment('00:00:00', 'HH:mm:ss'),
            moment('23:59:59', 'HH:mm:ss'),
          ],
        }}
      />
    </div>
  );
};

export default Info;
