import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Button,
  message,
  Table,
  Progress,
  Steps,
  Row,
  Col,
  MessageBox,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { timeDiff } from '@/_util';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportEmpRptRcd } from '@/_serveice/usercenter';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { SortAscendingOutlined } from '@ant-design/icons';
import SortIcon from '@/_assets/icon/sort.js';
import { useReactive } from 'ahooks';

const getTimeAOverTime = (val) => {
  let [start, end] = val;
  let overtime = 0; // 加班占比
  const maxEnd = moment(
    moment(start).set('h', 18).startOf('h').format('YYYY-MM-DD HH:mm:ss'),
  ); // 当天18点后算加班时间
  const minStart = moment(
    moment(start).set('h', 9).startOf('h').format('YYYY-MM-DD HH:mm:ss'),
  ); // 当天9点前算加班时间
  if (end > maxEnd) {
    overtime = timeDiff([start > maxEnd ? start : maxEnd, end]);
    end = maxEnd;
  }
  if (start < minStart) {
    overtime += timeDiff([start, end < minStart ? end : minStart]);
    start = minStart;
  }
  let time = timeDiff([start, end]);
  return {
    time,
    overtime,
  };
};

const ServCategory = ({ dispatch, loading, usercenter, setTableType }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [selectKey, setSelectKey] = useState([]);

  const modalRef = useRef({});
  const reportDate = useRef();

  const columns = [
    {
      dataIndex: 'reportDate',
      title: '上报日期',
    },
    {
      key: 'projectName',
      title: '项目名称',
      render: (row) => `${row.projectName}(${row.contractNumber})`,
    },
    {
      dataIndex: 'proServCatgNum',
      title: '工作包',
    },
    {
      dataIndex: 'proRptWorkHours',
      title: '上报工时',
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '项目/合同编号',
      },
    },
    {
      name: 'rptAuditStatus',
      type: 'Select',
      props: {
        placeholder: '状态',
        options: [
          {
            label: '待审核',
            value: 0,
          },
          {
            label: '审核通过',
            value: 1,
          },
          {
            label: '审核驳回',
            value: 2,
          },
        ],
      },
    },
    {
      name: 'workTimeLong',
      type: 'DatePickerRangePicker',
      props: {
        placeholder: ['工作开始时间', '工作结束时间'],
      },
    },
  ];

  // 禁用日期
  const disabledDate = useCallback(
    (current) => {
      return (
        current < moment(reportDate.current).startOf('day') ||
        current > moment(reportDate.current).endOf('day')
      );
    },
    [reportDate.current],
  );

  function range(start, end) {
    const result = [];
    for (let i = start; i <= end; i += 10) {
      result.push(i);
    }
    return result;
  }

  // 获取时间禁用的分钟，分钟禁用数量长度超过5则禁用小时（0,10,20,30,40,50）
  const disabledTimeObj = (h) => {
    // debugger
    let start = {},
      end = {};
    const getM = (item) => {
      let sh = moment(item.beginDate).get('h');
      let eh = moment(item.endDate).get('h');
      if (eh > sh) {
        return 60;
      }
      return moment(item.endDate).get('m');
    };
    usercenter.empCurTimesLot
      ?.filter((item) => {
        if (
          item.beginDate == +moment(editModalData.workBeginTime) &&
          item.endDate == +moment(editModalData.workEndTime)
        ) {
          return false;
        }
        return true;
      })
      .map((item) => {
        let sh = moment(item.beginDate).get('h');
        let eh = moment(item.endDate).get('h');
        start[+sh] ||= [];
        end[+eh] ||= [];
        let timeArr = range(moment(item.beginDate).get('m'), getM(item));
        start[+sh] = [...start[+sh], ...timeArr.slice(0, timeArr.length - 1)];
        end[+eh] = [...end[+eh], ...timeArr.slice(1, timeArr.length - 1)];
      });

    const disabledHours = (obj) => () =>
      Object.keys(obj)
        .map((item) => {
          if (obj[item].length >= 5) {
            return +item;
          }
        })
        .filter(Boolean);
    return {
      start: {
        disabledHours: disabledHours(start),
        disabledMinutes: () => start[h],
      },
      end: {
        disabledHours: disabledHours(end),
        disabledMinutes: () => end[h],
      },
    };
  };
  const disabledTime = (date, type) => {
    let curDate = moment(date);
    return disabledTimeObj(curDate.get('h'))[type];
  };

  // 请求设置修改上报模态窗数据
  const modalSetFileds = ({ rptDetailId, workBeginTime }) => {
    reportDate.current = workBeginTime;
    const { form } = modalRef.current;
    dispatch({
      type: 'usercenter/getEmpCurTimesLotList',
      payload: {
        empId: localStorage.getItem('muId'),
        reportDate: moment(workBeginTime).format('YYYY-MM-DD'),
      },
    });
    dispatch({
      type: 'usercenter/getReportEmpWorkDetail',
      payload: {
        rptDetailId,
      },
    }).then((res) => {
      let data = {
        ...res,
        workTime: [moment(res.workBeginTime), moment(res.workEndTime)],
      };

      form.setFieldsValue(data);
    });
  };

  const timeChange = (val) => {
    const { form } = modalRef.current;
    const { time, overtime } = getTimeAOverTime(val);
    form.setFieldsValue({
      rptWorkHours: time,
      rptWorkOvertimeHours: overtime,
    });
  };

  const showEditModal = (row) => {
    setEditModalData(row);
    setEditModalVisible(true);

    modalSetFileds(row);
  };

  const workType = [
    {
      label: '正常工作日',
      value: 1,
    },
    {
      label: '有申请加班调休',
      value: 2,
    },
  ];
  const rptworkType = [
    { label: '正常上报', value: 1 },
    { label: '补报', value: 2 },
  ];

  const editModalSave = (val) => {
    let payload = {
      ...editModalData,
      ...val,
    };
    if (payload.workTime) {
      let [workBeginTime, workEndTime] = payload.workTime;
      payload.workBeginTime = moment(workBeginTime).format(
        'YYYY-MM-DD HH:mm:ss',
      );
      payload.workEndTime = moment(workEndTime).format('YYYY-MM-DD HH:mm:ss');
      payload.workTime = undefined;
    }
    // 测试 的电脑无法覆盖原数据，强制修改数据
    payload.rptWorkTotalHours = undefined;
    payload.overTimePercent = undefined;
    // 强制修改数据end
    payload.rptWorkTotalHours =
      +payload.rptWorkHours + +payload.rptWorkOvertimeHours;
    payload.overTimePercent =
      (payload.rptWorkOvertimeHours / payload.rptWorkTotalHours).toFixed(2) ||
      0;
    dispatch({
      type: 'usercenter/updateReportEmpWorkDetail',
      payload: payload,
    }).then((res) => {
      if (res) {
        setEditModalData({});
        setEditModalVisible(false);
      }
    });
  };

  const [datePanelOpen, setDatePanelOpen] = useState(false);

  const edtaFormItem = [
    {
      label: '已上报时间段',
      render: () =>
        usercenter.empCurTimesLot?.map((item, i) => {
          let start = moment(item.beginDate).format('YYYY-MM-DD HH:mm:ss');
          let end = moment(item.endDate).format('YYYY-MM-DD HH:mm:ss');
          return (
            <div key={i} style={{ color: '#4389f9' }}>
              {moment(item.beginDate).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
              {moment(item.endDate).format('YYYY-MM-DD HH:mm:ss')}
              <span style={{ marginLeft: 10, color: '#4389f9' }}>
                {item.remark}
              </span>
            </div>
          );
        }),
    },
    {
      name: 'workTime',
      label: '本次上报工时',
      type: 'DatePickerRangePicker',
      props: {
        disabledDate,
        disabledTime,
        onOpenChange: (open) => open && setDatePanelOpen(open),
        onCalendarChange: (dates) => {
          if (dates?.[0] && dates?.[1]) setDatePanelOpen(false);
        },
        open: datePanelOpen,
        showTime: {
          format: 'HH:mm',
          defaultValue: [moment('00', 'ss'), moment('00', 'ss')],
          minuteStep: 10,
        },
        onChange: timeChange,
      },
    },
    {
      name: 'rptWorkHours',
      label: '标准上班工时',
      type: 'Input',
      props: {
        placeholder: '标准上班工时',
        disabled: true,
      },
    },
    {
      name: 'rptWorkOvertimeHours',
      label: '加班工时',
      type: 'Input',
      props: {
        placeholder: '加班工时',
        disabled: true,
      },
    },
    {
      name: 'workHoursType',
      label: '工作类型',
      type: 'RadioGroup',
      props: {
        placeholder: '请输入工作类型',
        options: workType,
      },
    },
    {
      name: 'rptType',
      label: '上报类型',
      type: 'RadioGroup',
      props: {
        placeholder: '请输入上报类型',
        options: rptworkType,
        disabled: true,
      },
    },
    {
      name: 'rptRemark',
      label: '补充说明',
      type: 'TextArea',
      props: {
        placeholder: '请输入补充说明',
      },
    },
  ];

  const delLog = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'usercenter/deletereportworkdetail',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };
  const expandedRowRender = ({ projectId, projectName, rptWorkDetailList }) => {
    const rptAuditStatusObj = {
      0: <div className="status-grey">待审核</div>,
      1: <div className="status-green animate">审核通过</div>,
      2: <div className="status-red">审核驳回</div>,
    };
    const columns = [
      {
        title: '记录日期',
        dataIndex: 'gmtCreate',
        render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '任务名称',
        dataIndex: 'taskName',
      },
      {
        title: '服务类目',
        dataIndex: 'proServCatgName',
      },
      {
        title: '耗费工时',
        dataIndex: 'rptWorkTotalHours',
      },
      {
        title: '具体时间段',
        key: 'servCatgBeginTime',
        render: (row) =>
          `${moment(row?.workBeginTime).format('HH:mm:ss')} ~ ${moment(
            row?.workEndTime,
          ).format('HH:mm:ss')}`,
      },
      {
        title: '描述',
        width: 200,
        dataIndex: 'rptRemark',
        render: (val) => <EllipsisTooltip value={val} width={200} />,
      },
      {
        title: '状态',
        dataIndex: 'rptAuditStatus',
        render: (val) => rptAuditStatusObj[val],
      },
      {
        title: '操作',
        key: 'options',
        render: (row) => (
          <>
            <Button type="text" onClick={() => showEditModal(row)}>
              编辑
            </Button>
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delLog({ rptDetailId: row.rptDetailId })}
            >
              删除
            </Button>
          </>
        ),
      },
    ];

    return (
      <Table
        rowKey={(row) => `${projectId}-${row.proServCatgId}-${row.rptDetailId}`}
        columns={columns}
        curColumns
        dataSource={rptWorkDetailList}
        pagination={false}
      />
    );
  };

  const tableRef = useRef();
  const extra = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        type="primary"
        key="noKeys"
        onClick={() => {
          let value = tableRef.current.value;
          let rptBeginDate = '';
          let rptEndDate = '';
          if (value.workTimeLong) {
            const [start, end] = value.workTimeLong;
            rptBeginDate = moment(start).format('YYYY-MM-DD');
            rptEndDate = moment(end).format('YYYY-MM-DD');
          }
          let payload = {
            key: value.key ?? '',
            rptAuditStatus: value.rptAuditStatus ?? '',
            rptBeginDate,
            rptEndDate,
          };
          let url = `${baseURL}${exportEmpRptRcd}${splitParamsFoGet(payload)}`;
          downloadXlsx(url, '工作记录');
        }}
      >
        导出
      </Button>
      <Button
        type="primary"
        style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
        onClick={() => setTableType(2)}
      >
        {SortIcon}
        按上报分类显示
      </Button>
    </div>
  );

  const onSubmit = (payload) => {
    if (payload.workTimeLong) {
      const [start, end] = payload.workTimeLong;
      payload.workBeginTimeLong = +moment(start).startOf('d');
      payload.workEndTimeLong = +moment(end).endOf('d');
    }
    payload.workTimeLong = undefined;
  };

  return (
    <div>
      <TablePro
        params={{ empIdString: localStorage.getItem('muId') }}
        request="usercenter/getEmpWorkRptPage"
        rowKey={(row) => `${row.reportDate}-${row.projectId}`}
        extra={extra}
        actionRef={(ref) => (tableRef.current = ref)}
        columns={columns}
        searchCloumns={searchCloumns}
        data={usercenter.empWorkRptList}
        onSubmit={onSubmit}
        expandable={{ expandedRowRender, expandedRowKeys: selectKey, onExpandedRowsChange: setSelectKey }}
        onRow={row => {
          return {
            onClick: event => {
              let key = `${row.reportDate}-${row.projectId}`
              let newKeys = [...selectKey];
              let i = newKeys.indexOf(key)
              if (i != -1) newKeys.splice(i, 1);
              else newKeys.push(key)
              setSelectKey(newKeys)
            },
          };
        }}
      />

      <ModalFormPro
        width={800}
        title="编辑"
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setDatePanelOpen(false);
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ usercenter, loading }) => {
  return {
    usercenter,
    loading: loading.effects,
  };
})(ServCategory);
