import { history } from 'umi';

Array.prototype.flat ??= function (dept) {
  if (dept > 0)
    return this.reduce(
      (pre, cur) => pre.concat(Array.isArray(cur) ? cur.flat(dept - 1) : cur),
      [],
    );
  return this.slice();
};
Array.prototype.lastIndexOf ??= function (element) {
  // 实现自定义的 lastIndexOf() 方法
  for (var i = this.length - 1; i >= 0; i--) {
    if (this[i] === element) {
      return i;
    }
  }
  return -1;
};

export function render(oldRender) {
  let htmlLoading = document.querySelectorAll('.htmlLoading')?.[0];
  htmlLoading && document.body.removeChild(htmlLoading);
  oldRender();
}

// 路由拦截
export function onRouteChange({ location, routes, action }) {
  let token = localStorage.getItem('token');
  if (!token && !location.pathname.includes('Login')) {
    history.push('/user/Login');
  }
}
