import { timeDiff, flat, deepClone } from '@/_util';
import { findAddressTree } from '../../../components/AddressSelect';
import {
  getattendanceupdowndaysquery,
  getattendancehourtimequery,
} from '../../../_serveice/attendance';

const CERTKEY = 'COMMIT:PROVE:MATERIAL';

/** 将带有部门分类的员工列表扁平化 */
function employeeListAllOptions(empList) {
  console.log('employeeListAllOptions', empList);
  if (!Array.isArray(empList)) return [];
  let arr = empList
    .map((item) => {
      return item.options.map((item) => ({
        ...item,
        value: item.value.split('-')[item.value.split('-').length - 1],
      }));
    })
    .flat(2);
  return arr;
}

let idCacheMap = new Map();
// 调试缓存
// 树遍历查找
export const findTree = (fildTree = [], id) => {
  if (id == null || id == undefined || fildTree.length == 0) {
    return undefined;
  }
  let hasH = /-/.test(id);
  let cacheId = hasH ? id.split('-')[1] : id;
  // 缓存机制，多次调用查找树，存在缓存中则取缓存
  if (idCacheMap.get(cacheId)) {
    return idCacheMap.get(cacheId);
  }
  let resData = flat(
    fildTree.map((item) => item.options),
    2,
  ).find((item) => {
    if (!hasH && item.value?.split('-')[1] == id) {
      return true;
    }
    if (hasH && item.value == id) {
      return true;
    }
  });
  // baseNum++;
  idCacheMap.set(cacheId, resData);
  return resData;
};

const findItemById = (id, data) => {
  if (!data || data.length === 0) return null;
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.value?.split('-')[0] === id) {
      console.log('findItemById...', item);

      var index = item.label.indexOf('(');
      if (index !== -1) {
        item.label = item.label.substring(0, index).trim();
      }

      return item;
    }
  }

  return null;
};

/** 处理申请类型id */
export const handleBusiType = (str) => {
  let strArr = str.split('-');
  return strArr[strArr.length - 1];
};
/** 处理时间选框组件 */
export const momentTimeFormat = ([start, end]) => [
  moment(start).format('YYYY-MM-DD HH:mm:ss'),
  moment(end).format('YYYY-MM-DD HH:mm:ss'),
];

/** 处理申请名称：例如 张三的调休申请：xxxx~xxxx */
export const handleAttendanceApplyName = (
  payload,
  employeeAll,
  employeeListAll,
) => {
  if (!localStorage.getItem('employeeDTO')) return null;
  let name = JSON.parse(localStorage.getItem('employeeDTO')).empName;
  let typeName = payload.typeName;
  let time = `${payload.applyBeginTime}~${payload.applyEndTime}`;
  if (payload.applyBusiType == 12 && payload.forgetClockApplyType) {
    // 漏打卡是上班还是下班
    let typeObj = {
      1: '上班',
      2: '下班',
      3: '外出',
    };
    typeName = typeObj[payload.forgetClockApplyType] + typeName;
  }
  if (payload.partinList?.length > 0 && employeeAll) {
    name = payload.partinList
      .map((item) => {
        let empList = employeeAll;
        console.log(
          'employeeListAllOptions',
          payload.applyBusiType,
          payload.partinList,
        );
        if (payload.applyBusiType == 11) {
          empList = employeeListAllOptions(deepClone(employeeListAll));
        }
        let target = empList.find((emp) => emp.value == item.partinId);
        return target?.label;
      })
      .join('、');
  }
  if (payload.vacationList?.length > 0 && payload.typeList) {
    typeName = payload.vacationList
      .map((item) => findItemById(item.vacationId, payload.typeList))
      .filter(Boolean)
      .map((item) => item.label)
      .join('、');
    time = payload.vacationList
      .map((item) => `${item.applyBeginTime}~${item.applyEndTime}`)
      .join('、');
  }

  let name_arr = [];

  if (payload.partinList?.length > 0) {
    payload.partinList.map((item) => {
      let empList = employeeListAllOptions(deepClone(employeeListAll));
      return empList.map((emp) => {
        if (emp.value == item.partinId) {
          console.log('遍历', emp, item.partinId);

          name_arr.push(emp.label);
        }
      });
    });
  }

  if (payload.forgetClockProverName) {
    //如果是忘打卡申请有证明人
    name_arr.push(payload.forgetClockProverName);
  }

  //去重
  name_arr = name_arr.filter((item, index) => name_arr.indexOf(item) === index);

  if (name_arr?.length > 0) {
    const empName = JSON.parse(localStorage.getItem('employeeDTO')).empName;
    if (payload.applyBusiType == 11) {
      //外出申请
      if (payload.partinList?.length > 1) {
        name_arr = name_arr.filter((item) => item != empName);
        console.log('name_arr==============', name_arr);
        name = empName + '，协同人：' + name_arr.join('、');
      } else {
        name = name_arr.join('、');
      }
    } else if (payload.applyBusiType == 10 || payload.applyBusiType == 13) {
      //加班申请 假期延期
      if (payload.partinList?.length > 0) {
        if (payload.partinList?.length == 1 && empName == name_arr[0]) {
          name = name_arr.join('、');
        } else {
          name = empName + '，参与人：' + name_arr.join('、');
        }
      } else {
        name = name_arr.join('、');
      }
    } else if (payload.applyBusiType == 12) {
      //忘打卡
      // if (payload.partinList?.length > 0) {
      if (name_arr?.length > 1) {
        console.log('证明人', payload.partinList);
        name = name_arr.shift() + '，证明人：' + name_arr.join('、');
      } else {
        name = name_arr.join('、');
      }
    } else {
      name = name_arr.join('、');
    }
  }

  console.log('申请文案，发起：', name, '参与：', payload.partinList);

  if (payload.forgetClockApplyDate) time = payload.forgetClockApplyDate;
  if (payload.applyDelayTime) time = payload.applyDelayTime;
  return `发起人:${name}的${typeName}：${time}`;
};

/**
 * 获取时间数组中的最小开始时间和最大结束时间
 * @param {Array} timeArr 时间数组，格式为[[开始时间1, 结束时间1], [开始时间2, 结束时间2], ...]
 * @returns {Array} 返回最小开始时间和最大结束时间，格式为[最小开始时间, 最大结束时间]
 */
export const getMinStartTimeAndMaxEndTime = (timeArr) => {
  let minStartTime = moment(timeArr[0][0]);
  let maxEndTime = moment(timeArr[0][1]);
  for (let i = 1; i < timeArr.length; i++) {
    let startTime = moment(timeArr[i][0]);
    let endTime = moment(timeArr[i][1]);
    if (startTime.isBefore(minStartTime)) {
      minStartTime = startTime;
    }
    if (endTime.isAfter(maxEndTime)) {
      maxEndTime = endTime;
    }
  }
  return [
    minStartTime.format('YYYY-MM-DD HH:mm:ss'),
    maxEndTime.format('YYYY-MM-DD HH:mm:ss'),
  ];
};

/** 假期申请的时长类型：1：一天以内(小于7.5H)；2：一天及以上(大于等于7.5H)；(该字段假期申请时有效) */
export const getVacationApplyTimeType = (timeArr) => {
  let [applyBeginTime, applyEndTime] = getMinStartTimeAndMaxEndTime(timeArr);
  let payload = { applyBeginTime, applyEndTime };
  let h = timeDiff([payload.applyBeginTime, payload.applyEndTime], 'hours', 4);
  let days = moment(payload.applyEndTime).diff(
    moment(payload.applyBeginTime),
    'days',
  );
  if (h >= 7.5 || days > 0) {
    return 2;
  }
  return 1;
};

/** 自己申请的时候，处理申请人员 */
export const handleApplyMuId = (payload) => {
  if (!localStorage.getItem('employeeDTO')) return null;
  let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
  if (payload.processApplyMethod == 1) {
    return [{ partinId: empId, partinSort: 1 }];
  }
};

/** 代他人申请的时候处理申请人员 */
export const handlePartinList = (payload) => {
  if (!localStorage.getItem('employeeDTO')) return null;
  let id = JSON.parse(localStorage.getItem('employeeDTO')).empId;
  if (payload.partinList.findIndex((item) => item == id) == -1) {
    id = null;
  }
  return [
    ...[...new Set(payload.partinList)].filter(
      (item) => Boolean(item) && item != id,
    ),
    id, // 自己要放在最后
  ]
    .filter(Boolean)
    .map((item, index) => ({
      partinId: item.split('-')?.[item.split('-').length - 1],
      partinSort: index + 1,
    }))
    .filter(Boolean);
};

export const handleOutPartinList = (payload) => {
  return payload.partinList.map((item, i) => ({
    partinId: item.split('-')[item.split('-').length - 1],
    partinSort: i + 1,
  }));
};

/**
 * 判断两个时间区间是否有交集
 * @param {Array} time1 时间区间1，格式为[开始时间1, 结束时间1]
 * @param {Array} time2 时间区间2，格式为[开始时间2, 结束时间2]
 * @returns {Boolean} 是否有交集
 */
const isTimeOverlap = (time1, time2) => {
  const start1 = moment(time1[0]).startOf('s');
  const end1 = moment(time1[1]).startOf('s');
  const start2 = moment(time2[0]).startOf('s');
  const end2 = moment(time2[1]).startOf('s');
  return (
    moment(start1).isBefore(moment(end2)) &&
    moment(start2).isBefore(moment(end1))
  );
};

/**
 * 校验多个时间区间数组里面的时间是否存在交集
 * @param {Array} timeArr 时间区间数组，格式为[[开始时间1, 结束时间1], [开始时间2, 结束时间2], ...]
 * @returns {Boolean} 是否存在交集
 */
export const isTimeArrOverlap = (timeArr) => {
  for (let i = 0; i < timeArr.filter((item) => item?.[0]).length - 1; i++) {
    for (let j = i + 1; j < timeArr.length; j++) {
      if (isTimeOverlap(timeArr[i], timeArr[j])) {
        return true;
      }
    }
  }
  return false;
};

export const isTimeArrOverlap2 = (timeArr, timeArr2) => {
  for (let i = 0; i < timeArr.filter((item) => item?.[0]).length - 1; i++) {
    for (let j = i + 1; j < timeArr2.length; j++) {
      if (isTimeOverlap(timeArr[i], timeArr2[j])) {
        return true;
      }
    }
  }
  return false;
};

/**
 * 将传入的时间分为若干个上半天和下半天，上半天3小时，下半天4.5小时，返回格式数组[上半天,下半天]
 */
export const divideTimeIntoHalfDays = (totalHours) => {
  let morningCount = 0;
  let afternoonCount = 0;

  while (totalHours >= 7.5) {
    // 可以完整分配一组上下午
    totalHours -= 7.5; // 减去一个完整的一天上下午时间
    morningCount++; // 上午数量加1
    afternoonCount++; // 下午数量加1
  }

  // 如果剩余时间大于等于3小时，则还可以额外分配一个上午
  if (totalHours >= 3) {
    morningCount++;
    totalHours -= 3;
  }

  // 剩余时间不可能大于4.5小时，因为已经按最大上午时长进行了扣除

  return [morningCount, afternoonCount];
};

/**
 * 将表单上传的文件数组转换为指定格式的数组
 * @param {Array} fileList 表单上传的文件数组
 * @returns {Array} 转换后的数组
 */
export const transformFileList = (fileList = []) => {
  return fileList?.map((file, index) => {
    return {
      attachmentId: file.attachmentId,
      attachmentName: file.name ?? file.attachmentName,
      attachmentSort: index + 1,
      attachmentType: file.type?.startsWith('image')
        ? 1
        : 2 ?? file.attachmentType,
      attachmentUrl: file.url ?? file.attachmentUrl,
    };
  });
};

/** 获取假期余额的请求参数 */
// export const getHolidayNumPayload = (list) => {
//   if(!Array.isArray(list)) throw new Error('list 不是数组')
//   let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
//   let arr = list.find(item => item.id == 15)?.childList
//   let empIdList = [empId]
//   let vacationIdList = arr.map(item => item.id)
//   return {empIdList,vacationIdList}
// }

/** 处理 */
export const handleOvertimeYearMap = (values) => {
  const getStartTime = (time) =>
    moment(time).startOf('year').set({ h: 9, m: 0, s: 0 });
  const getEndTime = (time) =>
    moment(time).endOf('year').set({ h: 18, m: 0, s: 0 });
  let preYear = moment(values.applyBeginTime).year();
  let nextYear = moment(values.applyEndTime).year();
  let preHours = timeDiff([
    moment(values.applyBeginTime),
    getEndTime(values.applyBeginTime),
  ]);
  let nextHours = timeDiff([
    getStartTime(values.applyEndTime),
    moment(values.applyEndTime),
  ]);
  let res = {
    [preYear]: preHours,
    [nextYear]: nextHours,
  };
  console.log(res);
  return res;
  // payload.overtimeYearMap
};

export const handleYearMap = (values) => {
  let preYear = moment(values.applyBeginTime).year();
  let nextYear = moment(values.applyEndTime).year();
  let res = handleOvertimeYearMap(values);

  let preYearHour = res[preYear];
  let nextYearHour = res[nextYear];

  let preYearHour_divides = divideTimeIntoHalfDays(preYearHour);
  let nextYearHour_divides = divideTimeIntoHalfDays(nextYearHour);

  res[preYear] = {
    hours: preYearHour,
    upDays: preYearHour_divides[0],
    downDays: preYearHour_divides[1],
  };
  res[nextYear] = {
    hours: nextYearHour,
    upDays: nextYearHour_divides[0],
    downDays: nextYearHour_divides[1],
  };

  console.log('handleYearMap...', res);
  return res;
  // payload.overtimeYearMap
};

export const getApplyTimeHourByYearMap = (applyExtend) => {
  // 创建一个新的对象用于存储累加后的结果
  const accumulatedData = {
    applyTimeHour: 0,
    applyOvertimeUpDays: 0,
    applyOvertimeDownDays: 0,
  };

  // 遍历原对象
  for (const year in applyExtend) {
    if (applyExtend.hasOwnProperty(year)) {
      const dataForYear = applyExtend[year];

      //  // 如果累计对象中还没有该年份，则初始化为当前年份的数据
      //  if (!accumulatedData[year]) {
      //    accumulatedData[year] = { hours: dataForYear.hours, upDays: dataForYear.upDays, downDays: dataForYear.downDays };
      //  } else {
      // 如果已有该年份数据，则累加
      accumulatedData.applyTimeHour += dataForYear.hours;
      accumulatedData.applyOvertimeUpDays += dataForYear.upDays;
      accumulatedData.applyOvertimeDownDays += dataForYear.downDays;
    }
    //  }
  }
  //  console.log("accumulatedData...",accumulatedData);
  return accumulatedData;
};

function isNumeric(num) {
  return !isNaN(parseFloat(num)) && isFinite(num);
}

//调取接口获取上下半天数据
export const getattendanceupdowndays = (value) => {
  // console.log(
  //   '校验加班参数333...',
  //   value?.applyBusiType,
  //   isNumeric(value?.applyBusiType),
  // );
  if (!isNumeric(value?.applyBusiType)) {
    return;
  }
  let updowndaysquery = {
    attendanceType: value?.applyBusiType == 10 ? 1 : 2,
    beginTime: value.applyBeginTime,
    endTime: value.applyEndTime,
  };
  if (value?.applyBusiType == 10) {
    //加班
    updowndaysquery = {
      overtimeFreeType: value?.overtimeFreeType,
      overtimeType: value.applyOvertimeType == 1 ? 1 : 2,
      ...updowndaysquery,
    };
  }
  if (value?.applyBusiType == 15) {
    //调休
    updowndaysquery = {
      ...updowndaysquery,
      beginUpDown: isTimeInRange(value.applyBeginTime),
      endUpDown: isTimeInRange(value.applyEndTime, true),
      beginTime: value.applyBeginTime.split(' ')[0],
      endTime: value.applyEndTime.split(' ')[0],
    };
  }
  return getattendanceupdowndaysquery(updowndaysquery);
  // .then((res) => {
  //   if (res) {
  //     return res.data;
  //   }
  // });
};

//查询考勤时间(除了:加班|调休)
export const getattendancehourtime = (value) => {
  console.log('查询考勤时间(除了:加班|调休)', value);

  let hourTimeQuery = {
    attendanceType: value.applyBusiType,
    beginTime: value.applyBeginTime,
    endTime: value.applyEndTime,
    vacationCode: value.applyType?.split('-')[1],
  };

  return getattendancehourtimequery(hourTimeQuery);
  // .then((res) => {
  //   if (res) {
  //     return res.data;
  //   }
  // });
};

//判断时间是上半天还是下半天
function isTimeInRange(timeString, isEnd = false) {
  // 解析传入的时间字符串为Date对象
  const time = new Date(timeString);

  // 获取当前小时数（0-23）
  const currentHour = time.getHours();
  const currentMinutes = time.getMinutes();

  // 判断时间范围
  if (!isEnd) {
    //如果结束时间为12:00,计算为下午
    if (currentHour == 12 && currentMinutes == 0) {
      return 2;
    }
  }

  if (currentHour >= 9 && currentHour <= 12) {
    return 1;
  }

  if (isEnd) {
    //如果结束时间为13:30,计算为上午
    if (currentHour == 13 && currentMinutes == 30) {
      return 1;
    }
  }

  if (currentHour >= 13 && currentHour <= 18) {
    return 2;
  }
}

//  ===========================================查看详情 =================================================
export const transformAttendanceApplyDetail = (
  data,
  { applyTypeList, typeSelectChange, state, isShowStr },
) => {
  if (!data) return;
  data.applyBusiType = data.applyBusiType.toString(); // 申请类型
  // state.type = data.applyBusiType;

  if (applyTypeList) {
    let row = applyTypeList.find((item) => item.id == data.applyBusiType); // 假期类型的申请
    if (row) {
      if (row.childList?.length > 0) {
        row.childList = row.childList.map((child) => ({
          ...child,
          label: child.name,
          value: `${child.id}-${child.code}`,
          // remark: item.vacationRemark,
        }));
      }
      typeSelectChange(data.applyBusiType, {
        ...row,
        label: row.name,
        remark: row.vacationRemark,
      });
    }
  }

  state.applyType = data.processApplyMethod; // 申请方式
  if (['11', '13'].includes(data.applyBusiType)) {
    // 外出协同人
    data.partinList = data.partinList.map((item) => {
      if (data.applyBusiType == 13) return item.partinId; // 假期延期参与人
      return `${item.deptId}-${item.partinId}`;
    });
  } else if (data.partinList) {
    data.partinList = data.partinList.map((item) => item.partinId);
  }
  if (data.applyBusiType == 12) {
    // 忘打卡
    state.outType = data.forgetClockApplyType;
    let forgetClock = [
      data.applyOutProvinceCode,
      data.applyOutCityCode,
      data.applyOutAreaCode,
      data.applyBusiId,
      data.applyOutCompany,
    ].join('/');
    if (isShowStr) {
      forgetClock = [
        data.applyOutProvinceName,
        data.applyOutCityName,
        data.applyOutAreaName,
        data.applyOutCompany,
      ].join('/');
    }
    data.forgetClock = forgetClock;
  }

  // 处理时间
  if (data.applyBeginTime && data.applyEndTime) {
    data.applyTime = [moment(data.applyBeginTime), moment(data.applyEndTime)];
  }
  if (data.applyDelayTime && data.applyDelayTime) {
    //假期延迟
    data.applyDelayTime = moment(data.applyDelayTime);
  }

  console.log('data.vacationList...', data.vacationList);

  // 处理假期类型
  data.vacationList = data.vacationList?.map((item) => ({
    applyTime: [moment(item.applyBeginTime), moment(item.applyEndTime)],
    applyType: `${item.vacationId}-${item.vacationCode}`,
    vacationRcdId: item.vacationRcdId,
    applyTimeHour: item.applyTimeHour,
    applyUpDays: item.applyUpDays,
    applyDownDays: item.applyDownDays,
  }));
  // 处理附件
  data.attachmentList = data.attachmentList?.map((item) => {
    console.log('处理附件...', item);
    return {
      name: item.attachmentName,
      url: item.attachmentUrl,
      status: 'done',
      key: item.attachmentUrl,
      ...item,
    };
  });
  let proveMaterialList = Object.values(data.proveMateraiMap || {}).flat(2);
  if (data == CERTKEY) {
    let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
    proveMaterialList = data.proveMateraiMap?.[empId];
  }
  data.proveMaterialList = proveMaterialList?.map((item) => {
    return {
      name: item.attachmentName,
      url: item.attachmentUrl,
      status: 'done',
      key: item.attachmentUrl,
      ...item,
    };
  });

  //申请时长
  if (data.applyTimeHour) {
    state.applyTimeHour = data.applyTimeHour;
  }

  //上下半天
  state.applyDownDays = data.applyOvertimeDownDays;
  state.applyUpDays = data.applyOvertimeUpDays;

  if (data.applyBusiType == 17) {
    state.freeApplyUpDays = data.freeApplyUpDays;
    state.freeApplyDownDays = data.freeApplyDownDays;

    // 处理时间
    if (data.freeApplyBeginTime && data.freeApplyEndTime) {
      data.freeApplyTime = [
        moment(data.freeApplyBeginTime),
        moment(data.freeApplyEndTime),
      ];
    }
  }

  state.applyOvertimeType = data.applyOvertimeType;
  state.overtimeFreeType = data.overtimeFreeType;

  // state.isAuditPassChangeProcess = data.isAuditPassChangeProcess;

  handleOut(data);
  handleForgetClock(data);
  console.log('申请时长...', state.applyDownDays);
  return data;
};

/** 处理忘打卡的时间 */
const handleForgetClock = (payload) => {
  if (payload.forgetClockApplyDate)
    payload.forgetClockApplyDate = moment(payload.forgetClockApplyDate);
  if (payload.forgetClockActualTime)
    payload.forgetClockActualTime = moment(payload.forgetClockActualTime);
  if (payload.forgetClockProver)
    payload.forgetClockProver = `${payload.forgetClockProverDeptId}-${payload.forgetClockProver}`;
};

const handleOut = (payload) => {
  payload.applyOutArea = [
    payload.applyOutProvinceCode,
    payload.applyOutCityCode,
    payload.applyOutAreaCode,
  ];
};

/** 获取上一个节点 */
export const findPreviousNode = (id, data) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].processApplyNodeId === id) {
      if (i === 0) {
        return null;
      } else {
        return data[i - 1];
      }
    }
  }
  return null;
};
/** 从arr1中排除arr2的时间 */
export const filterTime = (arr1, arr2) => {
  return arr1.filter((time) => {
    let flag = true;
    for (let i = 0; i < arr2.length; i++) {
      if (
        moment(time[0]).isBetween(arr2[i][0], arr2[i][1], null, '[]') ||
        moment(time[1]).isBetween(arr2[i][0], arr2[i][1], null, '[]') ||
        moment(arr2[i][0]).isBetween(time[0], time[1], null, '[]') ||
        moment(arr2[i][1]).isBetween(time[0], time[1], null, '[]')
      ) {
        flag = false;
        break;
      }
    }
    return flag;
  });
};

// export const get

// 外出
export const handleAddreass = (data, tree) => {
  const [applyOutProvinceCode, applyOutCityCode, applyOutAreaCode] = data;
  const applyOutProvinceName = findAddressTree(
    tree,
    applyOutProvinceCode,
  )?.label;
  const applyOutCityName = findAddressTree(tree, applyOutCityCode)?.label;
  const applyOutAreaName = findAddressTree(tree, applyOutAreaCode)?.label;

  return {
    applyOutProvinceCode,
    applyOutProvinceName,
    applyOutCityCode,
    applyOutCityName,
    applyOutAreaCode,
    applyOutAreaName,
    applyOutTown: [
      applyOutProvinceName,
      applyOutCityName,
      applyOutAreaName,
    ].join(''),
  };
};

//  ===========================================结束  ====================================================
