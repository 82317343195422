import Jsonform from '@/components/JsonForm';
import { Collapse, InputNumber } from 'kenshin';
import React, { useImperativeHandle } from 'react';
import { useEffect, useRef } from 'react';

const CollapseCard = React.forwardRef((props, ref) => {
  const { defaultValue = {}, title, cloumns = [], onSave } = props;
  const formRef = useRef();

  // 标识是否有修改数据
  const hasChange = useRef(false);

  useEffect(() => {
    formRef.current?.form.setFieldsValue(defaultValue);
  }, [defaultValue]);

  // form内的组件有改变调用
  const handleFieldsChange = () => {
    hasChange.current = true;
  };

  // 插槽设置，默认是Jsonform
  let children = (
    <Jsonform
      labelAlign="left"
      columns={cloumns}
      labelCol={{ flex: '120px' }}
      wrapperCol={{ flex: 'auto' }}
      ref={formRef}
      onFieldsChange={handleFieldsChange}
    />
  );
  if (props.children) {
    children = props.children;
  }

  useImperativeHandle(ref, () => ({
    ...(formRef?.current ?? {}),
  }));

  const handleLeave = () => {
    if (hasChange.current) {
      let value = formRef.current?.form.getFieldsValue();
      onSave?.(value);
      hasChange.current = false;
    }
  };

  return (
    <div onMouseLeave={handleLeave}>
      <Collapse className="template-card" defaultActiveKey={'1'}>
        <Collapse.Panel header={title} name="detail" key={'1'}>
          {children}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});

export default CollapseCard;
export * as Template from './template/index';
