import { downloadForProject } from '@/_serveice/dataAnalyse';

const dataRule = (arr) => {
  if (!arr) {
    return {};
  }
  const money = (type, step = 100) =>
    arr.map((item) => +(item[type] / step).toFixed(2) || 0);
  const xData = arr.map((item) => item.projectName);
  return {
    xData,
    data: [
      {
        name: '项目金额',
        type: 'bar',
        color: '#49a9ee',
        data: money('projectAmount'),
      },
      {
        name: '项目收款金额',
        type: 'bar',
        color: '#42C697',
        data: money('collectAmount'),
      },
      {
        name: '工时成本（实际）',
        type: 'bar',
        color: '#fdb454',
        data: money('actualCostAmount'),
      },
      {
        name: '工时成本（虚拟）',
        type: 'bar',
        color: '#8ed0fb',
        data: money('virtualCostAmount'),
      },
      {
        name: '实际成本费用率',
        type: 'line',
        color: '#f59397',
        data: money('actualInoutputPercent', 1),
      },
      {
        name: '虚拟成本费用率',
        type: 'line',
        color: '#2cc2ec',
        data: money('virtualInoutputPercent', 1),
      },
    ],
  };
};
const columns = [
  {
    dataIndex: 'projectName',
    title: '项目名称',
    // render
  },
  {
    dataIndex: 'projectAmount',
    title: '项目金额',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'collectAmount',
    title: '收款金额',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'actualCostAmount',
    title: '实际工时成本',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'virtualCostAmount',
    title: '虚拟工时成本',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'actualProfitAmount',
    title: '实际盈利',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'virtualProfitAmount',
    title: '虚拟盈利',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'actualInoutputPercent',
    title: '实际成本费用率',
    render: (val) => `${val??0}%`,
  },
  {
    dataIndex: 'virtualInoutputPercent',
    title: '虚拟成本费用率',
    render: (val) => `${val??0}%`,
  },
  {
    title: '累计产出',
    dataIndex: 'productAmount',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'actualProfitPercent',
    title: '实际盈利百分比',
    render: (val) => `${val??0}%`,
  },
  {
    dataIndex: 'virtualProfitPercent',
    title: '虚拟盈利百分比',
    render: (val) => `${val??0}%`,
  },
];
const rowTableColums = [
  {
    dataIndex: 'servCatgName',
    title: '工作包名称',
    // render
  },
  {
    dataIndex: 'actualCostAmount',
    title: '实际工时成本',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'virtualCostAmount',
    title: '虚拟工时成本',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'actualProfitAmount',
    title: '实际盈利',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'virtualProfitAmount',
    title: '虚拟盈利',
    render: (val) => +(val / 100).toFixed(2),
  },
  {
    dataIndex: 'actualInoutputPercent',
    title: '实际成本费用率',
    render: (val) => `${val??0}%`,
  },
  {
    dataIndex: 'virtualInoutputPercent',
    title: '虚拟成本费用率',
    render: (val) => `${val??0}%`,
  },
  {
    dataIndex: 'actualProfitPercent',
    title: '实际盈利百分比',
    render: (val) => `${val??0}%`,
  },
  {
    dataIndex: 'virtualProfitPercent',
    title: '虚拟盈利百分比',
    render: (val) => `${val??0}%`,
  },
];

export default {
  dataRule,
  columns,
  rowTableColums,
  tableKey: 'projectId',
  rowTableKey: (_, index) => index,
  downloadUrl: downloadForProject,
  downloadName: '项目分析',
};
