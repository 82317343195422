import Jsonform from '@/components/JsonForm';
import { MessageBox, Dropdown, Button, message } from 'kenshin';
import { useEffect, useRef } from 'react';
import { useSelector, history, useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { useState } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { getAllTreeKeys } from '../../../components/CollapseCard/template/indicators';
import PreviewIndicators from '../../../components/PreviewIndicators';

const DIR = 1; // 目录
const ITEM = 2; // 指标

const scoreTypeObj = {
  SCORE_ONE_NODE: 1,
  SCORE_TWO_NODE: 2,
  SCORE_THREE_NODE: 3,
};

/** 查找节点 */
function findNodeById(key, nodes) {
  for (let node of nodes) {
    // 只找目录
    if (`${node.type}-${node.id}` === key) {
      return node;
    }
    if (node.childList) {
      let result = findNodeById(key, node.childList);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

const dispatchType = {
  GETTREE: 'performance/getosauserindextree',
  GETDIRTREE: 'performance/getosauserindexcatgtree',
  UPDATEDIR: 'performance/updateosaemployeeindexcatgdata',
  UPDATEITEM: 'performance/updateosaemployeeindexitemdata',
  DELETEDIR: 'performance/deleteosaemployeeindexcatgdata',
  DELETEITEM: 'performance/deleteosaemployeeindexitemdata',
  ADDTREE: 'performance/addosaemployeeindexdata',
  MOVEDIR: 'performance/moveosaemployeeindexcatgdata',
  MOVEITEM: 'performance/moveosaemployeeindexitemdata',
};

const indicators = ({
  dispatchApi = dispatchType,
  // id参数名
  idKey = 'osaTemplateId',
  // 其他id，例如绩效id，默认使用模板id
  idValue,
  disabled,
} = {}) => {
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);
  const curData = performance.curOsaSettingData.rows ?? {};
  const loading = useSelector(({ loading }) => loading.effects);
  /** 模板id */
  const osaTemplateId = idValue ?? history.location.query.osaTemplateId;

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 弹窗数据
  const [editModalData, setEditModalData] = useState(null);
  const [editVisible, setEditVisible] = useState(false);

  useEffect(() => {
    if (osaTemplateId) {
      getDirTree();
      dispatch({
        type: dispatchApi.GETTREE,
        payload: { [idKey]: osaTemplateId },
      });
    }
  }, [history.location.query.osaTemplateId, idValue]);

  // 获取目录树
  const getDirTree = () => {
    dispatch({
      type: dispatchApi.GETDIRTREE,
      payload: {
        [idKey]: osaTemplateId,
      },
    });
  };

  const showScroSetting = (row) => {
    setEditModalData(row);
    setEditVisible(true);
  };

  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      fixed: 'left',
      width: 600,
      render: (row) =>
        `${row.code} ${
          row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        }`,
      // render: (row) => {
      //   return (
      //     <EllipsisTooltip
      //       value={`${row.code} ${
      //         row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
      //       }`}
      //       width={300}
      //     />
      //   );
      // },
    },
    // {
    //   dataIndex: 'remark',
    //   title: '指标描述',
    //   width: 300,
    //   // render: (remark) => {
    //   //   return <EllipsisTooltip value={remark} width={300} />;
    //   // },
    // },
    {
      dataIndex: 'osaIndexItemGoalValueUnit',
      title: '单位',
      width: 120,
    },
    {
      dataIndex: 'osaIndexItemGoalValue',
      title: '目标值',
      width: 120,
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: 120,
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    {
      dataIndex: 'weight',
      title: '权重',
      width: 120,
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'osaIndexItemSelfScore',
      title: '评分(自评)',
      width: 120,
      render(val, row) {
        return val ?? row.osaIndexCatgSelfScore;
      },
    },
    {
      dataIndex: 'osaIndexItemSelfScoreRemark',
      title: '备注(自评)',
      width: 120,
    },
    {
      dataIndex: 'osaIndexItemDirectLeaderScore',
      title: '评分(上级)',
      width: 120,
      render(val, row) {
        return val ?? row.osaIndexCatgDirectLeaderScore;
      },
    },
    {
      dataIndex: 'osaIndexItemDirectLeaderScoreRemark',
      title: '备注(上级)',
      width: 120,
    },
    {
      dataIndex: 'osaIndexItemBusiLeaderScore',
      title: '评分(业务负责人)',
      width: 150,
      render(val, row) {
        return val ?? row.osaIndexCatgBusiLeaderScore;
      },
    },
    {
      dataIndex: 'osaIndexItemBusiLeaderScoreRemark',
      title: '备注(业务负责人)',
      width: 150,
    },
  ];

  const handleSave = (value) => {
    let payload = {
      osaIndexId: editModalData.id,
      scoreType: scoreTypeObj[curData.curnNodeNumber],
      ...value,
    };
    dispatch({
      type: 'performance/osaindexscore',
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditVisible(false);
      }
    });
  };

  const editCloumn = [
    {
      label: '评分',
      name: 'osaIndexScore',
      type: 'InputNumber',
      required: '请输入分数',
      props: {
        stepType: 'inside',
        max: editModalData?.weight ?? 100,
        min: 0,
        placeholder: '请输入分数',
      },
    },
    {
      label: '备注',
      name: 'scoreRemark',
      type: 'TextArea',
      required: '请输入备注',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '请输入备注',
      },
    },
  ];

  const extra = () => {
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            setExpandedRowKeys(
              expandedRowKeys?.length == 0
                ? getAllTreeKeys(performance.osaIndicators)
                : [],
            );
          }}
        >
          {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
        </Button>
        {curData && <PreviewIndicators row={curData} type="primary" />}
      </>
    );
  };

  return (
    <>
      <TablePro
        // params={{ osaTemplateId: history.location.query.osaTemplateId }}
        // request="performance/getosatemplateindextree"
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        curColumns
        extra={extra}
        columnsOptions={false}
        scrollX
        scrollY={700}
        loading={loading[dispatchApi.GETTREE]}
        childrenColumnName="childList"
        dataSource={performance.osaIndicators}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />
      <ModalFormPro
        width={600}
        title="评分"
        visible={editVisible}
        edtaFormItem={editCloumn}
        formData={editModalData}
        onSave={(val) => handleSave(val)}
        onCancel={() => {
          setEditVisible(false);
          setEditModalData(null);
        }}
      />
    </>
  );
};

export default indicators;
