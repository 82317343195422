import React, { useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import { Button, message, Tooltip } from 'kenshin';
import JsonForm from '@/components/JsonForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useReactive } from 'ahooks';
import {
  handleBusiType,
  momentTimeFormat,
  handleAttendanceApplyName,
  getVacationApplyTimeType,
  handleApplyMuId,
  handlePartinList,
  isTimeArrOverlap,
  transformFileList,
  transformAttendanceApplyDetail,
  handleOvertimeYearMap,
} from '../apply/_util';
import { timeDiff } from '@/_util';
import {
  getattendanceapplydetaildata,
  getattendanceprocesstree,
  getattendancetimelistbytype,
  getmanageallemplist,
} from '../../../_serveice/attendance';
import { getEditColumns } from '../apply/_columns';

const CERTKEY = 'COMMIT:PROVE:MATERIAL';
const getOptions = (data) => {
  const options = [];
  data.forEach((item) => {
    if (item.childList) {
      const childList = [];
      item.childList.forEach((child) => {
        childList.push({
          label: child.name,
          value: `${child.id}-${child.code}`,
          remark: child.vacationRemark,
        });
      });
      options.push({
        label: item.name,
        value: item.id,
        childList,
        remark: item.vacationRemark,
      });
    } else {
      options.push({
        label: item.name,
        value: item.id,
        remark: item.vacationRemark,
      });
    }
  });
  return options;
};

const AddApply = ({ dispatch, attendance, loadingEf }) => {
  const ref = useRef();

  const baseData = useRef({});
  const detailData = useRef({});
  // const [employeeList, setNewEmployeeList] = useState([]);
  const [employeeList, setEmployeeAll] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [employeeListAll, setNewEmployeeListAll] = useState([]);

  const state = useReactive({
    type: '',
    remark: '',
    typeName: '',
    timeList: [],
  });

  /** 流程图相关的状态 */
  const processState = useReactive({
    curNode: {},
    list: [],
    dots: null,
    curIndex: 0,
    certIndex: -1,
  });

  useEffect(() => {
    // getmanageallemplist().then((res) => {
    //   setNewEmployeeList(
    //     res.data?.map((item) => ({ label: item.value, value: item.id })) || [],
    //   );
    // });
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setNewEmployeeListAll(
        res?.filter((item) => item.value != '999999' && item.label != '其他'),
      );
    });
    getProcessList();
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setEmployeeAll(
        res
          .map((item) => item.options)
          .flat(2)
          .map((item) => ({ ...item, value: item.value.split('-')[1] })),
      );
    });
    dispatch({
      type: 'attendance/getattendanceapplytypelist',
    }).then((applyTypeList) => {
      let processApplyId = history.location.query.id;
      if (!processApplyId) return;
      if (processApplyId != undefined && processApplyId != null) {
        const { form } = ref.current;
        getattendanceapplydetaildata({ processApplyId }).then((res) => {
          if (res.data.applyBusiType == 17) {
            res.data.overtimeFreeType = 1;
          }
          baseData.current = res.data;
          detailData.current = transformAttendanceApplyDetail(
            { ...res.data },
            {
              typeSelectChange,
              applyTypeList,
              state,
            },
          );
          form.setFieldsValue({ ...detailData.current });
        });
      }
    });
  }, []);

  /** 获取流程图数据 */
  const getProcessList = () => {
    let processApplyId = history.location.query.id;
    if (!processApplyId) return;
    getattendanceprocesstree({ processApplyId }).then((res) => {
      if (res.success && res.data) {
        processState.curNode = res.data.curnProcessNode;
        processState.list = res.data?.processNodeList ?? [];
        let curIndex = processState.list.findIndex(
          (item) =>
            res.data?.curnProcessNode?.processApplyNodeId ==
            item.processApplyNodeId,
        );
        processState.certIndex = processState.list.findIndex(
          (item) => item.processNodeNumber == CERTKEY,
        );
        processState.curIndex = curIndex;
        processState.dots = (index) => {
          if (index < curIndex) return { dotColor: '#0DE894' };
          else if (index == curIndex) return { dotColor: '#4389f9' };
          else return {};
        };
      }
    });
  };

  const typeTitle = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        overlayStyle={{
          maxWidth: 420,
        }}
        placement="right"
        title={state.remark}
      >
        <ExclamationCircleOutlined style={{ marginRight: 4 }} />
      </Tooltip>
      申请类型
    </div>
  );

  /** 获取当前类型已上报的时间 */
  const getOldApplyTime = (applyBusiType) => {
    // let editTime = detailData.current
    getattendancetimelistbytype({ applyBusiType, replaceEmpIdList: '' }).then(
      (res) => {
        state.timeList = res.data?.filter(Boolean) || [];
      },
    );
  };

  const typeSelectChange = (value, row) => {
    getOldApplyTime(value);
    state.type = value;
    state.remark = row.remark;
    state.typeName = row.label;
    row.childList && (state.list = row.childList);
    console.log('typeSelectChange...', state.type);
    // 设置默认值
    if (state.type == '17') {
      state.overtimeFreeType = 1;
      ref.current?.form?.setFieldsValue({
        overtimeFreeType: 1,
      });
    } else {
      state.overtimeFreeType = 0;
      ref.current?.form?.setFieldsValue({
        overtimeFreeType: 0,
      });
    }
  };

  const handleDisabledDate = (current) => {
    let disabledTime = { ...detailData.current }?.applyTime;
    // let time = []
    if (disabledTime) {
      return (
        current < moment(disabledTime[0]) || current > moment(disabledTime[1])
      );
    }
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const handleDisabledTime = (current, type) => {
    let [startTime, endTime] = { ...detailData.current }?.applyTime;
    if (type === 'start') {
      return {
        disabledHours: () => {
          if (startTime.isSame(moment(current), 'day')) {
            // 如果是起始日期当天，禁用起始时间之前的所有小时
            return Array(startTime.hour())
              .fill()
              .map((_, i) => i);
          } else if (endTime.isSame(moment(current), 'day'))
            return range(endTime.hour(), 24);
          return [];
        },
        disabledMinutes: () => {
          if (
            startTime.isSame(moment(current), 'day') &&
            startTime.hour() === moment(current).hour()
          ) {
            // 如果是起始日期当天且是当前小时，禁用起始时间之前的所有分钟
            return Array(startTime.minute())
              .fill()
              .map((_, i) => i);
          }
          return [];
        },
        disabledSeconds: () => {
          if (
            startTime.isSame(moment(current), 'day') &&
            startTime.hour() === moment(current).hour() &&
            startTime.minute() === moment(current).minute()
          ) {
            // 如果是起始日期当天且是当前小时且是当前分钟，禁用起始时间之前的所有秒数
            return Array(startTime.second())
              .fill()
              .map((_, i) => i);
          }
          return [];
        },
      };
    }

    if (type === 'end') {
      return {
        disabledHours: () => {
          if (endTime.isSame(moment(current), 'day')) {
            // 如果是结束日期当天，禁用结束时间之后的所有小时
            return Array(24 - endTime.hour() - 1)
              .fill()
              .map((_, i) => endTime.hour() + i + 1);
          } else if (startTime.isSame(moment(current), 'day'))
            return Array(startTime.hour())
              .fill()
              .map((_, i) => i);
          return [];
        },
        disabledMinutes: () => {
          if (
            endTime.isSame(moment(current), 'day') &&
            endTime.hour() === moment(current).hour()
          ) {
            // 如果是结束日期当天且是当前小时，禁用结束时间之后的所有分钟
            return Array(60 - endTime.minute())
              .fill()
              .map((_, i) => endTime.minute() + i + 1);
          }
          return [];
        },
        disabledSeconds: () => {
          if (
            endTime.isSame(moment(current), 'day') &&
            endTime.hour() === moment(current).hour() &&
            endTime.minute() === moment(current).minute()
          ) {
            // 如果是结束日期当天且是当前小时且是当前分钟，禁用结束时间之后的所有秒数
            return Array(60 - endTime.second())
              .fill()
              .map((_, i) => endTime.second() + i + 1);
          }
          return [];
        },
      };
    }
  };

  const edtaFormItem = getEditColumns({
    getOptions,
    typeTitle,
    attendance,
    state,
    employeeList,
    typeSelectChange,
    isEndDisabled: true,
    ref: ref.current,
    disabledDate: handleDisabledDate,
    disabledTime: handleDisabledTime,
    detailData: detailData.current,
    holidayDisabledTime: { ...detailData.current }?.vacationList?.map(
      (item) => item.applyTime,
    ),
  });

  const hasChange = (payload) => {
    // console.log(payload, detailData.current);
    if (
      payload?.vacationList?.length != detailData.current.vacationList?.length
    )
      return true; // 删除了假期
    if (
      // 修改了假期时间
      payload?.vacationList?.some((item, i) =>
        item.applyTime.some(
          (t, ti) => t != detailData.current.vacationList[i].applyTime[ti],
        ),
      )
    )
      return true;
    if (
      // 参与人员改变
      payload.partinList &&
      JSON.stringify(payload.partinList) !=
        JSON.stringify(detailData.current.partinList)
    )
      return true;
    if (
      // 申请时间改变
      payload.applyTime?.some(
        (t, ti) => t != detailData.current.applyTime?.[ti],
      )
    )
      return true;
    if (
      // 加班调休申请时间改变
      payload?.freeApplyBeginTime != detailData.current.freeApplyBeginTime ||
      payload?.freeApplyEndTime != detailData.current.freeApplyEndTime
    )
      return true;

    return false;
  };

  /** 点击保存 */
  const handleSave = () => {
    let processApplyId = history.location.query.id;
    const { form } = ref.current;
    form.validateFields().then((values) => {
      let ischange = hasChange(values);
      console.log('handleSave...', values);

      if (!ischange) {
        handleCancel();
        return;
      }
      let payload = {};
      values.processApplyMethod ||= 1;
      if (values.applyTime) {
        let hasOverLap = isTimeArrOverlap([
          ...state.timeList.map((item) => [item.beginDate, item.endDate]),
          values.applyTime,
        ]);
        if (!processApplyId) return message.error('当前日期已经申请过了！');
        let applyTime = momentTimeFormat(values.applyTime);
        // payload.applyTimeHour = timeDiff(applyTime, 'hours', 4);
        payload.applyTimeHour = state.applyTimeHour;
        payload.applyBeginTime = applyTime[0];
        payload.applyEndTime = applyTime[1];

        payload.applyOvertimeDownDays = state.applyDownDays;
        payload.applyOvertimeUpDays = state.applyUpDays;
      } else {
        payload.applyTimeHour = state.applyTimeHour;
        payload.applyBeginTime = state.applyBeginTime;
        payload.applyEndTime = state.applyEndTime;
        payload.applyOvertimeDownDays = state.applyDownDays;
        payload.applyOvertimeUpDays = state.applyUpDays;
      }

      console.log('handleSave...', state, values);

      if (values.freeApplyTime) {
        // let hasOverLap = isTimeArrOverlap([
        //   ...state.timeList.map((item) => [item.beginDate, item.endDate]),
        //   values.applyTime,
        // ]);
        if (!processApplyId) return message.error('当前日期已经申请过了！');
        let freeApplyTime = momentTimeFormat(values.freeApplyTime);

        // payload.freeApplyTimeHour = timeDiff(freeApplyTime, 'hours', 4);
        payload.freeApplyBeginTime = freeApplyTime[0];
        payload.freeApplyEndTime = freeApplyTime[1];

        payload.freeApplyUpDays = state.freeApplyUpDays;
        payload.freeApplyDownDays = state.freeApplyDownDays;

        payload.freeApplyTimeHour = state.freeApplyTimeHour;
      }

      if (values.applyBusiType == 17) {
        if (payload.applyTimeHour < payload.freeApplyTimeHour) {
          return message.error('调休时间不能大于加班时间');
        }
      }

      if (values.applyBusiType == 15)
        payload.vacationApplyTimeType = getVacationApplyTimeType(
          values.vacationList.map((item) => item.applyTime),
        );
      if (values.vacationList) {
        let hasOverLap = isTimeArrOverlap([
          ...state.timeList.map((item) => [item.beginDate, item.endDate]),
          ...values.vacationList.map((item) => item.applyTime),
        ]);
        // if (hasOverLap && !processApplyId)
        if (!processApplyId) return message.error('当前日期已经申请过了！');
        payload.vacationList = [...values.vacationList].map((item, index) => {
          let applyTime = momentTimeFormat(item.applyTime);
          item.applyBeginTime = applyTime[0];
          item.applyEndTime = applyTime[1];
          item.vacationId = item.applyType.split('-')[0];
          item.vacationCode = item.applyType.split('-')[1];
          // item.vacationRcdId = item.applyType.split('-')[2];
          item.vacationSort = index + 1;
          item.applyTimeHour = timeDiff(applyTime, 'hours', 4);
          item.applyTime = undefined;
          item.applyType = undefined;
          return item;
        });
        payload.applyTimeHour =
          payload.vacationList.reduce(
            (pre, cur) => pre + cur.applyTimeHour,
            0,
          ) || 0;
      }
      values.applyTime = undefined;
      if (values.processApplyMethod == 2) {
        payload.partinList = handlePartinList(values, employeeList);
      } else {
        // payload.partinList = handleApplyMuId(values);
        // payload.partinList = detailData.current.payload.partinList;
      }

      // 处理跨年的加班时间
      if (
        values.applyBusiType == 10 &&
        moment(payload.applyBeginTime).year() !=
          moment(payload.applyEndTime).year()
      ) {
        payload.overtimeYearMap = handleOvertimeYearMap(payload);
      }

      payload.attendanceApplyName = handleAttendanceApplyName(
        {
          ...baseData.current,
          ...values,
          ...payload,
          typeName: state.typeName,
          typeList: state.list,
        },
        employeeList,
        employeeListAll,
      );

      // payload.attendanceApplyName = handleAttendanceApplyName(
      //   {
      //     ...values,
      //     typeName: state.typeName,
      //     typeList: state.list,
      //   },
      //   employeeList,
      // );
      // console.log({
      //       attendanceApplyId: detailData.current.attendanceApplyId,
      //       ...baseData.current,
      //       ...payload,
      //     });

      dispatch({
        type: 'attendance/hrupdateattendanceapplyauditpass',
        payload: {
          attendanceApplyId: detailData.current.attendanceApplyId,
          ...baseData.current,
          ...payload,
        },
      }).then((res) => {
        if (res) {
          handleCancel();
        }
      });
    });
  };

  const handleCancel = () => {
    history.go(-1);
  };

  return (
    <div>
      <div className="form_header">
        <h3>{history.location.query?.custId ? '编辑' : '新增'}假期类型</h3>
        <div>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={btnDisabled}
            loading={loadingEf['attendance/hrupdateattendanceapplyauditpass']}
          >
            保存
          </Button>
          <Button onClick={handleCancel}>返回</Button>
        </div>
      </div>
      <JsonForm columns={edtaFormItem} ref={ref} />
      {state.timeList.length > 0 && (
        <div style={{ marginLeft: '20%', marginBottom: 20 }}>
          <h3>已申请的假期时间：</h3>
          {state.timeList.map((item) => (
            <div key={item.beginDate}>
              {moment(item.beginDate).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
              {moment(item.endDate).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(({ customer, system, attendance, loading }) => ({
  customer,
  loadingEf: loading.effects,
  system,
  attendance,
}))(AddApply);
