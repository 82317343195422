import {
  getosaindexlibtree,
  getosaindexcatgtree,
  deleteindexlibitem,
  deleteindexlibcatg,
  addindexlibcatgdata,
  addindexlibitemdata,
  updateindexlibcatgdata,
  updateindexlibitemdata,
  enableordisableindexcatg,
  enableordisableindexitem,
  osaempcommitstatlist,
  osaquarterstat,
  queryosaquarteremplist,
  osaweekplanstat,
  queryosaweekplanemplist,
} from '../../_serveice/performance';
import * as template from './template';
import * as osa from './osa';
export function dirTreeOptions(tree) {
  if (Array.isArray(tree)) {
    tree.forEach((item) => {
      item.osaIndexCatgName = `${item.code} ${item.osaIndexCatgName}`;
      if (item.childCatgList) {
        dirTreeOptions(item.childCatgList);
      }
    });
    return tree;
  }
  return [];
}

export default {
  namespace: 'performance',

  state: {
    salaryList: {},
    salaryListSearchForm: {},

    osaindexlibtree: [],
    osaCommitList: {},
    dirTree: [],
    managerCountSearchForm: {},
    managerCount: {},
    managerPageListSearchForm: {},
    managerPageList: [],
    ...template.state,
    ...osa.state,
  },

  effects: {
    ...template.effects,
    ...osa.effects,
    *getosaindexlibtree({ payload }, { put, call }) {
      const res = yield call(getosaindexlibtree, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            osaindexlibtree: res.data,
          },
        });
        return true;
      }
    },
    *getosaindexcatgtree({ payload }, { put, call }) {
      const res = yield call(getosaindexcatgtree, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            dirTree: dirTreeOptions(res.data),
          },
        });
        return true;
      }
    },
    *osaempcommitstatlist({ payload }, { put, call }) {
      const res = yield call(osaempcommitstatlist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            osaCommitList: res.data,
          },
        });
        return true;
      }
    },

    *updateindexlibcatgdata({ payload, hasEdit }, { put, call }) {
      const res = yield call(
        hasEdit ? updateindexlibcatgdata : addindexlibcatgdata,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'getosaindexlibtree',
        });
        return true;
      }
    },
    *updateindexlibitemdata({ payload, hasEdit }, { put, call }) {
      const res = yield call(
        hasEdit ? updateindexlibitemdata : addindexlibitemdata,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'getosaindexlibtree',
        });
        return true;
      }
    },
    *deleteindexlibcatg({ payload }, { put, call }) {
      const res = yield call(deleteindexlibcatg, payload);
      if (res?.success) {
        yield put({
          type: 'getosaindexlibtree',
        });
        return true;
      }
    },
    *deleteindexlibitem({ payload }, { put, call }) {
      const res = yield call(deleteindexlibitem, payload);
      if (res?.success) {
        yield put({
          type: 'getosaindexlibtree',
        });
        return true;
      }
    },
    *enableordisableindexcatg({ payload }, { put, call }) {
      const res = yield call(enableordisableindexcatg, payload);
      if (res?.success) {
        yield put({
          type: 'getosaindexlibtree',
        });
        return true;
      }
    },
    *enableordisableindexitem({ payload }, { put, call }) {
      const res = yield call(enableordisableindexitem, payload);
      if (res?.success) {
        yield put({
          type: 'getosaindexlibtree',
        });
        return true;
      }
    },
    *queryosaquarteremplist(
      { payload, reset, tabsKey },
      { put, call, select },
    ) {
      let api = [queryosaquarteremplist, queryosaweekplanemplist];
      const searchData = yield select(
        ({ performance }) => performance.managerPageListSearchForm,
      );
      console.log(searchData, payload);
      let managerPageListSearchForm = { ...searchData, ...payload };
      if (reset) {
        managerPageListSearchForm = payload;
        managerPageListSearchForm.deptList ??= ['1'];
      }
      const res = yield call(api[tabsKey], managerPageListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            managerPageList: res.data,
            managerPageListSearchForm,
          },
        });
        return true;
      }
    },
    *osaquarterstat({ payload, reset, tabsKey }, { put, call, select }) {
      let api = [osaquarterstat, osaweekplanstat];
      const searchData = yield select(
        ({ performance }) => performance.managerCountSearchForm,
      );
      let managerCountSearchForm = { ...searchData, ...payload };
      if (reset) {
        managerCountSearchForm = payload;
        managerCountSearchForm.deptList ??= ['1'];
      }
      const res = yield call(api[tabsKey], managerCountSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            managerCount: res.data,
            managerCountSearchForm,
          },
        });
        return res.data;
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
