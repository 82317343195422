import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Col, Row, Collapse, Input, DatePicker, Tag } from 'kenshin';
import { useSelector } from 'umi';
import './index.less';
const defaultShowType = ['osaType', 'osaWeekType', 'time'];

const CollapseSearch = ({ onChange, showType = defaultShowType }) => {
  const performance = useSelector(({ performance }) => performance);
  const [osaTime, setOsaTime] = useState([]);
  const [timeType, setTimeType] = useState('0');
  const [typeChecked, setTypeChecked] = useState('');
  const [key, setkey] = useState('');

  const timeSearch = ({ time }) => {
    setTimeType(time);
    const [amount, unit] = time.split('-');
    const start = moment().subtract(amount, unit).startOf('date');
    const end = moment().endOf('date');
    setOsaTime([start, end]);
    const osaBeginTime = start.format('YYYY-MM-DD HH:mm:ss');
    const osaEndTime = end.format('YYYY-MM-DD HH:mm:ss');
    onChange?.({ osaBeginTime, osaEndTime });
  };
  const timeChange = (times) => {
    setOsaTime(times);
    let osaBeginTime = undefined;
    let osaEndTime = undefined;
    if (times) {
      const [start, end] = times;
      osaBeginTime = start.startOf('d').format('YYYY-MM-DD HH:mm:ss');
      osaEndTime = end.endOf('d').format('YYYY-MM-DD HH:mm:ss');
    } else {
      setTimeType('0');
    }
    onChange?.({ osaBeginTime, osaEndTime });
  };

  // 表单项配置：信息类别、考核类型
  const [TypeOptions] = useMemo(() => {
    const obj = performance.osaChangeSearchNum ?? {};
    const TypeOptions = [
      {
        label: `全部(${obj.osaTotal ?? 0})`,
        value: '',
      },
      {
        label: `试用期转正评估(${obj.probationToRegular ?? 0})`,
        value: '1',
      },
      {
        label: `绩效评估(${obj.osaEvaluate ?? 0})`,
        value: '2',
      },
      {
        label: `绩效改进评估(${obj.osaImproveEvaluate ?? 0})`,
        value: '3',
      },
      {
        label: `个人发展计划(${obj.personDevolopPlan ?? 0})`,
        value: '4',
      },
    ];
    return [TypeOptions];
  }, [performance.osaChangeSearchNum]);

  const timeOptions = [
    {
      label: '一周',
      value: '1-week',
    },
    {
      label: '一个月',
      value: '1-month',
    },
    {
      label: '两个月',
      value: '2-month',
    },
    {
      label: '三个月',
      value: '3-month',
    },
    {
      label: '半年',
      value: '6-month',
    },
    {
      label: '一年',
      value: '12-month',
    },
  ];

  // 搜索事件
  // const handleSearch = (e) => {
  //   e.stopPropagation();
  //   let value = e.target.value;
  //   let payload = { key: value };

  //   if (searchTimer) {
  //     clearTimeout(searchTimer);
  //     searchTimer = null;
  //   }

  //   searchTimer = setTimeout(() => {
  //     onChange?.(payload);
  //   }, 800);
  // };

  const headerRender = (
    <div style={{ display: 'flex', width: '96%' }}>
      <Input
        placeholder="请输入绩效名称/员工名称"
        allowClear
        style={{ width: 200 }}
        value={key}
        onChange={(e) => setkey(e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />
      <div style={{   position: 'absolute',right: '20px',top: '10px'}}>
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            onChange?.({ key });
          }}
        >
          查询
        </Button>
      </div>
    </div>
  );

  return (
    <Collapse
      className="template-card"
      defaultActiveKey={'1'}
      style={{ marginBottom: 10 }}
    >
      <Collapse.Panel header={headerRender} name="detail" key={'1'}>
        {showType.includes('osaType') && (
          <Row style={{ marginBottom: 10, alignItems: 'center' }}>
            <Col flex="60px">考核类型：</Col>
            <Col flex="auto">
              {TypeOptions.map((item) => (
                <Tag.CheckableTag
                  style={{ marginLeft: 4 }}
                  key={item.value}
                  checked={item.value == typeChecked}
                  onChange={(checked) => {
                    onChange?.({ osaType: item.value });
                    setTypeChecked(item.value);
                  }}
                >
                  {item.label}
                </Tag.CheckableTag>
              ))}
            </Col>
          </Row>
        )}
        {showType.includes('time') && (
          <Row style={{ alignItems: 'center' }}>
            <Col flex="60px">日期范围：</Col>
            <Col flex="auto">
              {timeOptions.map((item) => (
                <Tag.CheckableTag
                  style={{ marginLeft: 4 }}
                  key={item.value}
                  checked={item.value == timeType}
                  onChange={(checked) => {
                    timeSearch({ time: item.value });
                  }}
                >
                  {item.label}
                </Tag.CheckableTag>
              ))}
              <DatePicker.RangePicker
                value={osaTime}
                onChange={timeChange}
                style={{ marginLeft: 4 }}
              />
            </Col>
          </Row>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseSearch;
