export default (
  <span
    style={{
      display: 'inline-block',
      transform: 'rotate(90deg)',
      verticalAlign: 'middle',
    }}
  >
    <svg
      t="1669879252883"
      className="icon"
      viewBox="0 0 1024 1024"
      width="14"
      height="14"
    >
      <path
        d="M618.688 149.312l0 213.376L64 362.688 64 448l896 0L618.688 149.312zM405.312 874.688l0-213.376L960 661.312 960 576 64 576 405.312 874.688z"
        p-id="8955"
        data-spm-anchor-id="a313x.7781069.0.i0"
        fill="currentColor"
      ></path>
    </svg>
  </span>
);
