import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';

const menuConfig = ({ dispatch, loading, lowCode, user }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [tempTypeAll, setTempTypeAll] = useState([]);

  useEffect(() => {
    dispatch({
      type: 'lowCode/temptypeAll',
    });
  }, []);

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'lowCode/deleteTemplate',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = (value) => {
    let payload = { ...value };
    let hasEdit = false;
    if (editModalData?.tmptId) {
      payload.tmptId = editModalData.tmptId;
      hasEdit = true;
    }
    dispatch({
      type: 'lowCode/editTemplate',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        message.success('操作成功');
      }
    });
  };

  const toLowCodeFram = (row) => {
    history.push({
      pathname: '/admin/system/lowCodeIframe',
      query: {
        tmptId: row.tmptId,
        token: localStorage.getItem('token'),
        muId: localStorage.getItem('muId'),
      },
    });
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'tmptName',
      key: 'tmptName',
    },
    {
      title: '编号',
      dataIndex: 'tmptCode',
      key: 'tmptCode',
    },
    {
      title: '排序',
      dataIndex: 'tmptSort',
      key: 'tmptSort',
    },
    {
      title: '模板分类',
      dataIndex: 'tmptTypeId',
      key: 'tmptTypeId',
      render: (value) =>
        lowCode.temptypeListAll.find((item) => item.value == value)?.label,
    },
    {
      title: '创建时间',
      dataIndex: 'tmptCrtTime',
      key: 'tmptCrtTime',
      render: (value) => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => toLowCodeFram(row)}>
              模板配置
            </Button>
            <Button type="text" onClick={() => showEditModal(row)}>
              编辑
            </Button>
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delRole({ tmptId: row.tmptId })}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'tmptCode',
      type: 'Input',
      props: {
        placeholder: '编号',
      },
    },
    {
      name: 'tmptTypeName',
      type: 'Input',
      props: {
        placeholder: '模板名称',
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '排序',
      name: 'tmptSort',
      type: 'InputNumber',
      props: {
        placeholder: '请输入排序',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: '编号',
      name: 'tmptCode',
      type: 'Input',
      required: '请输入编号',
      props: {
        placeholder: '请输入编号',
        disabled: (row) => !!row.tmptId,
      },
    },
    {
      label: '名称',
      name: 'tmptName',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
    {
      label: '分类',
      name: 'tmptTypeId',
      type: 'Select',
      required: '请选择分类',
      props: {
        placeholder: '请选择分类',
        options: lowCode.temptypeListAll,
      },
    },
  ];

  const extra = (
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增模板
    </Button>
  );

  return (
    <>
      <TablePro
        request="lowCode/getTemplateList"
        rowKey={'tmptId'}
        columns={columns}
        searchCloumns={searchCloumns}
        data={lowCode.templateList}
        extra={extra}
      />

      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </>
  );
};

menuConfig.title = '菜单配置';

export default connect(({ lowCode, loading, user }) => {
  return {
    user,
    lowCode,
    loading: loading.effects,
  };
})(menuConfig);
