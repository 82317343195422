import { Button, Calendar, Select, Tag, Tooltip } from 'kenshin';
import { memo, useMemo, useState } from 'react';
import {
  ExclamationCircleFilled,
  CheckCircleFilled,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useCreation, useMemoizedFn } from 'ahooks';
import { useDispatch, history } from 'umi';
import styles from './styles.less';

const StaticsCalendar = memo(({}) => {
  const [calendarTime, setCalendarTime] = useState(moment());

  const dispatch = useDispatch();

  const dateRender = (date, dateToday) => {};

  const addCalendarTime = (diff, unit) => {
    if (diff == 0) {
      setCalendarTime(moment());
    } else {
      setCalendarTime(moment(calendarTime).add(diff, unit));
    }
  };

  const yearOptions = useCreation(() => {
    const currentYear = moment(calendarTime).year();
    const startYear = currentYear - 30;
    const endYear = currentYear + 30;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year, value: year });
    }
    return years;
  }, [calendarTime]);

  const headerRender = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="home-card-title">日历工作</div>
        <div style={{ display: 'flex' }}>
          <Select
            options={yearOptions}
            value={calendarTime.year()}
            style={{ width: 120 }}
            onChange={(val) =>
              setCalendarTime(moment(calendarTime).set('year', val))
            }
          />
          <Select
            options={new Array(12)
              .fill('')
              .map((_, i) => ({ label: `${i + 1}月`, value: i }))}
            value={calendarTime.month()}
            style={{ width: 120, margin: '0 10px' }}
            onChange={(val) =>
              setCalendarTime(moment(calendarTime).set('month', val))
            }
          />
          <div
            className={styles.calenartBtn}
            onClick={() => addCalendarTime(-1, 'month')}
          >
            <LeftOutlined />
          </div>
          {/* <div className='flr' style={{ marginLeft: 10 }} onClick={() => addCalendarTime(0, "month")}>今天</div> */}
          <div
            className={styles.calenartBtn}
            onClick={() => addCalendarTime(1, 'month')}
          >
            <RightOutlined />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Calendar
      // dateRender={dateRender}
      // value={calendarTime}
      headerRender={headerRender}
      showToday={false}
      // onPanelChange={getCurDateData}
      // onChange={getCurDateData}
    />
  );
});

export default StaticsCalendar;
