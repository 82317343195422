// 请求表格数据
export const createLoadTable = ({
  request,
  setCurrent,
  sortFieldsRef,
  searchRef,
  propsParams,
  props,
  current,
  rowCount,
  onSubmit,
  dispatch,
}) => {
  return (params = {}, reset = false, hasSearch = false) => {
    const { sortFields, ...values } = params;
    // debugger;
    if (request) {
      if (hasSearch) {
        setCurrent(1);
      }
      /** 如果重置 */
      if (reset) {
        sortFieldsRef.current = [];
      }
      let payload = {
        ...(searchRef.current?.value || {}),
        ...propsParams,
        ...values,
      };
      if (props.pagination != false) {
        let page = {
          pageNo: reset ? 1 : current,
          pageSize: rowCount,
          sortFields: sortFields ?? sortFieldsRef.current,
        };
        payload = {
          page,
          ...(searchRef.current?.value || {}),
          ...propsParams,
          ...values,
        };
      }
      // onSubmit可以拦截搜索条件，利用对象数据共享的原理对搜索的数据进行组装操作，最后返回对象
      let resPayload = onSubmit?.(payload);
      if (resPayload == false) {
        return;
      }
      payload = resPayload ?? payload;
      dispatch({
        type: request,
        reset,
        payload,
      });
    }
  };
};

/** 表格改变之后触发 */
export const createChange = ({
  setRowCount,
  setCurrent,
  onChange,
  loadTable,
  sortFieldsRef,
}) => {
  return (props, _filters) => {
    setRowCount((val) => {
      if (val == props.pageSize) {
        return val;
      }
      props.current = 1;
      return props.pageSize;
    });
    setCurrent(props.current);
    onChange?.(props);
    loadTable({
      page: {
        pageNo: props.current,
        pageSize: props.pageSize,
        sortFields: sortFieldsRef.current,
      },
    });
  };
};

/** 重置拦截 */
export const createReset = ({
  setCurrent,
  setRowCount,
  sortFieldsRef,
  propsParams,
  props,
  propsOnReset,
  dispatch,
  request,
  defaultPageSize,
  page,
}) => {
  return () => {
    setCurrent(1);
    setRowCount(defaultPageSize);
    sortFieldsRef.current = [];
    let payload = { ...propsParams };
    if (props.pagination != false) {
      payload.page = page;
    }
    propsOnReset?.(payload);

    dispatch({
      type: request,
      reset: true,
      payload,
    });
  };
};

/** 排序chang事件拦截 */
export const createSortChange = ({ sortFieldsRef, loadTable }) => {
  return (sortData) => {
    let hasFileIndex = sortFieldsRef.current.findIndex(
      (item) => item.name == sortData.name,
    );
    if (hasFileIndex != -1) {
      sortFieldsRef.current.splice(hasFileIndex, 1);
    }
    if (sortData.value != -1) {
      sortFieldsRef.current.push(sortData);
    }
    loadTable({ sortFields: sortFieldsRef.current });
  };
};
