import {
  getServcatgList,
  addServcatg,
  editServcatg,
  deleteServcatg,
  getServcatgEasyList,
  getServcatgDetailList,
  addProprealLocationMemberData,
  getProjectList,
  addProject,
  editProject,
  getProjectDetail,
  deleteProject,
  delServcatgRule,
  delTeamRule,
  restProject,
  getAuditProgress,
  getProjectLogList,
  getProjectLById,
  getProjectLByCustId,
  projectDetailLog,

  // 项目服务类目
  getProservcatgList,
  updataServcat,
  delServcat,
  sortServcat,

  //工作包管理
  getPackageMangerList,
  delPackageManger,

  // 任务管理
  getTaskPage,
  addTask,
  updateTask,
  deleteTask,
  taskStatus,
  // 查询简单项目列表
  getSimpProjectList,
  getusersimpprojectlist,
  getProServcatgTree,
  getEmpWorkList,
  getProServCatgstatList,
  getsimptasklist,
  getworkpkgclosepage,
  proworkpkgclosepage,
  updateworkpkgclose,
  deleteworkpkgcloselist,
} from '@/_serveice/project/index.js';

export default {
  namespace: 'project',

  state: {
    // 服务类目
    servcatgList: {},
    servcatgListSearchForm: {},

    servcatgEasyList: [],
    servcatgDetailList: [],

    // 项目管理
    projectList: {},
    projectDetail: {},
    projectListSearchForm: {},

    projectDetailLogList: {},

    auditProgress: [],
    // 操作日志
    projectLogList: [],
    projectLogListSearchForm: {},

    ProjectLByIdList: [],

    // 包管理
    packageMangerList: {},
    packageMangerListSearchForm: {},

    // 任务管理
    taskList: {},
    taskListSearchForm: {},

    // 简单项目列表
    projectSimpList: [],
    userProjectSimpList: [],
    workHourList: [],
    proServCatgstatList: [],

    simpTaskList: [],

    // 关闭工作包
    proworkpkgclosepage: {},
  },

  effects: {
    // 服务类目--------------------------
    *getServcatgList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ project }) => project.servcatgListSearchForm,
      );
      let servcatgListSearchForm = { ...searchData, ...payload };
      if (reset) {
        servcatgListSearchForm = payload;
      }
      const res = yield call(getServcatgList, servcatgListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            servcatgList: res.data,
            servcatgListSearchForm,
          },
        });
        return res.data;
      }
    },
    *editServcatg({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editServcatg : addServcatg, payload);
      if (res?.success) {
        yield put({
          type: 'getServcatgList',
        });
        yield put({
          type: 'getServcatgEasyList',
        });
        return true;
      }
    },
    *deleteServcatg({ payload }, { put, call }) {
      const res = yield call(deleteServcatg, payload);
      if (res?.success) {
        yield put({
          type: 'getServcatgList',
        });
        return true;
      }
    },
    *getServcatgEasyList({ payload }, { put, call }) {
      const res = yield call(getServcatgEasyList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            servcatgEasyList: res.data,
          },
        });
        return res;
      }
    },
    *getServcatgDetailList({ payload }, { put, call }) {
      const res = yield call(getServcatgDetailList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            servcatgDetailList: res.data,
          },
        });
        return res.data;
      }
    },
    // 服务类目---------------end-----------

    // 项目管理--------------------------
    *getProjectList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ project }) => project.projectListSearchForm,
      );
      let projectListSearchForm = { ...searchData, ...payload };
      if (reset) {
        projectListSearchForm = payload;
      }
      const res = yield call(getProjectList, projectListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectList: res.data,
            projectListSearchForm,
          },
        });
      }
    },
    *editProject({ payload }, { put, call }) {
      const res = yield call(editProject, payload);
      if (res?.success) {
        yield put({
          type: 'getProjectList',
        });
        return true;
      }
    },
    *getProjectDetail({ payload }, { put, call }) {
      const res = yield call(getProjectDetail, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectDetail: res.data,
          },
        });
        return res.data;
      }
    },
    *updateworkpkgclose({ payload }, { put, call }) {
      const res = yield call(updateworkpkgclose, payload);
      if (res?.success) {
        // yield put({
        //   type: 'save',
        //   payload: {
        //     projectDetail: res.data,
        //   },
        // });
        return res.success;
      }
    },

    *deleteworkpkgcloselist({ payload }, { put, call }) {
      const res = yield call(deleteworkpkgcloselist, payload);
      if (res?.success) {
        // yield put({
        //   type: 'save',
        //   payload: {
        //     projectDetail: res.data,
        //   },
        // });
        return res.success;
      }
    },

    *getProservcatgList({ payload }, { put, call }) {
      const res = yield call(getProservcatgList, payload);
      if (res?.success) {
        return res.data;
      }
    },
    *updataServcat({ payload }, { put, call }) {
      const res = yield call(updataServcat, payload);
      if (res?.success) {
        return true;
      }
      return false;
    },
    *delServcat({ payload }, { put, call }) {
      const res = yield call(delServcat, payload);
      if (res?.success) {
        return true;
      }
      return false;
    },
    *sortServcat({ payload }, { put, call }) {
      const res = yield call(sortServcat, payload);
      if (res?.success) {
        return true;
      }
      return false;
    },
    *deleteProject({ payload }, { put, call }) {
      const res = yield call(deleteProject, payload);
      if (res?.success) {
        yield put({
          type: 'getProjectList',
        });
        return true;
      }
    },
    *restProject({ payload }, { put, call }) {
      const res = yield call(restProject, payload);
      if (res?.success) {
        yield put({
          type: 'getProjectList',
        });
        return true;
      }
    },
    *delServcatgRule({ payload }, { put, call }) {
      const res = yield call(delServcatgRule, payload);
      if (res?.success) {
        return res.data;
      }
    },
    *delTeamRule({ payload }, { put, call }) {
      const res = yield call(delTeamRule, payload);
      if (res?.success) {
        return res.data;
      }
      return false;
    },

    *getAuditProgress({ payload }, { put, call }) {
      const res = yield call(getAuditProgress, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            auditProgress: res.data,
          },
        });
        return res.data;
      }
    },

    *projectDetailLog({ payload }, { put, call }) {
      const res = yield call(projectDetailLog, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectDetailLogList: res.data,
          },
        });
        return res.data;
      }
    },

    *getworkpkgclosepage({ payload }, { put, call }) {
      const res = yield call(getworkpkgclosepage, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            proworkpkgclosepage: res.data,
          },
        });
        return res.data;
      }
    },

    *addProprealLocationMemberData({ payload }, { put, call }) {
      const res = yield call(addProprealLocationMemberData, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            addProprealLocationMemberData: res.data,
          },
        });
        return res.data;
      }
    },

    // 项目管理---------------end-----------

    // 操作日志
    *getProjectLogList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ project }) => project.projectLogListSearchForm,
      );
      let projectLogListSearchForm = { ...searchData, ...payload };
      if (reset) {
        projectLogListSearchForm = payload;
      }
      const res = yield call(getProjectLogList, projectLogListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectLogList: res.data,
            projectLogListSearchForm,
          },
        });
      }
    },
    *getProjectLById({ payload }, { put, call }) {
      const res = yield call(getProjectLById, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            ProjectLByIdList: res.data,
          },
        });
        return res;
      }
    },
    //  工作包管理

    *getPackageMangerList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ project }) => project.packageMangerListSearchForm,
      );
      let packageMangerListSearchForm = { ...searchData, ...payload };
      if (reset) {
        packageMangerListSearchForm = payload;
      }
      const res = yield call(getPackageMangerList, packageMangerListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            packageMangerList: res.data,
            packageMangerListSearchForm,
          },
        });
      }
    },
    *delPackageManger({ payload }, { put, call }) {
      const res = yield call(delPackageManger, payload);
      if (res?.success) {
        // yield put({
        //   type: 'getPackageMangerList',
        // });
        return true;
      }
    },

    // 工作包 end
    // 任务管理
    *getTaskPage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ project }) => project.taskListSearchForm,
      );
      let taskListSearchForm = { ...searchData, ...payload };
      if (reset) {
        taskListSearchForm = payload;
      }
      const res = yield call(getTaskPage, taskListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            taskList: res.data,
            taskListSearchForm,
          },
        });
      }
    },
    *addTask({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? updateTask : addTask, payload);
      if (res?.success) {
        yield put({
          type: 'getTaskPage',
        });
        return true;
      }
    },
    *deleteTask({ payload }, { put, call }) {
      const res = yield call(deleteTask, payload);
      if (res?.success) {
        yield put({
          type: 'getTaskPage',
        });
        return true;
      }
    },
    *taskStatus({ payload }, { put, call }) {
      const res = yield call(taskStatus, payload);
      if (res?.success) {
        yield put({
          type: 'getTaskPage',
        });
        return true;
      }
    },
    *getSimpProjectList({ payload }, { put, call }) {
      const res = yield call(getSimpProjectList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            userProjectSimpList: res.data,
          },
        });
        return res.data;
      }
    },
    *getusersimpprojectlist({ payload }, { put, call }) {
      const res = yield call(getusersimpprojectlist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            userProjectSimpList: res.data,
          },
        });
        return res.data;
      }
    },
    *getProServcatgTree({ payload }, { put, call }) {
      const res = yield call(getProServcatgTree, payload);
      if (res?.success) {
        return res.data;
      }
    },
    *getEmpWorkList({ payload }, { put, call }) {
      const res = yield call(getEmpWorkList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            workHourList: res.data,
          },
        });
        return res.data;
      }
    },
    *getProServCatgstatList({ payload }, { put, call }) {
      const res = yield call(getProServCatgstatList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            proServCatgstatList: res.data,
          },
        });
        return res.data;
      }
    },
    *getsimptasklist({ payload }, { put, call }) {
      const res = yield call(getsimptasklist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            simpTaskList: res.data,
          },
        });
        return res.data;
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
