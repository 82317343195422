import { MessageBox, Button, Timeline } from 'kenshin';
import { CloseCircleFilled } from '@ant-design/icons';

/** 绩效/周计划 */
export const osaRecord = {
  getData({ dispatch, row, setTimeLineData }) {
    dispatch({
      type: 'performance/getosauserprocesslist',
      payload: {
        osaId: '',
        todoBusiId: row.todoItemBusiId ?? row.processApplyId,
      },
    }).then((list) => {
      if (list) {
        setTimeLineData(list);
      }
    });
  },
  com: ({ timeLineData }) => {
    let list = timeLineData?.osaProcessDataList ?? [];
    console.log(111111, timeLineData);
    let backNodeId = timeLineData?.backNodeId;
    let curIndex = list.findIndex((item) => item.curnNode);
    if (timeLineData?.processOver) {
      curIndex = list.length;
    }
    let dots = (index) => {
      if (backNodeId != null && list[index].processNodeId === backNodeId)
        return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
      else if (index < curIndex) return { dotColor: '#0DE894' };
      else if (index == curIndex) return { dotColor: '#4389f9' };
      else return {};
    };
    return (
      <>
        <Timeline>
          {list.map?.((item, index) => (
            <Timeline.Item
              key={index}
              content={item.processDescribe}
              // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
              {...dots(index)}
            />
          ))}
        </Timeline>
      </>
    );
  },
};

/** 考核 */
export const attendanceRecord = {
  getData({ dispatch, row, setTimeLineData }) {
    dispatch({
      type: 'attendance/getattendanceprocesstree',
      payload: {
        processApplyId: row.todoItemBusiId ?? row.processApplyId,
      },
    }).then((list) => {
      if (list) {
        setTimeLineData(list);
      }
    });
  },
  com: ({ timeLineData }) => {
    let list = timeLineData?.processNodeList ?? [];
    console.log(22222, timeLineData);
    let backNodeId = timeLineData?.backNodeId;
    let curNode = list.find(
      (item) =>
        item.processApplyNodeId ==
        timeLineData.curnProcessNode?.processApplyNodeId,
    );
    let curIndex = list.indexOf(curNode);
    if (curIndex == list.length - 1 && curNode?.processNodeStatus == 2) {
      curIndex = list.length;
    }
    let dots = (index) => {
      if (backNodeId != null && list[index].processApplyNodeId === backNodeId)
        return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
      else if (index < curIndex) return { dotColor: '#0DE894' };
      else if (index == curIndex) return { dotColor: '#4389f9' };
      else return {};
    };
    return (
      <>
        <Timeline>
          {list?.map((item, index) => (
            <Timeline.Item
              key={index}
              content={`${item.processNodeName}${
                item.processApplyNodeHandlerList?.length > 0 ? '（' : ''
              }${
                item.processApplyNodeHandlerList
                  ?.map((item) => item.processNodeHandlerName)
                  .join('、') ?? ''
              }${item.processApplyNodeHandlerList?.length > 0 ? '）' : ''}`}
              {...dots?.(index)}
            />
          ))}
        </Timeline>
      </>
    );
  },
};
