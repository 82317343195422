/** 迟到 */
export const LateIcon = (
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <g id="首页" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="画板" transform="translate(-115.000000, -178.000000)">
        <g id="有迟到" transform="translate(116.000000, 179.000000)">
          <g
            id="late-icon"
            transform="translate(4.566931, 3.173013)"
            stroke="#F95520"
          >
            <path
              d="M5.53209144,3 C3.04706218,3 1.03209144,4.91043343 1.03209144,7.2665386 L1.03209144,11 L3.3114701,11 L10.0320914,11 L10.0320914,7.2665386 C10.0320914,4.91043343 8.01712071,3 5.53209144,3 Z"
              id="Stroke-1"
              strokeLinejoin="round"
            />
            <line
              x1="0.0320914413"
              y1="12.5"
              x2="10.8990914"
              y2="12.5"
              id="Stroke-3"
              strokeLinecap="round"
            />
            <path
              d="M5.5,1.675 L5.5,0 M1.185,3.185 L0,2 M11.1659,2.0002 L9.9809,3.1852"
              id="形状结合"
              strokeLinecap="round"
            />
            <line
              x1="3.40119144"
              y1="7.9938"
              x2="3.40119144"
              y2="10.909415"
              id="Stroke-11"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

// 加班
export const OverTimeIcon = (
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <title>有加班</title>
    <g id="首页" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="画板" transform="translate(-75.000000, -178.000000)">
        <g id="有加班" transform="translate(76.000000, 179.000000)">
          <rect
            id="加班"
            stroke="#FFFFFF"
            fill="#F7FBFF"
            x="0"
            y="0"
            width="20"
            height="20"
            rx="4"
          />
          <path
            d="M14,16.0739 L6,16.0739 C4.895,16.0739 4,15.1789 4,14.0739 L4,7.5619 C4,6.4569 4.895,5.5619 6,5.5619 L14,5.5619 C15.105,5.5619 16,6.4569 16,7.5619 L16,14.0739 C16,15.1789 15.105,16.0739 14,16.0739 Z"
            id="Stroke-1"
            stroke="#4389F9"
          />
          <line
            x1="7.4346"
            y1="4"
            x2="7.4346"
            y2="5.511"
            id="Stroke-3"
            stroke="#4389F9"
            strokeLinecap="round"
          />
          <line
            x1="4.2014"
            y1="8.7618"
            x2="15.7284"
            y2="8.7618"
            id="Stroke-5"
            stroke="#4389F9"
            strokeLinecap="round"
          />
          <line
            x1="12.7411"
            y1="4"
            x2="12.7411"
            y2="5.511"
            id="Stroke-7"
            stroke="#4389F9"
            strokeLinecap="round"
          />
          <path
            d="M6.541,12.5545 C6.127,12.5545 5.791,12.2185 5.791,11.8045 C5.791,11.3905 6.127,11.0545 6.541,11.0545 C6.955,11.0545 7.291,11.3905 7.291,11.8045 C7.291,12.2185 6.955,12.5545 6.541,12.5545 Z M10.041,12.5545 C9.627,12.5545 9.291,12.2185 9.291,11.8045 C9.291,11.3905 9.627,11.0545 10.041,11.0545 C10.455,11.0545 10.791,11.3905 10.791,11.8045 C10.791,12.2185 10.455,12.5545 10.041,12.5545 Z M13.541,12.5545 C13.127,12.5545 12.791,12.2185 12.791,11.8045 C12.791,11.3905 13.127,11.0545 13.541,11.0545 C13.955,11.0545 14.291,11.3905 14.291,11.8045 C14.291,12.2185 13.955,12.5545 13.541,12.5545 Z"
            id="形状结合"
            fill="#468AF8"
          />
        </g>
      </g>
    </g>
  </svg>
);

// 请假
export const VavationApplyIcon = (
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <title>有请假</title>
    <g id="首页" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="画板" transform="translate(-195.000000, -178.000000)">
        <g id="vacationApply" transform="translate(196.000000, 179.000000)">
          <path
            d="M6.5,5.71875 C6.43125,5.71875 6.375,5.775 6.375,5.84375 L6.375,6.59375 C6.375,6.6625 6.43125,6.71875 6.5,6.71875 L12.5,6.71875 C12.56875,6.71875 12.625,6.6625 12.625,6.59375 L12.625,5.84375 C12.625,5.775 12.56875,5.71875 12.5,5.71875 L6.5,5.71875 Z M9.375,7.96875 L6.5,7.96875 C6.43125,7.96875 6.375,8.025 6.375,8.09375 L6.375,8.84375 C6.375,8.9125 6.43125,8.96875 6.5,8.96875 L9.375,8.96875 C9.44375,8.96875 9.5,8.9125 9.5,8.84375 L9.5,8.09375 C9.5,8.025 9.44375,7.96875 9.375,7.96875 Z M8.625,15.125 L5.125,15.125 L5.125,4.125 L13.875,4.125 L13.875,9.125 C13.875,9.19375 13.93125,9.25 14,9.25 L14.875,9.25 C14.94375,9.25 15,9.19375 15,9.125 L15,3.5 C15,3.2234375 14.7765625,3 14.5,3 L4.5,3 C4.2234375,3 4,3.2234375 4,3.5 L4,15.75 C4,16.0265625 4.2234375,16.25 4.5,16.25 L8.625,16.25 C8.69375,16.25 8.75,16.19375 8.75,16.125 L8.75,15.25 C8.75,15.18125 8.69375,15.125 8.625,15.125 Z M15.5,13.75 L13.25,13.75 L13.25,13.178125 C13.9734375,12.9625 14.5,12.29375 14.5,11.5 C14.5,10.5328125 13.7171875,9.75 12.75,9.75 C11.7828125,9.75 11,10.5328125 11,11.5 C11,12.2921875 11.5265625,12.9625 12.25,13.178125 L12.25,13.75 L10,13.75 C9.8625,13.75 9.75,13.8625 9.75,14 L9.75,16.375 C9.75,16.5125 9.8625,16.625 10,16.625 L15.5,16.625 C15.6375,16.625 15.75,16.5125 15.75,16.375 L15.75,14 C15.75,13.8625 15.6375,13.75 15.5,13.75 Z M11.96875,11.5 C11.96875,11.06875 12.31875,10.71875 12.75,10.71875 C13.18125,10.71875 13.53125,11.06875 13.53125,11.5 C13.53125,11.93125 13.18125,12.28125 12.75,12.28125 C12.31875,12.28125 11.96875,11.93125 11.96875,11.5 Z M14.78125,15.65625 L10.71875,15.65625 L10.71875,14.71875 L14.78125,14.71875 L14.78125,15.65625 Z"
            id="形状"
            fill="#23C2DB"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

/** 外出 */
export const GooutIcon = (
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <title>有外出</title>
    <g id="首页" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="画板" transform="translate(-35.000000, -178.000000)">
        <g id="有外出" transform="translate(36.000000, 179.000000)">
          <g
            id="goout-icon"
            transform="translate(4.000000, 4.000000)"
            stroke="#6370DE"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.005"
          >
            <line
              x1="6.00013629"
              y1="9.16239642"
              x2="6.00013629"
              y2="7.49333395"
              id="Stroke-1"
            />
            <path
              d="M10.0205538,1.69760167 C11.1173617,1.69760167 12,2.59890856 12,3.7107397 L12,5.97387526 C10.4034631,6.92123433 8.2942171,7.4935971 5.9967615,7.4935971 C3.69929292,7.4935971 1.59653694,6.92123433 0,5.97387526 L0,3.70416081 C0,2.59232968 0.889128297,1.69760167 1.9859362,1.69760167 L10.0205538,1.69760167 Z"
              id="Stroke-2"
            />
            <path
              d="M8.27163194,1.69472012 L8.27163194,1.45393302 C8.27163194,0.651309362 7.62912317,0 6.83734469,0 L5.16292789,0 C4.37116239,0 3.72865362,0.651309362 3.72865362,1.45393302 L3.72865362,1.69472012"
              id="Stroke-4"
            />
            <path
              d="M0.0159199395,8.37700944 L0.138580705,10.027651 C0.221652546,11.1401401 1.13609179,12 2.23614469,12 L9.76387478,12 C10.8639277,12 11.7783669,11.1401401 11.8614388,10.027651 L11.9840995,8.37700944"
              id="Stroke-6"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
/** 早退 */
export const LeaveEarlyIcon = (
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <title>有早退</title>
    <g id="首页" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="画板" transform="translate(-155.000000, -178.000000)">
        <g id="leaveEarly" transform="translate(156.000000, 179.000000)">
          <path
            d="M16.4204729,16 L3.58285293,16 C3.37665282,16 3.18375594,15.8911454 3.07899298,15.717303 C2.97423002,15.5434606 2.97423002,15.3257514 3.07566717,15.1502843 L3.07733007,15.1470349 L9.49614005,4.28594639 C9.5992401,4.11047929 9.79213698,4 10,4 C10.207863,4 10.4007599,4.10885459 10.50386,4.28594639 L16.921007,15.1454102 C17.02577,15.3192526 17.02577,15.5369618 16.9243328,15.7124289 C16.8228957,15.8895207 16.6283359,16 16.4204729,16 Z M4.51,14.94 L15.49,14.94 L10,5.56 L4.51,14.94 Z"
            id="形状"
            fill="#FEB64D"
            fillRule="nonzero"
          />
          <path
            d="M9.99002258,8.62713241 C9.67074498,8.62713241 9.41465774,8.88545898 9.42629807,9.19740049 L9.5194207,11.9285134 C9.52773522,12.1770918 9.73726115,12.3736799 9.99168548,12.3736799 C10.2461098,12.3736799 10.4539728,12.1770918 10.4639503,11.9285134 L10.5570729,9.19740049 C10.5653874,8.88545898 10.3093002,8.62713241 9.99002258,8.62713241 L9.99002258,8.62713241 Z M9.99002258,13.189277 C9.83537249,13.1876523 9.68737402,13.2461413 9.57928525,13.3533712 C9.46787068,13.4557271 9.40468032,13.6003249 9.40634322,13.7514216 C9.40634322,13.9106418 9.46288196,14.0438668 9.57928525,14.1510967 C9.68903693,14.2567019 9.83703539,14.3151909 9.99002258,14.3119415 C10.1430098,14.3135662 10.2926711,14.2567019 10.4007599,14.1510967 C10.5138374,14.0471162 10.5770277,13.9025183 10.5737019,13.7514216 C10.5753648,13.6019496 10.5138374,13.4573517 10.4007599,13.3533712 C10.2926711,13.2461413 10.1446727,13.1860276 9.99002258,13.189277 L9.99002258,13.189277 Z"
            id="形状"
            fill="#FEB64D"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);
