import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Timeline,
} from 'kenshin';
import { connect, history, useActivate } from 'umi';
import TreeData from './components/TreeData';
import TablePro from '@/components/TablePro';
import { OSATYPEKEY } from '../assessment/_keys';
import renderProcessList from '../components/ProcessList';
import PreviewIndicators from '../components/PreviewIndicators';
import AlertToOsa from '../components/AlertToOSA';
import CollapseSearch from '../components/CollapseSearch';

const OSAWEEKOSATYPE = {
  WEEK: 1,
  OSA: 3,
};

const isShowEditBtn = (row) => {
  if (row.osaWeekType == OSAWEEKOSATYPE.OSA) {
    const showKey = ['CREATE_OSA_NODE', 'CONFIRM_OSA_NODE'];
    return showKey.includes(row.curnNodeNumber);
  } else if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
    const showKey = ['CREATE_WEEK_PLAN_NODE', 'CONFIRM_WEEK_PLAN_NODE'];
    return showKey.includes(row.curnNodeNumber);
  }
  return false;
};

const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

/** 是否显示“评分”按钮 */
const isShowScoreBtn = (row) => {
  if (row) {
    const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    if (
      ['SCORE_ONE_NODE', 'SUMMER_WEEK_PLAN_ONE_NODE'].includes(
        row.curnNodeNumber,
      )
    )
      return cuEmpId == row.osaMuId;
    if (
      ['SCORE_TWO_NODE', 'SCORE_WEEK_PLAN_ONE_NODE'].includes(
        row.curnNodeNumber,
      )
    )
      return cuEmpId == row.directLeader?.id;
    if (['SCORE_THREE_NODE'].includes(row.curnNodeNumber))
      return cuEmpId == row.busiLeader?.id;
    return false;
  }
  return false;
};

const Template = ({ dispatch, performance }) => {
  const [treeKey, setTreeKay] = useState('');

  /** 表格ref */
  const tableRef = useRef();
  // 树组件
  const treeHandleChange = (keys) => {
    setTreeKay(keys[0]);
  };

  useActivate(() => {
    if (treeKey) {
      dispatch({
        type: 'performance/getosaempstatdata',
        payload: {
          deptId: treeKey,
        },
      });
    }
    dispatch({
      type: 'performance/getosaemployeepage',
    });
  });

  useEffect(() => {
    if (treeKey) {
      tableRef.current.reload({ deptId: treeKey });
      dispatch({
        type: 'performance/getosaempstatdata',
        payload: {
          deptId: treeKey,
        },
      });
    } else {
      dispatch({
        type: 'performance/save',
        payload: { osaSubAssessment: {}, osaSubAssessmentSearch: {} },
      });
    }
  }, [treeKey]);

  /** 获取当前流程数据 */
  const getProcessList = (osaId, visible) => {
    if (visible) {
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId,
          todoBusiId: '',
        },
      });
    }
  };

  /** 详情页 */
  const toDetail = () => {
    history.push({
      pathname: '/performance/assessment/OSA/OSADetail',
    });
  };

  /** 跳转编辑页面 */
  const handleEdit = (row, disabled) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: { ...row, title: row.userInfo.value, key: row.osaMuId },
        },
      },
    });

    if (disabled) {
      return toDetail(row);
    }

    let type = row.osaWeekType == OSAWEEKOSATYPE.OSA ? 1 : 2;
    if (type == OSATYPEKEY.OSA) {
      history.push({
        pathname: '/performance/assessment/OSA/OSAsetting',
        query: {
          muId: row.osaMuId,
          osaYearQuarter: row.osaYearQuarter,
          target: 'E',
        },
      });
    } else if (type == OSATYPEKEY.WEEK) {
      if (row.osaMuId && row.osaWeekOfYear && row.osaWeekOfMonth) {
        history.push({
          pathname: '/performance/assessment/weeklyPlan',
          query: {
            muId: row.osaMuId,
            osaWeekOfYear: `${row.osaYear}${row.osaWeekOfYear}`,
            osaWeekOfMonth: row.osaWeekOfMonth,
            target: 'E',
          },
        });
      } else {
        message.error('当前数据有问题，请联系管理员');
        localStorage.setItem('errorData', JSON.stringify(row));
      }
    }
  };

  /** 删除  */
  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '你确定要删除吗？',
      onOk: () => {
        dispatch({
          type: 'performance/deleteosaemployee',
          payload: row,
        }).then((success) => {
          if (success) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  /** 评分 */
  const toScoreSet = (row, path) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: row,
        },
      },
    });
    history.push({
      pathname: path,
      query: {},
    });
  };

  /** 表格表头配置 */
  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      fixed: 'left',
      width: 120,
      render(userInfo) {
        return userInfo?.value;
      },
    },
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      fixed: 'left',
      width: 120,
      render(value) {
        const key = {
          [OSAWEEKOSATYPE.WEEK]: '周计划',
          [OSAWEEKOSATYPE.OSA]: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaTime',
      title: '考核周期',
      width: 220,
      render(row) {
        const YMD = (time) => moment(time).format('YYYY-MM-DD');
        return [YMD(row.osaBeginTime), YMD(row.osaEndTime)].join(' ~ ');
      },
    },
    {
      dataIndex: 'busiLeader',
      title: '业务负责人',
      width: 120,
      render(busiLeader) {
        return busiLeader?.value;
      },
    },
    {
      dataIndex: 'directLeader',
      title: '主管(考核人)',
      width: 120,
      render(directLeader) {
        return directLeader?.value;
      },
    },
    {
      dataIndex: 'osaType',
      title: '考核类型',
      width: 120,
      render(val) {
        const key = {
          1: '试用期转正评估',
          2: '绩效评估',
          3: '绩效改进评估',
          4: '个人发展计划',
        };
        return key[val];
      },
    },
    {
      key: 'osaSumRemark',
      title: '评分',
      width: 180,
      render: (row) => {
        if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
          return <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>;
        }
        return [
          <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
          <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
          <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
        ];
      },
    },
    {
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
      render(val, row) {
        return (
          <Tooltip
            title={renderProcessList(row.osaId, performance)}
            onVisibleChange={(visible) => getProcessList(row.osaId, visible)}
            placement="left"
            trigger="click"
            overlayStyle={{
              color: '#333',
              maxWidth: 600,
              fontSize: 12,
              lineHeight: '24px',
            }}
            color="#fff"
          >
            <Button type="text">{val}</Button>
          </Tooltip>
        );
      },
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'options',
      render: (row) => {
        return (
          <>
            {/* {row.hasbtnList.includes(btnKeys.add) && row.permType == 1 && ( */}
            {row.curnNodeNumber == 'GRADE_NODE' && (
              <Button
                type="text"
                onClick={() =>
                  toScoreSet(row, '/performance/assessment/levelSetting')
                }
              >
                评级
              </Button>
            )}
            {isShowScoreBtn(row) && (
              <Button
                type="text"
                onClick={() => {
                  // if(moment()>moment(row.osaScoreBeginTime)){
                  toScoreSet(row, '/performance/assessment/scoreSetting');
                  // }else {
                  //   message.error("暂未到评分时间！")
                  // }
                }}
              >
                {row.curnNodeNumber == 'SUMMER_WEEK_PLAN_ONE_NODE'
                  ? '总结'
                  : '评分'}
              </Button>
            )}
            <PreviewIndicators row={row} />
            <Button type="text" onClick={() => handleEdit(row, true)}>
              查看
            </Button>
            {isShowEditBtn(row) && (
              <Button type="text" onClick={() => handleEdit(row)}>
                编辑
              </Button>
            )}
            {[
              'WEEK_PLAN_BEGIN_NODE',
              'CREATE_WEEK_PLAN_NODE',
              'CONFIRM_WEEK_PLAN_NODE',
              'AUDIT_WEEK_PLAN_ONE_NODE',
              'OSA_BEGIN_NODE',
              'CREATE_OSA_NODE',
              'CONFIRM_OSA_NODE',
              'AUDIT_OSA_ONE_NODE',
            ].includes(row.curnNodeNumber) && (
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => delItem({ osaId: row.osaId })}
              >
                删除
              </Button>
            )}

            {/* )} */}
          </>
        );
      },
    },
  ];

  const extra = (hasbtnList) => (
    <>
      <Button
        type="primary"
        key={'osa'}
        style={{ marginRight: 8 }}
        onClick={() => {
          history.push({
            pathname: '/performance/assessment/OSA',
          });
        }}
      >
        添加OSA绩效
      </Button>
      <Button
        type="primary"
        key={btnKeys.import}
        style={{ marginRight: 8 }}
        onClick={() => {
          history.push({
            pathname: '/performance/assessment/weeklyPlan',
          });
        }}
      >
        添加周计划
      </Button>
    </>
  );

  const handleSubmit = (payload) => {
    if (!payload.deptId) {
      return false;
    }
  };

  const searchData = useRef({
    deptId: treeKey,
  });
  const CollapseSearchChange = (data = {}) => {
    Object.assign(searchData.current, data);
    tableRef.current?.reload(data);
  };

  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData onChange={treeHandleChange} />
        </Col>
        <Col span={16}>
          <CollapseSearch onChange={CollapseSearchChange} />
          <AlertToOsa payload={{ ...searchData.current, deptId: treeKey }} />
          <TablePro
            params={{ deptId: treeKey }}
            request="performance/getosaemployeepage"
            rowKey={'osaId'}
            onSubmit={handleSubmit}
            columns={columns}
            curColumns
            scrollX
            data={performance.osaSubAssessment}
            extra={extra}
            actionRef={(ref) => (tableRef.current = ref)}
            // rowSelection={{
            //   type: 'checkbox',
            //   ...rowSelection,
            // }}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(({ employee_salary, performance }) => {
  return { employee_salary, performance };
})(Template);
