import { useSelector } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { Alert } from 'kenshin';
import { useEffect, useRef, useState } from 'react';
import { deepClone } from '@/_util/util';

const timeToMement = (payload, key) => {
  if (payload[key]) {
    return (payload[key] = moment(payload[key]));
  }
};

const mementToTime = (payload, key) => {
  if (payload[key]) {
    return (payload[key] = moment(payload[key])?.format('YYYY-MM-DD HH:mm:ss'));
  }
  return '';
};

const EditModal = ({ visible, onSave, onCancel, data, typeList }) => {
  const modalRef = useRef();

  const [beginErrorType, setBeginErrorType] = useState(-1);
  const [endErrorType, setEndErrorType] = useState(-1);

  useEffect(() => {
    if (visible && data) {
      let formData = deepClone(data);
      setBeginErrorType(data.attendanceBeginStatus);
      setEndErrorType(data.attendanceEndStatus);

      timeToMement(formData, 'attendanceBeginCheckinTime');
      timeToMement(formData, 'attendanceEndCheckinTime');
      timeToMement(formData, 'attendanceBeginSchCheckinTime');
      timeToMement(formData, 'attendanceEndSchCheckinTime');

      modalRef.current.form.setFieldsValue(formData);
    }
  }, [visible]);

  const disabledDate = (time) => {
    return (current) => {
      return (
        current > moment(time).endOf('day') ||
        current < moment(time).startOf('day')
      );
    };
  };

  const formItemColumns = [
    {
      render: () => (
        <Alert title="上班考勤" type="info" showIcon closable={false} />
      ),
      formItemProps: {
        wrapperCol: 20,
      },
    },
    {
      name: 'attendanceBeginSchCheckinTime',
      label: '标准时间',
      type: 'DatePicker',
      props: {
        placeholder: '请输入考勤上班标准时间',
        showTime: true,
        disabledDate: disabledDate(data?.attendanceBeginCheckinTime),
      },
    },
    {
      name: 'attendanceBeginCheckinTime',
      label: '打卡时间',
      type: 'DatePicker',
      props: {
        placeholder: '请输入考勤上班打卡时间',
        showTime: true,
        disabledDate: disabledDate(data?.attendanceBeginCheckinTime),
      },
    },
    {
      name: 'attendanceBeginStatus',
      label: '打卡状态',
      type: 'Select',
      props: {
        placeholder: '请输入上班打卡状态',
        options: typeList,
        onChange: setBeginErrorType,
      },
    },
    beginErrorType == 2
      ? {
          name: 'attendanceBeginException',
          label: '异常信息',
          type: 'Input',
          props: {
            placeholder: '请输入上班打卡异常信息',
          },
        }
      : null,
    {
      name: 'attendanceBeginRemark',
      label: '打卡备注',
      type: 'TextArea',
      props: {
        placeholder: '请输入考勤上班打卡备注',
        autoSize: { minRows: 2, maxRows: 6 },
      },
    },
    {
      render: () => (
        <Alert title="下班考勤" type="info" showIcon closable={false} />
      ),
      formItemProps: {
        wrapperCol: 20,
      },
    },
    {
      name: 'attendanceEndSchCheckinTime',
      label: '标准时间',
      type: 'DatePicker',
      props: {
        placeholder: '请输入考勤下班标准时间',
        showTime: true,
        disabledDate: disabledDate(data?.attendanceBeginCheckinTime),
      },
    },
    {
      name: 'attendanceEndCheckinTime',
      label: '打卡时间',
      type: 'DatePicker',
      props: {
        placeholder: '请输入考勤下班打卡时间',
        showTime: true,
        disabledDate: disabledDate(data?.attendanceEndCheckinTime),
      },
    },
    {
      name: 'attendanceEndStatus',
      label: '打卡状态',
      type: 'Select',
      props: {
        placeholder: '请输入下班打卡状态',
        options: typeList,
        onChange: setEndErrorType,
      },
    },
    endErrorType == 2
      ? {
          name: 'attendanceEndException',
          label: '异常信息',
          type: 'Input',
          props: {
            placeholder: '请输入下班打卡异常信息',
          },
        }
      : null,
    {
      name: 'attendanceEndRemark',
      label: '打卡备注',
      type: 'TextArea',
      props: {
        placeholder: '请输入考勤下班打卡备注',
        autoSize: { minRows: 2, maxRows: 6 },
      },
    },
    {
      render: () => (
        <div style={{ border: '1px solid #eee', margin: '10px 0' }}></div>
      ),
      formItemProps: {
        wrapperCol: 20,
      },
    },
    {
      name: 'attendanceRemark',
      label: '备注',
      type: 'TextArea',
      props: {
        placeholder: '请输入备注',
        autoSize: { minRows: 2, maxRows: 6 },
      },
    },
  ].filter(Boolean);

  const handleSave = (value) => {
    let payload = {
      ...data,
      ...value,
    };
    mementToTime(payload, 'attendanceBeginCheckinTime');
    mementToTime(payload, 'attendanceEndCheckinTime');
    mementToTime(payload, 'attendanceBeginSchCheckinTime');
    mementToTime(payload, 'attendanceEndSchCheckinTime');
    onSave?.(payload);
  };

  return (
    <ModalFormPro
      title="修改打卡信息"
      visible={visible}
      edtaFormItem={formItemColumns}
      onSave={handleSave}
      ref={modalRef}
      onCancel={onCancel}
    />
  );
};

export default EditModal;
