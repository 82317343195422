import { Input as KsInput } from 'kenshin';
const Input = ({ onChange, ...props }) => {
  const hanldeChange = (e) => {
    e.target.value = e.target.value?.trim().replace(/\t/g, '');
    onChange?.(e);
  };

  return <KsInput {...props} onChange={hanldeChange} />;
};

export default Input;
