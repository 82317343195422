import { Link } from 'umi';

const Breadcrumb = ({ routes, separator }) => {
  let renderLink = () => {
    return routes.map((item, index) => {
      if (index == routes.length - 1) {
        return <span key={item.path}>{item.breadcrumbName}</span>;
      }
      return (
        <Link key={item.path} to={item.path} style={{ color: '#999' }}>
          {item.breadcrumbName}
        </Link>
      );
    });
  };
  let children = renderLink();
  children = children.map((item, index) => {
    if (index !== children.length - 1) {
      return [
        item,
        <span key={index} style={{ margin: '0 8px' }}>
          {separator}
        </span>,
      ];
    }
    return item;
  });
  return <div style={{ marginBottom: 20, fontSize: 14 }}>{children}</div>;
};

export default Breadcrumb;
