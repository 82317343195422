import { useEffect, useRef, useState } from 'react';
import { Tabs, Input, message } from 'kenshin';

import {
  exportemposaindexdata,
  queryosadirectbusiscoregrade,
} from '@/_serveice/performance/osa';
export const scoreTypeKey = {
  SUMMER_WEEK_PLAN_ONE_NODE: 'osaSelfRemark',
  SCORE_ONE_NODE: 'osaSelfRemark',
  SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectLeaderRemark',
  SCORE_TWO_NODE: 'osaDirectLeaderRemark',
  SCORE_THREE_NODE: 'osaBusiLeaderRemark',
};

const JobEvaluation = ({
  curData,
  onChange,
  superiorShow = true,
  processlist,
}) => {
  const [activeKey, setActiveKey] = useState('');
  const once = useRef(true);
  useEffect(() => {
    const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    if (curData.osaId && once.current) {
      let key = 'SCORE_ONE_NODE';
      if (curData.directLeader?.id == cuEmpId)
        key =
          curData.osaWeekType == 3
            ? 'SCORE_TWO_NODE'
            : 'SUMMER_WEEK_PLAN_ONE_NODE';
      else if (curData.busiLeader?.id == cuEmpId) key = 'SCORE_THREE_NODE';
      setActiveKey(key);
      once.current = false;
    }
  }, [curData]);
  const disabledTextArea = () => {
    const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    let disabled =
      curData.oldOsaProcessNodeNumber == 'SCORE_ONE_NODE' ||
      !processlist?.[0]?.curnNode;
    if (
      activeKey == 'SCORE_ONE_NODE' &&
      curData.osaMuId == cuEmpId &&
      !disabled
    )
      return false;
    if (
      activeKey == 'SCORE_TWO_NODE' &&
      curData.directLeader?.id == cuEmpId &&
      !disabled &&
      cuEmpId == curData.processApplyMuId
    )
      return false;
    if (
      activeKey == 'SCORE_THREE_NODE' &&
      curData.busiLeader?.id == cuEmpId &&
      !disabled &&
      cuEmpId == curData.processApplyMuId
    )
      return false;
    return true;
  };

  const handleChange = async (e) => {
    let key = {
      SCORE_ONE_NODE: 'osaSelfRemark',
      SCORE_TWO_NODE: 'osaDirectLeaderRemark',
      SCORE_THREE_NODE: 'osaBusiLeaderRemark',
    };
    onChange?.({ [key[activeKey]]: e.target.value });
  };

  return (
    <div className="border-card">
      <Tabs
        activeKey={activeKey}
        titleText="卡片标题"
        type="card"
        onChange={setActiveKey}
      >
        {superiorShow && (
          <Tabs.TabPane tab="总结(自评)" key="SCORE_ONE_NODE"></Tabs.TabPane>
        )}
        <Tabs.TabPane tab="总结(上级)" key="SCORE_TWO_NODE"></Tabs.TabPane>
        {curData.osaWeekType == 3 && superiorShow && (
          <Tabs.TabPane
            tab="总结(业务负责人) "
            key="SCORE_THREE_NODE"
          ></Tabs.TabPane>
        )}
      </Tabs>
      <Input.TextArea
        autoSize={{ minRows: 2, maxRows: 6 }}
        placeholder="请输入内容"
        key={activeKey}
        disabled={disabledTextArea()}
        defaultValue={curData[scoreTypeKey[activeKey]]}
        onBlur={handleChange}
      />
    </div>
  );
};
export default JobEvaluation;
