import { Button, Typography, Tabs, Input, Radio, message } from 'kenshin';
import CollapseCard, { Template } from '../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../_keys';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import Indicators from './components/indicators';

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

const scoreTypeKey = {
  SCORE_ONE_NODE: 'osaSelfRemark',
  SCORE_TWO_NODE: 'osaDirectLeaderRemark',
  SCORE_THREE_NODE: 'osaBusiLeaderRemark',
};
/** 不同节点的评分参数 */
const scoreKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItem',
  SCORE_THREE_NODE: 'osaBusiBonusItem',
};

/** 不同节点的备注参数 */
const scoreRemarkKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItemRemark',
  SCORE_THREE_NODE: 'osaBusiBonusItemRemark',
};

/** 获取自评、上级、业务负责人评分总分 */
function getTotalScore(list) {
  let osaIndexCatgSelfScore = 0;
  let osaIndexCatgDirectLeaderScore = 0;
  let osaIndexCatgBusiLeaderScore = 0;
  list?.forEach((item) => {
    osaIndexCatgSelfScore +=
      item.osaIndexItemSelfScore ?? item.osaIndexCatgSelfScore ?? 0;

    osaIndexCatgDirectLeaderScore +=
      item.osaIndexItemDirectLeaderScore ??
      item.osaIndexCatgDirectLeaderScore ??
      0;

    osaIndexCatgBusiLeaderScore +=
      item.osaIndexItemBusiLeaderScore ?? item.osaIndexCatgBusiLeaderScore ?? 0;
  });
  return [
    <div key={1} style={{ lineHeight: 2 }}>
      <span style={{ fontWeight: 'bold' }}>自评总得分：</span>
      {osaIndexCatgSelfScore}
    </div>,
    <div key={2} style={{ lineHeight: 2 }}>
      <span style={{ fontWeight: 'bold' }}>上级总得分：</span>
      {osaIndexCatgDirectLeaderScore}
    </div>,
    <div key={3} style={{ lineHeight: 2 }}>
      <span style={{ fontWeight: 'bold' }}>业务负责人总得分：</span>
      {osaIndexCatgBusiLeaderScore}
    </div>,
  ];
}

const createTemplate = ({ performance, dispatch, loadingEf }) => {
  let curData = performance.curOsaSettingData.rows ?? {};
  const [yearNumber, setYearNum] = useState(0);
  const auditResult = useRef(AUDITTYPE.SUCCESS);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('SCORE_TWO_NODE');
  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    init();
    dispatch({
      type: 'performance/getosaauditsystemdescribelist',
    }).then((list) => {
      // console.log(res);
      if (list) {
        setResultList(
          list.map((item) => ({ label: item.value, value: item.id })),
        );
      }
    });
    return () => {
      dispatch({
        type: 'performance/save',
        payload: {
          curOsaSettingData: {
            rows: {},
          },
        },
      });
    };
  }, [history.location.query.busiId]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  const init = async () => {
    if (history.location.query.busiId) {
      curData = await dispatch({
        type: 'performance/getosaemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                },
              },
            },
          });
          return res;
        }
        return {};
      });
    }
    if (curData.osaYearQuarter) {
      // await dispatch({
      //   type: 'performance/getosagradeaenablenum',
      //   payload: { yearQuarter: curData.osaYearQuarter },
      // }).then((res) => {
      //   if (typeof res == 'number') {
      //     let count = res;
      //     if (curData.osaGrade == 'A') {
      //       count += 1;
      //     }
      //     setYearNum(count);
      //   }
      // });
    }
  };

  // 修改绩效数据
  const handleSave = (value) => {
    if (value.score) {
      value[scoreKey[curData.curnNodeNumber]] = value.score;
      value.score = undefined;
    }
    if (value.remark) {
      value[scoreRemarkKey[curData.curnNodeNumber]] = value.remark;
      value.remark = undefined;
    }
    // 保存dva当前的数据
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: {
            ...curData,
            ...value,
          },
        },
      },
    });
    // 后端保存数据
    dispatch({
      type: 'performance/updateosaemployeedata',
      payload: {
        ...curData,
        ...value,
      },
    });
  };

  const extraScoreCloumns = [
    {
      render() {
        return (
          <Tabs
            activeKey={activeKey}
            titleText="卡片标题"
            type="card"
            onChange={setActiveKey}
          >
            <Tabs.TabPane tab="直属上级" key="SCORE_TWO_NODE"></Tabs.TabPane>
            <Tabs.TabPane
              tab="业务负责人"
              key="SCORE_THREE_NODE"
            ></Tabs.TabPane>
          </Tabs>
        );
      },
    },
    {
      title: '加分分值',
      dataIndex: 'score',
      valueType: 'InputNumber',
      fieldProps: {
        style: { width: 200 },
        stepType: 'inside',
        min: 0,
        disabled: true,
        max: 100,
        placeholder: '请输入分数:0~20',
      },
    },
    {
      title: '原因描述',
      dataIndex: 'remark',
      valueType: 'TextArea',
      fieldProps: {
        autoSize: { minRows: 2, maxRows: 6 },
        disabled: true,
        placeholder: '请输入原因描述',
      },
    },
  ];

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const editModalSave = (value) => {
    /** 通过/拒绝原因 */
    let auditRemarkList = [
      ...(value.auditRemarkList ?? []),
      value.auditRemark,
    ]?.map((txt) => {
      let SUCCESSID = 88888888;
      let ERRORID = 99999999;
      let auditRmkDescribe =
        resultList.find((item) => item.value == txt)?.label || txt;
      let auditRmkDescribeId =
        resultList.find((item) => item.value == txt)?.value ??
        (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
      let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
      return {
        auditRmkDescribe,
        auditRmkDescribeId,
        auditRmkType,
      };
    });
    let payload = {
      ...value,
      auditRemarkList,
      osaId: curData.osaId,
      auditResult: auditResult.current,
    };
    dispatch({
      type: 'performance/auditosaprocess',
      payload,
    }).then((res) => {
      if (res) {
        setEditModalVisible(false);
        clearCache();
        goback();
      }
    });
  };

  const edtaFormItem = [
    auditResult.current == AUDITTYPE.ERROR
      ? {
          label: '原因',
          name: 'auditRemarkList',
          type: 'Select',
          props: {
            mode: 'tags',
            options: resultList,
            placeholder: '请输入原因，按enter确定',
          },
        }
      : null,
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
    ...(() => {
      if (
        ['HR_CONFIRM_NODE', 'HR_PRE_CONFIRM_NODE'].includes(
          curData.curnNodeNumber,
        ) &&
        auditResult.current == AUDITTYPE.ERROR
      ) {
        return [
          {
            label: '驳回到',
            name: 'backNode',
            required: '请选择驳回节点',
            type: 'RadioGroup',
            props: {
              options: [
                {
                  label: '自评',
                  value: 1,
                },
                {
                  label: '直属上级评分',
                  value: 2,
                },
                {
                  label: '业务负责人评分',
                  value: 3,
                },
              ],
            },
          },
        ];
      }
      return [];
    })(),
  ].filter(Boolean);

  const radioOptions = [
    // {
    //   label: `A(剩余${yearNumber}个)`,
    //   disabled: !yearNumber,
    //   value: 'A',
    // },
    {
      label: `A`,
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
  ];

  const radioChange = (e) => {
    let osaGrade = e.target.value;
    handleSave({ osaGrade });
  };

  const levelTextAreaChange = (e) => {
    let value = e.target.value;
    handleSave({ osaGradeRemark: value });
  };

  const HeaderButton = (
    <div className="fixed-right">
      <Button
        type="primary"
        onClick={() => {
          console.log('确认评级：', curData.osaGrade);

          if (!curData.osaGrade) {
            return message.error('请选择考核评级');
          }

          if (
            ['A', 'C'].includes(curData.osaGrade) &&
            !curData.osaGradeRemark
          ) {
            return message.error('评级为A或者C时，请写明备注！');
          }
          setEditModalVisible(true);
          auditResult.current = AUDITTYPE.SUCCESS;
        }}
      >
        确认评级
      </Button>
      {['HR_CONFIRM_NODE', 'HR_PRE_CONFIRM_NODE'].includes(
        curData.curnNodeNumber,
      ) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      <Button type="primary" onClick={() => goback()}>
        返回
      </Button>
    </div>
  );

  return (
    <div className="create-template-page">
      {HeaderButton}
      <Typography.Title level={4}>
        {curData.userInfo?.value}的{curData.osaName}评级
      </Typography.Title>
      <CollapseCard
        title={`${curData.userInfo?.value}(${curData.userInfo?.extendData})`}
      >
        <Indicators idKey="osaId" idValue={curData.osaId} />
        {getTotalScore(performance.osaIndicators)}
      </CollapseCard>

      <CollapseCard
        title={
          <>
            加分项
            <span style={{ color: '#999', fontSize: 12 }}>（最高上浮20%)</span>
          </>
        }
        cloumns={extraScoreCloumns}
        onSave={handleSave}
        key={activeKey}
        defaultValue={{
          score: curData[scoreKey[activeKey]],
          remark: curData[scoreRemarkKey[activeKey]],
        }}
      />

      <CollapseCard title="综合结论">
        <div>
          考核评级
          <Radio.Group
            style={{ marginLeft: 8 }}
            key={curData.osaGrade}
            disabled={['HR_CONFIRM_NODE', 'HR_PRE_CONFIRM_NODE'].includes(
              curData.curnNodeNumber,
            )}
            defaultValue={curData.osaGrade}
            options={radioOptions}
            onChange={radioChange}
          />
        </div>
        <Input.TextArea
          placeholder="具体原因"
          autoSize={{ minRows: 2, maxRows: 6 }}
          key={curData.osaGradeRemark}
          disabled={['HR_CONFIRM_NODE', 'HR_PRE_CONFIRM_NODE'].includes(
            curData.curnNodeNumber,
          )}
          defaultValue={curData.osaGradeRemark}
          style={{ marginTop: 8 }}
          onBlur={levelTextAreaChange}
        />
      </CollapseCard>

      <ModalFormPro
        title="确认绩效"
        width={600}
        visible={editModalVisible}
        btnLoading={loadingEf['performance/auditosaprocess']}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(createTemplate);
