// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from 'D:/dev/workspace/wdp-pc/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelLowCode0 from 'D:/dev/workspace/wdp-pc/src/models/admin/lowCode.js';
import ModelSystem1 from 'D:/dev/workspace/wdp-pc/src/models/admin/system.js';
import ModelOrganizational2 from 'D:/dev/workspace/wdp-pc/src/models/admin/system/organizational.js';
import ModelProcess3 from 'D:/dev/workspace/wdp-pc/src/models/admin/system/process.js';
import ModelIndex4 from 'D:/dev/workspace/wdp-pc/src/models/attendance/index.js';
import ModelIndex5 from 'D:/dev/workspace/wdp-pc/src/models/contract/list/index.js';
import ModelIndex6 from 'D:/dev/workspace/wdp-pc/src/models/customer/list/index.js';
import ModelIndex7 from 'D:/dev/workspace/wdp-pc/src/models/dataAnalyse/index.js';
import ModelIndex8 from 'D:/dev/workspace/wdp-pc/src/models/employee/index.js';
import ModelIndex9 from 'D:/dev/workspace/wdp-pc/src/models/etlmodel/index.js';
import ModelHome10 from 'D:/dev/workspace/wdp-pc/src/models/home.js';
import ModelIndex11 from 'D:/dev/workspace/wdp-pc/src/models/performance/index.js';
import ModelIndex12 from 'D:/dev/workspace/wdp-pc/src/models/project/index.js';
import ModelUser13 from 'D:/dev/workspace/wdp-pc/src/models/user.js';
import ModelIndex14 from 'D:/dev/workspace/wdp-pc/src/models/usercenter/index.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'lowCode', ...ModelLowCode0 });
app.model({ namespace: 'system', ...ModelSystem1 });
app.model({ namespace: 'organizational', ...ModelOrganizational2 });
app.model({ namespace: 'process', ...ModelProcess3 });
app.model({ namespace: 'index', ...ModelIndex4 });
app.model({ namespace: 'index', ...ModelIndex5 });
app.model({ namespace: 'index', ...ModelIndex6 });
app.model({ namespace: 'index', ...ModelIndex7 });
app.model({ namespace: 'index', ...ModelIndex8 });
app.model({ namespace: 'index', ...ModelIndex9 });
app.model({ namespace: 'home', ...ModelHome10 });
app.model({ namespace: 'index', ...ModelIndex11 });
app.model({ namespace: 'index', ...ModelIndex12 });
app.model({ namespace: 'user', ...ModelUser13 });
app.model({ namespace: 'index', ...ModelIndex14 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
