import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'umi';
import {
  Button,
  Col,
  message,
  MessageBox,
  Progress,
  Row,
  Table,
  Upload,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import ModalFormPro from '@/components/ModalFormPro';
import { roleType } from '@/_util/keysType';
import { exportContCollectList } from '@/_serveice/contract/list';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { SortAscendingOutlined } from '@ant-design/icons';
import {
  getContexpectCollectList,
  collectDownTemplate,
  importContractCollect,
} from '@/_serveice/contract/list/index';
import { getColumns } from './columns';

export const list = 'LIST';

const ContractPage = ({
  contract,
  dispatch,
  customer,
  project,
  systemOrgani,
}) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const collectAmountMax = useRef(null);

  const modalRef = useRef();
  const tableRef = useRef();

  const sortType = useRef();

  useEffect(() => {
    dispatch({
      type: 'customer/getEasyCustomer',
    });
  }, []);

  const showEditModal = async (row = null) => {
    setEditModalVisible(true);
    if (row) {
      setEditModalData({
        ...row,
        collectAmount: (row.collectAmount / 100).toFixed(2), //本次收款金额
        // contractCollectionAmount: (row.contractCollectionAmount / 100).toFixed(
        //   2,
        // ), //已收金额
        // contractAmount: (row.contractAmount / 100).toFixed(2), //合同金额含税
        // lastContractAmount: (row.contractRemainAmount / 100).toFixed(2), //剩余尾款
        collectDateTime: moment(row.collectDateTime),
      });
      await handleCustChange(row.customerId);
      await handleContractChange(row.contractId, row);
    }
  };

  const hideModal = () => {
    setEditModalData(null);
    setEditModalVisible(false);
    // 清除项目名称列表数据，避免下次未选择合同就有项目出现
    dispatch({
      type: 'project/save',
      payload: {
        ProjectLByIdList: [],
      },
    });
  };

  const editModalSave = (resValue) => {
    const {
      contractAmount,
      contractCollectionAmount,
      lastContractAmount,
      ...value
    } = resValue;

    let payload = { ...value };
    payload.collectUserString = localStorage.getItem('muId');
    let hasEdit = false;
    payload.collectAmount = +(payload.collectAmount * 100).toFixed(2);
    if (editModalData?.proCollectId) {
      hasEdit = true;
      payload.proCollectId = editModalData?.proCollectId;
    }
    if (payload.collectDateTime) {
      payload.collectDateTime = moment(payload.collectDateTime).format(
        'YYYY-MM-DD HH:mm:ss',
      );
    }
    dispatch({
      type: 'contract/editContractCollect',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        hideModal();
      }
    });
  };

  const contractbyIdList = useRef([]);
  const handleCustChange = async (custId) => {
    const { form } = modalRef.current;
    form.setFieldsValue({ contractId: null, projectId: null });
    await dispatch({
      type: 'contract/getContractById',
      payload: {
        custId,
      },
    }).then((res) => {
      contractbyIdList.current = res;
    });
  };

  // 收款批次
  const [batchjBatchCollect, setBatchjBatchCollect] = useState([]);
  const getBatchCollect = (contractNumber, setFieldsValue, row) => {
    let lastCollectStr = ['尾款', '结算款'];
    getContexpectCollectList({ contractNumber }).then((res) => {
      if (res.success) {
        setBatchjBatchCollect(res.data);
        let collectBatch = res.data?.find((item) => item.used == 0);
        let lastCollectBatch = res.data?.[res.data?.length - 1];
        if (!row?.proCollectId) {
          if (
            lastCollectStr.includes(lastCollectBatch?.collectBatch) &&
            lastCollectBatch?.used == 1
          ) {
            message.warning('暂无收款批次，因为尾款或结算款已收完毕');
            setFieldsValue({
              collectBatch: null,
            });
            return;
          }

          setFieldsValue({
            collectBatch: collectBatch?.collectBatch,
          });
        }
      }
    });
  };

  /** 合同开始时间 */
  const startDate = useRef();
  const handleContractChange = (contractId, row) => {
    const { form } = modalRef.current;
    let data = JSON.parse(
      contractbyIdList.current.find((item) => item.id == contractId)
        ?.extendData || '{}',
    );
    const { contractNumber } = data;
    data.contractBeginDateTime &&
      (startDate.current = moment(data.contractBeginDateTime));
    getBatchCollect(contractNumber, form.setFieldsValue, row); //  收款批次

    let collectAmount = form.getFieldsValue()?.collectAmount;
    let lastContractAmount = data.lastContractAmount / 100;

    collectAmountMax.current = data.lastContractAmount / 100; // 最大值
    if (row) {
      lastContractAmount += +(row.collectAmount / 100).toFixed(2);
      collectAmountMax.current += +(row.collectAmount / 100).toFixed(2);
    }
    form.setFieldsValue({
      ...data,
      contractCollectionAmount: data.contractCollectionAmount / 100,
      lastContractAmount,
      contractAmount: data.contractAmount / 100,
      projectId: null,
    });
    // 收款金额不能大于剩余尾款
    if (collectAmount > data.lastContractAmount / 100 && !row) {
      form.setFieldsValue({
        collectAmount: (data.lastContractAmount / 100).toFixed(2),
      });
    }
    // setCollectAmountMax(data.lastContractAmount / 100);
    dispatch({
      type: 'project/getProjectLById',
      payload: {
        contractId,
      },
    });
  };

  const delItem = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'contract/delContractCollect',
          payload,
        });
      },
    });
  };

  const A9_TableProProps3408393 = {
    columns: getColumns(sortType.current, { showEditModal, delItem }),
  };

  const collectDateTimeDisabled = (current) => {
    let time = Math.max(
      ...(batchjBatchCollect?.map((item) => item.useTime) || []),
    );
    return current <= moment(time);
  };

  const edtaFormItem = [
    {
      label: '客户名称',
      name: 'customerId',
      type: 'Select',
      required: '请选择客户',
      props: {
        placeholder: '请选择客户',
        onChange: handleCustChange,
        disabled: !!editModalData?.proCollectId,
        options: customer.easyCustomer?.map((item) => ({
          value: item.id,
          label: item.value,
        })),
      },
    },
    {
      label: '合同编号',
      name: 'contractId',
      type: 'Select',
      required: '请输入编号',
      props: {
        placeholder: '请先合同',
        onChange: (val) => handleContractChange(val),
        disabled: !!editModalData?.proCollectId,
        options: contract.contractbyIdList.map((item) => ({
          value: item.id,
          label: `${
            item.extendData && JSON.parse(item.extendData).contractNumber
          }(${item.value})`,
        })),
      },
    },
    {
      label: '项目名称',
      // name: 'projectId',
      type: 'Select',
      // required: '请输入编号',
      render: () => {
        return (
          <Row>
            {project.ProjectLByIdList?.map((item) => (
              <Col key={item.id} span={20}>
                {item.value}
              </Col>
            ))}
          </Row>
        );
      },
    },
    {
      label: '合同金额含税',
      name: 'contractAmount',
      type: 'Input',
      props: {
        placeholder: '请输入金额',
        disabled: true,
        style: { width: '100%' },
      },
    },
    {
      label: '已收款金额',
      name: 'contractCollectionAmount',
      type: 'Input',
      props: {
        placeholder: '请输入金额',
        disabled: true,
        style: { width: '100%' },
      },
    },
    {
      label: '剩余尾款',
      name: 'lastContractAmount',
      type: 'Input',
      props: {
        placeholder: '请输入金额',
        disabled: true,
      },
    },
    {
      label: '收款批次',
      name: 'collectBatch',
      type: 'Select',
      required: '请选择收款批次',
      props: {
        placeholder: '收款批次',
        disabled: !editModalData?.proCollectId,
        options: batchjBatchCollect
          ?.map((item) => {
            let ops = ['结算款', '尾款'];
            if (editModalData?.proCollectId) {
              // 其他款项只能改成尾款或结算款，首款只能改自己
              let newOps =
                editModalData.collectBatch == '首款'
                  ? [editModalData.collectBatch]
                  : [...ops, editModalData.collectBatch];
              if (!newOps.includes(item.collectBatch)) {
                return null;
              }
            }
            return {
              label: item.collectBatch,
              value: item.collectBatch,
              // disabled: editModalData?.proCollectId ? false : item.used != 0,
            };
          })
          .filter(Boolean),
      },
    },
    {
      label: '本次收款金额',
      name: 'collectAmount',
      type: 'InputNumber',
      formItemProps: {
        rules: [
          {
            required: true,
            message: '请输入金额',
          },
          () => ({
            async validator(_, value) {
              if (collectAmountMax.current != undefined && value) {
                if (value > collectAmountMax.current) {
                  return Promise.reject(
                    new Error('本次输入的收款金额必须小于等于尾款金额'),
                  );
                }
                return Promise.resolve();
              }
            },
          }),
        ],
      },
      props: {
        placeholder: '请输入金额',
        stepType: 'inside',
        min: 0,
        style: { width: '100%' },
      },
    },
    {
      label: '收款日期',
      name: 'collectDateTime',
      type: 'DatePicker',
      required: '请选择收款日期',
      props: {
        placeholder: '收款日期',
        showTime: { format: 'HH:mm:ss' },
        disabledDate: collectDateTimeDisabled,
      },
    },
  ];

  // 导入
  const customRequest = async (file) => {
    let formData = new FormData();
    formData.append('file', file.file);
    importContractCollect(formData).then((res) => {
      if (res?.success) {
        message.success('导入成功！');
      }
    });
  };

  // 按收款时间显示
  const timerSort = () => {
    sortType.current = sortType.current == list ? undefined : list;
    tableRef.current.reload({ sort: sortType.current });
  };

  const extra = (hasbtnList) => {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flex: 1 }}>
          {hasbtnList.includes(btnKeys.add) && (
            <Button type="primary" onClick={() => showEditModal()}>
              新增收款
            </Button>
          )}
          {sortType.current != list && (
            <Button
              type="primary"
              onClick={() => {
                let { contractTimeLong, ...value } = tableRef.current.value;
                let collectYearList =
                  typeof value.collectYearList == 'string'
                    ? value.collectYearList
                    : YearListOptions(value.collectYearList) ?? '';
                let payload = {
                  contractAmountMax: value.contractAmountMax ?? '',
                  contractAmountMin: value.contractAmountMin ?? '',
                  contractBeginTime: value.contractBeginTime ?? '',
                  contractEndTime: value.contractEndTime ?? '',
                  contractStatus: value.contractStatus ?? '',
                  collectYearList,
                  key: value.key ?? '',
                  projectManager: value.projectManager ?? '',
                  sortFields: value.sortFields
                    .map((item) => `${item.name}_${item.value}`)
                    .join(','),
                };
                if (contractTimeLong) {
                  payload.beginCooperTime = +moment(
                    contractTimeLong[0],
                  ).startOf('d');
                  payload.endCooperTime = +moment(contractTimeLong[1]).endOf(
                    'd',
                  );
                }
                let url = `${baseURL}${exportContCollectList}${splitParamsFoGet(
                  payload,
                )}`;
                // let sortFields =
                downloadXlsx(url, '合同收款列表');
              }}
            >
              导出
            </Button>
          )}
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => window.open(`${baseURL}${collectDownTemplate}`)}
          >
            下载模板
          </Button>
          {hasbtnList.includes(btnKeys.import) && (
            <Upload action={''} fileList={[]} customRequest={customRequest}>
              <Button type="primary">导入</Button>
            </Upload>
          )}
        </div>
        <div
          className="extra-tooltip"
          style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
          onClick={timerSort}
        >
          <SortAscendingOutlined />
          {sortType.current == list ? '按收款时间显示' : '按合同列表显示'}
        </div>
      </div>
    );
  };

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '合同编号',
      },
    },
    {
      name: 'contractTimeLong',
      type: 'DatePickerRangePicker',
      label: '合同周期',
      props: {},
    },
    {
      name: 'contractStatus',
      type: 'Select',
      label: '合同状态',
      props: {
        placeholder: '合同状态',
        allowClear: true,
        options: [
          { label: '未开启', value: 1 },
          { label: '进行中', value: 2 },
          { label: '已完成', value: 3 },
          { label: '终止', value: 4 },
        ],
      },
    },
    {
      name: 'projectManager',
      type: 'Select',
      label: '项目经理',
      props: {
        placeholder: '项目经理',
        options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
    {
      name: 'contractAmountMin',
      type: 'InputNumber',
      label: '合同最小金额',
      props: {
        placeholder: '合同最小金额（不含税）',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      name: 'contractAmountMax',
      type: 'InputNumber',
      label: '合同最大金额',
      props: {
        placeholder: '合同最大金额（不含税）',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      name: 'collectYearList',
      type: 'DatePickerRangePicker',
      label: '收款年份',
      props: {
        picker: 'year',
      },
    },
  ];

  // 表格折叠后的表格
  const expandedRowRender = (data) => {
    const { collectList, hasbtnList } = data;
    const columns = [
      {
        dataIndex: 'collectBatch',
        title: '收款批次',
        width: 100,
      },
      {
        dataIndex: 'collectDateTime',
        title: '收款时间',
        width: 200,
        render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        dataIndex: 'collectAmount',
        title: '收款金额',
        width: 150,
        render: (val) => val && (val / 100).toFixed(2),
      },
      {
        dataIndex: 'contractRemainAmount',
        title: '剩余尾款',
        width: 100,
        render: (val) => (val / 100).toFixed(2),
      },
      {
        key: 'options',
        title: '操作',
        width: 100,
        render: (row, _, i) => {
          if (i == collectList.length - 1) {
            return (
              <>
                {/* <Button type="text">查看</Button> */}
                {/* {hasbtnList?.includes(btnKeys.edit) && ( */}
                <Button
                  type="text"
                  onClick={() => showEditModal({ ...data, ...row })}
                >
                  编辑
                </Button>
                {/* )} */}
                {/* {hasbtnList?.includes(btnKeys.delete) && ( */}
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delItem({ proCollectId: row.proCollectId })}
                >
                  删除
                </Button>
                {/* )} */}
              </>
            );
          }
          return null;
        },
      },
    ];
    return (
      <Table
        rowKey="proCollectId"
        columns={columns}
        dataSource={collectList ?? []}
        pagination={false}
      />
    );
  };

  const YearListOptions = (timeArr) => {
    const [start, end] = timeArr.map((item) => +moment(item).year());
    let times = [];
    for (let i = start; i <= end; i++) {
      times.push(i);
    }
    return times;
  };

  // 拦截搜索
  const tableSubmit = (val) => {
    if (val.collectYearList) {
      let times = YearListOptions(val.collectYearList);
      val.collectYearList = times;
    }
    if (val.contractTimeLong) {
      const [start, end] = val.contractTimeLong;
      val.contractBeginTimeLong = +moment(start).startOf('d');
      val.contractEndTimeLong = +moment(end).endOf('d');
    }
    val.contractTimeLong = undefined;
    if (!!val.contractAmountMax != !!val.contractAmountMin) {
      message.warning('缺少最小金额或最大金额条件！');
      return false;
    }
    val.contractAmountMax &&
      (val.contractAmountMax = val.contractAmountMax * 100);
    val.contractAmountMin &&
      (val.contractAmountMin = val.contractAmountMin * 100);
  };

  return (
    <div>
      <TablePro
        request="contract/getContractCollectList"
        searchCloumns={searchCloumns}
        data={contract.contractCollectList}
        rowKey={sortType.current == list ? 'contractId' : 'proCollectId'}
        extra={extra}
        scrollX
        autoColumns={false}
        expandable={sortType.current == list ? { expandedRowRender } : {}}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={tableSubmit}
        onReset={tableSubmit}
        {...A9_TableProProps3408393}
      ></TablePro>

      <ModalFormPro
        width={600}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={hideModal}
      />
    </div>
  );
};

export default connect(({ contract, customer, project, systemOrgani }) => ({
  contract,
  customer,
  systemOrgani,
  project,
}))(ContractPage);
