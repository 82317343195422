import React, { useEffect } from 'react';
import { connect, history, useAliveController } from 'umi';
import { Tabs, Button } from 'kenshin';
import { getTabsName } from '@/_util/util';
import styles from './styles.less';

const { TabPane } = Tabs;

const TabsCard = (props) => {
  const { admin, tabsKeys, dispatch } = props;
  const { drop, dropScope, clear, getCachingNodes } = useAliveController();

  const onEdit = (targetKey, action) => {
    if (action == 'remove') {
      let newArr = [...admin.tabsList];
      let i = newArr.indexOf(targetKey);
      newArr.splice(i, 1);
      dispatch({
        type: 'admin/save',
        payload: {
          tabsList: newArr,
        },
      });
      drop(targetKey);
    }
  };

  return (
    <div className={styles.TabsCard}>
      <Tabs
        type="editable-card"
        onChange={(key) => {
          if (key != tabsKeys) {
            history.push(key);
          }
        }}
        hideAdd
        activeKey={tabsKeys}
        onEdit={onEdit}
      >
        {admin.tabsList.map((pane, i) => (
          <TabPane
            tab={getTabsName(pane.split('?')[0])}
            key={pane}
            closable={tabsKeys != pane}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default connect(({ admin }) => ({ admin }))(TabsCard);
