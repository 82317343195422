import { Checkbox, Input, InputNumber, Table, Timeline } from 'kenshin';
import Select from '@/_util/components/Select';
import { useEffect, useRef, useState } from 'react';
import { connect, useSelector, useDispatch } from 'umi';
import { deepClone } from '@/_util/util';
import styles from './style.less';

const StepModel = ({ onChange, value }) => {
  const { templateData, emplist } = useSelector(({ process }) => process);
  const dispatch = useDispatch();

  const [stepData, setStepData] = useState([]);

  const handleChange = (row, val) => {
    if (val.dealList) {
      val.processNodeMinAuditPassNum = val.dealList.length || 1;
    }
    Object.assign(row, val);
    onChange?.([...stepData]);
    setStepData([...stepData]);
  };

  const getEmpList = (roleKey) => {
    dispatch({
      type: 'process/getemplistbyrolekey',
      payload: {
        roleKey,
      },
    });
  };
  useEffect(() => {
    // 流程模板
    dispatch({
      type: 'process/getprocesstemplate',
    }).then((list) => {
      let newList = deepClone(list).map((item, i) => {
        let curItem = value?.find(
          (v) => v.processNodeName == item.processNodeName,
        );
        if (item.roleKey) {
          getEmpList(item.roleKey);
        }
        item.dealList = curItem?.dealList || [];
        item.processNodeMinAuditPassNum =
          curItem?.processNodeMinAuditPassNum || '';
        Object.defineProperty(item, 'disabled', {
          value: i == 0 || i == list.length - 1,
          enumerable: false,
        });
        item.select = item.disabled || !!curItem;
        return item;
      });
      setStepData([...newList]);
    });
  }, []);

  const columns = [
    {
      key: 'index',
      width: '10%',
      title: '序号',
      render: (_, row, index) => index + 1,
    },
    {
      dataIndex: 'processNodeName',
      title: '步骤名称',
      width: '20%',
    },
    {
      key: 'dealList',
      title: '处理人',
      width: '40%',
      render: (row) => {
        // if(row.roleKey){
        //   getEmpList(row.roleKey)
        // }
        if (row.roleKey) {
          return (
            <Select
              onChange={(val) => handleChange(row, { dealList: val })}
              mode="multiple"
              value={row.dealList}
              options={emplist[row.roleKey]?.map((item) => ({
                label: item.value,
                value: item.id,
              }))}
            />
          );
        }
        return '';
      },
    },
    {
      key: 'processNodeMinAuditPassNum',
      title: '最小审核人数',
      width: '30%',
      render: (row) => {
        if (row.roleKey) {
          return (
            <InputNumber
              value={row.processNodeMinAuditPassNum}
              max={row.dealList.length}
              onChange={(val) =>
                handleChange(row, { processNodeMinAuditPassNum: val })
              }
              min={1}
              stepType="inside"
            />
          );
        }
        return '';
      },
    },
  ];

  const itemRender = (row) => {
    return (
      <div className={styles.flexBox}>
        <Checkbox
          disabled={row.disabled}
          checked={row.select}
          onChange={(e) => handleChange(row, { select: e.target.checked })}
        ></Checkbox>
        <div className={styles.rightBox}>
          <div className="title">{row.processNodeName}</div>
          {!row.disabled && (
            <div className={styles.fieldBox}>
              <div style={{ width: 80 }}>步骤处理人</div>
              <Select
                style={{ width: 300 }}
                onChange={(val) => handleChange(row, { dealList: val })}
                mode="multiple"
                value={row.dealList}
                options={emplist[row.roleKey]?.map((item) => ({
                  label: item.value,
                  value: item.id,
                }))}
              />
              <InputNumber
                placeholder="最小审核人数"
                value={row.processNodeMinAuditPassNum}
                onChange={(val) =>
                  handleChange(row, { processNodeMinAuditPassNum: val })
                }
                style={{ marginLeft: 12, width: 100 }}
                min={1}
                stepType="inside"
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    // <Table
    //   rowKey="processNodeId"
    //   columns={columns}
    //   dataSource={stepData ?? []}
    //   pagination={false}
    // />
    <Timeline>
      {stepData?.map((item, i) => (
        <Timeline.Item key={item.processNodeId}>
          {itemRender(item)}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default StepModel;
