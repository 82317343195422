import menuStr from '@/_util/menu';
import { homeRole } from './keysType';
import { getOSSMsg } from '@/_serveice/user';

let ossRootDirectoryBase = ossDirUrl;

// 本地存储需要删除的数据
export const localStorageList = [
  'token',
  'muId',
  'employeeDTO',
  'refreshToken',
  'menu',
  'tableHeadPermissionList',
  'buttonPermissionList',
  'manageDeptId',
];

// 根据location获取menu里面的菜单名
export const getTabsName = (item, prefix = 'menu') => {
  if (item.split('/')[1]) {
    if (!menuStr[`${prefix}${item.split('/').join('.')}`]) {
      return `${prefix}${item.split('/')[1] ? item.split('/').join('.') : ''}`;
    }
    return menuStr[`${prefix}${item.split('/').join('.')}`];
  }
  return menuStr[`${prefix}`];
};

// 深拷贝
export function deepClone(obj) {
  //可传入对象 或 数组
  //  判断是否为 null 或 undefined 直接返回该值即可,
  if (obj === null || !obj) return obj;
  // 判断 是要深拷贝 对象 还是 数组
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    //对象字符串化的值会为 "[object Object]"
    let target = {}; //生成新的一个对象
    const keys = Object.keys(obj); //取出对象所有的key属性 返回数组 keys = [ ]
    //遍历复制值, 可用 for 循环代替性能较好
    keys.forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        //如果遇到的值又是 引用类型的 [ ] {} ,得继续深拷贝
        target[key] = deepClone(obj[key]);
        //递归
      } else target[key] = obj[key];
    });
    return target; //返回新的对象
  } else if (Array.isArray(obj)) {
    // 数组同理
    let arr = [];
    obj.forEach((item, index) => {
      if (item && typeof item === 'object') arr[index] = deepClone(item);
      else arr[index] = item;
    });
    return arr;
  }
}

// 防抖
export function debounce(func, wait) {
  let timer;
  return function () {
    let args = arguments; // arguments中存着e
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

/**
 * 数组中对象去重
 * @param {*} arr 要去重的数组
 * @param {*} key 根据数组中对象的哪个key值去重
 * @returns
 */
export const reArrAngement = (arr, key) => {
  let obj = {};
  let res = arr.reduce((cur, next) => {
    obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next));
    return cur;
  }, []);
  return res;
};

export const OSSImgUrl = (url) => {
  if (url) {
    if (url.startsWith('https://') || url.startsWith('http://')) {
      return url;
    }
    return `${ossUrl}/${url}`;
  }
};

/**
 *
 * @returns 1:财务，2：管理层，3：员工或其他
 */
export const getRoleType = () => {
  // if(process.env.NODE_ENV == 'development') return 1
  let roleList =
    JSON.parse(localStorage.getItem('employeeDTO'))?.roleTypeList || [];
  if (roleList.includes(homeRole.FINANCE)) {
    return 1;
  }
  if (roleList.includes(homeRole.MANAGEMENT)) {
    return 2;
  }
  return 3;
};

/**
 *  下载导出excel
 * @param {*} url 下载地址
 * @param {*} filename 下载文件名称，默认会使用后端返回的
 * @param {*} method 下载请求类型
 * @param {*} params post请求的其他参数
 */
export const downloadXlsx = (
  url,
  downname,
  method = 'get',
  params,
  callback,
) => {
  function createObjectURL(object) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }
  let filename = `${downname}.xls`;
  var xhr = new XMLHttpRequest();
  var formData = new FormData();
  xhr.open(method, url);
  xhr.setRequestHeader('pcDeviceCode', localStorage.getItem('pcDeviceCode'));
  xhr.setRequestHeader('muId', localStorage.getItem('muId'));
  xhr.setRequestHeader('accessToken', localStorage.getItem('token'));
  if (method == 'post')
    xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    callback?.();
    if (this.status == 200) {
      let encodedFileName = this.getResponseHeader('Content-disposition');
      const decodedFileName =
        decodeURIComponent(
          encodedFileName?.replace("attachment;filename*=utf-8''", ''),
        ) || filename;

      var blob = this.response;
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, decodedFileName);
      } else {
        var a = document.createElement('a');
        var url = createObjectURL(blob);
        a.href = url;
        a.download = decodedFileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    }
  };
  if (method == 'post') xhr.send(JSON.stringify(params));
  else xhr.send(formData);
};

/**
 * 将对象参数转换成string类型的get请求
 * @param {Object} objParams 请求的get参数
 * @returns
 */
export const splitParamsFoGet = (objParams) => {
  const paramStr = Object.entries(objParams)
    .filter((item) => item?.[1] != undefined)
    .map((item) => `${item[0]}=${item[1]}`)
    .join('&');
  return `?${paramStr}`;
};

export const createFindTreeFn = (chidlrenKey, idKey) => {
  const findTree = (dataTree, id) => {
    for (let i = 0; i < dataTree.length; i++) {
      if (dataTree[i][idKey] == id) {
        return dataTree[i];
      }
      if (dataTree[i][chidlrenKey]) {
        return findTree(dataTree[i].child, id);
      }
    }
  };
  return findTree;
};

/**
 * 将数字转换成中文
 * @param {number} num 数字
 * @returns
 */
export const changeNumToHan = (num) => {
  var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  var arr2 = [
    '',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
  ];
  if (!num || isNaN(num)) return '零';
  var english = num.toString().split('');
  var result = '';
  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i; // 倒序排列设值
    result = arr2[i] + result;
    var arr1_index = english[des_i];
    result = arr1[arr1_index] + result;
  }
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十'); // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零+/g, '零'); // 合并中间多个零为一个零
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万'); // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/亿万/g, '亿'); // 将【亿万】换成【亿】
  result = result.replace(/零+$/, ''); // 移除末尾的零
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, '十');
  return result;
};

/**
 * 扁平化数组，兼容Array.prototype.flat，部分浏览器不支持
 * @param {Array} arr 需要扁平化的数组
 * @param {number} deep 扁平化深度
 * @returns
 */
export const flat = (arr, deep) => {
  if (deep > 0)
    return arr.reduce(
      (pre, cur) => pre.concat(Array.isArray(cur) ? flat(cur, deep - 1) : cur),
      [],
    );
  return arr.slice();
};

/**
 * 金额转换 / 100（分） 例如 10000分转换后是：100元
 * @param {number} num 转换之前的金额
 * @returns number
 */
export function toAmount(num) {
  if (!num) {
    return 0;
  }
  return +(num / 100).toFixed(2);
}

/**
 * 根据Url下载文件
 * @param {String} url
 * @param {String} fileName
 */
export function downloadFileUrl(url, fileName) {
  // const elink = document.createElement('a')
  // elink.href = url
  // elink.setAttribute('download', fileName)
  // elink.style.display = 'none'
  // document.body.appendChild(elink)
  // setTimeout(() => {
  //   elink.click()
  //   document.body.removeChild(elink)
  // }, 66)

  let link = document.createElement('a');
  // 这里是将url转成blob地址，
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      // 将链接地址字符内容转变成blob地址
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    });
}

/**
 * 二进文件流下载文件
 * @param {*} fileStream 二进制
 * @param {*} name 文件名
 * @param {*} extension 扩展
 * @param {*} type 下载类型
 */
export function downloadFileStream(fileStream, name, extension, type = '') {
  const blob = new Blob([fileStream], { type });

  const fileName = `${name}.${extension}`;

  if ('download' in document.createElement('a')) {
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href);
    document.body.removeChild(elink);
  } else {
    // 兼容IE10+下载

    navigator.msSaveBlob(blob, fileName);
  }
}

// //这个下载图片和文件都可以
// // fileRealPath 文件全路径
// // fileName 文件名带格式的
// let link = document.createElement('a');
// let url = data.fileUrl; //codeIMG  要下载的路径
// // 这里是将url转成blob地址，
// fetch(url)
//   .then((res) => res.blob())
//   .then((blob) => {
//     // 将链接地址字符内容转变成blob地址
//     link.href = window.URL.createObjectURL(blob);
//     link.download = data.projectName;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     window.URL.revokeObjectURL(link.href);
//   });
// //这个是下载图片
// //  解决跨域  imgsrc 文件全路径
// // name 文件名
// export function downloadIamge(imgsrc, name) {
//   //下载图片地址和图片名
//   var image = new Image();
//   // 解决跨域 Canvas 污染问题
//   image.setAttribute('crossOrigin', 'anonymous');
//   image.onload = function () {
//     var canvas = document.createElement('canvas');
//     canvas.width = image.width;
//     canvas.height = image.height;
//     var context = canvas.getContext('2d');
//     context.drawImage(image, 0, 0, image.width, image.height);
//     var url = canvas.toDataURL('image/png'); //得到图片的base64编码数据

//     var a = document.createElement('a'); // 生成一个a元素
//     var event = new MouseEvent('click'); // 创建一个单击事件
//     a.download = name || 'photo'; // 设置图片名称
//     a.href = url; // 将生成的URL设置为a.href属性
//     a.dispatchEvent(event); // 触发a的单击事件
//   };
//   image.src = imgsrc;
// }

/**
 * 将base64转换成图片文件
 * @param {string} base64 base64字符串
 */
export function base64ToImage(base64) {
  const link = document.createElement('a');
  const blob = base64ToBlob(base64);
  const fileName = `${Date.now()}.png`;
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

/**
 * 将base64转换成blob对象
 * @param {string} base64 base64字符串
 * @returns {Blob} blob对象
 */
export function base64ToBlob(base64) {
  const arr = base64.split(',');
  const type = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type });
}

/**
 * 获取oss上传秘钥
 * @param {Blob} file blob文件
 */
export const getExtraData = async (file, dirUrl) => {
  let name = file.name;
  const ossInfo = await getOSSMsg().then((res) => res.data);
  let fileUrl = `${ossRootDirectoryBase}${dirUrl}/${moment().format(
    'YYYY/MM/DD',
  )}`;
  const { policy, accessId, signature } = ossInfo;

  let resUrl = `${fileUrl}/${name}`;
  return {
    key: `${fileUrl}/${name}`,
    policy,
    OSSAccessKeyId: accessId,
    signature,
    resUrl,
  };
};

/**
 * 获取季度月份
 */
export function getMonthName(input) {
  // 定义每个月份的名称
  const monthNames = [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  // 解析输入字符串
  const [quarter, monthInQuarter] = input.split('-').map(Number);

  // 计算实际的月份
  const actualMonth = (quarter - 1) * 3 + monthInQuarter;

  // 检查计算出的月份是否有效
  if (actualMonth < 1 || actualMonth > 12) {
    throw new Error(
      'Invalid input. Expected format is "Quarter-MonthInQuarter".',
    );
  }

  // 返回对应的月份名称
  return monthNames[actualMonth - 1];
}
