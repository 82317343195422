import { useSelector, history } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { useEffect, useRef } from 'react';
import { useReactive } from 'ahooks';

const EditHoliday = ({ visible, onSave, onCancel, data }) => {
  const attendance = useSelector(({ attendance }) => attendance);

  const state = useReactive({
    type: '',
  });

  const getVacationOptions = (list) => {
    if (!Array.isArray(list)) return [];
    return list.map((item) => {
      return {
        value: item.id,
        label: item.value,
      };
    });
  };

  const modalRef = useRef();

  useEffect(() => {
    if (visible) {
      state.type = data?.vacationLastYear;
      modalRef.current?.form.setFieldsValue(data);
    }
  }, [visible]);

  // const vacationOptions =

  const formItemColumns = [
    {
      label: ' ',
      type: 'RadioGroup',
      props: {
        value: state.type,
        onChange: (e) => (state.type = e.target.value),
        options: [
          { label: data?.vacationLastYear, value: data?.vacationLastYear },
          { label: data?.vacationThisYear, value: data?.vacationThisYear },
        ],
      },
      formItemProps: {
        colon: false,
      },
    },
    state.type == data?.vacationLastYear && {
      label: '假期',
      name: 'vacationLastYearEnableDays',
      type: 'InputNumber',
      // required: '请输入假期',
      props: {
        placeholder: '请输入假期',
        stepType: 'inside',
      },
    },
    state.type == data?.vacationThisYear && {
      label: '假期',
      name: 'vacationThisYearEnableDays',
      type: 'InputNumber',
      // required: '请输入假期',
      props: {
        placeholder: '请输入假期',
        stepType: 'inside',
      },
    },

    {
      label: '上半天数量',
      name: 'newVacationRemainEnableUpDays',
      type: 'InputNumber',
      required: '请输入数量',
      props: {
        placeholder: '请输入数量',
        stepType: 'inside',
      },
    },

    {
      label: '下半天数量',
      name: 'newVacationRemainEnableDownDays',
      type: 'InputNumber',
      required: '请输入数量',
      props: {
        placeholder: '请输入数量',
        stepType: 'inside',
      },
    },
  ].filter(Boolean);

  const handleSave = (value) => {
    console.log('handleSave...value', value);
    console.log('handleSave...data', data);
    let keyObj = {
      [data.vacationLastYear]: 'vacationLastYearEnableDays',
      [data.vacationThisYear]: 'vacationThisYearEnableDays',
    };
    let payload = {
      vacationId: data?.vacationId,
      vacationBelongYear: state.type,
      empId: history.location.query.empId,

      // oldVacationRemainEnableDays: data[keyObj[state.type]],
      // newVacationRemainEnableDays: value[keyObj[state.type]],

      // //新的假期剩余可用上半天数量
      newVacationRemainEnableUpDays: value.newVacationRemainEnableUpDays,
      // //新的假期剩余可用下半天数量
      newVacationRemainEnableDownDays: value.newVacationRemainEnableDownDays,

      //旧的的假期剩余可用上半天数量
      oldVacationRemainEnableUpDays: data.vacationRemainEnableUpDays,
      // oldVacationRemainEnableUpDays:2,
      //旧的假期剩余可用下半天数量
      oldVacationRemainEnableDownDays: data.vacationRemainEnableDownDays,
      // oldVacationRemainEnableDownDays:5,

      oldVacationRemainEnableDays: data[keyObj[state.type]],
      newVacationRemainEnableDays:
        value.newVacationRemainEnableUpDays * 0.4 +
        value.newVacationRemainEnableDownDays * 0.6,
    };
    console.log('dasdas', payload);
    // if (
    //   payload.oldVacationRemainEnableDays == payload.newVacationRemainEnableDays
    // ) {
    //   return onCancel();
    // }
    // console.log(payload, data);
    onSave?.(payload);
  };

  return (
    <ModalFormPro
      title="修改假期"
      visible={visible}
      ref={modalRef}
      edtaFormItem={formItemColumns}
      onSave={handleSave}
      onCancel={onCancel}
    />
  );
};

export default EditHoliday;
