import { Tag, Row, Col, Collapse, Dropdown, Button } from 'kenshin';
import { PureComponent } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { deepClone } from '@/_util/util';
import '../CollapseHoc/Hoc.less';

class itemDetail extends PureComponent {
  headerRender(headerEl) {
    const handleEditClick = () => {
      this.props.editItem?.(this.props.data);
    };

    const deleteItem = () => {
      this.props.setDataList((list) => {
        let i = list.findIndex(
          (item) =>
            (item.key || item.custContId) ==
            (this.props.data.key || this.props.data.custContId),
        );
        list.splice(i, 1);
        this.props.onChange?.(list);
        return [...list];
      });
    };

    const copyItem = () => {
      this.props.setDataList((list) => {
        // list.splice(i, 1);
        let newData = deepClone(this.props.data);
        newData.key = +new Date();
        newData.custContId = undefined;
        newData.time = moment().format('YYYY-MM-DD HH:mm:ss');
        list.push(newData);
        // this.props.onChange?.(list);
        return [...list];
      });
    };

    const menu = (
      <Dropdown.Menu>
        <Dropdown.Item>
          <div onClick={deleteItem}>删除</div>
        </Dropdown.Item>
        <Dropdown.Item>
          <div onClick={copyItem}>复制</div>
        </Dropdown.Item>
        {/* <Dropdown.Item><div onClick={addItem}>添加</div></Dropdown.Item> */}
      </Dropdown.Menu>
    );
    return (
      <div style={{ display: 'inline-block', flex: 1, width: '90%' }}>
        {headerEl}
        <div className="header-options" onClick={(e) => e.stopPropagation()}>
          {!this.props.disabled && (
            <>
              {this.props.data?.servCatgStatus != 1 && (
                <Button
                  type="text"
                  className="edit-btn"
                  onClick={handleEditClick}
                >
                  编辑
                </Button>
              )}
              <Dropdown menu={menu} trigger="click">
                <Button type="text" className="more-btn">
                  更多
                  <DownOutlined />
                </Button>
              </Dropdown>
            </>
          )}
          {this.props.headerExtra?.(this.props.data)}
        </div>
      </div>
    );
  }

  render() {
    const { data, custTagList, headerEl } = this.props;
    return (
      <>
        <Collapse className="hoc-edit-card">
          <Collapse.Panel header={this.headerRender(headerEl)} name="detail">
            <div className="kenshin-form kenshin-form-horizontal">
              <Row className="detail-box">
                <Col span={6}>
                  姓名：
                  <span className="c999">{data.contName}</span>
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  职位：
                  <span className="c999">{data.contJob}</span>
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  直属上司：
                  <span className="c999">{data.contImedtSuper}</span>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col span={6}>
                  录入时间：
                  <span className="c999">{data.time}</span>
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  人物标签：
                  {(Array.isArray(data.tagIdList)
                    ? data.tagIdList
                    : data.tagIdList?.split(',')
                  )?.map((item, i) => (
                    <Tag style={{ marginRight: 8 }} key={i}>
                      {custTagList?.find((row) => row.value == item)?.label}
                    </Tag>
                  ))}
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                  联系方式：
                  <span className="c999">{data.contMobile}</span>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col span={20}>
                  功能详情描述：<span className="c999">{data.contRemark}</span>
                </Col>
              </Row>
            </div>
          </Collapse.Panel>
        </Collapse>
      </>
    );
  }
}

export default itemDetail;
