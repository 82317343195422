import request from '@/_util/require';

let version = 'v1';

// 项目管理-------------------
// 列表
export async function getProjectList(options) {
  return request(`/${version}/front/midstage/project/projectlistpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addProject(options) {
  return request(`/${version}/front/midstage/project/addproject`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editProject(options) {
  return request(`/${version}/front/midstage/project/updateproject`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteProject(params) {
  return request(`/${version}/front/midstage/project/deleteproject`, {
    method: 'GET',
    params,
  });
}

// 查询
export async function projectDetail(params) {
  return request(`/${version}/front/midstage/project/getproject`, {
    method: 'GET',
    params,
  });
}
// 查询
export async function getProjectAll(params) {
  return request(`/${version}/front/midstage/project/getallproject`, {
    method: 'GET',
    params,
  });
}

// 项目管理-------------------end

// 页面管理-------------------
// 列表
export async function getProjectpageList(options) {
  return request(`/${version}/front/midstage/projectpage/projectpagelistpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addProjectpage(options) {
  return request(`/${version}/front/midstage/projectpage/addprojectpage`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editProjectpage(options) {
  return request(`/${version}/front/midstage/projectpage/updateprojectpage`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteProjectpage(params) {
  return request(`/${version}/front/midstage/projectpage/deleteprojectpage`, {
    method: 'GET',
    params,
  });
}

// 查询
export async function projectpageDetail(params) {
  return request(`/${version}/front/midstage/projectpage/getprojectpage`, {
    method: 'GET',
    params,
  });
}

// 页面管理-------------------end

// 模板分类管理-------------------
// 列表
export async function getTemptypeList(options) {
  return request(`/${version}/front/midstage/temptype/temptypelistpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addTemptype(options) {
  return request(`/${version}/front/midstage/temptype/addtemptype`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editTemptype(options) {
  return request(`/${version}/front/midstage/temptype/updatetemptype`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteTemptype(params) {
  return request(`/${version}/front/midstage/temptype/deletetemptype`, {
    method: 'GET',
    params,
  });
}

// 查询
export async function temptypeDetail(params) {
  return request(`/${version}/front/midstage/temptype/gettemptype`, {
    method: 'GET',
    params,
  });
}
// 查询
export async function temptypeAll(params) {
  return request(`/${version}/front/midstage/temptype/simptemptypelist`, {
    method: 'GET',
    params,
  });
}

// 模板管理-------------------end

// 模板管理-------------------
// 列表
export async function getTemplateList(options) {
  return request(`/${version}/front/midstage/template/templatelistpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addTemplate(options) {
  return request(`/${version}/front/midstage/template/addtemplate`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editTemplate(options) {
  return request(`/${version}/front/midstage/template/updatetemplate`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteTemplate(params) {
  return request(`/${version}/front/midstage/template/deletetemplate`, {
    method: 'GET',
    params,
  });
}

// 查询
export async function templateDetail(params) {
  return request(`/${version}/front/midstage/template/gettemplate`, {
    method: 'GET',
    params,
  });
}
// 查询
export async function getTemplateAll(params) {
  return request(`/${version}/front/midstage/template/templistbytemptypeid`, {
    method: 'GET',
    params,
  });
}

// 模板管理-------------------end
