import { PureComponent } from 'react';
import { Dropdown, Collapse, Button, Menu } from 'kenshin';
import { DownOutlined } from '@ant-design/icons';
import { deepClone } from '@/_util/util';
import './Hoc.less';

const CollapseHoc = (WarppedComponent) =>
  class extends PureComponent {
    async delItem(data) {
      let shouldDel = (await this.props.beforeDel?.(data)) ?? true;
      if (!shouldDel) {
        return;
      }
      this.props.setDataList((list) => {
        let i = list.indexOf(data);
        list.splice(i, 1);
        return [...list];
      });
      this.props.deleteItem?.(data);
    }

    headerRender(headerEl) {
      const copyItem = () => {
        this.props.setDataList((list) => {
          let data = {
            ...this.props.data,
            proServCatgId: undefined,
            servCatgCode: list.length + 1,
          };
          data.key = +new Date();
          this.props.copyItem?.([...list, data], data);
          return [...list, data];
        });
      };
      const addItem = () => {
        let data = this.props.data;
        this.props.addItem?.(data);
      };

      const handleEditClick = () => {
        this.props.editItem?.(this.props.data);
      };

      const menu = (
        <Dropdown.Menu>
          <Dropdown.Item>
            <div onClick={() => this.delItem(this.props.data)}>删除</div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div onClick={copyItem}>复制</div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div onClick={addItem}>添加</div>
          </Dropdown.Item>
          {/* <Dropdown.Item><div onClick={addItem}>添加</div></Dropdown.Item> */}
        </Dropdown.Menu>
      );
      return (
        <div style={{ display: 'inline-block', flex: 1, width: '90%' }}>
          {headerEl}
          <div className="header-options" onClick={(e) => e.stopPropagation()}>
            {!this.props.disabled && (
              <>
                <Button
                  type="text"
                  className="edit-btn"
                  onClick={handleEditClick}
                >
                  编辑
                </Button>
                <Dropdown menu={menu} trigger="click">
                  <Button type="text" className="more-btn">
                    更多
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            )}
            {this.props.headerExtra?.(this.props.data)}
          </div>
        </div>
      );
    }

    render() {
      const { headerEl, ...resProps } = this.props;
      return (
        <Collapse className="hoc-edit-card">
          <Collapse.Panel header={this.headerRender(headerEl)} name="detail">
            <div className="kenshin-form kenshin-form-horizontal">
              <WarppedComponent {...resProps} HOCdelete={this.delItem} />
            </div>
          </Collapse.Panel>
        </Collapse>
      );
    }
  };

export default CollapseHoc;
