import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
} from 'kenshin';
import { connect, history } from 'umi';
import TablePro from '@/components/TablePro';
import { OSATYPEKEY } from '../../assessment/_keys';
import renderProcessList from '../ProcessList';
import PreviewIndicators from '../PreviewIndicators';
import CollapseSearch from '../CollapseSearch';

const OSAWEEKOSATYPE = {
  WEEK: 1,
  OSA: 3,
};

const dispatchtype = 'performance/getosamypage';
const Template = ({
  dispatch,
  performance,
  visible,
  onCancel,
  onSave,
  disabledId,
  osaWeekType,
  dispatchApi = dispatchtype,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);

  const tableRef = useRef();
  useEffect(() => {
    dispatch({
      type: 'performance/getmyosastatdata',
    });
  }, []);

  // useEffect(() => {
  //   console.log("osaselfData...", performance.osaselfData);
  // }, [performance.osaselfData]);

  const getProcessList = (osaId, visible) => {
    if (visible) {
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId,
          todoBusiId: '',
        },
      });
    }
  };

  const isOsaHistoryType = dispatchApi == dispatchtype; //是否为默认的osa历史

  const columns = !isOsaHistoryType
    ? [
        {
          dataIndex: 'osaId',
          title: '被考核人',
          width: 120,
          fixed: 'left',
          render(val) {
            return JSON.parse(localStorage.getItem('employeeDTO'))?.empName;
          },
        },
        {
          dataIndex: 'osaBeginTime',
          title: '考核周期',
          width: 120,
          fixed: 'left',
          render(osaBeginTime, row) {
            // console.log('考核周期...', row, osaBeginTime);
            let begin = moment(osaBeginTime).format('YYYY-MM-DD');
            let end = moment(row.osaEndTime).format('YYYY-MM-DD');
            row;
            return `${begin} - ${end}`;
          },
        },
      ]
    : [
        {
          dataIndex: 'userInfo',
          title: '被考核人',
          width: 120,
          fixed: 'left',
          render(userInfo) {
            return userInfo?.value;
          },
        },
        // {
        //   dataIndex: 'osaWeekType',
        //   title: '信息类别',
        //   width: 120,
        //   fixed: 'left',
        //   render(value) {
        //     const key = {
        //       1: '周计划',
        //       3: 'OSA季度绩效',
        //     };
        //     return key[value];
        //   },
        // },
        {
          dataIndex: 'osaName',
          title: '绩效名称',
          width: 150,
        },
        {
          key: 'osaTime',
          title: '考核周期',
          width: 220,
          render(row) {
            const YMD = (time) => moment(time).format('YYYY-MM-DD');
            return [YMD(row.osaBeginTime), YMD(row.osaEndTime)].join(' ~ ');
          },
        },
        {
          dataIndex: 'busiLeader',
          title: '业务负责人',
          width: 120,
          render(busiLeader) {
            return busiLeader?.value;
          },
        },
        {
          dataIndex: 'directLeader',
          title: '主管(考核人)',
          width: 120,
          render(directLeader) {
            return directLeader?.value;
          },
        },
        {
          dataIndex: 'osaType',
          title: '考核类型',
          width: 120,
          render(val) {
            const key = {
              1: '试用期转正评估',
              2: '绩效评估',
              3: '绩效改进评估',
              4: '个人发展计划',
            };
            return key[val];
          },
        },
        {
          key: 'osaSumRemark',
          title: '评分',
          width: 180,
          render: (row) => {
            if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
              return (
                <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>
              );
            }
            return [
              <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
              <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
              <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
            ];
          },
        },
        {
          dataIndex: 'osaGrade',
          title: '评级',
          width: 120,
          render: (val) => val ?? '-',
        },
        {
          dataIndex: 'curnNode',
          title: '当前环节',
          width: 180,
          render(val, row) {
            return (
              <Tooltip
                title={renderProcessList(row.osaId, performance)}
                onVisibleChange={(visible) =>
                  getProcessList(row.osaId, visible)
                }
                placement="left"
                trigger="click"
                overlayStyle={{
                  color: '#333',
                  maxWidth: 600,
                  fontSize: 12,
                  lineHeight: '24px',
                }}
                color="#fff"
              >
                <Button type="text">{val}</Button>
              </Tooltip>
            );
          },
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          key: 'options',
          render: (row) => {
            return (
              <>
                <PreviewIndicators row={row} />
              </>
            );
          },
        },
      ];

  const handleOk = () => {
    if (selectedRowKeys == null || selectedRowKeys == undefined) {
      message.error('请选择绩效');
      return;
    }
    onSave?.(isOsaHistoryType ? selectedRowKeys : selectedRows);
  };

  const rowSelection = {
    selectedRowKeys: [selectedRowKeys],
    selectedRows: [selectedRows],
    onChange: (keys, rows) => {
      setSelectedRowKeys(keys[keys.length - 1]);
      setSelectedRows(rows[rows.length - 1]);
      console.log('selectedRows...', rows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.osaId === disabledId, // Column configuration not to be checked
    }),
  };
  return (
    <MessageBox
      width="95%"
      title="历史绩效"
      destroyOnClose
      style={{ top: 20 }}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          确定
        </Button>,
      ]}
    >
      <CollapseSearch
        onChange={tableRef.current?.reload}
        showType={
          osaWeekType == OSAWEEKOSATYPE.OSA ? ['osaType', 'time'] : ['time']
        }
      />
      <TablePro
        params={{ osaWeekType }}
        request={dispatchApi}
        rowKey={'osaId'}
        columns={columns}
        curColumns
        scrollX
        data={performance.osaselfData}
        actionRef={(ref) => (tableRef.current = ref)}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </MessageBox>
  );
};

export default connect(({ employee_salary, performance }) => {
  return { employee_salary, performance };
})(Template);
