import {
  Steps,
  Avatar,
  Row,
  Col,
  Tabs,
  Tooltip,
  Tag,
  Button,
  message,
  Loading,
} from 'kenshin';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect, history } from 'umi';
import InfoDetail from './components/InfoDetail';
// import ServCategoryList from './components/ServCategoryList';
import { findTree } from '@/components/ServCategoryList';
import ServCategoryList from '@/components/ServCategoryList';
import ModalFormPro from '@/components/ModalFormPro';
import { moundSetData, servCatgOnceOption } from '../detailDataTransform';
import './index.less';
import { OSSImgUrl } from '../../../../_util/util';
import RptInfo from './components/RptInfo';
import WorkDetailModal from './components/WorkDetailModal';
import CloseItem from './components/CloseItem';
import EmpWorkHourList from '../empWorkHourList';
import ServCatgStat from './components/ServCatgStat';
import TaskList from './components/TaskList';
import { EditOutlined } from '@ant-design/icons';
import { getContractMount } from '@/_serveice/contract/list/index';
import { getEditCloumns, getServCateProps } from '../cloumnsData';
import { processapplyvalide } from '@/_serveice/admin/process';
import CloseDetailModal from './components/CloseDetailModal';

const projDetail = ({ dispatch, project, systemOrgani, contract, loading }) => {
  const { projectDetail: data = {} } = project;

  const [tabsKeys, setTabsKeys] = useState('1');
  const [servCatgList, setServCatgList] = useState([]);

  // 弹窗操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const [workHourVisible, setWorkHourVisible] = useState(false);
  const [workHourData, setWorkHourData] = useState(false);

  // 合同剩余金额
  const [contractMoney, setContractMoney] = useState(0);
  const [editVisible, setEditVisible] = useState(false);

  // 是否有存在流程申请
  const [btnDisabled, setBtnDisabled] = useState(false);

  /** 项目数据源 */
  const rowData = useRef();
  /** 工作包金额是否变动 */
  const proServCatgDiscUnitPirceChange = useRef(false);

  const loadData = () => {
    let projectId = history.location.query?.projectId;
    dispatch({
      type: 'project/getProjectDetail',
      payload: {
        projectId,
      },
    }).then((res) => {
      if (res) {
        document.title = `微数平台-${res.projectName}`;
        dispatch({
          type: 'systemOrgani/getAllEmployee',
        }).then((employeeAll) => {
          if (employeeAll) {
            let newList = moundSetData(res.dbServCatgList, {
              systemOrgani,
              findTree,
              employeeAll,
            });
            console.log('getProjectDetail11...', newList);
            setServCatgList([...(newList || [])]);
          }
        });
      }
    });
  };

  useEffect(() => {
    // 合同
    dispatch({
      type: 'contract/getEasyContractList',
    });
    // 工作包清单
    dispatch({
      // type: 'project/getServcatgEasyList',
      type: 'project/getServcatgList',
    });
    // 获取详情
    let projectId = history.location.query?.projectId;
    if (projectId) {
      dispatch({
        type: 'project/getAuditProgress',
        payload: {
          projectId,
        },
      });
      processapplyvalide({ applyBusiId: projectId, applyBusiType: 3 }).then(
        (res) => {
          if (!res.data) {
            setBtnDisabled(true);
            // message.warn('当前项目存在未完成的流程变更!');
          }
        },
      );
      loadData();
    }
  }, []);
  const statusObj = {
    0: '未开始',
    1: '正常',
    2: '停止',
    3: '删除',
    4: '项目结束',
    5: '终止',
  };

  const tabsChange = (val) => {
    setTabsKeys(val);
  };

  const projectProgressTag = {
    0: <Tag type="danger">进度滞后</Tag>,
    1: <Tag type="primary">进度正常</Tag>,
    2: <Tag type="success">进度超前</Tag>,
  };

  // 编辑JSON
  const editCloumns = useMemo(
    () =>
      getEditCloumns({
        contractMoney,
        contractChange,
        easyContractList: contract.easyContractList,
        systemOrgani,
        rowData: rowData.current,
      }),
    [rowData.current, contract.easyContractList, contractMoney],
  );

  // 弹窗formItem
  const edtaFormItem = [
    // {
    //   label: ' ',
    //   name: 'proServCatgIdList',
    //   type: 'Select',
    //   // required: '请输入服务类目名称',
    //   formItemProps: {
    //     colon: false,
    //   },
    //   props: {
    //     placeholder: '请选择工作包',
    //     mode: 'multiple',
    //     allowClear: true,
    //     options: servCatgList
    //       .filter((item) => item.servCatgStatus == 0)
    //       .map((item) => ({
    //         label: item.servCatgName,
    //         value: item.proServCatgId,
    //       })),
    //   },
    // },
    {
      label: '关闭日期',
      name: 'closeDate',
      type: 'DatePicker',
      // required: '请输入服务类目名称',
      props: {
        placeholder: '关闭日期',
      },
    },
    // {
    //   label: ' ',
    //   name: 'closeWorkPackageList',
    //   type: 'Select',
    //   // required: '请输入服务类目名称',
    //   formItemProps: {
    //     colon: false,
    //   },
    //   render: () => <CloseItem selectOptions={servCatgList} />,
    // },
    {
      label: '关闭数量',
      name: 'closeNum',
      type: 'InputNumber',
      required: '请输入关闭数量',
      extra: `剩余关闭数量：${
        editModalData?.servCatgCount - editModalData?.servCatgFinishNum
      }`,
      props: {
        placeholder: '请输入关闭数量',
        stepType: 'inside',
        max:
          editModalData?.servCatgCount - editModalData?.servCatgFinishNum ||
          undefined,
        min: 0,
      },
    },
  ];
  const [workDetailVisible, setWorkDetailVisible] = useState(false);
  const [workDetailData, setWorkDetailData] = useState(null);

  const [workCloseDetailVisible, setWorkCloseDetailVisible] = useState(false);
  const [workCloseDetailData, setWorkCloseDetailData] = useState(null);

  /** 打开员工上报弹窗 */
  const showWordHour = (row) => {
    // type == 1 项目
    // type == 2 工作包
    setWorkHourData({
      id: row.proServCatgId,
      type: 2,
    });
    setWorkHourVisible(true);
  };

  const editCompoment = (row) => (
    <Row justify="end">
      {row.servCatgStatus != 1 && (
        <Button
          shape="round"
          type="primary"
          onClick={() => {
            // console.log(row)
            setEditModalVisible(true);
            setEditModalData(row);
          }}
        >
          关闭
        </Button>
      )}
      <Button
        shape="round"
        type="primary"
        onClick={() => {
          setWorkCloseDetailVisible(true);
          setWorkCloseDetailData(row);
        }}
      >
        查看工作包关闭记录
      </Button>
      <Button
        shape="round"
        type="primary"
        onClick={() => {
          setWorkDetailVisible(true);
          setWorkDetailData(row);
        }}
      >
        查看工作包上报情况
      </Button>
      <Button
        shape="round"
        type="primary"
        onClick={() => {
          showWordHour(row);
        }}
      >
        员工上报
      </Button>
    </Row>
  );

  const getServCateList = (projectId) => {
    dispatch({
      type: 'project/getProservcatgList',
      payload: {
        projectId,
      },
    }).then((res) => {
      // const { form } = servcateRef.current;
      let dbServCatgList = moundSetData(res || [], {
        employeeAll: systemOrgani.employeeAll,
        findTree,
      });
      // detailData.current = dbServCatgList;
      console.log('getProjectDetail22...', dbServCatgList);
      setServCatgList([...dbServCatgList]);
      // form.resetFields();
      // form.setFieldsValue({ dbServCatgList: [...dbServCatgList] });
    });
  };

  const servCateProps = getServCateProps({
    servCatgOnceOption,
    proServCatgDiscUnitPirceChange,
    dispatch,
    getServCateList,
    rowData: { current: data },
  });

  // 关闭工作包保存
  const editModalSave = (val) => {
    if (editModalData?.projectId) {
      let payload = {
        muIdString: localStorage.getItem('muId'),
        projectId: editModalData?.projectId,
        closeDate: moment(val.closeDate).format('YYYY-MM-DD'),
        closeWorkPackageList: [
          {
            closeNum: val.closeNum,
            proServCatgId: editModalData.proServCatgId,
          },
        ],
      };
      //   if (payload.closeDate) {
      //     payload.closeDate = moment(payload.closeDate).format('YYYY-MM-DD');
      //   }
      dispatch({
        type: 'project/delPackageManger',
        payload,
      }).then((res) => {
        if (res) {
          if (res) {
            message.success('操作成功！');
            loadData();
            getServCateList(editModalData?.projectId);
            setEditModalData(null);
            setEditModalVisible(false);
          }
        }
      });
    }
  };

  const detailedList = useMemo(() => {
    return (
      <div style={{ marginBottom: 100 }}>
        {btnDisabled && (
          <div style={{ color: 'red' }}>当前项目存在未完成的流程变更</div>
        )}
        <Loading loading={loading['project/getProservcatgList']} key="loading">
          <ServCategoryList
            {...servCateProps}
            disabled={btnDisabled}
            extra={editCompoment}
            value={servCatgList}
          />
        </Loading>
      </div>
    );
    // return (
    //   <ServCategoryList
    //     value={servCatgList}
    //     key={servCatgList}
    //     disabled
    //     extra={editCompoment}
    //     headerExtra={(data) =>
    //       data.servCatgStatus == 1 ? (
    //         <Tag.MiniTag>关闭</Tag.MiniTag>
    //       ) : (
    //         // <Tag.MiniTag>未完成</Tag.MiniTag>
    //         <Tag.MiniTag
    //           type="success"
    //           onClick={() => {
    //             setEditModalVisible(true);
    //             setEditModalData(data);
    //           }}
    //         >
    //           关闭
    //         </Tag.MiniTag>
    //         // <Button
    //         //   className="flr"
    //         //   type="primary"
    //         //   size="mini"
    //         //   onClick={() => {
    //         //     setEditModalVisible(true);
    //         //     setEditModalData(data);
    //         //   }}
    //         // >
    //         //   关闭
    //         // </Button>
    //       )
    //     }
    //   />
    // );
  }, [servCatgList, loading['project/getProservcatgList']]);

  /** 编辑点击完成 */
  const editSave = (data) => {
    const { dbServCatgList, projectTime, ...values } = data;
    // if (rowData.current && projectTime && rowData.current?.projectStatus != 0) {
    //   if (!(+projectTime[1] > moment() && +projectTime[0] < moment())) {
    //     message.error('进行中的项目时间必须包含当前日期！');
    //     return;
    //   }
    // }
    if (projectTime) {
      // 某些状态下修改日期是有限制的

      values.projectBeginTime = moment(projectTime[0])
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
      values.projectEndTime = moment(projectTime[1])
        .endOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
    }

    if (values.contractId) {
      let contractNumberArr = values.contractId.split('-');
      contractNumberArr.shift();
      values.contractNumber = contractNumberArr.join('-');
      values.contractId = values.contractId.split('-')[0];
    }

    if (rowData.current?.projectId) {
      values.projectId = rowData.current?.projectId;
    }
    // if (contractMoney < values.projectAmount) {
    //   message.warn('项目金额不能超过合同剩余金额');
    //   return;
    // }
    values.projectAmount &&
      (values.projectAmount = +(values.projectAmount * 100).toFixed(2));
    dispatch({
      type: 'project/editProject',
      payload: values,
    }).then((res) => {
      if (res) {
        // handleCancel();
        message.success('保存成功');
        setEditVisible(false);
        rowData.current = null;
        getServCateList(rowData.current?.projectId);
        loadData();
      }
    });
  };

  // 根据合同编号查询合同金额
  const contractChange = (key) => {
    if (key) {
      let contractId = key.split('-')?.[0];
      getContractMount({ contractId }).then((res) => {
        if (res?.success) {
          setContractMoney(res.data);
        }
      });
    }
  };

  /** 控制弹窗请求，打开弹窗，防止多次点击 */
  let hasShowEdit = false;
  /** 打开编辑框 */
  const showEditModal = ({ projectId, proServCatgDiscTotalAmount }) => {
    if (!hasShowEdit) {
      hasShowEdit = true;
      dispatch({
        type: 'project/getProjectDetail',
        payload: {
          projectId,
        },
      }).then((res) => {
        if (res) {
          res.projectTime = [
            moment(res.projectBeginTime),
            moment(res.projectEndTime),
          ];
          res.contractId = `${res.contractId}-${res.contractNumber}`;
          contractChange(res.contractId);
          res.projectAmount = +(res.projectAmount / 100).toFixed(2);
          res.proServCatgDiscTotalAmount = +(
            (proServCatgDiscTotalAmount || 0) / 100
          ).toFixed(2);
          rowData.current = res;
          setEditVisible(true);
          hasShowEdit = false;
        }
      });
    }
  };

  return (
    <div className="projDetail">
      <div className="title-box">
        <div className="info-data">
          <Avatar
            shape="square"
            size={130}
            style={{ backgroundColor: '#3d6cb7' }}
            src={OSSImgUrl(data?.projectCustomerLogo)}
          >
            {data?.projectName?.[0]}
          </Avatar>
          <div className="text-box">
            <div className="title">
              {data?.projectName}
              &emsp;
              {projectProgressTag[data?.projectProgress]}
            </div>
            <Row>
              <Col flex="150px">
                <span className="tip-text">负责人：</span>
                {data?.projectManagerName}
              </Col>
              <Col flex="200px">
                <span className="tip-text">创建时间：</span>
                {moment(data?.projectBeginTime).format('YYYY-MM-DD')}
              </Col>
              <Col flex="auto">
                <span className="tip-text">合同编码：</span>
                {data?.contractNumber}
              </Col>
            </Row>
            <Row>
              <span className="tip-text">当前状态：</span>
              {statusObj[data?.projectStatus]}
            </Row>
            <Row>
              <Tooltip title="项目信息编辑">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => showEditModal(data)}
                >
                  <EditOutlined />
                </span>
              </Tooltip>
            </Row>
          </div>
        </div>
        <Tabs onChange={tabsChange} activeKey={tabsKeys}>
          <Tabs.TabPane tab="基础信息" key={1}></Tabs.TabPane>
          <Tabs.TabPane tab="项目工作包清单" key={2}></Tabs.TabPane>
          <Tabs.TabPane tab="项目上报明细" key={3}></Tabs.TabPane>
          <Tabs.TabPane tab="工作包统计" key={4}></Tabs.TabPane>
          <Tabs.TabPane tab="任务管理" key={5}></Tabs.TabPane>
        </Tabs>
      </div>

      <div className="main-box">
        {tabsKeys == 1 && (
          <InfoDetail
            data={data}
            list={project.projectLogList}
            process={project.auditProgress}
          />
        )}
        {tabsKeys == 2 && (
          <div className="servc-list">
            {detailedList}
            {/* <Button
              className="flr"
              style={{ marginTop: 10 }}
              type="primary"
              onClick={() => {
                setEditModalVisible(true);
                setEditModalData(project.projectDetail);
              }}
            >
              工作包标记已完成
            </Button> */}
          </div>
        )}
        {tabsKeys == 3 && <RptInfo />}
        {tabsKeys == 4 && <ServCatgStat />}
        {tabsKeys == 5 && <TaskList />}
      </div>
      <ModalFormPro
        title={`关闭${editModalData?.servCatgName || '工作包'}`}
        width={700}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
      <WorkDetailModal
        visible={workDetailVisible}
        data={workDetailData}
        onCancel={() => {
          setWorkDetailData(null);
          setWorkDetailVisible(false);
        }}
      />

      <CloseDetailModal
        visible={workCloseDetailVisible}
        data={workCloseDetailData}
        dispatch={dispatch}
        onCancel={() => {
          setWorkCloseDetailData(null);
          setWorkCloseDetailVisible(false);
        }}
      />

      <EmpWorkHourList
        visible={workHourVisible}
        data={workHourData}
        onCancel={() => {
          setWorkHourVisible(false);
          setWorkHourData(null);
        }}
      />
      <ModalFormPro
        title="编辑"
        visible={editVisible}
        edtaFormItem={editCloumns}
        onSave={editSave}
        formData={rowData.current}
        onCancel={() => {
          setEditVisible(false);
          rowData.current = null;
        }}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani, contract, loading }) => ({
  project,
  contract,
  systemOrgani,
  loading: loading.effects,
}))(projDetail);
