import {
  etlDelete,
  etlAddModify,
  etlGetSNPage,
} from '@/_serveice/etl/index.js';

export default {
  namespace: 'etlmodel',

  state: {
    // 数据
    etlDataList: {},
    searchForm: {},
    etlData: {},
  },

  effects: {
    *etlAddModify({ payload }, { put, call }) {
      const res = yield call(etlAddModify, payload);
      console.log(res);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            etlData: res.data,
          },
        });
        yield put({
          type: 'etlGetSNPage',
        });
        return res;
      }
    },

    *etlGetSNPage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(({ system }) => system.searchForm);
      let searchForm = { ...searchData, ...payload };
      if (reset) {
        searchForm = payload;
      }
      const res = yield call(etlGetSNPage, searchForm);
      if (res?.success) {
        console.log('res=', res);
        yield put({
          type: 'save',
          payload: {
            etlDataList: res.data,
            searchForm,
          },
        });
      }
    },

    *etlDelete({ payload }, { put, call }) {
      const res = yield call(etlDelete, payload);
      yield put({
        type: 'etlGetSNPage',
      });
      return true;
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
