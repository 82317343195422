import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Row, Col, Tooltip } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { menberListOptions } from '../../../detailDataTransform';
import { findTree } from '@/components/ServCategoryList';
import { exportTaskList } from '@/_serveice/project';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import {
  getCloumns,
  statusFormItem,
  getEdtaFormItem,
  getSearchCloumns,
} from '../../../../taskManage/cloumnsData';

const getTreeData = (list) => {
  return list.map((item) => {
    let children;
    if (item.child) {
      children = getTreeData(item.child);
    }
    return {
      title: item.deptName,
      value: item.deptId,
      children,
    };
  });
};

const getServcatgTreeData = (list) => {
  if (list == null || list.length == 0) {
    return {};
  }

  return list.map((item) => {
    let children;
    if (item.childList) {
      children = getServcatgTreeData(item.childList);
    }
    return {
      title: item.servCatgName,
      value: item.proServCatgId,
      children,
    };
  });
};

const toOptions = (arr = []) => {
  arr ||= [];
  return arr.map((item) => ({
    value: item.proServCatgId,
    title: item.servCatgName,
    children: toOptions(item.childList),
    data: item,
  }));
};

const RptInfo = ({ project, systemOrgani, dispatch }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [employeeAll, setEmployeeAll] = useState([]);
  const [leaderKey, setLeaderKey] = useState('');

  const [selectKey, setSelectKey] = useState([]);
  const [servCatgList, setServCatgList] = useState({});
  const [servCatgTree, setServCatgTree] = useState({});

  /** 修改状态 */
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  /** 存储当前服务类目对应的数据 */
  const curData = useRef({ projectId: history.location.query?.projectId });

  useEffect(() => {
    // dispatch({
    //   type: 'project/getSimpProjectList',
    // });
    // 组织架构
    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId: history.location.query?.projectId,
      },
    }).then((data) => {
      setServCatgTree(getServcatgTreeData(data));
    });
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setEmployeeAll(res);
    });
    // 服务类目
    // dispatch({
    //   type: 'project/getServcatgEasyList',
    // });
    return () => {
      dispatch({
        type: 'project/save',
        payload: {
          taskList: {},
          taskListSearchForm: {},
        },
      });
    };
  }, []);

  /** 根据项目id获取服务类目 */
  const getServcatgForProjectId = (projectId) => {
    if (servCatgList[projectId]) {
      return servCatgList[projectId];
    }
    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId,
      },
    }).then((data) => {
      setServCatgList((list) => {
        list[projectId] = toOptions(data);
        return { ...list };
      });
    });
  };

  /** 一些从服务类目树带过来的默认数据 */
  const editShowData = (row) => {
    const {
      servCatgName,
      servCatgLeader,
      servCatgBeginTime,
      servCatgEndTime,
      servCatgWorkPeriod: taskWorkPeriod,
      proServCatgId,
    } = curData.current || {};

    let dbServCatgTeamMemberList =
      row.dbTaskTeamMemberList || curData.current?.dbServCatgTeamMemberList;
    let defaultValue = {
      proServCatgId,
      servCatgName,
      addTeamMemberList: dbServCatgTeamMemberList?.map(
        (member) => findTree(employeeAll, member.teamMemberId)?.value,
      ),
      taskTime: [
        moment(row.taskBeginTime || servCatgBeginTime || undefined),
        moment(row.taskEndTime || servCatgEndTime || undefined),
      ],
      taskWorkPeriod,
      ...row,
      taskLeader: findTree(employeeAll, row.taskLeader || servCatgLeader)
        ?.value,
    };
    return defaultValue;
  };

  /** 打开新增编辑框 */
  const showEditModal = (row = {}) => {
    setEditModalVisible(true);
    let data = { ...editShowData(row) };
    let projectId = data?.projectId || history.location.query?.projectId;
    if (projectId) {
      getServcatgForProjectId(projectId);
    }
    setLeaderKey(data.taskLeader);
    setEditModalData(data);
  };

  /** 删除 */
  const delRole = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'project/deleteTask',
          payload,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  /** 修改状态点击确定 */
  const statusSave = (val) => {
    let taskStatus = {
      taskIdList: statusData,
      taskStatus: val.taskStatus,
    };
    dispatch({
      type: 'project/taskStatus',
      payload: taskStatus,
    }).then((res) => {
      if (res) {
        setStatusVisible(false);
        setStatusData(null);
      }
    });
  };

  const addType = (item, payload) => {
    return {
      ...item,
      type: payload.taskLeader == item.teamMemberId ? 1 : 0,
    };
  };

  const editModalSave = ({ servCatgId, ...value }) => {
    if (!value.proServCatgId) {
      message.error('上级类目不能为空');
      return;
    }
    if (!value.addTeamMemberList?.includes(value.taskLeader)) {
      message.error('参与人员必须含有负责人！');
      return;
    }
    let payload = {
      proServCatgId: curData.current?.proServCatgId,
      projectId: history.location.query?.projectId || editModalData.projectId,
      taskTeamMemberNum: value.addTeamMemberList.length,
      oldTaskTeamMemberList: [],
      removeTaskTeamMemberList: [],
      ...value,
      taskLeader: value?.taskLeader.split('-')[1],
    };
    if (payload.taskTime) {
      const [start, end] = payload.taskTime;
      payload.taskBeginTime = moment(start).format('YYYY-MM-DD HH:mm:ss');
      payload.taskEndTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
    }
    payload.taskTime = undefined;
    let hasEdit = false;
    if (editModalData.taskId) {
      // 编辑
      hasEdit = true;
      let memberList = menberListOptions({
        dbServCatgTeamMemberList: editModalData.dbTaskTeamMemberList,
        addTeamMemberList: value.addTeamMemberList,
      });
      payload.oldTaskTeamMemberList =
        memberList.thisOldServCatgTeamMemberList.map((item) =>
          addType(item, payload),
        );
      payload.removeTaskTeamMemberList =
        memberList.thisRemoveServCatgTeamMemberList;
      payload.addTaskTeamMemberList =
        memberList.thisNewAddServCatgTeamMemberList.map((item) =>
          addType(item, payload),
        );
      payload.taskId = editModalData.taskId;
    }
    if (!hasEdit && payload.addTeamMemberList) {
      payload.addTaskTeamMemberList = payload.addTeamMemberList?.map(
        (item, index) => ({
          teamMemberId: item.split('-')[1],
          teamMemberSort: index + 1,
          type: payload.taskLeader == item.split('-')[1] ? 1 : 0,
        }),
      );
    }
    payload.addTeamMemberList = undefined;
    payload.servCatgName = undefined;
    // menberListOptions()
    dispatch({
      type: 'project/addTask',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        message.success('操作成功');
      }
    });
  };

  const finishTask = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认已经完成？请检查后再确认。',
      onOk: () => {
        dispatch({
          type: 'project/finishTask',
          payload,
        }).then((res) => {
          if (res) {
            message.success('操作成功');
          }
        });
      },
    });
  };

  const columns = getCloumns({
    employeeAll,
    setStatusVisible,
    setStatusData,
    showEditModal,
    delRole,
    isButton: true,
  });

  const leaderChange = (keys) => {
    const { form } = modalRef.current;
    let oldArr = form.getFieldsValue()?.addTeamMemberList || [];
    setLeaderKey(keys);
    oldArr.push(keys);
    oldArr = Array.from(new Set(oldArr));
    form.setFieldsValue({
      addTeamMemberList: oldArr,
    });
  };
  const modalRef = useRef({});

  // 弹窗formItem
  const edtaFormItem = getEdtaFormItem({
    servCatgList,
    editModalData,
    curData,
    employeeAll,
    modalRef,
    leaderChange,
    leaderKey,
  });

  const searchCloumns = getSearchCloumns({
    employeeAll,
    servcatgList: servCatgTree,
  });

  let tableRef = useRef();

  /** 批量完成 */
  const batchClose = () => {
    if (selectKey?.length == 0) {
      message.warn('请选择需要完成的任务');
      return;
    }
    // finishTask({ taskIdList: selectKey });
    setStatusData(selectKey);
    setStatusVisible(true);
  };

  const extra = () => [
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增任务
    </Button>,
    <Button type="primary" key={btnKeys.statusChange} onClick={batchClose}>
      批量修改状态
    </Button>,
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let { taskTime, ...value } = tableRef.current.value;
        let payload = {
          memberList: value.memberList ?? [],
          proServCatgId: value.proServCatgId ?? '',
          projectId: history.location.query?.projectId ?? '',
          taskName: value.taskName ?? '',
          taskBeginTime: '',
          taskEndTime: '',
        };
        if (payload.memberList) {
          payload.memberList = payload.memberList.map(
            (item) => item.split('-')[1],
          );
        }
        if (taskTime) {
          payload.taskBeginTime = moment(taskTime[0])
            .startOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
          payload.taskEndTime = moment(taskTime[1])
            .endOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
        }
        let url = `${baseURL}${exportTaskList}${splitParamsFoGet(payload)}`;
        downloadXlsx(url, '任务列表');
      }}
    >
      导出
    </Button>,
  ];

  const onSubmit = (payload) => {
    payload.projectId = history.location.query?.projectId;
    // if (!payload.projectId) {
    //   return false;
    // }
    // if (employeeAll?.length == 0) {
    //   return false;
    // }
    if (payload.taskTime) {
      const [start, end] = payload.taskTime;
      payload.taskBeginTime = moment(start)
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.taskEndTime = moment(end)
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    payload.taskTime = undefined;
    if (payload.memberList) {
      payload.memberList = payload.memberList.map((item) => item.split('-')[1]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList) => {
      setSelectKey(userIdList);
    },
    // getCheckboxProps: (record) => ({
    //   disabled: record.taskStatus == 1, // Column configuration not to be checked
    // }),
  };

  return (
    <div>
      <TablePro
        params={{ projectId: history.location.query?.projectId }}
        request="project/getTaskPage"
        rowKey="taskId"
        columns={columns}
        extra={extra}
        searchCloumns={searchCloumns}
        curColumns
        data={project.taskList}
        columnsOptions={false}
        scrollX
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={onSubmit}
        childrenColumnName="child"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />

      <ModalFormPro
        width={'80%'}
        title={editModalData?.taskId ? '编辑' : '新增'}
        formProps={{
          layout: 'vertical',
          labelCol: { span: 8 },
          wrapperCol: { span: 12 },
          className: 'inline-form',
          initialValues: {
            servCatgHourOrginCost: 1800,
          },
        }}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />

      <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani }) => {
  return {
    project,
    systemOrgani,
  };
})(RptInfo);
