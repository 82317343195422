import React, { useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import { Alert, Button, message, Tooltip } from 'kenshin';
import JsonForm from '@/components/JsonForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deepClone } from '@/_util/util';
import { useReactive } from 'ahooks';
import HolidayOptions from './components/HolidayOptions';
import {
  handleBusiType,
  momentTimeFormat,
  handleAttendanceApplyName,
  getVacationApplyTimeType,
  handleApplyMuId,
  handlePartinList,
  getMinStartTimeAndMaxEndTime,
  isTimeArrOverlap,
  transformFileList,
  transformAttendanceApplyDetail,
  filterTime,
  handleAddreass,
  findTree,
  isTimeArrOverlap2,
  handleOutPartinList,
  handleOvertimeYearMap,
  handleYearMap,
  getApplyTimeHourByYearMap,
  getHolidayNumPayload,
  divideTimeIntoHalfDays,
  getattendanceupdowndays,
} from './_util';
import { timeDiff } from '@/_util';
import {
  getattendanceapplydetaildata,
  getattendanceprocesstree,
  getattendancetimelistbytype,
  getbusimanageallemplist,
  getmanageallemplist,
  getmygooutapplyauditpasslist,
  getvacationdefaultdelaytime,
} from '../../../_serveice/attendance';
import { getEditColumns } from './_columns';

const CERTKEY = 'COMMIT:PROVE:MATERIAL';
const getOptions = (data, state) => {
  const options = [];
  deepClone(data).forEach((item) => {
    if (item.childList) {
      const childList = [];
      item.childList.forEach((child) => {
        let childItem = {
          label: child.name,
          value: `${child.id}-${child.code}`,
          vacationLimit: child.vacationLimit,
          remark: child.vacationRemark,
          vacationRemainEnableDays: child.vacationRemainEnableDays,
          vacationRemainEnableUpDays: child.vacationRemainEnableUpDays,
          vacationRemainEnableDownDays: child.vacationRemainEnableDownDays,

          vacationRemainEnableLockDownDays:
            child.vacationRemainEnableLockDownDays,
          vacationRemainEnableLockUpDays: child.vacationRemainEnableLockUpDays,
        };
        // console.log("假期限额...",data);
        //限额
        if (childItem.vacationLimit == 1)
          if (child.id === '4') {
            //调休
            childItem.label += `(剩余${
              childItem.vacationRemainEnableUpDays || 0
            }个上半天、
             ${childItem.vacationRemainEnableDownDays || 0}个下半天)`;
          } else {
            //年假
            childItem.label += `(剩余${
              childItem.vacationRemainEnableDays || 0
            }天)`;
          }

        childList.push(childItem);
      });
      options.push({
        label: item.name,
        value: item.id,
        childList,
        remark: item.vacationRemark,
        disabled: item.disabled,
      });
      // console.log("设置考勤申请类型...1",options);
    } else {
      if (state.hideDelay && item.id == 13) {
        return;
      }
      options.push({
        label: item.name,
        value: item.id,
        remark: item.vacationRemark,
        disabled: item.disabled,
      });
    }
  });

  return options;
};

const AddApply = ({ dispatch, attendance, user }) => {
  const ref = useRef();

  const detailData = useRef({});
  const [employeeList, setNewEmployeeList] = useState([]);
  const [employeeListAll, setNewEmployeeListAll] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [outDisabledTime, setOutDisabledTime] = useState(
    moment().add(1, 'year'),
  );

  const state = useReactive({
    loading: false,
    type: '10',
    remark: '',
    typeName: '加班申请',
    timeList: [],
    outList: [],
    outType: undefined,
    putOffEmpList: [],
    hideDelay: false,
    showDelayTime: false,
    applyTimeHour: 0,
    applyDownDays: 0,
    applyUpDays: 0,
    applyOvertimeType: 2,
    freeApplyUpDays: 0,
    freeApplyDownDays: 0,
    attendanceApplyId: history.location.query.id,
  });

  /** 流程图相关的状态 */
  const processState = useReactive({
    curNode: {},
    list: [],
    dots: null,
    curIndex: 0,
    certIndex: -1,
  });

  useEffect(() => {
    // console.log('detailData.current1...');
    // 假期延期的时候请求是否为业务负责人，是的话返回管理人员
    getbusimanageallemplist().then((res) => {
      if (res && res.data) {
        state.putOffEmpList = res.data?.map((item) => ({
          label: item.value,
          value: item.id,
        }));
        if (!res.success) {
          state.hideDelay = true;
        }
      }
    });
    getmanageallemplist().then((res) => {
      if (res && res.data) {
        // 设置协同人
        setNewEmployeeList(
          res.data?.map((item) => ({ label: item.value, value: item.id })),
        );
      }
    });

    state.loading = false;
    getProcessList();
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setNewEmployeeListAll(
        res?.filter((item) => item.value != '999999' && item.label != '其他'),
      );
    });
    // 设置默认值
    ref.current?.form?.setFieldsValue({
      applyBusiType: '10',
      applyOvertimeType: 2,
      overtimeFreeType: 0,
      processApplyMethod: 1,
      partinList: [],
      vacationApplyNeedEvidence: 1,
    });
    dispatch({
      type: 'attendance/getattendanceapplytypelist',
    }).then((applyTypeList) => {
      // let payload = getHolidayNumPayload(applyTypeList)
      let processApplyId = history.location.query.id;
      if (!processApplyId) return;
      if (processApplyId != undefined && processApplyId != null) {
        const { form } = ref.current;
        console.log('detailData.current1...', detailData.current);
        getattendanceapplydetaildata({ processApplyId }).then((res) => {
          detailData.current = transformAttendanceApplyDetail(res.data, {
            typeSelectChange,
            applyTypeList,
            state,
          });
          console.log('detailData.current3...', detailData.current);
          form.setFieldsValue(detailData.current);
          if (history.location.query.id) {
            //  获取外出时段
            clockApplyTypeChange(state.outType);

            if (detailData.current?.applyBusiType == '15') {
              let options = getOptions(
                attendance.applyTypeList.filter((child) => child.id != '16'),
                state,
              );
              if (options && options.length >= 5) {
                state.list = options[4]?.childList;
                // console.log("设置考勤申请类型...2", options[4]?.childList);
              }
            }
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    // 设置默认值
    if (state.type == '17') {
      ref.current?.form?.setFieldsValue({
        overtimeFreeType: 1,
      });
    } else {
      ref.current?.form?.setFieldsValue({
        overtimeFreeType: 0,
      });
    }
  }, state.type);

  /** 获取流程图数据 */
  const getProcessList = () => {
    let processApplyId = history.location.query.id;
    if (!processApplyId) return;
    getattendanceprocesstree({ processApplyId }).then((res) => {
      if (res && res.success && res.data) {
        processState.curNode = res.data.curnProcessNode;
        processState.list = res.data?.processNodeList ?? [];
        let curIndex = processState.list.findIndex(
          (item) =>
            res.data?.curnProcessNode?.processApplyNodeId ==
            item.processApplyNodeId,
        );
        processState.certIndex = processState.list.findIndex(
          (item) => item.processNodeNumber == CERTKEY,
        );
        processState.curIndex = curIndex;
        processState.dots = (index) => {
          if (index < curIndex || curIndex == processState.list.length - 1)
            return { dotColor: '#0DE894' };
          else if (index == curIndex) return { dotColor: '#4389f9' };
          else return {};
        };
      }
    });
  };

  const typeTitle = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        overlayStyle={{
          maxWidth: 420,
        }}
        placement="right"
        title={state.remark}
      >
        <ExclamationCircleOutlined style={{ marginRight: 4 }} />
      </Tooltip>
      申请类型
    </div>
  );

  /** 获取当前类型已上报的时间 */
  const getOldApplyTime = (applyBusiType) => {
    const { form } = ref.current;
    let objData = form.getFieldsValue();
    let replaceEmpIdList =
      objData.partinList?.map(
        (item) => item.split('-')[item.split('-').length - 1],
      ) ?? '';
    // if(replaceEmpIdList)
    if (replaceEmpIdList?.length == 0) replaceEmpIdList = '';
    getattendancetimelistbytype({
      applyBusiType: applyBusiType || objData.applyBusiType,
      replaceEmpIdList,
    }).then((res) => {
      if (!res || !res.data) return (state.timeList = []);
      let array = res.data.map((item) =>
        item.applyTimeList.map((child) => {
          return {
            ...child,
            empName: item?.employee?.value,
          };
        }),
      );
      let timeList =
        array.flat(2)?.map((item) => [item.beginDate, item.endDate, item]) ||
        [];

      if (history.location.query.id) {
        let time2 = [
          [detailData.current.applyBeginTime, detailData.current.applyEndTime],
          ...(detailData.current.vacationList?.map((item) => [
            moment(item.applyTime[0]).format('YYYY-MM-DD HH:mm:ss'),
            moment(item.applyTime[1]).format('YYYY-MM-DD HH:mm:ss'),
          ]) ?? []),
        ].filter((item) => item?.[0]);
        state.timeList = filterTime(timeList, time2).map((item) => ({
          ...item[2],
          beginDate: item[0],
          endDate: item[1],
        }));
      } else {
        state.timeList = timeList.map((item) => ({
          ...item[2],
          beginDate: item[0],
          endDate: item[1],
        }));
      }
    });
  };

  const typeSelectChange = (value, row) => {
    getOldApplyTime(value);
    if (value == 10) {
      // 加班默认选择公休日加班、自己申请
      ref.current?.form?.setFieldsValue({
        applyOvertimeType: 2,
        processApplyMethod: 1,
        partinList: [],
      });
    }
    if (value == 17) {
      // 加班默认选择公休日加班、自己申请
      ref.current?.form?.setFieldsValue({
        applyOvertimeType: 1,
        processApplyMethod: 1,
        partinList: [],
      });
    }
    if (value == 13) {
      // 外出陪同人员应清空
      ref.current?.form?.setFieldsValue({ partinList: [] });
    }
    if (value == 11) {
      let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
      let itemEmp = employeeListAll
        .map((item) => item.options)
        .flat(2)
        .find((item) => item.value.split('-')[1] == empId);
      ref.current?.form?.setFieldsValue({ partinList: [itemEmp?.value] });
    }
    state.type = value;
    state.remark = row.remark;
    state.typeName = row.label;
    row.childList && (state.list = row.childList);
  };

  const handleDisabledDate = (current) => {
    return (
      current > moment().add(8, 'month') ||
      current < moment().subtract(3, 'month')
    );
  };

  function isNumericString(value) {
    return typeof value === 'number';
  }
  /** 获取外出时段 */
  const clockApplyTypeChange = (type) => {
    // console.log('clockApplyTypeChange...', typeof type);
    if (isNumericString(type)) {
      state.outType = type;
    }
    if (type == 3 || state.outType == 3) {
      let forgetClockApplyDate = ref.current.form.getFieldValue(
        'forgetClockActualTime',
      );
      getmygooutapplyauditpasslist({
        applyDate: moment(forgetClockApplyDate).format('YYYY-MM-DD'),
      }).then((res) => {
        state.outList = res.data.map((item) => {
          let label = [
            item.applyOutProvinceName,
            item.applyOutCityName,
            item.applyOutAreaName,
            item.applyOutCompany,
          ].join('/');
          let value = [
            item.applyOutProvinceCode,
            item.applyOutCityCode,
            item.applyOutAreaCode,
            item.attendanceApplyId,
            item.applyOutCompany,
          ].join('/');
          return {
            label,
            value,
          };
        });
      });
    }
  };

  const partinChange = () => {
    // console.log("延期");
    const { form } = ref.current;
    let objData = form.getFieldsValue();
    console.log(objData.partinList);
    state.showDelayTime = false;
    if (objData.partinList?.length > 0) {
      getvacationdefaultdelaytime({ empIdList: objData.partinList }).then(
        (res) => {
          if (res.success) {
            state.showDelayTime = true;
            setOutDisabledTime(moment(res.data));
          }
        },
      );
    }
  };

  console.log('state..getEditColumns', state.applyOvertimeType);
  const edtaFormItem = getEditColumns({
    getOptions,
    typeTitle,
    attendance,
    state,
    disabled: processState.curIndex > 1,
    disabledWithoutTime: detailData.current.isAuditPassChangeProcess,
    editDisabled: !!history.location.query.id,
    employeeList,
    employeeListAll,
    detailData: detailData.current,
    disabledDate: handleDisabledDate,
    typeSelectChange,
    applyTimeDisabled: true,
    ref: ref.current,
    getOldApplyTime,
    clockApplyTypeChange,
    putOffEmpList: state.putOffEmpList,
    partinChange,
    outDateDisabled: (current) => current && current < moment(outDisabledTime),
  });

  const handleSave = () => {
    state.loading = true;
    let processApplyId = history.location.query.id;
    const { form } = ref.current;
    form
      .validateFields()
      .then(async (values) => {
        values.processApplyMethod ||= 1;
        values.applyBusiType = handleBusiType(values.applyBusiType);
        if (values.applyBusiType == 13) values.processApplyMethod = 2;
        if (values.applyTime) {
          // let hasOverLap = true
          // // let hasOverLap = isTimeArrOverlap([
          // //   ...state.timeList.map((item) => [item.beginDate, item.endDate]),
          // //   values.applyTime,
          // // ]);
          // if (hasOverLap) {
          //   state.loading = false;
          //   return message.error('当前日期已经申请过了！');
          // }

          let applyTime = momentTimeFormat(values.applyTime);
          values.applyTimeHour = timeDiff(applyTime, 'hours', 4);
          values.applyTimeHour = state.applyTimeHour;
          values.applyBeginTime = applyTime[0];
          values.applyEndTime = applyTime[1];

          if (values.applyBusiType == 10) {
            // let applyBusiType = ""
            // if (values.applyBusiType == 17) {
            //   applyBusiType = 15
            // }
            // console.log('校验加班参数222...', values, state);
            //加班申请增加上下半天参数
            // let res = await getattendanceupdowndays({
            //   ...values,
            //   applyBusiType,
            // });
            // if (res) {
            //   values.applyOvertimeUpDays = res.data.upDays;
            //   values.applyOvertimeDownDays = res.data.downDays;
            //   values.applyTimeHour =
            //     values.applyOvertimeUpDays * 3 +
            //     values.applyOvertimeDownDays * 4.5;
            // }
            // console.log('校验加班参数4444...', res);
            // console.log('校验加班参数...', values);
          }

          if (['10', '17'].includes(values.applyBusiType)) {
            values.applyBeginTime = applyTime[0];
            values.applyEndTime = applyTime[1];

            values.applyOvertimeUpDays = state.applyUpDays;
            values.applyOvertimeDownDays = state.applyDownDays;
            // console.log('校验加班参数4444...', state.applyDownDays);
            values.applyTimeHour =
              values.applyOvertimeUpDays * 3 +
              values.applyOvertimeDownDays * 4.5;
          }
        }
        //加班调休时间
        if (values.freeApplyTime) {
          let applyTime = momentTimeFormat(values.freeApplyTime);
          values.freeApplyBeginTime = applyTime[0];
          values.freeApplyEndTime = applyTime[1];

          // let applyBusiType = ""
          // if (values.applyBusiType == 17) {
          //   applyBusiType = 15
          // }

          //加班申请增加上下半天参数
          // let res = await getattendanceupdowndays({ ...values, applyBusiType });
          // if (res) {
          //   values.freeApplyUpDays = res.data.upDays;
          //   values.freeApplyDownDays = res.data.downDays;
          // }
          values.freeApplyUpDays = state.freeApplyUpDays;
          values.freeApplyDownDays = state.freeApplyDownDays;
          values.freeApplyTimeHour =
            values.freeApplyUpDays * 3 + values.freeApplyDownDays * 4.5;
          // console.log('校验加班参数...123123', values);
          values.freeApplyTime = undefined;
        } else {
          values.freeApplyBeginTime = '';
          values.freeApplyEndTime = '';
          values.freeApplyDownDays = 0;
          values.freeApplyUpDays = 0;
          values.freeApplyTimeHour = 0;
        }
        console.log('校验加班参数...123123', values);
        if (values.applyBusiType == 15)
          values.vacationApplyTimeType = getVacationApplyTimeType(
            values.vacationList.map((item) => item.applyTime),
          );
        if (values.vacationList) {
          let hasOverLap = isTimeArrOverlap2(
            [
              ...state.timeList
                .filter((item) => item.attendanceApplyType == 15)
                .map((item) => [item.beginDate, item.endDate]),
            ],
            ...values.vacationList.map((item) => item.applyTime),
          );
          if (hasOverLap) {
            // state.loading = false;
            // return message.error('当前日期已经申请过了！');
          }
          values.vacationList = [...values.vacationList].map((item, index) => {
            let applyTime = momentTimeFormat(item.applyTime);
            item.applyBeginTime = applyTime[0];
            item.applyEndTime = applyTime[1];
            item.vacationId = item.applyType.split('-')[0];
            item.vacationCode = item.applyType.split('-')[1];
            item.vacationSort = index + 1;
            if (!item.isDownReplaceUp) {
              // item.applyTimeHour = timeDiff(applyTime, 'hours', 4);
            }
            item.applyTime = undefined;
            item.applyType = undefined;

            if (item.vacationId === '4') {
              // let divides = divideTimeIntoHalfDays(item.applyTimeHour);
              // item.applyUpDays = divides[0];
              // item.applyDownDays = divides[1];
              // let res = await getattendanceupdowndays(values);
              // if(res) {
              //   values.applyOvertimeUpDays = res.data.upDays;
              //   values.applyOvertimeDownDays = res.data.downDays;
              // }
            }

            console.log('校验调休参数...', item);

            return item;
          });
          values.applyTimeHour =
            values.vacationList.reduce(
              (pre, cur) => pre + cur.applyTimeHour,
              0,
            ) || 0;
        }
        values.applyTime = undefined;
        if (values.applyBusiType == 11) {
          // 处理协同人
          values.partinList = handleOutPartinList(values);
          let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
          if (!values.partinList.some((item) => item.partinId == empId)) {
            message.error('协同人必须包含自己！');
            state.loading = false;
            return;
          }
        } else if (values.applyBusiType == 13) {
          //假期延期
          values.partinList = handleOutPartinList(values);
        } else if (values.processApplyMethod == 2) {
          //处理代他人申请
          values.partinList = handlePartinList(values, employeeList);
        } else {
          //自己申请
          values.partinList = handleApplyMuId(values);
        }

        // 忘打卡
        if (values.forgetClockActualTime) {
          values.forgetClockActualTime = moment(
            values.forgetClockActualTime,
          ).format('YYYY-MM-DD HH:mm:ss');

          const parts = values?.forgetClockActualTime?.split(' ');
          if (parts && parts.length > 1) {
            values.forgetClockApplyDate = parts[0];
          }
        }
        if (values.forgetClockApplyDate) {
          values.forgetClockApplyDate = moment(
            values.forgetClockApplyDate,
          ).format('YYYY-MM-DD');
        }
        if (values.forgetClockProver) {
          values.forgetClockProverName = findTree(
            employeeListAll,
            values.forgetClockProver,
          )?.label;
          values.forgetClockProver = values.forgetClockProver.split('-')[1];
        }
        if (values.applyDelayTime)
          values.applyDelayTime = moment(values.applyDelayTime)
            .endOf('date')
            .format('YYYY-MM-DD HH:mm:ss');
        // 忘打卡end

        values.attendanceApplyName = handleAttendanceApplyName(
          {
            ...values,
            typeName: state.typeName,
            typeList: state.list,
          },
          employeeList,
          employeeListAll,
        );
        if (values.attachmentList) {
          values.attachmentList = transformFileList(values.attachmentList);
        }
        console.log(
          'values.applyTimeHour',
          values.applyTimeHour,
          detailData.current.applyTimeHour,
        );
        if (
          //假期申请：时长发生变化 或者 假期类型发生变化 或者证明材料表示发生变化, 变更后的数据编辑操作不进行删除
          (!detailData.current.isAuditPassChangeProcess &&
            values.applyBusiType == 15 &&
            processApplyId &&
            // (values.applyTimeHour < 7.5 != detailData.current.applyTimeHour < 7.5 || values.applyBusiType != detailData.current.applyBusiType)
            (values.applyTimeHour != detailData.current.applyTimeHour ||
              values.applyBusiType != detailData.current.applyBusiType)) ||
          (detailData.current.vacationApplyNeedEvidence &&
            values.vacationApplyNeedEvidence !=
              detailData.current.vacationApplyNeedEvidence)
        ) {
          console.log('values.vacationList:1', values.vacationList);

          if (!detailData.current.isAuditPassChangeProcess) {
            await dispatch({
              type: 'attendance/deleteattendanceapply',
              loader: false,
              payload: { processApplyId },
            });
            processApplyId = undefined; //删除之后要清空申请id
            values.vacationList = values.vacationList?.map((item) => ({
              ...item,
              vacationRcdId: undefined,
            })); //删除之后要清空假期申请id

            console.log('values.vacationList:2', values.vacationList);
          }
        }

        // 处理地址
        if (values.applyOutArea) {
          Object.assign(
            values,
            handleAddreass(values.applyOutArea, user.addressList),
          );
          values.applyOutArea = undefined;
        }

        // 处理忘打卡外出申请的地区
        if (values.applyBusiType == 12 && values.forgetClockApplyType == 3) {
          let forgetClockArr = values.forgetClock.split('/');
          Object.assign(
            values,
            handleAddreass(forgetClockArr, user.addressList),
          );
          let applyOutCompany = forgetClockArr[forgetClockArr.length - 1];
          let applyBusiId = forgetClockArr[forgetClockArr.length - 2];
          values.applyOutCompany = applyOutCompany;
          values.applyBusiId = applyBusiId;
          values.forgetClock = undefined;
        }

        // console.log(values,moment(values.applyBeginTime).year(),moment(values.applyEndTime).year());
        //跨年
        if (
          moment(values.applyBeginTime).year() !=
          moment(values.applyEndTime).year()
        ) {
          // 处理跨年的加班时间
          if (values.applyBusiType == 10) {
            values.overtimeYearMap = handleOvertimeYearMap(values);
          }

          //加班跨年申请,增加字段
          if (['10'].includes(values.applyBusiType)) {
            values.applyExtend = handleYearMap(values);
            let timeResult = getApplyTimeHourByYearMap(values.applyExtend);
            // values.applyTimeHour = timeResult.applyTimeHour;
            // values.applyOvertimeUpDays = timeResult.applyOvertimeUpDays;
            // values.applyOvertimeDownDays = timeResult.applyOvertimeDownDays;
            values = { ...values, ...timeResult };
          }
        }

        // console.log('vvv', values);
        console.log('vvv', values.applyBusiType);
        // state.loading = false;

        dispatch({
          type: 'attendance/lunchattendanceapply',
          payload: {
            attendanceApplyId: processApplyId,
            ...values,
          },
        }).then((res) => {
          state.loading = false;
          if (res) {
            handleCancel();
            if (processState.curIndex == 0 && processApplyId) {
              dispatch({
                type: 'attendance/auditattendanceprocess',
                payload: {
                  attendanceApplyId: processApplyId,
                  auditResult: 1,
                  auditRemarkList: [],
                },
              }).then((res) => {});
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
        state.loading = false;
      });
  };

  const handleCancel = () => {
    history.go(-1);
  };

  const timeListRender = (item) => {
    let clockType = {
      1: '上班',
      2: '下班',
      3: '外出',
    };
    let name = [
      item.empName,
      clockType[item.forgetClockApplyType],
      item.attendanceApplyTypeName,
    ].join('');
    let time = '';
    if (item.forgetClockApplyDate) {
      time = moment(item.forgetClockApplyDate).format('YYYY-MM-DD');
    }
    if (item.beginDate) {
      time = `${moment(item.beginDate).format(
        'YYYY-MM-DD HH:mm:ss',
      )} ~ ${moment(item.endDate).format('YYYY-MM-DD HH:mm:ss')}`;
    }
    if (item.applyDelayTime) {
      time = moment(item.applyDelayTime).format('YYYY-MM-DD');
    }
    return `${name}：${time}`;
  };

  return (
    <div>
      <div className="form_header">
        <h3>{history.location.query?.id ? '编辑' : '新增'}假期类型</h3>
        <div>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={btnDisabled}
            loading={state.loading}
          >
            保存
          </Button>
          <Button onClick={handleCancel}>返回</Button>
        </div>
      </div>
      <JsonForm columns={edtaFormItem} ref={ref} />
      {state.timeList.length > 0 && (
        <div style={{ marginLeft: '20%', marginBottom: 20, lineHeight: 1.5 }}>
          <h3 style={{ color: '#4389f9' }}>已发起的申请：</h3>
          {state.timeList.map((item, index) => (
            <div key={index}>{timeListRender(item)}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(({ customer, system, attendance, user }) => ({
  customer,
  user,
  system,
  attendance,
}))(AddApply);
