import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Dropdown,
  MessageBox,
  Tree,
  message,
  Input,
  Button,
  Col,
  Row,
} from 'kenshin';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import '@/components/TreeData/index.less';
import { connect } from 'umi';
import { useMemoizedFn } from 'ahooks';
import ModalFormPro from '@/components/ModalFormPro';
import { debounce, deepClone } from '@/_util';
import { selectPinyinFilter } from '@/_util/componentsUtil';

const findTree = (fildTree, id = 3) => {
  for (let i = 0; i < fildTree.length; i++) {
    if (fildTree[i].deptId == id) {
      return {
        pre: fildTree[i - 1],
        cur: fildTree[i],
        next: fildTree[i + 1],
      };
    }
    if (fildTree[i].child) {
      return findTree(fildTree[i].child, id);
    }
  }
};

const TreeData = (props) => {
  const { dispatch, system, onChange, defaultSelect } = props;
  const { dictypeList } = system;

  const modalRef = useRef();

  const transformTreeData = useMemoizedFn((arr, level = 1) => {
    return arr?.map((item) => {
      let children = null;
      if (item.child) {
        children = transformTreeData(item.child, level + 1);
      }
      return {
        children,
        title: item.dicTypeName,
        key: item.dicTypeId,
        level,
        ...item,
      };
    });
  });

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (dictypeList?.child) {
      setTreeData(transformTreeData(dictypeList?.child));
    }
  }, [dictypeList]);

  // 编辑新增操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const TreeOptions = (e, row, type) => {
    e.stopPropagation();
    setEditModalData({ ...row, type });
    setEditModalVisible(true);
    if (type == 'add') {
      const { form } = modalRef.current;
      form.setFieldsValue({
        dicTypeSort: row?.child?.[row.child.length - 1]?.dicTypeSort + 1,
      });
    }
  };

  const editModalSave = (val) => {
    let hasEdit = false;
    let params = {
      ...val,
      dicTypePantId: editModalData.dicTypeId || '1',
    };
    if (editModalData.type != 'add') {
      let sortChange = 0;
      if (editModalData.dicTypeSort != val.dicTypeSort) {
        sortChange = 1;
      }
      hasEdit = true;
      params = {
        ...val,
        sortChange,
        dicTypePantId: editModalData.dicTypePantId,
        dicTypeId: editModalData.dicTypeId,
      };
    }

    dispatch({
      type: 'system/editDictype',
      hasEdit,
      payload: params,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const delTreeData = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'system/deleteDictype',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const optionsMenu = (row) => (
    <Dropdown.Menu>
      {row.level < 2 && (
        <Dropdown.Item>
          <span onClick={(e) => TreeOptions(e, row, 'add')}>添加字典类型</span>
        </Dropdown.Item>
      )}
      <Dropdown.Item>
        <span onClick={(e) => TreeOptions(e, row, 'edit')}>修改字典类型</span>
      </Dropdown.Item>
      <Dropdown.Item>
        <span onClick={(e) => delTreeData({ dicTypeId: row.dicTypeId })}>
          删除字典类型
        </span>
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const edtaFormItem = [
    {
      label: '名称',
      name: 'dicTypeName',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
    {
      label: '排序',
      name: 'dicTypeSort',
      type: 'InputNumber',
      required: '请输入排序',
      props: {
        placeholder: '请输入排序',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: 'key值',
      name: 'dicTypeKey',
      type: 'Input',
      required: '请输入排序',
      props: {
        placeholder: 'key值',
        disabled: editModalData?.type != 'add',
      },
    },
    {
      label: '备注',
      name: 'dicTypeRemark',
      type: 'TextArea',
      props: {
        placeholder: '请输入备注',
      },
    },
  ];

  const defaultTreeData = useMemo(
    () => deepClone(transformTreeData(dictypeList?.child)),
    [dictypeList],
  );
  // 搜索过滤树
  const filterTree = (arr, value) => {
    return arr.filter((item) => {
      if (item.children) {
        let children = filterTree(item.children, value);
        item.children = children;
        return children.length > 0;
      }
      return (
        item.title.includes(value) || selectPinyinFilter(item.title, value)
      );
    });
  };

  const searchInput = (e) => {
    const { value } = e.target;
    setTreeData(filterTree(deepClone(defaultTreeData), value));
  };

  return (
    <div className="treeData">
      <Row wrap={false} gutter={[8, 8]}>
        <Col flex={1}>
          <Input placeholder="请输入" onChange={debounce(searchInput, 500)} />
        </Col>
        <Col>
          <Button onClick={(e) => TreeOptions(e, { dicTypeId: 1 }, 'add')}>
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <Tree
        defaultExpandAll
        defaultSelectedKeys={[defaultSelect]}
        treeData={treeData}
        blockNode
        onSelect={onChange}
        titleRender={(node) => {
          return (
            <div>
              {node.title}
              <div
                style={{ float: 'right' }}
                onClick={(e) => e.stopPropagation()}
              >
                <Dropdown menu={optionsMenu(node)}>
                  <MoreOutlined />
                </Dropdown>
              </div>
            </div>
          );
        }}
      />

      <ModalFormPro
        title={editModalData?.type == 'add' ? '新增' : '编辑'}
        width={400}
        ref={modalRef}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        isSetValue={editModalData?.type != 'add'}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ system }) => ({ system }))(TreeData);
