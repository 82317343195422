import { connect, history } from 'umi';
import { Button, message, Switch, Table, MessageBox } from 'kenshin';
import TablePro from '@/components/TablePro';
import ModalFormPro from '@/components/ModalFormPro';
import { useEffect, useRef, useState } from 'react';
import PageLoading from '@/components/pageLoading';
import RecordModal from '../../usercenter/business/_pendingBusiness/components/RecordModal';

const ProcessList = ({ process, dispatch }) => {
  const tableRef = useRef();
  const curData = useRef();
  const [recordVisible, setRecordVisible] = useState(false);

  /** 处理 */
  const handleTodo = (row) => {
    let params = {
      processApplyId: row.dataChange.processApplyId,
      todoItemId: row.todoItemId,
      dataChangeId: row.dataChange.dataChangeId,
      dataChangeBefore: row.dataChange.dataChangeBefore,
    };
    const newData = JSON.parse(row.dataChange?.dataChangeAfter ?? '{}');
    dispatch({
      type: 'usercenter/save',
      payload: {
        createProcessData: params,
      },
    });
    dispatch({
      type: 'customer/save',
      payload: {
        editData: newData,
      },
    });
    history.push({
      pathname: '/customer/newCustomer',
      query: {
        custId: newData.custId,
        type: 'editProcess',
      },
    });
  };

  /** 审核 */
  const closeApply = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认关闭？关闭后无法恢复',
      onOk: () => {
        dispatch({
          type: 'process/closeprocessapply',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('关闭成功');
          }
        });
      },
    });
  };

  const applyStatusObj = {
    '-1': '待提交',
    0: '审核中',
    1: '审核通过',
    2: '审核拒绝',
    3: '终止流程',
  };

  const columnsObj = {
    columns: [
      {
        dataIndex: 'processApplyName',
        title: '流程申请名称',
      },
      {
        dataIndex: 'applyName',
        title: '申请人',
      },
      {
        dataIndex: 'applyBusiType',
        title: '申请的业务类型',
        render: (val) => process.processTypeStrObj[val],
      },
      {
        dataIndex: 'curnProcessNodeName',
        title: '当前流程',
        render(text, row) {
          return (
            <Button
              type="text"
              style={{ whiteSpace: 'inherit', textAlign: 'left' }}
              onClick={() => {
                curData.current = row;
                setRecordVisible(true);
              }}
            >
              {text}
            </Button>
          );
        },
      },
      {
        dataIndex: 'applyStatus',
        title: '申请状态',
        render: (val) => applyStatusObj[val],
      },
      {
        dataIndex: 'gmtCreate',
        title: '创建时间',
        render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        key: 'options',
        title: '操作',
        render: (row) => {
          return (
            <>
              {![1, 3].includes(row.applyStatus) &&
                ![6, 7, 8, 9, 10, 11, 12, 13, 15].includes(
                  row.applyBusiType,
                ) && (
                  <Button
                    type="text"
                    onClick={() =>
                      closeApply({ processApplyList: [row.processApplyId] })
                    }
                  >
                    关闭
                  </Button>
                )}
            </>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'processApplyName',
      type: 'Input',
      props: {
        placeholder: '流程名称',
      },
    },
    {
      name: 'processBusiType',
      type: 'Select',
      props: {
        placeholder: '业务类型',
        options: process.processTypeOptions,
      },
    },
  ];

  return (
    <div>
      <TablePro
        request="process/getallprocessapplypage"
        searchCloumns={searchCloumns}
        rowKey={'processApplyId'}
        data={process.allProcessApplyPageList}
        actionRef={(ref) => (tableRef.current = ref)}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        curColumns
        {...columnsObj}
      ></TablePro>
      <RecordModal
        visible={recordVisible}
        row={curData.current}
        processApplyId={curData.current?.processApplyId}
        onCancel={() => {
          setRecordVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ process }) => ({
  process,
}))(ProcessList);
