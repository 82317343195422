import Charts from 'echarts-for-react';
import { useRef, useMemo, memo, useEffect } from 'react';
import * as echarts from 'echarts';
import { Radio } from 'kenshin';
import { deepClone } from '@/_util/util';

const AnalyseCharts = memo((props) => {
  const {
    radioOptions,
    data,
    xData,
    unit,
    showDataZoom = true,
    legend = {},
    style = {}
  } = props;

  let leftUnit = unit ?? '元';
  let rightUnit = '%';
  let datas = [
    {
      name: '收款总金额',
      type: 'bar',
      data: [
        40.0, 40.9, 30.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
      ],
      color: '#4389f9',
    },
    {
      type: 'bar',
      name: '合同总金额',
      data: [
        12.6, 15.9, 19.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3,
        23.4, 23.0, 16.5, 12.0, 6.2,
      ],
      color: '#42c697',
    },
    {
      type: 'bar',
      name: '合同总金额2',
      data: [
        28.7, 70.7, 175.6, 182.2, 2.6, 5.9, 9.0, 26.4, 48.7, 18.8, 6.0, 2.3,
        182.2, 48.7, 18.8, 6.0, 2.3,
      ],
      color: '#fd8c8d',
    },
    // {
    //   type: 'line',
    //   name: '成本费用率',
    //   data: [
    //     2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2, 26.4,
    //     48.7, 18.8, 6.0, 2.3,
    //   ],
    // },
  ];

  let xDatas = [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  let options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    // toolbox: {
    //   feature: {
    //     dataView: { show: true, readOnly: false },
    //     magicType: { show: true, type: ['line', 'bar'] },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // },
    // toolbox: {
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: false
    //     },
    //   }
    // },
    dataZoom: showDataZoom ? [
      {
        type: 'inside',
      },
      {
        type: 'slider',
      },
    ] : undefined,
    legend: {
      show: true,
      data: data?.map((item) => item.name),
      ...legend,
      // data: data.map(item => item.name)
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        // name: leftUnit,
        axisLabel: {
          formatter: `{value} ${leftUnit}`,
        },
      },
      {
        type: 'value',
        // name: rightUnit,
        axisLabel: {
          formatter: `{value} ${rightUnit}`,
        },
      },
    ],
    series: data?.map((item) => {
      return {
        name: item.name,
        type: item.type,
        data: item.data,
        smooth: true,
        barWidth: '14',
        itemStyle: {
          normal: {
            color: item.color,
            barBorderRadius: [4, 4, 0, 0], //圆角大小
          },
        },
        tooltip: {
          valueFormatter: function (value) {
            if (item.type == 'bar') {
              return `${value} ${leftUnit}`;
            }
            return `${value} ${rightUnit}`;
          },
        },
        ...(() => {
          if (item.type == 'line') {
            return {
              yAxisIndex: 1,
            };
          }
          return {};
        })(),
      };
    }),
  };

  let refs = useRef();
  // useEffect(() => {
  //   refs?.current?.getEchartsInstance().setOption(deepClone(options));
  // }, [options]);

  // const baseCharte = useMemo(() => {
  //   return (
  //     <Charts
  //       ref={refs}
  //       notMerge
  //       option={options}
  //       lazyUpdate={true}
  //       style={{ height: 300, width: '100%' }}
  //     />
  //   );
  // }, []);

  return (
    <div className="analyse-charts">
      {radioOptions && (
        <Radio.Group options={radioOptions} size="small" optionType="button" />
      )}
      {/* {baseCharte} */}
      <Charts
        ref={refs}
        notMerge
        option={options}
        style={{ height: 300, width: '100%', ...style }}
      />
      {/* <Charts
        notMerge={false}
        option={options}
        lazyUpdate={true}
        style={{ height: 300, width: '100%' }}
      /> */}
    </div>
  );
});

export default AnalyseCharts;
