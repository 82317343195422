import EllipsisTooltip from '@/components/EllipsisTooltip';
import React from 'react';
import { Card, Col, Row } from 'kenshin';
import busiTypeKey from '../../_util/busiTypeKey';
import { cardJSONRender } from './util';
/** 表格表头 */
const cloumns = () => {
  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      width: 120,
      fixed: 'left',
      render(userInfo) {
        return userInfo?.value;
      },
    },
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      width: 120,
      fixed: 'left',
      render(value) {
        const key = {
          1: '周计划',
          3: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaSumRemark',
      title: '评分',
      width: 180,
      render: (row) => {
        if (row.osaWeekType == 1) {
          return <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>;
        }
        return [
          <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
          <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
          <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
        ];
      },
    },
    {
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
    },
  ];
  return columns;
};
/** 搜索数据 */
const searchCloumns = ({}) => {
  const searchCloumns = [];
  return searchCloumns;
};

/** 编辑数据 */
const formCloumns = ({}) => [];

/** 首次保存 */
const firstSave = (values, { process, getAfterStr }) => {};

/** 再次保存 */
const reSave = (values, { process, getAfterStr }) => {};

/** 创建后 */
const created = ({ dispatch }) => {};

/** 预览card */
const getCard = (afterData) => {
  if (Object.keys(afterData).length == 0) {
    return null;
  }
  let data = afterData?.newData || afterData;

  const cloumns = [
    {
      label: '角色名称',
      value: 'roleName',
    },
    {
      label: '角色标识',
      value: 'roleKey',
    },
    {
      label: '角色类型',
      value: 'roleTypeStr',
    },
    {
      label: '角色备注',
      value: 'roleRemark',
    },
    {
      label: '授权期限',
      value: 'grantDays',
      render: (time) => {
        // return moment(time).endOf('date').diff(moment(), 'd') + '天';
        let day = time ?? moment(time).endOf('date').diff(moment(), 'd') ?? '';
        return day + '天';
      },
    },
    {
      label: '过期日期',
      value: 'expireDate',
    },
  ];
  return (
    <Card className="process-card-box">{cardJSONRender(cloumns, data)}</Card>
  );
};

export default {
  chooseId: 'roleId',
  propsName: (data) => data['roleName'],
  cloumns,
  searchCloumns,
  formCloumns,
  firstSave,
  reSave,
  created,
  getCard,
  key: busiTypeKey.ROLE,
};
