import React from 'react';
import { Button, message, MessageBox, Row, Col, Tooltip } from 'kenshin';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { findTree } from '@/components/ServCategoryList';
import { timerYMD } from '@/_util/componentsUtil';
import { selectPinyinFilter, deepClone } from '@/_util';
import { taskStatusObj } from '.';

/** 状态修改弹窗form表单配置 */
export const statusFormItem = [
  {
    label: '状态',
    name: 'taskStatus',
    type: 'Select',
    props: {
      placeholder: '请选择状态',
      options: [
        {
          label: '未完成',
          value: 0,
        },
        {
          label: '已完成',
          value: 1,
        },
      ],
    },
  },
];

/** 表格列数据 */
export const getCloumns = ({
  employeeAll,
  setStatusVisible,
  setStatusData,
  showEditModal,
  delRole,
  isButton = false,
}) => {
  const columns = [
    {
      dataIndex: 'taskName',
      title: '任务名称',
      fixed: 'left',
      width: 200,
      render: (val, row) => val || row.servCatgName,
    },
    {
      key: 'servCatgName',
      title: '上级类目',
      width: 200,
      render: (row) => (
        <EllipsisTooltip
          value={`${row.projectName}>${row.servCatgName}`}
          width={200}
        />
      ),
    },
    {
      dataIndex: 'taskLeader',
      title: '任务负责人',
      width: 120,
      render: (val) => {
        return findTree(employeeAll, val)?.label;
      },
    },
    {
      dataIndex: 'dbTaskTeamMemberList',
      title: '参与团队',
      width: 200,
      render(memberList, row) {
        let list = [...memberList];
        let listDiv = list
          .splice(0, 3)
          .map((item) => findTree(employeeAll, item.teamMemberId)?.label)
          .join('、');
        return (
          <div>
            {listDiv}
            {row.taskTeamMemberNum > 3 && (
              <Tooltip
                overlayStyle={{ minWidth: 350, maxWidth: 450 }}
                title={
                  <Row>
                    {list.map((item, i) => (
                      <Col key={i} span={5}>
                        {findTree(employeeAll, item.teamMemberId)?.label}
                      </Col>
                    ))}
                  </Row>
                }
              >
                <span>
                  <Button type="text">更多{row.taskTeamMemberNum - 3}人</Button>
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      key: 'taskTime',
      title: '任务时间',
      width: 250,
      render: (row) => timerYMD([row.taskBeginTime, row.taskEndTime]),
      // render: (row) => (
      //   <div>
      //     <div>{row.taskBeginTime}~{row.taskEndTime}</div>
      //   </div>
      // ),
    },
    {
      dataIndex: 'taskStatus',
      title: '任务状态',
      width: 100,
      render: (taskStatus) => taskStatusObj[taskStatus],
    },
    {
      dataIndex: 'taskWorkPeriod',
      width: 100,
      title: '任务工期',
    },
    {
      dataIndex: 'rptWorkHour',
      title: '已上报/时',
      width: 100,
      render: (val) => val || 0,
    },
    {
      dataIndex: 'taskRemark',
      title: '任务备注',
      width: 220,
      render: (val) => <EllipsisTooltip value={val} width={200} />,
    },
    {
      title: '操作',
      width: 100,
      key: 'options',
      fixed: 'right',
      render: (row) => {
        return (
          <>
            {/* <Button type="text" onClick={() => toLowCodeFram(row)}>页面配置</Button> */}
            {(row.hasbtnList?.includes(btnKeys.statusChange) || isButton) && (
              <Button
                type="text"
                onClick={() => {
                  setStatusVisible(true);
                  setStatusData([row.taskId]);
                }}
              >
                状态
              </Button>
            )}
            {(row.hasbtnList?.includes(btnKeys.edit) || isButton) && (
              <Button
                type="text"
                onClick={() => showEditModal({ ...row, _type: 'edit' })}
              >
                编辑
              </Button>
            )}
            {(row.hasbtnList?.includes(btnKeys.delete) || isButton) && (
              <>
                <br />
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delRole({ taskId: row.taskId })}
                >
                  删除
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  return columns;
};

/** 编辑框表单项 */
export const getEdtaFormItem = ({
  servCatgList,
  editModalData,
  curData,
  employeeAll,
  modalRef,
  leaderChange,
  leaderKey,
}) => {
  const edtaFormItem = [
    {
      label: '上级类目',
      name: 'proServCatgId',
      type: 'TreeSelect',
      required: '请选择上级类目',
      props: {
        placeholder: '上级类目',
        treeData:
          servCatgList[editModalData?.projectId || curData.current?.projectId],
        treeDefaultExpandAll: true,
        onSelect: (_key, { data }) => {
          // 选择上级类目将负责人与参与人员带到表单里面
          let addTeamMemberList = data.dbServCatgTeamMemberList?.map(
            (member) => findTree(employeeAll, member.teamMemberId)?.value,
          );
          let taskLeader = findTree(employeeAll, data.servCatgLeader)?.value;
          modalRef.current?.form.setFieldsValue({
            addTeamMemberList,
            taskLeader,
          });
        },
        showSearch: true,
        filterTreeNode: (inputValue, treeNode) =>
          selectPinyinFilter(treeNode.title, inputValue),
        // disabled: true,
        // value:curData.current
      },
    },
    {
      label: '选择责任人',
      name: 'taskLeader',
      type: 'Select',
      props: {
        placeholder: '请选择责任人',
        options: employeeAll,
        onChange: leaderChange,
      },
    },
    {
      label: '选择参与团队',
      name: 'addTeamMemberList',
      type: 'Select',
      props: {
        placeholder: '请选择参与团队',
        mode: 'multiple',
        checkAlldisabledKey: '999999',
        maxTagCount: 'responsive',
        options: deepClone(employeeAll).map((item) => {
          if (leaderKey?.split('-')[0] == item.value) {
            item.options.find((op) => op.value == leaderKey).disabled = true;
          }
          return item;
        }),
      },
    },
    {
      label: '任务执行周期',
      name: 'taskTime',
      type: 'DatePickerRangePicker',
      props: {},
    },
    {
      label: '所用工期/天',
      name: 'taskWorkPeriod',
      type: 'Input',
      props: {
        placeholder: '请输入所用工期',
        // disabled: true,
        style: { width: '100%' },
      },
    },
    {
      label: '任务名称',
      name: 'taskName',
      type: 'Input',
      required: '请输入任务名称',
      formItemProps: {
        className: 'block-item',
      },
      props: {
        placeholder: '请输入任务名称',
      },
    },
    {
      label: '任务详情描述',
      name: 'taskRemark',
      type: 'TextArea',
      formItemProps: {
        className: 'block-item',
      },
      props: {
        placeholder: '请输入任务详情描述',
      },
    },
  ];
  return edtaFormItem;
};

/** 搜索框表单项 */
export const getSearchCloumns = ({ employeeAll, servcatgList }) => {
  const searchCloumns = [
    {
      name: 'memberList',
      type: 'Select',
      props: {
        placeholder: '请选择团队成员',
        mode: 'multiple',
        allowClear: true,
        maxTagCount: 'responsive',
        options: employeeAll,
      },
    },
    {
      name: 'taskTime',
      type: 'DatePickerRangePicker',
      props: {
        placeholder: ['任务开始时间', '任务结束时间'],
        style: { width: '100%' },
      },
    },
    {
      name: 'taskName',
      type: 'Input',
      props: {
        placeholder: '任务名称',
      },
    },
    {
      name: 'proServCatgId',
      type: 'TreeSelect',
      props: {
        placeholder: '工作包',
        treeData: servcatgList,
      },
    },
  ];
  return searchCloumns;
};
