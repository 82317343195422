import { InputNumber } from 'kenshin';
import { useEffect, useState } from 'react';
import styles from './styles.less';

const options = [['次年', { key: 'month' }, '月', { key: 'day' }, '日，清空']];

const Validity = ({ onChange, value }) => {
  const [handleValue, setHandleValue] = useState([{ month: '', day: 0 }]);

  useEffect(() => {
    if (value) {
      let [month, day] = value.split('-');
      setHandleValue([{ month, day }]);
    }
  }, [value]);

  const handleChange = (index, key, val) => {
    let list = handleValue;
    list[index][key] = val;
    let m = list[0]?.month?.toString().padStart(2, '0');
    let d = list[0]?.day?.toString().padStart(2, '0');
    onChange?.(`${m}-${d}`);
  };

  const createBox = (arr, i) => {
    return arr.map((item, key) => {
      if (typeof item === 'string') {
        return <span key={key}>{item}</span>;
      } else {
        return (
          <InputNumber
            min={1}
            max={key == 'month' ? 12 : 31}
            key={key}
            stepType="inside"
            value={handleValue[i][item?.key]}
            onChange={(val) => handleChange(i, item.key, val)}
          />
        );
      }
    });
  };

  return (
    <div className={styles.borderBox}>
      {options.map((arr, i) => (
        <div className={styles.flexBoxCenter} key={i}>
          {createBox(arr, i)}
        </div>
      ))}
    </div>
  );
};

export default Validity;
