export const editForm = ({ customer, customerOptions }) => {
  return [
    {
      name: 'caseName',
      label: '案例名称',
      type: 'Input',
      required: '请输入案例名称',
      props: {
        placeholder: '请输入案例名称',
      },
    },
    {
      name: 'caseTitle',
      label: '标题',
      type: 'Input',
      required: '请输入标题',
      props: {
        placeholder: '请输入标题',
      },
    },
    {
      name: 'caseSort',
      label: '排序',
      type: 'InputNumber',
      required: '请输入排序',
      props: {
        placeholder: '请输入排序',
        stepType: 'inside',
      },
    },
    {
      name: 'caseCover',
      label: '封面',
      type: 'UploadImg',
      required: '请输入封面',
      props: {
        dirUrl: '/customer/caselist',
        accept: 'image/*',
      },
    },
    {
      name: 'caseLink',
      label: '链接',
      type: 'Input',
      required: '请输入链接',
      props: {
        placeholder: '请输入链接',
      },
    },
    {
      name: 'caseCustId',
      label: '公司名称',
      type: 'Select',
      required: '请输入公司名称',
      props: {
        placeholder: '请输入公司名称',
        options: customerOptions.current,
      },
    },
    {
      name: 'caseBelongIndustry',
      label: '行业类型',
      type: 'Select',
      required: '请输入行业类型',
      props: {
        placeholder: '请输入行业类型',
        options: customer.caseIndustryList?.map((item) => ({
          label: item.value,
          value: item.id,
        })),
      },
    },
    {
      name: 'caseTagList',
      label: '标签列表',
      type: 'Select',
      required: '请输入标签列表',
      props: {
        placeholder: '请输入标签列表',
        mode: 'multiple',
        options: customer.caseTagList?.map((item) => ({
          label: item.value,
          value: [item.id, item.value, item.extendData].join('-'),
        })),
      },
    },
    {
      name: 'caseBriefDescribe',
      label: '文本描述',
      type: 'TextArea',
      props: {
        placeholder: '请输入文本描述',
      },
    },
    {
      name: 'caseText',
      label: '简介描述',
      type: 'Editor',
      props: {
        placeholder: '请输入简介描述',
      },
    },
  ];
};
