import { Button, MessageBox, Col, Input, Switch, Row } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { ISLIMIT, SENDTIME, isLimitOptions } from './_util/updateColumns';

const VacationManage = ({ attendance, dispatch, systemOrgani }) => {
  const handleStatusChange = (row) => {
    dispatch({
      type: 'attendance/setvacationstatus',
      payload: {
        vacationId: row.vacationId,
        vacationStatus: +!row.vacationStatus,
      },
    });
  };

  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '你确定要删除吗？',
      onOk: () => {
        dispatch({
          type: 'attendance/deletevacation',
          payload: row,
        }).then((success) => {
          if (success) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const columns = [
    {
      dataIndex: 'vacationName',
      title: '假期名称',
    },
    {
      dataIndex: 'vacationUnit',
      title: '假期单位',
      render(key) {
        let unit = {
          1: '小时',
          2: '天',
        };
        return unit[key];
      },
    },
    {
      dataIndex: 'vacationLimit',
      title: '是否限额',
      render: (val) => {
        let obj = {
          [ISLIMIT.N]: '不限额',
          [ISLIMIT.Y]: '限额',
        };
        return obj[val];
      },
    },
    {
      dataIndex: 'vacationStatus',
      title: '假期状态',
      render: (val, row) => (
        <Switch checked={val == 0} onChange={() => handleStatusChange(row)} />
      ),
    },
    {
      key: 'options',
      title: '操作',
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => handleToUpdate(row)}>
              编辑
            </Button>
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delItem({ vacationId: row.vacationId })}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const handleToUpdate = (row = {}) => {
    if (row) {
      dispatch({
        type: 'attendance/save',
        payload: {
          vacationEditData: row,
        },
      });
    }
    history.push({
      pathname: '/attendance/vacationmanage/updatevacation',
    });
  };

  const extra = () => {
    return (
      <Button type="primary" onClick={() => handleToUpdate()}>
        新增
      </Button>
    );
  };

  return (
    <div>
      <TablePro
        extra={extra}
        request="attendance/getallvacationlist"
        rowKey="vacationId"
        pagination={false}
        dataSource={attendance.allVacationList}
        columns={columns}
        curColumns
      ></TablePro>
    </div>
  );
};

export default connect(({ attendance, loading, systemOrgani }) => ({
  attendance,
  systemOrgani,
  loading: loading.effects,
}))(VacationManage);
