/**
 * item:追加模板后面
 * list:合并项再模板后面
 * arr:覆盖全部
 */

import { history } from 'umi';

const defaultKey = {
  osaName: 'osaName',
  osaModel: 'osaModel',
};

export default ({
  item,
  list,
  arr: propsArr,
  propsKey = defaultKey,
  disabled,
  target,
  osaNameDisabled = true,
} = {}) => {
  let arr = [
    {
      title: '考核方案名称',
      dataIndex: propsKey.osaName,
      valueType: 'Input',
      required: '请输入考核名称',
      fieldProps: {
        disabled: osaNameDisabled,
        placeholder: '请输入考核方案名称',
      },
    },
    {
      title: '考核对象',
      render() {
        return (
          <div>
            {target?.title ??
              target?.userInfo?.value ??
              history.location.query.deptName}
          </div>
        );
      },
    },
    {
      title: '考核模式',
      dataIndex: propsKey.osaModel,
      valueType: 'RadioGroup',
      fieldProps: {
        disabled,
        placeholder: '请输入考核方案名称',
        options: [{ label: '常规指标', value: 1 }],
      },
    },
  ];
  if (propsArr) {
    arr = propsArr;
  }
  if (item) {
    arr.push(item);
  }
  if (list) {
    arr.concat(list);
  }

  return arr;
};
