import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';

const menuConfig = ({ dispatch, loading, lowCode, user }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'lowCode/deleteTemptype',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = (value) => {
    let payload = { ...value };
    let hasEdit = false;
    if (editModalData?.tmptTypeId) {
      hasEdit = true;
      payload.tmptTypeId = editModalData.tmptTypeId;
    }
    dispatch({
      type: 'lowCode/editTemptype',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        message.success('操作成功');
      }
    });
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'tmptTypeName',
      key: 'tmptTypeName',
    },
    {
      title: '编码',
      dataIndex: 'tmptTypeCode',
      key: 'tmptTypeCode',
    },
    {
      title: '排序',
      dataIndex: 'tmptTypeSort',
      key: 'tmptTypeSort',
    },
    {
      title: '模板数量',
      dataIndex: 'tmptNum',
      key: 'tmptNum',
    },
    {
      title: '创建时间',
      dataIndex: 'tmptTypeCrtTime',
      key: 'tmptTypeCrtTime',
      render: (value) => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => showEditModal(row)}>
              编辑
            </Button>
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delRole({ tmptTypeId: row.tmptTypeId })}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'tmptTypeCode',
      type: 'Input',
      props: {
        placeholder: '编码',
      },
    },
    {
      name: 'tmptTypeName',
      type: 'Input',
      props: {
        placeholder: '模板名称',
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '排序',
      name: 'tmptTypeSort',
      type: 'InputNumber',
      props: {
        placeholder: '请输入排序',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: '编码',
      name: 'tmptTypeCode',
      type: 'Input',
      required: '请输入编码',
      props: {
        placeholder: '请输入编码',
        disabled: (row) => !!row.tmptTypeId,
      },
    },
    {
      label: '名称',
      name: 'tmptTypeName',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
  ];

  const extra = (
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增模板
    </Button>
  );

  return (
    <>
      <TablePro
        request="lowCode/getTemptypeList"
        rowKey={'tmptTypeId'}
        columns={columns}
        searchCloumns={searchCloumns}
        data={lowCode.temptypeList}
        extra={extra}
      />

      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </>
  );
};

menuConfig.title = '菜单配置';

export default connect(({ lowCode, loading, user }) => {
  return {
    user,
    lowCode,
    loading: loading.effects,
  };
})(menuConfig);
