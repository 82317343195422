import { LeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { history } from 'umi';
import { Skeleton } from 'kenshin';

// let defaultUrl = "http://183.6.7.218:8092/dist/index.html#/home"
let defaultUrl = iframeUrl;

const toUrlParams = (obj) => {
  return Object.keys(obj)
    .map((item) => `${item}=${obj[item]}`)
    .join('&');
};

const LowCode = () => {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let resUrl = `${defaultUrl}?${toUrlParams(history.location.query)}`;
    setUrl(resUrl);
  }, [location.hash]);

  const iframeLoad = () => {
    setLoading(false);
  };

  return (
    <div className="lowCode" style={{ overflow: 'hidden', height: '100vh' }}>
      <span
        onClick={() => history.goBack()}
        style={{ cursor: 'pointer', marginLeft: 20 }}
      >
        <LeftOutlined />
        返回
      </span>
      {loading && <Skeleton active paragraph={{ rows: 6 }} />}
      <iframe
        width="100%"
        frameBorder="0"
        height="100%"
        src={url}
        onLoad={iframeLoad}
        scrolling="no"
      >
        您还在使用远古时期的浏览器，请升级浏览器
      </iframe>
    </div>
  );
};

export default LowCode;
