import { useEffect, useRef, useState } from 'react';
import { Tabs, Input, message } from 'kenshin';

import {
  exportemposaindexdata,
  queryosadirectbusiscoregrade,
} from '@/_serveice/performance/osa';
export const scoreTypeKey = {
  SUMMER_WEEK_PLAN_ONE_NODE: 'osaSelfRemark',
  SCORE_ONE_NODE: 'osaSelfRemark',
  SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectLeaderRemark',
  SCORE_TWO_NODE: 'osaDirectLeaderRemark',
  SCORE_THREE_NODE: 'osaBusiLeaderRemark',
};

const JobEvaluation = ({
  curData,
  onChange,
  superiorShow = true,
  disabled,
}) => {
  const [activeKey, setActiveKey] = useState('');
  const once = useRef(true);
  useEffect(() => {
    if (curData.curnNodeNumber && once.current) {
      let keyObj = {
        SCORE_WEEK_PLAN_ONE_NODE: 'SCORE_TWO_NODE', // 上级
        SUMMER_WEEK_PLAN_ONE_NODE: 'SCORE_ONE_NODE', //  自评
      };
      let key = 'SCORE_ONE_NODE';
      if (Object.keys(scoreTypeKey).includes(curData.curnNodeNumber)) {
        key = keyObj[curData.curnNodeNumber] ?? curData.curnNodeNumber;
      }
      setActiveKey(key);
      once.current = false;
    }
  }, [curData]);
  const disabledTextArea = () => {
    if (disabled) {
      return true;
    }
    if (activeKey == 'SCORE_ONE_NODE') {
      const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
      if (localEmpId != curData.osaMuId) return true;
      return ![
        'SUMMER_WEEK_PLAN_ONE_NODE',
        'SCORE_ONE_NODE',
        'SCORE_WEEK_PLAN_ONE_NODE',
      ].includes(curData.curnNodeNumber);
    }
    if (activeKey == 'SCORE_TWO_NODE') {
      const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
      if (localEmpId != curData.directLeader?.id) return true;
      return !['SCORE_TWO_NODE', 'SCORE_WEEK_PLAN_ONE_NODE'].includes(
        curData.curnNodeNumber,
      );
    }
    if (activeKey == 'SCORE_THREE_NODE') {
      return !['SCORE_THREE_NODE'].includes(curData.curnNodeNumber);
    }
    return true;
  };

  const handleChange = async (value) => {
    let typeKey = {
      SCORE_ONE_NODE: 1,
      SCORE_THREE_NODE: 2,
      GRADE_NODE: 3,
    };
    let hasNext = true;
    if (
      curData.curnNodeNumber == 'SCORE_WEEK_PLAN_ONE_NODE' &&
      activeKey == 'SCORE_ONE_NODE'
    ) {
      hasNext = await queryosadirectbusiscoregrade({
        osaId: curData.osaId,
        type: 1,
      }).then((res) => !res.data);
    }
    if (!hasNext) {
      message.error('下个节点评分人已评分，当前不能修改评分或备注');
      return;
    }
    onChange?.(value);
  };

  return (
    <div className="border-card">
      <Tabs
        activeKey={activeKey}
        titleText="卡片标题"
        type="card"
        onChange={setActiveKey}
      >
        {superiorShow && (
          <Tabs.TabPane tab="总结(自评)" key="SCORE_ONE_NODE"></Tabs.TabPane>
        )}
        <Tabs.TabPane tab="总结(上级)" key="SCORE_TWO_NODE"></Tabs.TabPane>
        {curData.osaWeekType == 3 && superiorShow && (
          <Tabs.TabPane
            tab="总结(业务负责人) "
            key="SCORE_THREE_NODE"
          ></Tabs.TabPane>
        )}
      </Tabs>
      <Input.TextArea
        autoSize={{ minRows: 2, maxRows: 6 }}
        placeholder="请输入内容"
        key={activeKey}
        disabled={disabledTextArea()}
        defaultValue={curData[scoreTypeKey[activeKey]]}
        onBlur={handleChange}
      />
    </div>
  );
};
export default JobEvaluation;
