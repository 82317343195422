import { connect, history } from 'umi';
import Search from './search';
import { Radio, Button } from 'kenshin';
import AnalyseCharts from '@/components/AnalyseCharts';
import { useEffect, useState } from 'react';
import {
  statTypeOptions,
  dataTypeOptions,
  chartTransform,
  createTableHeader,
  createDataSource,
  DATATYPEKEY,
  INFOKEY,
  EMPTYPE,
} from './staticData';
import TablePro from '@/components/TablePro';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportosaquarterstatdata } from '@/_serveice/dataAnalyse';
import styles from './styles.less';

const defaultOsaTime = () => {
  return [
    moment().subtract(3, 'quarter').startOf('quarter'),
    moment().endOf('quarter'),
  ];
};

const dataRule = (arr) => {
  // if (dataType != DATATYPEKEY.ALL) {
  //   return {
  //     xData: [],
  //     data: [],
  //   }
  // }
  if (!arr[0]) {
    return {};
  }
  let resultArr = arr[0]?.statItemList;
  const xData = resultArr.map((item) => item.statTime);
  const getDataForkey = (key) =>
    resultArr.map((item) => parseInt(item[key]) || 0);
  return {
    xData,
    data: [
      {
        name: '平均分',
        type: 'bar',
        color: '#ffd86e',
        data: getDataForkey('avgScore'),
      },
      {
        name: '升降率',
        type: 'line',
        color: '#4389f9',
        data: getDataForkey('upDownPercent'),
      },
    ],
  };
};

const OsaAnalyse = ({ dataAnalyse, dispatch, loadingEf }) => {
  const [statType, setStatType] = useState('1');
  const [dataType, setDataType] = useState(DATATYPEKEY.ALL);
  const [infoChecked, setInfoChecked] = useState(INFOKEY.OSA);
  const [osaTime, setOsaTime] = useState(defaultOsaTime());
  const [empType, setEmpType] = useState(EMPTYPE.ALL);

  useEffect(() => {
    dispatch({
      type: 'dataAnalyse/queryosaquaterallstatdata',
      payload: {
        osaStatBeginTime: osaTime[0].format('YYYY-MM-DD HH:mm:ss'),
        osaStatEndTime: osaTime[1].format('YYYY-MM-DD HH:mm:ss'),
        statType,
        empType,
      },
      dataType,
      infoChecked,
      chartTransform,
    });
  }, [statType, dataType, osaTime, infoChecked, empType]);

  let columns = createTableHeader(dataAnalyse.osaTableHeader, {
    dataType,
    infoChecked,
  });

  const handleExport = () => {
    let payload = {
      osaStatBeginTime: osaTime[0] ? osaTime[0].format('YYYY-MM-DD') : '',
      osaStatEndTime: osaTime[1] ? osaTime[1].format('YYYY-MM-DD') : '',
      osaType: infoChecked ?? '',
      statType: statType ?? '',
      type: dataType ?? '',
      empType: empType ?? '',
    };
    let url = `${baseURL}${exportosaquarterstatdata}${splitParamsFoGet(
      payload,
    )}`;
    downloadXlsx(url, '绩效分析');
  };

  return (
    <div className={styles.osaAnalyse}>
      <Search
        time={[osaTime, setOsaTime]}
        info={[infoChecked, setInfoChecked]}
        stat={[statType, setStatType]}
        emptype={[empType, setEmpType]}
      />
      <Radio.Group
        options={statTypeOptions(infoChecked)}
        onChange={(e) => setStatType(e.target.value)}
        value={statType}
        optionType="button"
      />
      <AnalyseCharts {...dataRule(dataAnalyse.osaChartData)} unit="分" />
      <Radio.Group
        options={dataTypeOptions}
        onChange={(e) => setDataType(e.target.value)}
        value={dataType}
        optionType="button"
      />
      <Button
        type="primary"
        key="noKeys"
        style={{ marginLeft: 10, height: 40 }}
        onClick={handleExport}
      >
        导出
      </Button>
      <TablePro
        columns={columns}
        loading={loadingEf['dataAnalyse/queryosaquaterallstatdata']}
        curColumns
        stripe
        columnsOptions={false}
        bordered
        scrollX
        dataSource={createDataSource(dataAnalyse.osaStatData)}
        rowKey={(item, index) =>
          [item.deptId, item.statTime, index].filter(Boolean).join('-')
        }
      />
    </div>
  );
};

export default connect(({ dataAnalyse, loading, system }) => ({
  dataAnalyse,
  system,
  loadingEf: loading.effects,
}))(OsaAnalyse);
