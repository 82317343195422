const getCardObj = (title, num) => {
  return {
    title,
    num: num || 0,
    icon: require('@/_assets/icon-month-work-time.png'),
    size: 'large',
  };
};

export const smallCardList = [
  {
    title: '本月应工作时间',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
  },
  {
    title: '实际工作时间',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    type: 'success',
  },
  {
    title: '迟到',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    type: 'danger',
  },
  {
    title: '早退',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    type: 'danger',
  },
  {
    title: '旷工',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    type: 'danger',
  },
  {
    title: '缺卡',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    type: 'danger',
  },
  {
    title: '外出',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
  },
  {
    title: '请假',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    type: 'danger',
  },
];

export const getInfoList = (data) => {
  let resArr = [
    '迟到-lateCount-次',
    '早退-leaveEarlyCount-次',
    '旷工-absenteeismCount-次',
    '补卡-forgetClockApplyCount-次',
    '外出-gooutApplyCount-次',
    '请假-vacationApplyCount-次',
  ];

  return resArr.map((item) => {
    const [title, key] = item.split('-');
    return getCardObj(title, data[key]);
  });
};

export const getUsercenterList = (data) => {
  let resArr = [
    '本周工时-lateCount-小时',
    '参与项目-leaveEarlyCount-个',
    '参与工作包-absenteeismCount-个',
    '剩余调休时间-holidayEnableDayThisYear-天',
    '剩余年假时间-annualEnableDayThisYear-天',
    '本月加班时长-overtimeMonthDays-天',
  ];

  return resArr.map((item) => {
    const [title, key] = item.split('-');
    return getCardObj(title, data[key]);
  });
};

export const largerCardList = (data) => [
  {
    title: '本年度加班时长',
    num: 22.6,
    icon: require('@/_assets/icon-month-work-time.png'),
    size: 'large',
    desc: '3月31日到期',
  },
  {
    title: '本月加班时长',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    size: 'large',
    desc: '3月31日到期',
  },
  {
    title: '剩余调休时间',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    size: 'large',
    desc: '3月31日到期',
  },
  {
    title: '剩余年假时间',
    num: 22,
    icon: require('@/_assets/icon-month-work-time.png'),
    size: 'large',
    desc: '3月31日到期',
  },
];
