import { Button } from 'kenshin';
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import ChooseModal from './ChooseModal';
import { useSelector } from 'umi';
import { createFindTreeFn } from '../../_util/util';

export const findDept = createFindTreeFn('child', 'deptId');

const ChooseDePartment = memo(
  forwardRef((props, ref) => {
    const [chooseVisible, setChooseVisible] = useState(false);
    const systemOrgani = useSelector(({ systemOrgani }) => systemOrgani);

    const { children, onChange, value, ...resProps } = props;
    if (children) {
      return children;
    }
    return (
      <>
        <Button
          type="primary"
          onClick={() => {
            setChooseVisible(true);
          }}
        >
          选择部门
        </Button>
        {Array.isArray(value) && (
          <span style={{ marginLeft: 8 }}>
            {value?.map((item) => item.value).join('、')}
          </span>
        )}
        {(typeof value == 'string' || typeof value == 'number') && (
          <span style={{ marginLeft: 8 }}>
            {findDept([systemOrgani.departmentList], value)?.deptName}
          </span>
        )}
        <ChooseModal
          visible={chooseVisible}
          value={value}
          onCancel={() => setChooseVisible(false)}
          onSave={(val) => {
            onChange?.(val);
            // currentValue.current = val;
            setChooseVisible(false);
          }}
          {...resProps}
        />
      </>
    );
  }),
);

export default ChooseDePartment;
