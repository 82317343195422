import React from 'react';
import './index.less';

export default () => {
  return (
    <div className="page-default-loading">
      <main>
        <svg width="340" height="120">
          <defs>
            <linearGradient id="yanse">
              <stop offset="33%" stopColor="#00BFFF">
                <animate
                  attributeName="stop-color"
                  from="#00BFFF"
                  to="#FF8C00"
                  dur="5s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
              <stop offset="66%" stopColor="#00FFFF"></stop>
              <stop offset="100%" stopColor="#00FA9A">
                <animate
                  attributeName="stop-color"
                  from="#00FA9AF"
                  to="#FF00FF"
                  dur="5s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
            </linearGradient>
          </defs>
          <text x="30" y="120" className="txt">
            WDP
          </text>
        </svg>
      </main>
    </div>
  );
};
