import { Table } from 'kenshin';
import styles from './weekCalendar.less';
import { flat } from '@/_util/util';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, MessageBox, message } from 'kenshin';
import { useEffect, useState } from 'react';
import WeekEdit from './weekEdit';

function arrToObj(arr) {
  // let obj = {};
  // arr.forEach((item,index) => {
  //   obj[index] = item
  // })
  // return obj

  return arr.reduce((obj, cur, index) => {
    obj[index] = cur;
    return obj;
  }, {});
}

// 转换树结构的数据
function treeDataToTable(tree) {
  if (Array.isArray(tree)) {
    return flat(
      tree.map((item) => {
        return item.osaWeekMonthList.map((item, index) => {
          return arrToObj(item.osaWeekDateList);
        });
      }),
      2,
    );
  }
  return [];
}
function getYMD(momentTime) {
  return momentTime.format('MM-DD');
}

const WeekCalendar = ({ value, treeData, disabled, dispatch, year }) => {
  // let titleArr = ['第一周', '第二周', '第三周', '第四周', '第五周'];
  const [editVisible, setEditVisible] = useState(false);
  const [editRows, setEditRows] = useState(null);

  const handleShowEdit = (item = {}) => {
    setEditVisible(true);
    let formData = {
      ...item,
      osaWeekPlanYear: year,
      osaWeekPlanDate: item?.osaWeekPlanMonth,
    };
    if (item.osaWeekPlanBeginDate) {
      formData.osaWeekPlanTime = [
        moment(item.osaWeekPlanBeginDate),
        moment(item.osaWeekPlanEndDate),
      ];
    }
    setEditRows(formData);
  };

  const handleDelItem = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '你确定要删除吗？',
      onOk: () => {
        dispatch({
          type: 'performance/deleteweekplandatedata',
          payload,
        }).then((success) => {
          if (success) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const timeRender = (item, row) => {
    let arr = Object.values(row);
    let lastKey = arr[arr.length - 1]?.osaWeekPlanDateId;
    if (item) {
      let time = [
        getYMD(moment(item.osaWeekPlanBeginDate)),
        getYMD(moment(item.osaWeekPlanEndDate)),
      ];
      let isCurData = moment().isBetween(
        moment(item.osaWeekPlanBeginDate),
        moment(item.osaWeekPlanEndDate),
      );
      let style = {
        backgroundColor: isCurData ? '#eff5ff' : undefined,
      };
      return (
        <div style={style} className="time-item">
          {time.join(' ~ ')}
          <EditOutlined
            style={{ color: '#4389f9', margin: '0 10px' }}
            onClick={() => handleShowEdit(item)}
          />
          {lastKey == item.osaWeekPlanDateId && (
            <DeleteOutlined
              style={{ color: 'red' }}
              onClick={() =>
                handleDelItem({ osaWeekPlanDateId: item.osaWeekPlanDateId })
              }
            />
          )}
        </div>
      );
    }
    return `-`;
  };
  const columns = [
    {
      title: '#',
      dataIndex: 'month',
      width: '10%',
      render(_, row, index) {
        return <div>{`${index + 1}月`}</div>;
      },
    },
    {
      title: '第一周',
      dataIndex: 0,
      width: '18%',
      render: timeRender,
    },
    {
      title: '第二周',
      dataIndex: 1,
      width: '18%',
      render: timeRender,
    },
    {
      title: '第三周',
      dataIndex: 2,
      width: '18%',
      render: timeRender,
    },
    {
      title: '第四周',
      dataIndex: 3,
      width: '18%',
      render: timeRender,
    },
    {
      title: '第五周',
      dataIndex: 4,
      width: '18%',
      render: timeRender,
    },
  ];

  const handleSave = (value) => {
    let monthOptional = [
      value.osaWeekPlanTime[0].month() + 1,
      value.osaWeekPlanTime[1].month() + 1,
    ];
    if (!monthOptional.includes(value.osaWeekPlanDate)) {
      message.error(`当前日期区间不存在${value.osaWeekPlanDate}月`);
      return;
    }
    let payload = {
      osaWeekPlanBeginDate: value.osaWeekPlanTime[0]
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss'),
      osaWeekPlanEndDate: value.osaWeekPlanTime[1]
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss'),
      osaWeekPlanMonth: value.osaWeekPlanDate,
      osaWeekPlanQuarter: Math.ceil(value.osaWeekPlanDate / 3),
      osaWeekPlanYear: editRows.osaWeekPlanYear,
      osaWeekPlanDateId: editRows.osaWeekPlanDateId,
    };
    dispatch({
      type: 'performance/addupdateweekplandatedata',
      payload,
    }).then((res) => {
      if (res) {
        setEditVisible(false);
        setEditRows(null);
      }
    });
  };

  return (
    <div className={styles.WeekCalendar}>
      <Button
        type="primary"
        onClick={() => handleShowEdit()}
        style={{ marginBottom: 10 }}
      >
        新增
      </Button>
      <Table
        bordered
        columns={columns}
        rowKey={(_, i) => i}
        pagination={false}
        dataSource={treeDataToTable(treeData)}
      />
      {/* <div className="title-box">
        {titleArr.map((item) => (
          <span className='title' key={item}>{item}</span>
        ))}
      </div> */}
      <WeekEdit
        data={editRows}
        timelist={treeData}
        visible={editVisible}
        onSave={handleSave}
        onCancel={() => {
          setEditVisible(false);
          setEditRows(null);
        }}
      />
    </div>
  );
};

export default WeekCalendar;
