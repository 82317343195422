import React, { useState, useEffect, useMemo } from 'react';
import { getCode, encrypt, decrypt } from '../_util';
import { Form, Input, Button, Row, Col, Checkbox, MessageBox } from 'kenshin';
import styles from './index.less';
import { connect } from 'umi';
import useLoaderDispatch from '../../../layouts/hook/useLoaderDispatch';

const Login = (props) => {
  const { dispatch, loading } = props;
  const [code] = useState({
    uniqueKey: '',
    src: '', // 验证码图片路径
    interval: 60, // 刷新时间
    timer: null, // 刷新验证码定时器
  });

  const [remember, setRemember] = useState(!!localStorage.getItem('userAorP'));

  const [codeSrc, setCodeSrc] = useState('');

  const generateCode = () => {
    Object.assign(code, getCode());
    setCodeSrc(code.src);
    clearTimeout(code.timer);
    // 定时刷新验证码
    code.timer = setTimeout(() => {
      generateCode();
    }, code.interval * 1000);
    return code;
  };

  /** 清空状态缓存数据 */
  const clearDva = () => {
    dispatch({
      type: 'usercenter/init',
    });
  };

  useEffect(() => {
    generateCode();
    clearDva();
    dispatch({
      type: 'usercenter/save',
      payload: {
        homeEmpWorkRptList: {
          key: [],
          list: [],
        },
      },
    });
    return () => {
      clearTimeout(code.timer);
    };
  }, []);

  const onFinish = (values) => {
    let params = {
      ...values,
      uniqueKey: code.key,
      empNumber: encrypt(values.empNumber),
      empLoginPwd: encrypt(values.empLoginPwd),
    };
    dispatch({
      type: 'user/login',
      remember,
      hasDefaultPwd: values.empLoginPwd == '123456',
      payload: params,
    }).then((res) => {
      if (res) {
        localStorage.setItem('pcDeviceCode', code.key);
        useLoaderDispatch(dispatch);
        // if(!res.employeeDTO.empBusiLeaderId){
        //   MessageBox.confirm({
        //     title: '系统提示',
        //     content: '当前用户暂无业务负责人，请联系管理员添加业务负责人',
        //   });
        // }
      }
      generateCode(); //刷新验证码
    });
  };
  let userAorP = JSON.parse(localStorage.getItem('userAorP') ?? '{}');
  return (
    <>
      <div className={styles.videoBox}>
        <video
          src={`${ossUrl}/${ossDirUrl}/video/login.mp4`}
          loop
          muted
          preload="auto"
          autoPlay
        ></video>
      </div>
      <div className={styles.loginBox}>
        <div className={styles.loginForm}>
          <div className="segment">
            <h1>微数平台</h1>
          </div>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              // empNumber: 'A0001',
              empNumber: decrypt(userAorP.empNumber),
              empLoginPwd: decrypt(userAorP.empLoginPwd),
            }}
          >
            <Form.Item
              name="empNumber"
              rules={[{ required: true, message: '请填写账号!' }]}
            >
              <Input placeholder="账号" />
            </Form.Item>

            <Form.Item
              name="empLoginPwd"
              rules={[{ required: true, message: '请输入密码!' }]}
            >
              <Input.Password placeholder="密码" visibilityToggle={false} />
            </Form.Item>
            <Form.Item
              name="captcha"
              rules={[{ required: true, message: '请输入验证码!' }]}
            >
              <Row>
                <Col>
                  <Input placeholder="验证码" />
                </Col>
                <Col>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={generateCode}
                    src={codeSrc}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col>
                  <Checkbox
                    checked={remember}
                    onChange={(e) => setRemember(e.target.checked)}
                  >
                    记住密码
                  </Checkbox>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                style={{ width: '100%' }}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default connect(({ loading }) => ({ loading: loading.models.user }))(
  Login,
);
