import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Timeline,
  Tag,
} from 'kenshin';
import { useSelector } from 'umi';

const defaultShowType = ['osaType', 'osaWeekType', 'time'];

let searchTimer = null;
const CollapseSearch = ({ onChange, showType = defaultShowType }) => {
  const performance = useSelector(({ performance }) => performance);
  const [osaTime, setOsaTime] = useState([]);
  const [timeType, setTimeType] = useState('0');
  const [typeChecked, setTypeChecked] = useState('');
  const [infoChecked, setInfoChecked] = useState('');

  const timeSearch = ({ time }) => {
    setTimeType(time);
    const [amount, unit] = time.split('-');
    const start = moment().subtract(amount, unit).startOf('date');
    const end = moment().endOf('date');
    setOsaTime([start, end]);
    const osaBeginTime = start.format('YYYY-MM-DD HH:mm:ss');
    const osaEndTime = end.format('YYYY-MM-DD HH:mm:ss');
    onChange?.({ osaBeginTime, osaEndTime });
  };
  const timeChange = (times) => {
    setOsaTime(times);
    let osaBeginTime = undefined;
    let osaEndTime = undefined;
    if (times) {
      const [start, end] = times;
      osaBeginTime = start.startOf('d').format('YYYY-MM-DD HH:mm:ss');
      osaEndTime = end.endOf('d').format('YYYY-MM-DD HH:mm:ss');
    } else {
      setTimeType('0');
    }
    onChange?.({ osaBeginTime, osaEndTime });
  };

  // 表单项配置：信息类别、考核类型
  const [TypeOptions, infoOptions] = useMemo(() => {
    const obj = performance.osaSearchNum ?? {};
    const TypeOptions = [
      {
        label: `全部(${obj.osaTotal ?? 0})`,
        value: '',
      },
      {
        label: `试用期转正评估(${obj.probationToRegular ?? 0})`,
        value: '1',
      },
      {
        label: `绩效评估(${obj.osaEvaluate ?? 0})`,
        value: '2',
      },
      {
        label: `绩效改进评估(${obj.osaImproveEvaluate ?? 0})`,
        value: '3',
      },
      {
        label: `个人发展计划(${obj.personDevolopPlan ?? 0})`,
        value: '4',
      },
    ];
    const infoOptions = [
      {
        label: `全部(${
          (obj.osaQuarterCount || 0) + (obj.osaWeekPlanCount || 0)
        })`,
        value: '',
      },
      {
        label: `OSA季度绩效(${obj.osaQuarterCount ?? 0})`,
        value: '3',
      },
      {
        label: `周计划(${obj.osaWeekPlanCount ?? 0})`,
        value: '1',
      },
    ];
    return [TypeOptions, infoOptions];
  }, [performance.osaSearchNum]);

  const timeOptions = [
    {
      label: '一周',
      value: '1-week',
    },
    {
      label: '一个月',
      value: '1-month',
    },
    {
      label: '两个月',
      value: '2-month',
    },
    {
      label: '三个月',
      value: '3-month',
    },
    {
      label: '半年',
      value: '6-month',
    },
    {
      label: '一年',
      value: '12-month',
    },
  ];

  // 搜索事件
  const handleSearch = (e) => {
    e.stopPropagation();
    let value = e.target.value;
    let payload = { key: value };

    if (searchTimer) {
      clearTimeout(searchTimer);
      searchTimer = null;
    }

    searchTimer = setTimeout(() => {
      onChange?.(payload);
    }, 800);
  };

  const headerRender = (
    <div style={{ display: 'flex', width: '96%' }}>
      <Input
        placeholder="请输入绩效名称/员工名称"
        allowClear
        style={{ width: 200 }}
        onClick={(e) => e.stopPropagation()}
        onChange={handleSearch}
      />
      {/* <div className='button_right'> */}
      <div style={{  position: 'absolute',right: '20px' }}>
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            onChange?.();
          }}
        >
          查询
        </Button>
      </div>
    </div>
  );

  return (
    <Collapse
      className="template-card"
      defaultActiveKey={'1'}
      style={{ marginBottom: 10 }}
    >
      <Collapse.Panel header={headerRender} name="detail" key={'1'}>
        {showType.includes('osaType') && (
          <Row style={{ marginBottom: 10, alignItems: 'center' }}>
            <Col flex="60px">考核类型：</Col>
            <Col flex="auto">
              {/* <Radio.Group
                defaultValue=""
                onChange={(e) => onChange?.({ osaType: e.target.value })}
                optionType="text"
                options={TypeOptions}
              /> */}
              {TypeOptions.map((item) => (
                <Tag.CheckableTag
                  style={{ marginLeft: 4 }}
                  key={item.value}
                  checked={item.value == typeChecked}
                  onChange={(checked) => {
                    onChange?.({ osaType: item.value });
                    setTypeChecked(item.value);
                  }}
                >
                  {item.label}
                </Tag.CheckableTag>
              ))}
            </Col>
          </Row>
        )}
        {showType.includes('osaWeekType') && (
          <Row style={{ marginBottom: 10, alignItems: 'center' }}>
            <Col flex="60px">信息类别：</Col>
            <Col flex="auto">
              {/* <Radio.Group
                defaultValue=""
                optionType="text"
                onChange={(e) => onChange?.({ osaWeekType: e.target.value })}
                options={infoOptions}
              /> */}
              {infoOptions.map((item) => (
                <Tag.CheckableTag
                  style={{ marginLeft: 4 }}
                  key={item.value}
                  checked={item.value == infoChecked}
                  onChange={(checked) => {
                    onChange?.({ osaWeekType: item.value });
                    setInfoChecked(item.value);
                  }}
                >
                  {item.label}
                </Tag.CheckableTag>
              ))}
            </Col>
          </Row>
        )}
        {showType.includes('time') && (
          <Row style={{ alignItems: 'center' }}>
            <Col flex="60px">日期范围：</Col>
            <Col flex="auto">
              {/* <Radio.Group
                value={timeType}
                onChange={(e) => timeSearch({ time: e.target.value })}
                optionType="text"
                options={timeOptions}
              /> */}
              {timeOptions.map((item) => (
                <Tag.CheckableTag
                  style={{ marginLeft: 4 }}
                  key={item.value}
                  checked={item.value == timeType}
                  onChange={(checked) => {
                    timeSearch({ time: item.value });
                  }}
                >
                  {item.label}
                </Tag.CheckableTag>
              ))}
              <DatePicker.RangePicker
                value={osaTime}
                onChange={timeChange}
                style={{ marginLeft: 4 }}
              />
            </Col>
          </Row>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseSearch;
