import { Button, Col, Input, message, Row, Upload } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import {
  downloadimportemployeevacationtemplate,
  importemployeevacationdata,
} from '@/_serveice/attendance';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import WdpAvatar from '@/components/Avatar';
import { useState, useRef } from 'react';
import { exportemployeevacationlist } from '../../../_serveice/attendance';
import { useReactive } from 'ahooks';
import ModalFormPro from '@/components/ModalFormPro';

const VacationBalance = ({ attendance, dispatch, systemOrgani }) => {
  let token = localStorage.getItem('token');
  let pcDeviceCode = localStorage.getItem('pcDeviceCode');
  let muId = localStorage.getItem('muId');
  const [importTime, setImportTime] = useState(moment());
  const tableRef = useRef();
  const state = useReactive({
    asyncVisible: false,
  });
  /** 查看详情 */
  const toDetail = (row) => {
    dispatch({
      type: 'attendance/save',
      payload: {
        holidayDetail: row,
      },
    });
    history.push({
      pathname: '/attendance/vacationbalance/detail',
      query: {
        empId: row.empId,
      },
    });
  };

  /** 获取动态表头 */
  const getDynaColumns = (tableList) => {
    if (!Array.isArray(tableList)) return [];
    return tableList.map((item) => {
      return {
        key: `vacationList-${item.id}`,
        width: 150,
        title: item.value,
        render: (row) => {
          let value = row.vacationList?.find(
            (vacation) => vacation.id == item.id,
          )?.value;
          if (value == '-') return value;
          return value ? `${value}天` : '-';
        },
      };
    });
  };

  const columns = [
    {
      dataIndex: 'empName',
      title: '姓名',
      fixed: 'left',
      width: 200,
      render: (empName, row) => {
        return (
          <div>
            <WdpAvatar url={row.empHeadImg}>{empName}</WdpAvatar>
            {empName}
          </div>
        );
      },
    },
    {
      dataIndex: 'deptName',
      width: 250,
      title: '部门',
    },
    {
      dataIndex: 'empEntryDate',
      width: 200,
      title: '入职时间',
      render: (date) => (date ? moment(date).format('YYYY-MM-DD') : '-'),
    },
    ...getDynaColumns(attendance.vacationbalanceTable),
    {
      key: 'options',
      title: '操作',
      fixed: 'right',
      width: 150,
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => toDetail(row)}>
              查看
            </Button>
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'deptId',
      type: 'DeptSelect',
      props: {
        multiple: false,
      },
    },
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '请输入姓名',
      },
    },
  ];

  const asyncColumns = [
    {
      label: '日期',
      type: 'DatePicker',
      props: {
        allowClear: false,
        picker: 'year',
        value: importTime,
        disabledDate: (current) =>
          current > moment().endOf('year') ||
          current < moment().subtract(1, 'year').startOf('year'),
        onChange: (val) => {
          // console.log(val);
          setImportTime(val);
        },
      },
    },
    {
      label: '文件',
      render: () => (
        <Upload
          action={`${baseURL}`}
          headers={{
            muId: muId,
            accessToken: token,
            pcDeviceCode: pcDeviceCode,
          }}
          customRequest={uploadChange}
          fileList={[]}
        >
          <Button>导入</Button>
        </Upload>
      ),
    },
  ].filter(Boolean);

  /** 导入 */
  const uploadChange = (file) => {
    let fd = new FormData();
    fd.append('file', file.file);
    importemployeevacationdata(fd, importTime.year()).then((res) => {
      if (res.success) {
        message.success('导入成功。请稍后查询数据');
        state.asyncVisible = false;
      }
    });
  };
  function createObjectURL(object) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }
  const handleExport = () => {
    // console.log(tableRef.current.search.getFieldsValue());
    let payload = tableRef.current.search.getFieldsValue();
    // exportemployeevacationlist({ ...payload, deptId: payload.deptId ?? 1 }).then(function(res){
    //   console.log(res);
    // })
    downloadXlsx(
      `${baseURL}${exportemployeevacationlist}`,
      '假期余额.xlsx',
      'post',
      { ...payload, deptId: payload.deptId ?? 1 },
    );
  };

  const extra = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Button onClick={handleExport} type="primary">
          导出
        </Button>

        <Button
          type="primary"
          onClick={() => {
            state.asyncVisible = true;
          }}
        >
          导入
        </Button>
        <Button
          key="noKeys"
          type="text"
          onClick={() => {
            let url = `${baseURL}${downloadimportemployeevacationtemplate}`;
            downloadXlsx(url, '员工列表');
          }}
        >
          下载模板
        </Button>
      </div>
    );
  };

  return (
    <div>
      <TablePro
        searchCloumns={searchCloumns}
        extra={extra}
        request="attendance/getemployeevacationbalancepage"
        rowKey={'empId'}
        scrollX
        actionRef={(ref) => (tableRef.current = ref)}
        data={attendance.vacationbalancePage}
        columns={columns}
        curColumns
      ></TablePro>
      <ModalFormPro
        title="导入数据"
        edtaFormItem={asyncColumns}
        visible={state.asyncVisible}
        onCancel={() => (state.asyncVisible = false)}
      />
    </div>
  );
};

export default connect(({ attendance, loading, systemOrgani }) => ({
  attendance,
  systemOrgani,
  loading: loading.effects,
}))(VacationBalance);
