import request from '@/_util/require';

let version = 'v1';
export async function getCustomerList(options) {
  return request(`/${version}/waper/erp/pc/customer/getcustomerpage`, {
    method: 'POST',
    data: options,
  });
}

export async function customerAdd(options) {
  return request(`/${version}/waper/erp/pc/customer/addcustomer`, {
    method: 'POST',
    data: options,
  });
}

export async function customerSatus(options) {
  return request(`/${version}/waper/erp/pc/customer/customerstatus`, {
    method: 'POST',
    data: options,
  });
}

export async function customerEdit(options) {
  return request(`/${version}/waper/erp/pc/customer/updatecustomer`, {
    method: 'POST',
    data: options,
  });
}

export async function getAllCustomer(params) {
  return request(`/${version}/waper/erp/pc/customer/getcustcategorylist`, {
    method: 'GET',
    params,
  });
}

// 其他
export async function getEasyCustomer(params) {
  return request(`/${version}/waper/erp/pc/customer/getsimplecustomerlist`, {
    method: 'GET',
    params,
  });
}

// 通过客户id查询项目列表
export async function getProjectLByCustId(params) {
  return request(`/${version}/waper/erp/pc/project/getprojectlistbycustid`, {
    method: 'GET',
    params,
  });
}
// 通过客户id查询项目列表
export async function getCustomerTagList(params) {
  return request(`/${version}/waper/erp/pc/customer/getcustomertaglist`, {
    method: 'GET',
    params,
  });
}

// 导出客户列表
export const exportCustomerListUrl = `/${version}/waper/erp/pc/customer/exportcustomerlist`;

/** 分页查询客户案例列表 */
export async function getcustcasepage(options) {
  return request(`/${version}/waper/erp/pc/customer/case/getcustcasepage`, {
    method: 'POST',
    data: options,
  });
}

/** 新增|修改客户案例 */
export async function addupdatecustcase(options) {
  return request(`/${version}/waper/erp/pc/customer/case/addupdatecustcase`, {
    method: 'POST',
    data: options,
  });
}

/** 查询案例行业列表 */
export async function getcaseindustrylist(params) {
  return request(`/${version}/waper/erp/pc/customer/case/getcaseindustrylist`, {
    method: 'GET',
    params,
  });
}

/** 查询案例标签列表 */
export async function getcasetaglist(params) {
  return request(`/${version}/waper/erp/pc/customer/case/getcasetaglist`, {
    method: 'GET',
    params,
  });
}

/** 发布|取消发布客户案例 */
export async function puborcancelcustcase(options) {
  return request(`/${version}/waper/erp/pc/customer/case/puborcancelcustcase`, {
    method: 'POST',
    data: options,
  });
}

/** 删除客户案例列表 */
export async function deletecustcaselist(params) {
  return request(`/${version}/waper/erp/pc/customer/case/deletecustcaselist`, {
    method: 'GET',
    params,
  });
}
