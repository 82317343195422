import React, { useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import { Button, Timeline, Tooltip, MessageBox, message } from 'kenshin';
import JsonForm from '@/components/JsonForm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deepClone } from '@/_util/util';
import { useReactive } from 'ahooks';
import HolidayOptions from './components/HolidayOptions';
import ModalFormPro from '@/components/ModalFormPro';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  findPreviousNode,
  transformAttendanceApplyDetail,
  transformFileList,
} from './_util';
import {
  auditattendanceprocess,
  fixattendanceapplytime,
  getattendanceapplydetaildata,
  getattendanceauditsystemdescribelist,
  getattendanceprocesstree,
  getmanageallemplist,
  getmygooutapplyauditpasslist,
} from '../../../_serveice/attendance';
import { getEditColumns } from './_columns';
import { timeDiff } from '@/_util';

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

//申请状态：-1:待提交;0：审核中；1：审核通过；2：审核拒绝；3：终止申请；

const ATTENDANCE_APPLY_STATUS = {
  NOSUBMIT: -1, // '待提交'
  AUDITING: 0, // '审核中'
  PASS: 1, // '审核通过'
  REJECT: 2, // '审核拒绝'
  STOP: 3, // '终止申请'
};
let applyStatusStr = {
  [ATTENDANCE_APPLY_STATUS.NOSUBMIT]: '待提交',
  [ATTENDANCE_APPLY_STATUS.AUDITING]: '审核中',
  [ATTENDANCE_APPLY_STATUS.PASS]: '审核通过',
  [ATTENDANCE_APPLY_STATUS.REJECT]: '审核拒绝',
  [ATTENDANCE_APPLY_STATUS.STOP]: '终止申请',
};
const CERTKEY = 'COMMIT:PROVE:MATERIAL';

const showApplyBtn = (curNode) => {
  const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  let muidList =
    curNode?.processApplyNodeHandlerList?.map(
      (item) => item.processNodeHandler,
    ) || [];
  if (muidList.includes(localEmpId)) {
    return true;
  }
  return false;
};

const getOptions = (data) => {
  const options = [];
  data.forEach((item) => {
    if (item.childList) {
      const childList = [];
      item.childList.forEach((child) => {
        childList.push({
          label: child.name,
          value: `${child.id}-${child.code}`,
          remark: child.vacationRemark,
        });
      });
      options.push({
        label: item.name,
        value: item.id,
        childList,
        remark: item.vacationRemark,
      });
    } else {
      options.push({
        label: item.name,
        value: item.id,
        remark: item.vacationRemark,
      });
    }
  });
  return options;
};

const AddApply = ({ dispatch, attendance, loadingEf }) => {
  const ref = useRef();
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListAll, setEmployeeAll] = useState([]);
  const disabled = true;
  const empId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

  const [editModalVisible, setEditModalVisible] = useState(false);
  const auditResult = useRef(AUDITTYPE.SUCCESS);
  const [resultList, setResultList] = useState([]);

  /** 流程图相关的状态 */
  const processState = useReactive({
    curNode: {},
    list: [],
    dots: null,
    curIndex: 0,
    certIndex: -1,
    applyNum: 1,
    backNodeIndex: -1,
  });

  /** 全局的状态 */
  const state = useReactive({
    type: '',
    remark: '',
    typeName: '',
    detailData: {},
    outList: [],
    outType: undefined,
    forgetClockProverDescribe: '',
  });

  useEffect(() => {
    getmanageallemplist().then((res) => {
      setEmployeeList(
        res.data?.map((item) => ({ label: item.value, value: item.id })),
      );
    });
    getResultList();
    // 申请人员公司全体人员数据
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setEmployeeAll(res);
    });
    // 获取申请类型
    dispatch({
      type: 'attendance/getattendanceapplytypelist',
    }).then((applyTypeList) => {
      let processApplyId = history.location.query.id;

      // if (history.location.query.todoItemBusiType == "16" && history.location.query.busiId) {
      //   processApplyId = history.location.query.busiId
      // }

      if (processApplyId != undefined && processApplyId != null)
        getFormData(processApplyId, applyTypeList);
    });
  }, []);

  /** 获取外出时段 */
  const clockApplyTypeChange = (type) => {
    state.outType = type;
    if (type == 3) {
      let forgetClockApplyDate = ref.current.form.getFieldValue(
        'forgetClockActualTime',
      );
      console.log('forgetClockApplyDate...', forgetClockApplyDate);
      getmygooutapplyauditpasslist({
        applyDate: moment(forgetClockApplyDate).format('YYYY-MM-DD'),
      }).then((res) => {
        state.outList = res.data.map((item) => {
          let label = [
            item.applyOutProvinceName,
            item.applyOutCityName,
            item.applyOutAreaName,
            item.applyOutCompany,
          ].join('/');
          let value = [
            item.applyOutProvinceCode,
            item.applyOutCityCode,
            item.applyOutAreaCode,
            item.attendanceApplyId,
            item.applyOutCompany,
          ].join('/');
          return {
            label,
            value,
          };
        });
      });
    }
  };

  // 表单数据回显
  const getFormData = (processApplyId, applyTypeList) => {
    const { form } = ref.current;
    getattendanceapplydetaildata({ processApplyId }).then((res) => {
      let detailData = transformAttendanceApplyDetail(deepClone(res.data), {
        typeSelectChange,
        applyTypeList,
        state,
        isShowStr: true,
      });
      // console.log("详情页。。。", detailData);

      if (!detailData) {
        goback();
        return;
      }

      clockApplyTypeChange(state.outType);
      state.detailData = detailData;
      detailData.processApplyMethod == 2 &&
        (detailData.processApplyMethod = '代他人申请');
      if (detailData.partinList) {
        detailData.partinList = res.data.partinList.map(
          (item) => item.partinName,
        );
      }
      form.setFieldsValue(detailData);
      if (history.location.query.id) {
        //  获取外出时段
        clockApplyTypeChange(state.outType);
      }
      // 流程图相关数据
      getProcessList();
    });
  };

  const getResultList = () => {
    getattendanceauditsystemdescribelist().then((res) => {
      if (res.success) {
        setResultList(
          res.data?.map(
            (item) =>
              ({
                label: item.extendData2,
                value: item.id,
              } || []),
          ) || [],
        );
      }
    });
  };

  /** 获取流程图数据 */
  const getProcessList = () => {
    let processApplyId = history.location.query.id;
    if (history.location.query.busiId) {
      processApplyId = history.location.query.busiId;
    }
    getattendanceprocesstree({ processApplyId }).then((res) => {
      if (res.success && res.data) {
        processState.curNode = res.data.curnProcessNode; // 当前节点
        processState.list = res.data?.processNodeList ?? []; //  流程图列表
        // 驳回节点的索引
        processState.backNodeIndex = res.data?.processNodeList.findIndex(
          (item) => item.processApplyNodeId == res.data.backNodeId,
        );
        let curIndex = processState.list.findIndex(
          //  当前流程索引
          (item) =>
            res.data?.curnProcessNode?.processApplyNodeId ==
            item.processApplyNodeId,
        );
        if (state.detailData?.applyBusiType == 15) {
          processState.certIndex = processState.list.findIndex(
            //  证明材料节点索引
            (item) => item.processNodeNumber == CERTKEY,
          );
        }
        processState.curIndex = curIndex; // 当前流程索引
        processState.dots = (index) => {
          if (index < curIndex || curIndex == processState.list.length - 1)
            return { dotColor: '#0DE894' };
          // else if (index == processState.backNodeIndex) return { dotColor: '#f95520' };
          else if (index == processState.backNodeIndex)
            return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
          else if (index == curIndex) return { dotColor: '#4389f9' };
          else return {};
        };
        // 假如当前节点是上级审核，并且上级跟业务负责人是同一个人
        if (
          processState.curNode?.processNodeNumber == 'DIRECT:LEADER:AUDIT' &&
          processState.list[curIndex + 1]?.processNodeNumber ==
            'DIVISION:LEADER:AUDIT'
        ) {
          let hsaOne =
            processState.curNode?.processApplyNodeHandlerList[0]
              ?.processNodeHandler ==
            processState.list[curIndex + 1]?.processApplyNodeHandlerList[0]
              ?.processNodeHandler;
          if (hsaOne) processState.applyNum = 2;
        }
      }
    });
  };

  const [btnDisabled, setBtnDisabled] = useState(false);

  /** 申请类型title */
  const typeTitle = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip
        overlayStyle={{
          maxWidth: 420,
        }}
        placement="right"
        title={state.remark}
      >
        <ExclamationCircleOutlined style={{ marginRight: 4 }} />
      </Tooltip>
      申请类型
    </div>
  );

  /** 申请类型change事件 */
  const typeSelectChange = (value, row) => {
    state.type = value;
    state.remark = row.remark;
    state.typeName = row.label;
    row.childList && (state.list = row.childList);
  };

  const proveMaterialHandle = () => {
    const { form } = ref.current;
    let datas = form.getFieldsValue();

    let processApplyId = history.location.query.id;
    if (history.location.query.todoItemBusiType == '16') {
      processApplyId = history.location.query.busiId;
    }

    let processApplyNodeId = processState.curNode?.processApplyNodeId;
    let payload = {
      processApplyId,
      processApplyNodeId,
      proveMaterialList: transformFileList(datas.proveMaterialList),
      forgetClockProverDescribe: datas.forgetClockProverDescribe,
    };
    if (
      payload.proveMaterialList?.length == 0 &&
      !datas.forgetClockProverDescribe
    )
      return;
    dispatch({
      type: 'attendance/commitattendanceprovematerial',
      payload,
    });
  };

  const applyTimeDisabled = () => {
    let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
    if (state.detailData?.applyBusiType != 10) return false;
    if (state.detailData?.applyOvertimeType != 1) return false;
    if (processState.curNode.processNodeNumber != 'DIVISION:LEADER:AUDIT')
      return false;
    let hasHandle = processState.curNode.processApplyNodeHandlerList?.some(
      (item) => item.processNodeHandler == empId,
    );
    if (hasHandle) return true;
    return false;
  };

  /** 表单json代码 */
  const edtaFormItem = [
    ...getEditColumns({
      getOptions,
      typeTitle,
      attendance,
      state,
      disabled,
      employeeList,
      employeeListAll,
      typeSelectChange,
      applyTimeDisabled: applyTimeDisabled(),
      isdateValidator: false,
      ref: ref.current,
    }),
    processState.certIndex <= processState.curIndex && {
      // title: '证明材料上传',
      title: '申请附件',
      dataIndex: 'proveMaterialList',
      valueType: 'UploadFile',
      fieldProps: {
        // onChange: proveMaterialHandle,
        disabled: !processState.curNode.processApplyNodeHandlerList?.some(
          (item) => item.processNodeHandler == empId,
        ),
        mode: 'multiple',
        // accept: uploadAccept,
        dirUrl: '/attendance/proveMaterialList',
        multiple: true,
      },
    },
    processState.certIndex <= processState.curIndex && {
      title: '证明信息',
      dataIndex: 'forgetClockProverDescribe',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入证明信息',
        // onChange: state.forgetClockProverDescribe,
        disabled: !processState.curNode.processApplyNodeHandlerList?.some(
          (item) => item.processNodeHandler == empId,
        ),
        // accept: uploadAccept,
      },
    },
    {
      title: '流程节点',
      render: () => <div>{applyStatusStr[state.detailData?.applyStatus]}</div>,
    },
    processState.backNodeIndex != -1 && {
      title: '驳回人',
      render: () => <div>{state.detailData?.backUserName}</div>,
    },
    processState.backNodeIndex != -1 && {
      title: '驳回原因',
      render: () => <div>{state.detailData?.backUserRemark || '-'}</div>,
    },
  ].filter(Boolean);

  /** 审核、驳回json代码 */
  const applyFormItem = [
    auditResult.current == AUDITTYPE.SUCCESS &&
      auditResult.current == AUDITTYPE.ERROR && {
        label: '原因',
        name: 'auditRemarkList',
        type: 'Select',
        props: {
          mode: 'tags',
          options: resultList,
          placeholder: '请输入原因，按enter确定',
        },
      },
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
    auditResult.current == AUDITTYPE.SUCCESS &&
      processState.curNode?.processNodeNumber == 'HR:SECOND:ROUND:AUDIT' && {
        label: '完成方式',
        name: 'applyFinishMethod',
        required: '请选择完成方式',
        type: 'RadioGroup',
        props: {
          options: [
            {
              label: '材料无误归档',
              value: 1,
            },
            {
              label: '材料不符，缺材料归档',
              value: 2,
            },
            {
              label: '无任何材料，无材料归档',
              value: 3,
            },
          ],
        },
      },
    auditResult.current == AUDITTYPE.ERROR &&
      ['15'].includes(state.detailData.applyBusiType) &&
      [1].includes(state.detailData.vacationApplyNeedEvidence) &&
      processState.curNode?.processNodeNumber == 'HR:SECOND:ROUND:AUDIT' && {
        label: '驳回到',
        name: 'backNode',
        required: '请选择驳回节点',
        type: 'RadioGroup',
        props: {
          options: [
            {
              label: '发起假期申请',
              value: processState.list[0]?.processApplyNodeId,
            },
            {
              label: '提交证明资料',
              value:
                processState.list[processState.certIndex]?.processApplyNodeId,
            },
          ],
        },
      },
  ].filter(Boolean);

  /** 审核点击提交 */
  const applySubmit = async (value) => {
    proveMaterialHandle();
    /** 通过/拒绝原因 */

    let processApplyId = history.location.query.id;
    if (history.location.query.todoItemBusiType == '16') {
      processApplyId = history.location.query.busiId;
    }
    let auditRemarkList = [...(value.auditRemarkList ?? []), value.auditRemark]
      ?.filter(Boolean)
      .map((txt) => {
        let SUCCESSID = 88888888;
        let ERRORID = 99999999;
        let auditRmkDescribe =
          resultList.find((item) => item.value == txt)?.label || txt;
        let auditRmkDescribeId =
          resultList.find((item) => item.value == txt)?.value ??
          (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
        let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
        return {
          auditRmkDescribe,
          auditRmkDescribeId,
          auditRmkType,
        };
      });
    let payload = {
      ...value,
      auditRemarkList,
      attendanceApplyId: processApplyId,
      auditResult: auditResult.current,
    };

    let applyNum = processState.applyNum;
    if (auditResult.current == AUDITTYPE.ERROR) {
      applyNum = 1;
      // let lastNode = findPreviousNode(
      //   processState.curNode?.processApplyNodeId,
      //   processState.list,
      // );
      let lastNode = processState.list[0];
      if (
        processState.certIndex != -1 &&
        processState.certIndex < processState.curIndex
      ) {
        lastNode = processState.list.find(
          (item) => item.processNodeNumber == CERTKEY,
        );
      }
      payload.backNode ||= lastNode?.processApplyNodeId;
    } else {
      updateTime();
    }
    let res;
    for (let i = 0; i < applyNum; i++) {
      payload.isJumpNextNode = applyNum && i == 0;
      res = await dispatch({
        type: 'attendance/auditattendanceprocess',
        payload,
      });
    }
    if (res) {
      setEditModalVisible(false);
      goback();
    }
  };

  // 返回
  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const updateTime = () => {
    const { form } = ref.current;
    if (!form.getFieldsValue().applyTime) return;
    let [start, end] = form.getFieldsValue().applyTime;
    if (
      +moment(state.detailData.applyBeginTime) != +start ||
      +moment(state.detailData.applyEndTime) != +end
    ) {
      let applyTimeHour = timeDiff([start, end], 'hours', 4);
      let attendanceApplyId = state.detailData.attendanceApplyId;
      let applyBeginTime = moment(start).format('YYYY-MM-DD HH:mm:ss');
      let applyEndTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
      fixattendanceapplytime({
        applyTimeHour,
        attendanceApplyId,
        applyBeginTime,
        applyEndTime,
      });
    }
  };

  const showDeleteBtn = () => {
    let isReject = false;
    processState.list?.map((item) => {
      if (item.processNodeStatus == 3) {
        isReject = true;
      }
    });

    return state.detailData?.isAuditPassChangeProcess && isReject;
  };

  const delItem = () => {
    if (
      history.location.query.todoItemBusiType == '16' &&
      history.location.query.busiId
    ) {
      let processApplyId = history.location.query.busiId;
      let params = { processApplyId };
      MessageBox.confirm({
        title: '确认操作',
        content: '确认删除？删除后无法恢复',
        onOk: () => {
          dispatch({
            type: 'attendance/deleteattendanceapply',
            payload: params,
          }).then((res) => {
            if (res) {
              message.success('删除成功');
              goback();
            }
          });
        },
      });
    }
  };

  return (
    <div>
      <div className="form_header">
        <h3>{history.location.query?.id ? '编辑' : '新增'}假期类型</h3>
        <div>
          {processState.curIndex == 0 && !showDeleteBtn() && (
            <Button
              type="primary"
              onClick={() => {
                history.replace({
                  pathname: '/attendance/apply/addApply',
                  query: history.location.query,
                });
              }}
              disabled={btnDisabled}
            >
              编辑
            </Button>
          )}

          {showDeleteBtn() && (
            <Button
              type="danger"
              onClick={() => {
                delItem();
              }}
              disabled={btnDisabled}
            >
              删除
            </Button>
          )}
          {processState.curIndex != 0 && showApplyBtn(processState.curNode) && (
            <Button
              type="primary"
              onClick={() => {
                setEditModalVisible(true);
                auditResult.current = AUDITTYPE.SUCCESS;
              }}
              disabled={btnDisabled}
            >
              {processState.curNode?.processNodeNumber == CERTKEY
                ? '提交'
                : '审核'}
            </Button>
          )}
          {processState.curIndex != 0 &&
            showApplyBtn(processState.curNode) &&
            !!processState.curIndex &&
            processState.curNode?.processNodeNumber != CERTKEY && (
              <Button
                type="danger"
                onClick={() => {
                  setEditModalVisible(true);
                  auditResult.current = AUDITTYPE.ERROR;
                }}
                disabled={btnDisabled}
              >
                驳回
              </Button>
            )}
          <Button onClick={goback}>返回</Button>
        </div>
      </div>
      <JsonForm columns={edtaFormItem} ref={ref} />
      <Timeline style={{ marginLeft: '20%' }}>
        {processState.list.map((item, index) => (
          <Timeline.Item
            key={index}
            content={`${item.processNodeName}${
              item.processApplyNodeHandlerList?.length > 0 ? '（' : ''
            }${
              item.processApplyNodeHandlerList
                ?.map((item) => item.processNodeHandlerName)
                .join('、') ?? ''
            }${item.processApplyNodeHandlerList?.length > 0 ? '）' : ''}`}
            {...processState.dots?.(index)}
          />
        ))}
      </Timeline>

      <ModalFormPro
        width={600}
        visible={editModalVisible}
        edtaFormItem={applyFormItem}
        onSave={applySubmit}
        btnLoading={loadingEf['attendance/auditattendanceprocess']}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ customer, system, attendance, loading }) => ({
  customer,
  system,
  attendance,
  loadingEf: loading.effects,
}))(AddApply);
