import React, { useEffect, useState, useMemo } from 'react';
import { Dropdown, MessageBox, Tree, Avatar, Input, Tooltip } from 'kenshin';
import '@/components/TreeData/index.less';
import { connect } from 'umi';
import { useMemoizedFn, useReactive } from 'ahooks';
import ModalFormPro from '@/components/ModalFormPro';
import ChooseDePartment from '@/components/ChooseManager/ChooseModal';
import {
  MoreOutlined,
  FolderTwoTone,
  FolderOpenTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { debounce, deepClone, filterTree } from '@/_util';
import { selectPinyinFilter } from '@/_util/componentsUtil';

/** 树查找 */
const findTree = (fildTree, id = 3) => {
  for (let i = 0; i < fildTree.length; i++) {
    if (fildTree[i].deptId == id) {
      return {
        pre: fildTree[i - 1],
        cur: fildTree[i],
        next: fildTree[i + 1],
      };
    }
    if (fildTree[i].child) {
      let resData = findTree(fildTree[i].child, id);
      if (resData) {
        return resData;
      }
    }
  }
};

/** 根据id查找 */
function findNodeById(id, nodes) {
  if (id == null || id == undefined) {
    return null;
  }
  for (let node of nodes) {
    if (node.deptId == id) {
      return node;
    }

    if (node.child) {
      let result = findNodeById(id, node.child);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

// 自定义图标
const getSwitcherIcon = (props) => {
  if (props.isLeaf) {
    return null;
  }
  const { expanded, loading } = props;
  let icon = <FolderTwoTone />;
  if (loading) {
    icon = <LoadingOutlined />;
  } else if (expanded) {
    icon = <FolderOpenTwoTone />;
  }
  return icon;
};

/** 对部门员工进行处理 */
function renderEmployeeTree(arr, baseKey, useBusiId) {
  if (arr?.length == 0) {
    return [];
  }
  return arr.map((item) => {
    let data = {
      // ...item,
      key: `${baseKey}-${item.id}`,
      muid: item.id,
      title: item.value,
      children: [],
      isLeaf: true,
      // switcherIcon: getSwitcherIcon,
      switcherIcon: () => (
        <Avatar
          size="small"
          src={
            item.extendData2 &&
            (item.extendData2?.startsWith('http')
              ? item.extendData2
              : `${ossUrl}/${item.extendData2}`)
          }
        >
          {item.value?.[0]}
        </Avatar>
      ),
    };
    if (!item.extendData3 && useBusiId) {
      (data.disabled = !item.extendData3),
        (data.title = (
          <Tooltip title="禁用原因：暂无负责人">
            <span>{item.value}</span>
          </Tooltip>
        ));
    }
    return data;
  });
}

const TreeData = (props) => {
  const manageDeptId = localStorage.getItem('manageDeptId');

  const {
    dispatch,
    systemOrgani,
    onChange,
    performance,
    hideEmp,
    value,
    useBusiId = false,
  } = props;
  const { departmentList, employeeForDept } = systemOrgani; // 部门，部门架构成员

  const [expandedRowKeys, setExpandedRowKeys] = useState([]); //树组件展开受控
  const [selectkeys, setSelectkeys] = useState(['1']); //树组件选中受控

  const state = useReactive({
    treeData: [],
  });

  /** 返回的数据改造成组件认识的结构：{title,key,children} */
  const transformTreeData = useMemoizedFn((arr) => {
    return arr.map((item) => {
      let children = [];
      if (item.child) {
        children = transformTreeData(item.child);
      }
      if (employeeForDept[item.deptId]) {
        children.unshift(
          ...renderEmployeeTree(
            employeeForDept[item.deptId],
            item.deptId,
            useBusiId,
          ),
        );
      }
      return {
        children,
        title: item.deptName,
        key: item.deptId,
        switcherIcon: getSwitcherIcon,
        ...item,
      };
    });
  });

  // 部门架构发生改变的时候应该刷新树组件的数据源
  useEffect(() => {
    let rootTree = findNodeById(manageDeptId, [departmentList]);
    if (rootTree) {
      let tree = transformTreeData([rootTree]);
      state.treeData = tree;
      if (tree[0]) {
        let curExpend = [tree[0]?.key];
        setExpandedRowKeys(curExpend);
        if (!hideEmp)
          onLoadData({ key: tree[0].key, children: tree[0].children });
      }
      if (!performance.curOsaSettingData.expandedKeys && !value)
        handleChange([tree[0].key], { node: tree[0] });
    }
  }, [departmentList]);

  useEffect(() => {
    let curData = performance.curOsaSettingData;
    if (curData.expandedKeys || curData.rows) {
      expandedRowKeys.push(...(curData.expandedKeys || []));
      setExpandedRowKeys([...expandedRowKeys]);
      handleChange([curData.rows?.key], { node: curData.rows });
    }
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
  }, []);

  /** 默认的数据源 */
  const defaultTreeData = useMemo(() => {
    let rootTree = findNodeById(manageDeptId, [departmentList]);
    if (rootTree) {
      return deepClone(transformTreeData([rootTree]));
    }
    return [];
  }, [systemOrgani.departmentList]);

  /** 输入框搜索事件 */
  const searchInput = (e) => {
    const { value } = e.target;
    state.treeData = filterTree(deepClone(defaultTreeData), value);
  };

  /** 树组件选中回调 */
  const handleChange = (keys, row) => {
    setSelectkeys(keys);
    keys.length > 0 &&
      onChange?.(keys, row.node, { expandedKeys: expandedRowKeys });
  };

  // 展开部门的时候应该加载架构成员
  const onLoadData = ({ key, children }) => {
    return new Promise((resolve, reject) => {
      if (employeeForDept[key]) {
        resolve();
      } else {
        dispatch({
          type: 'systemOrgani/getemplistbydeptid',
          payload: {
            deptId: key,
          },
        }).then((res) => {
          let newEmployees = renderEmployeeTree(res, key, useBusiId);
          let newChildren = newEmployees.filter((newEmployee) => {
            return !children.some((child) => child.key == newEmployee.key);
          });
          console.log(res, newChildren);
          children?.unshift(...newChildren);
          state.treeData = [...state.treeData];
          resolve();
        });
      }
    });
  };
  return (
    <div className="treeData">
      <Input placeholder="请输入关键词" onChange={debounce(searchInput, 500)} />

      <Tree
        defaultExpandAll
        loadData={hideEmp ? null : onLoadData}
        selectedKeys={selectkeys}
        treeData={[...state.treeData]}
        blockNode
        expandedKeys={expandedRowKeys}
        onExpand={setExpandedRowKeys}
        onSelect={handleChange}
        titleRender={({ title }) => {
          return (
            <div>
              {title}
              <div style={{ float: 'right' }}></div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default connect(({ systemOrgani, performance }) => ({
  systemOrgani,
  performance,
}))(TreeData);
