import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MessageBox, Form, Input, Tree, Button, Col, Row } from 'kenshin';
import styles from './index.less';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'umi';
import { useMemoizedFn } from 'ahooks';
import { deepClone, findTree, debounce } from '@/_util';

export const transformTreeData = (arr) => {
  return arr.map((item) => {
    let children = null;
    if (item.child) {
      children = transformTreeData(item.child);
    }
    return {
      children,
      title: item.deptName,
      key: item.deptId,
      ...item,
    };
  });
};

const ChooseModal = ({
  visible,
  onCancel,
  onSave,
  value,
  onChange,
  systemOrgani,
  multiple = true,
}) => {
  const [selectKey, setSelectKey] = useState(value ?? []);
  const [selectRow, setSelectRow] = useState(value ?? []);

  const [data, setData] = useState([]);
  const defaultTreeData = useMemo(
    () => deepClone(transformTreeData([systemOrgani.departmentList])),
    [systemOrgani.departmentList],
  );

  useEffect(() => {
    setData(defaultTreeData);
  }, [systemOrgani.departmentList]);

  useEffect(() => {
    // 单选可能返回字符串
    if (value) {
      if (!Array.isArray(value)) {
        setSelectKey([value]);
        setSelectRow(
          // 兼容处理，可能部门被删除了
          [findTree([systemOrgani.departmentList], value)].filter(
            (item) => !!item,
          ) ?? [],
        );
      } else {
        // 数组的处理方式
        setSelectRow(value ?? []);
        setSelectKey([value?.map((item) => item.id)] ?? []);
      }
    }
  }, [value]);

  // 确定按钮
  const handleOk = () => {
    onSave?.(selectRow);
    onChange?.(selectRow);
  };

  // 树选择
  const handleChange = (val, row) => {
    setSelectKey(val);
    setSelectRow(
      row.selectedNodes.map((item) => ({ id: item.key, value: item.title })),
    );
  };

  // 取消
  const handleCancel = () => {
    onCancel?.();
    setSelectKey(value ?? []);
  };

  // 树搜索
  const searchInput = (e) => {
    const { value } = e.target;
    setData(filterTree(deepClone(defaultTreeData), value));
  };

  const filterTree = (arr, value) => {
    return arr.filter((item) => {
      if (item.children) {
        let children = filterTree(item.children, value);
        item.children = children;
        return children.length > 0;
      }
      return item.title.includes(value);
    });
  };

  return (
    <MessageBox
      title="选择组织架构"
      width={800}
      bodyStyle={{ minHeight: 400, overflowY: 'auto' }}
      onCancel={handleCancel}
      destroyOnClose
      visible={visible}
      onOk={handleOk}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          确定
        </Button>,
      ]}
    >
      <Row>
        <Col span={20}>
          <Input
            style={{ marginBottom: 8 }}
            placeholder="Search"
            onChange={debounce(searchInput, 500)}
          />
        </Col>
        <Col span={9}>
          <div>选择权限功能</div>
          <div className={styles.borderBox}>
            <Tree
              treeData={data}
              selectedKeys={selectKey}
              onSelect={handleChange}
              multiple={multiple}
              defaultExpandAll
            />
          </div>
        </Col>
        <Col span={2} className={styles.flexCenter}>
          &gt;
        </Col>
        <Col span={9}>
          <div>已选择</div>
          <div className={styles.borderBox}>
            {Array.isArray(selectRow) &&
              selectRow?.map((item, index) => (
                <div key={item.id || item.deptId}>
                  {item.value || item.deptName}
                  <CloseOutlined
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => {
                      setSelectKey((key) => {
                        key.splice(index, 1);
                        return [...key];
                      });
                      setSelectRow((row) => {
                        row.splice(index, 1);
                        return [...row];
                      });
                    }}
                  />
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </MessageBox>
  );
};

export default connect(({ systemOrgani, loading, user }) => {
  return {
    systemOrgani,
  };
})(ChooseModal);
