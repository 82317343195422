import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  Button,
  message,
  Table,
  Progress,
  Steps,
  Row,
  Col,
  MessageBox,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';

const ServCategory = ({ dispatch, loading, dataAnalyse, user }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const tableRef = useRef();

  const statusToStr = (rptWorkTotalHours = 0) => {
    let str = <div className="status-red">异常</div>;

    if (rptWorkTotalHours > 0) {
      if (rptWorkTotalHours >= 7.5)
        str = <div className="status-green">正常</div>;
      if (rptWorkTotalHours < 7.5)
        str = <div className="status-grey">未报满(不够7.5小时)</div>;
    }
    return str;
  };

  const columns = [
    {
      dataIndex: 'rptDate',
      title: '上报日期',
    },
    {
      dataIndex: 'projectNum',
      title: '项目数量',
    },
    {
      dataIndex: 'workPkgNum',
      title: '工作包',
      render: (val) => `${val}个`,
    },
    {
      dataIndex: 'rptWorkTotalHours',
      title: '上报工时',
    },
    {
      key: 'timeArea',
      title: '工作时间',
      render: (row) =>
        row.minBeginTime &&
        [
          moment(row.minBeginTime).format('HH:mm:ss'),
          moment(row.maxEndTime).format('HH:mm:ss'),
        ]
          .filter(Boolean)
          .join(' ~ '),
    },
    {
      dataIndex: 'overtimeDeptPercent',
      title: '部门加班占比',
    },
    {
      key: 'status',
      title: '状态',
      render: (row) => statusToStr(row.rptWorkTotalHours),
    },
  ];
  const { statBeginTime, statEndTime } = history.location.query;
  const searchCloumns = [
    {
      name: 'statTime',
      type: 'DatePickerRangePicker',
      label: '上报周期',
      props: {
        defaultValue: [moment(statBeginTime), moment(statEndTime)],
      },
    },
    {
      name: 'rptStatus',
      type: 'Select',
      props: {
        placeholder: '状态',
        options: [
          {
            label: '漏报',
            value: 0,
          },
          {
            label: '正常',
            value: 1,
          },
          {
            label: '未报满',
            value: 2,
          },
        ],
      },
    },
  ];

  const onExpand = (expanded, { rptDate }) => {
    if (expanded) {
      dispatch({
        type: `dataAnalyse/getEmpRptDetailList`,
        payload: {
          empId: history.location.query.empId,
          rptDate,
        },
      });
    }
  };

  const expandedRowRender = ({ rptDate }) => {
    const exCloumns = [
      {
        dataIndex: 'gmtCreate',
        title: '记录日期',
        render: (date) => moment(date).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        key: 'project',
        title: '服务类目',
        render: (row) => [row.proServCatgName, row.taskName].join(' > '),
      },
      {
        dataIndex: 'projectName',
        title: '项目名称',
      },
      {
        dataIndex: 'rptWorkTotalHours',
        title: '上报总工时',
      },
      {
        key: 'workBeginTime',
        title: '具体时段',
        render: (date) =>
          [
            moment(date.workBeginTime).format('HH:mm:ss'),
            moment(date.workEndTime).format('HH:mm:ss'),
          ].join(' ~ '),
      },
      {
        dataIndex: 'rptRemark',
        title: '备注',
      },
    ];
    return (
      <Table
        rowKey="rptDetailId"
        columns={exCloumns}
        dataSource={dataAnalyse.rptDetailPageById[rptDate]}
        pagination={false}
      />
    );
  };

  const extra = () => (
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let value = tableRef.current.value;
        let payload = {
          key: value.key ?? '',
          rptAuditStatus: value.rptAuditStatus ?? '',
        };
        // let url = `${baseURL}${exportEmpRptRcd}${splitParamsFoGet(payload)}`;
        // downloadXlsx(url, '工作记录');
      }}
    >
      导出
    </Button>
  );

  const onSubmit = (payload) => {
    if (payload.statTime == null) {
      payload.statBeginTime = undefined;
      payload.statEndTime = undefined;
      payload.statTime = undefined;
    }
    if (payload.statTime) {
      const [start, end] = payload.statTime;
      payload.statBeginTime = moment(start).format('YYYY-MM-DD');
      payload.statEndTime = moment(end).format('YYYY-MM-DD');
      payload.statTime = undefined;
    }
  };

  const onReset = () => {
    const { statBeginTime, statEndTime } = history.location.query;
    tableRef.current.search.setFieldsValue?.({
      statTime: [moment(statBeginTime), moment(statEndTime)],
    });
  };

  return (
    <div>
      <TablePro
        params={history.location.query}
        request="dataAnalyse/getAllEmpRptDetailStat"
        rowKey="rptDate"
        // extra={extra}
        actionRef={(ref) => (tableRef.current = ref)}
        columns={columns}
        curColumns
        searchCloumns={searchCloumns}
        onSubmit={onSubmit}
        data={dataAnalyse.rptDetailPage}
        columnsOptions={false}
        onReset={onReset}
        expandable={{
          expandedRowRender,
          onExpand,
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />
    </div>
  );
};

export default connect(({ dataAnalyse, loading, user }) => {
  return {
    user,
    dataAnalyse,
    loading: loading.effects,
  };
})(ServCategory);
