import React, { memo, useEffect, useState } from 'react';
import { Tree, message } from 'kenshin';

const ClassifyMenu = memo((props) => {
  const { data, onChange, setTreeData } = props;

  const getTree = (data) => {
    if (!Array.isArray(data)) {
      return null;
    }
    return data.map((item) => {
      return {
        title: `${item.servCatgCode} ${item.servCatgName}`,
        key: item.key,
        data: item,
        children: getTree(item.childList),
      };
    });
  };

  // const [treeData,setTreeData] = useState([])

  // useEffect(() => {
  //   setTreeData(getTree(data))
  // },[data])

  const onSelect = (keys, node) => {
    let data = node.node?.props?.data?.data;
    if (keys.length > 0) {
      onChange?.(keys[0], data);
    }
  };
  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;

    const dropPos = info.node.pos.split('-');
    const dragPos = info.dragNode.pos.split('-');

    const dragPosStr = dragPos.slice(0, dragPos.length - 1).join('-');
    const dropPosStr = dropPos
      .slice(0, dropPos.length - (info.dropToGap ? 1 : 0))
      .join('-');

    if (dragPosStr != dropPosStr) {
      message.warn('暂不支持跨级排序');
      return;
    }

    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].childList) {
          loop(data[i].childList, key, callback);
        }
      }
    };
    const newData = [...data];

    let dragObj;
    loop(newData, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(newData, dropKey, (item) => {
        item.childList = item.childList || [];
        item.childList.unshift(dragObj);
      });
    } else if (
      (info.node.props.childList || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap 在底部的缝隙上
    ) {
      loop(newData, dropKey, (item) => {
        item.childList = item.childList || [];
        item.childList.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(newData, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setTreeData(newData);
    // setTreeData(data)
    // setData(data)
  };
  return (
    <Tree
      onSelect={onSelect}
      defaultExpandAll
      // defaultSelectedKeys={['0-0-0']}
      treeData={getTree(data)}
      draggable
      onDrop={onDrop}
      blockNode
      // titleRender={(node) => {
      //   return (
      //     <div>
      //       {node.title}
      //       {/* <div
      //         style={{ float: 'right', width: 20, textAlign: 'center' }}
      //         onClick={(e) => {
      //           e.stopPropagation();
      //           addItem(node.data)
      //         }}
      //       >
      //         +
      //       </div> */}
      //     </div>
      //   );
      // }}
    />
  );
});

export default ClassifyMenu;
