import {
  // 客户
  getCustomerCharts,
  getCustomerList,
  getCustomerListById,
  // 收款
  getCollectCharts,
  getCollectList,
  getCollectListById,
  // 成本 --项目
  getCostProjectCharts,
  getCostProjectList,
  getCostProjectListById,
  // 成本 --部门
  getCostDepCharts,
  getCostDepList,
  getCostDepListById,
  // 成本 --员工
  getCostEmpList,
  getCostEmpListById,
  getCostEmpCharts,
  getEmpRptDetailStat,
  getEmpRptStat,
  getEmpRptDetailStatPage,
  getEmpRptDetailList,
  getDepRptStat,
  getDepRptDetailStat,
  // 绩效
  queryosaquaterallstatdata,
  queryosaquaterdeptstatdata,
  queryosaquaterempstatdata,
  queryosaweekplanallstatdata,
  queryosaweekplandeptstatdata,
  queryosaweekplanempstatdata,
  // 考勤
  findattendancestatdata,

  //所有上报分析
  getAllEmpRptStat,
  getAllEmpRptDetailStat,
  getAllDepRptStat,
  getAllDepRptDetailStat,
} from '@/_serveice/dataAnalyse';

import { history } from 'umi';

const costFun = {
  getCostProjectCharts,
  getCostProjectList,
  getCostProjectListById,
  // 成本 --部门
  getCostDepCharts,
  getCostDepList,
  getCostDepListById,
  // 成本 --员工
  getCostEmpList,
  getCostEmpListById,
  getCostEmpCharts,
};
function costType(tabKeys) {
  let obj = {
    1: 'Project',
    2: 'Dep',
    3: 'Emp',
  };
  return obj[tabKeys];
}

export default {
  namespace: 'dataAnalyse',

  state: {
    customerChartData: {},
    customerList: {},
    customerListById: {},
    // 项目
    projectChartData: {},
    projectList: {},
    projectListById: {},
    // 收款
    collectChartData: {},
    collectList: {},
    collectListById: {},
    // 成本
    costChartData: {},
    costList: {},
    costListById: {},

    empRptDetailStat: [],
    empRptStat: [],

    rptDetailPageSearch: {},
    rptDetailPage: {},
    rptDetailPageById: {},

    // 绩效
    osaStatData: [],
    osaTableHeader: [],
    osaChartData: [],

    // 考勤
    attendanceData: [],
    attendanceTheader: {},
    attendanceChat: [],
  },

  effects: {
    *getCustomerCharts({ payload, dataRule }, { put, call }) {
      const res = yield call(getCustomerCharts, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            customerChartData: dataRule(res.data),
          },
        });
      }
    },
    *getCustomerList({ payload }, { put, call }) {
      const res = yield call(getCustomerList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            customerList: res.data,
          },
        });
      }
    },
    *getCustomerListById({ payload }, { put, call, select }) {
      // custId
      const customerListById = yield select(
        ({ dataAnalyse }) => dataAnalyse.customerListById,
      );
      if (customerListById[payload.custId]) {
        return customerListById[payload.custId];
      }
      const res = yield call(getCustomerListById, payload);

      if (res?.success) {
        customerListById[payload.custId] = res.data;
        yield put({
          type: 'save',
          payload: {
            customerListById: { ...customerListById },
          },
        });
      }
    },

    // 项目
    *getProjectCharts({ payload, dataRule }, { put, call }) {
      const res = yield call(getCostProjectCharts, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectChartData: dataRule(res.data),
          },
        });
      }
    },
    *getProjectList({ payload }, { put, call }) {
      const res = yield call(getCostProjectList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectList: res.data,
          },
        });
      }
    },
    *getProjectListById({ payload }, { put, call, select }) {
      // projectId
      const projectListById = yield select(
        ({ dataAnalyse }) => dataAnalyse.projectListById,
      );
      if (projectListById[payload.projectId]) {
        return projectListById[payload.projectId];
      }
      const res = yield call(getCostProjectListById, payload);

      if (res?.success) {
        projectListById[payload.projectId] = res.data;
        yield put({
          type: 'save',
          payload: {
            projectListById: { ...projectListById },
          },
        });
      }
    },
    // 收款
    *getCollectCharts({ payload, dataRule }, { put, call }) {
      const res = yield call(getCollectCharts, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            collectChartData: dataRule(res.data),
          },
        });
      }
    },
    *getCollectList({ payload }, { put, call }) {
      const res = yield call(getCollectList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            collectList: res.data,
          },
        });
      }
    },
    *getCollectListById({ payload }, { put, call, select }) {
      let ids =
        payload.statYearMonth || payload.statYearQuarter || payload.statYear;
      const collectListById = yield select(
        ({ dataAnalyse }) => dataAnalyse.collectListById,
      );
      if (collectListById[ids]) {
        return collectListById[ids];
      }
      const res = yield call(getCollectListById, payload);

      if (res?.success) {
        collectListById[ids] = res.data;
        yield put({
          type: 'save',
          payload: {
            collectListById: { ...collectListById },
          },
        });
      }
    },
    // 成本
    *getCostCharts({ payload, dataRule }, { put, call }) {
      const { tabsKey, ...params } = payload;

      let api = costFun[`getCost${costType(tabsKey)}Charts`];
      if (!api) {
        return false;
      }
      const res = yield call(api, params);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            costChartData: dataRule(res.data),
          },
        });
      }
    },
    *getCostList({ payload }, { put, call }) {
      const { tabsKey, ...params } = payload;
      let api = costFun[`getCost${costType(tabsKey)}List`];
      if (!api) {
        return false;
      }

      const res = yield call(api, params);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            costList: res.data,
          },
        });
      }
    },
    *getCostListById({ payload, id }, { put, call, select }) {
      const { tabsKey, ...params } = payload;
      let api = costFun[`getCost${costType(tabsKey)}ListById`];
      if (!api) {
        return false;
      }

      const costListById = yield select(
        ({ dataAnalyse }) => dataAnalyse.costListById,
      );
      if (costListById[payload[id]]) {
        return costListById[payload[id]];
      }
      const res = yield call(api, params);

      if (res?.success) {
        costListById[payload[id]] = res.data;
        yield put({
          type: 'save',
          payload: {
            costListById: { ...costListById },
          },
        });
      }
    },

    *getEmpRptDetailStat({ payload: params }, { put, call }) {
      const { isDep, ...payload } = params;
      const res = yield call(
        isDep ? getDepRptDetailStat : getEmpRptDetailStat,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empRptDetailStat: res.data,
          },
        });
      }
    },
    *getEmpRptStat({ payload: params, dataRule }, { put, call }) {
      const { isDep, ...payload } = params;
      const res = yield call(isDep ? getDepRptStat : getEmpRptStat, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empRptStat: dataRule(res.data),
          },
        });
      }
    },

    *getAllEmpRptStat({ payload: params, dataRule }, { put, call }) {
      const { isDep, ...payload } = params;
      const res = yield call(
        isDep ? getAllDepRptStat : getAllEmpRptStat,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empRptStat: dataRule(res.data),
          },
        });
      }
    },

    *getAllDepRptStat({ payload: params, dataRule }, { put, call }) {
      const { isDep, ...payload } = params;
      const res = yield call(getAllDepRptStat, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empRptStat: dataRule(res.data),
          },
        });
      }
    },

    *getEmpRptDetailStatPage({ payload, reset }, { put, call, select }) {
      // console.log("getEmpRptDetailStatPage...",payload);

      const searchData = yield select(
        ({ dataAnalyse }) => dataAnalyse.rptDetailPageSearch,
      );

      // if(!payload.statBeginTime || !payload.statEndTime) {
      //   payload.statBeginTime = history.location.query.statBeginTime
      //   payload.statEndTime = history.location.query.statEndTime
      // }

      let rptDetailPageSearch = { ...searchData, ...payload };
      if (reset) {
        rptDetailPageSearch = payload;
      }
      const res = yield call(getEmpRptDetailStatPage, rptDetailPageSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            rptDetailPage: res.data,
            rptDetailPageSearch,
          },
        });
      }
    },

    *getAllEmpRptDetailStat({ payload: params }, { put, call }) {
      const { isDep, ...payload } = params;
      const res = yield call(
        isDep ? getAllDepRptDetailStat : getAllEmpRptDetailStat,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empRptDetailStat: res.data,
          },
        });
      }
    },

    *getAllDepRptDetailStat({ payload: params }, { put, call }) {
      const { isDep, ...payload } = params;
      const res = yield call(getAllDepRptDetailStat, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empRptDetailStat: res.data,
          },
        });
      }
    },

    *getEmpRptDetailList({ payload }, { put, call, select }) {
      // projectId
      const rptDetailPageById = yield select(
        ({ dataAnalyse }) => dataAnalyse.rptDetailPageById,
      );
      if (rptDetailPageById[payload.rptDate]) {
        return rptDetailPageById[payload.rptDate];
      }
      const res = yield call(getEmpRptDetailList, payload);

      if (res?.success) {
        rptDetailPageById[payload.rptDate] = res.data;
        yield put({
          type: 'save',
          payload: {
            rptDetailPageById: { ...rptDetailPageById },
          },
        });
      }
    },

    // 绩效
    *queryosaquaterallstatdata(
      { payload, infoChecked, dataType },
      { put, call },
    ) {
      const infoTypeApi = {
        2: [
          '',
          queryosaweekplanallstatdata,
          queryosaweekplandeptstatdata,
          queryosaweekplanempstatdata,
        ],
        1: [
          '',
          queryosaquaterallstatdata,
          queryosaquaterdeptstatdata,
          queryosaquaterempstatdata,
        ],
      };
      const res = yield call(infoTypeApi[infoChecked][dataType], payload);

      if (res?.success) {
        let payload = {
          // osaChartData: dataRule(res.data),
          osaStatData: res.data?.oasStatList ?? [],
          osaTableHeader: res.data?.tableList ?? [],
        };
        if (dataType == 1) {
          payload.osaChartData = res.data?.oasStatList ?? [];
        }
        yield put({
          type: 'save',
          payload,
        });
      }
    },
    *findattendancestatdata({ payload }, { put, call }) {
      const res = yield call(findattendancestatdata, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            attendanceData: res.data?.attendanceStatDataList,
            attendanceChat: res.data?.attendanceStatChartList,
            attendanceTheader: res.data?.tableHeadDataList,
          },
        });
        return res.data;
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
