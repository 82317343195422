import React, { useCallback } from 'react';
import DataAnalysePage from '@/components/DataAnalysePage';
import { downloadForCustomer } from '@/_serveice/dataAnalyse';

const dataAnalyseCustomer = () => {
  const dataRule = useCallback((arr) => {
    const money = (type, step = 100) =>
      arr.map((item) => +(item[type] / step).toFixed(2));
    const xData = arr.map((item) => item.custCompFullName);
    return {
      xData,
      data: [
        {
          name: '收款金额',
          type: 'bar',
          color: '#ffc01e',
          data: money('collectAmount'),
        },
        {
          name: '合同金额',
          type: 'bar',
          color: '#4389f9',
          data: money('contractAmount'),
        },
        // {
        //   name: '累积产出',
        //   type: 'bar',
        //   color: '#4389f9',
        //   data: money('productAmount'),
        // },
        {
          name: '工时成本（实际）',
          type: 'bar',
          color: '#40c898',
          data: money('actualCostAmount'),
        },
        {
          name: '工时成本（虚拟）',
          type: 'bar',
          color: '#8ed0fb',
          data: money('virtualCostAmount'),
        },
        {
          name: '成本费用率（实际）',
          type: 'line',
          color: '#fe8d8b',
          data: money('actualInoutputPercent', 1),
        },
        {
          name: '成本费用率（虚拟）',
          type: 'line',
          color: '#a8fd0c',
          data: money('virtualInoutputPercent', 1),
        },
      ],
    };
  }, []);
  const dispatchType = {
    charts: 'getCustomerCharts',
    list: 'getCustomerList',
    listById: 'getCustomerListById',
  };
  const dataType = {
    charts: 'customerChartData',
    list: 'customerList',
    listById: 'customerListById',
  };
  const publicColums = [
    {
      dataIndex: 'actualCostAmount',
      title: '工时成本(实际)',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'actualInoutputPercent',
      title: '成本费用率(实际)%',
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'virtualCostAmount',
      title: '工时成本(虚实)',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'virtualInoutputPercent',
      title: '成本费用率(虚实)%',
      render: (val) => `${val}%`,
    },
    {
      title: '累计产出',
      dataIndex: 'productAmount',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'actualProfitAmount',
      title: '盈利(实际)',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'actualProfitPercent',
      title: '盈利率(实际)%',
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'virtualProfitAmount',
      title: '盈利(虚实)',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'virtualProfitPercent',
      title: '盈利率(虚实)%',
      render: (val) => `${val}%`,
    },
  ];
  const columns = [
    {
      dataIndex: 'custCompFullName',
      title: '客户名称',
    },
    {
      dataIndex: 'contractAmount',
      title: '合同金额',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'collectAmount',
      title: '收款金额',
      render: (val) => +(val / 100).toFixed(2),
    },
    ...publicColums,
  ];
  const rowTableColums = [
    {
      key: 'projectName',
      title: '项目名称',
      render: (row) => row.projectTempStatDTO.projectName,
    },
    {
      key: 'projectAmount',
      title: '项目金额',
      render: (row) =>
        +(row.projectTempStatDTO.projectAmount / 100 || 0).toFixed(2),
    },
    {
      key: 'collectAmount',
      title: '收款金额',
      render: (row) =>
        +(row.projectTempStatDTO.collectAmount / 100 || 0).toFixed(2),
    },
    ...publicColums,
  ];

  return (
    <DataAnalysePage
      dataRule={dataRule}
      dispatchType={dispatchType}
      dataType={dataType}
      columns={columns}
      rowTableColums={rowTableColums}
      tableKey="custId"
      rowTableKey={(row, index) => row.projectId || index}
      downloadUrl={downloadForCustomer}
      downloadName="客户分析"
    />
  );
};

export default dataAnalyseCustomer;
