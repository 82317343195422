import { Button } from 'kenshin';
import styles from './index.less';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const Render = ({ children, visible }) => {
  return (
    <div className={styles.hideButtonBox}>
      {/* <div className="arrow"></div> */}
      {children}
    </div>
  );
};

const MoreButton = (props) => {
  const { quantity = 2, children } = props;
  const [visible, setVisible] = useState(false);
  const boxRef = useRef();
  const renderRef = useRef();

  const createDiv = (style) => {
    const { left, top, height, width } = style;
    // let width = 98;
    renderRef.current = document.createElement('div');
    let div = renderRef.current;
    div.style.position = `fixed`;
    div.style.top = `${top + height}px`;
    div.style.left = `${left}px`;
    div.style.width = `${width}px`;
    div.style.zIndex = 2;
    ReactDOM.render(<Render>{hideChildren}</Render>, div);
    return div;
    // document.body.appendChild(div)
  };

  const handleMore = () => {
    const style = boxRef.current.getBoundingClientRect();
    if (visible && renderRef.current) {
      setTimeout(() => {
        // 避免点击立即失效问题
        document.body.removeChild(renderRef.current);
        renderRef.current = null;
      }, 200);
    } else if (!renderRef.current) {
      let div = createDiv(style);
      document.body.appendChild(div);
    }
    setVisible(!visible);
  };

  const getHideButton = () => {
    return (
      <div
        className={styles.moreButton}
        ref={boxRef}
        onFocus={handleMore}
        onBlur={handleMore}
      >
        <Button type="text">更多</Button>
        {/* <div className={["hide-button-box", visible ? 'show' : 'hide'].join(" ")}>
        {hideChildren}
      </div> */}
      </div>
    );
  };

  let beforeChildren = [];
  let hideChildren = [];
  let moreChildren = null;
  if (children.length > quantity) {
    beforeChildren = children.slice(0, quantity);
    hideChildren = children.slice(quantity);
    moreChildren = getHideButton();
  }

  return (
    <div>
      {beforeChildren}
      {moreChildren}
    </div>
  );
  // return <Button type="text">更多</Button>
};

export default MoreButton;
