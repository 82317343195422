import {
  addosaemployeeindexdata,
  addosaemployeeindexcatgdata,
  addosaemployeeindexitemdata,
  deleteosaemployeeindexcatgdata,
  deleteosaemployeeindexitemdata,
  getosaempstatdata,
  getosauserindexcatgtree,
  getosauserindextree,
  initosaemployeedata,
  moveosaemployeeindexcatgdata,
  moveosaemployeeindexitemdata,
  updateosaemployeedata,
  updateosaemployeeindexcatgdata,
  updateosaemployeeindexitemdata,
  updateosaemployeenoticedata,
  updateosaemployeescore,
  getosaemployeepage,
  getosamypage,
  getmyosastatdata,
  getosauserprocesslist,
  getosaemployeedata,
  auditosaprocess,
  deleteosaemployee,
  osaindexscore,
  updateosaempindexactucalvalue,
  getosagradeaenablenum,
  getosaemployeeauditscoredata,
  getallosaemployeepage,
  queryempweekplandata,
  launchosaweekplanchangeapply,
  getosachangeprocesslist,
  getosachangeemployeedata,
  deleteosachangedata,
  // 指标
  addosachangeindexcatgdata,
  addosachangeindexdata,
  addosachangeindexitemdata,
  moveosachangeindexcatgdata,
  moveosachangeindexitemdata,
  updateosachangeindexcatgdata,
  updateosachangeindexitemdata,
  importosaempindexdata,
  deleteosachangeindexcatgdata,
  deleteosachangeindexitemdata,
  getosachangeindextree,
  getosachangeindexcatgtree,
  osaChangeAuditosaprocess,
  getlastosachangedata,
  // 指标end
  // 周计划日期配置
  initweekplanyeardatedata,
  addupdateweekplandatedata,
  deleteweekplandatedata,
  getyearweekplandatedata,
  haveosachangeprocess,
  // 周计划日期配置end
  copyhistoryosaindexdata,
  importosachangeindexdata,
  copyhistoryosachangeindexdata,
  getmychangeosapage,
  getmychangeosastatdata,
  updateosachangedata,
  osachangeindexscore,
  updateosachangeindexactucalvalue,
  osaweekplanrevoke,
  osaindexscorebatch,
  getosaauditsystemdescribelist,

  //周计划驳回原因
  getosaweekprocessrefuseinfo,
  getosaweekchangeprocessrefuseinfo,

  //月度策略
  getosamonthstraget,
  getosachangemonthstraget,
  addupdateosamonthstraget,
  addupdateosachangemonthstraget,
  gethistoryosamonthstraget,
} from '../../_serveice/performance/osa';

export const state = {
  osaSearchNum: {},
  osaSubAssessment: {},
  osaSubAssessmentSearch: {},
  osaselfData: {},
  osaselfDataSearch: {},
  osaProcessCache: {},
  curOsaSettingData: {}, // 存储一些展示的数据，例如点击了某成员，保存成员的名字
  weekTimeTree: [],
  weekTimeTreeSearchData: {},
  osaChangeSearchNum: {},
  osaChangeList: {},
  osaChangeListSearchData: {},
};
import { dirTreeOptions } from '.';

/** 将后端返回的周计划时间转换成Tree组件支持选择人的格式 */
function weekPlanDateToTree(timeArr, parentData = {}) {
  if (timeArr && Array.isArray(timeArr)) {
    timeArr.map((item, index) => {
      if (item.osaWeekMonthList ?? item.osaWeekDateList) {
        weekPlanDateToTree(item.osaWeekMonthList ?? item.osaWeekDateList, {
          ...parentData,
          ...item,
        });
      }
      item.osaWeekPlanMonth ??= parentData.osaWeekPlanMonth;
      item.osaWeekPlanQuarter ??= parentData.osaWeekPlanQuarter;
    });
  }
  return [];
}

export const effects = {
  *getosaemployeepage({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaSubAssessmentSearch,
    );
    let osaSubAssessmentSearch = { ...searchData, ...payload };
    const res = yield call(getosaemployeepage, osaSubAssessmentSearch);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaSubAssessment: res.data,
          osaSubAssessmentSearch,
        },
      });
      return true;
    }
  },
  *getallosaemployeepage({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaSubAssessmentSearch,
    );
    let osaSubAssessmentSearch = { ...searchData, ...payload };
    const res = yield call(getallosaemployeepage, osaSubAssessmentSearch);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaSubAssessment: res.data,
          osaSubAssessmentSearch,
        },
      });
      return true;
    }
  },
  *getosamypage({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaselfDataSearch,
    );
    let osaselfDataSearch = { ...searchData, ...payload };
    const res = yield call(getosamypage, osaselfDataSearch);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaselfData: res.data,
          osaselfDataSearch,
        },
      });
      return true;
    }
  },
  *getosauserprocesslist({ payload }, { put, call, select }) {
    const osaProcessCache = yield select(
      ({ performance }) => performance.osaProcessCache,
    );
    const res = yield call(getosauserprocesslist, payload);
    if (res?.success) {
      payload.osaId && (osaProcessCache[payload.osaId] = res.data);
      yield put({
        type: 'save',
        payload: {
          osaProcessCache,
        },
      });
      return res.data;
    }
  },

  *getosaempstatdata({ payload }, { put, call }) {
    const res = yield call(getosaempstatdata, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaSearchNum: res.data,
        },
      });
      return res.data;
    }
  },
  *deleteosaemployee(
    { payload, action = 'getosaemployeepage' },
    { put, call },
  ) {
    const res = yield call(deleteosaemployee, payload);
    if (res?.success) {
      yield put({
        type: action,
      });
      return true;
    }
    return false;
  },
  *getmyosastatdata({ payload }, { put, call }) {
    const res = yield call(getmyosastatdata, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaSearchNum: res.data,
        },
      });
      return res.data;
    }
  },
  *initosaemployeedata({ payload }, { put, call }) {
    const res = yield call(initosaemployeedata, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *queryempweekplandata({ payload }, { put, call }) {
    const res = yield call(queryempweekplandata, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *getosaemployeedata({ payload }, { put, call }) {
    const res = yield call(getosaemployeedata, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          templateEditor: res.data,
        },
      });
      return res.data;
    }
  },
  *updateosaemployeedata({ payload }, { put, call }) {
    const res = yield call(updateosaemployeedata, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *updateosaemployeenoticedata({ payload }, { put, call }) {
    const res = yield call(updateosaemployeenoticedata, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *getosauserindexcatgtree({ payload }, { put, call }) {
    const res = yield call(getosauserindexcatgtree, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          dirTree: dirTreeOptions(res.data),
        },
      });
      return true;
    }
  },
  *getosauserindextree({ payload }, { put, call, select }) {
    console.log(
      'getosauserindextree==================payload================',
      payload,
    );
    const searchData = yield select(
      ({ performance }) => performance.osaIndicatorsPayload,
    );
    let osaIndicatorsPayload = { ...searchData, ...payload };
    const res = yield call(getosauserindextree, osaIndicatorsPayload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaIndicators: JSON.parse(JSON.stringify(res.data)),
          osaIndicatorsPayload,
        },
      });
      return JSON.parse(JSON.stringify(res.data));
    }
  },
  //查询月度策略
  *getosamonthstraget({ payload }, { put, call, select }) {
    console.log(
      'getosamonthstraget==================payload================',
      payload,
    );
    const searchData = yield select(
      ({ performance }) => performance.osaIndicatorsPayload,
    );
    let osaIndicatorsPayload = { ...searchData, ...payload };
    const res = yield call(getosamonthstraget, osaIndicatorsPayload);
    console.log(
      'getosamonthstraget==================res================',
      JSON.parse(res.data.monthStrategy),
    );
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          monthStrategy: JSON.parse(res.data.monthStrategy),
          osaIndicatorsPayload,
        },
      });
      return JSON.parse(JSON.stringify(res.data));
    }
  },

  //查询变更月度策略
  *getosachangemonthstraget({ payload }, { put, call, select }) {
    console.log(
      'getosachangemonthstraget==================payload================',
      payload,
    );
    const searchData = yield select(
      ({ performance }) => performance.osaIndicatorsPayload,
    );
    let osaIndicatorsPayload = { ...searchData, ...payload };
    const res = yield call(getosachangemonthstraget, osaIndicatorsPayload);
    console.log(
      'getosachangemonthstraget==================res================',
      JSON.parse(res.data.monthStrategy),
    );
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          monthStrategy: JSON.parse(res.data.monthStrategy),
          osaIndicatorsPayload,
        },
      });
      return JSON.parse(JSON.stringify(res.data));
    }
  },

  //新增|修改绩效月度策略
  *addupdateosamonthstraget({ payload }, { put, call, select }) {
    const res = yield call(addupdateosamonthstraget, payload);

    console.log(
      'addupdateosamonthstraget===========payload=======================',
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosamonthstraget',
        payload: { osaId: payload.osaId },
      });
      return true;
    }
  },

  //新增|修改绩效变更月度策略
  *addupdateosachangemonthstraget({ payload }, { put, call, select }) {
    const res = yield call(addupdateosachangemonthstraget, payload);

    console.log(
      'addupdateosamonthstraget===========payload=======================',
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosachangemonthstraget',
        payload: { osaId: payload.osaId },
      });
      return true;
    }
  },

  //查询历史月度策略
  *gethistoryosamonthstraget({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaselfDataSearch,
    );
    let osaselfDataSearch = { ...searchData, ...payload };
    const res = yield call(gethistoryosamonthstraget, osaselfDataSearch);
    console.log(
      'gethistoryosamonthstraget===========payload=======================',
      res.data,
    );
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaselfData: { datas: res.data },
          osaselfDataSearch,
        },
      });
      return true;
    }
  },

  *addosaemployeeindexdata({ payload }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(addosaemployeeindexdata, payload);

    console.log(
      'addosaemployeeindexdata完毕===========payload=======================',
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },
  *deleteosaemployeeindexcatgdata({ payload }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(deleteosaemployeeindexcatgdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },
  *deleteosaemployeeindexitemdata({ payload }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(deleteosaemployeeindexitemdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },

  *updateosaemployeeindexcatgdata({ payload, hasEdit }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(
      hasEdit ? updateosaemployeeindexcatgdata : addosaemployeeindexcatgdata,
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },
  *updateosaemployeeindexitemdata({ payload, hasEdit }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(
      hasEdit ? updateosaemployeeindexitemdata : addosaemployeeindexitemdata,
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },
  *moveosaemployeeindexcatgdata({ payload }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(moveosaemployeeindexcatgdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },
  *moveosaemployeeindexitemdata({ payload }, { put, call }) {
    payload.osaUserId = payload.osaId;
    payload.osaId = undefined;
    const res = yield call(moveosaemployeeindexitemdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
      });
      return true;
    }
  },
  *osaindexscore({ payload }, { put, call }) {
    const res = yield call(osaindexscore, payload);
    if (res?.success) {
      // yield put({
      //   type: 'getosauserindextree',
      // });
      return true;
    }
  },
  *updateosaempindexactucalvalue({ payload }, { put, call }) {
    const res = yield call(updateosaempindexactucalvalue, payload);
    if (res?.success) {
      // yield put({
      //   type: 'getosauserindextree',
      // });
      return true;
    }
  },

  *updateosaemployeescore({ payload }, { put, call }) {
    const res = yield call(updateosaemployeescore, payload);
    if (res?.success) {
      return res.data;
    }
  },

  *auditosaprocess({ payload }, { put, call, select }) {
    const res = yield call(auditosaprocess, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaProcessCache: {},
        },
      });
      return true;
    }
    return false;
  },
  *getosagradeaenablenum({ payload }, { put, call }) {
    const res = yield call(getosagradeaenablenum, payload);
    if (res?.success) {
      return res.data;
    }
    return false;
  },
  *getosaemployeeauditscoredata({ payload }, { put, call, select }) {
    const curOsaSettingData = yield select(
      ({ performance }) => performance.curOsaSettingData,
    );
    const res = yield call(getosaemployeeauditscoredata, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          curOsaSettingData: {
            ...curOsaSettingData,
            rows: {
              ...curOsaSettingData.rows,
              ...res.data,
            },
          },
        },
      });
      return res.data;
    }
    return false;
  },
  *launchosaweekplanchangeapply({ payload }, { put, call }) {
    const res = yield call(launchosaweekplanchangeapply, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *getosachangeprocesslist({ payload }, { put, call, select }) {
    const osaProcessCache = yield select(
      ({ performance }) => performance.osaProcessCache,
    );
    const res = yield call(getosachangeprocesslist, payload);
    if (res?.success) {
      payload.osaId && (osaProcessCache[payload.osaId] = res.data);
      yield put({
        type: 'save',
        payload: {
          osaProcessCache,
        },
      });
      return res.data;
    }
  },
  *getosachangeemployeedata({ payload }, { put, call }) {
    const res = yield call(getosachangeemployeedata, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          templateEditor: res.data,
        },
      });
      return res.data;
    }
  },
  *deleteosachangedata({ payload }, { put, call }) {
    const res = yield call(deleteosachangedata, payload);
    if (res?.success) {
      return true;
    }
    return false;
  },

  // 变更指标模块

  *getosachangeindextree({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaIndicatorsPayload,
    );
    let osaIndicatorsPayload = { ...searchData, ...payload };
    const res = yield call(getosachangeindextree, osaIndicatorsPayload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaIndicators: res.data,
          osaIndicatorsPayload,
        },
      });
      return true;
    }
  },
  *addosachangeindexdata({ payload }, { put, call }) {
    const res = yield call(addosachangeindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *deleteosachangeindexcatgdata({ payload }, { put, call }) {
    const res = yield call(deleteosachangeindexcatgdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *deleteosachangeindexitemdata({ payload }, { put, call }) {
    const res = yield call(deleteosachangeindexitemdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *getosachangeindexcatgtree({ payload }, { put, call }) {
    const res = yield call(getosachangeindexcatgtree, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          dirTree: res.data,
        },
      });
      return true;
    }
  },
  *updateosachangeindexcatgdata({ payload, hasEdit }, { put, call }) {
    const res = yield call(
      hasEdit ? updateosachangeindexcatgdata : addosachangeindexcatgdata,
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *updateosachangeindexitemdata({ payload, hasEdit }, { put, call }) {
    const res = yield call(
      hasEdit ? updateosachangeindexitemdata : addosachangeindexitemdata,
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *importosaempindexdata({ payload }, { put, call }) {
    const res = yield call(importosaempindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
        payload: {
          osaId: payload.osaId,
        },
      });
      return true;
    }
  },

  *moveosachangeindexcatgdata({ payload }, { put, call }) {
    const res = yield call(moveosachangeindexcatgdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *moveosachangeindexitemdata({ payload }, { put, call }) {
    const res = yield call(moveosachangeindexitemdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *copyhistoryosaindexdata({ payload }, { put, call }) {
    const res = yield call(copyhistoryosaindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosauserindextree',
        payload: { osaId: payload.curnOsaId },
      });
      return true;
    }
  },
  *copyhistoryosachangeindexdata({ payload }, { put, call }) {
    const res = yield call(copyhistoryosachangeindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
        payload: {
          osaId: payload.curnOsaId,
        },
      });
      return true;
    }
  },
  *importosachangeindexdata({ payload }, { put, call }) {
    const res = yield call(importosachangeindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosachangeindextree',
      });
      return true;
    }
  },
  *osaChangeAuditosaprocess({ payload }, { put, call }) {
    const res = yield call(osaChangeAuditosaprocess, payload);
    if (res?.success) {
      return true;
    }
    return false;
  },
  *getlastosachangedata({ payload }, { put, call, select }) {
    const res = yield call(getlastosachangedata, payload);
    const performance = yield select(({ performance }) => performance);
    let row = {};
    if (res?.success && res.data) {
      row = {
        ...res.data,
        title: res.data.userInfo?.value,
        key: res.data.osaMuId,
      };
    }
    yield put({
      type: 'save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: row,
        },
      },
    });
    return row;
  },

  // end
  // 周计划日期配置
  *getyearweekplandatedata({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.weekTimeTreeSearchData,
    );
    const weekTimeTreeSearchData = { ...searchData, ...payload };
    const res = yield call(getyearweekplandatedata, weekTimeTreeSearchData);
    if (res?.success) {
      let list = res.data?.osaWeekQuarterList ?? [];
      weekPlanDateToTree(list);
      yield put({
        type: 'save',
        payload: {
          // weekTimeTree: {
          //   title: res.data?.osaWeekPlanYear,
          //   key: res.data?.osaWeekPlanYear,
          //   children: weekPlanDateToTree(res.data?.osaWeekQuarterList),
          // },
          // weekTimeTree: weekPlanDateToTree(res.data?.osaWeekQuarterList),
          weekTimeTree: list,
          weekTimeTreeSearchData,
        },
      });
      return list;
    }
  },
  *initweekplanyeardatedata({ payload }, { put, call, select }) {
    const res = yield call(initweekplanyeardatedata, payload);
    if (res?.success) {
      yield put({
        type: 'getyearweekplandatedata',
      });
      return true;
    }
    return false;
  },
  *addupdateweekplandatedata({ payload }, { put, call }) {
    const res = yield call(addupdateweekplandatedata, payload);
    if (res?.success) {
      yield put({
        type: 'getyearweekplandatedata',
      });
      return true;
    }
  },
  *addupdateweekplandatedata({ payload }, { put, call }) {
    const res = yield call(addupdateweekplandatedata, payload);
    if (res?.success) {
      yield put({
        type: 'getyearweekplandatedata',
      });
      return true;
    }
  },
  *deleteweekplandatedata({ payload }, { put, call }) {
    const res = yield call(deleteweekplandatedata, payload);
    if (res?.success) {
      yield put({
        type: 'getyearweekplandatedata',
      });
      return true;
    }
  },
  *haveosachangeprocess({ payload }, { put, call }) {
    const res = yield call(haveosachangeprocess, payload);
    if (res?.success) {
      return res.data;
    }
    return false;
  },

  *getmychangeosapage({ payload, reset }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaChangeListSearchData,
    );
    let osaChangeListSearchData = { ...searchData, ...payload };
    if (reset) osaChangeListSearchData = payload;
    const res = yield call(getmychangeosapage, osaChangeListSearchData);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaChangeList: res.data,
          osaChangeListSearchData,
        },
      });
      return res.data;
    }
  },
  *getmychangeosastatdata({ payload }, { put, call }) {
    const res = yield call(getmychangeosastatdata, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaChangeSearchNum: res.data,
        },
      });
      return res.data;
    }
  },
  *updateosachangedata({ payload }, { put, call }) {
    const res = yield call(updateosachangedata, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *osachangeindexscore({ payload }, { put, call }) {
    const res = yield call(osachangeindexscore, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *updateosachangeindexactucalvalue({ payload }, { put, call }) {
    const res = yield call(updateosachangeindexactucalvalue, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *osaweekplanrevoke({ payload }, { put, call }) {
    const res = yield call(osaweekplanrevoke, payload);
    if (res?.success) {
      yield put({
        type: 'usercenter/getcompleteitemnewpage',
      });
      return true;
    }
  },
  *osaindexscorebatch({ payload }, { put, call }) {
    const res = yield call(osaindexscorebatch, payload);
    if (res?.success) {
      // yield put({
      //   type: 'usercenter/getcompleteitemnewpage',
      // });
      return true;
    }
  },
  *getosaauditsystemdescribelist({ payload }, { put, call }) {
    const res = yield call(getosaauditsystemdescribelist, payload);
    if (res?.success) {
      return res.data;
    }
    return null;
  },

  *getosaweekprocessrefuseinfo({ payload }, { put, call }) {
    const res = yield call(getosaweekprocessrefuseinfo, payload);
    if (res?.success) {
      return res.data;
    }
    return null;
  },

  *getosaweekchangeprocessrefuseinfo({ payload }, { put, call }) {
    const res = yield call(getosaweekchangeprocessrefuseinfo, payload);
    if (res?.success) {
      return res.data;
    }
    return null;
  },
};
