import { Timeline } from 'kenshin';
import PageLoading from '@/components/pageLoading';
import { CloseCircleFilled } from '@ant-design/icons';

const renderProcessList = (id, performance) => {
  if (!performance.osaProcessCache[id]) {
    return <PageLoading />;
  }
  let list = performance.osaProcessCache[id]?.osaProcessDataList ?? [];
  let backNodeId = performance.osaProcessCache[id]?.backNodeId;
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[id]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (backNodeId != null && list[index].processNodeId === backNodeId)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  return (
    <Timeline>
      {list.map((item, index) => (
        <Timeline.Item
          key={index}
          content={item.processDescribe}
          // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
          {...dots(index)}
        />
      ))}
    </Timeline>
  );
};

export default renderProcessList;
