import { Row, Col, message, Typography, Tag } from 'kenshin';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect, history } from 'umi';
import ItemDetail from './itemDetail';
import ModalFormPro from '../ModalFormPro';
import './index.less';
import { useDrag, useMemoizedFn } from 'ahooks';
import { timeDiff } from '../../_util';
import { ServContext } from '../../pages/project/list/AddProject';
import { debounce, flat } from '@/_util';
import SortBox from './SortBox';

let idCacheMap = new Map();
// 调试缓存
let cacheNum = 0;
let baseNum = 0;
// 树遍历查找
export const findTree = (fildTree = [], id) => {
  if (id == null || id == undefined || fildTree.length == 0) {
    return undefined;
  }
  let hasH = /-/.test(id);
  let cacheId = hasH ? id.split('-')[1] : id;
  // 缓存机制，多次调用查找树，存在缓存中则取缓存
  if (idCacheMap.get(cacheId)) {
    // cacheNum++;
    // console.log(idCacheMap);
    // console.log('执行函数次数', baseNum);
    // console.log('取缓存数据次数', cacheNum);
    return idCacheMap.get(cacheId);
  }
  let resData = flat(
    fildTree.map((item) => item.options),
    2,
  ).find((item) => {
    if (!hasH && item.value?.split('-')[1] == id) {
      return true;
    }
    if (hasH && item.value == id) {
      return true;
    }
  });
  // baseNum++;
  idCacheMap.set(cacheId, resData);
  return resData;
};

// 查自己添加的服务类目对应树节点
export const dataListFind = (list, key, findParent = false, parent = null) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (item.key == key) {
      if (findParent) return parent;
      return item;
    }
    if (item.childList) {
      let findItem = dataListFind(item.childList, key, findParent, item);
      if (findItem) return findItem;
    }
  }
};

// 服务类目金额计算公式
export const getPrice = (data, pirceChange) => {
  /*

    单价、总优惠金额不需要传给后端，前端展示用，

    servCatgTotalDisc：总价折扣率需要处理后传给后端
    总价折扣率 = 总价 / 优惠后的总价

  */
  const {
    servCatgCount, // 数量
    servCatgHourOrginCost, // 工时费
    servCatgOrginTotalAmount, // 总价
    servCatgDiscTotalAmount, // 优惠后总价
  } = data;

  let servCatgWorkPeriod = 0, // 工时数
    servCatgUnitPrice = 0, // 单价
    servCatgHourDiscCost = 0, // 优惠后单价
    servCatgHourDisc = 0, // 优惠折扣
    DiscountAmount = 0; // 总优惠金额
  // 单价 = 总价 / 数量
  if (servCatgOrginTotalAmount && servCatgCount) {
    servCatgUnitPrice = +(servCatgOrginTotalAmount / servCatgCount).toFixed(2);
  }
  // 工时数 = 单价 / 工时费
  if (servCatgUnitPrice && servCatgHourOrginCost) {
    servCatgWorkPeriod = +(servCatgUnitPrice / servCatgHourOrginCost).toFixed(
      2,
    );
  }
  // 优惠后单价 = 优惠后总价 / 数量
  if (servCatgDiscTotalAmount && servCatgCount) {
    servCatgHourDiscCost = +(servCatgDiscTotalAmount / servCatgCount).toFixed(
      2,
    );
    pirceChange?.(servCatgHourDiscCost);
  }
  // 优惠折扣 = 优惠后总价 / 总价
  if (+servCatgDiscTotalAmount && +servCatgOrginTotalAmount) {
    servCatgHourDisc = +(
      servCatgDiscTotalAmount / servCatgOrginTotalAmount
    ).toFixed(2);
  }
  // 总优惠金额 = 总价 - 优惠后总价
  if (servCatgOrginTotalAmount && servCatgDiscTotalAmount) {
    DiscountAmount = +(
      servCatgOrginTotalAmount - servCatgDiscTotalAmount
    ).toFixed(2);
  }

  return {
    servCatgWorkPeriod,
    servCatgUnitPrice,
    servCatgHourDiscCost,
    servCatgHourDisc,
    DiscountAmount,
  };
};

export const sortToArrData = (baseArr, prefix) => {
  if (!Array.isArray(baseArr)) return [];
  let resArr = [];
  baseArr.map((item, index) => {
    let servCatgCode = [prefix, index + 1]
      .filter((item) => item != undefined)
      .join('.');

    if (item.servCatgCode != servCatgCode) {
      // 只需要改变过的
      resArr.push({
        proServCatgId: item.proServCatgId,
        sort: index,
        // name: item.servCatgName,
        servCatgCode,
      });
    }
    if (item.childList) {
      let childArr = sortToArrData(item.childList, servCatgCode);
      resArr = [...resArr, ...childArr];
    }
  });
  return resArr;
};

let TeamMemberListOldArr = [];
//设置参与团队，已经选择
let TeamMemberListArr = [];
const ServCategoryList = ({
  systemOrgani,
  dispatch,
  project,
  value,
  onChange,
  // 关闭编辑等按钮
  disabled,
  // 顶部扩展，放在按钮后面
  headerExtra,
  extra,
  pirceChange,
  onDelete,
  onSort,
  onSave,
  loading,
  rowData, // 列表数据
}) => {
  const modalRef = useRef();
  // const { setDeleteList } = useContext(ServContext);
  const [dataList, setDataList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [leaderKey, setLeaderKey] = useState('');

  // console.log("组件重置",rowData);

  useEffect(() => {
    console.log('systemOrgani.employeeAll...', systemOrgani.employeeAll);
  }, [systemOrgani.employeeAll]);

  useEffect(() => {
    if (
      Array.isArray(value) &&
      systemOrgani.employeeAll
      // dataList.length == 0
    ) {
      setDataList([...JSON.parse(JSON.stringify(value))]);
      setTeamMemberListData();
    }
  }, [value, systemOrgani.employeeAll]);

  useEffect(() => {
    //设置参与团队，已经选择
    setTeamMemberListData();
    // TeamMemberListArr = [];
    // if (rowData.proPreallocationMemberList) {
    //   console.log('TeamMemberListArr,首次', TeamMemberListArr);
    //   rowData.proPreallocationMemberList.map((item) => {
    //     systemOrgani.employeeAll.map((employee) => {
    //       employee.options.map((o) => {
    //         if (item && item.teamMemberId == o.value.split('-')[1]) {
    //           TeamMemberListArr.push(o);
    //         }
    //       });
    //     });
    //   });
    //   console.log('TeamMemberListArr,首次1', TeamMemberListArr);
    //   // 去重
    //   TeamMemberListArr =  Array.from(new Set(TeamMemberListArr.map(a => JSON.stringify(a)))).map(str => JSON.parse(str));
    //   console.log('TeamMemberListArr,首次去重', TeamMemberListArr);
    //   TeamMemberListOldArr = TeamMemberListArr;
    // }
  }, []);

  const setTeamMemberListData = () => {
    //设置参与团队，已经选择
    TeamMemberListArr = [];
    if (rowData.proPreallocationMemberList) {
      console.log('TeamMemberListArr,首次', TeamMemberListArr);
      rowData.proPreallocationMemberList.map((item) => {
        systemOrgani.employeeAll.map((employee) => {
          employee.options.map((o) => {
            if (item && item.teamMemberId == o.value.split('-')[1]) {
              TeamMemberListArr.push(o);
            }
          });
        });
      });
      console.log('TeamMemberListArr,首次1', TeamMemberListArr);
      // 去重
      TeamMemberListArr = Array.from(
        new Set(TeamMemberListArr.map((a) => JSON.stringify(a))),
      ).map((str) => JSON.parse(str));
      console.log('TeamMemberListArr,首次去重', TeamMemberListArr);
      TeamMemberListOldArr = TeamMemberListArr;
    }
  };

  const addItem = () => {
    console.log('rowData =', rowData);
    console.log('TeamMemberListArr,addItem', TeamMemberListArr);
    setEditModalVisible(true);
    const { form } = modalRef.current;
    form.setFieldsValue({ servCatgHourOrginCost: 1800 });
    form.setFieldsValue({ addTeamMemberList: TeamMemberListArr });
    TeamMemberListOldArr = TeamMemberListArr;
    console.log('TeamMemberListArr,addItem1', TeamMemberListOldArr);
  };

  // 服务类目编辑或新增完成
  const editChange = async (val) => {
    // 子级新增
    // console.log("编辑",editModalData,val);
    // if(!editModalData || editModalData?.$childrenAdd){
    //   if(val.servCatgDiscTotalAmount > residueMoney){
    //     message.error("")
    //     return ;
    //   }
    // }

    // console.log(11111)

    //新增项目预分配成员列表，如果对比旧成员有新增
    addpropreallocationmemberdata(val.addTeamMemberList, TeamMemberListOldArr);

    // console.log(11112)

    if (editModalData?.$childrenAdd) {
      editModalData.childList = editModalData.childList || [];
      let curData = dataListFind(dataList, editModalData.key);
      let otherData = {
        proServCatgPantId: curData.proServCatgId,
        servCatgCode: `${curData.servCatgCode}.${
          (curData.childList?.length || 0) + 1
        }`,
        servCatgSort: (curData.childList?.length || 0) + 1,
      };
      let data = {
        key: +new Date(),
        ...val,
      };
      // console.log(11113)
      let success = await onSave?.(data, otherData);
      if (success) {
        setEditModalData(null);
        setEditModalVisible(false);
        // console.log(11114)
      }
      return;
    }
    let data = {
      key: +new Date(),
      $top: true,
    };
    Object.keys(val).map((item) => {
      data[item] = val[item] || null;
    });
    if (
      editModalData &&
      data.servCatgCount < editModalData?.servCatgFinishNum
    ) {
      message.warning('数量不能小于已关闭的的数量！');
      return;
    }
    console.log(11115);
    // console.log('编辑', data, editModalData);
    let success = await onSave?.(
      data,
      editModalData || {
        servCatgCode: dataList.length + 1,
        servCatgSort: dataList.length + 1,
      },
    );
    if (success) {
      // console.log(11116)
      setTimeout(() => {
        setEditModalData(null);
        setEditModalVisible(false);
      }, 500);

      message.success('更新成功！');
      // console.log(11117)
    }
  };

  // 服务类目更改后自动填入数据
  const setFormValueChange = (id) => {
    const { form } = modalRef.current;
    dispatch({
      type: 'project/getServcatgDetailList',
      payload: {
        serviceCategoryId: id,
      },
    }).then((res) => {
      // 自动填充
      form.setFieldsValue({ ...res, servCatgTime: null });
    });
  };

  //新增项目预分配成员列表，如果对比旧成员有新增
  const addpropreallocationmemberdata = (newarr, oldarr) => {
    newarr = newarr.map((item) => {
      if (typeof item === 'object') {
        return item.value;
      } else {
        return item;
      }
    });

    oldarr = oldarr.map((item) => {
      if (typeof item === 'object') {
        return item.value;
      } else {
        return item;
      }
    });

    console.log('addpropreallocationmemberdata...', newarr, oldarr);
    // var filteredArr = [...newarr, ...oldarr.map((item) => item.value)];
    // = newarr.filter(
    //   (item1) => !oldarr.find((item2) => item2.value === item1),
    // );

    if (newarr == undefined || newarr.length == 0) {
      return;
    }

    // if(typeof oldarr[0] === "object"){
    //     // 将oldarr中的元素转换为字符串形式
    //     const oldarrString = oldarr.map(obj => JSON.stringify(obj));

    //     // 找出newarr中在oldarr中不存在的元素
    //     const increment = newarr.filter(obj => !oldarrString.includes(JSON.stringify(obj)));

    //     newarr = Array.from(new Set(increment));
    //     console.log("newarr 1 = ",newarr);
    //     if(newarr.length == 0){
    //       return
    //     }
    // }else{
    //   const oldarrString = new Set(oldarr);
    //   const incrementObjects = newarr.filter(obj => !oldarrString.has(obj));
    //   newarr = Array.from(new Set(incrementObjects));
    //   console.log("newarr 2 = ",newarr);

    //   if(newarr.length == 0){
    //     return
    //   }
    // }

    newarr = [...new Set([...newarr, ...oldarr])];

    console.log('filteredArr = ', newarr);
    let projectId = history.location.query?.projectId;
    dispatch({
      type: 'project/addProprealLocationMemberData',
      payload: newarr?.map((item, index) => {
        // console.log('addpropreallocationmemberdata...item', item.value.split("-"));
        return {
          projectId: projectId,
          teamMemberId: item.value
            ? item.value.split('-')[1]
            : item.split('-')[1],
          teamMemberSort: index + 1,
        };
      }),
    }).then((res) => {
      TeamMemberListArr = [];
      newarr.map((item) => {
        systemOrgani.employeeAll.map((employee) => {
          employee.options.map((o) => {
            if (item && item == o.value) {
              TeamMemberListArr.push(o);
            }
          });
        });
      });
      // console.log("addpropreallocationmemberdata...TeamMemberListArr",TeamMemberListArr);
    });
  };

  const conutChange = (_val, target) => {
    const { form } = modalRef.current;
    let servCatgCount = form.getFieldValue('servCatgCount'); // 数量
    let servCatgHourOrginCost = form.getFieldValue('servCatgHourOrginCost');
    let servCatgOrginTotalAmount = form.getFieldValue(
      'servCatgOrginTotalAmount',
    );
    let servCatgDiscTotalAmount = form.getFieldValue('servCatgDiscTotalAmount');
    if (target == 'servCatgOrginTotalAmount') {
      servCatgDiscTotalAmount = servCatgOrginTotalAmount;
    }
    form.setFieldsValue({
      ...getPrice(
        {
          servCatgCount,
          servCatgHourOrginCost,
          servCatgOrginTotalAmount,
          servCatgDiscTotalAmount,
        },
        editModalData?.proServCatgId ? pirceChange : null,
      ),
      servCatgDiscTotalAmount,
    });
  };

  const selectTeamChange = (id, item) => {
    console.log('selectTeamChange.....', id, item);
    let projectId = history.location.query?.projectId;
    if (projectId && editModalData?.proServCatgId) {
      const { form } = modalRef.current;
      let oldArr = form.getFieldsValue()?.addTeamMemberList || [];
      let filteredArr = oldArr.filter((item) => item.value !== id);
      form.setFieldsValue({
        addTeamMemberList: [...filteredArr],
      });
      //   dispatch({
      //     type: 'project/addTeamRule',
      //     payload: {
      //       projectId,
      //       proServCatgId: editModalData.proServCatgId,
      //       teamMemberId: id.split('-')[1],
      //     },
      //   }).then((res) => {
      //     if (!res) {
      //       message.warn('该成员存在上报信息！');

      //     }
      //   });
    }
  };

  const leaderChange = (keys, value) => {
    // console.log("keys=",keys,"value=",value)
    const { form } = modalRef.current;
    // console.log("form=",form)
    let oldArr = form.getFieldsValue()?.addTeamMemberList || [];
    // console.log("oldArr=",oldArr)
    let lastLeaderIndex = oldArr.findIndex((item) => item == leaderKey);
    if (lastLeaderIndex != -1) oldArr.splice(lastLeaderIndex, 1);
    setLeaderKey(keys);
    oldArr.push(value);
    // oldArr = [...new Set(oldArr)];
    oldArr = Array.from(new Set(oldArr.map(JSON.stringify))).map(JSON.parse);
    // console.log("oldArr1=",oldArr)
    form.setFieldsValue({
      addTeamMemberList: oldArr,
    });
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const residueMoney = useMemo(() => {
    let listCount = dataList.reduce(
      (pre, item) => pre + item.servCatgDiscTotalAmount,
      0,
    );
    return +((rowData?.projectAmount || 0) / 100).toFixed(2) - listCount;
  }, [dataList]);

  const edtaFormItem = [
    {
      label: '选择服务类目',
      name: 'servCatgId',
      type: 'TreeSelect',
      required: '请选择服务类目',
      props: {
        placeholder: '请选择服务类目',
        treeDefaultExpandAll: true,
        fieldNames: {
          label: 'servCatgName',
          value: 'servCatgId',
          children: 'child',
        },
        // options: project.servcatgEasyList.map((item) => ({
        treeData: [project.servcatgList],
        onChange: setFormValueChange,
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    {
      label: '工作包别名',
      name: 'servCatgName',
      required: '工作包别名不能为空',
      type: 'Input',
      props: {
        placeholder: '请输入工作包别名',
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    {
      label: '工时费',
      name: 'servCatgHourOrginCost',
      type: 'InputNumber',
      props: {
        placeholder: '请输入工时费',
        stepType: 'inside',
        onChange: debounce(
          (val) => conutChange(val, 'servCatgHourOrginCost'),
          300,
        ),
        style: { width: '100%' },
        min: 0,
        disabled: !!editModalData && !editModalData?.$childrenAdd,
      },
    },
    {
      label: '选择责任人',
      name: 'servCatgLeader',
      type: 'Select',
      props: {
        placeholder: '请选择责任人',
        options: systemOrgani.employeeAll,
        onChange: leaderChange,
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    {
      label: '选择参与团队',
      name: 'addTeamMemberList',
      type: 'Select',
      props: {
        placeholder: '请选择参与团队',
        mode: 'multiple',
        allowClear: true,
        maxTagCount: 'responsive',
        options: systemOrgani.employeeAll,
        onDeselect: selectTeamChange, //删除不能调接口，只能添加
        // onChange: leaderChange,
        disabled: editModalData?.servCatgStatus == 1,
      },
    },

    {
      label: '所用工期/天（工时数）',
      name: 'servCatgWorkPeriod',
      type: 'Input',
      props: {
        placeholder: '请输入所用工期',
        // disabled: true,
        style: { width: '100%' },
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    {
      label: '数量',
      name: 'servCatgCount',
      type: 'InputNumber',
      props: {
        placeholder: '请输入数量',
        stepType: 'inside',
        onChange: debounce((val) => conutChange(val, 'servCatgCount'), 300),
        style: { width: '100%' },
        min: 0,
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    {
      label: '单价',
      name: 'servCatgUnitPrice',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '单价',
      },
    },
    {
      label: '优惠后单价',
      name: 'servCatgHourDiscCost',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '优惠后单价',
      },
    },
    {
      label: '单位',
      name: 'servCatgUnit',
      type: 'Input',
      props: {
        placeholder: '请输入单位',
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    {
      label: '总价',
      name: 'servCatgOrginTotalAmount',
      type: 'InputNumber',
      props: {
        placeholder: '请输入总价',
        stepType: 'inside',
        onChange: debounce(
          (val) => conutChange(val, 'servCatgOrginTotalAmount'),
          300,
        ),
        style: { width: '100%' },
        min: 0,
        disabled: !!editModalData && !editModalData?.$childrenAdd,
      },
    },
    {
      label: '优惠后总价',
      name: 'servCatgDiscTotalAmount',
      type: 'InputNumber',
      help: `项目剩余金额：${residueMoney}`,
      props: {
        placeholder: '请输入优惠后总价',
        stepType: 'inside',
        onChange: debounce(
          (val) => conutChange(val, 'servCatgDiscTotalAmount'),
          300,
        ),
        style: { width: '100%' },
        min: 0,
        disabled: !!editModalData && !editModalData?.$childrenAdd,
      },
    },
    {
      label: '优惠折扣',
      name: 'servCatgHourDisc',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '优惠折扣',
      },
    },
    {
      label: '总优惠金额',
      name: 'DiscountAmount',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '总优惠金额',
      },
    },
    {
      label: '工作包周期',
      name: 'servCatgTime',
      type: 'DatePickerRangePicker',
      props: {
        // onChange: timeChange,
        showTime: {
          hideDisabledOptions: true, //不显示禁用的时间
          defaultValue: [moment('09:00:00', 'HH:mm'), moment('18:00', 'HH:mm')],
          minuteStep: 30,
          format: 'HH:mm',
        },
        disabledTime: (_, type, b) => ({
          disabledHours: () => {
            let arr = range(0, 24);
            arr.splice(9, 10);
            if (type == 'start') arr = arr.concat([12, 18]);
            if (type == 'end') arr = arr.concat([13, 9]);
            return arr;
          },
          disabledMinutes: (hours) => {
            if (hours == 12) return [30];
            if (hours == 13) return [0];
            return [];
          },
        }),
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
    // {
    //   label: '服务类目金额',
    //   render: () => <div>{countMoney}</div>,
    // },
    {
      label: '功能详情描述',
      name: 'servCatgRemark',
      type: 'TextArea',
      formItemProps: {
        className: 'block-item',
      },
      props: {
        placeholder: '请输入功能详情描述',
        disabled: editModalData?.servCatgStatus == 1,
      },
    },
  ];

  const beforeDel = async (row) => {
    if (!row.projectId) {
      return true;
    }
    let shouldDel = true;
    if (row.proServCatgId) {
      await dispatch({
        type: 'project/delServcatgRule',
        payload: {
          proServCatgId: row.proServCatgId,
          projectId: row.projectId,
        },
      }).then((res) => {
        shouldDel = res;
        if (!res) {
          message.warn('该服务类目存在上报信息，不可删除！');
        }
      });
    }
    return shouldDel;
  };

  const deleteItem = async (row) => {
    await onDelete?.(row);
    let list = [...dataList];
    let i = list.findIndex((item) => item.key == row.key);
    dataList.splice(i, 1);
    // 删除之后通过重新排序设置code跟排序
    onSort?.(sortToArrData([...dataList]));
  };
  const copyItem = (data) => {
    if (data.$top) {
      return editChange({
        ...data,
        dbServCatgTeamMemberList: [],
      });
    }
    // code 生成
    let servCatgCodeArr = data.servCatgCode?.split('.') || [];
    let parent = dataListFind(dataList, data.key, true);
    servCatgCodeArr.splice(
      servCatgCodeArr.length - 1,
      1,
      parent?.childList?.length + 1,
    );
    let servCatgCode = servCatgCodeArr.join('.');
    let payload = {
      ...data,
      proServCatgId: undefined,
      childList: undefined,
      servCatgAlias: undefined,
      servCatgLeaderName: undefined,
      servCatgFinishNum: undefined,
      servCatgDbcode: undefined,
    };
    onSave?.(payload, {
      servCatgCode,
      servCatgSort: parent?.childList?.length + 1,
      proServCatgPantId: data.proServCatgPantId,
    });
  };

  const editItem = (row) => {
    const { form } = modalRef.current;
    if (row.servCatgTime) {
      row.servCatgTime[0] = moment(row.servCatgTime[0]);
      row.servCatgTime[1] = moment(row.servCatgTime[1]);
    }
    setEditModalVisible(true);
    setLeaderKey(row.taskLeader);
    setEditModalData({
      ...row,
      ...getPrice(row),
    });
    form.setFieldsValue({
      ...row,
      ...getPrice(row),
    });
  };
  const addItemToChildren = (row) => {
    setEditModalVisible(true);
    setEditModalData({
      ...row,
      $childrenAdd: true,
    });
  };

  const sortChange = (from, to) => {
    let list = [...dataList];
    if (from.key != to.key) {
      let targetIndex = list.findIndex((item) => item.key == from.key);
      let target = list.splice(targetIndex, 1)?.[0];
      if (target) {
        let dropIndex = list.findIndex((item) => item.key == to.key);
        let index = targetIndex > dropIndex ? 0 : 1;
        list.splice(dropIndex + index, 0, target);
      }
    }
    let res = sortToArrData(list);
    if (res.length > 1) {
      onSort?.(res);
    }
    // resList = [...list];
    // return [...list];
    // onChange?.(resList);
  };

  return (
    <div className="ServCategoryList">
      {dataList.map((item, index) => {
        let calHeaderl = (
          <Row className="detail-box">
            <Col span={2}>
              <span className="c999">
                {item.servCatgCode} {item.servCatgName}
              </span>
            </Col>
            <Col span={2}>
              责任人：
              {systemOrgani.employeeAll && (
                <span className="c999">
                  {
                    findTree(systemOrgani.employeeAll, item.servCatgLeader)
                      ?.label
                  }
                </span>
              )}
            </Col>
            <Col span={2}>
              <Typography.Paragraph ellipsis>
                参与制作：
                <span className="c999">
                  {item.addTeamMemberList
                    ?.map((id) => findTree(systemOrgani.employeeAll, id)?.label)
                    .join(',')}
                </span>
              </Typography.Paragraph>
            </Col>
            <Col span={2}>
              单价：
              {item.servCatgHourDiscCost}
            </Col>
            <Col span={2}>
              已完成：
              {item.servCatgFinishNum} / {item.servCatgCount}
            </Col>
            <Col span={2}>
              总金额：
              {item.servCatgDiscTotalAmount}
            </Col>
            <Col span={2}>
              完成金额：
              {item.servCatgFinishNum == item.servCatgCount
                ? item.servCatgDiscTotalAmount
                : +(item.servCatgFinishNum * item.servCatgHourDiscCost).toFixed(
                    2,
                  )}
            </Col>
            <Col span={2}>
              未完成金额：
              {
                +(
                  (item.servCatgCount - item.servCatgFinishNum) *
                  item.servCatgHourDiscCost
                ).toFixed(2)
              }
            </Col>
            <Col span={2}>
              状态：
              {item.servCatgStatus == 1 ? (
                <Tag.MiniTag type="success">已关闭</Tag.MiniTag>
              ) : (
                <Tag.MiniTag>未关闭</Tag.MiniTag>
              )}
            </Col>
          </Row>
        );
        return (
          <SortBox data={item} onChange={sortChange} key={item.key}>
            <ItemDetail
              disabled={disabled}
              headerExtra={headerExtra}
              extra={extra}
              data={item}
              headerEl={calHeaderl}
              addItem={addItemToChildren}
              deleteItem={deleteItem}
              beforeDel={beforeDel}
              editItem={editItem}
              copyItem={copyItem}
              onChange={onChange}
              employeeAll={systemOrgani.employeeAll}
              servcatgEasyList={project.servcatgEasyList}
              key={item.key}
              setDataList={setDataList}
              onCancel={() =>
                setDataList((pre) => {
                  pre.splice(index, 1);
                  // onChange?.(pre);
                  return [...pre];
                })
              }
            />
          </SortBox>
        );
      })}
      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        width={'80%'}
        formProps={{
          layout: 'vertical',
          labelCol: { span: 8 },
          wrapperCol: { span: 12 },
          className: 'inline-form',
          initialValues: {
            // servCatgHourOrginCost: 1800,
          },
        }}
        ref={modalRef}
        btnLoading={loading['project/updataServcat']}
        onSave={editChange}
        onCancel={() => {
          setEditModalVisible(false);
          setEditModalData(null);
        }}
      />
      {!disabled && (
        <div className="dashedBtn" onClick={addItem}>
          + 添加
        </div>
      )}
    </div>
  );
};

export default connect(({ systemOrgani, project, loading }) => ({
  systemOrgani,
  project,
  loading: loading.effects,
}))(ServCategoryList);
