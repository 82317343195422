import {
  Button,
  Typography,
  message,
  Radio,
  Input,
  Tabs,
  Timeline,
  MessageBox,
} from 'kenshin';
import CollapseCard, { Template } from '../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../../assessment/_keys';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import Indicators from '../../components/CollapseCard/template/indicators';
import { exportosachangeindexdata } from '@/_serveice/performance/osa';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { CloseCircleFilled } from '@ant-design/icons';

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

const isShowConfirmBtn = (row, list, curData) => {
  if (!(list?.length > 0)) return true;
  if (history.location.query.osaId) return false;
  if (history.location.query.busiId) return true;

  if (
    row &&
    (row.osaProcessDataList[2]?.curnNode || row.osaProcessDataList[3]?.curnNode)
  ) {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    return curData.busiLeader?.id == localEmpId;
  }
  if (
    row &&
    (row.osaProcessDataList[0].curnNode || row.osaProcessDataList[1].curnNode)
  ) {
    return true;
  }
  return false;
};
const isShowReturnBtn = (row) => {
  if (!row) return false;
  if (
    row &&
    (row.osaProcessDataList[0].curnNode || row.osaProcessDataList[1].curnNode)
  ) {
    return false;
  }
  if (history.location.query.osaId) {
    return false;
  }
  if (history.location.query.busiId) {
    return true;
  }
  return false;
};
const isShowCancelBtn = (row) => {
  if (history.location.query.busiId) {
    return false;
  }
  return !row?.processOver;
};

const indicatorsKey = {
  DIR: 1,
  ITEM: 2,
};

const dispatchApi = {
  GETTREE: 'performance/getosachangeindextree',
  GETDIRTREE: 'performance/getosachangeindexcatgtree',
  UPDATEDIR: 'performance/updateosachangeindexcatgdata',
  UPDATEITEM: 'performance/updateosachangeindexitemdata',
  DELETEDIR: 'performance/deleteosachangeindexcatgdata',
  DELETEITEM: 'performance/deleteosachangeindexitemdata',
  ADDTREE: 'performance/addosachangeindexdata',
  MOVEDIR: 'performance/moveosachangeindexcatgdata',
  MOVEITEM: 'performance/moveosachangeindexitemdata',
  IMPOERSHEET: 'performance/importosachangeindexdata',
  COPYHISTORY: 'performance/copyhistoryosachangeindexdata',
};

/** 递归校验目录必须含有一个或多个指标 */
const indicatorsValidate = (list) =>
  list.every((item) => {
    if (item.type == indicatorsKey.DIR && !(item.childList?.length > 0)) {
      return false;
    }
    if (Array.isArray(item.childList)) {
      return indicatorsValidate(item.childList);
    }
    return true;
  });

const createTemplate = ({ performance, dispatch, loadingEf }) => {
  // 当前页面数据
  let curData = performance.curOsaSettingData?.rows ?? {};

  let disabled = true;

  const [editModalVisible, setEditModalVisible] = useState(false);
  const auditResult = useRef(AUDITTYPE.SUCCESS);

  useEffect(() => {
    init();
  }, [history.location.query.busiId]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  const init = async () => {
    if (history.location.query.busiId) {
      curData = await dispatch({
        type: 'performance/getosachangeemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                },
              },
            },
          });
          return res;
        }
        return {};
      });
    } else if (history.location.query.osaId) {
      curData =
        (await dispatch({
          type: 'performance/getlastosachangedata',
          payload: {
            osaId: history.location.query.osaId,
          },
        })) ?? {};
    } else if (curData.osaId) {
      curData.osaNewId = await dispatch({
        type: 'performance/launchosaweekplanchangeapply',
        payload: {
          osaId: curData.osaId,
        },
      });
    }
    if (curData.osaId && curData.osaNewId) {
      dispatch({
        type: 'performance/getosaemployeeauditscoredata',
        payload: {
          osaId: curData.osaId,
        },
      });
      getProcessList(curData);
    }
  };

  /** 流程节点列表 */
  const getProcessList = (curData) => {
    dispatch({
      type: 'performance/getosachangeprocesslist',
      payload: {
        osaId: curData.osaNewId,
        todoBusiId: '',
      },
    });
  };

  // 基本信息的附加项
  const infoParams = {
    title: '考核类型',
    dataIndex: 'osaType',
    valueType: 'RadioGroup',
    fieldProps: {
      placeholder: '请输入考核方案名称',
      disabled,
      options: [
        { label: '试用期转正评估', value: 1 },
        { label: '绩效评估', value: 2 },
        { label: '绩效改进评估', value: 3 },
        { label: '个人发展计划', value: 4 },
      ],
    },
  };

  const baseCloumns = [
    {
      title: '考核周期',
      valueType: 'RadioGroup',
      fieldProps: {
        disabled: true,
        value: curData.osaWeekType,
        options: [
          {
            label: '周',
            value: 1,
          },
          {
            label: '季度',
            value: 3,
          },
        ],
      },
    },
    {
      title: '考核有效期',
      valueType: 'DatePickerRangePicker',
      fieldProps: {
        disabled,
        value: [moment(curData.osaBeginTime), moment(curData.osaEndTime)],
        placeholder: ['开始时间', '结束时间'],
      },
    },
    // {
    //   title: '自动分发时间',
    //   valueType: 'InputNumber',
    //   fieldProps: {
    //     disabled,
    //     value: curData.osaNoticeDaysBeforeAfter,
    //     placeholder: '自动分发时间',
    //     stepType: 'inside',
    //   },
    // },
    {
      title: '考核模式',
      valueType: 'Select',
      extra:
        '在既定时间内完成目标，且有多付出、多贡献、有特别亮点的项目则视情况给予附加分1-20分',
      fieldProps: {
        value: curData.osaScoreRule,
        placeholder: '请选择考核模式',
        disabled,
        options: [
          {
            label: '满分100分制（可向上浮动20%）',
            value: 1,
          },
        ],
      },
    },
  ];

  const handleCancel = () => {
    if (curData.osaNewId) {
      MessageBox.confirm({
        title: '确认操作',
        content: '确认删除？',
        onOk: () => {
          dispatch({
            type: 'performance/deleteosachangedata',
            payload: {
              osaId: curData.osaNewId,
            },
          }).then((success) => {
            if (success) {
              goback();
            }
          });
        },
      });
    }
  };

  let list =
    performance.osaProcessCache[curData.osaNewId]?.osaProcessDataList ?? [];
  let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[curData.osaNewId]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (backNodeId != null && list[index].processNodeId === backNodeId)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  const hasAuditBtnText = (row) => {
    let textObj = [
      '完成修改',
      '确认绩效',
      '审核绩效',
      '审核绩效',
      '审核绩效',
      '审核绩效',
    ];
    if (curData?.osaWeekType == 1) {
      textObj = [
        '完成修改',
        '确认周计划',
        '审核周计划',
        '审核周计划',
        '审核周计划',
      ];
    }
    if (row && row.osaProcessDataList[0]) {
      let arr = row.osaProcessDataList.filter((item) => item.curnNode);
      let i = row.osaProcessDataList.indexOf(arr[arr.length - 1]);
      return textObj[i];
    }
    return '完成修改';
  };

  const handleExport = (type) => {
    let payload = {
      osaId: curData.osaNewId ?? '',
      type: type ?? '',
      // ...value,
    };
    let url = `${baseURL}${exportosachangeindexdata}${splitParamsFoGet(
      payload,
    )}`;

    downloadXlsx(url);
  };

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const HeaderButton = (
    <div className="fixed-right">
      {curData.osaNewId &&
        isShowConfirmBtn(
          performance.osaProcessCache[curData.osaNewId],
          list,
          curData,
        ) && (
          <Button
            type="primary"
            onClick={() => {
              if (!indicatorsValidate(performance.osaIndicators)) {
                message.error('指标目录应含有指标');
                return;
              }
              setEditModalVisible(true);
              auditResult.current = AUDITTYPE.SUCCESS;
            }}
          >
            {hasAuditBtnText(performance.osaProcessCache[curData.osaNewId])}
          </Button>
        )}
      {isShowReturnBtn(performance.osaProcessCache[curData.osaNewId]) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      {curData.osaNewId &&
        isShowCancelBtn(performance.osaProcessCache[curData.osaNewId]) && (
          <Button
            type="danger"
            loading={loadingEf['performance/deleteosachangedata']}
            onClick={() => handleCancel()}
          >
            删除变更申请
          </Button>
        )}
      {curData.osaNewId &&
        isShowCancelBtn(performance.osaProcessCache[curData.osaNewId]) && (
          <Button
            type="primary"
            onClick={() => handleExport(curData.osaWeekType ? 2 : 1)}
          >
            导出
          </Button>
        )}
      <Button type="primary" onClick={goback}>
        返回
      </Button>
    </div>
  );

  const edtaFormItem = [
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
  ];

  const editModalSave = async (value) => {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    const list = performance.osaProcessCache[curData.osaNewId]
      ?.osaProcessDataList ?? [{ curnNode: true }]; // 没有则给个初始值
    // 节点索引
    let index = list.findIndex((item) => item.curnNode);
    let payload = {
      ...value,
      osaId: curData.osaNewId,
      auditResult: auditResult.current,
    };
    let step = 1;
    if (
      (index == 2 && curData.busiLeader?.id == localEmpId) ||
      (index == 0 && curData.osaCreatorId == curData.osaMuId)
    ) {
      // 假如创建人跟审核人是同一人
      // 假如业务负责人跟上级是同一人，则调用两次审核接口
      step++;
    }
    let success = true; // 是否成功
    for (let i = 0; i < step; i++) {
      // 调用次数
      await dispatch({
        type: 'performance/osaChangeAuditosaprocess',
        payload,
      }).then((res) => {
        if (!res) {
          success = false;
        }
      });
    }

    // 成功之后执行
    if (success) {
      setEditModalVisible(false);
      clearCache();
      goback();
      // if (index > 0) {
      //   goback();
      // } else {
      //   getProcessList(curData);
      // }
    }
  };

  return (
    <div className="create-template-page">
      {HeaderButton}
      <Typography.Title level={4}>OSA考核设置</Typography.Title>
      <CollapseCard
        title="基本设置"
        cloumns={Template.INFO.default({
          item: infoParams,
          disabled,
          target: performance.curOsaSettingData?.rows,
        })}
        defaultValue={curData}
      />
      {curData.osaId && (
        <CollapseCard title="常规指标">
          <Template.INDICATORS.default
            dispatchApi={dispatchApi}
            osaWeekType={curData.osaWeekType}
            tableIdkey="osaId"
            idKey="osaUserId"
            idValue={curData.osaNewId}
            showBtn={false}
            disabled={!!history.location.query.osaId}
          />
          {/* <Indicators
            dispatchApi={dispatchApi}
            tableIdkey="osaId"
            idKey="osaUserId"
            idValue={curData.osaNewId}
            showBtn={false}
          /> */}
        </CollapseCard>
      )}
      {list?.length > 0 && (
        <CollapseCard
          title={
            <>
              流程图
              <span style={{ color: '#999', fontSize: 12 }}>
                （查看目前处于哪个环节)
              </span>
            </>
          }
        >
          <Timeline>
            {list.map((item, index) => (
              <Timeline.Item
                key={index}
                content={item.processDescribe}
                // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
                {...dots(index)}
              />
            ))}
          </Timeline>
        </CollapseCard>
      )}
      <CollapseCard
        title="方案基本设置"
        cloumns={baseCloumns}
        key={performance.curOsaSettingData?.rows}
        disabled={disabled}
        defaultValue={curData}
      />
      {/* <CollapseCard title="评分人设置">
        <Template.GRADER.default
          disabled={disabled}
          defaultValue={curData.osaScoreList}
        />
      </CollapseCard>
      <Template.OFFTIME.default
        disabled={disabled}
        defaultValue={curData.osaScoreEndDaysBeforeAfter}
      /> */}
      <CollapseCard title="审核流程设置">
        <Template.PROCESS.default
          disabled={disabled}
          defaultValue={curData.osaAuditList}
        />
      </CollapseCard>
      <ModalFormPro
        title="确认绩效"
        width={600}
        btnLoading={loadingEf['performance/osaChangeAuditosaprocess']}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(createTemplate);
