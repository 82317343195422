import React from 'react';
import { Card, Col, Row, Avatar, Tag, Table, Button } from 'kenshin';

export const createSaveCacheData = () => {};

export const cardJSONRender = (arr, data) => {
  const colRender = (item) => {
    return (
      <>
        {item.label && <span className="label">{item.label}：</span>}
        {item.render?.(data[item.value]) || data[item.value]}
      </>
    );
  };

  return (
    <Row gutter={[5, 20]}>
      {arr.map((item, i) => (
        <Col span={item.span || 10} key={i}>
          {(item.colRender || colRender)(item)}
        </Col>
      ))}
    </Row>
  );
};
