import { Col, Tag, Row, DatePicker } from 'kenshin';
import { useState } from 'react';
import styles from './styles.less';
import {
  timeOptions,
  infoOptions,
  INFOKEY,
  groupByTimeOptions,
  groupByPeopleOptions,
} from './staticData';
import { useDispatch } from 'umi';
import { useReactive } from 'ahooks';
import { getDefaultTime } from '.';

/** 时间树扁平化 */
export const weekFlat = (list) => {
  if (!list) {
    return [];
  }
  return list
    .map((item) => {
      let arr = item.osaWeekMonthList.map((item) =>
        item.osaWeekDateList.map((item) => [
          item.osaWeekPlanBeginDate,
          item.osaWeekPlanEndDate,
        ]),
      );
      return arr;
    })
    .flat(2);
};

/** */
export const findBetweenTime = (times, searchTime) => {
  let start = moment(times[0]);
  let end = moment(times[1]);
  return moment(searchTime).isBetween(start, end);
};

const Search = ({ onChange }) => {
  const [osaTime, setOsaTime] = useState(getDefaultTime('6-month'));
  console.log(222222, osaTime);
  const dispatch = useDispatch();

  /** state状态数据 */
  const state = useReactive({
    attendanceType: -1,
    groupByPeople: 1,
    groupByTime: 1,
    time: '6-month',
  });

  const handleChange = (times) => {
    // console.log(55555,times)
    const [queryBeginTime, queryEndTime] = (times || osaTime).map((item) =>
      item.format('YYYY-MM-DD'),
    );
    const payload = { ...state, queryBeginTime, queryEndTime };
    onChange?.(payload);
  };
  const timeSearch = ({ time }) => {
    // console.log(3333,time)
    const [amount, unit] = time.split('-');
    let startTime = moment();
    const start = startTime.subtract(amount, unit).startOf(unit);
    const end = moment().endOf(unit);
    setOsaTime([start, end]);
    handleChange([start, end]);
  };

  const timeChange = async (time) => {
    // console.log(1111,time)
    // 开始时间
    time[0] = time[0].startOf('day');
    time[1] = time[1].endOf('day');
    setOsaTime(time);
    handleChange(time);
  };

  const listArr = [
    {
      title: '信息类别',
      list: infoOptions,
      key: 'attendanceType',
    },
    {
      title: '日期范围',
      list: timeOptions,
      change: (item) => {
        timeSearch({ time: item.value });
      },
      key: 'time',
      time: true,
    },
    {
      title: '时间维度',
      list: groupByTimeOptions,
      key: 'groupByTime',
    },
    {
      title: '人员维度',
      list: groupByPeopleOptions,
      key: 'groupByPeople',
    },
  ];

  return (
    <div className={styles.search}>
      {/* 信息 */}
      {listArr.map(({ title, list, key, change, time }) => (
        <Row style={{ marginBottom: 10 }} key={key}>
          <Col flex="80px">{title}：</Col>
          <Col flex="auto">
            {list.map((item) => (
              <Tag.CheckableTag
                style={{ marginLeft: 4 }}
                key={item.value}
                checked={item.value == state[key]}
                onChange={(checked) => {
                  state[key] = item.value;
                  if (change && typeof change == 'function') {
                    return change(item, checked);
                  }
                  handleChange?.();
                  // setInfoChecked(item.value);
                }}
              >
                {item.label}
              </Tag.CheckableTag>
            ))}
            {time && (
              <DatePicker.RangePicker
                value={osaTime}
                allowClear={false}
                onChange={timeChange}
                style={{ marginLeft: 4 }}
                // panelRender={panelRender}
              />
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Search;
