import ModalFormPro from '@/components/ModalFormPro';
import { useMemo } from 'react';
import { flat } from '@/_util/util';

/** 抽取出开始跟结束时间 */
function extractionStartEnd(arr) {
  // return arr.map((obj) => [moment(obj.osaWeekPlanBeginDate), moment(obj.osaWeekPlanEndDate)]);
  return arr.map((obj) => [obj.osaWeekPlanBeginDate, obj.osaWeekPlanEndDate]);
}

const WeekEdit = ({ visible, onCancel, onSave, data, timelist = [] }) => {
  const existTime = useMemo(() => {
    let resTimeList = flat(
      timelist.map((item) => {
        return item.osaWeekMonthList?.map((item) =>
          extractionStartEnd(item.osaWeekDateList),
        );
      }),
      2,
    );
    if (
      resTimeList[0][0] !=
      data?.osaWeekPlanTime[0].format('YYYY-MM-DD HH:mm:ss')
    ) {
      resTimeList[0][0] = moment(resTimeList[0][0])
        // .startOf('year')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (
      resTimeList[resTimeList.length - 1][1] !=
      data?.osaWeekPlanTime[1].format('YYYY-MM-DD HH:mm:ss')
    ) {
      resTimeList[resTimeList.length - 1][1] = moment(
        resTimeList[resTimeList.length - 1][1],
      )
        // .endOf('year')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    return resTimeList;
  }, [timelist]);

  const disabledDateList = useMemo(() => {
    if (data) {
      let start = data.osaWeekPlanTime?.[0].format('YYYY-MM-DD HH:mm:ss');
      let end = data.osaWeekPlanTime?.[1].format('YYYY-MM-DD HH:mm:ss');
      return existTime?.filter((item) => item[0] != start && item[1] != end);
    }
  }, [existTime, data]);
  const disabledDate = (current) => {
    current.endOf('h');
    let res = disabledDateList?.some((item) => current.isBetween(...item));
    return res || current.year() != data?.osaWeekPlanYear;
  };

  const edtaFormItem = [
    {
      label: '日期',
      name: 'osaWeekPlanTime',
      type: 'DatePickerRangePicker',
      required: '请选择日期',
      props: {
        disabledDate,
      },
    },
    {
      label: '所属月份',
      name: 'osaWeekPlanDate',
      type: 'InputNumber',
      required: '请输入所属月份',
      props: {
        placeholder: '所属月份',
        stepType: 'inside',
        min: 1,
        max: 12,
      },
    },
  ];

  return (
    <div>
      <ModalFormPro
        width={600}
        visible={visible}
        formData={data}
        edtaFormItem={edtaFormItem}
        onSave={onSave}
        onCancel={onCancel}
      />
    </div>
  );
};

export default WeekEdit;
