import {
  Button,
  Col,
  message,
  MessageBox,
  Progress,
  Row,
  Tooltip,
  Upload,
} from 'kenshin';
import { list } from '.';
import SortTitle from './components/SortTitle';

const contractStatusObj = {
  1: <div className="status-grey">未开启</div>,
  2: <div className="status-green animate">进行中</div>,
  3: <div className="status-grey">已完成</div>,
  4: <div className="status-red">终止</div>,
};

export const getColumns = (sortType, { showEditModal, delItem }) => {
  const columns = [
    // {
    //   title: '项目名称',
    //   fixed: 'left',
    //   key: 'projectName',
    //   width: 250,
    //   render: (row) => {
    //     const renderItem = row.proAndMngList?.map((item) => (
    //       <div key={item.projectId}>
    //         {item.projectName}：
    //         <span className="tip-text">{item.projectManagerName}</span>
    //       </div>
    //     ));

    //     if (row.proAndMngList?.length > 1) {
    //       return (
    //         <Tooltip title={renderItem} overlayStyle={{ maxWidth: 400 }}>
    //           <div>
    //             {row.proAndMngList[0].projectName}：
    //             <span className="tip-text">
    //               {row.proAndMngList[0].projectManagerName}
    //             </span>
    //             <span style={{ marginLeft: 10, color: '#4389f9' }}>
    //               等{row.proAndMngList.length}个
    //             </span>
    //           </div>
    //         </Tooltip>
    //       );
    //     }
    //     return renderItem;
    //   },
    // },
    {
      title: '合同名称',
      fixed: 'left',
      width: 150,
      dataIndex: 'contractName',
    },
    {
      // title: <SortTitle >合同编号</SortTitle>,
      title: '合同编号',
      fixed: 'left',
      sort: true,
      width: 150,
      dataIndex: 'contractNumber',
    },
    {
      title: '客户名称',
      // title: <SortTitle >客户名称</SortTitle>,
      width: 150,
      sort: true,
      dataIndex: 'custCompname',
    },
    {
      dataIndex: 'contractAmount',
      title: '合同金额含税',
      width: 150,
      render: (val) => (val / 100).toFixed(2),
    },
    {
      dataIndex: 'contractTaxAmount',
      title: '合同金额不含税',
      width: 150,
      render: (val) => (val / 100).toFixed(2),
    },
    {
      key: 'contractBeginTime',
      title: '合同执行日期',
      width: 220,
      render: (row) =>
        row.contractBeginTime && (
          <>
            {moment(row.contractBeginTime).format('YYYY-MM-DD')} ~{' '}
            {moment(row.contractEndTime).format('YYYY-MM-DD')}
          </>
        ),
    },
    {
      dataIndex: 'contractCollectionAmount',
      title: '已收金额',
      width: 100,
      render: (val) => (val / 100).toFixed(2),
    },
    {
      dataIndex: 'contractRemainAmount',
      title: '剩余尾款',
      width: 100,
      render: (val) => (val / 100).toFixed(2),
    },
    {
      dataIndex: 'contractStatus',
      title: '合同状态',
      width: 100,
      render: (val) => contractStatusObj[val],
    },
  ];
  if (sortType == list) {
    return [...columns];
  } else {
    return [
      ...columns,
      {
        dataIndex: 'collectBatch',
        title: '收款批次',
        width: 100,
      },
      {
        dataIndex: 'collectDateTime',
        title: '收款时间',
        width: 200,
        sort: true,
        render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        dataIndex: 'collectAmount',
        title: '本次收款金额',
        width: 150,
        render: (val) => val && (val / 100).toFixed(2),
      },
      {
        key: 'options',
        title: '操作',
        width: 100,
        fixed: 'right',
        render: (row) => {
          if (row.isLast == 1) {
            return (
              <>
                {/* <Button type="text">查看</Button> */}
                {row.hasbtnList?.includes(btnKeys.edit) && (
                  <Button type="text" onClick={() => showEditModal(row)}>
                    编辑
                  </Button>
                )}
                {row.hasbtnList?.includes(btnKeys.delete) && (
                  <Button
                    type="text"
                    style={{ color: 'red' }}
                    onClick={() => delItem({ proCollectId: row.proCollectId })}
                  >
                    删除
                  </Button>
                )}
              </>
            );
          }
          return null;
        },
      },
    ];
  }
};
