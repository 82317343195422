import { beforeSaveChangeData } from '@/pages/contract/list/util';
import { dicLType, roleType } from '@/_util/keysType';
import { findDept } from '@/components/ChooseDepartment';
import { flat } from '@/_util';
import { filterServCatgList, memberListFilter } from './detailDataTransform';
import { btnType } from '..';
import { message } from 'kenshin';

export const busiTypeKey = {
  CONTRACT: 2,
  TASK: 4,
};

/** 树组件配置 */
export const toOptions = (arr = []) => {
  arr ||= [];
  return arr.map((item) => ({
    value: item.proServCatgId,
    title: item.servCatgName,
    children: toOptions(item.childList),
    data: item,
  }));
};

/** 初次再次标识符 */
export const TypeKey = {
  PROCESS: 'process',
  EDITPROCESS: 'editProcess',
};

/** 树组件根据id查找 */
export function findForArr(arr, id) {
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (item.value == id) {
        return item;
      }
      if (item.children) {
        let res = findForArr(item.children, id);
        if (res) {
          return res;
        }
      }
    }
  }
}

// 内部处理
// ----------------------------------------------------任务start----------------------------------------------

/** 初次修改任务 */
export const taskFirstSave = (values, { process, getAfterStr }) => {
  let {
    taskName,
    projectId,
    proServCatgId,
    taskId,
    projectName,
    servCatgName,
  } = process.changeInfo.data;
  //  首次保存

  const { afterProjectName, afterProServCatgName } = getAfterStr(values);
  const { busiType, ...otherParams } = process.changeInfo.params;
  let params = {
    ...otherParams,
    // 存储更新前跟更新后的属性
    processApplyDataChangeDTO: {
      // 更新后
      dataChangeAfter: JSON.stringify({
        taskId,
        projectName: afterProjectName,
        proServCatgName: afterProServCatgName,
        ...values,
      }),
      // 更新前
      dataChangeBefore: JSON.stringify({
        taskId,
        projectName,
        proServCatgName: servCatgName,
        taskName,
        projectId,
        proServCatgId,
      }),
    },
  };
  return params;
};

/** 再次修改任务 */
export const taskReSave = (values, { process, getAfterStr }) => {
  const { afterProjectName, afterProServCatgName } = getAfterStr(values);
  const { busiType, ...otherParams } = process.changeInfo.params;
  let params = {
    ...otherParams,
    dataChangeAfter: JSON.stringify({
      ...JSON.parse(process.changeInfo.params.dataChangeBefore),
      projectName: afterProjectName,
      proServCatgName: afterProServCatgName,
      ...values,
    }),
  };
  return params;
};

// ----------------------------------------------------任务end----------------------------------------------

// ----------------------------------------------------合同start----------------------------------------------

const getContractData = ({ values, process, systemOrgani, system }) => {
  /** 修改前的data */
  let beforeData = process.changeInfo.data;
  // 合同类型
  let contractTypeList = flat(
    system.dictypeListAll[dicLType.CONTRACTTYPE].map((item) => item.dataList),
    2,
  );

  // 获取合同处理后的数据
  let payload = beforeSaveChangeData(values);
  let collectAmount = payload.contractExpectCollectList.reduce(
    (pre, cur) => pre + cur.collectAmount,
    0,
  );

  // 修改前的部门名称
  beforeData.contractBelongDeptStr = findDept(
    [systemOrgani.departmentList],
    beforeData.contractBelongDept,
  )?.deptName;
  // 修改后的部门名称
  payload.contractBelongDeptStr = findDept(
    [systemOrgani.departmentList],
    payload.contractBelongDept,
  )?.deptName;

  // 修改前的合同类型
  beforeData.contractTypeStr = contractTypeList.find(
    (item) => item.id == beforeData.contractType,
  )?.value;
  // 修改后的合同类型
  payload.contractTypeStr = contractTypeList.find(
    (item) => item.id == payload.contractType,
  )?.value;

  const { busiType, ...otherParams } = process.changeInfo.params;

  return { beforeData, payload, otherParams, collectAmount };
};

/** 初次修改合同 */
export const contractFirstSave = (
  values,
  { process, systemOrgani, system },
) => {
  const { beforeData, payload, otherParams, collectAmount } = getContractData({
    values,
    process,
    systemOrgani,
    system,
  });

  if (payload.contractAmount < collectAmount) {
    message.error('收款批次的收款金额之和不能大于合同金额！');
    return false;
  }

  let params = {
    ...otherParams,
    // 存储更新前跟更新后的属性
    processApplyDataChangeDTO: {
      // 更新后
      dataChangeBefore: JSON.stringify(beforeData),
      // 更新前
      dataChangeAfter: JSON.stringify({
        ...beforeData,
        ...payload,
        contractBusiList: beforeData.contractBusiList,
      }),
    },
  };
  return params;
};

/** 再次修改合同 */
export const contractReSave = (values, { process, systemOrgani, system }) => {
  const { payload, otherParams, collectAmount } = getContractData({
    values,
    process,
    systemOrgani,
    system,
  });

  if (payload.contractAmount < collectAmount) {
    message.error('收款批次的收款金额之和不能大于合同金额！');
    return false;
  }

  let params = {
    ...otherParams,
    dataChangeAfter: JSON.stringify({
      ...JSON.parse(process.changeInfo.params.dataChangeBefore),
      ...payload,
      contractBusiList: process.changeInfo.data.contractBusiList,
    }),
  };
  return params;
};

// ----------------------------------------------------合同end------------------------------------------------

// ----------------------------------------------------项目start----------------------------------------------

/** 提交的时候数据应该经过处理 */
const submitDataOptions = (data, { detailData, projectId, deleteList }) => {
  const { dbServCatgList, projectTime, ...values } = data;

  // values.proServCatgDiscUnitPirceChange =
  //   proServCatgDiscUnitPirceChange.current; //项目服务类目优惠后的单价是否发生变更

  values.projectId = projectId;
  if (projectTime) {
    values.projectBeginTime = moment(projectTime[0])
      .startOf('date')
      .format('YYYY-MM-DD HH:mm:ss');
    values.projectEndTime = moment(projectTime[1])
      .endOf('date')
      .format('YYYY-MM-DD HH:mm:ss');
  }

  if (values.contractId) {
    let contractNumberArr = values.contractId.split('-');
    contractNumberArr.shift();
    values.contractNumber = contractNumberArr.join('-');
    values.contractId = values.contractId.split('-')[0];
  }

  if (dbServCatgList) {
    Object.assign(
      values,
      filterServCatgList(
        JSON.parse(JSON.stringify(dbServCatgList)),
        detailData,
      ),
    );
  }

  values.projectCreateUserString = localStorage.getItem('muId');

  // if (contractMoney < values.projectAmount) {
  //   message.warn('项目金额不能超过合同剩余金额');
  //   return;
  // }
  values.thisRemoveServCatgList = memberListFilter(deleteList);
  values.projectAmount &&
    (values.projectAmount = +(values.projectAmount * 100).toFixed(2));
  return values;
};

const dataToStr = (data, { systemOrgani, contract }) => {
  /** 商务 */
  data.projectManagerStr = systemOrgani.ProjectManagerAll[
    roleType.PROJECTMANAGER
  ].find((item) => item.id == data.projectManager)?.value;
  /** 合同 */
  let contractIdArr = contract.easyContractList.find(
    (item) => item.id == data.contractId.split('-')[0],
  );

  data.contractIdStr = `${contractIdArr?.value}(${contractIdArr?.extendData})`;
  // 周期
  data.projectBeginTime = data.projectTime
    ? moment(data.projectTime?.[0]).format('YYYY-MM-DD HH:mm:ss')
    : data.projectBeginTime;
  data.projectEndTime = data.projectTime
    ? moment(data.projectTime?.[1]).format('YYYY-MM-DD HH:mm:ss')
    : data.projectEndTime;
};

/** 初次修改项目 */
export const projectFirstSave = (
  data,
  { otherParams, detailData, contract, deleteList, systemOrgani, formData },
  type,
) => {
  let result = submitDataOptions(data, {
    detailData,
    projectId: detailData.projectId,
    deleteList,
  });

  dataToStr(data, { systemOrgani, contract });
  dataToStr(detailData, { systemOrgani, contract });
  dataToStr(formData, { systemOrgani, contract });

  result.newData = { ...result, ...data };
  let params = {
    ...otherParams,
    busiType: undefined,
    // 存储更新前跟更新后的属性
    processApplyDataChangeDTO: {
      // 更新后
      dataChangeBefore: JSON.stringify(formData),
      // 更新前
      dataChangeAfter: JSON.stringify(result),
    },
  };

  return params;
};

/** 再次修改项目 */
export const projectReSave = (
  data,
  { otherParams, detailData, contract, deleteList, systemOrgani, formData },
  type,
) => {
  let result = submitDataOptions(data, {
    detailData,
    projectId: detailData.projectId,
    deleteList,
  });

  dataToStr(data, { systemOrgani, contract });
  dataToStr(detailData, { systemOrgani, contract });

  result.newData = { ...result, ...data };
  let params = {
    ...otherParams,
    dataChangeAfter: JSON.stringify(result),
    busiType: undefined,
  };
  return params;
};

// ----------------------------------------------------项目end----------------------------------------------

// ----------------------------------------------------角色start----------------------------------------------

const getRoleString = (data) => {
  let obj = { 1: '系统预设', 2: '用户定义' };
  data.roleTypeStr = obj[data.roleType];
  if (data.expireDate) {
    data.expireDate = moment(data.expireDate).endOf('d').format('YYYY-MM-DD');
  }
  data.menuPermIdList = undefined;
  return data;
};

/** 初次修改任务 */
export const roleFirstSave = (values, { process, getAfterStr }) => {
  //  首次保存
  const { busiType, ...otherParams } = process.changeInfo.params;
  let newData = getRoleString(values);
  let oldData = getRoleString(process.changeInfo.data);

  newData.grantDays = moment(newData.expireDate)
    .endOf('date')
    .diff(moment().startOf('date'), 'd');
  let params = {
    ...otherParams,
    // 存储更新前跟更新后的属性
    processApplyDataChangeDTO: {
      // 更新后
      dataChangeAfter: JSON.stringify({
        ...oldData,
        ...newData,
      }),
      // 更新前
      dataChangeBefore: JSON.stringify(oldData),
    },
  };
  return params;
};

/** 再次修改任务 */
export const roleReSave = (values, { process }) => {
  const { busiType, ...otherParams } = process.changeInfo.params;
  let newData = getRoleString(values);
  newData.grantDays = moment(newData.expireDate)
    .endOf('date')
    .diff(moment().startOf('date'), 'd');
  let params = {
    ...otherParams,
    dataChangeAfter: JSON.stringify({
      ...JSON.parse(process.changeInfo.params.dataChangeBefore),
      ...newData,
    }),
  };
  return params;
};

// ----------------------------------------------------角色end----------------------------------------------
