import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, MessageBox, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportDeptEmpRptRcd } from '@/_serveice/usercenter';
import EllipsisTooltip from '@/components/EllipsisTooltip';

const getTreeData = (list) => {
  return list.map((item) => {
    let children;
    if (item.child) {
      children = getTreeData(item.child);
    }
    return {
      title: item.deptName,
      value: item.deptId,
      children,
    };
  });
};

const rptTypeObj = {
  1: '正常上报',
  2: '补报',
};
const rptAuditResultObj = {
  1: <div className="status-green animate">通过</div>,
  2: <div className="status-red">驳回</div>,
};

const menuConfig = ({ dispatch, usercenter, systemOrgani, project }) => {
  useEffect(() => {
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
    dispatch({
      type: 'project/getSimpProjectList',
    });
  }, []);

  const departmentList = useMemo(
    () => getTreeData([systemOrgani.departmentList]),
    [systemOrgani.departmentList],
  );

  const columns = [
    {
      dataIndex: 'empName',
      title: '员工姓名',
      width: 100,
    },
    {
      dataIndex: 'deptList',
      width: 150,
      title: '部门列表',
      render: (val) =>
        val?.map((item, i) => {
          let arr = item?.split('/');
          return <div key={i}>{arr[arr.length - 1]}</div>;
        }),
    },
    {
      dataIndex: 'taskName',
      title: '任务名称',
      width: 150,
    },
    // {
    //   dataIndex: 'projectName',
    //   width: 200,
    //   title: '项目名称',
    // },
    {
      dataIndex: 'servCatgName',
      width: 250,
      title: '工作包',
      render: (servCatgName, row) => `${row.projectName}>${servCatgName}`,
    },
    {
      dataIndex: 'rptDate',
      width: 150,
      title: '上报日期',
    },
    {
      dataIndex: 'rptType',
      width: 100,
      title: '上报类型',
      render: (val) => rptTypeObj[val],
    },
    {
      dataIndex: 'rptWorkHours',
      width: 130,
      title: '上报的标准工时',
    },
    {
      dataIndex: 'rptWorkOvertimeHours',
      width: 130,
      title: '上报的加班工时',
    },
    {
      dataIndex: 'rptWorkTotalHours',
      width: 150,
      title: '上报的总工时',
    },
    {
      dataIndex: 'rptAuditResult',
      title: '上报审核结果',
      width: 150,
      render: (val) => rptAuditResultObj[val],
    },
    {
      dataIndex: 'rptRemark',
      width: 200,
      title: '上报备注',
      render: (val) => <EllipsisTooltip value={val} width={200} />,
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '员工/工作包',
      },
    },
    {
      label: '部门',
      name: 'deptIdList',
      type: 'TreeSelect',
      props: {
        placeholder: '部门',
        treeNodeFilterProp: 'title',
        multiple: true,
        treeDefaultExpandAll: true,
        maxTagCount: 'responsive',
        dropdownStyle: { maxHeight: 600, overflow: 'auto' },
        treeData: departmentList,
      },
    },
    {
      label: '项目',
      name: 'projectList',
      type: 'Select',
      props: {
        placeholder: '请选择项目',
        mode: 'multiple',
        options: project.userProjectSimpList.map((item) => ({
          label: item.projectName,
          value: item.projectId,
        })),
      },
    },
    {
      name: 'rptDate',
      type: 'DatePickerRangePicker',
      label: '上报时间',
      props: {
        placeholder: ['开始时间', '结束时间'],
      },
    },
  ];

  const tableRef = useRef();
  const extra = () => (
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let value = tableRef.current.value;
        let payload = {
          key: value.key ?? '',
          deptIdList: value.deptIdList ?? '',
          rptBeginDate: '',
          rptEndDate: '',
          projectList: value.projectList ?? '',
        };
        if (value.rptDate) {
          const [start, end] = value.rptDate;
          payload.rptBeginDate = moment(start)
            .startOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
          payload.rptEndDate = moment(end)
            .endOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
        }
        let url = `${baseURL}${exportDeptEmpRptRcd}${splitParamsFoGet(
          payload,
        )}`;
        downloadXlsx(url, '部门员工上报');
      }}
    >
      导出
    </Button>
  );

  const onSubmit = (payload) => {
    if (payload.rptDate) {
      const [start, end] = payload.rptDate;
      payload.rptBeginDate = moment(start).startOf('d').format('YYYY-MM-DD');
      payload.rptEndDate = moment(end).endOf('d').format('YYYY-MM-DD');
    }
    payload.rptDate = undefined;
    // return false
  };

  return (
    <div>
      <TablePro
        params={{ empId: localStorage.getItem('muId') }}
        request="usercenter/getDeptRptList"
        version="usercenter"
        rowKey={'rptDetailId'}
        extra={extra}
        scrollX
        actionRef={(ref) => (tableRef.current = ref)}
        columns={columns}
        curColumns
        onSubmit={onSubmit}
        searchCloumns={searchCloumns}
        data={usercenter.deptRptList}
      />
    </div>
  );
};

export default connect(({ usercenter, project, systemOrgani }) => ({
  usercenter,
  project,
  systemOrgani,
}))(menuConfig);
