import {
  Button,
  Col,
  message,
  MessageBox,
  Progress,
  Row,
  Tag,
  Tooltip,
} from 'kenshin';
import { history } from 'umi';
import { roleType } from '@/_util/keysType';
import { timerYMD } from '@/_util/componentsUtil';
import MoreButton from '../../components/moreButton';

export const getTableCloumns = ({ delRole, handleEdit }) => [
  {
    dataIndex: 'appKey',
    fixed: 'left',
    width: 200,
    title: 'appKey',
    // sort:true,
    // sortDirections:["descend"],
  },

  {
    dataIndex: 'snId',
    fixed: 'left',
    width: 100,
    title: 'snId',
    // sort:true,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'snNo',
    fixed: 'left',
    width: 100,
    title: 'snNo',
    // sort:true,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'copyRight',
    width: 200,
    title: '版权信息',
    // sort: false,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'footUrl',
    width: 150,
    title: '跳转连接',
    // sort: true,
    // sortDirections:["descend"],
  },
  {
    dataIndex: 'footer',
    width: 150,
    title: '页脚信息',
    // sort: true,
  },
  {
    dataIndex: 'modules',
    width: 150,
    title: '应用列表',
    // sort: true,
  },
  {
    dataIndex: 'serverUuid',
    width: 150,
    title: '机器编码',
    // sort: true,
  },
  {
    dataIndex: 'snExpireDate',
    width: 150,
    title: '失效日期',
    // sort: true,
    // render: (val) => (val / 100).toFixed(2),
  },
  {
    dataIndex: 'connector',
    width: 150,
    title: '连接器',
    // sort: true,
    // render: (val) => (val / 100).toFixed(2),
  },
  {
    dataIndex: 'newApp',
    width: 150,
    title: '应用列表',
    // sort: true,
    // render: (val) => (val / 100).toFixed(2),
    render: (val) => (val == '1' ? '是' : '否'),
  },
  {
    dataIndex: 'version',
    width: 150,
    title: '软件版本',
    // sort: true,
  },
  {
    dataIndex: 'versionType',
    width: 150,
    title: '软件版本类型',
    // sort: true,
    render: (val) => (val == '1' ? '商业版' : '社区版'),
  },
  {
    title: '操作',
    width: 150,
    key: 'options',
    fixed: 'right',
    render: (row) => {
      return (
        <>
          <Button
            type="text"
            style={{ color: 'red' }}
            onClick={() => delRole({ idList: [row.snId] })}
          >
            删除
          </Button>
          <Button type="text" onClick={() => handleEdit(row)}>
            编辑
          </Button>
        </>
      );
    },
  },
];

export const getEditCloumns = ({ rowData }) => {
  console.log(rowData);

  return [
    {
      label: 'appKey',
      name: 'appKey',
      type: 'Input',
      required: '请输入API接口调用时的凭证',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入API接口调用时的凭证',
      },
    },
    {
      label: '版权信息',
      name: 'copyRight',
      type: 'Input',
      required: '请输入版权信息',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入版权信息',
      },
    },
    {
      label: '跳转连接',
      name: 'footUrl',
      type: 'Input',
      required: '请输入页脚跳转连接',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入页脚跳转连接',
      },
    },
    {
      label: '页脚信息',
      name: 'footer',
      type: 'Input',
      required: '请输入页脚信息',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入页脚信息',
      },
    },
    {
      label: '应用列表',
      name: 'modules',
      type: 'Input',
      required: '请输入应用列表',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入应用列表',
      },
    },

    {
      label: '机器编码',
      name: 'serverUuid',
      type: 'Input',
      required: '请输入机器唯一编码',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入机器唯一编码',
      },
    },

    {
      label: '失效日期',
      name: 'snExpireDate',
      type: 'Input',
      required: '请输入序列号失效日期，格式：2020-10-10',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入序列号失效日期，格式：2020-10-10',
      },
    },

    {
      label: '连接器',
      name: 'connector',
      type: 'Input',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入连接器，多个用逗号隔开',
      },
    },

    {
      label: '应用列表',
      name: 'newApp',
      type: 'Select',
      // help: `合同剩余金额：${contractMoney / 100}`,
      // required: '是否创建应用',
      props: {
        placeholder: '是否创建应用',
        // disabled: rowData?.snId ? true : false,
        // disabled: !!rowData?.projectId,
        // listHeight: document.body.offsetHeight - 250,
        // onChange: contractChange,
        options: [
          { value: 1, label: '是' },
          { value: 0, label: '否' },
        ],
      },
    },

    {
      label: '流程数量',
      name: 'serverNum',
      type: 'Input',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入应用可用流程数量',
      },
    },

    {
      label: '集群服务数量',
      name: 'processNum',
      type: 'Input',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入应用集群服务数量',
      },
    },

    {
      label: '序列号id',
      name: 'snId',
      type: 'Input',

      props: {
        disabled: true,
        placeholder: '序列号id,生成后显示',
      },
    },

    {
      label: '序列号编号',
      name: 'snNo',
      type: 'Input',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入序列号编号',
      },
    },

    {
      label: '用户数量',
      name: 'userNum',
      type: 'Input',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入用户数量',
      },
    },

    {
      label: '软件版本',
      name: 'version',
      type: 'Input',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '请输入软件版本',
      },
    },

    {
      label: '软件版本类型',
      name: 'versionType',
      type: 'Select',
      props: {
        // disabled: rowData?.snId ? true : false,
        placeholder: '版本类型',
        // listHeight: document.body.offsetHeight - 250,
        options: [
          { value: 0, label: '社区版' },
          { value: 1, label: '商业版' },
        ],
      },
    },

    // {
    //   label: '应用列表',
    //   name: 'newApp',
    //   type: 'Select',
    //   // help: `合同剩余金额：${contractMoney / 100}`,
    //   required: '是否创建应用',
    //   props: {
    //     placeholder: '是否创建应用',
    //     disabled: !!rowData?.projectId,
    //     listHeight: document.body.offsetHeight - 250,
    //     onChange: contractChange,
    //     options: [{value: 0, label: "是"},{value: 0, label: "否"}],
    //   },
    // },

    // {
    //   label: '项目周期',
    //   name: 'projectTime',
    //   type: 'DatePickerRangePicker',
    //   required: '请输入项目周期',
    //   props: {},
    // },
    // {
    //   label: '项目经理',
    //   name: 'projectManager',
    //   type: 'Select',
    //   required: '请选择项目经理',
    //   props: {
    //     placeholder: '请选择项目经理',
    //     options: projManage?.map((item) => ({
    //       label: item.value,
    //       value: item.id,
    //     })),
    //   },
    // },
    // {
    //   label: '项目金额',
    //   name: 'projectAmount',
    //   type: 'InputNumber',
    //   required: '请输入项目金额',
    //   // extra: rowData?.proServCatgDiscTotalAmount
    //   //   ? `支持修改最小值：${rowData.proServCatgDiscTotalAmount}`
    //   //   : undefined,
    //   props: {
    //     disabled: !!rowData?.projectId,
    //     placeholder: '单位元',
    //     min: rowData?.proServCatgDiscTotalAmount || 0,
    //     stepType: 'inside',
    //   },
    // },
    // {
    //   label: '项目描述',
    //   name: 'projectDescribtion',
    //   type: 'TextArea',
    //   props: {
    //     placeholder: '请输入项目描述',
    //   },
    // },
    // {
    //   label: '选择参与团队',
    //   name: 'proPreallocationMemberList',
    //   type: 'Select',
    //   required: '请选择参与团队',
    //   props: {
    //     mode: 'multiple',
    //     placeholder: '请选择参与团队',
    //     options: employeeAll,
    //   },
    // },

    // {
    //   label: '选择参与团队',
    //   name: 'addTeamMemberList',
    //   type: 'Select',
    //   props: {
    //     placeholder: '请选择参与团队',
    //     mode: 'multiple',
    //     checkAlldisabledKey: '999999',
    //     maxTagCount: 'responsive',
    //     options: deepClone(employeeAll).map((item) => {
    //       if (leaderKey?.split('-')[0] == item.value) {
    //         item.options.find((op) => op.value == leaderKey).disabled = true;
    //       }
    //       return item;
    //     }),
    //   },
    // }
  ];
};

export const getEditCloumns1 = ({ rowData }) => {
  console.log(rowData);

  // let rowData1 = rowData;

  if (rowData) {
    rowData['newApp1'] =
      rowData?.newApp === '1' || rowData?.newApp == 1 ? '是' : '否';
    rowData['versionType1'] =
      rowData?.versionType === '1' || rowData?.versionType == 1
        ? '商业版'
        : '社区版';
  }

  return [
    {
      label: 'appKey',
      name: 'appKey',
      type: 'Input',
      required: '请输入API接口调用时的凭证',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入API接口调用时的凭证',
      },
    },
    {
      label: '版权信息',
      name: 'copyRight',
      type: 'Input',
      required: '请输入版权信息',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入版权信息',
      },
    },
    {
      label: '跳转连接',
      name: 'footUrl',
      type: 'Input',
      required: '请输入页脚跳转连接',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入页脚跳转连接',
      },
    },
    {
      label: '页脚信息',
      name: 'footer',
      type: 'Input',
      required: '请输入页脚信息',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入页脚信息',
      },
    },
    {
      label: '应用列表',
      name: 'modules',
      type: 'Input',
      required: '请输入应用列表',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入应用列表',
      },
    },

    {
      label: '机器编码',
      name: 'serverUuid',
      type: 'Input',
      required: '请输入机器唯一编码',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入机器唯一编码',
      },
    },

    {
      label: '失效日期',
      name: 'snExpireDate',
      type: 'Input',
      required: '请输入序列号失效日期，格式：2020-10-10',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入序列号失效日期，格式：2020-10-10',
      },
    },

    {
      label: '连接器',
      name: 'connector',
      type: 'Input',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入连接器，多个用逗号隔开',
      },
    },

    {
      label: '是否创建应用',
      name: 'newApp1',
      type: 'Input',
      // help: `合同剩余金额：${contractMoney / 100}`,
      // required: '是否创建应用',

      props: {
        placeholder: '是否创建应用',
        disabled: rowData?.snId ? true : false,
        // listHeight: document.body.offsetHeight - 250,
        // value: (rowData?.newApp == "1" ? "是" : "否"),
      },
    },

    {
      label: '流程数量',
      name: 'serverNum',
      type: 'Input',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入应用可用流程数量',
      },
    },

    {
      label: '集群服务数量',
      name: 'processNum',
      type: 'Input',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入应用集群服务数量',
      },
    },

    {
      label: '序列号id',
      name: 'snId',
      type: 'Input',

      props: {
        disabled: true,
        placeholder: '序列号id,生成后显示',
      },
    },

    {
      label: '序列号编号',
      name: 'snNo',
      type: 'Input',
      props: {
        disabled: false,
        placeholder: '请输入序列号编号',
      },
    },

    {
      label: '用户数量',
      name: 'userNum',
      type: 'Input',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入用户数量',
      },
    },

    {
      label: '软件版本',
      name: 'version',
      type: 'Input',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '请输入软件版本',
      },
    },

    {
      label: '软件版本类型',
      name: 'versionType1',
      type: 'Input',
      props: {
        disabled: rowData?.snId ? true : false,
        placeholder: '版本类型',
        // listHeight: document.body.offsetHeight - 250,
      },
    },
  ];
};

export const getSearchCloumns = ({ systemOrgani }) => [
  {
    name: 'key',
    type: 'Input',
    props: {
      placeholder: '序列号/机器唯一编码/软件版本',
    },
  },
];
