import { useSelector } from 'umi';
import { MessageBox, Button } from 'kenshin';
import { useEffect, useState } from 'react';
import { useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { getempvacationenabledetaillist } from '../../../../_serveice/attendance';
import { getTableCloumns } from './_cloumns';

const HolidayChangeLog = ({ visible, onCancel, data }) => {
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (visible) {
      getempvacationenabledetaillist({
        vacationId: data.vacationId,
        empId: data.empId,
      }).then((res) => {
        let list = res.data;
        if (Array.isArray(list)) {
          setDataSource(list);
        }
      });
    }
  }, [visible]);

  const columns = getTableCloumns();

  return (
    <MessageBox
      title={`可用明细`}
      onCancel={onCancel}
      maskClosable={false}
      width="90%"
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      <TablePro
        rowKey={'empVacationChangeId'}
        dataSource={dataSource}
        pagination={false}
        scrollX
        columns={columns}
        columnsOptions={false}
        curColumns
      ></TablePro>
    </MessageBox>
  );
};

export default HolidayChangeLog;
