import { useDrag, useDrop } from 'ahooks';
import { useRef } from 'react';

export default ({ data, onChange, ...props }) => {
  const dragRef = useRef(null);
  useDrag(data, dragRef, {});

  useDrop(dragRef, {
    onDom: (content, e) => {
      onChange?.(content, data);
    },
  });

  return (
    <div className="drag-item" ref={dragRef}>
      {props.children}
    </div>
  );
};
