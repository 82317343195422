import request from '@/_util/require';
let version = 'v1';
// 查询前端页面缓存数据
export async function getCache(pageUrl) {
  let params = {
    pageUrl: pageUrl || location.hash,
  };
  return request(`/${version}/waper/erp/pc/cachepage/getcachepagedata`, {
    method: 'GET',
    params,
  });
}

// 删除前端页面缓存数据
export async function delCache(pageUrl) {
  let params = {
    pageUrl: pageUrl || location.hash,
  };
  return request(`/${version}/waper/erp/pc/cachepage/deletecachepagedata`, {
    method: 'GET',
    params,
  });
}

// 新增或者修改前端页面缓存数据
export async function addCache(params, cacheId) {
  let options = {
    pageUrl: location.hash,
    params,
    cacheId,
  };
  return request(`/${version}/waper/erp/pc/cachepage/addupdatecachepage`, {
    method: 'POST',
    data: options,
  });
}
