import EllipsisTooltip from '@/components/EllipsisTooltip';
import React from 'react';
import { Card, Col, Row } from 'kenshin';
import busiTypeKey from '../../_util/busiTypeKey';
/** 表格表头 */
const cloumns = () => {
  const columns = [
    {
      dataIndex: 'taskName',
      title: '任务名称',
      width: 200,
      render: (val, row) => val || row.servCatgName,
    },
    {
      dataIndex: 'projectName',
      title: '项目',
      width: 200,
    },
    {
      dataIndex: 'servCatgName',
      title: '服务类目',
      width: 220,
    },
    {
      dataIndex: 'taskRemark',
      title: '任务备注',
      width: 220,
      render: (val) => <EllipsisTooltip value={val} width={200} />,
    },
  ];
  return columns;
};
/** 搜索数据 */
const searchCloumns = ({
  employeeAll,
  userProjectSimpList,
  taskTreeData,
  projectSelectChange,
}) => {
  const searchCloumns = [
    {
      name: 'projectId',
      type: 'Select',
      props: {
        placeholder: '项目',
        allowClear: true,
        options: userProjectSimpList?.map((item) => ({
          label: item.projectName,
          value: item.projectId,
        })),
        onChange: projectSelectChange,
      },
    },
    {
      name: 'proServCatgId',
      type: 'TreeSelect',
      props: {
        placeholder: '工作包',
        allowClear: true,
        treeData: taskTreeData,
      },
    },
    {
      name: 'taskName',
      type: 'Input',
      props: {
        placeholder: '任务名称',
      },
    },
  ];
  return searchCloumns;
};

/** 编辑数据 */
const formCloumns = ({
  getServcatgForProjectId,
  project,
  servCatgList,
  curId,
}) => [
  {
    title: '任务名称',
    dataIndex: 'taskName',
    valueType: 'Input',
    required: '请输入任务名称',
    fieldProps: {
      placeholder: '请输入任务名称',
    },
  },
  {
    title: '项目',
    dataIndex: 'projectId',
    valueType: 'Select',
    required: '任务所属项目',
    fieldProps: {
      placeholder: '任务所属项目',
      onChange: getServcatgForProjectId,
      options: project.userProjectSimpList?.map((item) => ({
        label: item.projectName,
        value: item.projectId,
      })),
    },
  },
  {
    title: '服务类目',
    dataIndex: 'proServCatgId',
    valueType: 'TreeSelect',
    required: '任务所属服务类目',
    fieldProps: {
      placeholder: '任务所属服务类目',
      treeData: servCatgList[curId.current],
      treeDefaultExpandAll: true,
    },
  },
];

/** 首次保存 */
const firstSave = (values, { process, getAfterStr }) => {
  let {
    taskName,
    projectId,
    proServCatgId,
    taskId,
    projectName,
    servCatgName,
  } = process.changeInfo.data;
  //  首次保存

  const { afterProjectName, afterProServCatgName } = getAfterStr(values);
  const { busiType, ...otherParams } = process.changeInfo.params;
  let params = {
    ...otherParams,
    // 存储更新前跟更新后的属性
    processApplyDataChangeDTO: {
      // 更新后
      dataChangeAfter: JSON.stringify({
        taskId,
        projectName: afterProjectName,
        proServCatgName: afterProServCatgName,
        ...values,
      }),
      // 更新前
      dataChangeBefore: JSON.stringify({
        taskId,
        projectName,
        proServCatgName: servCatgName,
        taskName,
        projectId,
        proServCatgId,
      }),
    },
  };
  return params;
};

/** 再次保存 */
const reSave = (values, { process, getAfterStr }) => {
  const { afterProjectName, afterProServCatgName } = getAfterStr(values);
  const { busiType, ...otherParams } = process.changeInfo.params;
  let params = {
    ...otherParams,
    dataChangeAfter: JSON.stringify({
      ...JSON.parse(process.changeInfo.params.dataChangeBefore),
      projectName: afterProjectName,
      proServCatgName: afterProServCatgName,
      ...values,
    }),
  };
  return params;
};

/** 创建后 */
const created = ({ dispatch }) => {
  dispatch({
    type: 'project/getSimpProjectList',
  });
};

/** 预览card */
const getCard = (data) => {
  if (Object.keys(data).length == 0) {
    return null;
  }
  return (
    <Card className="process-card-box">
      <Row gutter={[5, 20]}>
        <Col span={20}>
          <span className="label">任务名称：</span>
          {data.taskName}
        </Col>
        <Col span={20}>
          <span className="label">项目：</span>
          {data.projectName}
        </Col>
        <Col span={20}>
          <span className="label">服务类目：</span>
          {data.proServCatgName}
        </Col>
      </Row>
    </Card>
  );
};

export default {
  chooseId: 'taskId',
  propsName: (data) => data['taskName'] || data['servCatgName'],
  cloumns,
  searchCloumns,
  formCloumns,
  firstSave,
  reSave,
  created,
  getCard,
  key: busiTypeKey.TASK,
};
