import { useEffect, useState, useMemo } from 'react';
import { Dropdown, MessageBox, Tree, Avatar, Input } from 'kenshin';
import '@/components/TreeData/index.less';
import { connect } from 'umi';
import { useMemoizedFn } from 'ahooks';
import ModalFormPro from '@/components/ModalFormPro';
import ChooseDePartment from '@/components/ChooseManager/ChooseModal';
import {
  MoreOutlined,
  FolderTwoTone,
  FolderOpenTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { debounce, deepClone, filterTree } from '@/_util';
import { selectPinyinFilter } from '@/_util/componentsUtil';

/** 树查找 */
const findTree = (fildTree, id = 3) => {
  for (let i = 0; i < fildTree.length; i++) {
    if (fildTree[i].deptId == id) {
      return {
        pre: fildTree[i - 1],
        cur: fildTree[i],
        next: fildTree[i + 1],
      };
    }
    if (fildTree[i].child) {
      let resData = findTree(fildTree[i].child, id);
      if (resData) {
        return resData;
      }
    }
  }
};

/** 根据id查找 */
function findNodeById(id, nodes) {
  if (id == null || id == undefined) {
    return null;
  }
  for (let node of nodes) {
    if (node.deptId == id) {
      return node;
    }

    if (node.child) {
      let result = findNodeById(id, node.child);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

// 自定义图标
const getSwitcherIcon = (props) => {
  if (props.isLeaf) {
    return null;
  }
  const { expanded, loading } = props;
  let icon = <FolderTwoTone />;
  if (loading) {
    icon = <LoadingOutlined />;
  } else if (expanded) {
    icon = <FolderOpenTwoTone />;
  }
  return icon;
};

/** 对部门员工进行处理 */
function renderEmployeeTree(arr, baseKey) {
  if (arr?.length == 0) {
    return [];
  }
  return arr.map((item) => ({
    // ...item,
    key: `${baseKey}-${item.id}`,
    muid: item.id,
    title: item.value,
    children: [],
    isLeaf: true,
    // switcherIcon: getSwitcherIcon,
    switcherIcon: () => (
      <Avatar
        size="small"
        src={
          item.extendData2 &&
          (item.extendData2?.startsWith('http')
            ? item.extendData2
            : `${ossUrl}/${item.extendData2}`)
        }
      >
        {item.value?.[0]}
      </Avatar>
    ),
  }));
}

const TreeData = (props) => {
  // const manageDeptId = 1;
  const manageDeptId = localStorage.getItem('manageDeptId');

  const { dispatch, systemOrgani, onChange } = props;
  const { departmentList, employeeForDept } = systemOrgani;

  const [treeData, setTreeData] = useState([]);

  // 转换成树组件能使用的格式
  const transformTreeData = useMemoizedFn((arr) => {
    return arr.map((item) => {
      let children = null;
      if (item.child) {
        children = transformTreeData(item.child);
      }
      return {
        children,
        title: item.deptName,
        key: item.deptId,
        switcherIcon: getSwitcherIcon,
        ...item,
      };
    });
  });

  useEffect(() => {
    let rootTree = findNodeById(manageDeptId, [departmentList]);
    if (rootTree) {
      let tree = transformTreeData([rootTree]);
      setTreeData(tree);
      handleChange([tree[0].key], { node: tree[0] });
    }
  }, [departmentList]);

  useEffect(() => {
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
  }, []);

  // 最初的树组件数据，用于搜索还原
  const defaultTreeData = useMemo(() => {
    let rootTree = findNodeById(manageDeptId, [departmentList]);
    if (rootTree) {
      return deepClone(transformTreeData([rootTree]));
    }
    return [];
  }, [systemOrgani.departmentList]);

  // 搜索
  const searchInput = (e) => {
    const { value } = e.target;
    setTreeData(filterTree(deepClone(defaultTreeData), value));
  };

  // 点击部门
  const handleChange = (keys, row) => {
    keys.length > 0 && onChange?.(keys, row.node);
  };

  return (
    <div className="treeData">
      <Input placeholder="请输入关键词" onChange={debounce(searchInput, 500)} />

      <Tree
        defaultExpandAll
        defaultSelectedKeys={['1']}
        treeData={treeData}
        blockNode
        onSelect={handleChange}
        titleRender={(node) => {
          return (
            <div>
              {node.title}
              <div style={{ float: 'right' }}></div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default connect(({ systemOrgani }) => ({ systemOrgani }))(TreeData);
