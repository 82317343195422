import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Tabs } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import request from 'umi-request';
import ModalFormPro from '@/components/ModalFormPro';

const menuConfig = ({ dispatch, loading, lowCode, user }) => {
  const [temptypeAll, setTemptypeAll] = useState([]);
  const [tmptTypeId, setTmptTypeId] = useState('');
  const [tmptlate, setTmptlate] = useState([]);

  const [createVisible, setCreateVisible] = useState(false);
  const [createData, setCreateData] = useState(null);

  const ref = useRef();

  useEffect(() => {
    dispatch({
      type: 'lowCode/getProjectAll',
    }).then((res) => {
      if (res) {
        setTabKeys(res[0]?.id);
        ref.current.reload({
          projectId: res[0]?.id,
        });
      }
    });
    dispatch({
      type: 'lowCode/temptypeAll',
    }).then((res) => {
      if (res) {
        setTemptypeAll(res);
      }
    });
  }, []);

  useEffect(() => {
    if (tmptTypeId) {
      dispatch({
        type: 'lowCode/getTemplateAll',
        payload: {
          tmptTypeId,
        },
      }).then((res) => {
        if (res) {
          setTmptlate(
            res.map((item) => ({ label: item.value, value: item.id })),
          );
        }
      });
    }
  }, [tmptTypeId]);

  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const [tabKeys, setTabKeys] = useState('');

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'lowCode/deleteProjectpage',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = ({ tmptTypeId, ...value }) => {
    let payload = { ...value, projectId: tabKeys };
    let hasEdit = false;
    if (editModalData?.pageId) {
      hasEdit = true;
      payload.pageId = editModalData.pageId;
    }
    dispatch({
      type: 'lowCode/editProjectpage',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        message.success('操作成功');
      }
    });
  };

  const toLowCodeFram = (row) => {
    history.push({
      pathname: '/admin/system/lowCodeIframe',
      query: {
        pageId: row.pageId,
        token: localStorage.getItem('token'),
        muId: localStorage.getItem('muId'),
      },
    });
  };

  // 生成modles文件
  const [modlesCreateVisible, setModlesCreateVisible] = useState(false);

  const showCreateModles = (row) => {
    setModlesCreateVisible(true);
  };
  const modelsCreateSave = (data) => {
    // request
    //   .post('/local/createModels', {
    //     data,
    //   })
    //   .then((res) => {
    //     message.success('操作成功');
    //     setModlesCreateVisible(false);
    //   });
  };

  const pageTypeOptions = [
    { value: 0, label: '空白页' },
    { value: 1, label: '列表页' },
    { value: 2, label: '表单页' },
    { value: 3, label: '仪表页' },
    { value: 4, label: '详情页' },
  ];

  const showCreatePage = (row) => {
    setCreateVisible(true);
    setCreateData(row);
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'pageName',
      key: 'pageName',
    },
    {
      title: '页面类型',
      dataIndex: 'pageType',
      key: 'pageType',
      render: (val) => pageTypeOptions.find((item) => item.value == val).label,
    },
    {
      title: '创建时间',
      dataIndex: 'pageCrtTime',
      key: 'pageCrtTime',
      render: (value) => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            {user.hasLocalServer && (
              <Button type="text" onClick={() => showCreatePage(row)}>
                生成页面
              </Button>
            )}
            <Button type="text" onClick={() => toLowCodeFram(row)}>
              页面配置
            </Button>
            <Button type="text" onClick={() => showEditModal(row)}>
              编辑
            </Button>
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delRole({ proPageId: row.pageId })}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'pageName',
      type: 'Input',
      props: {
        placeholder: '页面名称',
      },
    },
    {
      name: 'pageType',
      type: 'Select',
      props: {
        placeholder: '页面类型',
        options: pageTypeOptions,
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '名称',
      name: 'pageName',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
    {
      label: '页面类型',
      name: 'pageType',
      type: 'Select',
      props: {
        placeholder: '请选择类型',
        options: pageTypeOptions,
      },
    },
    ...(() => {
      let res = [];
      if (!editModalData) {
        res = [
          {
            label: '模板分类',
            name: 'tmptTypeId',
            type: 'Select',
            props: {
              placeholder: '请选择模板分类',
              options: temptypeAll,
              onChange: setTmptTypeId,
            },
          },
          {
            label: '模板',
            name: 'pageTempId',
            type: 'Select',
            props: {
              placeholder: '请选择模板',
              options: tmptlate,
            },
          },
        ];
      }
      return res;
    })(),
  ];
  // 弹窗Models文件
  const createModelsFormItem = [
    {
      label: '路由',
      name: 'router',
      help: '推荐格式：system/admin',
      type: 'Input',
      required: '请输入路由',
      props: {
        placeholder: '请输入路由',
      },
    },
    {
      label: '名称',
      name: 'name',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
    {
      label: '命名空间',
      name: 'namespace',
      type: 'Input',
      required: '请输入命名空间',
      props: {
        placeholder: '请输入命名空间',
      },
    },
  ];

  // 弹窗formItem
  const createPageFormItem = [
    {
      label: '路由',
      name: 'routers',
      type: 'Input',
      required: '请输入路由',
      props: {
        placeholder: '请输入路由',
      },
    },
  ];

  const extra = () => (
    <>
      <Button type="primary" onClick={() => showEditModal()}>
        新增页面
      </Button>
      <Button type="primary" onClick={() => showCreateModles()}>
        生成models文件
      </Button>
    </>
  );

  return (
    <>
      <Tabs
        onChange={(key) => {
          setTabKeys(key);
          ref.current.reload({
            projectId: key,
          });
        }}
      >
        {lowCode.projectListAll.map((item) => {
          return <Tabs.TabPane tab={item.value} key={item.id} />;
        })}
      </Tabs>
      <TablePro
        request="lowCode/getProjectpageList"
        params={{ projectId: tabKeys }}
        isRequest={false}
        rowKey={'pageId'}
        columns={columns}
        searchCloumns={searchCloumns}
        data={lowCode.projectpageList}
        actionRef={ref}
        extra={extra}
      />

      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />

      <ModalFormPro
        title="生成Models模板"
        visible={modlesCreateVisible}
        onSave={modelsCreateSave}
        edtaFormItem={createModelsFormItem}
        onCancel={() => {
          setModlesCreateVisible(false);
        }}
      />
      <ModalFormPro
        visible={createVisible}
        edtaFormItem={createPageFormItem}
        title="生成页面"
        onSave={(value) => {
          // request
          //   .post('/local/createFile', {
          //     data: {
          //       content: createData?.pageContent,
          //       router: value.routers,
          //     },
          //   })
          //   .then((res) => {});
        }}
        onCancel={() => {
          setCreateVisible(false);
          setCreateData(null);
        }}
      />
    </>
  );
};

menuConfig.title = '菜单配置';

export default connect(({ lowCode, loading, user }) => {
  return {
    user,
    lowCode,
    loading: loading.effects,
  };
})(menuConfig);
