// 会在页面刷新时执行请求获取字典信息
export const dicLType = {
  CONTRACTTYPE: 'CONTRACT:TYPE', //合同类型 CONTRACT:TYPE
  CUSTOMERTYPE: 'CUST:LEVEL', //客户等级 CUST:LEVEL
  // CONTRACTTAG: 'CONTRACT:TAG', //联系人标签 CUST:LEVEL
};

export const roleType = {
  PROJECTMANAGER: 'PROJECT:MANAGER', // 项目经理
  BUSINESSAFFAIRS: 'BUSINESS:AFFAIRS', // 商务
};

export const homeRole = {
  FINANCE: 'FINANCE', // 财务  首页工作台优先判断
  MANAGEMENT: 'MANAGEMENT', // 管理层
};
