import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, Table, Progress, Steps, Row, Col } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import EllipsisTooltip from '@/components/EllipsisTooltip';

const LogList = ({ dispatch, system }) => {
  const tableRef = useRef();

  const businessTypeObjStr = {
    1:"PC",
    2:'App'
  }
  const columns = [
    {
      dataIndex: 'empName',
      title: '员工姓名',
    },
    {
      dataIndex: 'loginIp',
      title: '登录ip',
    },
    {
      dataIndex: 'systemType',
      title: '登录系统类型',
    },
    {
      dataIndex: 'browserType',
      title: '操作浏览器类型',
    },
    {
      dataIndex: 'businessType',
      title: '登录类型',
      render:val => businessTypeObjStr[val]
    },
    {
      dataIndex: 'gmtCreate',
      title: '登录时间',
      render:val => moment(val).format("YYYY-MM-DD HH:mm:ss")
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      label:"关键词",
      props: {
        placeholder: '操作人姓名/IP',
      },
    },
    {
      name: 'businessType',
      label: '登录类型',
      type: 'Select',
      props: {
        placeholder: '登录类型',
        allowClear: true,
        options:[
          {label:"PC",value:1},
          {label:"APP",value:2},
        ]
      },
    },
    {
      name: 'timer',
      type: 'DatePickerRangePicker',
      label: '登录时间',
      props: {
        placeholder: ['开始时间', '结束时间'],
      },
    },
  ];

  const onSubmit = (payload) => {
    if (payload.timer) {
      const [start, end] = payload.timer;
      payload.beginTime = moment(start)
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.endTime = moment(end)
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    payload.timer = undefined;
    // return false
  };

  return (
    <div>
      <TablePro
        request="system/getLoginLogPage"
        rowKey={'loginId'}
        actionRef={(ref) => (tableRef.current = ref)}
        columns={columns}
        curColumns
        onSubmit={onSubmit}
        searchCloumns={searchCloumns}
        data={system.loginLogList}
      />
    </div>
  );
};

export default connect(({ system }) => {
  return {
    system,
  };
})(LogList);
