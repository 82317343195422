import React from 'react';
import DataAnalysePage from '@/components/DataAnalysePage';
import project from '../cost/project';

const dataAnalyseCost = () => {
  // 标签栏当前key
  const dispatchType = {
    charts: 'getProjectCharts',
    list: 'getProjectList',
    listById: 'getProjectListById',
  };
  const dataType = {
    charts: 'projectChartData',
    list: 'projectList',
    listById: 'projectListById',
  };

  return (
    <DataAnalysePage
      dataType={dataType}
      dispatchType={dispatchType}
      {...project}
    />
  );
};

export default dataAnalyseCost;
