import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Alert,
  Tag,
  TreeSelect,
} from 'kenshin';
import { useSelector, useDispatch } from 'umi';
import {
  weekFlat,
  findBetweenTime,
} from '../../../../dataAnalyse/osaAnalyse/search';

const getTreeData = (list) => {
  return list.map((item) => {
    let children;
    if (item.child) {
      children = getTreeData(item.child);
    }
    return {
      title: item.deptName,
      value: item.deptId,
      children,
    };
  });
};

const performanceKey = {
  OSA: 0,
  WEEK: 1,
};

const createSearchItem = (label, options = [], checkValue, onChange, extra) => {
  return (
    <Row key={label} style={{ marginBottom: 10, alignItems: 'center' }}>
      <Col flex="100px">{label}：</Col>
      <Col flex="auto">
        {options.map((item) => {
          let [label, value] = item.split(':');
          return (
            <React.Fragment key={value}>
              <Tag.CheckableTag
                style={{ marginLeft: 4 }}
                key={value}
                checked={value == checkValue}
                onChange={() => onChange({ label, value })}
              >
                {label}
              </Tag.CheckableTag>
            </React.Fragment>
          );
        })}
        {extra}
      </Col>
    </Row>
  );
};
/** 存储每个月对应的周 */
const weekMap = {};

const CollapseSearch = ({
  onChange,
  osaTimeState,
  performanceType,
  getSearchCount,
}) => {
  const managerCount = useSelector(
    ({ performance }) => performance.managerCount,
  );
  const systemOrgani = useSelector(({ systemOrgani }) => systemOrgani);
  const dispatch = useDispatch();

  const [osaTime, setOsaTime] = osaTimeState; // 日期
  const [timeType, setTimeType] = useState('0-quarter'); // 日期范围快捷选项
  const [typeChecked, setTypeChecked] = useState('-1'); // 信息类别
  const [osaStatus, setOsaStatus] = useState('-1'); // 当前状态
  const [selfScore, setSelfScore] = useState('-1'); // 自评
  const [directScore, setDirectScore] = useState('-1'); // 上级评分
  const [busSicore, setBusSicore] = useState('-1'); // 业务负责人评分
  const [searchData, setSearchData] = useState({
    key: '',
    deptList: ['1'],
  });
  // const searchData = useRef({})

  // useEffect(() => {
  //   onChange?.({
  //     osaType: typeChecked,
  //     osaStatusType: osaStatus,
  //     osaSelfStatusType: selfScore,
  //     osaDirectScoreStatusType: directScore,
  //     osaBusiScoreStatusType: busSicore,
  //   });
  // }, [typeChecked, osaStatus, selfScore, directScore, busSicore])

  const departmentList = useMemo(
    () => getTreeData([systemOrgani.departmentList]),
    [systemOrgani.departmentList],
  );

  const getWeekData = async (year = moment()) => {
    if (weekMap[year]) return weekMap[year];
    let result = await dispatch({
      type: 'performance/getyearweekplandatedata',
      payload: {
        osaWeekPlanYear: year,
      },
    });
    weekMap[year] = weekFlat(result);
    return weekMap[year];
  };

  const timeSearch = async ({ time }) => {
    setTimeType(time);
    const [amount, unit] = time.split('-');
    let start = moment().subtract(amount, unit);
    let end = moment().endOf(unit).endOf('date');
    // 单独处理上季度、上周的结束时间
    if (['1-week', '1-quarter'].includes(time))
      end = moment(start).endOf(unit).endOf('date');

    let times = [start, end];
    await setTimeOfWeek(times);
    setOsaTime(times);
    const osaStatBeginTime = times[0].format('YYYY-MM-DD HH:mm:ss');
    const osaStatEndTime = times[1].format('YYYY-MM-DD HH:mm:ss');
    onChange?.({ osaStatBeginTime, osaStatEndTime });
    getSearchCount?.({ osaStatBeginTime, osaStatEndTime });
  };

  const setTimeOfWeek = async (times) => {
    if (performanceType == performanceKey.WEEK) {
      // 开始时间
      let weekData = await getWeekData(times[0].year());
      let curTime = weekData.find((timeArr) =>
        findBetweenTime(timeArr, times[0]),
      );
      curTime && (times[0] = moment(curTime[0]));
      //结束
      weekData = await getWeekData(times[1].year());
      curTime = weekData.find((timeArr) => findBetweenTime(timeArr, times[1]));
      curTime && (times[1] = moment(curTime[1]));
    } else if (times[0]) {
      times[0] = times[0].startOf('quarter');
      times[1] = times[1].endOf('quarter');
    }
  };

  const timeChange = async (times) => {
    // setTimeOfWeek(times);
    setOsaTime(times);
    let osaStatBeginTime = undefined;
    let osaStatEndTime = undefined;
    if (times) {
      const [start, end] = times;
      osaStatBeginTime = start
        .startOf(performanceType == performanceKey.WEEK ? 'date' : 'quarter')
        .format('YYYY-MM-DD HH:mm:ss');
      osaStatEndTime = end
        .endOf(performanceType == performanceKey.WEEK ? 'date' : 'quarter')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    onChange?.({ osaStatBeginTime, osaStatEndTime });
    getSearchCount?.({ osaStatBeginTime, osaStatEndTime });
  };

  // 搜索事件
  const handleSearch = (e) => {
    e.stopPropagation();
    let value = e.target.value;
    setSearchData((data) => {
      return { ...data, key: value };
    });
  };

  useEffect(() => {
    onReset();
  }, [performanceType]);

  const onReset = async () => {
    setTimeType(performanceType == performanceKey.OSA ? '0-quarter' : '0-week');
    setSearchData({
      key: '',
      deptList: ['1'],
    });
    let times = [moment(), moment()];
    await setTimeOfWeek(times);
    setOsaTime(times);
    // setTimeType("0")
    setTypeChecked('-1');
    setOsaStatus('-1');
    setSelfScore('-1');
    setDirectScore('-1');
    setBusSicore('-1');
    onChange(
      {
        osaStatBeginTime: times[0]
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss'),
        osaStatEndTime: times[1].endOf('date').format('YYYY-MM-DD HH:mm:ss'),
      },
      true,
    );
    getSearchCount(
      {
        osaStatBeginTime: times[0]
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss'),
        osaStatEndTime: times[1].endOf('date').format('YYYY-MM-DD HH:mm:ss'),
      },
      true,
    );
  };

  let deptProps = {
    placeholder: '部门',
    treeNodeFilterProp: 'title',
    multiple: true,
    treeDefaultExpandAll: true,
    maxTagCount: 'responsive',
    dropdownStyle: { maxHeight: 800, overflow: 'auto' },
    treeData: departmentList,
    style: { width: 250, marginLeft: 10 },
    onChange: (key) => {
      // searchData.current.deptList = key.length > 0 ? key : ['1']
      setSearchData({
        ...searchData,
        deptList: key.length > 0 ? key : ['1'],
      });
    },
  };
  const headerRender = (
    <div style={{ display: 'flex', width: '96%' }}>
      <Input
        placeholder="请输入绩效名称/员工名称"
        allowClear
        value={searchData.key}
        style={{ width: 200 }}
        onClick={(e) => e.stopPropagation()}
        onChange={handleSearch}
      />
      <span onClick={(e) => e.stopPropagation()}>
        <TreeSelect value={searchData.deptList} {...deptProps} />
      </span>
      <div style={{   position: 'absolute',right: '20px',top: '10px'}}>
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            onChange?.(searchData);
            getSearchCount?.(searchData);
          }}
        >
          查询
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onChange?.({}, true);
            getSearchCount?.({}, true);
            onReset();
          }}
        >
          重置
        </Button>
      </div>
    </div>
  );

  let dateExtra = (
    <DatePicker.RangePicker
      picker={performanceType == performanceKey.OSA ? 'quarter' : undefined}
      value={osaTime}
      allowClear={false}
      onChange={timeChange}
      style={{ marginLeft: 4 }}
    />
  );

  const getCount = (key) => managerCount?.[key] || 0;

  const searchRowOSA = [
    [
      '日期范围',
      [
        `上季度:1-quarter`,
        `本季度:0-quarter`,
        `半年:6-month`,
        `一年:1-year`,
        `两年:2-year`,
        `三年:3-year`,
        `五年:5-year`,
      ],
      timeType,
      (item) => timeSearch({ time: item.value }),
      dateExtra,
    ],
    [
      '信息类别',
      [
        `全部(${getCount('osaTypeTotal')}):-1`,
        `季度绩效(${getCount('osaEvaluate')}):2`,
        `试用期转正评估(${getCount('probationToRegular')}):1`,
        `个人发展计划(${getCount('personDevolopPlan')}):4`,
        `绩效改进评估(${getCount('osaImproveEvaluate')}):3`,
      ],
      typeChecked,
      (item) => {
        onChange?.({ osaType: item.value });
        setTypeChecked(item.value);
      },
    ],
    [
      '当前状态',
      [
        `全部(${getCount('osaStatusTotal')}):-1`,
        `已提交(${getCount('osaCommitCount')}):1`,
        `未提交(${getCount('osaNoCommitCount')}):2`,
      ],
      osaStatus,
      (item) => {
        onChange?.({ osaStatusType: item.value });
        setOsaStatus(item.value);
      },
    ],
    [
      '自评状态',
      [
        `全部(${getCount('osaSelfStatusTotal')}):-1`,
        `已自评(${getCount('osaHaveSelfCount')}):1`,
        `未自评(${getCount('osaNoSelfCount')}):2`,
      ],
      selfScore,
      (item) => {
        onChange?.({ osaSelfStatusType: item.value });
        setSelfScore(item.value);
      },
    ],
    [
      '上级评分',
      [
        `全部(${getCount('osaDirectScoreStatusTotal')}):-1`,
        `已评(${getCount('osaHaveDirectScoreCount')}):1`,
        `未评(${getCount('osaNoDirectScoreCount')}):2`,
      ],
      directScore,
      (item) => {
        onChange?.({ osaDirectScoreStatusType: item.value });
        setDirectScore(item.value);
      },
    ],
    [
      '业务负责人评分',
      [
        `全部(${getCount('osaBusiScoreStatusTotal')}):-1`,
        `已评(${getCount('osaHaveBusiScoreCount')}):1`,
        `未评(${getCount('osaNoBusiScoreCount')}):2`,
      ],
      busSicore,
      (item) => {
        onChange?.({ osaBusiScoreStatusType: item.value });
        setBusSicore(item.value);
      },
    ],
  ];
  const searchRowWeek = [
    [
      '日期范围',
      [
        `上周:1-week`,
        `本周:0-week`,
        `本月:0-month`,
        `季度:1-quarter`,
        `半年度:6-month`,
        `一年:1-year`,
        `三年:3-year`,
      ],
      timeType,
      (item) => timeSearch({ time: item.value }),
      dateExtra,
    ],
    [
      '当前状态',
      [
        `全部(${getCount('osaStatusTotal')}):-1`,
        `已提交(${getCount('osaCommitCount')}):1`,
        `未提交(${getCount('osaNoCommitCount')}):2`,
      ],
      osaStatus,
      (item) => {
        onChange?.({ osaStatusType: item.value });
        setOsaStatus(item.value);
      },
    ],
    [
      '上级评分',
      [
        `全部(${getCount('osaDirectScoreStatusTotal')}):-1`,
        `已评(${getCount('osaHaveDirectScoreCount')}):1`,
        `未评(${getCount('osaNoDirectScoreCount')}):2`,
      ],
      directScore,
      (item) => {
        onChange?.({ osaDirectScoreStatusType: item.value });
        setDirectScore(item.value);
      },
    ],
  ];

  const numSpanClick = async (setData, value, prop) => {
    setTypeChecked('-1');
    setOsaStatus('-1');
    setSelfScore('-1');
    setDirectScore('-1');
    setBusSicore('-1');
    setData(value);
    let payload = {
      osaType: '-1',
      osaStatusType: '-1',
      osaSelfStatusType: '-1',
      osaDirectScoreStatusType: '-1',
      osaBusiScoreStatusType: '-1',
      [prop]: value,
    };
    if (performanceType == performanceKey.WEEK) {
      payload = {
        osaStatusType: '-1',
        osaDirectScoreStatusType: '-1',
        [prop]: value,
      };
    }
    onChange?.(payload);
  };

  const getNumSpan = (key, click) => (
    <span
      style={{
        textDecoration: 'underline',
        color: '#4389f9',
        cursor: 'pointer',
      }}
      onClick={click}
    >
      {managerCount?.[key] ?? 0}
    </span>
  );
  const alertCountText = (
    <div>
      员工总数
      {getNumSpan('osaStatusTotal', () =>
        numSpanClick(setOsaStatus, '-1', 'osaStatusType'),
      )}
      份， 已提交OSA季度绩效
      {getNumSpan('osaCommitCount', () =>
        numSpanClick(setOsaStatus, '1', 'osaStatusType'),
      )}
      份 未提交
      {getNumSpan('osaNoCommitCount', () =>
        numSpanClick(setOsaStatus, '2', 'osaStatusType'),
      )}
      份。
      {performanceType == performanceKey.OSA && (
        <span>
          员工已完成自评
          {getNumSpan('osaHaveSelfCount', () =>
            numSpanClick(setSelfScore, '1', 'osaSelfStatusType'),
          )}
          份,
        </span>
      )}
      {performanceType == performanceKey.OSA && (
        <span>
          未自评
          {getNumSpan('osaNoSelfCount', () =>
            numSpanClick(setSelfScore, '2', 'osaSelfStatusType'),
          )}
          份。
        </span>
      )}
      上级已完成评分
      {getNumSpan('osaHaveDirectScoreCount', () =>
        numSpanClick(setDirectScore, '1', 'osaDirectScoreStatusType'),
      )}
      份， 未评分
      {getNumSpan('osaNoDirectScoreCount', () =>
        numSpanClick(setDirectScore, '2', 'osaDirectScoreStatusType'),
      )}
      份。
    </div>
  );

  return (
    <>
      <Collapse
        className="template-card"
        defaultActiveKey={'1'}
        style={{ marginBottom: 10 }}
      >
        <Collapse.Panel header={headerRender} name="detail" key={'1'}>
          {(performanceType == performanceKey.OSA
            ? searchRowOSA
            : searchRowWeek
          ).map((item) => createSearchItem(...item))}
        </Collapse.Panel>
      </Collapse>

      <Alert
        type="info"
        style={{ marginBottom: 10 }}
        showIcon
        title={alertCountText}
        closable={false}
      />
    </>
  );
};

export default CollapseSearch;
