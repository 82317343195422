import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataAnalysePage from '@/components/DataAnalysePage';
import { useDispatch, useSelector, history } from 'umi';
import { Tabs, Button } from 'kenshin';
import { downloadForCollect } from '../../../_serveice/dataAnalyse';

const dataAnalyseCollect = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  // 标签栏当前key
  const [curCheckedKey, setCurCheckedKey] = useState('1');
  const curCheckedKeyRef = useRef(1);

  const empRptDetailStat = useSelector(
    ({ dataAnalyse }) => dataAnalyse.empRptDetailStat,
  );
  const dataRule = (arr) => {
    if (!arr) {
      return {};
    }
    let xName = curCheckedKeyRef.current == 1 ? 'empName' : 'deptName';
    const money = (type, step = 100) =>
      arr.map((item) => +(item[type] / step).toFixed(2));
    const xData = arr.map((item) => item[xName]);
    return {
      xData,
      data: [
        {
          name: '正常上报',
          type: 'bar',
          color: '#41c797',
          data: money('normalRptDays', 1),
        },
        {
          name: '异常上报',
          type: 'bar',
          color: '#4389fc',
          data: money('errorRptDays', 1),
        },
        {
          name: '正常上报率',
          type: 'line',
          color: '#41c797',
          data: money('normalRptPercent', 1),
        },
        {
          name: '异常上报率',
          type: 'line',
          color: '#4389fc',
          data: money('errorRptPercent', 1),
        },
      ],
    };
  };
  const dispatchType = {
    list: 'getAllEmpRptDetailStat',
    charts: 'getAllEmpRptStat',
  };
  const dataType = {
    charts: 'empRptStat',
    list: 'empRptDetailStat',
  };

  // console.log(2222222211111);

  const toDetail = (row) => {
    let { searchTime } = tableRef.current.value;
    let statBeginTime = moment().subtract(7, 'day').format('YYYY-MM-DD');
    let statEndTime = moment().format('YYYY-MM-DD');
    if (searchTime) {
      statBeginTime = moment(searchTime[0]).format('YYYY-MM-DD');
      statEndTime = moment(searchTime[1]).format('YYYY-MM-DD');
    }
    history.push({
      pathname: '/dataAnalyse/reported/reporteDetail',
      query: {
        empId: row.empId,
        statBeginTime,
        statEndTime,
      },
    });
  };

  const columns = useMemo(() => {
    let publicColumns = [
      {
        dataIndex: 'errorRptDays',
        width: 150,
        title: '异常上报天数',
      },
      {
        dataIndex: 'errorRptDeptPercent',
        width: 150,
        title: '异常上报率占比',
      },
      {
        dataIndex: 'errorRptPercent',
        width: 150,
        title: '异常上报率',
      },
      {
        dataIndex: 'normalRptDays',
        width: 150,
        title: '正常上报天数',
      },
      {
        dataIndex: 'normalRptPercent',
        width: 150,
        title: '正常上报率',
      },
      {
        dataIndex: 'overtimeDeptPercent',
        width: 150,
        title: '加班占比',
      },
      {
        dataIndex: 'partinProjectNum',
        width: 150,
        title: '参与项目',
      },
      {
        dataIndex: 'partinWorkPkgNum',
        width: 150,
        title: '参与工作包',
      },
      {
        dataIndex: 'rptWorkOvertimeHours',
        width: 150,
        title: '上报加班工时',
      },
      {
        dataIndex: 'rptWorkTotalHours',
        width: 150,
        title: '上报工时',
      },
    ];
    if (curCheckedKey == 1) {
      return [
        {
          dataIndex: 'empName',
          width: 150,
          fixed: 'left',
          title: '员工姓名',
        },
        {
          dataIndex: 'deptName',
          width: 150,
          fixed: 'left',
          title: '部门',
        },
        ...publicColumns,
        {
          key: 'options',
          width: 100,
          fixed: 'right',
          title: '操作',
          render: (row) => {
            return (
              <>
                <Button type="text" onClick={() => toDetail(row)}>
                  详细
                </Button>
              </>
            );
          },
        },
      ];
    }
    return [
      {
        dataIndex: 'deptName',
        width: 150,
        title: '部门名称',
      },
      ...publicColumns,
    ];
  }, [curCheckedKey]);

  const checkedKeyObj = useMemo(
    () => ({
      1: 'statYearMonth',
      2: 'statYearQuarter',
      3: 'statYear',
    }),
    [],
  );

  // const onExpand = ({ defaultTime }) => {
  //   return (expanded, props) => {
  //     if (expanded) {
  //       dispatch({
  //         type: `dataAnalyse/${dispatchType.listById}`,
  //         payload: {
  //           [checkedKeyObj[curCheckedKey]]: props.statTime,
  //           statBeginTime: defaultTime[0].format('YYYY'),
  //           statEndTime: defaultTime[1].format('YYYY'),
  //         },
  //       });
  //     }
  //   };
  // };

  const tabsArr = useMemo(
    () => [
      {
        label: '员工',
        value: '1',
      },
      {
        label: '部门',
        value: '2',
      },
    ],
    [],
  );

  const handleTabsChange = (key) => {
    setCurCheckedKey(key);
    curCheckedKeyRef.current = key;
    let payload = {};
    // if (key == 2) {
    //   payload = { isDep: true };
    // }
    // tableRef.current?.reload();
  };

  return (
    <>
      <Tabs defaultActiveKey="1" type="card" onChange={handleTabsChange}>
        {tabsArr.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.value}></Tabs.TabPane>
        ))}
      </Tabs>
      <DataAnalysePage
        dataRule={dataRule}
        chartsProps={{ unit: '天' }}
        dataType={dataType}
        dispatchType={dispatchType}
        columns={columns}
        timeFormat="YYYY-MM-DD"
        tableKey={(_, index) => index}
        ref={tableRef}
        otherParams={{
          isDep: curCheckedKey == 2,
        }}
        rowTableKey="empId"
        // searchCloumns={searchCloumns}
        // onExpand={onExpand}
        tableProps={{
          pagination: false,
          dataSource: empRptDetailStat,
          expandable: {},
          // params:{
          //   isDep:curCheckedKeyRef.current == 2
          // }
        }}
        // downloadUrl={downloadForCollect}
        downloadName="上报分析"
      />
    </>
  );
};

export default dataAnalyseCollect;
