import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Timeline,
  Tag,
} from 'kenshin';
import { useSelector, history } from 'umi';
import './index.less';
import { formUrl } from '../../_pendingBusiness';

const defaultShowType = ['osaType', 'osaWeekType', 'time'];

let searchTimer = null;
const CollapseSearch = ({ onChange, searchChange, data: obj }) => {
  const [osaTime, setOsaTime] = useState([]);
  const [timeType, setTimeType] = useState('0');
  const [infoChecked, setInfoChecked] = useState(-1);
  const usercenter = useSelector((state) => state.usercenter);
  const system = useSelector((state) => state.system);

  const [key, setKey] = useState('');

  useEffect(() => {
    // 假如是从处理页面返回，则回显上次的搜索结果
    if (formUrl.includes(system.router.to)) {
      console.log(11111111, usercenter.businessPendSearchData);
      setKey(usercenter.businessPendSearchData.key);
      const startTime = usercenter.businessPendSearchData.statBeginTime;
      const endTime = usercenter.businessPendSearchData.statEndTime;
      if (startTime && endTime) {
        const start = moment(startTime, 'YYYY-MM-DD HH:mm:ss');
        const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss');
        console.log(2222222, startTime);
        setOsaTime([start, end]);
        onChange?.({ start, end });
        // searchChange?.({ start, end });
      }

      if (
        usercenter.businessPendSearchData.busiType != null ||
        usercenter.businessPendSearchData.busiType > 0
      ) {
        onChange?.({ busiType: usercenter.businessPendSearchData.busiType });
        // searchChange?.({ busiType:  usercenter.businessPendSearchData.busiType});
        setInfoChecked(usercenter.businessPendSearchData.busiType);
      }
    }
  }, []);

  const timeSearch = ({ time }) => {
    setTimeType(time);
    const [amount, unit] = time.split('-');
    const start = moment().subtract(amount, unit).startOf('date');
    const end = moment().endOf('date');
    console.log('start = ', start);
    console.log('end = ', end);
    setOsaTime([start, end]);
    const statBeginTime = start.format('YYYY-MM-DD HH:mm:ss');
    const statEndTime = end.format('YYYY-MM-DD HH:mm:ss');
    onChange?.({ statBeginTime, statEndTime });
    searchChange?.({ statBeginTime, statEndTime });
  };
  const timeChange = (times) => {
    setOsaTime(times);
    let statBeginTime = undefined;
    let statEndTime = undefined;
    if (times) {
      const [start, end] = times;
      statBeginTime = start.startOf('d').format('YYYY-MM-DD HH:mm:ss');
      statEndTime = end.endOf('d').format('YYYY-MM-DD HH:mm:ss');
    } else {
      setTimeType('0');
    }
    onChange?.({ statBeginTime, statEndTime });
    searchChange?.({ statBeginTime, statEndTime });
  };

  // 表单项配置：信息类别、考核类型
  const infoOptions = [
    {
      label: `全部(${obj.totalCount || 0})`,
      value: -1,
    },
    {
      label: `客户(${obj.customerTypeCount || 0})`,
      value: 1,
    },
    {
      label: `合同(${obj.contractTypeCount || 0})`,
      value: 2,
    },
    {
      label: `项目(${obj.projectTypeCount || 0})`,
      value: 3,
    },
    {
      label: `任务(${obj.taskTypeCount || 0})`,
      value: 4,
    },
    {
      label: `角色(${obj.roleTypeCount || 0})`,
      value: 5,
    },
    {
      label: `OSA绩效(${obj.osaTypeCount || 0})`,
      value: 6,
    },
    {
      label: `周执行(${obj.weekPlanTypeCount || 0})`,
      value: 7,
    },
    {
      label: `绩效变更(${obj.osaChangeTypeCount || 0})`,
      value: 8,
    },
    {
      label: `周计划变更(${obj.weekPlanChangeTypeCount || 0})`,
      value: 9,
    },
    {
      label: `加班申请(${obj.workOvertimeApplyCount || 0})`,
      value: 10,
    },
    {
      label: `外出申请(${obj.gooutApplyCount || 0})`,
      value: 11,
    },
    {
      label: `忘打卡申请(${obj.clockForgetApplyCount || 0})`,
      value: 12,
    },
    {
      label: `假期延期申请(${obj.vacationDelayApplyCount || 0})`,
      value: 13,
    },
    {
      label: `假期申请(${obj.vacationApplyCount || 0})`,
      value: 15,
    },
    {
      label: `加班调休申请(${obj.freeOvertimeApplyCount || 0})`,
      value: 17,
    },
    {
      label: `考勤变更(${obj.attendanceChangeApplyCount || 0})`,
      value: 16,
    },
  ];

  const timeOptions = [
    {
      label: '一个月',
      value: '1-month',
    },
    {
      label: '两个月',
      value: '2-month',
    },
    {
      label: '三个月',
      value: '3-month',
    },
    {
      label: '半年',
      value: '6-month',
    },
    {
      label: '一年',
      value: '12-month',
    },
  ];

  // 搜索事件
  const handleSearch = (e) => {
    e.stopPropagation();
    let value = e.target.value;
    let payload = { key: value };

    if (searchTimer) {
      clearTimeout(searchTimer);
      searchTimer = null;
    }

    searchTimer = setTimeout(() => {
      onChange?.(payload);
    }, 800);
  };

  const headerRender = (
    <div style={{ display: 'flex', width: '96%' }}>
      <Input
        placeholder="请输入业务名称"
        allowClear
        value={key}
        style={{ width: 200 }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setKey(e.target.value)}
      />
      {/* <div style={{ float: 'right' }}> */}
      <div className="button_right">
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            onChange?.({ key });
          }}
        >
          查询
        </Button>
      </div>
    </div>
  );

  return (
    <Collapse
      className="template-card"
      defaultActiveKey={'1'}
      style={{ marginBottom: 10 }}
    >
      <Collapse.Panel header={headerRender} name="detail" key={'1'}>
        <Row style={{ marginBottom: 10, alignItems: 'center' }}>
          <Col flex="60px">信息类别：</Col>
          <Col flex="auto">
            {infoOptions.map((item) => (
              <Tag.CheckableTag
                style={{ marginLeft: 4, marginBottom: 4 }}
                key={item.value}
                checked={item.value == infoChecked}
                onChange={(checked) => {
                  onChange?.({ busiType: item.value });
                  setInfoChecked(item.value);
                }}
              >
                {item.label}
              </Tag.CheckableTag>
            ))}
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col flex="60px">日期范围：</Col>
          <Col flex="auto">
            {/* <Radio.Group
                value={timeType}
                onChange={(e) => timeSearch({ time: e.target.value })}
                optionType="text"
                options={timeOptions}
              /> */}
            {timeOptions.map((item) => (
              <Tag.CheckableTag
                style={{ marginLeft: 4 }}
                key={item.value}
                checked={item.value == timeType}
                onChange={(checked) => {
                  timeSearch({ time: item.value });
                }}
              >
                {item.label}
              </Tag.CheckableTag>
            ))}
            <DatePicker.RangePicker
              value={osaTime}
              onChange={timeChange}
              style={{ marginLeft: 4 }}
            />
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseSearch;
