import { Timeline } from 'kenshin';
import PageLoading from '@/components/pageLoading';
import { useReactive } from 'ahooks';
import { CloseCircleFilled } from '@ant-design/icons';

const renderProcessList = ({ attendance, processApplyId }) => {
  if (!attendance.processList[processApplyId]) {
    return <PageLoading />;
  }

  let list = attendance.processList[processApplyId]?.processNodeList ?? [];
  let curIndex = list.findIndex(
    (item) =>
      attendance.processList[processApplyId]?.curnProcessNode
        ?.processApplyNodeId == item.processApplyNodeId,
  );
  let dots = (index) => {
    // if (list[index].processNodeStatus == 3)
    // {
    //   console.log(12345567,list)
    //   return {dotColor: '#f95520'};
    // }

    // else if (index < curIndex || curIndex == list.length - 1)
    //   return { dotColor: '#0DE894' };
    // else if (index == curIndex) return { dotColor: '#4389f9' };
    // // else if(list[index].processApplyStatus == 3) return {dotColor: 'red'}
    // else return {};

    if (index < curIndex || curIndex == list.length - 1)
      return { dotColor: '#0DE894' };
    // else if (index == processState.backNodeIndex) return { dotColor: '#f95520' };
    else if (list[index]?.processNodeStatus == 3)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };
  return (
    <Timeline>
      {list?.map((item, index) => (
        <Timeline.Item
          key={index}
          content={`${item.processNodeName}${
            item.processApplyNodeHandlerList?.length > 0 ? '（' : ''
          }${
            item.processApplyNodeHandlerList
              ?.map((item) => item.processNodeHandlerName)
              .join('、') ?? ''
          }${item.processApplyNodeHandlerList?.length > 0 ? '）' : ''}`}
          {...dots?.(index)}
        />
      ))}
    </Timeline>
  );
};

export default renderProcessList;
