import React, { useEffect, useState } from 'react';
import { connect, history, KeepAlive } from 'umi';
import Header from './components/Header';
import LayoutMenu from './components/LayoutMenu';
import TabsCard from './components/TabsCard';
import Login from '../pages/user/Login';
import request from 'umi-request';
import menu from '@/_util/menu';
import './index.less';
import './global.less';
import useLoaderDispatch from './hook/useLoaderDispatch';
// import { Breadcrumb, BackTop, Button } from 'kenshin';
import Breadcrumb from './components/Breadcrumb';
import { scrollTo } from 'kenshin/lib/back-top/_util';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import useSocket from '../hook/useSocket';
import cachePath from './_util/cachePath';
// 处理删除键回退页面的问题
window.addEventListener('keydown', function (event) {
  if (
    event.key === 'Backspace' &&
    (event.target === document.body || event.target.nodeName == 'DIV') &&
    !event.target.className.includes('luckysheet')
  ) {
    event.preventDefault();
    // Custom function to handle desired behavior
  }
});

const noTabs = ['Login', 'lowCodeIframe'];

function clearService() {
  return navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      window.registrations = registrations;
      for (let registration of registrations) {
        registration.unregister();
      }
    });
}

window.clearService = clearService;
// if (!sessionStorage.getItem('userId')) {
//   console.log('不存在服务');
// clearService();
// setTimeout(() => {
//   location.reload()
// }, 1000);
// }

let lastRouterName =
  history.location.pathname.split('/')[
    history.location.pathname.split('/').length - 1
  ];

const BaseLayout = (props) => {
  const { admin, dispatch, system } = props;
  const [hasLayout, setHasLayout] = useState(noTabs.includes(lastRouterName));

  // useSocket([localStorage.getItem('token')]);

  useEffect(() => {
    let lastRouterName =
      history.location.pathname.split('/')[
        history.location.pathname.split('/').length - 1
      ];
    // 登录页布局需更改，无菜单栏
    setHasLayout(noTabs.includes(lastRouterName));
    // tabsCard标签
    if (!admin.tabsList.includes(history.location.pathname)) {
      // 登录页不应该在tab栏
      if (!noTabs.includes(lastRouterName)) {
        let newList = [...admin.tabsList];
        newList.push(history.location.pathname);
        dispatch({
          type: 'admin/save',
          payload: {
            tabsList: newList,
          },
        });
      }
    }

    // 记录路由跳转日志，umi暂无类似vue的router监听
    dispatch({
      type: 'system/save',
      payload: {
        router: {
          from: system.router.to,
          to: history.location.pathname,
        },
      },
    });
    // });
  }, [location.hash]);

  const getBreadcrumb = () => {
    let arr = [];
    history.location.pathname
      .split('/')
      .filter((item) => !!item)
      .reduce((pre, cur) => {
        pre = pre + `.${cur}`;
        arr.push(pre);
        return pre;
      }, 'menu');
    return arr
      .map((item) => {
        return {
          breadcrumbName: menu[item],
          // path: `/${item.split('.').pop()}`,
          // path:item.split(".").slice(1).join("/")
          path: `/${item.split('.').slice(1).join('/')}`,
          // path: `/performance/assessment/OSA`,
        };
      })
      .filter((item) => item.breadcrumbName);
  };

  useEffect(() => {
    useLoaderDispatch(dispatch);
    let token = localStorage.getItem('token');
    if (token) {
      // 初次进入页面需请求获取菜单栏数据
      // dispatch({
      //   type: 'user/getuserfunctions',
      // });
      // if (process.env.NODE_ENV == 'development') {
      //   request
      //     .get('/local')
      //     .then((res) => {
      //       if (res?.success) {
      //         dispatch({
      //           type: 'user/save',
      //           payload: {
      //             hasLocalServer: true,
      //           },
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       console.warn(
      //         '（可忽略）需要启动本地服务,才能运行部分功能，例如生成页面。',
      //       );
      //     });
      // }
    }
  }, []);
  // 无token不需要渲染页面
  if (!localStorage.getItem('token')) {
    return props.children;
  }

  // 下滑至底部
  const scrollToBottom = (num) => {
    let height = document.querySelector('.content').scrollHeight;
    scrollTo(num ?? height, {
      getContainer: () => document.querySelector('.content'),
      duration: 800,
    });
  };

  return hasLayout ? (
    // <Login />
    props.children
  ) : (
    <div className="base-layout">
      <div className="menu-layout">
        <LayoutMenu routes={props.routes[0].routes} />
        <div className="content">
          {/* 去到底部 */}
          <div className="scroll-to bottom" onClick={() => scrollToBottom()}>
            {/* <VerticalAlignBottomOutlined /> */}
            <ArrowDownOutlined />
          </div>
          <div className="scroll-to top" onClick={() => scrollToBottom(0)}>
            <ArrowUpOutlined />
          </div>
          {/* <BackTop
            target={() => document.querySelector('.content')}
            style={{ right: 15 }}
          /> */}
          <div className="layout-breadcrumb">
            <Breadcrumb routes={getBreadcrumb()} separator=">" />
          </div>
          <div className="content-body">
            <div className="layout_main">{props.children}</div>
          </div>
        </div>
      </div>
      <Header />
    </div>
  );
};

export default connect(({ admin, system }) => ({ admin, system }))(BaseLayout);
