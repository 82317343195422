import { Alert, Tooltip } from 'kenshin';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';

const AlertToOsa = ({ payload }) => {
  const dispatch = useDispatch();
  const osaCommitList = useSelector(
    ({ performance }) => performance.osaCommitList,
  );

  let hsaRender =
    payload?.deptId && payload?.osaWeekType && payload?.osaBeginTime;
  useEffect(() => {
    if (hsaRender) {
      dispatch({
        type: 'performance/osaempcommitstatlist',
        payload: {
          ...payload,
          key: undefined,
        },
      });
    }
  }, [payload?.deptId, payload?.osaWeekType, payload?.osaBeginTime]);

  const getTooltipTitle = (list) => {
    if (Array.isArray(list)) {
      return (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {list.map((item) => (
            <div key={item.id} style={{ width: '100px' }}>
              {' '}
              {item.value}
            </div>
          ))}
        </div>
      );
    }
  };

  const getTitle = () => {
    let nocommitLength = osaCommitList?.noCommitEmpList?.length ?? 0;
    let allLength =
      (osaCommitList?.commitEmpList?.length ?? 0) + nocommitLength;
    return (
      <div>
        总人数：{allLength}人,剩余
        <Tooltip
          overlayStyle={{
            maxWidth: 420,
            minWidth: 100,
            fontSize: 12,
            lineHeight: '24px',
          }}
          title={getTooltipTitle(osaCommitList?.noCommitEmpList || '')}
        >
          <span
            className="span"
            style={{
              color: 'red',
              padding: '0 10px',
              textDecoration: 'underline',
            }}
          >
            {nocommitLength}
          </span>
        </Tooltip>
        人未提交绩效
      </div>
    );
  };
  if (!hsaRender) {
    return <></>;
  }
  return <Alert title={getTitle()} type="info" showIcon closable={false} />;
};

export default AlertToOsa;
