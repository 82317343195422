import request from '@/_util/require';

let version = 'v1';
export async function sysLogin(options) {
  return request(`/${version}/waper/erp/pc/login/employeelogin`, {
    method: 'POST',
    data: options,
  });
}

export async function getuserfunctions(params) {
  return request(`/${version}/waper/erp/pc/menuperm/getmenupermtree`, {
    method: 'GET',
    params,
  });
}

export async function getOSSMsg(params) {
  return request(`/${version}/waper/erp/pc/oss/getosspolicemsg`, {
    method: 'GET',
    params,
  });
}
