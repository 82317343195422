import { Button, Radio, Row, Typography } from 'kenshin';
import React, { useEffect, useState } from 'react';
import styles from './index.less';
import { smallCardList, largerCardList, getInfoList } from './data';
import StatisticsCard from '../components/StatisticsCard';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import StaticsCalendar from '../components/StaticsCalendar';
import { getempindexattendancestatdata } from '../../../../_serveice/attendance';
import { useReactive } from 'ahooks';

const Personal = () => {
  const [expansion, setExpansion] = useState(false);

  const state = useReactive({
    time: '0.month:0.month',
    data: {},
  });

  const options = [
    { label: '昨日', value: '-1.d:-1.d' },
    { label: '今日', value: '0.d:0.d' },
    { label: '本周', value: '0.w:0.w' },
    { label: '本月', value: '0.month:0.month' },
  ];

  useEffect(() => {
    // getempindexattendancestatdata()
    handleTime(state.time);
  }, []);

  const getListData = (payload) => {
    getempindexattendancestatdata(payload).then((res) => {
      console.log(res);
    });
  };

  const expansionChildren = () => {
    if (expansion)
      return (
        <div className={styles.expansionBtn}>
          收起
          <CaretUpOutlined />
        </div>
      );
    return (
      <div className={styles.expansionBtn}>
        展开
        <CaretDownOutlined />
      </div>
    );
  };

  // 切换时间
  const handleTime = (timeStr) => {
    state.time = timeStr;
    let timeArr = timeStr.split(':').map((item, i) => {
      let [diff, unit] = item.split('.');
      let times = moment().add(diff, unit).startOf(unit);
      if (i == 1) times = times.endOf(unit);
      if (unit == 'w') times = times.add(1, 'd'); // 如果是本周，则加一（国际时间本周开始是周日开始）
      return times.format('YYYY-MM-DD HH:mm:ss');
    });
    const [queryBeginTime, queryEndTime] = timeArr;
    let payload = { queryBeginTime, queryEndTime };
    getListData(payload);
    // console.log(timeArr);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <div
          style={{
            lineHeight: '65px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Radio.Group
            options={options}
            onChange={(e) => handleTime(e.target.value)}
            value={state.time}
            optionType="button"
          />
          <Button type="text" onClick={() => setExpansion(!expansion)}>
            {expansionChildren()}
          </Button>
        </div>
        <h3 className={styles.title}>本月考勤统计</h3>
        <div className={styles.cardFlex}>
          {getInfoList(state.data).map((props, key) => (
            <StatisticsCard key={key} {...props} />
          ))}
        </div>
        <div style={{ display: expansion ? 'block' : 'none' }}>
          <h3 className={styles.title} style={{ marginTop: 30 }}>
            加班统计
          </h3>
          <div className={styles.cardFlex}>
            {largerCardList(state.data).map((props, key) => (
              <StatisticsCard key={key} {...props} />
            ))}
          </div>
        </div>
      </div>
      <StaticsCalendar />
    </>
  );
};

export default Personal;
