import { useSelector } from 'umi';
import { MessageBox, Button } from 'kenshin';
import { useEffect, useState } from 'react';
import { useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { getTableCloumns } from './_cloumns';
import { useReactive } from 'ahooks';
import HolidayChangeDetail from './HolidayChangeDetail';

const HolidayChangeLog = ({ visible, onCancel, data }) => {
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);

  const state = useReactive({
    detailVisible: false,
    detailData: null,
  });

  useEffect(() => {
    if (visible) {
      dispatch({
        type: 'attendance/getemployeevacationchangelist',
        payload: {
          empId: data.empId,
          vacationId: data.vacationId,
        },
      }).then((list) => {
        if (Array.isArray(list)) {
          setDataSource(list);
        }
      });
    }
  }, [visible]);

  const showChangelog = (row) => {
    state.detailVisible = true;
    state.detailData = row;
  };

  // 表格列
  const columns = getTableCloumns();
  columns.splice(
    1,
    0,
    {
      dataIndex: 'vacationChangeBefore',
      title: '变更前',
      width: '80px',
    },
    {
      dataIndex: 'vacationChangeAfter',
      title: '变更后',
      width: '80px',
    },
  );
  columns.push({
    key: 'options',
    title: '操作',
    width: '150px',
    fixed: 'right',
    render: (row) => {
      // let fun = row.vacationChangeType == 1 ?showAdd:showSub;
      return (
        <div>
          <Button type="text" onClick={() => showChangelog(row)}>
            查看详情
          </Button>
        </div>
      );
    },
  });

  return (
    <MessageBox
      title={`变动记录`}
      onCancel={onCancel}
      maskClosable={false}
      width="90%"
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      <TablePro
        rowKey={'empVacationChangeId'}
        dataSource={dataSource}
        pagination={false}
        columns={columns}
        scrollX
        columnsOptions={false}
        curColumns
      ></TablePro>

      <HolidayChangeDetail
        visible={state.detailVisible}
        data={state.detailData}
        onCancel={() => {
          state.detailVisible = false;
          state.detailData = null;
        }}
      />
    </MessageBox>
  );
};

export default HolidayChangeLog;
