import { Button, DatePicker, InputNumber, Table } from 'kenshin';
import Select from '@/_util/components/Select';
import { useEffect, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { changeNumToHan } from '@/_util/util';

const buttonStyle = {
  borderRadius: '12px',
  width: '24px',
  height: '24px',
  textAlign: 'center',
  padding: 0,
  marginLeft: 10,
};
const defaultData = () => ({
  collectAmount: '',
  collectBatch: null,
  collectDate: '',
});

const hiddenStr = ['结算款', '尾款'];

const onChangeToData = (data) => {
  if (Array.isArray(data)) {
    return [...data.map((item, i) => ({ ...item, collectSort: i + 1 }))];
  }
};

const CollectBatch = ({ onChange, value }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(value || []);
  }, [value]);

  const add = () => {
    setData((data) => {
      data.push(defaultData());
      onChange?.(onChangeToData(data));
      return [...data];
    });
  };

  const delItem = (row) => {
    setData((data) => {
      let i = data.indexOf(row);
      data.splice(i, 1);
      onChange?.(onChangeToData(data));
      return [...data];
    });
  };

  const handleChange = (val, row) => {
    Object.assign(row, val);
    setData([...data]);
    onChange?.(onChangeToData(data));
  };

  const optionsMap = (arr) => arr.map((item) => ({ label: item, value: item })); // 简单处理label跟value同的情况

  const collectBatchOptions = (i) => {
    if (i == 0) {
      //  第一次只有首款跟全款
      return optionsMap(['首款', '全款']);
    }
    if (data[0].collectBatch == '全款') {
      //假如是全款，则只能有最后的结算款
      return optionsMap(['结算款']);
    }
    return optionsMap(['尾款', '结算款', `第${changeNumToHan(i + 1)}笔`]);
  };

  // 禁用时间，下次的时间必须等于或者大于上一次的时间
  const disabledDate = (i) => {
    let index = i - 1;
    if (index < 0) {
      return undefined;
    }
    return (current) => {
      return current < moment(data[index].collectDate).startOf('day');
    };
  };

  const columns = [
    {
      dataIndex: 'collectBatch',
      title: '收款批次',
      width: '30%',
      render: (val, row, i) => (
        <Select
          placeholder="收款批次"
          value={val}
          onChange={(val) => handleChange({ collectBatch: val }, row)}
          options={collectBatchOptions(i)}
        />
      ),
    },
    {
      dataIndex: 'collectAmount',
      title: '收款金额/元',
      width: '25%',
      render: (val, row) => (
        <InputNumber
          placeholder="收款金额"
          onChange={(val) => handleChange({ collectAmount: val }, row)}
          value={val}
          style={{ width: '100%', marginBottom: -6 }}
          stepType="inside"
        />
      ),
    },
    {
      dataIndex: 'collectDate',
      title: '预计收款日期',
      width: '30%',
      render: (val, row, i) => (
        <DatePicker
          onChange={(val) =>
            handleChange(
              { collectDate: val ? moment(val).format('YYYY-MM-DD') : null },
              row,
            )
          }
          value={val ? moment(val) : undefined}
          disabledDate={disabledDate(i)}
          format="YYYY-MM-DD"
        />
      ),
    },
    {
      key: 'options',
      title: (
        <div>
          操作
          <Button
            type="success"
            style={buttonStyle}
            onClick={add}
            disabled={
              data
                ?.map((item) => item.collectBatch)
                .filter((item) => hiddenStr.includes(item)).length > 0
            }
          >
            <PlusOutlined />
          </Button>
        </div>
      ),
      width: '15%',
      render: (row) => (
        <>
          {row.used != 1 && (
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delItem(row)}
            >
              删除
            </Button>
          )}
        </>
      ),
    },
  ];
  // const tableProps = {
  //   columns
  // }
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(_, i) => i}
      pagination={false}
    />
  );
};

export default CollectBatch;
