import React, { useState, useEffect, useRef } from 'react';
import { MessageBox, Form, Radio, Input, Select, Button, Tree } from 'kenshin';
import { connect } from 'umi';
import { debounce, deepClone, filterTree } from '@/_util';

const PowerTreeModal = ({
  treeList,
  data,
  value,
  visible,
  onCancel,
  onSave,
  ...props
}) => {
  const baseTreeData = useRef([]);
  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState();

  useEffect(() => {
    setCheckedKeys(value);
  }, [value]);

  useEffect(() => {
    let resData = JSON.parse(
      JSON.stringify(treeList)
        .replace(/permName/g, 'title')
        .replace(/permId/g, 'key'),
    );
    setTreeData(resData);
    baseTreeData.current = resData;
  }, [treeList]);

  const handleOk = () => {
    onSave?.(checkedKeys);
  };

  const titleRender = (nodeData) => {
    const findAllKeys = (arr) => {
      let resArr = [];
      arr.map((item) => {
        resArr.push(item.key);
        if (item.children) {
          let keys = findAllKeys(item.children);
          resArr = [...resArr, ...keys];
        }
      });
      return resArr;
    };

    return (
      <div>
        {nodeData.title}
        {nodeData.hasChild && (
          <Button
            type="text"
            style={{ marginLeft: 10 }}
            onClick={() => {
              setCheckedKeys((oldKey) => {
                let newKeys = findAllKeys(nodeData.children);
                let key1 = oldKey.filter((item) => !newKeys.includes(item));
                let key2 = newKeys.filter((item) => !oldKey.includes(item));
                let res = Array.from(new Set([...key1, ...key2]));
                return res;
              });
            }}
          >
            反选
          </Button>
        )}
      </div>
    );
  };

  const searchInput = (e) => {
    let value = e.target.value;
    setTreeData(filterTree(deepClone(baseTreeData.current), value));
  };

  return (
    <MessageBox
      width="800px"
      onCancel={onCancel}
      destroyOnClose
      visible={visible}
      bodyStyle={{ maxHeight: '600px', overflow: 'auto' }}
      title="授权"
      onOk={handleOk}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          确定
        </Button>,
      ]}
    >
      <Input onChange={debounce(searchInput, 500)} placeholder="输入搜索关键词" />
      <Tree
        checkable
        defaultExpandAll
        checkStrictly
        titleRender={titleRender}
        height={600}
        checkedKeys={checkedKeys}
        selectedKeys={[]}
        treeData={treeData}
        onCheck={(keys) => {
          setCheckedKeys(keys.checked);
        }}
      />
    </MessageBox>
  );
};

const PowerTree = (props) => {
  const { dispatch, system, value = [], onChange } = props;
  const [treeVisible, setTreeVisible] = useState(false);
  useEffect(() => {
    dispatch({
      type: 'system/getsysfunctiontree',
    });
  }, []);

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setTreeVisible(true);
        }}
      >
        授权
      </Button>

      <PowerTreeModal
        visible={treeVisible}
        treeList={system.menuList}
        value={value}
        onCancel={() => setTreeVisible(false)}
        onSave={(keys) => {
          onChange?.(keys);
          setTreeVisible(false);
        }}
      />
    </>
  );
};

export default connect(({ system, loading }) => {
  return {
    system,
    loading: loading.effects,
  };
})(PowerTree);
