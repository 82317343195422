import {
  getUsercenterList,

  // 多余待删除
  getProServcatgHourList,
  getEmpCurTimesLotList,
  getDeptmanagerByMuid,
  reportEmpWork,
  getEmpWorkRptPage,
  getEmpWorkRptList,
  getEmpHomeWork,
  getMangeHomeWork,
  getFinaceHomeWork,
  getFinaceHomeTable,
  getFinanceProdCharts,
  // 部门员工上报列表
  getDeptRptList,
  getReportEmpWorkDetail,
  updateReportEmpWorkDetail,
  // 新版本v1.1 上报
  getRptTaskPage,
  deletereportworkdetail,
  getemprptlistpage,
  moveemprptdata,
  // 待办/已办
  todoitemstat,
  gettodoitemnewpage,
  getcompleteitemnewpage,
  completeitemstat,
  getholidaybyyear,
} from '@/_serveice/usercenter/index.js';
import { reArrAngement, getRoleType } from '@/_util';
import request from 'umi-request';

// 国家法定节假日
// async function getHolidayApi(date) {
//   return request(`https://timor.tech/api/holiday/year/${date}`, {
//     method: 'GET',
//     noHeaders: true,
//   });
// }

function getWordCardApi(role) {
  if (getRoleType() == 1) {
    return getFinaceHomeWork; // 财务，待修改
  }
  if (getRoleType() == 2) {
    return getMangeHomeWork;
  }
  return getEmpHomeWork;
}

let getState = () => ({
  usercenterList: {},
  usercenterListSearchForm: {},

  usercenterProjectList: {},
  usercenterProjectListSearchForm: {},

  empWorkRptList: {},
  empWorkRptListSearchForm: {},

  deptRptList: {},
  deptRptListSearchForm: {},

  servcatgHourList: [],

  deptmanagerList: [],

  empCurTimesLot: [],

  curStep: 0,
  rptData: {},
  homeWork: {},

  homeTableList: [],

  // 首页上报
  homeEmpWorkRptList: {
    key: [],
    list: [],
  },

  // 发起流程临时数据存储
  createProcessData: {},

  // 上报记录
  emprptlistpageList: {},
  emprptlistpageListSearch: {},
  // 待办/已办
  todoSearchNum: {},
  todoPageList: {},
  todoPageListSearch: {},
  hasdoSearchNum: {},
  hasdoPageList: {},
  hasdoPageListSearch: {},
  businessPendSearchData: {}, // 存储搜索条件key，处理数据返回后回显
});

export default {
  namespace: 'usercenter',

  state: getState(),

  effects: {
    *init({ payload, reset }, { put }) {
      yield put({
        type: 'save',
        payload: getState(),
      });
    },
    // 页面列表--------------------------
    *getUsercenterProjectList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.usercenterProjectListSearchForm,
      );
      let usercenterProjectListSearchForm = { ...searchData, ...payload };
      if (reset) {
        usercenterProjectListSearchForm = payload;
      }
      const res = yield call(
        getUsercenterList,
        usercenterProjectListSearchForm,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            usercenterProjectList: res.data,
            usercenterProjectListSearchForm,
          },
        });
      }
    },
    *getUsercenterList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.usercenterListSearchForm,
      );
      let usercenterListSearchForm = { ...searchData, ...payload };
      if (reset) {
        usercenterListSearchForm = payload;
      }
      const res = yield call(getRptTaskPage, usercenterListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            usercenterList: res.data,
            usercenterListSearchForm,
          },
        });
      }
    },
    *getEmpWorkRptPage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.empWorkRptListSearchForm,
      );
      let empWorkRptListSearchForm = { ...searchData, ...payload };
      if (reset) {
        empWorkRptListSearchForm = payload;
      }
      const res = yield call(getEmpWorkRptPage, empWorkRptListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            empWorkRptList: res.data,
            empWorkRptListSearchForm,
          },
        });
      }
    },
    *getDeptmanagerByMuid({ payload }, { put, call }) {
      const res = yield call(getDeptmanagerByMuid, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            deptmanagerList: res.data,
          },
        });
        return true;
      }
    },
    *getProServcatgHourList({ payload }, { put, call }) {
      const res = yield call(getProServcatgHourList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            servcatgHourList: res.data,
            homeEmpWorkRptList: {
              key: [],
              list: [],
            },
          },
        });
        return res.data;
      }
    },
    *getEmpCurTimesLotList({ payload, hasSave = true }, { put, call }) {
      const res = yield call(getEmpCurTimesLotList, payload);
      if (res?.success) {
        if (hasSave) {
          yield put({
            type: 'save',
            payload: {
              empCurTimesLot: res.data ?? [],
            },
          });
        }
        return res;
      }
    },
    *reportEmpWork({ payload }, { put, call, select }) {
      const res = yield call(reportEmpWork, payload);
      const homeEmpWorkRptList = yield select(
        ({ usercenter }) => usercenter.homeEmpWorkRptList,
      );
      if (res?.success) {
        homeEmpWorkRptList.key = [];
        yield put({
          type: 'getUsercenterList',
        });
        yield put({
          type: 'save',
          payload: {
            homeEmpWorkRptList,
          },
        });
        return true;
      }
    },
    *getEmpWorkRptList({ payload }, { put, call, select }) {
      const homeEmpWorkRptList = yield select(
        ({ usercenter }) => usercenter.homeEmpWorkRptList,
      );

      if (payload.rptEndTime) {
        let month = moment(payload.rptEndTime).format('YYYY-MM');
        let hasKey = homeEmpWorkRptList.key.includes(month);
        if (!hasKey) {
          homeEmpWorkRptList.key.push(month);
          const res = yield call(getEmpWorkRptList, payload);
          // // 国家法定节假日
          // const holiday = yield call(
          //   // getHolidayApi,
          //   payload?.rptBeginTime?.split('-').splice(0, 2).join('-'),
          // );
          // if (holiday?.code == 0) {
          //   yield put({
          //     type: 'save',
          //     payload: {
          //       homeHoliday: { ...homeHoliday, ...holiday.holiday },
          //     },
          //   });
          // }
          if (res?.success) {
            homeEmpWorkRptList.list = [...homeEmpWorkRptList.list, ...res.data];
            homeEmpWorkRptList.list = reArrAngement(
              homeEmpWorkRptList.list,
              'reportDate',
            );
            yield put({
              type: 'save',
              payload: {
                homeEmpWorkRptList,
              },
            });
            return res;
          }
        }
      }
    },
    // 查询首页工作台信息
    *getEmpHomeWork({ payload }, { put, call }) {
      const res = yield call(getWordCardApi(), payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            homeWork: res.data,
          },
        });
        return true;
      }
    },
    // 查询财务表格信息
    *getFinaceHomeTable({ payload }, { put, call, select }) {
      const res = yield call(getFinaceHomeTable, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            homeTableList: res.data,
          },
        });
      }
    },
    // 查询财务圆环信息
    *getFinanceProdCharts({ payload }, { put, call, select }) {
      const res = yield call(getFinanceProdCharts, payload);
      if (res?.success) {
        return res.data;
        // yield put({
        //   type: 'save',
        //   payload: {
        //     homeTableList: res.data,
        //   },
        // });
      }
    },
    // 页面列表---------------end-----------
    // 部门员工上报列表
    *getDeptRptList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.deptRptListSearchForm,
      );
      let deptRptListSearchForm = { ...searchData, ...payload };
      if (reset) {
        deptRptListSearchForm = payload;
      }
      const res = yield call(getDeptRptList, deptRptListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            deptRptList: res.data,
            deptRptListSearchForm,
          },
        });
      }
    },
    *updateReportEmpWorkDetail({ payload }, { put, call, select }) {
      const res = yield call(updateReportEmpWorkDetail, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            homeEmpWorkRptList: {
              key: [],
              list: [],
            },
          },
        });
        yield put({
          type: 'getEmpWorkRptPage',
        });
        return true;
      }
    },
    *getReportEmpWorkDetail({ payload }, { put, call }) {
      const res = yield call(getReportEmpWorkDetail, payload);
      if (res?.success) {
        return res.data;
      }
    },
    *deletereportworkdetail({ payload }, { put, call }) {
      const res = yield call(deletereportworkdetail, payload);
      if (res?.success) {
        yield put({
          type: 'getEmpWorkRptPage',
        });
        return res.data;
      }
    },

    // 上报记录--------------------------
    *getemprptlistpage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.emprptlistpageListSearch,
      );
      let emprptlistpageListSearch = { ...searchData, ...payload };
      if (reset) {
        emprptlistpageListSearch = payload;
      }
      const res = yield call(getemprptlistpage, emprptlistpageListSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            emprptlistpageList: res.data,
            emprptlistpageListSearch,
          },
        });
      }
    },
    *moveemprptdata({ payload }, { put, call }) {
      const res = yield call(moveemprptdata, payload);
      if (res?.success) {
        yield put({
          type: 'getemprptlistpage',
        });
        return res.success;
      }
    },
    *todoitemstat({ payload }, { put, call }) {
      const res = yield call(todoitemstat, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            todoSearchNum: res.data,
          },
        });
        return res.data;
      }
    },
    *gettodoitemnewpage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.todoPageListSearch,
      );
      let todoPageListSearch = { ...searchData, ...payload };
      if (reset) {
        todoPageListSearch = payload;
      }
      const res = yield call(gettodoitemnewpage, todoPageListSearch);
      if (res?.success) {
        let payload = {
          todoPageList: res.data,
          todoPageListSearch,
        };
        // if (todoPageListSearch) {
        //   // 存储搜索条件key，处理数据返回后回显
        payload.businessPendSearchData = todoPageListSearch;
        // }

        yield put({
          type: 'save',
          payload: payload,
        });
      }
    },
    *completeitemstat({ payload }, { put, call }) {
      const res = yield call(completeitemstat, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            hasdoSearchNum: res.data,
          },
        });
        return res.data;
      }
    },
    *getcompleteitemnewpage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ usercenter }) => usercenter.hasdoPageListSearch,
      );
      let hasdoPageListSearch = { ...searchData, ...payload };
      if (reset) {
        hasdoPageListSearch = payload;
      }
      const res = yield call(getcompleteitemnewpage, hasdoPageListSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            hasdoPageList: res.data,
            hasdoPageListSearch,
          },
        });
      }
    },
    *getholidaybyyear({ payload }, { put, call }) {
      const res = yield call(getholidaybyyear, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            hasdoSearchNum: res.data,
          },
        });
        return res.data;
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
