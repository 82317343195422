import React, { useState } from 'react';

import TimerTable from './components/TimerTable';
import TypeTable from './components/TypeTable';

const WorkLog = () => {
  const [tableType, setTableType] = useState(1);

  return (
    <>
      {tableType == 1 && <TimerTable setTableType={setTableType} />}
      {tableType == 2 && <TypeTable setTableType={setTableType} />}
    </>
  );
};

export default WorkLog;
