import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Col, message, MessageBox, Row, Typography } from 'kenshin';
import { connect, history } from 'umi';
import TreeData from '../../components/TreeData';
import CardBox from './components/CardBox';
import './index.less';

export const empStatusArr = {
  0: <div className="status-red">禁用</div>,
  1: <div className="status-green animate">正常</div>,
  2: <div className="status-grey">离职</div>,
};

const Template = ({ dispatch }) => {
  const [muid, setMuId] = useState(history.location.query.muId);
  useEffect(() => {
    if (history.location.query.muId) {
      setMuId(history.location.query.muId);
    }
  }, [history.location.query.muId]);
  const treeHandleChange = (keys, rows, { expandedKeys }) => {
    if (!rows) return;
    setMuId(rows.muid);
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: { expandedKeys },
      },
    });
  };

  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        {!history.location.query.muId && (
          <Col span={4}>
            <TreeData onChange={treeHandleChange} value={muid} useBusiId />
          </Col>
        )}
        <Col span={history.location.query.muId ? 20 : 16}>
          <CardBox muId={muid} />
        </Col>
      </Row>
    </>
  );
};

export default connect(({ systemOrgani, system, loading, user }) => {
  return {};
})(Template);
