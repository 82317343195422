import { getEmpSalaryPage } from '@/_serveice/employee/index.js';

export default {
  namespace: 'employee_salary',

  state: {
    salaryList: {},
    salaryListSearchForm: {},
  },

  effects: {
    // 薪酬管理--------------------------
    *getEmpSalaryPage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ employee_salary }) => employee_salary.salaryListSearchForm,
      );
      let salaryListSearchForm = { ...searchData, ...payload };
      if (reset) {
        salaryListSearchForm = payload;
      }
      const res = yield call(getEmpSalaryPage, salaryListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            salaryList: res.data,
            salaryListSearchForm,
          },
        });
      }
    },
    *editServcatg({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editServcatg : addServcatg, payload);
      if (res?.success) {
        yield put({
          type: 'getEmpSalaryPage',
        });
        return true;
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
