
import React from 'react';
import { Card, Form, Input, InputNumber, DatePicker, Switch, Radio } from 'kenshin';

const User = (props) => {

  const FormProps4620244 = { "id": "Form4620244", "labelCol": { "span": 4 }, "wrapperCol": { "span": 14 }, "labelAlign": "right" }

  const InputProps4644964 = { "placeholder": "请输入内容", "id": "Input4644964" }

  const InputNumberProps4710682 = { "id": "InputNumber4710682", "stepType": "inside", "placeholder": "请输入年龄" }

  const DatePickerProps4753412 = { "id": "DatePicker4753412", "placeholder": "请选择出生年月日" }

  const SwitchProps4738371 = { "id": "Switch4738371" }

  const RadioGroupProps4677681 = { "options": [{ "label": "男", "value": 1 }, { "value": "2", "label": "女" }], "id": "RadioGroup4677681" }


  return (
    <>

      <Form {...FormProps4620244}>
        <Form.Item label="姓名"     >
          <Input {...InputProps4644964}></Input>
        </Form.Item>
        <Form.Item label="年龄"     >
          <InputNumber {...InputNumberProps4710682}></InputNumber>
        </Form.Item>
        <Form.Item label="出生年月日"     >
          <DatePicker {...DatePickerProps4753412}></DatePicker>
        </Form.Item>
        <Form.Item label="婚否"     >
          <Switch {...SwitchProps4738371}></Switch>
        </Form.Item>
        <Form.Item label="性别"     >
          <Radio.Group {...RadioGroupProps4677681}></Radio.Group>
        </Form.Item></Form>

    </>
  )
}

User.title="用户列表"

export default User
