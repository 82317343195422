import React, { createContext, useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import { Affix, Button, message, MessageBox } from 'kenshin';
import JsonForm from '@/components/JsonForm';
import { findTree } from '@/components/ServCategoryList';
import '../../customer/newCustomer/index.less';
import {
  filterServCatgList,
  moundSetData,
  memberListFilter,
} from './detailDataTransform';
import { deepClone } from '@/_util/util';
import { roleType } from '@/_util/keysType';
import { getContractMount } from '@/_serveice/contract/list/index';

export const ServContext = createContext();

const AddProject = ({ dispatch, contract, systemOrgani, loading }) => {
  let proServCatgDiscUnitPirceChange = useRef(false); // 项目服务类目优惠后的单价是否发生变更
  const ref = useRef({});
  const detailData = useRef({
    data: [],
    keys: [],
  });

  const [deleteList, setDeleteList] = useState([]);
  const [contractMoney, setContractMoney] = useState(0);

  useEffect(() => {
    // 合同
    dispatch({
      type: 'contract/getEasyContractList',
    });
    // 服务类目
    dispatch({
      type: 'project/getServcatgEasyList',
    });
    setForLocalStorage();
  }, []);

  const cacheDataRef = useRef();
  const hasSetFild = useRef(false);

  const setForLocalStorage = () => {
    let cacheData = localStorage.getItem('projectCache');
    let fieldValue = (cacheData && JSON.parse(cacheData)) || {};
    let projectId = history.location.query?.projectId;
    if (
      Object.keys(fieldValue).length > 0 &&
      fieldValue.projectId == projectId
    ) {
      MessageBox.confirm({
        title: '系统提示',
        content: '上次保存暂未提交，是否使用上次未提交的数据',
        onCancel: () => {
          if (cacheDataRef.current) {
            const { form } = ref.current;
            //form.setFieldsValue({ ...cacheData.current });
            form.setFieldsValue({ ...cacheDataRef.current });
          }
          hasSetFild.current = true;
        },
        onOk: () => {
          if (fieldValue.projectTime.length == 2) {
            fieldValue.projectTime = [
              moment(fieldValue.projectTime[0]),
              moment(fieldValue.projectTime[1]),
            ];
          } else {
            fieldValue.projectTime = [];
          }
          const { form } = ref.current;
          form.setFieldsValue({ ...fieldValue });
        },
      });
    }
  };

  useEffect(() => {
    let isUnmount = false; // 避免组件卸载了还在渲染组件，例如异步请求的情况
    getCurData(isUnmount);
    // 组织架构

    return () => (isUnmount = true);
  }, []);

  const getCurData = (isUnmount) => {
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((employeeAll) => {
      if (employeeAll) {
        let projectId = history.location.query?.projectId;
        // 获取详情
        if (projectId) {
          dispatch({
            type: 'project/getProjectDetail',
            payload: {
              projectId,
            },
          }).then((res) => {
            if (res && !isUnmount) {
              const { form } = ref.current;
              res.projectTime = [
                moment(res.projectBeginTime),
                moment(res.projectEndTime),
              ];
              res.contractId = `${res.contractId}-${res.contractNumber}`;
              contractChange(res.contractId);
              detailData.current = {
                data: deepClone(res.dbServCatgList) || [],
                keys:
                  res.dbServCatgList?.map((item) => item.proServCatgId) || [],
              };
              res.projectAmount = +(res.projectAmount / 100).toFixed(2);
              res.dbServCatgList = moundSetData(res.dbServCatgList, {
                employeeAll,
                findTree,
              });

              let cacheData = localStorage.getItem('projectCache');
              if (
                cacheData &&
                JSON.parse(cacheData)?.projectId == projectId &&
                !hasSetFild.current
              ) {
                cacheDataRef.current = res;
              } else {
                form?.setFieldsValue({ ...res });
              }
            }
          });
        }
      }
    });
  };

  const contractChange = (key) => {
    if (key) {
      let contractId = key.split('-')?.[0];
      getContractMount({ contractId }).then((res) => {
        if (res?.success) {
          setContractMoney(res.data);
        }
      });
    }
  };

  const edtaFormItem = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      valueType: 'Input',
      required: '请输入项目名称',
      fieldProps: {
        placeholder: '请输入项目名称',
      },
    },
    {
      title: '合同编号',
      dataIndex: 'contractId',
      valueType: 'Select',
      help: `合同剩余金额：${contractMoney / 100}`,
      required: '请输入合同编号',
      fieldProps: {
        placeholder: '请输入合同编号',
        disabled: history.location.query?.projectId,
        onChange: contractChange,
        options: contract.easyContractList?.map((item) => ({
          value: `${item.id}-${item.value}`,
          label: `${item.value}(${item.extendData})`,
        })),
      },
    },
    {
      title: '项目周期',
      dataIndex: 'projectTime',
      valueType: 'DatePickerRangePicker',
      required: '请输入项目周期',
      fieldProps: {},
    },
    {
      title: '项目经理',
      dataIndex: 'projectManager',
      valueType: 'Select',
      required: '请选择项目经理',
      fieldProps: {
        placeholder: '请选择项目经理',
        options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
    {
      title: '项目金额',
      dataIndex: 'projectAmount',
      valueType: 'InputNumber',
      fieldProps: {
        placeholder: '单位元',
        min: 0,
        stepType: 'inside',
      },
    },
    {
      title: '项目描述',
      dataIndex: 'projectDescribtion',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入项目描述',
      },
    },
    {
      title: '服务类目/清单',
      dataIndex: 'dbServCatgList',
      required: '请填写服务类目/清单',
      valueType: 'ServCategoryList',
      fieldProps: {
        onChange() {
          const { form } = ref.current;
          const data = form.getFieldValue();
          localStorage.setItem('projectCache', JSON.stringify(data));
        },
        pirceChange() {
          proServCatgDiscUnitPirceChange.current = true;
        },
      },
      // component: <CustomerInfo />,
    },
  ];

  const handleCancel = () => {
    if (history.length > 0) {
      history.goBack();
      return;
    }
    history.push('/project/list');
  };

  const getFieldValue = () => {
    const { form } = ref.current;
    const data = form.getFieldValue();
    return data;
  };

  const handleSave = () => {
    let projectId = history.location.query?.projectId;
    const { form } = ref.current;
    form?.validateFields().then((data) => {
      const { dbServCatgList, projectTime, ...values } = data;

      values.proServCatgDiscUnitPirceChange =
        proServCatgDiscUnitPirceChange.current; //项目服务类目优惠后的单价是否发生变更

      if (projectTime) {
        values.projectBeginTime = moment(projectTime[0])
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss');
        values.projectEndTime = moment(projectTime[1])
          .endOf('date')
          .format('YYYY-MM-DD HH:mm:ss');
      }

      if (values.contractId) {
        let contractNumberArr = values.contractId.split('-');
        contractNumberArr.shift();
        values.contractNumber = contractNumberArr.join('-');
        values.contractId = values.contractId.split('-')[0];
      }

      if (dbServCatgList) {
        Object.assign(
          values,
          filterServCatgList(
            JSON.parse(JSON.stringify(dbServCatgList)),
            detailData.current,
          ),
        );
      }

      values.projectCreateUserString = localStorage.getItem('muId');

      let hasEdit = false;
      if (projectId) {
        values.projectId = projectId;
        hasEdit = true;
      }
      if (!hasEdit && contractMoney < values.projectAmount) {
        message.warn('项目金额不能超过合同剩余金额');
        return;
      }
      values.thisRemoveServCatgList = memberListFilter(deleteList);
      values.projectAmount &&
        (values.projectAmount = +(values.projectAmount * 100).toFixed(2));
      dispatch({
        type: 'project/editProject',
        hasEdit,
        payload: values,
      }).then((res) => {
        if (res) {
          localStorage.removeItem('projectCache');
          // handleCancel();
          message.success('保存成功');
          getCurData(false);
        }
      });
    });
  };

  let formLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
  };
  let fixStyle = {
    position: 'fixed',
    right: '40px',
    zIndex: 22,
    top: 150,
  };
  return (
    <div>
      <div className="form_header">
        <h3>{`${history.location.query?.projectId ? '编辑' : '新增'}`}项目</h3>
        <div style={fixStyle}>
          <Button
            type="primary"
            onClick={handleSave}
            loading={loading[`project/editProject`]}
          >
            保存
          </Button>
          <Button onClick={handleCancel}>返回</Button>
        </div>
      </div>
      <ServContext.Provider
        value={{
          setDeleteList,
        }}
      >
        <JsonForm columns={edtaFormItem} ref={ref} {...formLayout} />
      </ServContext.Provider>
    </div>
  );
};

export default connect(({ contract, systemOrgani, loading }) => ({
  contract,
  systemOrgani,
  loading: loading.effects,
}))(AddProject);
