import Jsonform from '@/components/JsonForm';
import { MessageBox, Dropdown, Button, message } from 'kenshin';
import { useEffect, useRef } from 'react';
import { useSelector, history, useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import ChooseIndicators from '../chooseIndicators';
import { useState } from 'react';
import { dirCloumns, itemCloumns } from '../../../indicators/_cloumns';
import ModalFormPro from '@/components/ModalFormPro';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import EditForExcel from '../../editForExcel';
import ChooseHistoryOSA from '../../ChooseHistoryOSA';
import { deepClone, flat, downloadXlsx } from '@/_util/util';
import PreviewIndicators from '../../../components/PreviewIndicators';

const DIR = 1; // 目录
const ITEM = 2; // 指标

const defaultEdshowData = () => ({
  osaIndexItemModel: 1,
  osaIndexItemScoreMethod: 1,
  osaIndexItemActualValueSource: 1,
  osaIndexItemGoalValueSource: 1,
});

export function getAllTreeKeys(tree) {
  if (!Array.isArray(tree)) return [];
  let keys = [];
  return flat(
    tree.map((row) => {
      if (row) {
        if (row.childList) {
          let arr = getAllTreeKeys(row.childList);
          return [...arr, `${row.type}-${row.id}`];
        }
      }
      return `${row.type}-${row.id}`;
    }),
    2,
  );
}

/** 查找节点 */
function findNodeById(key, nodes) {
  for (let node of nodes) {
    // 只找目录
    if (`${node.type}-${node.id}` === key) {
      return node;
    }
    if (node.childList) {
      let result = findNodeById(key, node.childList);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

function setTreeWeight(tree) {
  if (Array.isArray(tree)) {
    tree.forEach((item) => {
      if (Array.isArray(item.childList)) {
        setTreeWeight(item.childList);
        let osaIndexRemainWeight =
          item.childList?.reduce((pre, cur) => pre + cur.weight, 0) ?? 0;
        item.osaIndexRemainWeight = Math.abs(
          item.weight - osaIndexRemainWeight,
        );
      }
    });
  }
  return [];
}
const dispatchType = {
  GETTREE: 'performance/getosatemplateindextree',
  GETDIRTREE: 'performance/getosatemplateindexcatgtree',
  UPDATEDIR: 'performance/updateosatemplateindexcatgdata',
  UPDATEITEM: 'performance/updateosatemplateindexitemdata',
  DELETEDIR: 'performance/deleteosatemplateindexcatgdata',
  DELETEITEM: 'performance/deleteosatemplateindexitemdata',
  ADDTREE: 'performance/addosatempindexdata',
  MOVEDIR: 'performance/moveosatemplateindexcatgdata',
  MOVEITEM: 'performance/moveosatemplateindexitemdata',
  IMPOERSHEET: 'performance/importosatemplateindexdata',
};

const indicators = ({
  dispatchApi = dispatchType,
  // id参数名
  tableIdkey,
  idKey = 'osaTemplateId',
  // 其他id，例如绩效id，默认使用模板id
  idValue,
  disabled,
  osaWeekType,
  showPublicTemplate = true,
} = {}) => {
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);
  const loading = useSelector(({ loading }) => loading.effects);
  const [chooseData, setChooseData] = useState(null);
  const [sheetVisible, setSheetVisible] = useState(false); // 设置excel弹窗状态
  const [historyVisible, setHistoryVisible] = useState(false); // 设置历史绩效弹窗选择状态
  const [chooseVisible, setChooseVisible] = useState(false);

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  /** 模板id */
  const osaTemplateId = idValue ?? history.location.query.osaTemplateId;

  // 目录
  const [editDirVisible, setEditDirVisible] = useState(false);
  // 指标
  const [editItemVisible, setEditItemVisible] = useState(false);
  // 弹窗数据
  const [editModalData, setEditModalData] = useState(null);
  // 剩余权重
  const [maxWeight, setMaxWeight] = useState(100);
  // 设置指标编辑的回显参数
  const [editShowData, setEditShowData] = useState(defaultEdshowData());

  const dirRef = useRef('');
  const itemRef = useRef('');

  const init = () => {
    setEditDirVisible(false);
    setEditItemVisible(false);
    setEditModalData(null);
    setEditShowData(defaultEdshowData());
    setMaxWeight(100);
  };
  const treeSelectClear = () => {
    let totalWeight = performance.osaIndicators.reduce(
      (pre, cur) => pre + cur.weight,
      0,
    );
    setMaxWeight(100 - totalWeight);
  };
  // 目录弹窗配置
  const dirEditCloumn = dirCloumns({
    dirTree: performance.dirTree,
    maxWeight,
    setMaxWeight,
    onClear: treeSelectClear,
    showRemark: !showPublicTemplate,
  });
  // 指标弹窗配置
  const itemEditCloumn = itemCloumns({
    dirTree: performance.dirTree,
    editShowData,
    setEditShowData,
    maxWeight,
    setMaxWeight,
    onClear: treeSelectClear,
    showRemark: !showPublicTemplate,
  });

  useEffect(() => {
    // console.log("dispatchApi.GETTREE===============tableIdkey:",tableIdkey,"idKey:",idKey);
    if (osaTemplateId) {
      getDirTree();
      dispatch({
        type: dispatchApi.GETTREE,
        payload: { [tableIdkey || idKey]: osaTemplateId },
      });
    }
  }, [history.location.query.osaTemplateId, idValue]);

  // 获取目录树
  const getDirTree = () => {
    console.log(
      'dispatchApi.GETDIRTREE===============tableIdkey:',
      tableIdkey,
      'idKey:',
      idKey,
    );
    dispatch({
      type: dispatchApi.GETDIRTREE,
      payload: {
        [tableIdkey || idKey]: osaTemplateId,
      },
    });
  };

  // 点击编辑
  const showEditModal = (row, type, pObj) => {
    if (type == DIR) {
      // if(pId) setEditModalData({osaIndexCatgPantId:pId});
      // console.log("dirRef.current",dirRef.current.form?.setFieldsValue);
      pObj &&
        dirRef.current.form?.setFieldsValue({ osaIndexCatgPantId: pObj.id });
      // 目录
      setEditDirVisible(true);
    } else if (type == ITEM) {
      // if(pId) setEditModalData({osaIndexCatgId:pId});
      pObj && itemRef.current.form?.setFieldsValue({ osaIndexCatgId: pObj.id });
      // 指标
      setEditItemVisible(true);
      row && setEditShowData(row); // 设置展开项
    }
    if (row) {
      // 剩余的权重
      let allSurplusCount =
        100 -
          performance.osaIndicators.reduce((pre, cur) => pre + cur.weight, 0) ||
        0;
      let prant = findNodeById(
        `1-${row.osaIndexCatgPantId || row.osaIndexCatgId}`,
        performance.osaIndicators,
      );
      let parentRemainWeight = prant
        ? prant.osaIndexRemainWeight
        : 0 + allSurplusCount;
      setMaxWeight(parentRemainWeight + row.weight); // 设置展开项
    } else {
      treeSelectClear();
      if (pObj?.id) {
        setMaxWeight(pObj.osaIndexRemainWeight);
      }
    }
    // if(row){
    setEditModalData(row || editShowData);
    // }
  };

  /** 弹窗保存 */
  const handleSave = (payload, saveType) => {
    console.log('handleSave12=========================');
    payload.code = (performance.osaIndicators?.length || 0) + 1;
    payload[idKey] = osaTemplateId;
    const prantId = payload.osaIndexCatgPantId || payload.osaIndexCatgId;
    if (prantId) {
      let dir = findNodeById(`${DIR}-${prantId}`, performance.osaIndicators);
      let index = dir?.childList?.length || 0;
      payload.code = `${dir.code}.${index + 1}`;
    }
    let hasEdit = false;
    if (editModalData?.id) {
      hasEdit = true; // 编辑
      // 旧的上级id
      let oldId =
        editModalData.osaIndexCatgPantId || editModalData.osaIndexCatgId;

      payload.osaIndexRemainWeight =
        payload.weight -
        (editModalData.childList?.reduce((pre, cur) => pre + cur.weight, 0) ||
          0);
      payload = {
        ...editModalData,
        ...payload,
        childList: undefined,
      };
      // if (oldId != prantId && maxWeight < payload.weight) {
      //   message.error('权重不能超过剩余权重！');
      //   return;
      // }
    }
    // if (maxWeight < payload.weight && showPublicTemplate) {
    if (maxWeight < payload.weight) {
      message.error('权重不能超过剩余权重！');
      return;
    }

    let type = dispatchApi.UPDATEDIR;
    if (saveType == ITEM) {
      type = dispatchApi.UPDATEITEM;
      payload.osaIndexRemainWeight = undefined;
    }

    if (saveType == DIR && editModalData?.id && showPublicTemplate) {
      if (
        payload.weight <
        editModalData.weight - editModalData.osaIndexRemainWeight
      ) {
        return message.error('权重不足与分配！');
      }
    }

    dispatch({
      type,
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        init();
        getDirTree();
      }
    });
  };

  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        let type = dispatchApi.DELETEDIR;
        let payload = {
          indexCatgId: row.id,
          [idKey]: osaTemplateId,
        };
        if (row.type != DIR) {
          type = dispatchApi.DELETEITEM;
          payload = {
            indexItemId: row.id,
            [idKey]: osaTemplateId,
          };
        }
        dispatch({
          type,
          payload,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
            getDirTree();
          }
        });
      },
    });
  };

  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      width: '60%',
      render: (row) => {
        return `${row.code} ${
          row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        }`;
        // return (
        //   <EllipsisTooltip
        //     value={`${row.code} ${
        //       row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        //     }`}
        //     width={300}
        //   />
        // );
      },
    },
    // {
    //   dataIndex: 'remark',
    //   title: '指标描述',
    //   width: '30%',
    //   // render: (remark) => {
    //   //   return <EllipsisTooltip value={remark} width={300} />;
    //   // },
    // },
    {
      dataIndex: 'type',
      title: '类型',
      width: '10%',
      render: (val, row) => {
        let type = {
          1: '目录',
          2: '指标',
        };
        let osaIndexItemModelType = {
          1: '定量',
          2: '定性',
        };
        return [type[val], osaIndexItemModelType[row.osaIndexItemModel]]
          .filter(Boolean)
          .join('-');
      },
    },
    {
      dataIndex: 'weight',
      title: '权重',
      width: '10%',
      render: (val) => (val ? `${val}%` : '-'),
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: '10%',
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    !showPublicTemplate
      ? {
          dataIndex: 'remark',
          title: '备注',
          width: '10%',
          render: (val) => (val != undefined ? `${val}` : '-'),
        }
      : {},
    ...(() => {
      if (!disabled) {
        return [
          {
            title: '操作',
            width: '10%',
            key: 'options',
            render: (row) => {
              return (
                <>
                  {row.type == 1 && (
                    <Dropdown
                      menu={dropdownMenu(row)}
                      trigger="click"
                      disabled={disabled}
                    >
                      <Button type="text">新增</Button>
                    </Dropdown>
                  )}
                  {row.type == 1 && (
                    <Button
                      type="text"
                      onClick={() => {
                        setChooseVisible(true);
                        setChooseData(row);
                      }}
                    >
                      模板
                    </Button>
                  )}
                  <Button
                    type="text"
                    onClick={() => showEditModal(row, row.type)}
                  >
                    编辑
                  </Button>
                  <Button
                    type="text"
                    style={{ color: 'red' }}
                    onClick={() => delItem(row)}
                  >
                    删除
                  </Button>
                </>
              );
            },
          },
        ];
      }
      return [];
    })(),
  ];

  // 选择指标之后返回一颗新树
  const chooseSave = (tree) => {
    setTreeWeight(tree);
    let countWeights = tree.reduce((pre, cur) => pre + cur.weight, 0);
    let rootWeight =
      performance.osaIndicators?.reduce((pre, cur) => pre + cur.weight, 0) ?? 0;
    let rootRemainWeigh = Math.abs(100 - rootWeight);

    if (showPublicTemplate) {
      //是否判断权重
      if (chooseData) {
        if (countWeights > chooseData?.osaIndexRemainWeight) {
          message.error(
            `当前选择的指标权重超过剩余权重：${chooseData.osaIndexRemainWeight}%!`,
          );
          return;
        }
      } else if (countWeights > rootRemainWeigh) {
        message.error(`当前选择的指标权重超过剩余权重：${rootRemainWeigh}%!`);
        return;
      }
    }

    dispatch({
      type: dispatchApi.ADDTREE,
      payload: {
        indexDataJson: JSON.stringify(tree),
        [idKey]: osaTemplateId,
        indexPantId: chooseData?.id,
      },
    }).then((res) => {
      if (res) {
        setChooseVisible(false);
        setChooseData(null);
        getDirTree();
      }
    });
  };

  const onDrop = (startId, endId, sortType) => {
    if (disabled) {
      return;
    }
    if (startId == endId) {
      return;
    }
    let start = findNodeById(startId, performance.osaIndicators); // 拖拽项
    let end = findNodeById(endId, performance.osaIndicators); // 目标
    if (start && end) {
      let code = end.code; // 目标编码
      let sort = end.sort; // 目标排序
      let osaIndexCatgId = end.osaIndexCatgId ?? end.osaIndexCatgPantId; // 目标上级目录id
      let startCatgId = start.osaIndexCatgId ?? start.osaIndexCatgPantId; // 目标上级目录id

      let startCodeArr = start.code.split('.');
      let endCodeArr = code.split('.');
      // 处理跨级，拖拽code小于目标code的情况
      if (startCodeArr.length < endCodeArr.length) {
        for (let i = 0; i < startCodeArr.length; i++) {
          if (+startCodeArr[i] < +endCodeArr[i]) {
            +endCodeArr[i]--;
          }
        }
        code = endCodeArr.join('.');
      }
      // 目标上级目录
      let parent = findNodeById(
        `${DIR}-${osaIndexCatgId}`,
        performance.osaIndicators,
      );
      // 假如移入的目录大于目标上级目录的剩余权重，不通过
      if (
        osaIndexCatgId != startCatgId &&
        parent?.osaIndexRemainWeight < start.weight
      ) {
        message.error('权重超过剩余权重！');
        return;
      }

      let type = dispatchApi.MOVEDIR;
      let payload = {
        ...start,
        code,
        sort,
        osaIndexCatgPantId: osaIndexCatgId,
        childList: null,
      };

      if (start.type == ITEM) {
        // 假如拖拽的是指标
        type = dispatchApi.MOVEITEM;
        payload = {
          ...start,
          code,
          sort,
          osaIndexCatgId,
        };
      }

      // 单独处理osa考核的数据
      if (idKey == 'osaId') {
        payload.osaId = idValue;
      }
      if (idKey == 'osaUserId') {
        payload.osaUserId = idValue;
      }
      dispatch({
        type,
        payload,
      }).then((res) => {
        if (res) {
          getDirTree();
        }
      });
    }
  };

  // 下拉菜单
  const dropdownMenu = (row) => (
    <Dropdown.Menu>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => showEditModal(null, DIR, row)}
      >
        新增目录
      </Dropdown.Item>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => showEditModal(null, ITEM, row)}
      >
        新增指标
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const extra = () => (
    <>
      <Dropdown menu={dropdownMenu()} trigger="click" disabled={disabled}>
        <Button type="primary">新增</Button>
      </Dropdown>
      <Button
        style={{ marginLeft: 8 }}
        disabled={disabled}
        onClick={() => {
          setChooseVisible(true);
          setChooseData(null);
        }}
      >
        添加指标模板
      </Button>
      <Button
        onClick={() => {
          setExpandedRowKeys(
            expandedRowKeys?.length == 0
              ? getAllTreeKeys(performance.osaIndicators)
              : [],
          );
        }}
      >
        {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
      </Button>
      <Button
        style={{ marginLeft: 8 }}
        type="success"
        disabled={disabled}
        onClick={() => {
          setSheetVisible(true);
          setChooseData(null);
        }}
      >
        Excel编辑
      </Button>
      {dispatchApi.COPYHISTORY && (
        <Button
          style={{ marginLeft: 8 }}
          type="success"
          disabled={disabled}
          onClick={() => {
            setHistoryVisible(true);
            setChooseData(null);
          }}
        >
          使用历史绩效
        </Button>
      )}
      {/* <Button
        style={{ marginLeft: 8 }}
        disabled={disabled}
        onClick={() => {
          dispatch({
            type: 'performance/getosauserindextree',
          });
        }}
      >
        查询
      </Button> */}
    </>
  );

  const SheetSave = (value) => {
    dispatch({
      type: dispatchApi.IMPOERSHEET,
      payload: {
        [idKey == 'osaUserId' ? 'osaId' : idKey]: osaTemplateId,
        indexDataJson: JSON.stringify(value),
      },
    }).then(async (res) => {
      if (res) {
        setSheetVisible(false);
        getDirTree();
        // await dispatch({
        //   type: 'performance/getosauserindextree',
        // });
        // await dispatch({
        //   type: 'performance/getosauserindextree',
        // });
      }
    });
  };

  const historySave = (historyOsaId) => {
    dispatch({
      type: dispatchApi.COPYHISTORY,
      payload: {
        historyOsaId,
        curnOsaId: idValue,
      },
    }).then((res) => {
      if (res) {
        getDirTree();
        setHistoryVisible(false);
        setChooseData(null);
      }
    });
  };

  const curData = performance.curOsaSettingData.rows ?? {};
  const disabledExtra = () => {
    if (curData) {
      return (
        <>
          <PreviewIndicators row={curData} type="primary" />
          <Button
            onClick={() => {
              setExpandedRowKeys(
                expandedRowKeys?.length == 0
                  ? getAllTreeKeys(performance.osaIndicators)
                  : [],
              );
            }}
          >
            {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <TablePro
        // params={{ osaTemplateId: history.location.query.osaTemplateId }}
        // request="performance/getosatemplateindextree"
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        extra={!disabled ? extra : disabledExtra}
        curColumns
        columnsOptions={false}
        drag={!disabled}
        loading={loading[dispatchApi.GETTREE]}
        onDrop={onDrop}
        childrenColumnName="childList"
        dataSource={performance.osaIndicators}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
        scrollY={700}
      />
      <ChooseIndicators
        showPublicTemplate={showPublicTemplate}
        visible={chooseVisible}
        onSave={chooseSave}
        baseCode={performance.osaIndicators?.length}
        onCancel={() => {
          setChooseVisible(false);
          setChooseData(null);
        }}
      />
      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editDirVisible}
        edtaFormItem={dirEditCloumn}
        formData={editModalData}
        onSave={(val) => handleSave(val, DIR)}
        ref={dirRef}
        onCancel={init}
      />
      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editItemVisible}
        edtaFormItem={itemEditCloumn}
        formData={editModalData}
        ref={itemRef}
        onSave={(val) => handleSave(val, ITEM)}
        onCancel={init}
      />
      <EditForExcel
        isCalculateWeight={showPublicTemplate}
        visible={sheetVisible}
        loading={loading[dispatchApi.IMPOERSHEET]}
        onCancel={() => {
          setSheetVisible(false);
          setChooseData(null);
        }}
        dataSource={performance.osaIndicators}
        onSave={SheetSave}
      />
      <ChooseHistoryOSA
        osaWeekType={osaWeekType}
        visible={historyVisible}
        disabledId={idValue}
        onCancel={() => {
          setHistoryVisible(false);
          setChooseData(null);
        }}
        onSave={historySave}
      />
    </>
  );
};

export default indicators;
