import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Input, MessageBox, message, Row } from 'kenshin';
import { connect, history, useActivate } from 'umi';
import TablePro from '@/components/TablePro';
import busiTypeKey, { attendanceKey } from '../../../process/_util/busiTypeKey';
import CollapseSearch from '../components/CollapseSearch';
import RecordModal from '../_pendingBusiness/components/RecordModal';
const { OSA, WEEK, OSAEDIT, WEEKEDIT } = busiTypeKey;

const muid = localStorage.getItem('muId');

const showReturnBtn = (row) => {
  let hideKey = [OSAEDIT, WEEKEDIT];
  let osaOrWeek = [OSA, WEEK];
  // if (hideKey.includes(row.todoItemBusiType)) return false; // 没有撤回操作的
  if (osaOrWeek.includes(row.todoItemBusiType)) {
    // 绩效相关的撤回
    let personnelNode = [
      'AUDIT_OSA_FOUR_NODE',
      'HR_CONFIRM_NODE',
      'AUDIT_WEEK_PLAN_THREE_NODE',
      'HR_CONFIRM_WEEK_PLAN_NODE',
    ]; // 人事入库的需要特殊处理
    if (personnelNode.includes(row.processApplyNodeNumber))
      return row.isNextNodeDeal == 0 && row.processAuditStatus != 1;
    return row.isNextNodeDeal == 0;
    // row.processApplyNodeNumber
  }
  // 其他撤回
  return row.isNextNodeDeal == 0 && row.dataChange?.dataChangeStatus != 4;
};

const ProcessList = ({ usercenter, dispatch, process }) => {
  const tableRef = useRef();
  const curData = useRef();
  const [recordVisible, setRecordVisible] = useState(false);

  useActivate(() => {
    dispatch({
      type: 'usercenter/getcompleteitemnewpage',
    });
  });

  /** 详细 */
  const diffDetailClick = (row) => {
    let toOsaChangKeys = [OSAEDIT, WEEKEDIT];
    if (toOsaChangKeys.includes(row.todoItemBusiType)) {
      return history.push({
        pathname: '/performance/selfosa/changosa',
        query: {
          processApplyId: row.todoItemBusiId,
        },
      });
    }
    dispatch({
      type: 'usercenter/save',
      payload: {
        createProcessData: row,
      },
    });
    history.push('/process/businessLog/ChangeRecord');
  };

  const gethasdoSearchNum = (payload = {}) => {
    dispatch({
      type: 'usercenter/completeitemstat',
      payload,
    });
  };

  /** 撤销点击 */
  const handleUndo = (row) => {
    let osaBusi = [OSA, WEEK, OSAEDIT, WEEKEDIT];
    let payload = { completeItemId: row.completeItemId };
    let type = 'process/completerevoke';
    if (osaBusi.includes(row.todoItemBusiType)) {
      type = 'performance/osaweekplanrevoke';
    }
    if (Object.values(attendanceKey).includes(row.todoItemBusiType)) {
      type = 'attendance/auditattendancerevoke';
    }
    MessageBox.confirm({
      title: '确认操作',
      content: '确认撤销？撤销后无法恢复',
      onOk: () => {
        dispatch({
          type,
          payload,
        }).then((res) => {
          if (res) {
            message.success('撤销成功');
          }
        });
      },
    });
  };
  // 表头数据
  const columnsObj = {
    columns: [
      {
        dataIndex: 'todoItemName',
        title: '业务名称',
      },
      {
        dataIndex: 'todoItemBusiType',
        title: '业务类型',
        render: (val) => process.processTypeStrObj[val],
      },
      {
        dataIndex: 'gmtCreate',
        title: '提交时间',
        render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        dataIndex: 'commitMuname',
        title: '提交人',
      },
      {
        dataIndex: 'curnProcessNodeName',
        title: '当前流程',
        render(text, row) {
          return (
            <Button
              type="text"
              onClick={() => {
                curData.current = row;
                setRecordVisible(true);
              }}
            >
              {text}
            </Button>
          );
        },
      },
      {
        key: 'options',
        title: '操作',
        render: (row) => {
          return (
            <>
              <Button type="text" onClick={() => diffDetailClick(row)}>
                详情
              </Button>
              {showReturnBtn(row) && (
                <Button
                  style={{ color: 'red' }}
                  type="text"
                  onClick={() => handleUndo(row)}
                >
                  撤销
                </Button>
              )}
            </>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '关键词',
      },
    },
    {
      name: 'busiType',
      type: 'Select',
      props: {
        placeholder: '业务类型',
        options: process.processTypeOptions,
      },
    },
  ];

  /** 表格拦截 */
  const onSubmit = (payload) => {};

  return (
    <div>
      <CollapseSearch
        onChange={tableRef.current?.reload}
        searchChange={gethasdoSearchNum}
        data={usercenter?.hasdoSearchNum || {}}
      />
      <TablePro
        request="usercenter/getcompleteitemnewpage"
        // searchCloumns={searchCloumns}
        rowKey={'todoItemId'}
        data={usercenter.hasdoPageList}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={onSubmit}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        curColumns
        {...columnsObj}
      ></TablePro>
      <RecordModal
        visible={recordVisible}
        row={curData.current}
        processApplyId={curData.current?.dataChange?.processApplyId}
        onCancel={() => {
          setRecordVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ usercenter, process }) => ({
  usercenter,
  process,
}))(ProcessList);
