import request, { mock } from '@/_util/require';
let version = 'v1';

// ==================================================================考核指标==================================================================

/** 新增绩效模板指标数据 */
export async function addosatempindexdata(options) {
  return request(`/${version}/waper/erp/pc/osa/template/addosatempindexdata`, {
    method: 'POST',
    data: options,
  });
}
/** 新增绩效模板指标目录数据 */
export async function addosatemplateindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/addosatemplateindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 新增绩效模板指标条目数据 */
export async function addosatemplateindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/addosatemplateindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 删除绩效模板指标目录数据 */
export async function deleteosatemplateindexcatgdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/template/deleteosatemplateindexcatgdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 删除绩效模板指标条目数据 */
export async function deleteosatemplateindexitemdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/template/deleteosatemplateindexitemdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询绩效模板的指标目录树结构 */
export async function getosatemplateindexcatgtree(params) {
  return request(
    `/${version}/waper/erp/pc/osa/template/getosatemplateindexcatgtree`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询绩效模板的指标树结构 */
export async function getosatemplateindextree(params) {
  return request(
    `/${version}/waper/erp/pc/osa/template/getosatemplateindextree`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询绩效模板列表 */
export async function getosatemplatelist(options) {
  return request(`/${version}/waper/erp/pc/osa/template/getosatemplatelist`, {
    method: 'POST',
    data: options,
  });
}
/** 查询公共的绩效模板列表 */
export async function getcommonosatemplatelist(params) {
  return request(
    `/${version}/waper/erp/pc/osa/template/getcommonosatemplatelist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 删除绩效模板数据 */
export async function deleteosatemplate(params) {
  return request(`/${version}/waper/erp/pc/osa/template/deleteosatemplate`, {
    method: 'GET',
    params,
  });
}

/** 初始化绩效模板数据 */
export async function initosatemplatedata(options) {
  return request(`/${version}/waper/erp/pc/osa/template/initosatemplatedata`, {
    method: 'POST',
    data: options,
  });
}
/** 移动绩效模板指标目录数据 */
export async function moveosatemplateindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/moveosatemplateindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 移动绩效模板指标条目数据 */
export async function moveosatemplateindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/moveosatemplateindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改绩效模板数据 */
export async function updateosatemplatedata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/updateosatemplatedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改绩效模板指标目录数据 */
export async function updateosatemplateindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/updateosatemplateindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改绩效模板指标条目数据 */
export async function updateosatemplateindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/updateosatemplateindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 导入绩效考核模板指标数据 */
export async function importosatemplateindexdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/template/importosatemplateindexdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// ==================================================================考核指标end==================================================================
