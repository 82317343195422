import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, message, MessageBox, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { deepClone } from '@/_util/util';

const menuConfig = ({ dispatch, system }) => {
  let modalRef = useRef();
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const getTagAllList = () => {
    dispatch({
      type: 'system/getTagAll',
    });
  };
  useEffect(getTagAllList, []);

  const showEditModal = (row) => {
    const data = row ? deepClone(row) : {};
    data.moduleList = data.moduleList?.map((item) => item.moduleFlag);
    setEditModalVisible(true);
    setEditModalData(data);
  };

  const delTag = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'system/deleteTag',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const columns = [
    {
      dataIndex: 'tagName',
      title: '标签名称',
    },
    {
      dataIndex: 'tagFlag',
      title: '标签唯一标识',
    },
    {
      dataIndex: 'moduleList',
      title: '模块列表',
      render: (val) =>
        val?.map((item) => (
          <div key={item.moduleFlag} style={{ marginTop: 4 }}>
            {/* {item.moduleName}：{item.moduleFlag} */}
            <Tag type="primary">{item.moduleName}</Tag>
          </div>
        )),
    },
    {
      dataIndex: 'mark',
      title: '备注',
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => showEditModal(row)}>
              编辑
            </Button>
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delTag({ tagId: row.tagId })}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'tagName',
      type: 'Input',
      props: {
        placeholder: '标签名称',
      },
    },
    {
      name: 'moduleList',
      type: 'Select',
      props: {
        placeholder: '请选择模块',
        mode: 'multiple',
        maxTagCount: 'responsive',
        options: system.tagAllList.map((item) => ({
          value: item.moduleFlag,
          label: item.moduleName,
        })),
      },
    },
  ];

  const extra = () => (
    <Button type="primary" onClick={() => showEditModal()}>
      新增标签
    </Button>
  );

  const editModalSave = (value) => {
    let hasEdit = true;
    value.moduleList = value.moduleList.map((item) =>
      system.tagAllList.find((obj) => obj.moduleFlag == item),
    );
    let params = {
      ...editModalData,
      ...value,
    };
    if (!editModalData?.tagId) {
      hasEdit = false;
      // 新增
      params = value;
    }
    dispatch({
      type: 'system/editTag',
      hasEdit,
      payload: params,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const edtaFormItem = [
    {
      name: 'moduleList',
      label: '模块列表',
      type: 'Select',
      required: '请选择模块',
      props: {
        placeholder: '请选择模块',
        // labelInValue: true,
        mode: 'multiple',
        options: system.tagAllList.map((item) => ({
          value: item.moduleFlag,
          label: item.moduleName,
        })),
      },
    },
    {
      name: 'tagFlag',
      label: '标签唯一标识',
      type: 'Input',
      required: '请输入标签唯一标识',
      props: {
        placeholder: '请输入标签唯一标识',
      },
    },
    {
      name: 'tagName',
      label: '标签名称',
      type: 'Input',
      required: '请输入标签名称',
      props: {
        placeholder: '请输入标签名称',
      },
    },
    {
      name: 'mark',
      label: '备注',
      type: 'TextArea',
      props: {
        placeholder: '请输入备注',
      },
    },
  ];

  return (
    <div>
      <TablePro
        request="system/getTagList"
        version="system"
        rowKey={'tagId'}
        columns={columns}
        curColumns
        searchCloumns={searchCloumns}
        dataSource={system.tagList.datas}
        data={system.tagList}
        extra={extra}
      />
      <ModalFormPro
        title={editModalData?.tagId ? '编辑' : '新增'}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        ref={modalRef}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ system }) => ({ system }))(menuConfig);
