import { Button, Typography, message, Timeline } from 'kenshin';
import CollapseCard, { Template } from '../../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../../_keys';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import AutoSend from '../../../components/AutoSend';
import { CloseCircleFilled } from '@ant-design/icons';

import {
  nextNodeIsMine,
  AUDITTYPE,
  targetTYPE,
  defaliltOsaYearQuarter,
  osaBeginTime,
  osaEndTime,
  isShowConfirmBtn,
  hasShowReturnBtn,
  dispatchApi,
  isDisabled,
  getQuarter,
  indicatorsValidate,
  getStartAEnd,
  floatIndicators,
} from './_unitl.js';

// 防抖标识
let timer = null;

const createTemplate = ({ performance, dispatch, loadingEf }) => {
  const initPayload = {
    osaBeginTime,
    osaEndTime,
    osaType: OSATYPEKEY.OSA,
    ...history.location.query,
    ...getStartAEnd(history.location.query?.osaYearQuarter),
    osaId: undefined,
    target: undefined,
  };
  // 当前页面数据
  let curData = performance.curOsaSettingData.rows ?? {};

  let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;

  // console.log("performance = ",performance)
  // console.log("curData = ",curData)

  let osaYearAndQuarter =
    history.location.query.osaYearQuarter || defaliltOsaYearQuarter;
  let disabled = isDisabled(curData);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [resultList, setResultList] = useState([]);
  const auditResult = useRef(AUDITTYPE.SUCCESS);

  useEffect(() => {
    init();
    dispatch({
      type: 'performance/getosaauditsystemdescribelist',
    }).then((list) => {
      // console.log(res);
      if (list) {
        setResultList(
          list.map((item) => ({ label: item.value, value: item.id })),
        );
      }
    });
    return () => {
      dispatch({
        type: 'performance/save',
        payload: {
          curOsaSettingData: {
            rows: {},
          },
        },
      });
    };
  }, [history.location.query.busiId]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  const init = async () => {
    if (history.location.query.busiId) {
      curData = await dispatch({
        type: 'performance/getosaemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                },
              },
            },
          });
          return res;
        }
        return {};
      });
    } else if (history.location.query.target != targetTYPE.edit) {
      curData = await getEditData();
    }
    if (curData?.osaId) {
      dispatch({
        type: 'performance/getosaemployeeauditscoredata',
        payload: {
          osaId: curData.osaId,
        },
      });
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId: curData.osaId,
          todoBusiId: '',
        },
      });
    }
  };

  const getEditData = async () => {
    if (osaYearAndQuarter) {
      let Y = osaYearAndQuarter.slice(0, 4); // 年
      let Q = osaYearAndQuarter.slice(4);
      const dateTime = moment().set({ year: Y, quarter: Q });
      initPayload.osaYear = Y;
      initPayload.osaYearQuarter = Q;
      initPayload.osaBeginTime = dateTime
        .startOf('quarter')
        .format('YYYY-MM-DD HH:mm:ss');
      initPayload.osaEndTime = dateTime
        .endOf('quarter')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    return await dispatch({
      type: 'performance/initosaemployeedata',
      payload: initPayload,
    }).then((res) => {
      if (res) {
        let osaName =
          res.osaName ||
          `${res.userInfo?.value}${initPayload.osaYear}年Q${initPayload.osaYearQuarter}季度绩效评估表`;

        dispatch({
          type: 'performance/save',
          payload: {
            curOsaSettingData: {
              ...performance.curOsaSettingData,
              rows: {
                ...res,
                title: curData.title,
                osaName,
                osaYearQuarter: initPayload.osaYearQuarter,
              },
            },
          },
        });
        handleSave({
          ...res,
          osaName,
          osaYearQuarter: initPayload.osaYearQuarter,
        });
        return {
          ...res,
          title: curData.title,
          osaName,
          osaYearQuarter: initPayload.osaYearQuarter,
        };
      }
    });
  };

  const osaTypeOptions = [
    { label: '试用期转正评估', value: 1 },
    { label: '绩效评估', value: 2 },
    { label: '绩效改进评估', value: 3 },
    { label: '个人发展计划', value: 4 },
  ];

  const osaTypeChange = (e) => {
    let value = e.target.value;
    let label = osaTypeOptions.find((item) => item.value == value)?.label;
    let osaName = `${curData.userInfo?.value}${curData.osaYear}年Q${curData.osaYearQuarter}季度${label}表`;
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: {
            ...curData,
            osaType: value,
            osaName,
          },
        },
      },
    });

    console.log(osaName);
  };

  // 基本信息的附加项
  const infoParams = {
    title: '考核类型',
    dataIndex: 'osaType',
    valueType: 'RadioGroup',
    fieldProps: {
      disabled,
      onChange: osaTypeChange,
      options: osaTypeOptions,
    },
  };

  const handleSave = (value) => {
    console.log('value', value);
    if (value.score) {
      value[scoreKey[curData.curnNodeNumber]] = value.score;
      value.score = undefined;
    }
    if (value.remark) {
      value[scoreRemarkKey[curData.curnNodeNumber]] = value.remark;
      value.remark = undefined;
    }
    // 保存dva当前的数据
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: {
            ...curData,
            ...value,
          },
        },
      },
    });
    // 后端保存数据
    dispatch({
      type: 'performance/updateosaemployeedata',
      payload: {
        ...curData,
        ...value,
        title: undefined,
        key: undefined,
        muid: undefined,
        pos: undefined,
        selected: undefined,
      },
    });
  };

  const handleNotice = (value) => {
    if (value.osaTime) {
      const [start, end] = value.osaTime;
      value.osaYearQuarter = `${start.quarter()}`;
      value.osaBeginTime = start.format('YYYY-MM-DD HH:mm:ss');
      value.osaEndTime = end.format('YYYY-MM-DD HH:mm:ss');
      value.osaTime = undefined;
    }
    let templateEditor = {
      ...curData,
      ...value,
    };
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: templateEditor,
        },
      },
    });
    // 防抖处理
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      const {
        osaBeginTime,
        osaEndTime,
        osaId,
        osaMuId,
        osaNoticeDaysBeforeAfter,
        osaYearQuarter,
      } = templateEditor;

      let payload = {
        osaBeginTime,
        osaEndTime,
        osaId,
        osaMuId,
        osaNoticeDaysBeforeAfter,
        osaYearQuarter,
      };
      dispatch({
        type: 'performance/updateosaemployeenoticedata',
        payload,
      });
    }, 1500);
  };

  const baseCloumns = [
    {
      title: '考核周期',
      valueType: 'RadioGroup',
      fieldProps: {
        disabled: true,
        value: curData.osaWeekType,
        options: [
          {
            label: '周',
            value: 1,
          },
          {
            label: '季度',
            value: 3,
          },
        ],
      },
    },
    {
      title: '考核有效期',
      valueType: 'DatePickerRangePicker',
      // dataIndex: 'osaTime',
      fieldProps: {
        disabled,
        onChange: (val) => handleNotice({ osaTime: val }),
        value: [moment(curData.osaBeginTime), moment(curData.osaEndTime)],
        placeholder: ['开始时间', '结束时间'],
        ranges: {
          上个季度: getQuarter(curData.osaBeginTime, -1),
          下个季度: getQuarter(curData.osaBeginTime, 1),
        },
      },
    },
    // {
    //   title: '自动分发时间',
    //   valueType: 'InputNumber',
    //   render() {
    //     return (
    //       <AutoSend
    //         disabled={disabled}
    //         onChange={(val) => handleNotice({ osaNoticeDaysBeforeAfter: val })}
    //         value={curData.osaNoticeDaysBeforeAfter}
    //       />
    //     );
    //   },
    //   // fieldProps: {
    //   //   disabled,
    //   //   onChange: (val) => handleNotice({ osaNoticeDaysBeforeAfter: val }),
    //   //   value: curData.osaNoticeDaysBeforeAfter,
    //   //   placeholder: '自动分发时间',
    //   //   stepType: 'inside',
    //   // },
    // },
    {
      title: '考核模式',
      valueType: 'Select',
      extra:
        '在既定时间内完成目标，且有多付出、多贡献、有特别亮点的项目则视情况给予附加分1-20分',
      fieldProps: {
        value: curData.osaScoreRule,
        placeholder: '请选择考核模式',
        disabled,
        options: [
          {
            label: '满分100分制（可向上浮动20%）',
            value: 1,
          },
        ],
      },
    },
  ];

  // 评分人设置保存
  const graderChange = (val) => {
    let payload = val.osaScoreList;
    let templateEditor = {
      ...curData,
      ...val,
    };
    dispatch({
      type: 'performance/save',
      payload: {
        templateEditor,
      },
    });
    dispatch({
      type: 'performance/updateosaemployeescore',
      payload,
    });
  };

  /** 确认绩效按钮文案 */
  const toNextNodeBtn = () => {
    let textObj = {
      CREATE_OSA_NODE: '创建绩效',
      CONFIRM_OSA_NODE: '确认绩效',
      AUDIT_OSA_ONE_NODE: '审批通过',
      AUDIT_OSA_TWO_NODE: '审批通过',
      AUDIT_OSA_THREE_NODE: '审批通过',
      AUDIT_OSA_FOUR_NODE: '审批通过',
    };
    return textObj[curData.curnNodeNumber] || '创建绩效';
  };

  const hasEq100 = () => {
    const countScore = performance.osaIndicators.reduce(
      (pre, cur) => cur.weight + pre,
      0,
    );
    if (countScore != 100) {
      message.error(
        `指标总权重超过100或者少于100，当前指标总分数：${countScore}`,
      );
      return false;
    }
    let filterItem = floatIndicators(performance.osaIndicators).filter(
      (item) => item.osaIndexRemainWeight,
    );
    if (filterItem.length > 0) {
      let name = filterItem.map((item) => item.osaIndexCatgName).join();
      message.error(`${name}指标剩余权重未分配完成`);
      return false;
    }
    return true;
  };

  const edtaFormItem = [
    auditResult.current == AUDITTYPE.ERROR
      ? {
          label: '原因',
          name: 'auditRemarkList',
          type: 'Select',
          props: {
            mode: 'tags',
            options: resultList,
            placeholder: '请输入原因，按enter确定',
          },
        }
      : null,
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
  ].filter(Boolean);

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const editModalSave = async (value) => {
    console.log(value);

    /** 通过/拒绝原因 */
    let auditRemarkList = [
      ...(value.auditRemarkList ?? []),
      value.auditRemark,
    ]?.map((txt) => {
      let SUCCESSID = 88888888;
      let ERRORID = 99999999;
      let auditRmkDescribe =
        resultList.find((item) => item.value == txt)?.label || txt;
      let auditRmkDescribeId =
        resultList.find((item) => item.value == txt)?.value ??
        (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
      let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
      return {
        auditRmkDescribe,
        auditRmkDescribeId,
        auditRmkType,
      };
    });
    let payload = {
      ...value,
      auditRemarkList,
      osaId: curData.osaId,
      auditResult: auditResult.current,
    };
    let promiseIndex = 0;
    let hasSuccess = true;
    nextNodeIsMine(curData, curData.curnNodeNumber, (node) => promiseIndex++);

    for (let i = 0; i < promiseIndex; i++) {
      await dispatch({
        type: 'performance/auditosaprocess',
        payload: {
          ...payload,
          isJumpNextNode: i != promiseIndex - 1,
        },
      }).then((res) => {
        if (!res) {
          hasSuccess = false;
        }
      });
    }

    if (hasSuccess) {
      setEditModalVisible(false);
      clearCache();
      if (
        curData.curnNodeNumber == 'CREATE_OSA_NODE' &&
        curData.osaCreatorId == curData.osaMuId
      ) {
        init();
        return;
      }

      if (backNodeId != null) {
        backNodeId == null;
        window.location.reload();
      } else if (!history.goBack()) {
        history.replace('/usercenter/business');
      }
    }
  };

  let list =
    performance.osaProcessCache[curData.osaId]?.osaProcessDataList ?? [];
  // let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[curData.osaId]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (list[index].processNodeId === backNodeId)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  const HeaderButton = (
    <div className="fixed-right">
      {isShowConfirmBtn(performance.curOsaSettingData.rows) && (
        <Button
          type="primary"
          onClick={() => {
            if (!indicatorsValidate(performance.osaIndicators)) {
              message.error('指标目录应含有指标');
              return;
            }
            if (!hasEq100()) return;

            console.log(1111, backNodeId);
            if (curData.osaId != null && backNodeId != null) {
              editModalSave('');
            } else {
              setEditModalVisible(true);
            }

            auditResult.current = AUDITTYPE.SUCCESS;
          }}
        >
          {toNextNodeBtn()}
        </Button>
      )}
      {hasShowReturnBtn(performance.curOsaSettingData.rows) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      <Button type="primary" onClick={() => goback()}>
        返回
      </Button>
    </div>
  );

  return (
    <div className="create-template-page">
      {!history.location.query.osaId && HeaderButton}
      <Typography.Title level={4}>OSA考核设置</Typography.Title>
      <CollapseCard
        title="基本设置"
        cloumns={Template.INFO.default({
          item: infoParams,
          disabled,
          target: performance.curOsaSettingData.rows,
        })}
        defaultValue={curData}
        onSave={handleSave}
      />
      {curData.osaId && (
        <CollapseCard title="常规指标">
          <Template.INDICATORS.default
            dispatchApi={dispatchApi}
            idKey="osaId"
            osaWeekType={3}
            disabled={
              disabled ||
              curData.osaMuId !=
                JSON.parse(localStorage.getItem('employeeDTO'))?.empId
            } //不是自己的绩效，不能修改
            idValue={curData.osaId}
            showPublicTemplate={true}
          />
        </CollapseCard>
      )}

      {curData.osaWeekType == 3 && (
        <CollapseCard title={<>月度策略</>}>
          <Template.MONTHSTRATEGY.default
            // dispatchApi={dispatchApi}
            idKey="osaId"
            osaWeekType={3}
            disabled={
              disabled ||
              curData.osaMuId !=
                JSON.parse(localStorage.getItem('employeeDTO'))?.empId
            } //不是自己的绩效，不能修改
            idValue={curData.osaId}
            // showPublicTemplate={true}
          />
        </CollapseCard>
      )}

      <CollapseCard
        title={
          <>
            流程图
            <span style={{ color: '#999', fontSize: 12 }}>
              (查看目前处于哪个环节)
            </span>
          </>
        }
      >
        <Timeline>
          {list.map((item, index) => (
            <Timeline.Item
              key={index}
              content={item.processDescribe}
              // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
              {...dots(index)}
            />
          ))}
        </Timeline>
      </CollapseCard>
      <CollapseCard
        title="方案基本设置"
        onSave={handleNotice}
        cloumns={baseCloumns}
        key={performance.curOsaSettingData.rows}
        disabled={disabled}
        defaultValue={curData}
      />
      {/* <CollapseCard title="评分人设置">
        <Template.GRADER.default
          onSave={graderChange}
          disabled={disabled}
          defaultValue={curData.osaScoreList}
        />
      </CollapseCard> */}
      {/* <Template.OFFTIME.default
        onSave={handleSave}
        disabled={disabled}
        defaultValue={curData.osaScoreEndDaysBeforeAfter}
      /> */}
      <CollapseCard title="审核流程设置">
        <Template.PROCESS.default
          disabled={disabled}
          defaultValue={curData.osaAuditList}
        />
      </CollapseCard>
      {/* <Template.VALIDITY.default defaultValue={performance.templateEditor} /> */}

      <ModalFormPro
        title="确认绩效"
        width={600}
        btnLoading={loadingEf['performance/auditosaprocess']}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(createTemplate);
