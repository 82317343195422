import { Row, Col, message, Typography } from 'kenshin';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import ItemDetail from './itemDetail';
import ModalFormPro from '@/components/ModalFormPro';
import './index.less';
import { ServContext } from '../../index';
import { debounce, deepClone } from '@/_util';
import SortBox from './SortBox';
import {
  findTree,
  dataListFind,
  getPrice,
} from '../../../../../../components/ServCategoryList';

const ServCategoryList = ({
  systemOrgani,
  dispatch,
  project,
  value,
  onChange,
  // 关闭编辑等按钮
  disabled,
  // 顶部扩展，放在按钮后面
  headerExtra,
  extra,
  pirceChange,
}) => {
  const modalRef = useRef();
  // const { setDeleteList } = useContext(ServContext);
  const ServContextData = useContext(ServContext);
  const setDeleteList = ServContextData?.setDeleteList;
  const [dataList, setDataList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  useEffect(() => {
    if (
      Array.isArray(value) &&
      systemOrgani.employeeAll &&
      dataList.length == 0
    ) {
      setDataList(JSON.parse(JSON.stringify(value)));
    }
  }, [value, systemOrgani.employeeAll]);

  const addItem = () => {
    setEditModalVisible(true);
    const { form } = modalRef.current;
    form.setFieldsValue({ servCatgHourOrginCost: 1800 });
  };

  // 服务类目编辑或新增完成
  const editChange = (val) => {
    // if (!val.addTeamMemberList?.includes(val.servCatgLeader)) {
    //   message.warn('参与团队必须存在对应责任人!');
    //   return;
    // }
    // 树结构的新增
    if (editModalData?.$childrenAdd) {
      editModalData.childList = editModalData.childList || [];
      // let curData = dataList.find((item) => item.key == editModalData.key);
      let curData = deepClone(dataListFind(dataList, editModalData.key));
      let data = {
        key: +new Date(),
        servCatgCode: `${curData.servCatgCode}.${
          (curData.childList?.length || 0) + 1
        }`,
        ...val,
      };

      curData.childList = curData.childList || [];
      curData.childList.push(data);
      setEditModalVisible(false);
      setEditModalData(null);

      setDataList([...dataList]);
      onChange?.(dataList);
      return;
    }

    // 编辑
    if (editModalData?.key) {
      let curData = dataListFind(dataList, editModalData.key);
      Object.assign(curData, { ...val });
      setDataList([...dataList]);
      onChange?.([...dataList]);
      if (
        editModalData &&
        curData.servCatgCount < editModalData?.servCatgFinishNum
      ) {
        curData.servCatgCount = editModalData.servCatgCount;
        message.warning('数量不能小于已关闭的的数量！');
        return;
      }

      setEditModalVisible(false);
      setEditModalData(null);
      return;
    }

    // 单纯的新增,不挂载任何父节点（最顶层节点）
    // $top最顶层节点不需要下边的按钮
    dataList.push({
      key: +new Date(),
      servCatgCode: dataList.length + 1,
      ...val,
      $top: true,
    });
    setDataList([...dataList]);
    onChange?.(dataList);
    setEditModalVisible(false);
  };

  // 服务类目更改后自动填入数据
  const setFormValueChange = (id) => {
    const { form } = modalRef.current;
    dispatch({
      type: 'project/getServcatgDetailList',
      payload: {
        serviceCategoryId: id,
      },
    }).then((res) => {
      // 自动填充
      form.setFieldsValue({ ...res, servCatgTime: null });
    });
  };

  const conutChange = (_val, target) => {
    const { form } = modalRef.current;
    let servCatgCount = form.getFieldValue('servCatgCount'); // 数量
    let servCatgHourOrginCost = form.getFieldValue('servCatgHourOrginCost');
    let servCatgOrginTotalAmount = form.getFieldValue(
      'servCatgOrginTotalAmount',
    );
    let servCatgDiscTotalAmount = form.getFieldValue('servCatgDiscTotalAmount');
    form.setFieldsValue(
      getPrice(
        {
          servCatgCount,
          servCatgHourOrginCost,
          servCatgOrginTotalAmount,
          servCatgDiscTotalAmount,
        },
        editModalData?.proServCatgId ? pirceChange : null,
      ),
    );
  };

  const selectTeamChange = (id, item) => {
    let projectId = history.location.query?.projectId;
    if (projectId && editModalData?.proServCatgId) {
      const { form } = modalRef.current;
      let oldArr = form.getFieldsValue()?.addTeamMemberList || [];
      dispatch({
        type: 'project/delTeamRule',
        payload: {
          projectId,
          proServCatgId: editModalData.proServCatgId,
          teamMemberId: id.split('-')[1],
        },
      }).then((res) => {
        if (!res) {
          message.warn('该成员存在上报信息！');
          form.setFieldsValue({
            addTeamMemberList: [...oldArr, id],
          });
        }
      });
    }
  };

  const leaderChange = (keys) => {
    const { form } = modalRef.current;
    let oldArr = form.getFieldsValue()?.addTeamMemberList || [];
    oldArr.push(keys);
    oldArr = Array.from(new Set(oldArr));
    form.setFieldsValue({
      addTeamMemberList: oldArr,
    });
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const edtaFormItem = [
    {
      label: '选择服务类目',
      name: 'servCatgId',
      type: 'Select',
      required: '请选择服务类目',
      props: {
        placeholder: '请选择服务类目',
        options: project.servcatgEasyList.map((item) => ({
          label: item.value,
          value: item.id,
        })),
        onChange: setFormValueChange,
      },
    },
    {
      label: '选择责任人',
      name: 'servCatgLeader',
      type: 'Select',
      props: {
        placeholder: '请选择责任人',
        options: systemOrgani.employeeAll,
        onChange: leaderChange,
      },
    },
    {
      label: '选择参与团队',
      name: 'addTeamMemberList',
      type: 'Select',
      props: {
        placeholder: '请选择参与团队',
        mode: 'multiple',
        allowClear: true,
        maxTagCount: 'responsive',
        options: systemOrgani.employeeAll,
        onDeselect: selectTeamChange,
      },
    },
    {
      label: '单位',
      name: 'servCatgUnit',
      type: 'Input',
      props: {
        placeholder: '请输入单位',
      },
    },
    {
      label: '数量',
      name: 'servCatgCount',
      type: 'InputNumber',
      props: {
        placeholder: '请输入数量',
        stepType: 'inside',
        onChange: debounce((val) => conutChange(val, 'servCatgCount'), 300),
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      label: '工时费',
      name: 'servCatgHourOrginCost',
      type: 'InputNumber',
      props: {
        placeholder: '请输入工时费',
        stepType: 'inside',
        onChange: debounce(
          (val) => conutChange(val, 'servCatgHourOrginCost'),
          300,
        ),
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      label: '总价',
      name: 'servCatgOrginTotalAmount',
      type: 'InputNumber',
      props: {
        placeholder: '请输入总价',
        stepType: 'inside',
        onChange: debounce(
          (val) => conutChange(val, 'servCatgOrginTotalAmount'),
          300,
        ),
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      label: '优惠后总价',
      name: 'servCatgDiscTotalAmount',
      type: 'InputNumber',
      // help: '项目剩余金额：999',
      props: {
        placeholder: '请输入优惠后总价',
        stepType: 'inside',
        onChange: debounce(
          (val) => conutChange(val, 'servCatgDiscTotalAmount'),
          300,
        ),
        style: { width: '100%' },
        min: 0,
      },
    },

    {
      label: '所用工期/天（工时数）',
      name: 'servCatgWorkPeriod',
      type: 'Input',
      props: {
        placeholder: '请输入所用工期',
        // disabled: true,
        style: { width: '100%' },
      },
    },
    {
      label: '单价',
      name: 'servCatgUnitPrice',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '单价',
      },
    },
    {
      label: '优惠后单价',
      name: 'servCatgHourDiscCost',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '优惠后单价',
      },
    },
    {
      label: '优惠折扣',
      name: 'servCatgHourDisc',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '优惠折扣',
      },
    },
    {
      label: '总优惠金额',
      name: 'DiscountAmount',
      type: 'Input',
      props: {
        disabled: true,
        placeholder: '总优惠金额',
      },
    },
    {
      label: '工作包别名',
      name: 'servCatgName',
      type: 'Input',
      props: {
        placeholder: '请输入工作包别名',
      },
    },
    {
      label: '工作包周期',
      name: 'servCatgTime',
      type: 'DatePickerRangePicker',
      props: {
        // onChange: timeChange,
        showTime: {
          hideDisabledOptions: true, //不显示禁用的时间
          defaultValue: [moment('09:00:00', 'HH:mm'), moment('18:00', 'HH:mm')],
          minuteStep: 30,
          format: 'HH:mm',
        },
        disabledTime: (_, type, b) => ({
          disabledHours: () => {
            let arr = range(0, 24);
            arr.splice(9, 10);
            if (type == 'start') arr = arr.concat([12, 18]);
            if (type == 'end') arr = arr.concat([13, 9]);
            return arr;
          },
          disabledMinutes: (hours) => {
            if (hours == 12) return [30];
            if (hours == 13) return [0];
            return [];
          },
        }),
      },
    },
    // {
    //   label: '服务类目金额',
    //   render: () => <div>{countMoney}</div>,
    // },
    {
      label: '功能详情描述',
      name: 'servCatgRemark',
      type: 'TextArea',
      formItemProps: {
        className: 'block-item',
      },
      props: {
        placeholder: '请输入功能详情描述',
      },
    },
  ];

  const beforeDel = async (row) => {
    if (!row.projectId) {
      return true;
    }
    let shouldDel = true;
    if (row.proServCatgId) {
      await dispatch({
        type: 'project/delServcatgRule',
        payload: {
          proServCatgId: row.proServCatgId,
          projectId: row.projectId,
        },
      }).then((res) => {
        shouldDel = res;
        if (!res) {
          message.warn('该服务类目存在上报信息，不可删除！');
        }
      });
    }
    return shouldDel;
  };

  const deleteItem = (row) => {
    setDeleteList((list) => {
      if (row.proServCatgId) {
        delete row.$top;
        list.push(row);
      }
      return [...list];
    });
    onChange?.(dataList);
  };
  const copyItem = (list) => {
    onChange?.(list);
  };

  const editItem = (row) => {
    const { form } = modalRef.current;
    if (row.servCatgTime) {
      row.servCatgTime[0] = moment(row.servCatgTime[0]);
      row.servCatgTime[1] = moment(row.servCatgTime[1]);
    }
    setEditModalVisible(true);
    setEditModalData({
      ...row,
      ...getPrice(row),
    });
    form.setFieldsValue({
      ...row,
      ...getPrice(row),
    });
  };
  const addItemToChildren = (row) => {
    setEditModalVisible(true);
    setEditModalData({
      ...row,
      $childrenAdd: true,
    });
  };

  const sortChange = (from, to) => {
    let resList = dataList;
    setDataList((list) => {
      let targetIndex = list.findIndex((item) => item.key == from.key);
      let target = list.splice(targetIndex, 1)?.[0];
      if (target) {
        let dropIndex = list.findIndex((item) => item.key == to.key);
        let index = targetIndex > dropIndex ? 0 : 1;
        list.splice(dropIndex + index, 0, target);
      }
      resList = [...list];
      return [...list];
    });
    onChange?.(resList);
  };

  return (
    <div className="ServCategoryList">
      {dataList.map((item, index) => {
        let calHeaderl = (
          <Row className="detail-box">
            <Col span={5}>
              <span className="c999">
                {item.servCatgCode} {item.servCatgName}
              </span>
            </Col>
            <Col span={5}>
              责任人：
              {systemOrgani.employeeAll && (
                <span className="c999">
                  {
                    findTree(systemOrgani.employeeAll, item.servCatgLeader)
                      ?.label
                  }
                </span>
              )}
            </Col>
            <Col span={7}>
              <Typography.Paragraph ellipsis>
                参与制作：
                <span className="c999">
                  {item.addTeamMemberList
                    ?.map((id) => findTree(systemOrgani.employeeAll, id)?.label)
                    .join(',')}
                </span>
              </Typography.Paragraph>
            </Col>
          </Row>
        );
        return (
          <SortBox data={item} onChange={sortChange} key={item.key}>
            <ItemDetail
              disabled={disabled}
              headerExtra={headerExtra}
              extra={extra}
              data={item}
              headerEl={calHeaderl}
              addItem={addItemToChildren}
              deleteItem={deleteItem}
              beforeDel={beforeDel}
              editItem={editItem}
              copyItem={copyItem}
              onChange={onChange}
              employeeAll={systemOrgani.employeeAll}
              servcatgEasyList={project.servcatgEasyList}
              key={item.key}
              setDataList={setDataList}
              onCancel={() =>
                setDataList((pre) => {
                  pre.splice(index, 1);
                  // onChange?.(pre);
                  return [...pre];
                })
              }
            />
          </SortBox>
        );
      })}
      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        width={'80%'}
        formProps={{
          layout: 'vertical',
          labelCol: { span: 8 },
          wrapperCol: { span: 12 },
          className: 'inline-form',
          initialValues: {
            // servCatgHourOrginCost: 1800,
          },
        }}
        ref={modalRef}
        onSave={editChange}
        onCancel={() => {
          setEditModalVisible(false);
          setEditModalData(null);
        }}
      />
      {!disabled && (
        <div className="dashedBtn" onClick={addItem}>
          + 添加
        </div>
      )}
    </div>
  );
};

export default connect(({ systemOrgani, project }) => ({
  systemOrgani,
  project,
}))(ServCategoryList);
