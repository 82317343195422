import { MessageBox, Button, Table } from 'kenshin';
import TablePro from '@/components/TablePro';
import { useEffect, useState } from 'react';
import { flat } from '@/_util/util';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportemposaindexdata } from '@/_serveice/performance/osa';

/** 处理指标的数据项 */
function dataSouceChildOptions(childList, kpi) {
  let child =
    childList?.map?.((item) => {
      item.kpi = kpi;
      if (item.childList) {
        let childList = dataSouceChildOptions(item.childList, kpi);
        return [{ ...item, childList: undefined }, ...childList];
      }
      return [{ ...item, childList: undefined }];
    }) || [];
  return flat(child, 2);
}

/** 处理第一次dataSource */
function dataSouceOptions(dataSource) {
  if (Array.isArray(dataSource)) {
    let newDataSource = dataSource.map((item) => {
      item.kpi = `${item.code}：${
        item.osaIndexCatgName ?? item.osaIndexItemName
      }`;
      if (item.childList) {
        item.childList = dataSouceChildOptions(
          item.childList,
          `${item.code}：${item.osaIndexCatgName}`,
        );
      }
      return item;
    });
    return flat(
      newDataSource.map((item) => item.childList || [item]),
      2,
    );
  }
  return [];
}

function filterDuplicateNames(array, value) {
  let acc = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i].kpi !== value) break;
    acc.push(array[i]);
  }
  return acc;
}

const Preview = ({ visible, onCancel, dataSource = [], data = {} }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    if (dataSource) {
      let newList = dataSouceOptions(dataSource);
      setList(newList);
    }
  }, [dataSource]);

  const hasShowScoreNode = [
    'WEEK_PLAN_END_NODE',
    'OSA_END_NODE',
    'HR_CONFIRM_WEEK_PLAN_NODE',
    'HR_CONFIRM_NODE',
  ];
  const columns = [
    {
      dataIndex: 'kpi',
      title: '关键绩效目标（KPI）',
      fixed: 'left',
      width: 200,
      render(value, row, index) {
        let rowSpan = filterDuplicateNames(list.slice(index), value)?.length;
        let obj = {
          children: <div style={{ textAlign: 'center' }}>{value}</div>,
          props: { rowSpan },
        };
        if (row.kpi == list[index - 1]?.kpi) {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
    {
      key: 'osaIndexCatgName',
      title: '指标描述',
      fixed: 'left',
      width: 300,
      render(row) {
        return `${row.code}：${
          row.osaIndexItemName ?? row.osaIndexCatgName
        } ${'    '} ${row.weight}%`;
      },
    },
    {
      dataIndex: 'osaIndexItemGoalValueUnit',
      title: '单位',
      width: 80,
    },
    {
      dataIndex: 'osaIndexItemGoalValue',
      title: '目标值',
      width: 100,
    },
    {
      dataIndex: 'osaIndexItemActualValue',
      title: '实际值',
      width: 100,
    },
    {
      dataIndex: 'weight',
      title: '权重(%)',
      width: 100,
      render: (val) => `${val}%`,
    },
    ...(hasShowScoreNode.includes(data.curnNodeNumber)
      ? [
          {
            dataIndex: 'osaIndexItemSelfScore',
            title: '自评分',
            width: 80,
          },
          {
            dataIndex: 'osaIndexItemSelfScoreRemark',
            title: '自评分备注',
            width: 150,
          },
          {
            dataIndex: 'osaIndexItemDirectLeaderScore',
            title: '上级评分',
            width: 100,
          },
          {
            dataIndex: 'osaIndexItemDirectLeaderScoreRemark',
            title: '上级评分备注',
            width: 150,
          },
          {
            dataIndex: 'osaIndexItemBusiLeaderScore',
            title: '业务负责人评分',
            width: 140,
          },
          {
            dataIndex: 'osaIndexItemBusiLeaderScoreRemark',
            title: '业务负责人评分备注',
            width: 180,
          },
        ]
      : []),
  ];

  const handleExport = (type) => {
    let payload = {
      osaId: data.osaId ?? '',
      type: type ?? '',
      // ...value,
    };
    let url = `${baseURL}${exportemposaindexdata}${splitParamsFoGet(payload)}`;

    downloadXlsx(url);
  };

  const header = (
    <>
      <div style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          onClick={() => handleExport(data.osaWeekType ? 2 : 1)}
        >
          导出
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div>
            <span style={{ display: 'inline-block', width: 160 }}>
              员工姓名（被考核人)：
            </span>
            {data.userInfo?.value}
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 160 }}>
              所属部门：
            </span>
            {data.osaMuDept}
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 160 }}>
              直接主管(考核人)：
            </span>
            {data.directLeader?.value}
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 160 }}>
              评估周期：
            </span>
            {moment(data.osaBeginTime).format('YYYY年MM月DD日')} 至
            {moment(data.osaEndTime).format('YYYY年MM月DD日')}
          </div>
        </div>
        <div>
          <div>
            <span style={{ display: 'inline-block', width: 120 }}>
              职位名称：
            </span>
            {data.userInfo?.extendData}
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 120 }}>
              业务负责人：
            </span>
            {data.busiLeader?.value}
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 120 }}>
              考核类型：
            </span>
            {
              {
                1: '试用期转正评估',
                2: '绩效评估',
                3: '绩效改进评估',
                4: '个人发展计划',
              }[data.osaType]
            }
          </div>
        </div>
      </div>
    </>
  );
  return (
    <MessageBox
      width="95%"
      title="预览"
      destroyOnClose
      style={{ top: 20 }}
      bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      {header}
      <TablePro
        rowKey={'code'}
        bordered
        pagination={false}
        columns={columns}
        curColumns
        columnsOptions={false}
        scrollX
        childrenColumnName="childList"
        dataSource={list}
      />
    </MessageBox>
  );
};

export default Preview;
