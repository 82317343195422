import { MessageBox, Button, message } from 'kenshin';
import TablePro from '@/components/TablePro';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'umi';
import { useMemoizedFn } from 'ahooks';
import {
  customerColumns,
  contractCloumns,
  projectCloumns,
  roleCloumns,
  getProjectSearchCloumns,
  getContractSearchCloumns,
  getCustomerSearchCloumns,
} from './columns';
import busiTypeKey from '../../_util/busiTypeKey';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { contractSubmit, customerSubmit, taskSubmit } from './onSubmit';
const { CUSTOMER, CONTRACT, PROJECT, TASK, ROLE, OSAEDIT, WEEKEDIT } =
  busiTypeKey;
import Util from '../_unit';
const {
  TaskUtil,
  ContractUtil,
  CustomerUtil,
  ProjectUtil,
  RoleUtil,
  OsaEditUtil,
} = Util;

const ChooseBusiness = ({ busiType, visible, onCancel, process, onSave }) => {
  const [selectKey, setSelectKey] = useState([]);
  const [taskTreeData, setTaskTreeData] = useState([]);
  const selectData = useRef();

  const tableRef = useRef();

  const dispatch = useDispatch();

  // Redux数据
  const customer = useSelector(({ customer }) => customer);
  const system = useSelector(({ system }) => system);
  const project = useSelector(({ project }) => project);
  const performance = useSelector(({ performance }) => performance);
  const contract = useSelector(({ contract }) => contract);
  const systemOrgani = useSelector(({ systemOrgani }) => systemOrgani);

  useEffect(() => {
    if (visible) {
      if (busiType == TASK) {
        // dispatch({
        //   type: 'systemOrgani/getAllEmployee',
        // });
        dispatch({
          type: 'project/getSimpProjectList',
        });
      }
    }
  }, [visible]);

  // 结构转换给树组件渲染
  const transformTreeData = useMemoizedFn((arr) => {
    return arr?.map((item) => {
      let children = null;
      if (item.childList) {
        children = transformTreeData(item.childList);
      }
      return {
        children,
        title: (
          <EllipsisTooltip
            value={`${item.servCatgCode} ${item.servCatgName}`}
            width={200 - 60}
            placement="right"
            trigger="click"
          />
        ),
        key: item.proServCatgId,
        ...item,
      };
    });
  });

  const projectSelectChange = (projectId) => {
    tableRef.current?.search?.setFieldsValue({ proServCatgId: undefined });
    if (!projectId) {
      setTaskTreeData([]);
      return;
    }
    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId,
      },
    }).then((data) => {
      setTaskTreeData(transformTreeData(data));
    });
  };

  /**
   * 不同业务的表格属性
   * 1：客户管理；2：合同管理；3：项目管理；4：任务管理；5：角色管理；
   */
  const requestOrData = useMemo(() => {
    if (busiType == CUSTOMER) {
      //  客户
      return {
        searchCloumns: getCustomerSearchCloumns({ system }),
        request: 'customer/getCustomerList',
        data: customer.customerList,
        columns: customerColumns(system),
        rowKey: 'custId',
        key: 'customer',
      };
    }
    if (busiType == CONTRACT) {
      //  合同
      return {
        searchCloumns: ContractUtil.searchCloumns({ systemOrgani }),
        request: 'contract/getContractList',
        data: contract.contractList,
        columns: contractCloumns(system),
        rowKey: (row, index) => `${row.contractId}-${index}`,
        params: {
          roleList: JSON.parse(localStorage.getItem('employeeDTO'))?.roleList,
        },
        key: 'contract',
      };
    }
    if (busiType == PROJECT) {
      //  项目
      return {
        searchCloumns: getProjectSearchCloumns({ systemOrgani }),
        request: 'project/getProjectList',
        data: project.projectList,
        columns: projectCloumns(),
        rowKey: 'projectId',
        params: {
          roleList: JSON.parse(localStorage.getItem('employeeDTO'))?.roleList,
        },
        key: 'project',
      };
    }
    if (busiType == TASK) {
      //  任务
      return {
        searchCloumns: TaskUtil.searchCloumns({
          userProjectSimpList: project.userProjectSimpList,
          employeeAll: systemOrgani.employeeAll,
          taskTreeData,
          projectSelectChange,
        }),
        request: 'project/getTaskPage',
        data: project.taskList,
        columns: TaskUtil.cloumns(),
        rowKey: 'taskId',
        key: 'task',
      };
    }

    if (busiType == ROLE) {
      //  任务
      return {
        request: 'system/getroleList',
        data: system.roleList,
        columns: roleCloumns(),
        rowKey: 'roleId',
        key: 'role',
      };
    }
    if (busiType == OSAEDIT) {
      return OsaEditUtil.tableOptions?.({
        performance,
        dispatch,
        tableRef: tableRef.current,
      });
    }
    if (busiType == WEEKEDIT) {
      return OsaEditUtil.tableOptions?.({
        performance,
        dispatch,
        type: WEEKEDIT,
        tableRef: tableRef.current,
      });
    }
  }, [
    busiType,
    customer,
    project,
    contract,
    system,
    systemOrgani,
    taskTreeData,
    performance,
  ]);

  // 点击保存的时候数据
  const handleSave = () => {
    if (!selectData.current) {
      message.error('请选择业务！');
      return;
    }
    // onCancel();
    onSave?.(selectData.current);
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList, row) => {
      selectData.current = row[row.length - 1];
      setSelectKey([userIdList[userIdList.length - 1]]);
    },
  };

  /** 搜索拦截 */
  const onSubmit = (val) => {
    if (busiType == TASK) {
      return taskSubmit(val, { employeeAll: systemOrgani.employeeAll });
    }
    if (busiType == CONTRACT) {
      return contractSubmit(val);
    }
    if (busiType == CUSTOMER) {
      return customerSubmit(val);
    }
  };

  return (
    <MessageBox
      title="选择业务"
      onCancel={onCancel}
      width={1000}
      maskClosable={false}
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      destroyOnClose
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          确定
        </Button>,
      ]}
    >
      <TablePro
        {...requestOrData}
        curColumns
        onSubmit={onSubmit}
        columnsOptions={false}
        actionRef={(ref) => (tableRef.current = ref)}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      ></TablePro>
    </MessageBox>
  );
};

export default ChooseBusiness;
