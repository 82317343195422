import request from '@/_util/require';
let version = 'v1';
// 菜单列表
export async function getsysfunctiontree(params) {
  return request(`/${version}/waper/erp/pc/menuperm/getmenupermtree`, {
    method: 'GET',
    params,
  });
}

// 新增菜单
export async function addmenu(options) {
  return request(`/${version}/waper/erp/pc/menuperm/addmenupermission`, {
    method: 'POST',
    data: options,
  });
}
// 编辑菜单
export async function editmenu(options) {
  return request(`/${version}/waper/erp/pc/menuperm/editmenupermission`, {
    method: 'POST',
    data: options,
  });
}

// 删除菜单
export async function deleteMenu(params) {
  return request(`/${version}/waper/erp/pc/menuperm/deletemenupermission`, {
    method: 'GET',
    params,
  });
}

// 角色管理---------------------------------
// 角色列表
export async function getroleList(options) {
  return request(`/${version}/waper/erp/pc/role/getsysrolepage`, {
    method: 'POST',
    data: options,
  });
}
// 新增角色
export async function addRole(options) {
  return request(`/${version}/waper/erp/pc/role/addsysrole`, {
    method: 'POST',
    data: options,
  });
}
// 删除角色
export async function deleteRole(params) {
  return request(`/${version}/waper/erp/pc/role/deletesysrole`, {
    method: 'GET',
    params,
  });
}
// 修改角色
export async function editRole(options) {
  return request(`/${version}/waper/erp/pc/role/updatesysrole`, {
    method: 'POST',
    data: options,
  });
}
// 查询角色
export async function roleDetail(params) {
  return request('/sys/role/getdetailed', {
    method: 'GET',
    params,
  });
}
// 角色集合
export async function getsimplerolelist(params) {
  return request('/sys/role/getsimplerolelist', {
    method: 'GET',
    params,
  });
}
// 查询所有角色列表
export async function getAllRoleList(params) {
  return request(`/${version}/waper/erp/pc/role/getallrolelist`, {
    method: 'GET',
    params,
  });
}

// 字典类型管理---------------------------------
// 字典类型列表树
export async function getDictypeList(params) {
  return request(`/${version}/waper/erp/pc/sysdictype/getsysdictypetree`, {
    method: 'GET',
    params,
  });
}
// 新增字典类型
export async function addDictype(options) {
  return request(`/${version}/waper/erp/pc/sysdictype/addsysdictype`, {
    method: 'POST',
    data: options,
  });
}
// 删除字典类型
export async function deleteDictype(params) {
  return request(`/${version}/waper/erp/pc/sysdictype/deletesysdictype`, {
    method: 'GET',
    params,
  });
}
// 修改字典类型
export async function editDictype(options) {
  return request(`/${version}/waper/erp/pc/sysdictype/editsysdictype`, {
    method: 'POST',
    data: options,
  });
}
// 查询字典类型
export async function DictypeDetail(params) {
  return request(`/${version}/waper/erp/pc/sysdictype/getsysdictype`, {
    method: 'GET',
    params,
  });
}
// 查询所有字典类型
// export async function DictypeAll(params) {
//   return request('/sys/dictype/getall', {
//     method: 'GET',
//     params,
//   });
// }
// 通过字典类型查新字典列表
export async function DictypeAll(params) {
  return request(
    `/${version}/waper/erp/pc/sysdictionary/getsimpledicListbydictypeid`,
    {
      method: 'GET',
      params,
    },
  );
}

// 字典管理---------------------------------
// 字典列表
export async function getDictionaryList(params) {
  return request(
    `/${version}/waper/erp/pc/sysdictionary/getsysdictionarylist`,
    {
      method: 'GET',
      params,
    },
  );
}
// 新增字典
export async function addDictionary(options) {
  return request(`/${version}/waper/erp/pc/sysdictionary/addsysdictionary`, {
    method: 'POST',
    data: options,
  });
}
// 删除字典
export async function deleteDictionary(params) {
  return request(`/${version}/waper/erp/pc/sysdictionary/deletesysdictionary`, {
    method: 'GET',
    params,
  });
}
// 修改字典
export async function editDictionary(options) {
  return request(`/${version}/waper/erp/pc/sysdictionary/updatesysdictionary`, {
    method: 'POST',
    data: options,
  });
}
// 查询字典
export async function dictionaryDetail(params) {
  return request(`/${version}/waper/erp/pc/sysdictionary/getsysdictionary`, {
    method: 'GET',
    params,
  });
}

// 用户管理---------------------------------
// 用户列表
export async function getUserPagelist(options) {
  return request('/sys/user/getpagelist', {
    method: 'POST',
    data: options,
  });
}
// 新增用户
export async function addUser(options) {
  return request('/sys/user/add', {
    method: 'POST',
    data: options,
  });
}
// 删除用户
export async function deleteUser(params) {
  return request('/sys/user/singledelete', {
    method: 'get',
    params,
  });
}
// 批量删除用户
export async function batchDeleteUser(options) {
  return request('/sys/user/deletebatch', {
    method: 'POST',
    data: options,
  });
}
// 用户状态
export async function userStatus(params) {
  return request('/sys/user/setstatus', {
    method: 'GET',
    params,
  });
}
// 修改用户
export async function editUser(options) {
  return request('/sys/user/edit', {
    method: 'POST',
    data: options,
  });
}
// 查询用户
export async function userDetail(params) {
  return request('/sys/user/getdetailed', {
    method: 'GET',
    params,
  });
}
// 重置密码
export async function resetpwd(options) {
  return request('/sys/user/resetpwd', {
    method: 'POST',
    data: options,
  });
}
// 右上角修改密码
export async function sysupdatepwd(options) {
  return request(`/${version}/waper/erp/pc/employee/updateemployeepwd`, {
    method: 'POST',
    data: options,
  });
}
// 设置业务负责人
export async function setemployeebusileader(options) {
  return request(`/${version}/waper/erp/pc/employee/setemployeebusileader`, {
    method: 'POST',
    data: options,
  });
}
// 组织架构部门---------------------------------
// 查询部门员工列表
export async function getemplistbydeptid(params) {
  return request(`/${version}/waper/erp/pc/employee/getemplistbydeptid`, {
    method: 'GET',
    params,
  });
}
// 部门列表
export async function getDepartmentPagelist(options) {
  return request(`/${version}/waper/erp/pc/dept/getsysdepttree`, {
    method: 'POST',
    data: options,
  });
}
// 新增部门
export async function addDepartment(options) {
  return request(`/${version}/waper/erp/pc/dept/addsysdept`, {
    method: 'POST',
    data: options,
  });
}
// 删除部门
export async function deleteDepartment(params) {
  return request(`/${version}/waper/erp/pc/dept/deletesysdept`, {
    method: 'GET',
    params,
  });
}
// 移动部门
export async function moveDepartment(options) {
  return request(`/${version}/waper/erp/pc/dept/movesysdept`, {
    method: 'POST',
    data: options,
  });
}
// 搜索部门用户
export async function searchSysDeptUser(options) {
  return request(`/${version}/waper/erp/pc/dept/searchsysdeptuser`, {
    method: 'POST',
    data: options,
  });
}
// 设置部门上级
export async function setDeptmanager(options) {
  return request(`/${version}/waper/erp/pc/dept/setdeptmanager`, {
    method: 'POST',
    data: options,
  });
}
// 修改部门
export async function editDepartment(options) {
  return request(`/${version}/waper/erp/pc/dept/updatesysdept`, {
    method: 'POST',
    data: options,
  });
}

// 组织架构---------------------------------
// 列表
export async function getOrganiList(options) {
  return request(`/${version}/waper/erp/pc/employee/getemployeepage`, {
    method: 'POST',
    data: options,
  });
}
// // 新增
export async function addOrgani(options) {
  return request(`/${version}/waper/erp/pc/employee/addmployee`, {
    method: 'POST',
    data: options,
  });
}
// 状态
export async function organiStatus(options) {
  return request(`/${version}/waper/erp/pc/employee/updateemployeestatus`, {
    method: 'POST',
    data: options,
  });
}
// 修改
export async function editOrgani(options) {
  return request(`/${version}/waper/erp/pc/employee/updateemployee`, {
    method: 'POST',
    data: options,
  });
}
// 重置密码
export async function resetPwd(params) {
  return request(`/${version}/waper/erp/pc/employee/resetemppassword`, {
    method: 'GET',
    params,
  });
}
// 查询所以员工
export async function getAllEmployee(params) {
  return request(`/${version}/waper/erp/pc/employee/getdeptemployeelist`, {
    method: 'GET',
    params,
  });
}

//新增修改打卡通知任务
export async function addupdateclocknoticetask(params) {
  return request(`/${version}/waper/erp/pc/employee/addupdateclocknoticetask`, {
    method: 'POST',
    data: params,
  });
}

//查询打卡通知任务
export async function getclocknoticetask(params) {
  return request(`/${version}/waper/erp/pc/employee/getclocknoticetask`, {
    method: 'GET',
    params,
  });
}

// 查询项目经理
export async function getRoleKeyObj(params) {
  return request(`/${version}/waper/erp/pc/employee/getemplistbyrolekey`, {
    method: 'GET',
    params,
  });
}

//  标签---------------
// 查询标签表格数据
export async function getTagList(params) {
  return request(`/${version}/waper/erp/pc/taglibrary/getTagLibraryPage`, {
    method: 'POST',
    data: params,
  });
}
// 查询标签库简单分类类别--用于新增修改
export async function getTagAll(params) {
  return request(`/${version}/waper/erp/pc/taglibrary/getmodulelist`, {
    method: 'GET',
    params,
  });
}
// 删除标签
export async function deleteTag(params) {
  return request(`/${version}/waper/erp/pc/taglibrary/deleteTagLibrary`, {
    method: 'GET',
    params,
  });
}
// 新增标签
export async function addTag(params) {
  return request(`/${version}/waper/erp/pc/taglibrary/addtaglibrary`, {
    method: 'POST',
    data: params,
  });
}
// 新增标签
export async function editTag(params) {
  return request(`/${version}/waper/erp/pc/taglibrary/updatetaglibrary`, {
    method: 'POST',
    data: params,
  });
}

export const exportEmployee = `/${version}/waper/erp/pc/employee/exportemployee`;

// 操作日志
export async function getOperatorLogPage(params) {
  return request(`/${version}/waper/erp/pc/operatorlog/getoperatorlogpage`, {
    method: 'POST',
    data: params,
  });
}
// 查询所有模块
export async function getAllModuleName(params) {
  return request(`/${version}/waper/erp/pc/operatorlog/getallmodulename`, {
    method: 'GET',
    params,
  });
}

// 登录日志
export async function getLoginLogPage(params) {
  return request(`/${version}/waper/erp/pc/loginlog/getloginlogpage`, {
    method: 'POST',
    data: params,
  });
}

// 修改员工基础信息
export async function updateUserInfo(params) {
  return request(`/${version}/waper/erp/pc/employee/updateemployeebaseinfo`, {
    method: 'POST',
    data: params,
  });
}
