// import

import { Button } from 'kenshin';
import { useRef, useState } from 'react';
import { connect, history } from 'umi';
import ChangeCard from '../../businessLog/ChangeRecord/components/ChangeCard';
import ModalFormPro from '@/components/ModalFormPro';
import busiTypeKey from '../../_util/busiTypeKey';
import Util from '@/pages/process/createProcess/_unit';
const { ROLE } = busiTypeKey;

const auditPage = ({ usercenter, dispatch }) => {
  const rowsData = usercenter.createProcessData;
  const [editModalVisible, setEditModalVisible] = useState(false);

  const editModalSave = (value) => {
    // 默认选择通过
    value.auditType ??= '1';
    let payload = {
      todoItemId: rowsData.todoItemId,
      ...value,
    };

    dispatch({
      type: 'process/auditprocessapply',
      payload,
    }).then((res) => {
      if (res) {
        setEditModalVisible(false);
        history.go(-1);
      }
    });
  };

  const edtaFormItem = [
    {
      name: 'auditType',
      label: ' ',
      type: 'RadioGroup',
      formItemProps: {
        colon: false,
      },
      props: {
        optionType: 'button',
        defaultValue: '1',
        options: [
          {
            label: '通过',
            value: '1',
          },
          {
            label: '拒绝',
            value: '2',
          },
        ],
      },
    },
    {
      name: 'auditRemark',
      label: '审核备注',
      type: 'InputTextArea',
      props: {
        placeholder: '请输入审核备注',
      },
    },
  ];

  const CardRender = () => {
    if (rowsData.todoItemBusiType == ROLE) {
      return (
        <>
          <div style={{ marginBottom: 10, fontWeight: 'bold' }}>申请角色</div>
          {Util?.RoleUtil?.getCard(
            JSON.parse(rowsData.dataChange?.dataChangeAfter || '{}'),
          )}
        </>
      );
    }
    return (
      <ChangeCard
        type={rowsData.todoItemBusiType}
        afterData={JSON.parse(rowsData.dataChange?.dataChangeAfter || '{}')}
        beforeData={JSON.parse(rowsData.dataChange?.dataChangeBefore || '{}')}
      />
    );
  };

  return (
    <div className="change-record">
      <div className="flr">
        <Button type="primary" onClick={() => setEditModalVisible(true)}>
          审核
        </Button>
      </div>
      {CardRender()}

      <ModalFormPro
        title={'审核'}
        width="600px"
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ usercenter }) => ({
  usercenter,
}))(auditPage);
