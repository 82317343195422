import { InputNumber, Typography } from 'kenshin';
const AutoSend = ({ value, onChange, disabled }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>考核结束前后</span>
      <InputNumber
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder="自动分发时间"
        stepType="inside"
        style={{ margin: '0 10px' }}
      />
      <span>天（到期会通知关联同事及上级完成考核）</span>
    </div>
  );
};

export default AutoSend;
