import request from '@/_util/require';
let version = 'v1';

export async function getContractList(options) {
  return request(`/${version}/waper/erp/pc/contract/getcontractpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addContract(options) {
  return request(`/${version}/waper/erp/pc/contract/addcontract`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editContract(options) {
  return request(`/${version}/waper/erp/pc/contract/updatecontract`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteContract(params) {
  return request(`/${version}/waper/erp/pc/contract/deleteContract`, {
    method: 'GET',
    params,
  });
}
// 删除
export async function statusChange(params) {
  return request(`/${version}/waper/erp/pc/contract/contractfinishedend`, {
    method: 'POST',
    data: params,
  });
}

// 查询简单的合同列表
export async function getEasyContractList(params) {
  return request(`/${version}/waper/erp/pc/contract/getsimplecontractlist`, {
    method: 'GET',
    params,
  });
}

// 合同收款
export async function getContractCollectList(options) {
  return request(
    `/${version}/waper/erp/pc/project/collection/getprojectcollectrcdpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 合同收款（按收款时间）
export async function getContractCollectRCList(options) {
  return request(
    `/${version}/waper/erp/pc/project/collection/getcontractcollectrcdpage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 新增
export async function addContractCollect(options) {
  return request(
    `/${version}/waper/erp/pc/project/collection/addprojectcollectRcd`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 编辑
export async function editContractCollect(options) {
  return request(
    `/${version}/waper/erp/pc/project/collection/updateprojectcollectRcd`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 删除
export async function delContractCollect(params) {
  return request(`/${version}/waper/erp/pc/project/collection/deleteprojectcollectrcd`, {
    method: 'GET',
    params,
  });
}
// 其他
export async function getContractById(params) {
  return request(
    `/${version}/waper/erp/pc/contract/getsimplecontractlistbycustid`,
    {
      method: 'GET',
      params,
    },
  );
}
// 查询合同预收款列表
export async function getContexpectCollectList(params) {
  return request(
    `/${version}/waper/erp/pc/contract/findcontexpectcollectlist`,
    {
      method: 'GET',
      params,
    },
  );
}
// 查询合同可用金额(单位：分)
export async function getContractMount(params) {
  return request(`/${version}/waper/erp/pc/contract/getcontractenableamount`, {
    method: 'GET',
    params,
  });
}

// 合同导出
export const exportContractList = `/${version}/waper/erp/pc/contract/exportcontractlist`;
// 合同收款导出
export const exportContCollectList = `/${version}/waper/erp/pc/project/collection/exportcontcollectlist`;

// 查询合同编号是否被使用
export async function findContractNumUsed(params) {
  return request(`/${version}/waper/erp/pc/contract/findcontractnumused`, {
    method: 'GET',
    params,
  });
}

// 导入模板下载
export const collectDownTemplate = `/template/contract-collection.xls`;

// 导入模板下载
export async function importContractCollect(options) {
  return request(`/${version}/waper/erp/pc/project/collection/importcontractcollect`, {
    method: 'POST',
    data: options,
  });
}
