import {
  Button,
  Typography,
  message,
  Radio,
  Input,
  Tabs,
  Timeline,
} from 'kenshin';
import CollapseCard, { Template } from '../../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../../_keys';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import Indicators from './indicators';
import JobEvaluation from '../../scoreSetting/components/jobEvaluation';
import { CloseCircleFilled } from '@ant-design/icons';

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};
/** 不同节点的评分参数 */
const scoreKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItem',
  SCORE_THREE_NODE: 'osaBusiBonusItem',
};

/** 不同节点的备注参数 */
const scoreRemarkKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItemRemark',
  SCORE_THREE_NODE: 'osaBusiBonusItemRemark',
};

const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

/** 获取自评、上级、业务负责人评分总分 */
function getTotalScore(list, curData = {}) {
  let osaIndexCatgSelfScore = 0;
  let osaIndexCatgDirectLeaderScore = 0;
  let osaIndexCatgBusiLeaderScore = 0;
  list?.forEach((item) => {
    osaIndexCatgSelfScore +=
      item.osaIndexItemSelfScore ?? item.osaIndexCatgSelfScore ?? 0;

    osaIndexCatgDirectLeaderScore +=
      item.osaIndexItemDirectLeaderScore ??
      item.osaIndexCatgDirectLeaderScore ??
      0;

    osaIndexCatgBusiLeaderScore +=
      item.osaIndexItemBusiLeaderScore ?? item.osaIndexCatgBusiLeaderScore ?? 0;
  });
  let isWeek = curData.osaWeekType == 1;
  return [
    isWeek ? null : (
      <div key={1} style={{ lineHeight: 2 }}>
        <span style={{ fontWeight: 'bold' }}>自评总得分：</span>
        {osaIndexCatgSelfScore}
      </div>
    ),
    <div key={2} style={{ lineHeight: 2 }}>
      <span style={{ fontWeight: 'bold' }}>上级总得分：</span>
      {+osaIndexCatgDirectLeaderScore + +curData.osaDirectBonusItem}（
      {osaIndexCatgDirectLeaderScore} + {curData.osaDirectBonusItem}）
    </div>,
    // <div key={2} style={{ lineHeight: 2 }}>
    //   <span style={{ fontWeight: 'bold' }}>上级总得分（含加分）：</span>
    //   {+osaIndexCatgDirectLeaderScore + +curData.osaDirectBonusItem}
    // </div>,
    isWeek ? null : (
      <>
        <div key={3} style={{ lineHeight: 2 }}>
          <span style={{ fontWeight: 'bold' }}>业务负责人总得分：</span>
          {+osaIndexCatgBusiLeaderScore + +curData.osaBusiBonusItem}（
          {osaIndexCatgBusiLeaderScore} + {curData.osaBusiBonusItem}）
        </div>
        {/* <div key={3} style={{ lineHeight: 2 }}>
        <span style={{ fontWeight: 'bold' }}>业务负责人总得分（含加分）：</span>
        {+curData.osaBusiTotalScore + +curData.osaBusiBonusItem}
      </div> */}
      </>
    ),
  ].filter(Boolean);
}

/** 上级查看的时候是否显示相关内容（其他人的评分） */
const getSuperiorShow = (curData) => {
  let superEmp = curData.osaScoreList?.find((item) => item.osaScoreType == 2);
  if (superEmp?.osaScoreMuId == localEmpId) {
    return !!superEmp?.osaSeeOtherScore;
  }
  return true;
};

const isShowConfirmBtn = (row) => {
  return !!history.location.query.busiId;
};

const indicatorsKey = {
  DIR: 1,
  ITEM: 2,
};

/** 递归校验目录必须含有一个或多个指标 */
const indicatorsValidate = (list) =>
  list.every((item) => {
    if (item.type == indicatorsKey.DIR && !(item.childList?.length > 0)) {
      return false;
    }
    if (Array.isArray(item.childList)) {
      return indicatorsValidate(item.childList);
    }
    return true;
  });

const createTemplate = ({ performance, dispatch }) => {
  // 当前页面数据
  let curData = performance.curOsaSettingData.rows ?? {};

  let disabled = true;

  const superiorShow = getSuperiorShow(curData);
  const [activeKey, setActiveKey] = useState('SCORE_TWO_NODE');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const auditResult = useRef(AUDITTYPE.SUCCESS);
  const [resultList, setResultList] = useState([]);

  const [refuseinfo, setRefuseinfo] = useState(null);

  useEffect(() => {
    init();
    dispatch({
      type: 'performance/getosaauditsystemdescribelist',
    }).then((list) => {
      // console.log(res);
      if (list) {
        setResultList(
          list.map((item) => ({ label: item.value, value: item.id })),
        );
      }
    });
    return () => {
      dispatch({
        type: 'performance/save',
        payload: {
          curOsaSettingData: {
            rows: {},
          },
        },
      });
    };
  }, [history.location.query.busiId]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  const init = async () => {
    if (history.location.query.busiId) {
      curData = await dispatch({
        type: 'performance/getosaemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                },
              },
            },
          });
          return res;
        }
        return {};
      });
    }
    if (curData.osaId) {
      dispatch({
        type: 'performance/getosaemployeeauditscoredata',
        payload: {
          osaId: curData.osaId,
        },
      });
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId: curData.osaId,
          todoBusiId: '',
        },
      }).then((res) => {
        console.log('refuseinfo==================3', refuseinfo);

        if (res) {
          res.osaProcessDataList.map((item) => {
            if (
              res.backNodeId != null &&
              item.processNodeId === res.backNodeId
            ) {
              console.log('请求拒绝接口了');
              dispatch({
                type: 'performance/getosaweekprocessrefuseinfo',
                payload: {
                  osaId: curData.osaId,
                  todoBusiId: '',
                },
              }).then((res) => {
                console.log('getosaweekprocessrefuseinfo:curData', curData);
                if (res) {
                  setRefuseinfo(res);
                }
              });
            }
          });
        }
      });
    }
  };

  // 基本信息的附加项
  const infoParams = {
    title: '考核类型',
    dataIndex: 'osaType',
    valueType: 'RadioGroup',
    fieldProps: {
      placeholder: '请输入考核方案名称',
      disabled,
      options: [
        { label: '试用期转正评估', value: 1 },
        { label: '绩效评估', value: 2 },
        { label: '绩效改进评估', value: 3 },
        { label: '个人发展计划', value: 4 },
      ],
    },
  };

  const baseCloumns = [
    {
      title: '考核周期',
      valueType: 'RadioGroup',
      fieldProps: {
        disabled: true,
        value: curData.osaWeekType,
        options: [
          {
            label: '周',
            value: 1,
          },
          {
            label: '季度',
            value: 3,
          },
        ],
      },
    },
    {
      title: '考核有效期',
      valueType: 'DatePickerRangePicker',
      // dataIndex: 'osaTime',
      fieldProps: {
        disabled,
        value: [moment(curData.osaBeginTime), moment(curData.osaEndTime)],
        placeholder: ['开始时间', '结束时间'],
      },
    },
    // {
    //   title: '自动分发时间',
    //   valueType: 'InputNumber',
    //   fieldProps: {
    //     disabled,
    //     value: curData.osaNoticeDaysBeforeAfter,
    //     placeholder: '自动分发时间',
    //     stepType: 'inside',
    //   },
    // },
    {
      title: '考核模式',
      valueType: 'Select',
      extra:
        '在既定时间内完成目标，且有多付出、多贡献、有特别亮点的项目则视情况给予附加分1-20分',
      fieldProps: {
        value: curData.osaScoreRule,
        placeholder: '请选择考核模式',
        disabled,
        options: [
          {
            label: '满分100分制（可向上浮动20%）',
            value: 1,
          },
        ],
      },
    },
  ];

  let list =
    performance.osaProcessCache[curData.osaId]?.osaProcessDataList ?? [];

  let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[curData.osaId]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (backNodeId != null && list[index].processNodeId === backNodeId)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  const HeaderButton = (
    <div className="fixed-right">
      {isShowConfirmBtn(performance.curOsaSettingData.rows) && (
        <Button
          type="primary"
          onClick={() => {
            if (!indicatorsValidate(performance.osaIndicators)) {
              message.error('指标目录应含有指标');
              return;
            }
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.SUCCESS;
          }}
        >
          {['HR_PRE_CONFIRM_WEEK_PLAN_NODE', 'HR_PRE_CONFIRM_NODE'].includes(
            curData.curnNodeNumber,
          )
            ? '审核通过'
            : '确认入库'}
        </Button>
      )}
      {isShowConfirmBtn(performance.curOsaSettingData.rows) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      <Button type="primary" onClick={() => goback()}>
        返回
      </Button>
    </div>
  );

  const edtaFormItem = [
    auditResult.current == AUDITTYPE.ERROR
      ? {
          label: '原因',
          name: 'auditRemarkList',
          type: 'Select',
          props: {
            mode: 'tags',
            options: resultList,
            placeholder: '请输入原因，按enter确定',
          },
        }
      : null,
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
    ...(() => {
      if (
        ['HR_CONFIRM_NODE', 'HR_PRE_CONFIRM_NODE'].includes(
          curData.curnNodeNumber,
        ) &&
        auditResult.current == AUDITTYPE.ERROR
      ) {
        return [
          {
            label: '驳回到',
            name: 'backNode',
            required: '请选择驳回节点',
            type: 'RadioGroup',
            props: {
              options: [
                {
                  label: '自评',
                  value: 1,
                },
                {
                  label: '直属上级评分',
                  value: 2,
                },
                {
                  label: '业务负责人评分',
                  value: 3,
                },
              ],
            },
          },
        ];
      }
      return [];
    })(),
  ].filter(Boolean);

  // 驳回信息
  const rejectInfoParams = [
    {
      title: `${refuseinfo?.backUserName}:`,
      render() {
        return <div>{refuseinfo?.backUserRemark}</div>;
      },
    },
  ];

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const editModalSave = (value) => {
    /** 通过/拒绝原因 */
    let auditRemarkList = [
      ...(value.auditRemarkList ?? []),
      value.auditRemark,
    ]?.map((txt) => {
      let SUCCESSID = 88888888;
      let ERRORID = 99999999;
      let auditRmkDescribe =
        resultList.find((item) => item.value == txt)?.label || txt;
      let auditRmkDescribeId =
        resultList.find((item) => item.value == txt)?.value ??
        (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
      let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
      return {
        auditRmkDescribe,
        auditRmkDescribeId,
        auditRmkType,
      };
    });
    let payload = {
      ...value,
      auditRemarkList,
      osaId: curData.osaId,
      auditResult: auditResult.current,
    };
    dispatch({
      type: 'performance/auditosaprocess',
      payload,
    }).then((res) => {
      if (res) {
        setEditModalVisible(false);
        clearCache();
        goback();
      }
    });
  };

  const radioOptions = [
    {
      label: `A`,
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
  ];

  const extraScoreCloumns = [
    {
      render() {
        return (
          <Tabs
            activeKey={activeKey}
            titleText="卡片标题"
            type="card"
            onChange={setActiveKey}
          >
            <Tabs.TabPane tab="直属上级" key="SCORE_TWO_NODE"></Tabs.TabPane>
            {curData.osaWeekType == 3 && superiorShow && (
              <Tabs.TabPane
                tab="业务负责人"
                key="SCORE_THREE_NODE"
              ></Tabs.TabPane>
            )}
          </Tabs>
        );
      },
    },
    {
      title: '加分分值',
      dataIndex: 'score',
      valueType: 'InputNumber',
      fieldProps: {
        style: { width: 200 },
        stepType: 'inside',
        min: 0,
        disabled: true,
        max: 100,
        placeholder: '请输入分数:0~20',
      },
    },
    {
      title: '原因描述',
      dataIndex: 'remark',
      valueType: 'TextArea',
      fieldProps: {
        autoSize: { minRows: 2, maxRows: 6 },
        disabled: true,
        placeholder: '请输入原因描述',
      },
    },
  ];

  return (
    <div className="create-template-page">
      {!history.location.query.osaId && HeaderButton}
      <Typography.Title level={4}>OSA考核设置</Typography.Title>
      <CollapseCard
        title="基本设置"
        cloumns={Template.INFO.default({
          item: infoParams,
          disabled,
          target: performance.curOsaSettingData.rows,
        })}
        defaultValue={curData}
      />
      {curData.osaId && (
        <CollapseCard title="常规指标">
          {/* <Template.INDICATORS.default
            idKey="osaId"
            disabled={disabled}
            idValue={curData.osaId}
          /> */}
          <Indicators
            superiorShow={superiorShow}
            idKey="osaId"
            idValue={curData.osaId}
            showBtn={false}
          />
          {getTotalScore(performance.osaIndicators, curData)}
        </CollapseCard>
      )}

      {curData.osaWeekType == 3 && (
        <CollapseCard title={<>月度策略</>}>
          <Template.MONTHSTRATEGY.default
            // dispatchApi={dispatchApi}
            idKey="osaId"
            osaWeekType={3}
            disabled={disabled}
            idValue={curData.osaId}
            // showPublicTemplate={true}
          />
        </CollapseCard>
      )}

      <CollapseCard
        title={
          <>
            加分项
            <span style={{ color: '#999', fontSize: 12 }}>(最高上浮20%)</span>
          </>
        }
        cloumns={extraScoreCloumns}
        key={activeKey}
        defaultValue={{
          score: curData[scoreKey[activeKey]],
          remark: curData[scoreRemarkKey[activeKey]],
        }}
      />
      <JobEvaluation
        curData={curData}
        superiorShow={superiorShow}
        disabled={disabled}
      />
      {curData.osaWeekType == 3 && (
        <CollapseCard title="综合结论">
          <div>
            考核评级
            <Radio.Group
              style={{ marginLeft: 8 }}
              key={curData.osaGrade}
              disabled
              defaultValue={curData.osaGrade}
              options={radioOptions}
            />
          </div>
          <Input.TextArea
            placeholder="具体原因"
            key={curData.osaGradeRemark}
            disabled
            defaultValue={curData.osaGradeRemark}
            style={{ marginTop: 8 }}
          />
        </CollapseCard>
      )}

      {refuseinfo &&
        refuseinfo?.backUserRemark &&
        refuseinfo?.backUserRemark != 'null;' && (
          <CollapseCard
            visible="v"
            title="驳回信息"
            cloumns={Template.INFO.default({
              disabled,
              target: curData,
              arr: rejectInfoParams,
            })}
          />
        )}

      <CollapseCard
        title={
          <>
            流程图
            <span style={{ color: '#999', fontSize: 12 }}>
              （查看目前处于哪个环节)
            </span>
          </>
        }
      >
        <Timeline>
          {list.map((item, index) => (
            <Timeline.Item
              key={index}
              content={item.processDescribe}
              // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
              {...dots(index)}
            />
          ))}
        </Timeline>
      </CollapseCard>
      <CollapseCard
        title="方案基本设置"
        cloumns={baseCloumns}
        key={performance.curOsaSettingData.rows}
        disabled={disabled}
        defaultValue={curData}
      />
      {/* <CollapseCard title="评分人设置">
        <Template.GRADER.default
          disabled={disabled}
          defaultValue={curData.osaScoreList}
        />
      </CollapseCard> */}
      {/* <Template.OFFTIME.default
        disabled={disabled}
        defaultValue={curData.osaScoreEndDaysBeforeAfter}
      /> */}
      <CollapseCard title="审核流程设置">
        <Template.PROCESS.default
          disabled={disabled}
          defaultValue={curData.osaAuditList}
        />
      </CollapseCard>
      <ModalFormPro
        width={600}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ performance }) => {
  return { performance };
})(createTemplate);
