import React, { useEffect } from 'react';
import { Button, Card, MessageBox } from 'kenshin';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
// 样式文件
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// 转化为 html（根据自己的需求添加）
import draftjs from 'draftjs-to-html';
import { useReactive } from 'ahooks';
import { request } from 'umi';
import { getOSSMsg } from '@/_serveice/user';
import { OSSImgUrl } from '@/_util/util';
import htmlToDraft from 'html-to-draftjs';

let aaa =
  '<p></p>\n<img src="https://oss.waperidea.cn/A9/waper-erpcustomer/caselist/2023/05/09/00026-481193937.png" alt="undefined" style="height: 100px;width: 100px"/>\n<p>😺</p>\n';
let ossRootDirectoryBase = ossDirUrl;
const Text = ({ dirUrl = 'customer/caselist', value = aaa }) => {
  useEffect(() => {
    state.editorContent = value;
    let contentBlock = htmlToDraft(value);
    // console.log(htmlToDraft(value));
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      // console.log(editorState);
      state.editorState = editorState;
    }
  }, []);

  const state = useReactive({
    editorContent: '',
    editorState: '',
  });

  const onEditorChange = (value) => {
    state.editorContent = value;
  };
  const onEditorStateChange = (value) => {
    state.editorState = value;
  };

  const getData = () => {
    console.log(state, draftjs(state.editorContent));
  };

  const uploadCallback = async (file) => {
    let name = file.name;
    const ossInfo = await getOSSMsg().then((res) => res.data);
    let fileUrl = `${ossRootDirectoryBase}${dirUrl}/${moment().format(
      'YYYY/MM/DD',
    )}`;
    const { policy, accessId, signature } = ossInfo;

    let resUrl = `${fileUrl}/${name}`;
    let fd = new FormData();
    fd.append('key', resUrl);
    fd.append('policy', policy);
    fd.append('OSSAccessKeyId', accessId);
    fd.append('signature', signature);
    fd.append('file', file);
    fetch(ossUrl, {
      method: 'POST',
      body: fd,
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve({ data: { link: OSSImgUrl(resUrl) } }), 1000);
    });
  };
  return (
    <div>
      <Button onClick={getData}>获取数据</Button>
      <Editor
        editorState={state.editorState}
        onContentStateChange={onEditorChange}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            className: undefined,
            // component: () => <div>123</div>,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
          },
        }}
      />
    </div>
  );
};

export default Text;
