import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'umi';
import { Tabs, Table, Row, Col, Radio, Progress } from 'kenshin';
import department from '../dataAnalyse/cost/department';
import employee from '../dataAnalyse/cost/employee';
import project from '../dataAnalyse/cost/project';
import SearchForm from '@/components/TablePro/components/SearchForm';
import AnalyseCharts from '@/components/AnalyseCharts';
import PieShade from './charts/PieShade';
import Bar from './charts/Bar';
import { useUpdateEffect } from 'ahooks';

const dataAnalyseCost = () => {
  const dispatch = useDispatch();
  const dataAnalyse = useSelector(({ dataAnalyse }) => dataAnalyse);
  const homeTableList = useSelector(
    ({ usercenter }) => usercenter.homeTableList,
  );
  // 标签栏当前key
  const [curCheckedKey, setCurCheckedKey] = useState('1');
  const [charts, setCharts] = useState({});

  useEffect(() => {
    dispatch({
      type: 'usercenter/getFinaceHomeTable',
    });
    dispatch({
      type: 'usercenter/getFinanceProdCharts',
    }).then((res) => {
      setCharts(res);
    });
    onSubmit();
  }, []);

  const dataArr = useMemo(
    () => ({
      1: project,
      2: department,
      3: employee,
    }),
    [],
  );

  const dispatchType = {
    charts: 'getCostCharts',
  };
  const dataType = {
    charts: 'costChartData',
  };

  const tabsArr = useMemo(
    () => [
      {
        label: '项目',
        value: '1',
      },
      {
        label: '部门',
        value: '2',
      },
      {
        label: '人员',
        value: '3',
      },
    ],
    [],
  );

  const defaultTime = [
    moment().subtract(7, 'day').startOf('date'),
    moment().subtract().endOf('date'),
  ];
  const searchCloumns = [
    {
      name: 'searchTime',
      label: '日期范围',
      type: 'DatePickerRangePicker',
      span: 8,
      props: {
        defaultValue: defaultTime,
      },
    },
  ];

  const onSubmit = (params = { searchTime: defaultTime }) => {
    const [start, end] = params.searchTime || defaultTime;
    params.statBeginTime = moment(start).format('YYYY-MM-DD HH:mm:ss');
    params.statEndTime = moment(end).format('YYYY-MM-DD HH:mm:ss');
    params.searchTime = undefined;
    // let { statBeginTime, statEndTime } = params;
    dispatch({
      type: 'dataAnalyse/getCostCharts',
      dataRule: dataArr[curCheckedKey]?.dataRule,
      payload: {
        tabsKey: curCheckedKey,
        ...params,
      },
    });
  };

  const columns = [
    {
      dataIndex: 'projectName',
      title: '项目名称',
    },
    {
      dataIndex: 'contractAmount',
      title: '合同金额',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'actualProfitAmount',
      title: '实际盈利',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'projectTimeProgressValue',
      title: '时间进度',
      render: (val) => (
        <div className="progress-box">
          <span>{+val}% </span>
          <Progress strokeWidth={6} percentage={val} showText={false} />
        </div>
      ),
    },
    {
      dataIndex: 'projectActualConsumProgressValue',
      title: '消耗进度',
      render: (val) => (
        <div className="progress-box">
          <span>{+val}% </span>
          <Progress strokeWidth={6} percentage={val} showText={false} />
        </div>
      ),
    },
  ];

  useUpdateEffect(onSubmit, [curCheckedKey]);
  const [tableKeys, setTableKeys] = useState(1);
  const [tableDataSource, setTableDataSource] = useState([]);

  useEffect(() => {
    setTableDataSource(homeTableList);
  }, [homeTableList]);

  const options = [
    { label: '全部', value: 1 },
    { label: '盈利', value: 2 },
    { label: '亏损', value: 3 },
  ];

  const tableChange = (e) => {
    let val = e.target.value;
    setTableKeys(val);
    switch (val) {
      case 2:
        setTableDataSource([
          ...homeTableList.filter((item) => item.actualProfitAmount > 0),
        ]);
        break;
      case 3:
        setTableDataSource([
          ...homeTableList.filter((item) => item.actualProfitAmount < 0),
        ]);
        break;
      default:
        setTableDataSource([...homeTableList]);
        break;
    }
    if (val == 1) {
    }
  };

  const extra = () => {
    return (
      <div style={{ marginBottom: 8 }}>
        <Radio.Group
          options={options}
          onChange={tableChange}
          value={tableKeys}
          optionType="button"
          size="small"
        />
      </div>
    );
  };

  return (
    <>
      <Tabs defaultActiveKey="1" type="card" onChange={setCurCheckedKey}>
        {tabsArr.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.value}></Tabs.TabPane>
        ))}
      </Tabs>
      <SearchForm
        columns={searchCloumns}
        onSubmit={onSubmit}
        key={curCheckedKey}
      />
      <AnalyseCharts {...dataAnalyse?.costChartData} />
      {/* TablePro */}
      <Row style={{ marginTop: 10 }}>
        <Col span={8}>
          <div className="home-card-title">项目执行情况</div>
          {extra()}
          <Table
            rowKey="projectId"
            columns={columns}
            dataSource={tableDataSource}
            pagination={{
              pageSize: 5,
              hideOnSinglePage: true,
            }}
          />
        </Col>
        <Col span={6}>
          <div className="home-card-title">项目执行分析</div>
          <div className="card-box">
            <div className="shadow-charts">
              <PieShade data={charts} />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div className="home-card-title">项目利润分析(实际)</div>
          <div className="card-box">
            <Bar />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default dataAnalyseCost;
