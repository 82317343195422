import { connect, history } from 'umi';
import JsonForm from '@/components/JsonForm';
import { createContext, useEffect, useRef, useState } from 'react';
import { Button, message } from 'kenshin';
import { getContractMount } from '@/_serveice/contract/list/index';
import {
  SelectOptionsTransform,
  beforeShowEditChangeData,
} from '@/pages/contract/list/util';
import {
  firstaddprocessapply,
  firstaddprocessapplycommit,
  updateprocessapply,
  updatecommitprocessapply,
} from '@/_serveice/admin/process';
import { projectCloumns, roleCloumns } from './_util/columns';
import {
  toOptions,
  TypeKey,
  findForArr,
  projectFirstSave,
  projectReSave,
  roleFirstSave,
  roleReSave,
} from './_util/util';
import { usePageEffect } from './_hook/usePageEffect';
import busiTypeKey from '../../_util/busiTypeKey';
const { CONTRACT, PROJECT, TASK, ROLE, OSAEDIT, WEEKEDIT } = busiTypeKey;
import TaskUtil from '../_unit/task';
import ContractUtil from '../_unit/contract';
import OsaEditUtil from '../_unit/osaEdit';

export const ServContext = createContext();

export const btnType = {
  SAVE: 'save',
  SUBMIT: 'submit',
};

const ChangePage = ({
  process,
  dispatch,
  project,
  system,
  systemOrgani,
  contract,
}) => {
  const [servCatgList, setServCatgList] = useState({});
  /** 业务类型 */
  const busiType = process.changeInfo.params?.busiType;
  /** 页面操作类型（首次、再次） */
  const enterType = history.location.query.type;
  const [customerOptions, setCustomerOptions] = useState();
  console.log('customerOptions', customerOptions);

  // 项目-删除的服务类目列表
  const [deleteList, setDeleteList] = useState([]);
  /** 项目金额最小值 */
  const projectMoneyStatus = useState(0);

  // 合同-剩余金额
  const [contractMoney, setContractMoney] = useState(0);
  // 保存按钮的加载状态
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  // 提交按钮的加载状态
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  /** form表单的ref */
  const ref = useRef();
  /** 当前的任务id */
  const curId = useRef(null);

  useEffect(() => {
    // 设置项目最小金额
    if (process.changeInfo?.data?.proServCatgDiscTotalAmount) {
      let money = process.changeInfo.data.proServCatgDiscTotalAmount;
      projectMoneyStatus[1](+(money / 100).toFixed(2));
    } else if (process.changeInfo?.data?.dbServCatgList) {
      let arr = process.changeInfo?.data?.dbServCatgList;
      let count = arr.reduce(
        (pre, cur) => pre + cur.servCatgDiscTotalAmount,
        0,
      );
      projectMoneyStatus[1](count);
    }
  }, []);

  /** 通过项目id获取服务类目 */
  const getServcatgForProjectId = (projectId, zero = true) => {
    if (servCatgList[projectId]) {
      return servCatgList[projectId];
    }
    curId.current = projectId;
    dispatch({
      type: 'project/getProServcatgTree',
      payload: {
        projectId,
      },
    }).then((data) => {
      if (zero) {
        const { form } = ref.current;
        form.setFieldsValue({ proServCatgId: undefined });
      }
      setServCatgList((list) => {
        list[projectId] = toOptions(data);
        return { ...list };
      });
    });
  };

  // 根据合同编号查询合同金额
  const contractChange = (key) => {
    if (key) {
      let contractId = key.split('-')?.[0];
      getContractMount({ contractId }).then((res) => {
        if (res?.success) {
          setContractMoney(res.data);
        }
      });
    }
  };

  /** 初渲染执行生命周期 */
  usePageEffect({
    process,
    dispatch,
    setCustomerOptions,
    SelectOptionsTransform,
    getServcatgForProjectId,
    customerOptions,
    systemOrgani,
    ref,
    system,
  });

  /** 根据业务类型选择表单JSON */
  const getColumnsForBusiType = {
    [TASK]() {
      // 任务
      return TaskUtil.formCloumns({
        getServcatgForProjectId,
        project,
        servCatgList,
        curId,
      });
    },
    [CONTRACT]() {
      // 合同
      return ContractUtil.formCloumns({
        SelectOptionsTransform,
        system,
        customerOptions,
        systemOrgani,
      });
    },
    [PROJECT]() {
      // 项目
      return projectCloumns({
        contract,
        systemOrgani,
        rowData: process.changeInfo.data,
        contractMoney,
        contractChange,
        projectMoneyStatus,
      });
    },
    [ROLE]() {
      // 项目
      return roleCloumns({});
    },
    [OSAEDIT]: () => OsaEditUtil.formCloumns(),
    [WEEKEDIT]: () => OsaEditUtil.formCloumns(),
  };

  /** 表单JSON数据 */
  const edtaFormItem = getColumnsForBusiType[busiType]?.() || [];

  /** 任务选择框根据id找到对应的字符串 */
  const getAfterStr = (values) => {
    const afterProjectName = project.userProjectSimpList.find(
      (item) => item.projectId == values.projectId,
    )?.projectName;

    const afterProServCatgName = findForArr(
      servCatgList[values.projectId],
      values.proServCatgId,
    )?.title;

    return {
      afterProjectName,
      afterProServCatgName,
    };
  };

  /** 第一次提交或者保存 */
  const saveOrSumbitFirst = (values, type) => {
    const saveType = {
      [TASK]() {
        return TaskUtil.firstSave(values, { process, getAfterStr });
      },
      [CONTRACT]() {
        let res = ContractUtil.firstSave(values, {
          process,
          systemOrgani,
          system,
          customerOptions,
        });
        if (res) {
          return res;
        }
      },
      [PROJECT]() {
        let res = projectFirstSave(
          values,
          {
            otherParams: process.changeInfo.params,
            detailData: process.changeInfo.data,
            formData: process.formData,
            deleteList,
            systemOrgani,
            contract,
          },
          type,
        );
        if (res) {
          return res;
        }
      },
      [ROLE]() {
        return roleFirstSave(values, { process });
      },
      [OSAEDIT]: () => OsaEditUtil.firstSave(),
      [WEEKEDIT]: () => OsaEditUtil.firstSave(),
    };
    return saveType[busiType]();
  };

  /** 再次保存或者提交 */
  const saveOrSumbit = (values, type) => {
    const saveType = {
      [TASK]() {
        return TaskUtil.reSave(values, { process, getAfterStr });
      },
      [CONTRACT]() {
        return ContractUtil.reSave(values, {
          process,
          systemOrgani,
          system,
          customerOptions,
        });
      },
      [PROJECT]() {
        let res = projectReSave(
          values,
          {
            otherParams: process.changeInfo.params,
            detailData: process.changeInfo.data,
            formData: process.formData,
            deleteList,
            systemOrgani,
            contract,
          },
          type,
        );
        if (res) {
          return res;
        }
      },
      [ROLE]() {
        return roleReSave(values, { process });
      },
      [OSAEDIT]: () => OsaEditUtil.reSave(),
      [WEEKEDIT]: () => OsaEditUtil.reSave(),
    };
    return saveType[busiType]();
  };

  /** 根据type参数选择保存api */
  const saveTypeFn = {
    [TypeKey.PROCESS](values, type) {
      let payload = saveOrSumbitFirst(values, type);
      if (payload) {
        setSaveBtnLoading(true);
        firstaddprocessapply(payload).then((res) => {
          setSaveBtnLoading(false);
          if (res.success) {
            message.success('保存成功');
            handleCancel();
          }
        });
      }
    },
    [TypeKey.EDITPROCESS](values, type) {
      let payload = saveOrSumbit(values, type);
      if (payload) {
        setSaveBtnLoading(true);
        updateprocessapply(payload).then((res) => {
          setSaveBtnLoading(false);
          if (res.success) {
            message.success('保存成功');
            handleCancel();
          }
        });
      }
    },
  };

  /** 提交 */
  const handleSubmit = () => {
    const { form } = ref.current;
    form.validateFields().then((values) => {
      if (enterType == TypeKey.PROCESS) {
        let payload = saveOrSumbitFirst(values, btnType.SUBMIT);
        if (payload) {
          setSubmitBtnLoading(true);
          firstaddprocessapplycommit(payload).then((res) => {
            setSubmitBtnLoading(false);
            if (res.success) {
              message.success('提交成功');
              handleCancel();
            }
          });
        }
      } else {
        let payload = saveOrSumbit(values, btnType.SUBMIT);
        if (payload) {
          setSubmitBtnLoading(true);
          updatecommitprocessapply(payload).then((res) => {
            setSubmitBtnLoading(false);
            if (res.success) {
              message.success('提交成功');
              handleCancel();
            }
          });
        }
      }
    });
  };

  /** 保存 */
  const handleSave = () => {
    const { form } = ref.current;
    form.validateFields().then((values) => {
      if (enterType) {
        return saveTypeFn[enterType](values, btnType.SAVE);
      }
    });
  };
  const handleCancel = () => {
    history.go(-1);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>变更信息</h3>
        <div>
          {(enterType == TypeKey.PROCESS ||
            enterType == TypeKey.EDITPROCESS) && (
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={submitBtnLoading}
            >
              提交
            </Button>
          )}
          <Button type="primary" onClick={handleSave} loading={saveBtnLoading}>
            保存
          </Button>
          <Button onClick={handleCancel}>返回</Button>
        </div>
      </div>
      <ServContext.Provider
        value={{
          setDeleteList,
        }}
      >
        <JsonForm columns={edtaFormItem} ref={ref} />
      </ServContext.Provider>
    </div>
  );
};

export default connect(
  ({ process, project, system, systemOrgani, contract }) => ({
    process,
    project,
    system,
    systemOrgani,
    contract,
  }),
)(ChangePage);
