import {
  Input,
  Form,
  Row,
  Col,
  Select,
  InputNumber,
  Card,
  Button,
  message,
} from 'kenshin';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'umi';
import ItemDetail from './itemDetail';
import ModalFormPro from '../ModalFormPro';
import '../ServCategoryList/index.less';
import { dicLType } from '@/_util/keysType';
import { useCreation } from 'ahooks';

const ServCategoryList = ({ onChange, value, customer }) => {
  const [dataList, setDataList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const custTagList = useMemo(
    () =>
      customer.customerTagList?.map((item) => ({
        label: item.value,
        value: item.id + '',
      })),
    [customer.customerTagList],
  );

  const addItem = () => {
    setEditModalVisible(true);
  };

  const editChange = (val) => {
    const { contBorn } = val;
    let bornReg = /^(\d{4}-)?\d{1,2}-{1}\d{1,2}$/;
    if (contBorn) {
      if (!bornReg.test(contBorn)) {
        message.warn('生日格式错误，应为：2022-01-01或者01-01');
        return;
      }
      let bornDate = contBorn.split('-');
      if (bornDate.length == 3) {
        val.contBornYear = bornDate[0];
        val.contBornMoth = bornDate[1].padStart(2, '0');
        val.contBornDay = bornDate[2].padStart(2, '0');
      } else if (bornDate.length == 2) {
        val.contBornMoth = bornDate[0].padStart(2, '0');
        val.contBornDay = bornDate[1].padStart(2, '0');
        val.contBornYear = null;
      }
    }
    val.contBorn = undefined;
    if (editModalData?.key || editModalData?.custContId) {
      Object.assign(editModalData, { ...val });
      setDataList([...dataList]);
      onChange?.(dataList);

      setEditModalVisible(false);
      setEditModalData(null);
      return;
    }

    let otherParams = {
      key: +new Date(),
      time: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    dataList.push({ ...otherParams, ...val });
    setDataList([...dataList]);
    onChange?.(dataList);
    setEditModalVisible(false);
  };

  useEffect(() => {
    if (value) {
      setDataList([...value]);
    }
  }, [value]);

  const edtaFormItem = [
    {
      label: '姓名',
      name: 'contName',
      type: 'Input',
      required:"请输入姓名",
      props: {
        placeholder: '请输入姓名',
      },
    },
    {
      label: '职位',
      name: 'contJob',
      type: 'Input',
      props: {
        placeholder: '请输入职位',
      },
    },
    {
      label: '联系方式',
      name: 'contMobile',
      type: 'Input',
      props: {
        placeholder: '请输入联系方式，逗号隔开',
      },
    },
    {
      label: '邮箱',
      name: 'contEmail',
      type: 'Input',
      props: {
        placeholder: '请输入邮箱',
      },
    },
    {
      label: '人物标签',
      name: 'tagIdList',
      type: 'Select',
      props: {
        mode: 'multiple',
        placeholder: '请选择人物标签',
        allowClear: true,
        options: custTagList,
      },
    },
    {
      label: '直属上司',
      name: 'contImedtSuper',
      type: 'Input',
      props: {
        placeholder: '请输入直属上司',
      },
    },
    {
      label: '所属地',
      name: 'cont',
      type: 'AddressSelect',
      props: {},
    },
    {
      label: '生日',
      name: 'contBorn',
      type: 'Input',
      tooltip: '生日格式：2022-01-01，或者1-1，以-隔开',
      props: {
        placeholder: '请输入生日,以-号隔开',
      },
    },
    {
      label: '性别',
      name: 'contSex',
      type: 'RadioGroup',
      props: {
        options: [
          { label: '男', value: 1 },
          { label: '女', value: 2 },
          { label: '未知', value: 3 },
        ],
      },
    },
    {
      label: '是否关键角色',
      name: 'contRoleLevel',
      type: 'RadioGroup',
      props: {
        options: [
          { label: '非常关键', value: 1 },
          { label: '一般关键', value: 2 },
          { label: '不关键', value: 3 },
        ],
      },
    },
    {
      label: '详情描述',
      name: 'contRemark',
      type: 'TextArea',
      formItemProps: {
        className: 'block-item',
      },
      props: {
        placeholder: '请输入详情描述',
      },
    },
  ];

  const modalRef = useRef();

  const editItem = (row) => {
    const { form } = modalRef.current;
    setEditModalVisible(true);
    row.cont = row.cont || [row.contCountry, row.contProvince, row.contArea];
    row.contBorn =
      row.contBorn ||
      [row.contBornYear, row.contBornMoth, row.contBornDay]
        .filter(Boolean)
        .join('-');
    setEditModalData(row);
    form.setFieldsValue(row);
  };

  return (
    <div className="CustomerInfo">
      {dataList.map((item, index) => {
        let calHeaderl = (
          <Row className="detail-box">
            <Col span={5}>
              对接人：
              <span className="c999">{item.contName}</span>
            </Col>
            <Col span={5}>
              职位：
              <span className="c999">{item.contJob}</span>
            </Col>
            <Col span={5}>
              联系方式：
              <span className="c999">{item.contMobile}</span>
            </Col>
          </Row>
        );
        return (
          <ItemDetail
            headerEl={calHeaderl}
            data={item}
            custTagList={custTagList}
            setDataList={setDataList}
            editItem={editItem}
            onChange={onChange}
            key={index}
            onCancel={() =>
              setDataList((pre) => {
                pre.splice(index, 1);
                onChange?.(pre);
                return [...pre];
              })
            }
          />
        );
      })}
      <ModalFormPro
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        width={1000}
        ref={modalRef}
        formProps={{
          layout: 'vertical',
          labelCol: { span: 8 },
          wrapperCol: { span: 12 },
          className: 'inline-form',
        }}
        onSave={editChange}
        onCancel={() => {
          setEditModalVisible(false);
          setEditModalData(null);
        }}
      />
      <div className="dashedBtn" onClick={addItem}>
        + 添加
      </div>
    </div>
  );
};

export default connect(({ customer }) => ({ customer }))(ServCategoryList);
