export const titleArr = [
  {
    v: '关键绩效目标（KPI）',
    ct: {
      fa: 'General',
      t: 'g',
    },
    m: '关键绩效目标（KPI）',
  },
  {
    v: '指标描述',
    ct: {
      fa: 'General',
      t: 'g',
    },
    m: '指标描述',
  },
  {
    v: '目标值',
    ct: {
      fa: 'General',
      t: 'g',
    },
    m: '目标值',
  },
  {
    v: '单位',
    ct: {
      fa: 'General',
      t: 'g',
    },
    m: '单位',
  },
  {
    v: '权重',
    ct: {
      fa: '@',
      t: 's',
    },
    m: '权重',
  },
];

/** 添加备注 */
export function addRemark(titleArr) {
  if (titleArr?.length == 5) {
    titleArr.push({
      v: '备注',
      ct: {
        fa: '@',
        t: 'g',
      },
      m: '备注',
    });
  }
}

/** 扁平化树结构 */
function flattenTree(tree) {
  let arr = [];
  tree?.forEach((item) => {
    arr.push(item);
    if (item.childList) {
      arr.push(...flattenTree(item.childList));
    }
  });
  return arr;
}

/** 将每一项的对象数据转换成excel二维数组 */
function renderRowData(obj, index, arr, $importData) {
  let res = [
    `${obj.code}${obj.osaIndexCatgName ?? obj.osaIndexItemName}`,
    obj.osaIndexItemGoalValue,
    obj.osaIndexItemGoalValueUnit,
    obj.weight,
    obj.remark,
  ].map((item, c) => ({
    c,
    tb: '2',
    r: index,
    v: item,
    m: item,
    ct: { fa: 'General', t: 'g' },
  }));

  const sliceCode = (obj) =>
    obj.code.slice(0, obj.code.indexOf('.')) || obj.code; // 通过裁减获取根code,例：1.2.3 => 1
  let code = sliceCode(obj);
  let onceIndex = arr.findIndex((item) => sliceCode(item) == code);
  let length = arr.filter((item) => sliceCode(item) == code).length;
  let curKei = $importData.find((item) => item.code == code);
  let v = `${code}${
    curKei?.osaIndexCatgName ?? curKei?.osaIndexItemName ?? ''
  }`;
  if (index == onceIndex) {
    res.unshift({
      v,
      m: v,
      ct: { fa: 'General', t: 'g' },
      mc: {
        rs: length,
        cs: 1,
        r: onceIndex + 1,
        c: 0,
      },
    });
  } else {
    res.unshift({
      mc: {
        r: index,
        c: 0,
      },
    });
  }
  return res;
}

export function renderImportData(tree) {
  let flatArr = tree.map((item) => flattenTree(item.childList)).flat(2);
  let onceArr = tree.filter((item) => !item.childList);
  let resArr = [...flatArr, ...onceArr].sort((a, b) => a.code - b.code);
  let result = resArr.map((item, index) => {
    let arr = renderRowData(item, index, resArr, tree);
    return [...arr];
  });
  return result;
}

function delKey(keyArr, obj) {
  keyArr.forEach((key) => {
    obj[key] = undefined;
    delete obj[key];
  });
}

export function generateTree(items, isMonth = false) {
  console.log('generateTree=====1', items);
  const result = [];
  const itemMap = {};

  for (const item of items) {
    const code = item.code;

    const newItem = {
      osaIndexItemName: item.value, //  绩效指标条目名称
      osaIndexItemGoalValueSource: 1, //目标值来源
      osaIndexItemGoalValue: item.osaIndexItemGoalValue, //目标值
      osaIndexItemModel: item.osaIndexItemModel, //条目模式：1：定量；2：定性；
      osaIndexItemGoalValueUnit: item.osaIndexItemGoalValueUnit, // 目标值单位;
      osaIndexItemActualValueUnit: item.osaIndexItemGoalValueUnit, // 实际值单位
      code: code,
      osaIndexCatgName: item.value,
      osaIndexItemName: item.value,
      weight: item.weight,
      remark: item.remark,
      type: 2,
      childList: [],
    };

    itemMap[code] = newItem;

    const parentCode = code.substring(0, code.lastIndexOf('.'));

    if (parentCode in itemMap) {
      // 目录
      itemMap[parentCode].type = 1; //类型：1、目录，2、条目
      itemMap[parentCode].osaIndexNum =
        itemMap[parentCode].childList.push(newItem); // 指标数量
      newItem.sort = itemMap[parentCode].childList.length;
      // delKey(
      //   [
      //     'osaIndexItemGoalValueSource',
      //     'osaIndexItemName',
      //     'osaIndexItemGoalValue',
      //     'osaIndexItemModel',
      //     'osaIndexItemGoalValueUnit',
      //     'osaIndexItemActualValueUnit',
      //   ],
      //   itemMap[parentCode],
      // );
    } else {
      if (newItem.childList.length > 0) {
        newItem.type = 1; //类型：1、目录，2、条目
        newItem.osaIndexNum = newItem.childList.push(newItem); // 指标数量
        delKey(
          [
            'osaIndexItemGoalValueSource',
            'osaIndexItemName',
            'osaIndexItemGoalValue',
            'osaIndexItemModel',
            'osaIndexItemGoalValueUnit',
            'osaIndexItemActualValueUnit',
          ],
          newItem,
        );
      }
      newItem.sort = result.length + 1;
      result.push(newItem);
    }
  }

  if (isMonth) {
    result.map((item) => {
      if (['1', '2', '3'].includes(item.code)) {
        item.osaIndexCatgName = item.osaIndexCatgName.trim();
      }
    });
  }

  console.log('generateTree=====2', result);
  return result;
}

function excelMap(index) {
  return (data) => {
    if (data[index] && data.filter(Boolean).length > 0) {
      let str = data[index].v || data[index].ct?.s?.[0]?.v;
      let weight = data[4]?.v;
      let osaIndexItemGoalValueUnit = data[3]?.v;
      let osaIndexItemGoalValue = data[2]?.v;
      let osaIndexItemModel = osaIndexItemGoalValue ? 1 : 2;

      let remark = undefined;
      if (data?.length >= 6 && index != 0) {
        remark = data[5]?.v;
      }

      console.log('excelMap:======', data);

      if (str) {
        let code = str.toString().match(codeRegExp)?.[0];
        let value = str.toString().slice(code?.length ?? 0);
        return {
          remark,
          code,
          value,
          weight,
          osaIndexItemGoalValue,
          osaIndexItemModel,
          osaIndexItemGoalValueUnit,
          osaIndexItemActualValueUnit: osaIndexItemGoalValueUnit,
        };
      }
    }
  };
}

export const codeRegExp = /^\d+(\.\d+)*/;
export function getTree(sheetArrr) {
  let childTree = sheetArrr.slice(1).map(excelMap(1)).filter(Boolean);
  let parent = sheetArrr.slice(1).map(excelMap(0)).filter(Boolean);
  return [...parent, ...childTree].filter((value, index, self) => {
    return self.findIndex((item) => item.value == value.value) == index;
  });
}

export function setOsaIndexRemainWeight(arr) {
  arr.forEach((item) => {
    if (item.childList?.length > 0) {
      setOsaIndexRemainWeight(item.childList);
      item.osaIndexRemainWeight =
        item.weight - item.childList.reduce((pre, cur) => pre + cur.weight, 0);
    }
  });
}

/** 校验code必须存在并且不重复 */
export function hasDuplicateCodes(arr) {
  const codeMap = {};
  for (let i = 0; i < arr.length; i++) {
    const code = arr[i].code;
    if (code === undefined || code === null || code === '') {
      // 如果code不存在或为空，则返回true
      return arr[i];
    }
    if (codeMap[code]) {
      // 如果code已经在codeMap中出现过，则说明存在重复的code
      return arr[i];
    } else {
      // 在codeMap中记录该code已经出现过
      codeMap[code] = arr[i];
    }
  }
  // 遍历完整个数组后没有发现重复的code，返回false
  return false;
}

export function findToSelect(value) {
  const { column, row } = window.luckysheet.find(value)?.[0] ?? {};
  window.luckysheet.setRangeShow({ row: [row], column: [column] });
}

export function checkWeight(tree) {
  let arr = flattenTree(tree);
  let errObj = arr.find((item) => item.osaIndexRemainWeight < 0);
  return errObj;
}
