import {
  Dropdown,
  message,
  Badge,
  Tooltip,
  Progress,
  Avatar,
  Button,
} from 'kenshin';
import { history } from 'umi';
import { connect } from 'umi';
import { useCallback, useMemo, useRef, useState } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import { encrypt } from '@/pages/user/_util';
import {
  DownOutlined,
  CarryOutFilled,
  BellFilled,
  AuditOutlined,
} from '@ant-design/icons';
import { localStorageList, OSSImgUrl } from '../../_util/util';
import { updateUserInfo } from '../../_serveice/admin/system';
import { QuestionCircleOutlined } from '@ant-design/icons';

function clearService() {
  return navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
}

const helpLinkList = [
  {
    name: '员工',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAoa5kU1PKYRredPTl45p?scode=AAcAAgebAA4Oj0EBmeAB0AKQZCAAo',
  },
  {
    name: '总裁',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAo0QRFfADJRKi1JsqLt1?scode=AAcAAgebAA4anMiZl7',
  },
  {
    name: '财务',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAocaCkNz3nRAaxhmSXvd?scode=AAcAAgebAA41sghHvR',
  },
  {
    name: '高层管理',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAoPANybJF6SIa6vOLRcL?scode=AAcAAgebAA4fqK2EKI',
  },
  {
    name: '职能管理层',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAoY30xUrXCQDWlugv8oC?scode=AAcAAgebAA4xMmSoYy',
  },
  {
    name: '人事',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAogldd8sLATzelC1Y1sf?scode=AAcAAgebAA4e7vdT0q',
  },
  {
    name: '商务',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAofAgpnQ1PR5OtAXK1wK?scode=AAcAAgebAA4ylQp2ec',
  },
  {
    name: '项目经理',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAo1KVP8buKTfyUY7ExJz?scode=AAcAAgebAA4GrEZnCd',
  },
  {
    name: '项目统筹',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAoDgBi43uNTtSr6uUnJN?scode=AAcAAgebAA4Q0ujZ2Q',
  },
  {
    name: '项目助理',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAoAo9K0Jo3QkqwQo9gKd?scode=AAcAAgebAA4pfQPs2S',
  },
  {
    name: '运维人员',
    link: 'https://doc.weixin.qq.com/doc/w3_AB0AKQZCAAoDcsuiUmNSUepv0H1xV?scode=AAcAAgebAA4u1t1XFQ',
  },
];

const Header = ({ dispatch }) => {
  // 重置密码
  const [resetPWdModalVisible, setResetPWdModalVisible] = useState(false);
  const [userInfoVisible, setUserInfoVisible] = useState(false);

  const userInfoRef = useRef();
  const showUserInfoModal = () => {
    let employeeDTO = JSON.parse(localStorage.getItem('employeeDTO'));
    setUserInfoVisible(true);
    userInfoRef.current?.form.setFieldsValue(employeeDTO);
  };

  const resetNotice = async () => {
    await clearService();
    location.reload();
  };

  const Menu = (
    <Dropdown.Menu>
      <Dropdown.Item>
        <div onClick={resetNotice}>重置通知功能</div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div onClick={showUserInfoModal}>修改个人信息</div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div
          onClick={() => {
            setResetPWdModalVisible(true);
          }}
        >
          修改密码
        </div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div
          onClick={() => {
            localStorageList.map((item) => {
              localStorage.removeItem(item);
            });
            history.push('/user/Login');
            dispatch({
              type: 'usercenter/save',
              payload: {
                homeEmpWorkRptList: {
                  key: [],
                  list: [],
                },
              },
            });
          }}
        >
          退出登录
        </div>
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  // 操作密码
  const resetPwdCloums = [
    {
      label: '旧密码',
      name: 'oldpwd',
      type: 'Password',
      required: '请输入密码',
      props: {
        placeholder: '请输入密码',
      },
    },
    {
      label: '新密码',
      name: 'newpwd',
      type: 'Password',
      required: '请输入密码',
      props: {
        placeholder: '请输入密码',
      },
      formItemProps: {
        hasFeedback: true,
      },
    },
    {
      label: '确认新密码',
      name: 'returnNewpwd',
      type: 'Password',
      props: {
        placeholder: '请输入密码',
      },
      formItemProps: {
        hasFeedback: true,
        rules: [
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newpwd') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('两次输入的密码不一致！'));
            },
          }),
        ],
      },
    },
  ];

  const uploadAccept = 'image/*';
  const userInfoEditItem = [
    // 个人信息
    {
      label: '邮箱',
      name: 'empEmail',
      type: 'Input',
      required: '请输入邮箱',
      props: {
        placeholder: '请输入邮箱',
      },
    },
    {
      label: '联系电话',
      name: 'empMobile',
      type: 'Input',
      required: '请输入联系电话',
      props: {
        placeholder: '请输入联系电话',
      },
    },
    {
      label: '员工头像',
      name: 'empHeadImg',
      type: 'UploadImg',
      props: {
        dirUrl: '/employee/avatar',
        accept: uploadAccept,
      },
    },
  ];

  const userInfoSave = (val) => {
    updateUserInfo(val).then((res) => {
      if (res.success) {
        setUserInfoVisible(false);
        message.success('操作成功');
      }
    });
  };
  const resetPwdSave = (value) => {
    dispatch({
      type: 'system/sysupdatepwd',
      payload: {
        muIdString: localStorage.getItem('muId'),
        newEncrpyptPwD: encrypt(value.newpwd),
        oldEncrpyptPwd: encrypt(value.oldpwd),
      },
    }).then((res) => {
      if (res) {
        setResetPWdModalVisible(false);
        message.success('修改成功');
      }
    });
  };

  const wordTooltipTest = useCallback((type) => {
    return (
      <div className="tooltip-content">
        <div className="title">
          任务（5个进行中）
          <Button type="text" size="small">
            全部标记为已读
          </Button>
        </div>
        <div className="tooltip-item">
          <AuditOutlined />
          <div className="data">
            <div>
              数据上传备份
              <div className="flr">马上到期</div>
              <div className="tip-text">
                紫阳 提交于2022-01-06，需在2022-01-07千完成备份任务
              </div>
              <Progress percentage={60} strokeWidth={4} />
            </div>
          </div>
        </div>
        <div className="foot-btn">查看全部任务</div>
      </div>
    );
  }, []);

  const wordTooltip = useMemo(
    () => (
      <div className="tooltip-content">
        <div className="title">
          任务（5个进行中）
          <Button type="text" size="small">
            全部标记为已读
          </Button>
        </div>
        <div className="tooltip-item">
          <AuditOutlined />
          <div className="data">
            <div>
              数据上传备份
              <div className="flr">马上到期</div>
              <div className="tip-text">
                紫阳 提交于2022-01-06，需在2022-01-07千完成备份任务
              </div>
              <Progress percentage={60} strokeWidth={4} />
            </div>
          </div>
        </div>
        <div className="foot-btn">查看全部任务</div>
      </div>
    ),
    [],
  );

  const informTooltip = useMemo(
    () => (
      <div className="tooltip-content">
        <div className="title">
          通知（5个未读）
          <Button type="text" size="small">
            全部标记为已读
          </Button>
        </div>
        <div className="tooltip-item">
          <AuditOutlined />
          <div className="data">
            <div>
              2月1日你的工作上报审核不通过
              <div className="tip-text">5分钟前</div>
            </div>
          </div>
        </div>
        <div className="foot-btn">查看全部通知</div>
      </div>
    ),
    [],
  );
  return (
    <div className="header">
      <div className="h-left">
        <div className="logo">
          <img src={require('@/_assets/logo.png')} />
        </div>
        微数平台
      </div>
      <div className="h-right">
        {/* <Tooltip
          title={wordTooltip}
          overlayStyle={{
            color: '#333',
            minWidth: 200,
            maxWidth: 400,
            fontSize: 12,
            lineHeight: '24px',
          }}
          color="#fff"
        >
          <Badge count={14} size="small">
            <CarryOutFilled />
          </Badge>
        </Tooltip>
        <Tooltip
          title={informTooltip}
          overlayStyle={{
            color: '#333',
            minWidth: 300,
            maxWidth: 400,
            fontSize: 12,
            lineHeight: '24px',
          }}
          color="#fff"
        >
          <Badge count={10} size="small">
            <BellFilled />
          </Badge>
        </Tooltip> */}
        <Tooltip
          title={
            <>
              {helpLinkList.map((item) => (
                <div key={item.name} style={{ marginBottom: 10 }}>
                  <a href={item.link} target="_blank" style={{ color: '#fff' }}>
                    {item.name}操作指南
                  </a>
                </div>
              ))}
            </>
          }
        >
          <QuestionCircleOutlined
            style={{ fontSize: '32px', color: '#333' }}
            className="help"
          />
        </Tooltip>
        <Dropdown
          menu={Menu}
          trigger="click"
          getPopupContainer={() => document.body}
        >
          <div className="right-flex-box">
            <Avatar
              src={
                OSSImgUrl(
                  JSON.parse(localStorage.getItem('employeeDTO'))?.empHeadImg,
                ) || 'https://joeschmoe.io/api/v1/random'
              }
            />
            <div className="userName">
              {JSON.parse(localStorage.getItem('employeeDTO'))?.empName ??
                '未知用户'}
              <DownOutlined />
            </div>
          </div>
        </Dropdown>
      </div>

      <ModalFormPro
        title="修改密码"
        visible={resetPWdModalVisible}
        edtaFormItem={resetPwdCloums}
        onSave={resetPwdSave}
        onCancel={() => {
          setResetPWdModalVisible(false);
        }}
      />

      <ModalFormPro
        title="修改个人信息"
        ref={userInfoRef}
        visible={userInfoVisible}
        edtaFormItem={userInfoEditItem}
        onSave={userInfoSave}
        onCancel={() => {
          setUserInfoVisible(false);
        }}
      />
    </div>
  );
};
export default connect()(Header);
