import request, { baseUrl } from '../../_util/openaiRequire';
import baserequest from '../../_util/require';
let version = 'v1';
/** 新增历史聊天(增量增加) */
export async function addChatHistory(sid, params) {
  return baserequest(`/${version}/waper/erp/pc/chatgpt/addChatHistory/${sid}`, {
    method: 'POST',
    data: params,
  });
}
/** 新增历史聊天(增量增加) */
export async function chat(sid, params) {
  return request(`/openai/chat/chat/${sid}`, {
    method: 'POST',
    data: params,
  });
}
/** 新增历史聊天(增量增加) */
export async function getChatHistoryBySid(sid) {
  return baserequest(
    `/${version}/waper/erp/pc/chatgpt/getChatHistoryBySid/${sid}`,
    {
      method: 'GET',
    },
  );
}
/** 查询用户会话列表 */
export async function getUserSessionList(sid) {
  return baserequest(`/${version}/waper/erp/pc/chatgpt/getUserSessionList`, {
    method: 'GET',
  });
}
/** 查询用户会话列表 */
export async function updateSessionName(sid, name) {
  return baserequest(
    `/${version}/waper/erp/pc/chatgpt/updateSessionName/${sid}?sName=${name}`,
    {
      method: 'GET',
    },
  );
}
/** 查询用户会话列表 */
export async function clearSessionList(options) {
  return request(`/openai/chat/clearSessionList`, {
    method: 'POST',
    data: options,
  });
}
/** 查询用户会话列表 */
export const createSse = (sid, name) =>
  `${baseUrl}/openai/chat/createSse/${sid}?sName=${name}`;

/** 生成图片 */
export async function genimages(params) {
  return request(`/openai/commont/genimages`, {
    method: 'GET',
    params,
  });
}

/** 编辑文本 */
export async function edittext(options) {
  return request(`/openai/commont/edittext`, {
    method: 'POST',
    data: options,
  });
}
/** 查询余额 */
export async function getbillinfo() {
  return request(`/openai/commont/getbillinfo`, {
    method: 'GET',
  });
}
/** 查询余额 */
export async function createsession(sid, params) {
  return request(`/openai/commont/createsession/${sid}`, {
    method: 'GET',
    params,
  });
}
