import {
  Button,
  Card,
  Col,
  MessageBox,
  message,
  Tooltip,
  Alert,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { useEffect, useState, useRef } from 'react';
import renderProcessList from '../components/ProcessList';
import ModalFormPro from '@/components/ModalFormPro';
import JsonForm from '@/components/JsonForm';
import { deepClone, debounce } from '@/_util/util';
import {
  getAllEmployee,
  addupdateclocknoticetask,
  getclocknoticetask,
} from '@/_serveice/admin/system';
let applyStatusStr = {};

let time = '';
let taskId = '';
let taskCancelNoticeUserList = '';
const MyAttendanceApply = ({ attendance, dispatch, systemOrgani }) => {
  const [employeeList, setNewEmployeeList] = useState([]);
  const [selectEmployeeList, setSelectEmployeeList] = useState([]);
  const [taskNoticeTime, setTaskNoticeTime] = useState([]);
  const ref = useRef();

  useEffect(() => {
    time = '';
    taskId = '';
    taskCancelNoticeUserList = '';

    // 组织架构
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      deepClone(res);
      console.log('getAllEmployee', deepClone(res));
      setNewEmployeeList(res);
    });
  }, []);

  useEffect(() => {
    if (employeeList) {
      getclocknoticetaskApi();
    }
  }, [employeeList]);

  useEffect(() => {
    console.log('selectEmployeeList', selectEmployeeList);
  }, [selectEmployeeList]);

  const handleSave = () => {
    const { form } = ref.current;
    form.validateFields().then(async (values) => {
      let params = {
        taskNoticeTime: time,
        taskId: taskId ? taskId : undefined,
        taskCancelNoticeUserList: taskCancelNoticeUserList,
      };

      console.log('请求参数：', params);

      dispatch({
        type: 'systemOrgani/addupdateclocknoticetask',
        hasEdit: false,
        payload: params,
      }).then((res) => {
        console.log('请求参数：', res);
        if (res) {
          // if (res.success) {
          message.success('更新成功！');
          // }

          getclocknoticetaskApi();
        }
      });
    });
  };

  function findValues(jsonArray, targetValues) {
    // Flatten the array to get all options in a single level
    const flattened = jsonArray.flatMap((dept) => dept.options || []);

    // Filter the flattened array based on the target values
    return flattened.filter((option) => {
      // console.log("findValues....",targetValues,option.value.split('-')[1]);
      return targetValues.includes(option.value.split('-')[1]);
    });
  }

  const getclocknoticetaskApi = () => {
    getclocknoticetask().then((res) => {
      if (res && res.data) {
        taskId = res.data.taskId;
        time = res.data.taskNoticeTime;

        taskCancelNoticeUserList = res.data.taskCancelNoticeUserList;
        if (taskCancelNoticeUserList) {
          var arr = taskCancelNoticeUserList.split(',').map(String);
          console.log(
            '不打卡人员名单。。。',
            findValues(deepClone(employeeList), arr),
            deepClone(res.data.taskNoticeTime),
          );
          // setSelectEmployeeList(findValues(deepClone(employeeList), arr));
          // setSelectEmployeeList(['4-27', '4-39', '4-42']);

          const { form } = ref.current;
          // form.setFieldsValue({ servCatgHourOrginCost: 1800 });
          form.setFieldsValue({
            addTeamMemberList: findValues(deepClone(employeeList), arr),
            // taskNoticeTime: res.data.taskNoticeTime
          });

          setTaskNoticeTime(res.data.taskNoticeTime);

          form.setFieldsValue({
            taskNoticeTime: moment(res.data.taskNoticeTime, 'HH:mm:ss'),
          });

          // console.log("不打卡人员名单。。。", findValues(deepClone(employeeList), arr), arr);
        }
      }
    });
  };

  const timeSelectChange = (value) => {
    if (value) {
      time = moment(value).format('HH:mm:ss');
    }
  };

  const selectChange = (value) => {
    console.log('selectChange....', value);
    if (value) {
      taskCancelNoticeUserList = value
        .map((item) => item.split('-')[1])
        .join(',');
    }
  };

  const edtaFormItem = [
    {
      render: () => (
        <Alert
          style={{ width: '77%', marginLeft: '13%', marginRight: '10%' }}
          title="考勤豁免人员设置(豁免人员无需打卡，不推送打卡消息，无需统计)"
          type="info"
          closable={false}
        />
      ),
      formItemProps: {
        wrapperCol: 50,
      },
    },
    {
      title: '豁免员工',
      dataIndex: 'addTeamMemberList',
      valueType: 'Select',
      required: '请选择豁免员工',
      fieldProps: {
        // defaultValue:['4-27', '4-39', '4-42'],
        // defaultValue:selectEmployeeList,
        placeholder: '请选择豁免员工',
        mode: 'multiple',
        options: deepClone(employeeList),
        onChange: selectChange,
        // disabled: disabled || isEndDisabled,
      },
    },

    // {
    //   title: '豁免部门',
    //   dataIndex: 'DeptSelect',
    //   valueType: 'DeptSelect',
    //   required: '请选择豁免部门',
    //   fieldProps: {
    //     defaultValue: ['1'],
    //     options: [
    //       { label: '是', value: 1, remark: '' },
    //       { label: '否', value: 0, remark: '' },
    //     ],
    //     placeholder: '请选择豁免部门',
    //     // onChange: typeSelectChange,
    //     // disabled: disabled || isEndDisabled,
    //   },
    // },
    {
      render: () => (
        <Alert
          style={{ width: '77%', marginLeft: '13%', marginRight: '10%' }}
          title="提醒打卡时间设置"
          type="info"
          closable={false}
        />
      ),
      formItemProps: {
        wrapperCol: 50,
      },
    },

    {
      title: '设置时间',
      dataIndex: 'taskNoticeTime',
      valueType: 'DatePicker',
      required: '请选择时间',
      fieldProps: {
        picker: 'time',
        placeholder: '请选择时间',
        onChange: timeSelectChange,
        // disabled: disabled || isEndDisabled,
      },
    },
    {
      render: () => (
        <div
          style={{
            width: '90%',
            textAlign: 'right',
          }}
        >
          <Button
            type="primary"
            onClick={handleSave}
            // disabled={btnDisabled}
            // loading={state.loading}
          >
            保存
          </Button>
        </div>
      ),
      formItemProps: {
        wrapperCol: 50,
      },
    },
  ];

  const handleToApply = (row = {}, path = 'alllist/addApply') => {
    history.push({
      pathname: '/attendance/' + path,
      query: {
        ...row,
      },
    });
  };

  const extra = () => {
    return (
      <Button type="primary" onClick={() => handleExport()}>
        导出
      </Button>
    );
  };

  return (
    <div>
      <JsonForm columns={edtaFormItem} ref={ref} />
    </div>
  );
};

export default connect(({ attendance, loading, systemOrgani }) => ({
  attendance,
  systemOrgani,
  loading: loading.effects,
}))(MyAttendanceApply);
