import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Input, MessageBox, message, Row } from 'kenshin';
import { connect, history } from 'umi';
import TablePro from '@/components/TablePro';
import busiTypeKey from '../_util/busiTypeKey';
const { OSA, WEEK, OSAEDIT, WEEKEDIT } = busiTypeKey;

const muid = localStorage.getItem('muId');

const showReturnBtn = (row) => {
  let hideKey = [OSA, WEEK, OSAEDIT, WEEKEDIT];
  if (hideKey.includes(row.todoItemBusiType)) {
    return false;
  }
  return row.isNextNodeDeal == 0 && row.dataChange?.dataChangeStatus != 4;
};

const ProcessList = ({ process, dispatch }) => {
  const tableRef = useRef();

  /** 详细 */
  const diffDetailClick = (row) => {
    dispatch({
      type: 'usercenter/save',
      payload: {
        createProcessData: row,
      },
    });
    history.push('/process/businessLog/ChangeRecord');
  };

  /** 撤销点击 */
  const handleUndo = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认撤销？撤销后无法恢复',
      onOk: () => {
        dispatch({
          type: 'process/completerevoke',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('撤销成功');
          }
        });
      },
    });
  };
  // 表头数据
  const columnsObj = {
    columns: [
      {
        dataIndex: 'todoItemName',
        title: '业务名称',
      },
      {
        dataIndex: 'todoItemBusiType',
        title: '业务类型',
        render: (val) => process.processTypeStrObj[val],
      },
      {
        dataIndex: 'gmtCreate',
        title: '提交时间',
        render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        dataIndex: 'commitMuname',
        title: '提交人',
      },
      {
        key: 'options',
        title: '操作',
        render: (row) => {
          return (
            <>
              <Button type="text" onClick={() => diffDetailClick(row)}>
                详情
              </Button>
              {showReturnBtn(row) && (
                <Button
                  style={{ color: 'red' }}
                  type="text"
                  onClick={() =>
                    handleUndo({ completeItemId: row.completeItemId })
                  }
                >
                  撤销
                </Button>
              )}
            </>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '关键词',
      },
    },
    {
      name: 'busiType',
      type: 'Select',
      props: {
        placeholder: '业务类型',
        options: process.processTypeOptions,
      },
    },
  ];

  /** 表格拦截 */
  const onSubmit = (payload) => {};

  return (
    <div>
      <TablePro
        request="process/getcompleteitempage"
        searchCloumns={searchCloumns}
        rowKey={'todoItemId'}
        data={process.busListLog}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={onSubmit}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        curColumns
        {...columnsObj}
      ></TablePro>
    </div>
  );
};

export default connect(({ process }) => ({
  process,
}))(ProcessList);
