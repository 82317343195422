import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Row, Col, Tooltip } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import TreeData from '../../project/taskManage/components/TreeData';
import { useUpdateEffect } from 'ahooks';
import RptModal from './components/RptModal';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportRptDayTask } from '@/_serveice/usercenter';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { timerYMD } from '@/_util/componentsUtil';
import './index.less';

const toOptions = (arr = []) => {
  arr ||= [];
  return arr.map((item) => ({
    value: item.proServCatgId,
    title: item.servCatgName,
    children: toOptions(item.childList),
  }));
};

const ServCategory = ({ dispatch, usercenter, loading }) => {
  // 菜单操作
  const [rptModalVisible, setRptModalVisible] = useState(false);
  const [timeData, setTimeData] = useState({});
  const [employeeAll, setEmployeeAll] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);

  // const [projectId, setProjectId] = useState();
  const projectId = useRef();

  const tableRef = useRef(null);
  const curData = useRef(null); // 存储当前服务类目对应的数据

  let reportDate = +history.location.query.reportDate || undefined;
  useEffect(() => {
    dispatch({
      type: 'project/getusersimpprojectlist',
    });
    // 组织架构
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setEmployeeAll(res);
    });
    // 服务类目
    dispatch({
      type: 'project/getServcatgEasyList',
    });

    getLotTimeList();

    return () => {
      dispatch({
        type: 'project/save',
        payload: {
          taskList: {},
          taskListSearchForm: {},
        },
      });
    };
  }, []);

  const getLotTimeList = () => {
    dispatch({
      type: 'usercenter/getEmpCurTimesLotList',
      payload: {
        empId: localStorage.getItem('muId'),
        reportDate: moment(reportDate).format('YYYY-MM-DD'),
      },
    });
  };
  const setProServcatgHourList = (rows) => {
    let selectKeys = selectRows.map(
      (item) => `${item.projectId}-${item.proServCatgId}`,
    );
    let projectKeys = Array.from(
      new Set(selectKeys.map((item) => item.split('-')[0])),
    );
    let projectServCatgList = projectKeys.map((item) => ({
      projectId: item,
      servCatgList: selectKeys
        .filter((ids) => ids.startsWith(item))
        .map((item) => item.split('-')[1]),
    }));
    dispatch({
      type: 'usercenter/getProServcatgHourList',
      payload: {
        empIdString: localStorage.getItem('muId'),
        projectServCatgList,
      },
    }).then((res) => {
      if (res && Array.isArray(res)) {
        let rseData = {};
        res.map((item) => {
          let obj = {};
          obj.projectCurTime = item.projectWorkHour?.extendData;
          obj.projectTime = item.projectWorkHour?.value;
          item.proServCatgWorkHourList.map((serv) => {
            obj[`${item.projectWorkHour.id}_${serv.id}`] = serv.value;
          });
          rseData[item.projectWorkHour.id] = obj;
          // return obj;
        });
        setTimeData(rseData);
      }
    });
  };

  useUpdateEffect(() => {
    tableRef.current?.reload();
  }, [employeeAll]);

  const showRptModal = (row, isAdd = false) => {
    if (!isAdd) {
      setSelectRows([row]);
      setSelectKeys([row.taskId]);
    }
    setProServcatgHourList();
    setRptModalVisible(true);
  };

  const taskStatusObj = {
    0: <div className="status-grey">未完成</div>,
    1: <div className="status-green">已完成</div>,
  };

  const columns = [
    {
      dataIndex: 'taskName',
      width: 200,
      fixed: 'left',
      title: '任务名称',
    },
    {
      key: 'projectName',
      width: 200,
      title: '项目名称',
      render: (row) => `${row.projectName}(${row.contractNumber})`,
    },
    {
      dataIndex: 'servCatgName',
      width: 200,
      title: '服务类目',
    },
    {
      key: 'taskTime',
      title: '任务周期',
      width: 220,
      render: (row) => timerYMD([row.taskBeginTime, row.taskEndTime]),
      // render: (row) =>
      //   `${moment(row.taskBeginTime).format('YYYY-MM-DD HH:mm:ss')}~${moment(
      //     row.taskEndTime,
      //   ).format('YYYY-MM-DD HH:mm:ss')}`,
    },
    {
      dataIndex: 'rptWorkHour',
      title: '已上报/时',
      width: 150,
      render: (val) =>
        val ? (
          <div style={{ color: '#4389f9' }}>累计上报 {val}</div>
        ) : (
          <div className="status-grey">未上报</div>
        ),
    },
    {
      dataIndex: 'taskStatus',
      title: '任务状态',
      width: 100,
      render: (status) => taskStatusObj[status],
    },
    {
      dataIndex: 'taskRemark',
      title: '任务备注',
      width: 220,
      render: (val) => <EllipsisTooltip value={val} width={200} />,
    },
    {
      title: '操作',
      key: 'options',
      width: 100,
      fixed: 'right',
      render: (row) => {
        return (
          <Button type="text" onClick={() => showRptModal(row)}>
            上报
          </Button>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'taskTime',
      type: 'DatePickerRangePicker',
      props: {
        placeholder: ['任务开始时间', '任务结束时间'],
      },
    },
    {
      name: 'taskName',
      type: 'Input',
      props: {
        placeholder: '任务名称',
      },
    },
    {
      name: 'taskStatus',
      type: 'Select',
      props: {
        placeholder: '任务状态',
        options: [
          {
            label: '未完成',
            value: 0,
          },
          {
            label: '已完成',
            value: 1,
          },
        ],
      },
    },
  ];

  const extra = () => (
    <>
      <Button
        type="primary"
        key={btnKeys.add}
        onClick={() => showRptModal(null, true)}
      >
        批量上报
      </Button>
      <Button
        type="primary"
        key="noKeys"
        onClick={() => {
          let { taskTime, ...value } = tableRef.current.value;
          let payload = {
            taskBeginTime: value.taskBeginTime ?? '',
            taskEndTime: value.taskEndTime ?? '',
            taskName: value.taskName ?? '',
            taskStatus: value.taskStatus ?? '',

            projectId: projectId.current || '',
            proServCatgId:
              value.proServCatgId ?? curData.current?.proServCatgId ?? '',
            // ...value,
          };
          if (taskTime) {
            payload.taskBeginTime = moment(taskTime[0])
              .startOf('d')
              .format('YYYY-MM-DD HH:mm:ss');
            payload.taskEndTime = moment(taskTime[1])
              .endOf('d')
              .format('YYYY-MM-DD HH:mm:ss');
          }
          let url = `${baseURL}${exportRptDayTask}${splitParamsFoGet(payload)}`;
          downloadXlsx(url, '上报列表');
        }}
      >
        导出
      </Button>
    </>
  );

  const getHasRptTime = (reportDate) => {
    if (!reportDate) {
      return null;
    }
    return dispatch({
      type: 'usercenter/getEmpCurTimesLotList',
      payload: {
        empId: localStorage.getItem('muId'),
        reportDate,
      },
    });
  };

  const onSubmit = (payload) => {
    payload.projectId = projectId.current;
    if (employeeAll.length == 0) {
      return false;
    }
    if (payload.taskTime) {
      const [start, end] = payload.taskTime;
      payload.taskBeginTime = moment(start)
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.taskEndTime = moment(end)
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    payload.taskTime = undefined;
    if (payload.memberList) {
      payload.memberList = payload.memberList.map((item) => item.split('-')[1]);
    }
  };

  const treeHandleChange = (keys, row) => {
    curData.current = row;
    // setProjectId(row.projectId);
    projectId.current = row.projectId;
    tableRef.current?.reload(
      {
        projectId: row.projectId,
        proServCatgId: row.proServCatgId,
      },
      true,
    );
  };

  const rptSave = (val) => {
    let reasonTime = true;
    let emptyTime = false;
    val.map((item) => {
      item.rptWorkTotalHours = +(
        item.rptWorkOvertimeHours + item.rptWorkHours
      ).toFixed(2);
      item.overTimePercent =
        +(item.rptWorkOvertimeHours / item.rptWorkTotalHours).toFixed(2) || 0;
      // if (item.rptWorkTotalHours > 24) {
      //   reasonTime = false;
      // }
      // if (!item.rptWorkTotalHours) {
      //   emptyTime = true;
      // }
    });
    // if (emptyTime) {
    //   message.error('时间段不能为空');
    //   return;
    // }
    if (!reasonTime) {
      message.error('时间段不能超过24小时');
      return;
    }
    dispatch({
      type: 'usercenter/reportEmpWork',
      payload: {
        empIdString: localStorage.getItem('muId'),
        rptWorkDetailList: val,
      },
    }).then((res) => {
      if (res) {
        message.success('上报成功');
        getLotTimeList();
        setRptModalVisible(false);
      }
    });
  };

  return (
    <div className="task-audit">
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData onChange={treeHandleChange} />
        </Col>
        <Col span={16}>
          <TablePro
            params={{
              projectId: projectId.current,
              proServCatgId: curData.current?.proServCatgId,
            }}
            request="usercenter/getUsercenterList"
            rowKey="taskId"
            searchCloumns={searchCloumns}
            searchData={usercenter.usercenterListSearchForm}
            columns={columns}
            rowClassName={(row) => (row.taskStatus == 1 ? 'table-success' : '')}
            scrollX
            actionRef={(ref) => (tableRef.current = ref)}
            onSubmit={onSubmit}
            data={usercenter.usercenterList}
            extra={extra}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectKeys,
              onChange: (idList, rows) => {
                setSelectKeys(idList);
                setSelectRows(rows);
              },
            }}
          />
        </Col>
      </Row>
      <RptModal
        visible={rptModalVisible}
        getHasRptTime={getHasRptTime}
        selectRows={selectRows}
        reportDate={reportDate}
        usercenter={usercenter}
        loading={loading['usercenter/reportEmpWork']}
        onSave={rptSave}
        timeData={timeData}
        onCancel={() => setRptModalVisible(false)}
      />
    </div>
  );
};

export default connect(({ usercenter, loading }) => {
  return {
    usercenter,
    loading: loading.effects,
  };
})(ServCategory);
