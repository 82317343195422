import { MessageBox, Button, Timeline } from 'kenshin';
import TablePro from '@/components/TablePro';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'umi';
import busiTypeKey from '../../_util/busiTypeKey';
const { OSA, WEEK, OSAEDIT, WEEKEDIT } = busiTypeKey;

const RecordModal = ({ visible, onCancel, processApplyId, row }) => {
  const dispatch = useDispatch();
  const [timeLineData, setTimeLineData] = useState([]);
  // Redux数据
  const process = useSelector(({ process }) => process);

  useEffect(() => {
    if (visible) {
      if ([OSAEDIT, WEEKEDIT].includes(row?.todoItemBusiType)) {
        dispatch({
          type: 'performance/getosachangeprocesslist',
          payload: {
            osaId: '',
            todoBusiId: row.todoItemBusiId,
          },
        }).then((list) => {
          if (list) {
            setTimeLineData(list);
          }
        });
      }
      if ([OSA, WEEK].includes(row?.todoItemBusiType)) {
        dispatch({
          type: 'performance/getosauserprocesslist',
          payload: {
            osaId: '',
            todoBusiId: row.todoItemBusiId,
          },
        }).then((list) => {
          if (list) {
            setTimeLineData(list);
          }
        });
      }
    }
  }, [visible]);

  const statusObj = {
    0: '待审核',
    1: '审核中',
    2: '审核通过',
    3: '审核拒绝',
  };

  const columns = [
    {
      dataIndex: 'processApplyNodeName',
      title: '流程名称',
    },
    {
      dataIndex: 'processNodeAuditList',
      title: '审核人/提交人',
      render: (list) =>
        list?.map((item) => (
          <div key={item.processAuditId}>
            {item.processAuditName}：{item.processAuditRemark}
          </div>
        )),
    },
    {
      dataIndex: 'processNodeStatus',
      title: '审核状态',
      render: (status) => statusObj[status],
    },
  ];

  const renderChildren = (row) => {
    if ([OSA, WEEK, OSAEDIT, WEEKEDIT].includes(row?.todoItemBusiType)) {
      let list = timeLineData?.osaProcessDataList ?? [];
      // console.log(11111,2222)
      let curIndex = list.findIndex((item) => item.curnNode);
      if (timeLineData?.processOver) {
        curIndex = list.length;
      }
      let dots = (index) => {
        if (index < curIndex) return { dotColor: '#0DE894' };
        else if (index == curIndex) return { dotColor: '#4389f9' };
        else return {};
      };
      return (
        <>
          <Timeline>
            {list.map?.((item, index) => (
              <Timeline.Item
                key={index}
                content={item.processDescribe}
                // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
                {...dots(index)}
              />
            ))}
          </Timeline>
        </>
      );
    }
    return (
      <TablePro
        pagination={false}
        params={{ processApplyId }}
        request="process/getcompleteprocess"
        dataSource={process.completeprocessList}
        columns={columns}
        rowKey={'processApplyChangeId'}
        curColumns
        columnsOptions={false}
      ></TablePro>
    );
  };

  return (
    <MessageBox
      title="审核记录"
      onCancel={onCancel}
      width={1000}
      maskClosable={false}
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      destroyOnClose
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      {renderChildren(row)}
    </MessageBox>
  );
};

export default RecordModal;
