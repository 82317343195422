import React, { useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import { Button, message, MessageBox } from 'kenshin';
import JsonForm from '@/components/JsonForm';
import { deepClone } from '@/_util/util';
import './index.less';
import {
  COMPUTEDRULE,
  ISLIMIT,
  SENDTIME,
  SENDTYPE,
  UNITTYPE,
  VALIDITY,
  computedRuleOptions,
  isLimitOptions,
  sendTimeOptions,
  sendTypeOptions,
  unitOptions,
  validityOptions,
} from './_util/updateColumns';
import ComputedRule from './components/computedRule';
import Validity from './components/validity';
import { useReactive } from 'ahooks';

let initState = {
  btnDisabled: false,
  vacationLimit: 2,
  vacationLimitRuleMethod: 1,
  vacationSendMethod: 1,
  vacationTimeSendMethod: 1,
  vacationUnit: 2,
  vacationOverMethod: null,
};

const UpdateVacation = ({ dispatch, attendance }) => {
  const ref = useRef();

  const state = useReactive({
    ...initState,
  });

  useEffect(() => {
    let value = attendance.vacationEditData;
    if (value.vacationId) {
      state.vacationLimit = value.vacationLimit;
      state.vacationLimitRuleMethod = value.vacationLimitRuleMethod;
      state.vacationOverMethod = value.vacationOverMethod;
      state.vacationSendMethod = value.vacationSendMethod;
      ref.current.form.setFieldsValue({ ...value });
    } else {
      ref.current.form.setFieldsValue({ ...initState });
    }
    return () => {
      dispatch({
        type: 'attendance/save',
        payload: { vacationEditData: {} },
      });
    };
  }, []);

  /** 额度计算规则 */
  const getRuleMethodRender = () => {
    if (state.vacationLimitRuleMethod == COMPUTEDRULE.AGE) {
      return [
        {
          title: ' ',
          dataIndex: 'vacationLimitRuleList',
          formItemProps: { colon: false },
          render: () => {
            return (
              <ComputedRule
                unit={state.vacationUnit == UNITTYPE.HOURS ? '小时' : '天'}
              />
            );
          },
        },
      ];
    } else if (state.vacationLimitRuleMethod == COMPUTEDRULE.FIX) {
      return [
        {
          title: ' ',
          valueType: 'InputNumber',
          dataIndex: 'vacationDays',
          formItemProps: { colon: false },
          required: '请输入假期额度',
          fieldProps: {
            placeholder: '请输入假期额度',
            style: { width: 200 },
            stepType: 'inside',
          },
        },
      ];
    }
    return [];
  };

  /** 假期有效期 */
  const getOverMethodRender = () => {
    if (state.vacationOverMethod == VALIDITY.YEAR) {
      return [
        {
          title: ' ',
          dataIndex: 'vacationOverDate',
          formItemProps: { colon: false },
          render: () => {
            return <Validity />;
          },
        },
      ];
    } else if (state.vacationOverMethod == VALIDITY.START) {
      return [
        {
          title: ' ',
          valueType: 'InputNumber',
          dataIndex: 'vacationOverAfterDays',
          formItemProps: { colon: false },
          required: '请输入多少天后假期结束',
          fieldProps: {
            placeholder: '请输入多少天后假期结束',
            style: { width: 200 },
            stepType: 'inside',
          },
        },
      ];
    }
    return [];
  };

  const isLimitRender = () => {
    if (state.vacationLimit == ISLIMIT.Y) {
      return [
        {
          title: '发放方式',
          dataIndex: 'vacationSendMethod',
          valueType: 'Select',
          required: '请选择 发放方式',
          fieldProps: {
            value: state.vacationSendMethod,
            onChange: (val) => {
              state.vacationSendMethod = val;
              let vacationTimeSendMethod = ref.current.form.getFieldValue(
                'vacationTimeSendMethod',
              );
              if (vacationTimeSendMethod == SENDTIME.MONTH) {
                // 选择按年发放时如果发放时间是自然月则切换成自然年
                ref.current.form.setFieldsValue({
                  vacationTimeSendMethod: SENDTIME.YEAR,
                });
              }
            },
            options: sendTypeOptions,
            placeholder: '请选择 发放方式',
          },
        },
        state.vacationSendMethod == SENDTYPE.OTHER
          ? null
          : {
              title: '发放时间',
              dataIndex: 'vacationTimeSendMethod',
              valueType: 'Select',
              required: '请选择发放时间',
              fieldProps: {
                options: sendTimeOptions({
                  vacationSendMethod: state.vacationSendMethod,
                }),
                placeholder: '请选择 发放时间',
              },
            },
        state.vacationSendMethod == SENDTYPE.OTHER
          ? null
          : {
              title: '额度计算规则',
              dataIndex: 'vacationLimitRuleMethod',
              valueType: 'Select',
              required: '请选择额度计算规则',
              fieldProps: {
                value: state.vacationLimitRuleMethod,
                onChange: (val) => {
                  state.vacationLimitRuleMethod = val;
                },
                options: computedRuleOptions,
                placeholder: '请选择 额度计算规则',
              },
            },
        ...(state.vacationSendMethod == SENDTYPE.OTHER
          ? []
          : getRuleMethodRender()),
        {
          title: '假期有效期',
          dataIndex: 'vacationOverMethod',
          valueType: 'Select',
          fieldProps: {
            value: state.vacationOverMethod,
            onChange: (val) => (state.vacationOverMethod = val),
            options: validityOptions,
            placeholder: '请选择 假期有效期',
          },
        },
        ...getOverMethodRender(),
      ].filter(Boolean);
    }
    return [];
  };
  console.log('unitOptions(state)', unitOptions(state));
  const edtaFormItem = [
    {
      title: '假期名称',
      dataIndex: 'vacationName',
      valueType: 'Input',
      required: '请输入假期名称',
      fieldProps: {
        placeholder: '请输入假期名称',
      },
    },
    {
      title: '假期说明',
      dataIndex: 'vacationRemark',
      valueType: 'TextArea',
      required: '请输入假期说明',
      fieldProps: {
        placeholder: '请输入假期说明',
        autoSize: { minRows: 2, maxRows: 6 },
      },
    },
    {
      title: '假期编码',
      dataIndex: 'vacationCode',
      valueType: 'Input',
      required: '请输入假期编码',
      fieldProps: {
        disabled: attendance.vacationEditData?.vacationId,
        placeholder: '请输入假期编码',
      },
    },
    {
      title: '假期排序',
      dataIndex: 'vacationSort',
      valueType: 'InputNumber',
      required: '请输入假期排序',
      fieldProps: {
        placeholder: '请输入假期排序',
        stepType: 'inside',
      },
    },
    {
      title: '请假单位',
      dataIndex: 'vacationUnit',
      valueType: 'Select',
      required: '请选择请假单位',
      fieldProps: {
        value: state.vacationUnit,
        onChange: (val) => (state.vacationUnit = val),
        placeholder: '请选择请假单位',
        options: unitOptions(state),
      },
    },
    {
      title: '是否限额',
      dataIndex: 'vacationLimit',
      valueType: 'RadioGroup',
      required: '请选择 是否限额',
      fieldProps: {
        value: state.vacationLimit,
        onChange: (e) => {
          state.vacationLimit = e.target.value;
        },
        options: isLimitOptions,
      },
    },
    ...isLimitRender(),
  ];

  const handleSave = () => {
    const { form } = ref.current;
    form.validateFields().then((values) => {
      let hasEdit = false;
      if (attendance.vacationEditData?.vacationId) {
        hasEdit = true;
      }
      dispatch({
        type: 'attendance/updatevacation',
        hasEdit,
        payload: {
          ...attendance.vacationEditData,
          ...values,
        },
      }).then((res) => {
        if (res) {
          handleCancel();
        }
      });
    });
  };

  const handleCancel = () => {
    history.go(-1);
  };

  return (
    <div>
      <div className="form_header">
        <h3>
          {attendance.vacationEditData?.vacationId ? '编辑' : '新增'}假期类型
        </h3>
        <div>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={state.btnDisabled}
          >
            保存
          </Button>
          <Button onClick={handleCancel}>返回</Button>
        </div>
      </div>
      <JsonForm columns={edtaFormItem} ref={ref} />
    </div>
  );
};

export default connect(({ customer, system, attendance }) => ({
  customer,
  system,
  attendance,
}))(UpdateVacation);
