import { Dropdown, Input, Button, message, MessageBox } from 'kenshin';
import { useEffect, useRef } from 'react';
import { useSelector, history, useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { useState } from 'react';
import { nextNodeIsMine } from '../../../assessment/OSA/OSAsetting/_unitl';
import ChooseIndicators from '../../../components/CollapseCard/chooseIndicators';
import EditForExcel from '../../../components/editForExcel';
import ModalFormPro from '@/components/ModalFormPro';
import PreviewIndicators from '../../../components/PreviewIndicators';
import { useReactive } from 'ahooks';
import { dirCloumns, itemCloumns } from '../../../indicators/_cloumns';
import {
  DIR,
  ITEM,
  scoreTypeObj,
  scroKey,
  remarkKey,
  defaultEdshowData,
  setTreeWeight,
  getAllTreeKeys,
  getTotalScore,
  setItemScore,
  setScore,
  dispatchType,
} from './_util/util';
import { getTableCloumns } from './_util/cloumns';
import { updateosacatgscoredata } from '../../../../../_serveice/performance/osa';
import { compareTree } from '../../../assessment/scoreSetting/components/_util';
import { deepClone } from '../../../../../_util/util';

const indicators = ({
  dispatchApi = dispatchType,
  // id参数名
  idKey = 'osaTemplateId',
  tableIdkey,
  // 其他id，例如绩效id，默认使用模板id
  idValue, // 对应的id
  disabled, //禁用操作按钮
  superiorShow = true, // 上级是否有权限看到他人评分
  hasEdit = true, //显示操作按钮
  onScoreChange, // 评分改变时调用
  processlist,
} = {}) => {
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);
  const curData = performance.curOsaSettingData.rows ?? {};
  const loading = useSelector(({ loading }) => loading.effects); // 弹窗数据
  const [chooseData, setChooseData] = useState(null);
  // 目录
  const [editDirVisible, setEditDirVisible] = useState(false);
  // 指标
  const [editItemVisible, setEditItemVisible] = useState(false);
  // 弹窗数据
  const [editModalData, setEditModalData] = useState(null);
  const [sheetVisible, setSheetVisible] = useState(false); // 设置excel弹窗状态
  const [chooseVisible, setChooseVisible] = useState(false);
  // 剩余权重
  const [maxWeight, setMaxWeight] = useState(100);
  // 设置指标编辑的回显参数
  const [editShowData, setEditShowData] = useState(defaultEdshowData());

  const scoreState = useReactive({});
  const ramkState = useReactive({});

  const init = () => {
    setEditDirVisible(false);
    setEditItemVisible(false);
    setEditModalData(null);
    setEditShowData(defaultEdshowData());
    setMaxWeight(100);
  };
  const treeSelectClear = () => {
    let totalWeight = performance.osaIndicators.reduce(
      (pre, cur) => pre + cur.weight,
      0,
    );
    setMaxWeight(100 - totalWeight);
  };
  /** 模板id */
  const osaTemplateId = idValue ?? history.location.query.osaTemplateId;

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (osaTemplateId) {
      getDirTree();
      dispatch({
        type: dispatchApi.GETTREE,
        payload: { [tableIdkey || idKey]: osaTemplateId },
      });
    }
  }, [history.location.query.osaTemplateId, idValue]);

  // 获取目录树
  const getDirTree = () => {
    dispatch({
      type: dispatchApi.GETDIRTREE,
      payload: {
        [tableIdkey || idKey]: osaTemplateId,
      },
    });
  };

  /** 是否需要进行校验 */
  const hasCheck = () => {
    const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    if (
      curData.oldOsaProcessNodeNumber == 'SCORE_TWO_NODE' &&
      curData.osaMuId == cuEmpId
    )
      return curData.osaMuId != curData?.directLeader?.id;
    if (
      curData.oldOsaProcessNodeNumber == 'SCORE_THREE_NODE' &&
      cuEmpId == curData.directLeader?.id
    )
      return curData.busiLeader?.id != curData?.directLeader?.id;
    // if (status.busy) return true;
  };

  const getPayload = (value, item, scoreType) => {
    let scoreKey = {
      1: {
        score: 'osaIndexItemSelfScore',
        remark: 'osaIndexItemSelfScoreRemark',
      },
      2: {
        score: 'osaIndexItemDirectLeaderScore',
        remark: 'osaIndexItemDirectLeaderScoreRemark',
      },
      3: {
        score: 'osaIndexItemBusiLeaderScore',
        remark: 'osaIndexItemBusiLeaderScoreRemark',
      },
    };
    let param = { [scoreKey[scoreType][value[0]]]: value[1] };
    Object.assign(item, param);
    // setScore(performance.osaIndicators);

    // 防抖清除定时器
    // 更新总分只需要调用最后一次，只执行一次就行
    // 统一设置分数，方便后续获取总分
    setScore(performance.osaIndicators);
    let payload = {
      osaIndexId: item.id,
      scoreType,
      osaIndexScore: item[scoreKey[scoreType]?.score] ?? 0,
      scoreRemark: item[scoreKey[scoreType]?.remark] ?? '',
    };
    return payload;
  };
  const inputSetScore = async (value, item, scoreType) => {
    let payload = getPayload(value, item, scoreType);
    return await dispatch({
      type: 'performance/osachangeindexscore',
      payload,
    });
  };
  const scoreOrRemarkChangeTimer = useRef(null); // 防抖标识
  // 评分或者备注修改时调用
  const scoreOrRemarkChange = async (value, item, scoreType) => {
    const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

    let beforeTree = deepClone(performance.osaIndicators);
    await inputSetScore(value, item, scoreType);
    if (scoreType == 2 && curData.busiLeader?.id == cuEmpId) {
      await inputSetScore(value, item, scoreType + 1);
    }

    /** 设置总分 */
    const {
      osaIndexCatgSelfScore,
      osaIndexCatgDirectLeaderScore,
      osaIndexCatgBusiLeaderScore,
    } = getTotalScore(performance.osaIndicators);
    let scoreObj = {
      osaSelfTotalScore: osaIndexCatgSelfScore,
      osaDirectTotalScore: osaIndexCatgDirectLeaderScore,
      osaBusiTotalScore: osaIndexCatgBusiLeaderScore,
    };

    // --------------------------------

    let afterTree = deepClone(performance.osaIndicators);
    let diffDirScoreList = compareTree(afterTree, beforeTree);
    setDirScore(diffDirScoreList);
    dispatch({
      type: 'performance/updateosachangedata',
      payload: {
        ...curData,
        ...scoreObj,
      },
    });
    onScoreChange?.(scoreObj);
  };

  /** 设置目录具有差异的分数 */
  const setDirScore = (list) => {
    if (Array.isArray(list) && list.length > 0) {
      let dataType = 2;
      let osaIndexCatgList = list.map((item) => {
        return {
          indexCatgId: item.id,
          osaIndexCatgSelfScore: item.osaIndexCatgSelfScore || 0,
          osaIndexCatgDirectLeaderScore:
            item.osaIndexCatgDirectLeaderScore || 0,
          osaIndexCatgBusiLeaderScore: item.osaIndexCatgBusiLeaderScore || 0,
        };
      });
      let payload = { dataType, osaIndexCatgList };
      updateosacatgscoredata(payload);
    }
    // updateosacatgscoredata()
  };

  /** 弹窗保存 */
  const handleSave = (payload, saveType) => {
    console.log(curData, editModalData, payload);
    if (curData.processApplyMuId == curData.osaMuId && editModalData?.id) {
      if (
        [editModalData?.osaIndexItemSelfScore].filter(
          (item) => item > payload.weight,
        )?.length > 0
      ) {
        message.error('当前权重小于评分，请先修改评分！');
        return;
      }
    }
    payload.code = (performance.osaIndicators?.length || 0) + 1;
    payload[idKey] = osaTemplateId;
    const prantId = payload.osaIndexCatgPantId || payload.osaIndexCatgId;
    if (prantId) {
      let dir = findNodeById(`${DIR}-${prantId}`, performance.osaIndicators);
      let index = dir?.childList?.length || 0;
      payload.code = `${dir.code}.${index + 1}`;
    }
    let hasEdit = false;
    if (editModalData?.id) {
      hasEdit = true; // 编辑
      // 旧的上级id
      let oldId =
        editModalData.osaIndexCatgPantId || editModalData.osaIndexCatgId;

      payload.osaIndexRemainWeight =
        payload.weight -
        (editModalData.childList?.reduce((pre, cur) => pre + cur.weight, 0) ||
          0);
      payload = {
        ...editModalData,
        ...payload,
        childList: undefined,
      };
    }
    if (maxWeight < payload.weight) {
      message.error('权重不能超过剩余权重！');
      return;
    }
    if (
      saveType == DIR &&
      editModalData.weight - payload.weight > editModalData.osaIndexRemainWeight
    ) {
      return message.error('权重不足与分配！');
    }

    let type = dispatchApi.UPDATEDIR;
    if (saveType == ITEM) {
      console.log(dispatchApi.UPDATEITEM);
      type = dispatchApi.UPDATEITEM;
      payload.osaIndexRemainWeight = undefined;
    }
    dispatch({
      type,
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        init();
        getDirTree();
      }
    });
  };

  const actualChange = async (value, item) => {
    let typeKey = {
      SCORE_TWO_NODE: 1,
      SCORE_THREE_NODE: 2,
      GRADE_NODE: 3,
    };
    Object.assign(item, value); // 合并数据
    dispatch({
      type: 'performance/save',
      payload: {
        osaIndicators: [...performance.osaIndicators],
      },
    });
    dispatch({
      type: 'performance/updateosachangeindexactucalvalue',
      payload: {
        ...value,
        osaIndexActualValue: value.osaIndexItemActualValue,
        osaIndexId: item.id,
      },
    });
  };

  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        let type = dispatchApi.DELETEDIR;
        let payload = {
          indexCatgId: row.id,
          [idKey]: osaTemplateId,
        };
        if (row.type != DIR) {
          type = dispatchApi.DELETEITEM;
          payload = {
            indexItemId: row.id,
            [idKey]: osaTemplateId,
          };
        }
        dispatch({
          type,
          payload,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
            getDirTree();
          }
        });
      },
    });
  };

  // 目录弹窗配置
  const dirEditCloumn = dirCloumns({
    dirTree: performance.dirTree,
    maxWeight,
    setMaxWeight,
    onClear: treeSelectClear,
  });
  // 指标弹窗配置
  const itemEditCloumn = itemCloumns({
    dirTree: performance.dirTree,
    editShowData,
    setEditShowData,
    maxWeight,
    setMaxWeight,
    onClear: treeSelectClear,
  });

  // 选择指标之后返回一颗新树
  const chooseSave = (tree) => {
    setTreeWeight(tree);
    let countWeights = tree.reduce((pre, cur) => pre + cur.weight, 0);
    let rootWeight =
      performance.osaIndicators?.reduce((pre, cur) => pre + cur.weight, 0) ?? 0;
    let rootRemainWeigh = Math.abs(100 - rootWeight);

    if (chooseData) {
      if (countWeights > chooseData?.osaIndexRemainWeight) {
        message.error(
          `当前选择的指标权重超过剩余权重：${chooseData.osaIndexRemainWeight}%!`,
        );
        return;
      }
    } else if (countWeights > rootRemainWeigh) {
      message.error(`当前选择的指标权重超过剩余权重：${rootRemainWeigh}%!`);
      return;
    }

    dispatch({
      type: dispatchApi.ADDTREE,
      payload: {
        indexDataJson: JSON.stringify(tree),
        [idKey]: osaTemplateId,
        indexPantId: chooseData?.id,
      },
    }).then((res) => {
      if (res) {
        setChooseVisible(false);
        setChooseData(null);
        getDirTree();
      }
    });
  };

  const SheetSave = (value) => {
    dispatch({
      type: dispatchApi.IMPOERSHEET,
      payload: {
        [idKey == 'osaUserId' ? 'osaId' : idKey]: osaTemplateId,
        indexDataJson: JSON.stringify(value),
      },
    }).then(async (res) => {
      if (res) {
        setSheetVisible(false);
        getDirTree();
      }
    });
  };

  /** 查找节点 */
  function findNodeById(key, nodes) {
    for (let node of nodes) {
      // 只找目录
      if (`${node.type}-${node.id}` === key) {
        return node;
      }
      if (node.childList) {
        let result = findNodeById(key, node.childList);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  // 点击编辑
  const showEditModal = (row, type) => {
    if (type == DIR) {
      // 目录
      setEditDirVisible(true);
    } else if (type == ITEM) {
      // 指标
      setEditItemVisible(true);
      row && setEditShowData(row); // 设置展开项
    }
    if (row) {
      // 剩余的权重
      let allSurplusCount =
        100 -
          performance.osaIndicators.reduce((pre, cur) => pre + cur.weight, 0) ||
        0;
      let prant = findNodeById(
        `1-${row.osaIndexCatgPantId || row.osaIndexCatgId}`,
        performance.osaIndicators,
      );
      let parentRemainWeight = prant
        ? prant.osaIndexRemainWeight
        : 0 + allSurplusCount;
      setMaxWeight(parentRemainWeight + row.weight); // 设置展开项
    } else {
      treeSelectClear();
    }
    setEditModalData(row || editShowData);
  };
  // 下拉菜单
  const dropdownMenu = (
    <Dropdown.Menu>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => showEditModal(null, DIR)}
      >
        新增目录
      </Dropdown.Item>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => showEditModal(null, ITEM)}
      >
        新增指标
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const columns = getTableCloumns({
    curData,
    actualChange,
    superiorShow,
    setChooseVisible,
    setChooseData,
    showEditModal,
    delItem,
    scoreState,
    ramkState,
    scoreOrRemarkChange,
    hasEdit,
    processlist,
  });

  const extra = () => {
    return (
      <>
        {hasEdit && (
          <>
            <Dropdown menu={dropdownMenu} trigger="click" disabled={disabled}>
              <Button type="primary">新增</Button>
            </Dropdown>
            <Button
              style={{ marginLeft: 8 }}
              disabled={disabled}
              onClick={() => {
                setChooseVisible(true);
                setChooseData(null);
              }}
            >
              添加指标模板
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              type="success"
              disabled={disabled}
              onClick={() => {
                setSheetVisible(true);
                setChooseData(null);
              }}
            >
              Excel编辑
            </Button>
          </>
        )}
        <Button
          type="primary"
          onClick={() => {
            setExpandedRowKeys(
              expandedRowKeys?.length == 0
                ? getAllTreeKeys(performance.osaIndicators)
                : [],
            );
          }}
        >
          {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
        </Button>
        {curData && (
          <PreviewIndicators row={curData} type="primary" hasRequest={false} />
        )}
      </>
    );
  };

  return (
    <>
      <TablePro
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        extra={extra}
        key={performance.osaIndicators}
        curColumns
        columnsOptions={false}
        scrollX
        scrollY={700}
        loading={loading[dispatchApi.GETTREE]}
        childrenColumnName="childList"
        dataSource={performance.osaIndicators}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />
      <ChooseIndicators
        visible={chooseVisible}
        onSave={chooseSave}
        baseCode={performance.osaIndicators?.length}
        onCancel={() => {
          setChooseVisible(false);
          setChooseData(null);
        }}
      />
      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editDirVisible}
        edtaFormItem={dirEditCloumn}
        formData={editModalData}
        onSave={(val) => handleSave(val, DIR)}
        onCancel={init}
      />
      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editItemVisible}
        edtaFormItem={itemEditCloumn}
        formData={editModalData}
        onSave={(val) => handleSave(val, ITEM)}
        onCancel={init}
      />
      <EditForExcel
        visible={sheetVisible}
        loading={loading[dispatchApi.IMPOERSHEET]}
        onCancel={() => {
          setSheetVisible(false);
          setChooseData(null);
        }}
        dataSource={performance.osaIndicators}
        onSave={SheetSave}
      />
    </>
  );
};

export default indicators;
