import {
  addosatempindexdata,
  addosatemplateindexcatgdata,
  addosatemplateindexitemdata,
  deleteosatemplateindexcatgdata,
  deleteosatemplateindexitemdata,
  getosatemplateindexcatgtree,
  getosatemplateindextree,
  getosatemplatelist,
  getcommonosatemplatelist,
  initosatemplatedata,
  moveosatemplateindexcatgdata,
  moveosatemplateindexitemdata,
  updateosatemplatedata,
  updateosatemplateindexcatgdata,
  updateosatemplateindexitemdata,
  importosatemplateindexdata,
  deleteosatemplate,
} from '../../_serveice/performance/template';

export let state = {
  templateEditor: {},
  templateList: [],
  publicTemplateList: [],
  publicTemplateListWithchildList: [],
  osaIndicators: [],
  osaIndicatorsPayload: {},
  osaTemplateIndexTree: [],
};

export const effects = {
  *getosatemplatelist({ payload }, { put, call }) {
    const res = yield call(getosatemplatelist, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          templateList: res.data,
        },
      });
      return res.data;
    }
  },
  
  *deleteosatemplate({ payload }, { put, call }) {
    const res = yield call(deleteosatemplate, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplatelist',
      });
      return true;
    }
  },

  *deletecommonosatemplate({ payload }, { put, call }) {
    const res = yield call(deleteosatemplate, payload);
    if (res?.success) {
      yield put({
        type: 'getcommonosatemplatelist',
      });
      return true;
    }
  },

  *initosatemplatedata({ payload }, { put, call }) {
    const res = yield call(initosatemplatedata, payload);
    if (res?.success) {
      return res.data;
    }
  },
  *updateosatemplatedata({ payload }, { put, call }) {
    const res = yield call(updateosatemplatedata, payload);
    if (res?.success) {
      return res.data;
    }
  },

  *getosatemplateindextree({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaIndicatorsPayload,
    );
    let osaIndicatorsPayload = { ...searchData, ...payload };
    const res = yield call(getosatemplateindextree, osaIndicatorsPayload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          osaIndicators: res.data,
          osaIndicatorsPayload,
        },
      });
      return true;
    }
  },
  *addosatempindexdata({ payload }, { put, call }) {
    const res = yield call(addosatempindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *deleteosatemplateindexcatgdata({ payload }, { put, call }) {
    const res = yield call(deleteosatemplateindexcatgdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *deleteosatemplateindexitemdata({ payload }, { put, call }) {
    const res = yield call(deleteosatemplateindexitemdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *getosatemplateindexcatgtree({ payload }, { put, call }) {
    const res = yield call(getosatemplateindexcatgtree, payload);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          dirTree: res.data,
        },
      });
      return true;
    }
  },
  *updateosatemplateindexcatgdata({ payload, hasEdit }, { put, call }) {
    const res = yield call(
      hasEdit ? updateosatemplateindexcatgdata : addosatemplateindexcatgdata,
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *updateosatemplateindexitemdata({ payload, hasEdit }, { put, call }) {
    const res = yield call(
      hasEdit ? updateosatemplateindexitemdata : addosatemplateindexitemdata,
      payload,
    );
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *importosatemplateindexdata({ payload }, { put, call }) {
    const res = yield call(importosatemplateindexdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *moveosatemplateindexcatgdata({ payload }, { put, call }) {
    const res = yield call(moveosatemplateindexcatgdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },
  *moveosatemplateindexitemdata({ payload }, { put, call }) {
    const res = yield call(moveosatemplateindexitemdata, payload);
    if (res?.success) {
      yield put({
        type: 'getosatemplateindextree',
      });
      return true;
    }
  },

  *getcommonosatemplatelist({ payload }, { put, call }) {
    const res = yield call(getcommonosatemplatelist, payload);

    // res.data?.map((item) => {
    //   item.childList = [];
    //   item.type = 0;
    //   item.status = 1;
    //   item.id = item.osaTemplateId;
    // });

    console.log('公共模板payload', payload);
    console.log('公共模板', res.data);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          publicTemplateList: res.data,
        },
      });
      state.publicTemplateList = res.data;
      return res.data;
    }
  },


  *getcommonosatemplatelistwithchildList({ payload }, { put, call }) {
    const res = yield call(getcommonosatemplatelist, payload);

    res.data?.map((item) => {
      item.childList = [];
      item.type = 0;
      item.status = 1;
      item.id = item.osaTemplateId;
    });

    console.log('公共模板payload', payload);
    console.log('公共模板', res.data);
    if (res?.success) {
      yield put({
        type: 'save',
        payload: {
          publicTemplateListWithchildList: res.data,
        },
      });
      state.publicTemplateListWithchildList = res.data;
      return res.data;
    }
  },

  *getosatemplateindextree_({ payload }, { put, call, select }) {
    const searchData = yield select(
      ({ performance }) => performance.osaTemplateIndexTree,
    );
    let osaIndicatorsPayload = { ...searchData, ...payload };
    const res = yield call(getosatemplateindextree, osaIndicatorsPayload);
    updateStatus(res.data);

    state.publicTemplateListWithchildList?.map((item) => {
      if (item.osaTemplateId === payload.osaTemplateId) {
        item.childList = res.data;
      }
    });

    if (res?.success && res.data?.length > 0) {
      // yield put({
      //   type: 'save',
      //   payload: {
      //     osaIndicatorsPayload,
      //     publicTemplateList: state.publicTemplateList,
      //   },
      // });
      return true;
    }
  },
};

function updateStatus(data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].status === null) {
      data[i].status = 1;
    }
    if (data[i].childList !== null) {
      updateStatus(data[i].childList);
    }
  }
}
