import {
  // 菜单
  getsysfunctiontree,
  addmenu,
  editmenu,
  deleteMenu,
  // 角色
  getroleList,
  addRole,
  deleteRole,
  editRole,
  roleDetail,
  getsimplerolelist,
  getAllRoleList,
  // 字典类型
  getDictypeList,
  addDictype,
  deleteDictype,
  editDictype,
  DictypeAll,
  // 字典
  getDictionaryList,
  addDictionary,
  deleteDictionary,
  editDictionary,
  dictionaryDetail,
  // 用户
  getUserPagelist,
  addUser,
  deleteUser,
  batchDeleteUser,
  editUser,
  userDetail,
  userStatus,
  resetpwd,
  sysupdatepwd,
  setemployeebusileader,
  // 标签库
  getTagList,
  getTagAll,
  deleteTag,
  addTag,
  editTag,
  // 操作日志
  getOperatorLogPage,
  getAllModuleName,
  // 登录日志
  getLoginLogPage,
} from '@/_serveice/admin/system';

const itemChange = (item, parent) => {
  const { child, ...resProp } = item;
  let newObj = {
    ...resProp,
  };
  if (child && child.length > 0) {
    newObj.children = menuTransform(child);
  }
  return newObj;
};

// 菜单过滤
const menuTransform = (arr, parent = {}) => {
  let resArr = [];
  resArr = arr.map((item) => itemChange(item, parent));
  return resArr;
};

// 字典树查找单项
const findDictOne = (arr, key) => {
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i];
    if (item.dicTypeKey == key) {
      return arr[i];
    }
    if (item.child) {
      let data = findDictOne(item.child, key);
      if (data) {
        return data;
      }
    }
  }
};
export default {
  namespace: 'system',

  state: {
    // 菜单
    menuList: [],
    // 角色
    roleList: {},
    roleSearchForm: {},
    roleAlllist: [],
    // 字典类型
    dictypeList: [],
    dictypeListAll: {},
    // 字典
    dicList: [],
    dicSearchForm: {},
    // 用户
    userList: [],
    userListSearchForm: {},
    // 用户
    departmentList: [],
    departmentListSearchForm: {},
    // 标签库
    tagList: {},
    tagSearchForm: {},
    tagAllList: [],
    // 操作日志
    logList: {},
    logSearchForm: {},
    allModule: [],
    // 登录日志
    loginLogList: {},
    loginLogSearchForm: {},
    router: {
      from: '/',
      to: '',
    },
  },

  // 类似vue的actions，里面可用执行异步操作
  effects: {
    // 菜单列表--------------------------
    *getsysfunctiontree({ _ }, { put, call }) {
      const res = yield call(getsysfunctiontree, _);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            menuList: menuTransform([res.data]),
          },
        });
      }
    },
    *editmenu({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editmenu : addmenu, payload);
      if (res?.success) {
        yield put({
          type: 'getsysfunctiontree',
        });
        return true;
      }
    },
    *delmenu({ payload }, { put, call }) {
      const res = yield call(deleteMenu, payload);
      if (res?.success) {
        yield put({
          type: 'getsysfunctiontree',
        });
        return true;
      }
    },
    // 菜单列表---------------end-----------

    // 角色列表--------------------------
    *getroleList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(({ system }) => system.roleSearchForm);
      let roleSearchForm = { ...searchData, ...payload };
      if (reset) {
        roleSearchForm = payload;
      }
      const res = yield call(getroleList, roleSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            roleList: res.data,
            roleSearchForm,
          },
        });
      }
    },
    *editRole({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editRole : addRole, payload);
      if (res?.success) {
        yield put({
          type: 'getroleList',
        });
        yield put({
          type: 'user/getuserfunctions',
        });
        return true;
      }
    },
    *deleteRole({ payload }, { put, call }) {
      const res = yield call(deleteRole, payload);
      if (res?.success) {
        yield put({
          type: 'getroleList',
        });
        return true;
      }
    },
    *roleDetail({ payload }, { put, call }) {
      const res = yield call(roleDetail, payload);
      if (res?.success) {
        return res.sysroleData;
      }
    },
    *roleMap({ payload }, { put, call }) {
      const res = yield call(getsimplerolelist, payload);
      if (res?.success) {
        return res.roleList;
      }
    },
    *getAllRoleList({ payload }, { put, call }) {
      const res = yield call(getAllRoleList, payload);
      if (res?.success) {
        const optionsFn = (item) => ({
          label: item.value,
          value: item.id,
        });

        yield put({
          type: 'save',
          payload: {
            roleAlllist: res.data.map(optionsFn),
          },
        });
        return res.data.map(optionsFn);
      }
    },
    // 角色列表---------------end-----------

    // 字典类型列表--------------------------
    *getDictypeList({ payload, reset }, { put, call, select }) {
      const res = yield call(getDictypeList);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            dictypeList: res.data,
          },
        });
        return true;
      }
    },
    *editDictype({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editDictype : addDictype, payload);
      if (res?.success) {
        yield put({
          type: 'getDictypeList',
        });
        return true;
      }
    },
    *deleteDictype({ payload }, { put, call }) {
      const res = yield call(deleteDictype, payload);
      if (res?.success) {
        yield put({
          type: 'getDictypeList',
        });
        return true;
      }
    },
    *getDictypeAll({ payload }, { put, call, select }) {
      if (payload.dicTypeKey) {
        const dictypeList = yield select(({ system }) => system.dictypeList);
        let data = findDictOne([dictypeList], payload.dicTypeKey);
        if (!data?.dicTypeId) return false;

        const dictypeListAll = yield select(
          ({ system }) => system.dictypeListAll,
        );
        const res = yield call(DictypeAll, { dicTypeId: data?.dicTypeId });
        if (res?.success) {
          dictypeListAll[payload.dicTypeKey] = res.data;
          yield put({
            type: 'save',
            payload: {
              dictypeListAll,
            },
          });
          return res.data;
        }
      }
    },
    // 字典类型列表---------------end-----------

    // 字典列表--------------------------
    *getDictionaryList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(({ system }) => system.dicSearchForm);
      let dicSearchForm = { ...searchData, ...payload };
      if (reset) {
        dicSearchForm = payload;
      }
      const res = yield call(getDictionaryList, dicSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            dicList: res.data,
            dicSearchForm,
          },
        });
      }
    },
    *editDictionary({ payload, hasEdit, updataKey }, { put, call }) {
      const res = yield call(hasEdit ? editDictionary : addDictionary, payload);
      if (res?.success) {
        yield put({
          type: 'getDictionaryList',
        });
        yield put({
          type: 'getDictypeAll',
          payload: {
            dicTypeKey: updataKey,
          },
        });
        return true;
      }
    },
    *deleteDictionary({ payload, updataKey }, { put, call }) {
      const res = yield call(deleteDictionary, payload);
      if (res?.success) {
        yield put({
          type: 'getDictionaryList',
        });
        yield put({
          type: 'getDictypeAll',
          payload: {
            dicTypeKey: updataKey,
          },
        });
        return true;
      }
    },
    *dictionaryDetail({ payload }, { put, call }) {
      const res = yield call(dictionaryDetail, payload);
      if (res?.success) {
        return res.sysroleData;
      }
    },
    // 字典列表---------------end-----------

    // 用户列表--------------------------
    *getUserPagelist({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ system }) => system.userListSearchForm,
      );
      let userListSearchForm = { ...searchData, ...payload };
      if (reset) {
        userListSearchForm = payload;
      }
      const res = yield call(getUserPagelist, userListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            userList: res,
            userListSearchForm,
          },
        });
      }
    },
    *editUser({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editUser : addUser, payload);
      if (res?.success) {
        yield put({
          type: 'getUserPagelist',
        });
        return true;
      }
    },
    *deleteUser({ payload }, { put, call }) {
      const res = yield call(deleteUser, payload);
      if (res?.success) {
        yield put({
          type: 'getUserPagelist',
        });
        return true;
      }
    },
    *batchDeleteUser({ payload }, { put, call }) {
      const res = yield call(batchDeleteUser, payload);
      if (res?.success) {
        yield put({
          type: 'getUserPagelist',
        });
        return true;
      }
    },
    *userDetail({ payload }, { put, call }) {
      const res = yield call(userDetail, payload);
      if (res?.success) {
        return res.sysroleData;
      }
    },
    *userStatus({ payload }, { put, call }) {
      const res = yield call(userStatus, payload);
      if (res?.success) {
        yield put({
          type: 'getUserPagelist',
        });
        return true;
      }
    },
    *resetpwd({ payload }, { put, call }) {
      const res = yield call(resetpwd, payload);
      if (res?.success) {
        return true;
      }
    },
    *sysupdatepwd({ payload }, { put, call }) {
      const res = yield call(sysupdatepwd, payload);
      if (res?.success) {
        return true;
      }
    },
    // 用户列表---------------end-----------
    // 标签库---------------start-----------
    *getTagList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(({ system }) => system.tagSearchForm);
      let tagSearchForm = { ...searchData, ...payload };
      if (reset) {
        tagSearchForm = payload;
      }
      const res = yield call(getTagList, tagSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            tagList: res.data,
            tagSearchForm,
          },
        });
      }
    },
    *editTag({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editTag : addTag, payload);
      if (res?.success) {
        yield put({
          type: 'getTagList',
        });
        yield put({
          type: 'user/getuserfunctions',
        });
        return true;
      }
    },
    *deleteTag({ payload }, { put, call }) {
      const res = yield call(deleteTag, payload);
      if (res?.success) {
        yield put({
          type: 'getTagList',
        });
        return true;
      }
    },
    *getTagAll({ payload }, { put, call }) {
      const res = yield call(getTagAll, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            tagAllList: res.data,
          },
        });
        return res.data;
      }
    },
    // 标签库---------------end-----------
    // 操作日志 ---------------------------------
    *getOperatorLogPage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(({ system }) => system.logSearchForm);
      let logSearchForm = { ...searchData, ...payload };
      if (reset) {
        logSearchForm = payload;
      }
      const res = yield call(getOperatorLogPage, logSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            logList: res.data,
            logSearchForm,
          },
        });
      }
    },
    *getAllModuleName({ payload }, { put, call }) {
      const res = yield call(getAllModuleName, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            allModule: res.data,
          },
        });
        return true;
      }
    },
    // 登录日志
    *getLoginLogPage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ system }) => system.loginLogSearchForm,
      );
      let loginLogSearchForm = { ...searchData, ...payload };
      if (reset) {
        loginLogSearchForm = payload;
      }
      const res = yield call(getLoginLogPage, loginLogSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            loginLogList: res.data,
            loginLogSearchForm,
          },
        });
      }
    },
    // 操作日志---------------------end--------------
  },

  reducers: {
    // 每次保存数据的时候讲state再存一次，避免下一次数据存储的时候把原数据删了
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
