import { DIR } from './util';
import { Dropdown, Input, Button, message, InputNumber } from 'kenshin';

const getActualCloumns = ({ curData, actualChange }) => {
  return [
    {
      dataIndex: 'osaIndexItemActualValue',
      title: '实际值',
      width: 150,
      render(val, row) {
        const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
        console.log(
          'curData:',
          curData,
          'row:',
          row,
          'cuEmpId',
          cuEmpId,
          'curData?.userInfo?.id',
          curData?.userInfo?.id,
        );
        if (
          cuEmpId == curData.osaMuId &&
          row.type != 1 &&
          row.osaIndexItemModel == 1 &&
          curData.oldOsaProcessNodeNumber != 'SCORE_ONE_NODE'
        ) {
          return (
            <Input
              defaultValue={val}
              style={{ width: 120, minWidth: 120 }}
              onBlur={(e) =>
                actualChange({ osaIndexItemActualValue: e.target.value }, row)
              }
            />
          );
        }
        if (
          curData.processApplyMuId === cuEmpId &&
          row.osaIndexItemGoalValueUnit
        ) {
          return curData?.userInfo?.id === cuEmpId ? ( //实际值只允许周计划的本人修改
            <Input
              defaultValue={val}
              style={{ width: 120, minWidth: 120 }}
              onBlur={(e) =>
                actualChange({ osaIndexItemActualValue: e.target.value }, row)
              }
            />
          ) : (
            val ?? '-'
          );
        }
        return val ?? '-';
      },
    },
  ];
};

// 评分的render
const CreateScoreRender = ({
  catgKey,
  scoreKey,
  curNode,
  showInput,
  scoreState,
  scoreOrRemarkChange,
  scoreType,
}) => {
  return (val, row) => {
    let max = row?.weight ?? 100;
    if (showInput && row.type != 1) {
      return (
        <InputNumber
          max={max}
          min={0}
          value={scoreState[`${row.id}-${curNode}`] ?? row[scoreKey] ?? 0}
          stepType="inside"
          onChange={(val) => (scoreState[`${row.id}-${curNode}`] = val)}
          onBlur={(e) => {
            scoreOrRemarkChange(
              ['score', +e.target.value > max ? max : +e.target.value],
              row,
              scoreType,
            );
          }}
        />
      );
    }
    return +(+val).toFixed(2) || +(+row[catgKey]).toFixed(2) || '-';
  };
};

// 备注的render
const CreateScoreRemarkRender = ({
  curNode,
  showInput,
  ramkState,
  scoreOrRemarkChange,
  scoreType,
} = {}) => {
  return (val, row) => {
    if (showInput && row.type != 1) {
      return (
        <Input
          value={ramkState[`${row.id}-${curNode}`] ?? val}
          style={{ width: 120, minWidth: 120 }}
          onChange={(e) => (ramkState[`${row.id}-${curNode}`] = e.target.value)}
          onBlur={(e) =>
            scoreOrRemarkChange(['remark', e.target.value], row, scoreType)
          }
        />
      );
    }
    return val ?? '-';
  };
};

// 自评、业务负责人评价控制
const getOtherScoreCloumns = ({
  curData,
  superiorShow,
  scoreState,
  ramkState,
  scoreOrRemarkChange,
  processlist,
}) => {
  let self = [];
  let busi = [];

  let cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

  let nodisabled = curData.oldOsaProcessNodeNumber != 'SCORE_ONE_NODE';
  let showSelf =
    cuEmpId == curData.osaMuId &&
    nodisabled &&
    cuEmpId == curData.processApplyMuId &&
    processlist?.[0]?.curnNode;

  let showBusi =
    cuEmpId == curData.busiLeader?.id &&
    nodisabled &&
    curData.oldOsaProcessNodeNumber != 'GRADE_NODE' &&
    cuEmpId == curData.processApplyMuId &&
    curData.busiLeader?.id != curData.directLeader?.id &&
    processlist?.[0]?.curnNode;

  if (superiorShow && curData.osaWeekType != 1) {
    self = [
      {
        dataIndex: 'osaIndexItemSelfScore',
        title: '评分(自评)',
        width: 120,
        render: CreateScoreRender({
          scoreOrRemarkChange,
          scoreKey: 'osaIndexItemSelfScore',
          catgKey: 'osaIndexCatgSelfScore',
          curNode: 'SCORE_ONE_NODE',
          showInput: showSelf,
          scoreState,
          scoreType: 1,
          processlist,
        }),
      },
      {
        dataIndex: 'osaIndexItemSelfScoreRemark',
        title: '备注(自评)',
        width: 150,
        render: CreateScoreRemarkRender({
          scoreOrRemarkChange,
          ramkState,
          curNode: 'SCORE_ONE_NODE',
          row: 'osaIndexItemSelfScoreRemark',
          showInput: showSelf,
          scoreType: 1,
          processlist,
        }),
      },
    ];
    if (curData?.processApplyMuId != curData?.osaMuId) {
      busi = [
        {
          dataIndex: 'osaIndexItemBusiLeaderScore',
          title: '评分(业务负责人)',
          width: 150,
          render: CreateScoreRender({
            scoreType: 3,
            scoreOrRemarkChange,
            scoreKey: 'osaIndexItemBusiLeaderScore',
            catgKey: 'osaIndexCatgBusiLeaderScore',
            curNode: 'SCORE_THREE_NODE',
            showInput: showBusi,
            scoreState,
            processlist,
          }),
        },
        {
          dataIndex: 'osaIndexItemBusiLeaderScoreRemark',
          title: '备注(业务负责人)',
          width: 150,
          render: CreateScoreRemarkRender({
            scoreType: 3,
            scoreOrRemarkChange,
            ramkState,
            curNode: 'SCORE_THREE_NODE',
            remarkKey: 'osaIndexItemBusiLeaderScoreRemark',
            showInput: showBusi,
            processlist,
          }),
        },
      ];
    }
  }
  return { busi, self };
};

export const getTableCloumns = ({
  curData,
  actualChange,
  superiorShow,
  setChooseVisible,
  setChooseData,
  showEditModal,
  delItem,
  scoreState,
  ramkState,
  scoreOrRemarkChange,
  hasEdit,
  processlist,
}) => {
  let cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  let showDirect =
    JSON.parse(localStorage.getItem('employeeDTO'))?.empId ==
      curData.directLeader?.id &&
    curData.oldOsaProcessNodeNumber != 'SCORE_ONE_NODE' &&
    cuEmpId == curData.processApplyMuId &&
    processlist?.[0]?.curnNode;
  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      fixed: 'left',
      width: 300,
      render: (row) =>
        `${row.code} ${
          row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        }`,
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: 120,
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    {
      dataIndex: 'weight',
      title: '权重',
      width: 120,
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'osaIndexItemGoalValueUnit',
      title: '单位',
      width: 120,
    },
    {
      dataIndex: 'osaIndexItemGoalValue',
      title: '目标值',
      width: 120,
    },
    ...getActualCloumns({ curData, actualChange }),
    ...getOtherScoreCloumns({
      curData,
      superiorShow,
      scoreState,
      ramkState,
      scoreOrRemarkChange,
      processlist,
    }).self,
    ...(curData?.processApplyMuId != curData?.osaMuId
      ? [
          {
            dataIndex: 'osaIndexItemDirectLeaderScore',
            title: '评分(上级)',
            width: 120,
            render: CreateScoreRender({
              scoreType: 2,
              scoreOrRemarkChange,
              scoreKey: 'osaIndexItemDirectLeaderScore',
              catgKey: 'osaIndexCatgDirectLeaderScore',
              curNode: 'SCORE_TWO_NODE',
              showInput: showDirect,
              scoreState,
              processlist,
            }),
          },
          {
            dataIndex: 'osaIndexItemDirectLeaderScoreRemark',
            title: '备注(上级)',
            width: 120,
            render: CreateScoreRemarkRender({
              scoreType: 2,
              scoreOrRemarkChange,
              ramkState,
              curNode: 'SCORE_TWO_NODE',
              remarkKey: 'osaIndexItemDirectLeaderScoreRemark',
              showInput: showDirect,
              processlist,
            }),
          },
        ]
      : []),
    ...getOtherScoreCloumns({
      curData,
      superiorShow,
      scoreState,
      ramkState,
      scoreOrRemarkChange,
      processlist,
    }).busi,
    hasEdit
      ? {
          title: '操作',
          width: 150,
          fixed: 'right',
          key: 'options',
          render: (row) => {
            return (
              <>
                {row.type == 1 && (
                  <Button
                    type="text"
                    onClick={() => {
                      setChooseVisible(true);
                      setChooseData(row);
                    }}
                  >
                    添加指标模板
                  </Button>
                )}
                <Button
                  type="text"
                  onClick={() => showEditModal(row, row.type)}
                >
                  编辑
                </Button>
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delItem(row)}
                >
                  删除
                </Button>
              </>
            );
          },
        }
      : null,
  ].filter(Boolean);
  return columns;
};
