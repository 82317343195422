import React, { useState, useEffect, useImperativeHandle } from 'react';
import { MessageBox, Button, Table } from 'kenshin';
import { history, useSelector } from 'umi';
import TablePro from '@/components/TablePro';

const WorkDetailModal = ({ onCancel, visible, data = {}, ...props }) => {
  const workHourList = useSelector(({ project }) => project.workHourList);

  const rptAuditResultStatus = {
    1: <div className="status-green animate">通过</div>,
    2: <div className="status-red">驳回</div>,
  };

  const columns = [
    {
      dataIndex: 'empName',
      title: '员工姓名',
    },
    {
      dataIndex: 'rptWorkHours',
      title: '上报的标准工时',
    },
    {
      dataIndex: 'rptWorkOvertimeHours',
      title: '上报的加班工时',
    },
    {
      dataIndex: 'rptWorkTotalHours',
      title: '上报的总工时',
    },
  ];

  return (
    <MessageBox
      title="员工上报情况"
      width={1000}
      onCancel={onCancel}
      maskClosable={false}
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      destroyOnClose
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
      {...props}
    >
      <TablePro
        params={data}
        request="project/getEmpWorkList"
        rowKey="empId"
        columns={columns}
        autoColumns={false}
        columnsOptions={false}
        curColumns
        dataSource={workHourList}
        pagination={false}
      />
    </MessageBox>
  );
};

export default WorkDetailModal;
