export const manICon = (
  <svg t="1645769785443" viewBox="0 0 1024 1024" width="14" height="14">
    <path
      d="M576 706.62144l0-52.77696c70.49216-39.7312 128-138.77248 128-237.83424 0-159.06816 0-288.01024-192-288.01024s-192 128.94208-192 288.01024c0 99.06176 57.50784 198.10304 128 237.83424l0 52.77696c-217.10848 17.75616-384 124.416-384 253.37856l896 0c0-128.96256-166.89152-235.64288-384-253.37856z"
      fill="#4c92f9"
    ></path>
  </svg>
);

export const girlIcon = (
  <svg t="1645769785443" viewBox="0 0 1024 1024" width="14" height="14">
    <path
      d="M576 706.62144l0-52.77696c70.49216-39.7312 128-138.77248 128-237.83424 0-159.06816 0-288.01024-192-288.01024s-192 128.94208-192 288.01024c0 99.06176 57.50784 198.10304 128 237.83424l0 52.77696c-217.10848 17.75616-384 124.416-384 253.37856l896 0c0-128.96256-166.89152-235.64288-384-253.37856z"
      fill="#fa7131"
    ></path>
  </svg>
);
