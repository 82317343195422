import {
  // 项目
  getProjectList,
  addProject,
  editProject,
  deleteProject,
  projectDetail,
  getProjectAll,
  // 页面
  getProjectpageList,
  addProjectpage,
  editProjectpage,
  deleteProjectpage,
  projectpageDetail,
  // 模板分类
  getTemptypeList,
  addTemptype,
  editTemptype,
  deleteTemptype,
  temptypeDetail,
  temptypeAll,
  // 模板
  getTemplateList,
  addTemplate,
  editTemplate,
  deleteTemplate,
  templateDetail,
  getTemplateAll,
} from '@/_serveice/admin/lowCode.js';

function delPrefix(arr, propsName) {
  return arr.map((item) => {
    return {
      ...item,
      [propsName]: item[propsName]?.replace(/^front:midstage/, ''),
    };
  });
}

export default {
  namespace: 'lowCode',

  state: {
    projectList: {},
    projectListSearchForm: {},
    projectListAll: [],

    projectpageList: {},
    projectpageListSearchForm: {},

    temptypeList: {},
    temptypeListSearchForm: {},
    temptypeListAll: [],

    templateList: {},
    templateListSearchForm: {},
    templateListAll: [],
  },

  effects: {
    // 项目列表--------------------------
    *getProjectList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ lowCode }) => lowCode.projectListSearchForm,
      );
      let projectListSearchForm = { ...searchData, ...payload };
      if (reset) {
        projectListSearchForm = payload;
      }
      const res = yield call(getProjectList, projectListSearchForm);
      if (res?.success) {
        let projectList = res.data;
        projectList.datas = delPrefix(projectList.datas, 'projectName');

        yield put({
          type: 'save',
          payload: {
            projectList,
            projectListSearchForm,
          },
        });
      }
    },
    *editProject({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editProject : addProject, payload);
      if (res?.success) {
        yield put({
          type: 'getProjectList',
        });
        return true;
      }
    },
    *deleteProject({ payload }, { put, call }) {
      const res = yield call(deleteProject, payload);
      if (res?.success) {
        yield put({
          type: 'getProjectList',
        });
        return true;
      }
    },
    *projectDetail({ payload }, { put, call }) {
      const res = yield call(projectDetail, payload);
      if (res?.success) {
        return res;
      }
    },
    *getProjectAll({ payload }, { put, call }) {
      const res = yield call(getProjectAll, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectListAll: delPrefix(res.data, 'value'),
          },
        });
        return delPrefix(res.data, 'value');
      }
    },
    // 项目列表---------------end-----------

    // 页面列表--------------------------
    *getProjectpageList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ lowCode }) => lowCode.projectpageListSearchForm,
      );
      let projectpageListSearchForm = { ...searchData, ...payload };
      if (reset) {
        projectpageListSearchForm = payload;
      }
      const res = yield call(getProjectpageList, projectpageListSearchForm);
      if (res?.success) {
        let projectpageList = res.data;
        projectpageList.datas = delPrefix(projectpageList.datas, 'pageName');

        yield put({
          type: 'save',
          payload: {
            projectpageList,
            projectpageListSearchForm,
          },
        });
      }
    },
    *editProjectpage({ payload, hasEdit }, { put, call }) {
      const res = yield call(
        hasEdit ? editProjectpage : addProjectpage,
        payload,
      );
      if (res?.success) {
        yield put({
          type: 'getProjectpageList',
        });
        return true;
      }
    },
    *deleteProjectpage({ payload }, { put, call }) {
      const res = yield call(deleteProjectpage, payload);
      if (res?.success) {
        yield put({
          type: 'getProjectpageList',
        });
        return true;
      }
    },
    *projectpageDetail({ payload }, { put, call }) {
      const res = yield call(projectpageDetail, payload);
      if (res?.success) {
        return res;
      }
    },
    // 页面列表---------------end-----------

    // 模板分类列表--------------------------
    *getTemptypeList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ lowCode }) => lowCode.temptypeListSearchForm,
      );
      let temptypeListSearchForm = { ...searchData, ...payload };
      if (reset) {
        temptypeListSearchForm = payload;
      }
      const res = yield call(getTemptypeList, temptypeListSearchForm);
      if (res?.success) {
        let temptypeList = res.data;
        temptypeList.datas = delPrefix(temptypeList.datas, 'tmptTypeName');

        yield put({
          type: 'save',
          payload: {
            temptypeList,
            temptypeListSearchForm,
          },
        });
      }
    },
    *editTemptype({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editTemptype : addTemptype, payload);
      if (res?.success) {
        yield put({
          type: 'getTemptypeList',
        });
        return true;
      }
    },
    *deleteTemptype({ payload }, { put, call }) {
      const res = yield call(deleteTemptype, payload);
      if (res?.success) {
        yield put({
          type: 'getTemptypeList',
        });
        return true;
      }
    },
    *temptypeDetail({ payload }, { put, call }) {
      const res = yield call(temptypeDetail, payload);
      if (res?.success) {
        return res;
      }
    },
    *temptypeAll({ payload }, { put, call }) {
      const res = yield call(temptypeAll, payload);
      if (res?.success) {
        let resData = delPrefix(
          res.data.map((item) => ({
            label: item.value,
            value: item.id,
          })),
          'label',
        );
        yield put({
          type: 'save',
          payload: {
            temptypeListAll: resData,
          },
        });
        return resData;
      }
    },
    // 模板分类列表---------------end-----------

    // 模板列表--------------------------
    *getTemplateList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ lowCode }) => lowCode.templateListSearchForm,
      );
      let templateListSearchForm = { ...searchData, ...payload };
      if (reset) {
        templateListSearchForm = payload;
      }
      const res = yield call(getTemplateList, templateListSearchForm);
      if (res?.success) {
        let templateList = res.data;
        templateList.datas = delPrefix(templateList.datas, 'tmptName');

        yield put({
          type: 'save',
          payload: {
            templateList: res.data,
            templateListSearchForm,
          },
        });
      }
    },
    *editTemplate({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? editTemplate : addTemplate, payload);
      if (res?.success) {
        yield put({
          type: 'getTemplateList',
        });
        return true;
      }
    },
    *deleteTemplate({ payload }, { put, call }) {
      const res = yield call(deleteTemplate, payload);
      if (res?.success) {
        yield put({
          type: 'getTemplateList',
        });
        return true;
      }
    },
    *templateDetail({ payload }, { put, call }) {
      const res = yield call(templateDetail, payload);
      if (res?.success) {
        return res;
      }
    },
    *getTemplateAll({ payload }, { put, call }) {
      const res = yield call(getTemplateAll, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            templateListAll: delPrefix(res.data, 'value'),
          },
        });
        return delPrefix(res.data, 'value');
      }
    },
    // 模板列表---------------end-----------
  },

  reducers: {
    // 每次保存数据的时候讲state再存一次，避免下一次数据存储的时候把原数据删了
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
