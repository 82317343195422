import { Button, Card, Col, Input, Switch, Row, message } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { useEffect, useRef, useState } from 'react';
import { getTableColumns } from './_columns';
import EditModal from './components/EditModal';
import { getempattendancestatuslist } from '../../../_serveice/attendance';
import ModalFormPro from '@/components/ModalFormPro';
import Statistical from './components/Statistical';
import OutClockTable from './components/OutClockTable';

const defaultTime = () => [
  moment().subtract(10, 'day').startOf('date'),
  moment().endOf('date'),
];

const VacationManage = ({ attendance, dispatch, loadingEf }) => {
  const [statTime, setStatTime] = useState(defaultTime());
  const [editVisible, setEditVisible] = useState(false);
  const [asyncVisible, setAsyncVisible] = useState(false);
  const [outClockVisible, setOutClockVisible] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [typeList, setTypeList] = useState([]);
  const typeKey = useRef({});

  const [statisVisible, setStatisVisible] = useState(false);

  const showEditModal = (row) => {
    setEditVisible(true);
    setRowData(row);
  };

  useEffect(() => {
    getempattendancestatuslist().then((res) => {
      if (res.success) {
        typeKey.current = res.data;
        let typeList = Object.keys(res.data).map((item) => ({
          label: res.data[item],
          value: +item,
        }));
        setTypeList(typeList);
      }
    });
  }, []);

  const columns = getTableColumns({ showEditModal, typeKey: typeKey.current });

  const disabledDate = (current) => {
    return (
      (current && current > moment().endOf('day')) ||
      current < moment().subtract(30, 'day').endOf('day')
    );
  };

  const searchCloumns = [
    {
      name: 'empName',
      type: 'Input',
      props: {
        placeholder: '请输入姓名',
      },
    },
    {
      name: 'statTime',
      type: 'DatePickerRangePicker',
      props: {
        defaultValue: statTime,
        allowClear: false,
        onChange: setStatTime,
        disabledDate: (current) => current && current > moment().endOf('day'),
      },
    },
    {
      name: 'attendanceBeginStatus',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        allowClear: true,
        mode: 'multiple',
        options: typeList,
      },
    },
    {
      name: 'deptIdList',
      type: 'DeptSelect',
      props: {
        defaultValue: ['1'],
      },
    },
  ];

  const asyncData = (values) => {
    let statTime = values.statTime;
    dispatch({
      type: 'attendance/asyncemployeeclockdata',
      payload: {
        statBeginTime: statTime[0].startOf('d').format('YYYY-MM-DD HH:mm:ss'),
        statEndTime: statTime[1].format('YYYY-MM-DD HH:mm:ss'),
      },
    }).then((success) => {
      if (success) {
        message.success('同步成功，请5s后重新刷新数据！');
        setAsyncVisible(false);
      }
    });
  };

  const extra = () => {
    return (
      <>
        <Button
          // onClick={() => asyncData()}
          onClick={() => setAsyncVisible(true)}
          type="primary"
          loading={loadingEf['attendance/asyncemployeeclockdata']}
        >
          同步数据
        </Button>
        <Button
          // onClick={() => asyncData()}
          onClick={() => setStatisVisible(true)}
          type="primary"
        >
          查看考勤统计
        </Button>
        <Button
          // onClick={() => setOutClockVisible(true)}
          onClick={() => history.push('/attendance/list/outClockTable')}
          type="primary"
        >
          查看外出成员
        </Button>
      </>
    );
  };

  const onSubmit = (payload) => {
    if (!payload.deptIdList) {
      payload.deptIdList = ['1'];
    }
    if (payload.statTime) {
      payload.statBeginTime = payload.statTime[0].format('YYYY-MM-DD HH:mm:ss');
      payload.statEndTime = payload.statTime[1].format('YYYY-MM-DD HH:mm:ss');
      payload.statTime = undefined;
    }
  };

  const editSave = (payload) => {
    dispatch({
      type: 'attendance/updateemployeeattendancedata',
      payload,
    }).then((success) => {
      if (success) {
        setEditVisible(false);
        setRowData(null);
      }
    });
  };

  const handleReset = (payload) => {
    setStatTime(defaultTime());
    payload.statTime = defaultTime();
    if (payload.statTime) {
      payload.statBeginTime = payload.statTime[0].format('YYYY-MM-DD HH:mm:ss');
      payload.statEndTime = payload.statTime[1].format('YYYY-MM-DD HH:mm:ss');
      payload.statTime = undefined;
    }
  };

  return (
    <div>
      <TablePro
        params={{
          deptIdList: ['1'],
          statBeginTime: statTime[0].format('YYYY-MM-DD HH:mm:ss'),
          statEndTime: statTime[1].format('YYYY-MM-DD HH:mm:ss'),
        }}
        scrollX
        scrollY
        extra={extra}
        onReset={handleReset}
        request="attendance/getemployeeattendancepage"
        searchCloumns={searchCloumns}
        rowKey="attendanceId"
        // pagination={false}
        bordered
        onSubmit={onSubmit}
        data={attendance.attendanceList}
        columns={columns}
        curColumns
      ></TablePro>
      <EditModal
        data={rowData}
        typeList={typeList}
        visible={editVisible}
        onSave={editSave}
        onCancel={() => {
          setEditVisible(false);
          setRowData(null);
        }}
      />
      <ModalFormPro
        title="同步数据"
        visible={asyncVisible}
        edtaFormItem={[
          {
            name: 'statTime',
            type: 'DatePickerRangePicker',
            props: {
              allowClear: false,
              disabledDate,
            },
          },
        ]}
        onSave={asyncData}
        onCancel={() => setAsyncVisible(false)}
      />
      <OutClockTable
        visible={outClockVisible}
        onCancel={() => setOutClockVisible(false)}
        typeList={typeList}
      />
      <Statistical
        visible={statisVisible}
        onCancel={() => setStatisVisible(false)}
      />
    </div>
  );
};

export default connect(({ attendance, loading, systemOrgani }) => ({
  attendance,
  systemOrgani,
  loadingEf: loading.effects,
}))(VacationManage);
