import { Button } from 'kenshin';
import EllipsisTooltip from '@/components/EllipsisTooltip';

const ATTENDANCE_TYPE = {
  BENGIN: 1, // '上班打卡'
  END: 2, // '下班打卡'
  OUT: 3, // '外出打卡'
};
const ATTENDANCE_STATUS = {
  NORMAL: 1, // 正常
  WARN: 2, // 异常
  REST: 3, // 休息
};

export const getChildrenColumns = (key, typeKey) => {
  let typeStr = {
    [ATTENDANCE_TYPE.BENGIN]: '上班',
    [ATTENDANCE_TYPE.END]: '下班',
    [ATTENDANCE_TYPE.OUT]: '外出',
  };
  let statusStr = {
    [ATTENDANCE_STATUS.NORMAL]: <div className="status-green">正常</div>,
    [ATTENDANCE_STATUS.WARN]: <div className="status-red">异常</div>,
    [ATTENDANCE_STATUS.REST]: <div className="status-grey">休息</div>,
  };
  return [
    {
      dataIndex: `attendance${key}Type`,
      width: 100,
      title: `打卡类型`,
      render: (val) => typeStr[val],
    },
    {
      dataIndex: `attendance${key}CheckinTime`,
      width: 120,
      title: `打卡时间`,
      render: (time, row) => {
        return (
          <div>
            {time ? moment(time).format('HH:mm:ss') : '-'}
            <div>
              {row['attendance' + key + 'PatchClockTime'] &&
                `(补)${moment(
                  row['attendance' + key + 'PatchClockTime'],
                ).format('HH:mm:ss')}`}
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: `attendance${key}SchCheckinTime`,
      width: 100,
      title: `标准时间`,
      render: (time) => (time ? moment(time).format('HH:mm:ss') : '-'),
    },
    {
      dataIndex: `attendance${key}Status`,
      width: 150,
      title: `打卡状态`,
      render: (val) => typeKey[val],
    },
    {
      dataIndex: `attendance${key}Exception`,
      width: 150,
      title: `打卡异常信息`,
      render: (val) => val || '-',
    },
    {
      dataIndex: `attendance${key}LocationTitle`,
      width: 150,
      title: `打卡位置标题`,
      // render: val => val || '-'
      render: (val) =>
        val ? <EllipsisTooltip value={val} width={150} /> : '-',
    },
    {
      dataIndex: `attendance${key}LocationDetail`,
      width: 150,
      title: `打卡详细位置`,
      // render: val => val || '-'
      render: (val) =>
        val ? <EllipsisTooltip value={val} width={150} /> : '-',
    },
    {
      dataIndex: `attendance${key}Remark`,
      width: 200,
      title: `打卡备注`,
      // render: val => val || '-'
      render: (val) =>
        val ? <EllipsisTooltip value={val} width={200} /> : '-',
    },
  ];
};

export const getTableColumns = ({ showEditModal, typeKey }) => {
  const beginChildren = getChildrenColumns('Begin', typeKey);
  const endChildren = getChildrenColumns('End', typeKey);
  const columns = [
    {
      dataIndex: 'attendanceDate',
      width: 180,
      title: '考勤日期',
      fixed: 'left',
      render(time, row) {
        return `${moment(time).format('YYYY-MM-DD')} ${row.attendanceWeek}`;
      },
    },
    {
      dataIndex: 'empName',
      width: 160,
      title: '姓名',
      fixed: 'left',
      render(empName, row) {
        return `${empName}（${row.empNumber}）`;
      },
    },
    {
      dataIndex: 'deptName',
      width: 150,
      title: '部门',
      render: (val) => <EllipsisTooltip value={val} width={150} />,
    },
    {
      dataIndex: 'attendanceIsWorkday',
      width: 150,
      title: '是否工作日',
      render: (val) => (val ? '工作日' : '非工作日'),
    },
    {
      dataIndex: 'begin',
      width: beginChildren.reduce((pre, cur) => pre + cur.width, 0),
      title: '上班打卡',
      children: beginChildren,
    },
    {
      dataIndex: 'end',
      width: endChildren.reduce((pre, cur) => pre + cur.width, 0),
      title: '下班打卡',
      children: endChildren,
    },
    {
      key: 'options',
      title: '操作',
      width: 150,
      fixed: 'right',
      render: (row) => {
        return (
          <>
            <Button type="text" onClick={() => showEditModal(row)}>
              修改
            </Button>
          </>
        );
      },
    },
  ];

  return columns;
};
