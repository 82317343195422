import { flat } from '@/_util/util';

export const DIR = 1; // 目录
export const ITEM = 2; // 指标

export const scoreTypeObj = {
  SUMMER_WEEK_PLAN_ONE_NODE: 1, // 周计划-总结
  SCORE_ONE_NODE: 1, // 自评
  SCORE_WEEK_PLAN_ONE_NODE: 2, // 周计划-上级评分
  SCORE_TWO_NODE: 2, // 上级评分
  SCORE_THREE_NODE: 3, // 业务负责人评分
};

// 分数的属性名
export const scroKey = {
  SCORE_WEEK_PLAN_ONE_NODE: 'osaIndexItemDirectLeaderScore', // 周计划-上级评分
  SCORE_ONE_NODE: 'osaIndexItemSelfScore', // 自评
  SCORE_TWO_NODE: 'osaIndexItemDirectLeaderScore', // 上级评分
  SCORE_THREE_NODE: 'osaIndexItemBusiLeaderScore', // 业务负责人评分
};

// 备注的属性名
export const remarkKey = {
  SCORE_ONE_NODE: 'osaIndexItemSelfScoreRemark', // 自评
  SCORE_TWO_NODE: 'osaIndexItemDirectLeaderScoreRemark', // 上级评分
  SCORE_THREE_NODE: 'osaIndexItemBusiLeaderScoreRemark', // 业务负责人评分
  SCORE_WEEK_PLAN_ONE_NODE: 'osaIndexItemDirectLeaderScoreRemark', // 周计划-上级评分
};

/** 获取表格所有的key，展开全部使用 */
export function getAllTreeKeys(tree) {
  if (!Array.isArray(tree)) return [];
  let keys = [];
  return flat(
    tree.map((row) => {
      if (row) {
        if (row.childList) {
          let arr = getAllTreeKeys(row.childList);
          return [...arr, `${row.type}-${row.id}`];
        }
      }
      return `${row.type}-${row.id}`;
    }),
    2,
  );
}

/** 获取总分数 */
export function getTotalScore(list) {
  let osaIndexCatgSelfScore = 0;
  let osaIndexCatgDirectLeaderScore = 0;
  let osaIndexCatgBusiLeaderScore = 0;
  list?.forEach((item) => {
    osaIndexCatgSelfScore +=
      item.osaIndexItemSelfScore ?? item.osaIndexCatgSelfScore ?? 0;

    osaIndexCatgDirectLeaderScore +=
      item.osaIndexItemDirectLeaderScore ??
      item.osaIndexCatgDirectLeaderScore ??
      0;

    osaIndexCatgBusiLeaderScore +=
      item.osaIndexItemBusiLeaderScore ?? item.osaIndexCatgBusiLeaderScore ?? 0;
  });
  return {
    osaIndexCatgSelfScore, //  自评总得分
    osaIndexCatgDirectLeaderScore, // 上级总得分
    osaIndexCatgBusiLeaderScore, // 业务负责人总得分
  };
}

// 类似，a ?? b ?? 0
export function setItemScore() {
  return Array.from(new Set([...arguments, 0])).filter(
    (item) => item != null && item != undefined,
  )[0];
}

// 统一设置表格里的目录总得分
export function setScore(list) {
  list.forEach((node) => {
    if (node.type == DIR) {
      //  只处理目录
      if (node.childList) {
        setScore(node.childList);
        node.osaIndexCatgDirectLeaderScore = node.childList.reduce(
          (pre, item) => {
            pre += setItemScore(
              item.osaIndexItemDirectLeaderScore,
              item.osaIndexCatgDirectLeaderScore,
            );
            return pre;
          },
          0,
        );
        node.osaIndexCatgSelfScore = node.childList.reduce((pre, item) => {
          pre += setItemScore(
            item.osaIndexItemSelfScore,
            item.osaIndexCatgSelfScore,
          );
          return pre;
        }, 0);
        node.osaIndexCatgBusiLeaderScore = node.childList.reduce(
          (pre, item) => {
            pre += setItemScore(
              item.osaIndexItemBusiLeaderScore,
              item.osaIndexCatgBusiLeaderScore,
            );
            return pre;
          },
          0,
        );
      }
    }
  });
}

// 请求的dva Actions
export const dispatchType = {
  GETTREE: 'performance/getosauserindextree',
  GETDIRTREE: 'performance/getosauserindexcatgtree',
  UPDATEDIR: 'performance/updateosaemployeeindexcatgdata',
  UPDATEITEM: 'performance/updateosaemployeeindexitemdata',
  DELETEDIR: 'performance/deleteosaemployeeindexcatgdata',
  DELETEITEM: 'performance/deleteosaemployeeindexitemdata',
  ADDTREE: 'performance/addosaemployeeindexdata',
  MOVEDIR: 'performance/moveosaemployeeindexcatgdata',
  MOVEITEM: 'performance/moveosaemployeeindexitemdata',
};

export const getCurNodeOptions = (curData) => {
  const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  let obj = { self: false, direct: false, busy: false };

  obj.self =
    ['SCORE_ONE_NODE', 'SCORE_TWO_NODE'].includes(curData.curnNodeNumber) &&
    curData.osaMuId == cuEmpId;
  obj.direct =
    ['SCORE_THREE_NODE', 'SCORE_TWO_NODE', 'SCORE_WEEK_PLAN_ONE_NODE'].includes(
      curData.curnNodeNumber,
    ) && cuEmpId == curData.directLeader?.id;
  obj.busy =
    ['GRADE_NODE', 'SCORE_THREE_NODE'].includes(curData.curnNodeNumber) &&
    cuEmpId == curData.busiLeader?.id;
  return obj;
};

/**
 * 对比两个一样的树
 * @param {Array} tree1 树1
 * @param {Array} tree2 树2
 * @returns {Array} 返回扁平化后的数组
 */
export function compareTree(tree1, tree2) {
  const result = [];
  const flatTree1 = flatTree(tree1);
  const flatTree2 = flatTree(tree2);
  for (let i = 0; i < flatTree1.length; i++) {
    const node1 = flatTree1[i];
    const node2 = flatTree2[i];
    if (
      node1.osaIndexCatgSelfScore !== node2.osaIndexCatgSelfScore ||
      node1.osaIndexCatgDirectLeaderScore !==
        node2.osaIndexCatgDirectLeaderScore ||
      node1.osaIndexCatgBusiLeaderScore !== node2.osaIndexCatgBusiLeaderScore
    ) {
      result.push(node1);
    }
  }
  return result;
}

/**
 * 扁平化树
 * @param {Array} tree 树
 * @returns {Array} 返回扁平化后的数组
 */
function flatTree(tree) {
  const result = [];
  tree.forEach((node) => {
    result.push(node);
    if (node.childList) {
      result.push(...flatTree(node.childList));
    }
  });
  return result;
}
