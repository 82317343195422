export default {
  menu: '首页',
  'menu.userList': '用户列表',

  'menu.admin.system': '系统管理',
  'menu.admin.system.permission': '菜单配置',
  'menu.admin.system.organizational': '组织架构',
  'menu.admin.system.role': '角色管理',
  'menu.admin.system.dict-type': '字典类型列表',
  'menu.admin.system.dict': '字典列表',
  'menu.admin.system.account': '用户管理',
  'menu.admin.system.department': '部门管理',
  'menu.admin.system.tagManage': '标签库管理',
  'menu.admin.system.logList': '操作日志',
  'menu.admin.system.loginLogList': '登录日志',

  'menu.admin.lowCode': '低代码管理',
  'menu.admin.lowCode.projectManger': '项目管理',
  'menu.admin.lowCode.projectPage': '项目页面管理',
  'menu.admin.lowCode.temptype': '模板分类',
  'menu.admin.lowCode.template': '模板管理',
  'menu.admin.system.lowCodeIframe': '低代码',

  'menu.contract': '合同管理',
  'menu.contract.list': '合同列表',
  'menu.contract.collection': '合同收款',

  'menu.project': '项目管理',
  'menu.project.servCategory': '服务类目',
  'menu.project.list': '项目列表',
  'menu.project.list.AddProject': '新增项目',
  'menu.project.list.projDetail': '项目详情',
  'menu.project.packageManger': '工作包管理',
  'menu.project.taskManage': '任务管理',

  'menu.customer': '客户管理',
  'menu.customer.list': '客户列表',
  'menu.customer.newCustomer': '新增客户',
  'menu.customer.caselist': '客户案例',

  'menu.employee': '员工管理',
  'menu.employee.salaryManger': '薪酬管理',

  'menu.usercenter': '个人中心',
  'menu.usercenter.project': '我的项目',
  'menu.usercenter.rptAudit': '每日上报',
  'menu.usercenter.workLog': '工作记录',
  'menu.usercenter.departmentRpt': '部门员工上报',
  'menu.usercenter.business': '待办事项',

  'menu.dataAnalyse': '数据魔方',
  'menu.dataAnalyse.customer': '客户分析',
  'menu.dataAnalyse.project': '项目分析',
  'menu.dataAnalyse.cost': '成本分析',
  'menu.dataAnalyse.collection': '收款分析',
  'menu.dataAnalyse.reported': '上报分析',
  'menu.dataAnalyse.reported.reporteDetail': '上报详细',
  'menu.dataAnalyse.osaAnalyse': '绩效分析',
  'menu.dataAnalyse.attendanceAnalyse': '考勤分析',
  'menu.dataAnalyse.allreported': '所有上报分析',

  'menu.process': '流程控制',
  'menu.process.applylist': '申请列表',
  'menu.process.createProcess': '新启流程',
  'menu.process.createProcess.detail': '详细',
  'menu.process.createProcess.changePage': '变更信息',
  // 'menu.process.pendingBusiness': '待办业务',
  'menu.process.pendingBusiness.auditPage': '审核',
  // 'menu.process.businessLog': '已办业务',
  'menu.process.businessLog.ChangeRecord': '变更信息详情',
  'menu.process.processList': '流程管理',

  'menu.performance': '绩效管理',
  'menu.performance.template': '考核方案',
  'menu.performance.indicators': '个人指标库',
  'menu.performance.template.createTemplate': '创建考核方案',
  'menu.performance.subAssessment': '下属考核',
  'menu.performance.assessmentList': '绩效列表',

  'menu.performance.assessment.OSA': 'OSA考核',
  'menu.performance.assessment.OSA.OSAsetting': 'OSA考核设置',
  'menu.performance.assessment.OSA.OSADetail': 'OSA详情',
  'menu.performance.assessment.weeklyPlan': '周计划',
  'menu.performance.assessment.scoreSetting': '评分',
  'menu.performance.assessment.levelSetting': '评级',

  'menu.performance.selfosa': '我的考核',
  'menu.performance.selfosa.OSAEditAudit': '变更申请',
  'menu.performance.selfosa.changosa': '变更申请',

  'menu.performance.weekTimeConfig': '周计划日期配置',
  'menu.performance.publicTemplate': '公共考核模板',
  'menu.performance.manage': '绩效综合管理',

  'menu.attendance': '考勤管理',
  'menu.attendance.vacationmanage': '假期管理',
  'menu.attendance.vacationmanage.updatevacation': '假期类型',
  'menu.attendance.vacationbalance': '假期余额',
  'menu.attendance.vacationbalance.detail': '查看假期余额',
  'menu.attendance.apply': '我的申请',
  'menu.attendance.apply.addApply': '新增申请',
  'menu.attendance.apply.applyDetail': '考勤申请详情',
  'menu.attendance.list': '打卡列表',
  'menu.attendance.list.outClockTable': '外出人员列表',
  'menu.attendance.alllist': '考勤列表',
  'menu.attendance.exemption': '考勤豁免',
  'menu.attendance.alllist.addApply': '编辑考勤',

  'menu.statistics': '统计',
  'menu.statistics.personal': '个人信息统计',
  'menu.statistics.attendance': '考勤统计',

  'menu.openai': 'WPAI',
};
