import { findContractNumUsed } from '@/_serveice/contract/list';
import { dicLType } from '@/_util/keysType';
import CollectBatch from '@/pages/contract/list/components/CollectBatch';
import { roleType } from '@/_util/keysType';
import ServCategoryList from '../components/ServCategoryList';

export const contractCloumns = ({
  SelectOptionsTransform,
  system,
  customerOptions,
  systemOrgani,
}) => {
  const uploadAccept = 'image/*,.doc,.docx,.pdf';
  const contractBelongYearOptions = [-3, -2, -1, 0, 1] // 归属年份暂定上三年到下一年这几个选项
    .map((item) => moment().add(item, 'years').format('YYYY'))
    .map((item) => ({
      label: item,
      value: item,
    }));
  return [
    {
      title: '合同名称',
      dataIndex: 'contractName',
      valueType: 'Input',
      required: '请输入合同名称',
      fieldProps: {
        placeholder: '请输入合同名称',
      },
    },
    {
      title: '合同编号',
      dataIndex: 'contractNumber',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入编号',
      },
      formItemProps: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: '请输入编号' },
          // () => ({
          //   async validator(_, value) {
          //     if (value) {
          //       let isHas = await findContractNumUsed({
          //         contractNumber: value,
          //       }).then((res) => res.success && res.data);
          //       if (isHas) {
          //         return Promise.reject(new Error('当前合同编号已被使用'));
          //       }
          //       return Promise.resolve();
          //     }
          //   },
          // }),
        ],
      },
    },
    {
      title: '合同金额',
      dataIndex: 'contractAmount',
      valueType: 'InputNumber',
      required: '请输入合同金额',
      help: '单位：元',
      fieldProps: {
        placeholder: '请输入金额',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      title: '合同税率',
      dataIndex: 'contractTaxRate',
      valueType: 'InputNumber',
      required: '请输入合同税率',
      // help: "单位：元",
      fieldProps: {
        placeholder: '请输入合同税率',
        stepType: 'inside',
        formatter: (value) => `${value}%`,
        max: 100,
        min: 0,
      },
    },
    {
      title: '合同类型',
      dataIndex: 'contractType',
      valueType: 'Select',
      required: '请选择合同类型',
      fieldProps: {
        placeholder: '请选择合同类型',
        options: SelectOptionsTransform(
          system.dictypeListAll[dicLType.CONTRACTTYPE],
          null,
          { info: 'simpleData', datalist: 'dataList' },
        ),
      },
    },
    {
      title: '合同归属年份',
      dataIndex: 'contractBelongYear',
      valueType: 'Select',
      required: '请选择合同归属年份',
      fieldProps: {
        placeholder: '请选择合同归属年份',
        options: contractBelongYearOptions,
      },
    },
    {
      title: '收款批次',
      dataIndex: 'contractExpectCollectList',
      required: '请选择收款批次',
      render: () => <CollectBatch />,
    },
    {
      title: '客户名称',
      dataIndex: 'contractCustId',
      valueType: 'Select',
      required: '请选择客户',
      fieldProps: {
        placeholder: '请选择客户',
        options: customerOptions,
        disabled: true,
      },
    },
    {
      title: '合同周期',
      dataIndex: 'contractTime',
      valueType: 'DatePickerRangePicker',
      required: '请选择合同周期',
      fieldProps: {
        disabled: true,
      },
    },
    {
      title: '合同归属部门',
      dataIndex: 'contractBelongDept',
      valueType: 'ChooseDepartment',
      required: '请选择合同归属部门',
      fieldProps: {
        multiple: false,
      },
    },
    {
      title: '合同归属商务',
      dataIndex: 'contractBusiList',
      valueType: 'Select',
      required: '请选择合同归属商务',
      tooltip: '注意：只有归属商务能看到当前合同',
      fieldProps: {
        placeholder: '请选择合同归属商务',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.BUSINESSAFFAIRS]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
        // disabled: true,
      },
    },
    {
      title: '合同电子版',
      dataIndex: 'contractMaterialList1',
      valueType: 'UploadFile',
      required: '请选择合同电子版',
      fieldProps: {
        mode: 'multiple',
        accept: uploadAccept,
        dirUrl: '/employee/avatar',
        multiple: true,
      },
    },
    {
      title: '验收单电子版',
      dataIndex: 'contractMaterialList2',
      valueType: 'UploadFile',
      fieldProps: {
        mode: 'multiple',
        accept: uploadAccept,
        dirUrl: '/employee/avatar',
        multiple: true,
      },
    },
    {
      title: '合同备注',
      dataIndex: 'contractRemark',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入合同备注',
      },
    },
  ];
};

export const projectCloumns = ({
  contract,
  systemOrgani,
  rowData,
  contractMoney,
  contractChange,
  projectMoneyStatus,
}) => {
  const [projectMoney, setProjectMoney] = projectMoneyStatus;
  return [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      valueType: 'Input',
      required: '请输入项目名称',
      fieldProps: {
        placeholder: '请输入项目名称',
      },
    },
    {
      title: '合同编号',
      dataIndex: 'contractId',
      valueType: 'Select',
      help: `合同剩余金额：${contractMoney / 100}`,
      required: '请输入合同编号',
      fieldProps: {
        placeholder: '请输入合同编号',
        onChange: contractChange,
        options: contract.easyContractList?.map((item) => ({
          value: `${item.id}-${item.value}`,
          label: `${item.value}(${item.extendData}:${item.extendData2})`,
        })),
      },
    },
    {
      title: '项目周期',
      dataIndex: 'projectTime',
      valueType: 'DatePickerRangePicker',
      required: '请输入项目周期',
      fieldProps: {},
    },
    {
      title: '项目经理',
      dataIndex: 'projectManager',
      valueType: 'Select',
      required: '请选择项目经理',
      fieldProps: {
        placeholder: '请选择项目经理',
        options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
    {
      title: '项目金额',
      dataIndex: 'projectAmount',
      valueType: 'InputNumber',
      required: '请输入项目金额',
      extra: `支持修改最小值：${projectMoney}`,
      fieldProps: {
        placeholder: '单位元',
        min: projectMoney,
        stepType: 'inside',
      },
    },
    {
      title: '项目描述',
      dataIndex: 'projectDescribtion',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入项目描述',
      },
    },
    {
      title: '工作包清单',
      dataIndex: 'dbServCatgList',
      required: '工作包清单',
      // valueType: 'ServCategoryList',
      // fieldProps: {
      //   onChange() {
      //     const { form } = ref.current;
      //     const data = form.getFieldValue();
      //     localStorage.setItem('projectCache', JSON.stringify(data));
      //   },
      //   pirceChange() {
      //     proServCatgDiscUnitPirceChange.current = true;
      //   },
      // },
      // 使用旧版本的代替，找到的是git记录的代码，单独存放再./components里面，而不是全局的组件
      component: (
        <ServCategoryList
          onChange={(list) => {
            let count = list.reduce(
              (pre, cur) => cur.servCatgDiscTotalAmount + pre,
              0,
            );
            setProjectMoney(count);
          }}
        />
      ),
    },
  ];
};

export const roleCloumns = ({}) => {
  return [
    {
      title: '角色名称',
      dataIndex: 'roleName',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入角色名称',
        disabled: true,
      },
    },
    {
      title: '角色标识',
      dataIndex: 'roleKey',
      valueType: 'Input',
      fieldProps: {
        placeholder: '请输入角色标识',
        disabled: true,
      },
    },
    {
      title: '角色类型',
      dataIndex: 'roleType',
      valueType: 'Select',
      fieldProps: {
        placeholder: '请输入角色类型',
        disabled: true,
        options: [
          { label: '系统预设', value: 1 },
          { value: 2, label: '用户定义' },
        ],
      },
    },
    {
      title: '角色备注',
      dataIndex: 'roleRemark',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入角色备注',
        disabled: true,
      },
    },
    {
      title: '过期日期',
      dataIndex: 'expireDate',
      valueType: 'DatePicker',
      required: '请输入过期日期',
      fieldProps: {
        placeholder: '请输入期限',
        disabledDate(current) {
          return current && current < moment().endOf('day');
        },
      },
    },
  ];
};
