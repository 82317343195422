import { Button, Typography, Timeline, Tabs, Input, message } from 'kenshin';
import CollapseCard, { Template } from '../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../_keys';
import './index.less';
import { useEffect, useState, useRef } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import Indicators from './components/indicators';
import JobEvaluation, { scoreTypeKey } from './components/jobEvaluation';
import { floatIndicators, nextNodeIsMine } from '../OSA/OSAsetting/_unitl';
import { deepClone } from '@/_util/util';
import Preview from './components/Preview';
import PreviewScore from './components/previewScore';
import { CloseCircleFilled } from '@ant-design/icons';

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

const scoreKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItem',
  SCORE_THREE_NODE: 'osaBusiBonusItem',
  SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectBonusItem',
};
const scoreRemarkKey = {
  SCORE_TWO_NODE: 'osaDirectBonusItemRemark',
  SCORE_THREE_NODE: 'osaBusiBonusItemRemark',
  SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectBonusItemRemark',
};

const getCurNodeOptions = (curData) => {
  const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  let obj = { self: false, direct: false, busy: false };
  if (
    curData?.curnNodeNumber == ['SCORE_TWO_NODE'] &&
    curData.osaMuId == cuEmpId &&
    curData.osaMuId != curData.directLeader?.id
  )
    obj.self = true;
  if (
    curData?.curnNodeNumber == 'SCORE_THREE_NODE' &&
    cuEmpId == curData.directLeader?.id
  )
    obj.direct = true;
  if (
    curData?.curnNodeNumber == 'GRADE_NODE' && // 业务负责人评分
    cuEmpId == curData.busiLeader?.id && // 我是他的业务负责人
    curData.osaMuId != curData.busiLeader?.id //我是我的业务负责人
  )
    obj.busy = true;
  // if (
  //   curData?.curnNodeNumber == 'SCORE_WEEK_PLAN_ONE_NODE' && // 周计划上级评分
  //   curData?.directLeader?.id == cuEmpId // 我是他的上级
  // )
  //   obj.direct = true;
  return obj;
};

const hasShowReturnBtn = (rows) => {
  // if (Object.values(getCurNodeOptions(rows)).filter(Boolean).length > 0)
  //   return false;
  // return [
  //   'SCORE_TWO_NODE',
  //   'SCORE_THREE_NODE',
  //   'SCORE_WEEK_PLAN_ONE_NODE',
  // ].includes(rows?.curnNodeNumber);
};

const hasShowComfimBtn = (rows) => {
  if (Object.values(getCurNodeOptions(rows)).filter(Boolean).length > 0)
    return false;
  return true;
};

/** 是否当前修改已提交的数据 */

const createTemplate = ({ performance, dispatch, loadingEf }) => {
  let curData = performance.curOsaSettingData.rows ?? {};

  const [activeKey, setActiveKey] = useState(curData.curnNodeNumber);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [previewScoreVisible, setPreviewScoreVisible] = useState(false);
  const once = useRef(true);
  const modalRef = useRef();
  const [resultList, setResultList] = useState([]);

  const auditResult = useRef();

  const [scoreActiveKey, setScoreActiveKey] = useState('SCORE_TWO_NODE');
  const [previewVisible, setPreviewVisible] = useState(false);

  const [refuseinfo, setRefuseinfo] = useState(null);

  useEffect(() => {
    if (curData.curnNodeNumber && once.current) {
      setScoreActiveKey(curData.curnNodeNumber);
      once.current = false;
    }
  }, [curData]);

  useEffect(() => {
    init();
    dispatch({
      type: 'performance/getosaauditsystemdescribelist',
    }).then((list) => {
      if (list) {
        setResultList(
          list.map((item) => ({ label: item.value, value: item.id })),
        );
      }
    });
    return () => {
      dispatch({
        type: 'performance/save',
        payload: {
          curOsaSettingData: {
            rows: {},
          },
        },
      });
    };
  }, [history.location.query.busiId]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  const saveCurData = (rows = {}) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows,
        },
      },
    });
  };

  const init = async () => {
    if (history.location.query.busiId) {
      curData = await dispatch({
        type: 'performance/getosaemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          saveCurData({ ...res });
          return res;
        }
        return {};
      });
      setActiveKey(curData.curnNodeNumber);
    }
    if (curData.osaId) {
      await dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId: curData.osaId,
          todoBusiId: '',
        },
      }).then((res) => {
        // console.log('refuseinfo==================3', refuseinfo);

        if (res) {
          res.osaProcessDataList.map((item) => {
            if (
              res.backNodeId != null &&
              item.processNodeId === res.backNodeId
            ) {
              console.log('请求拒绝接口了');
              dispatch({
                type: 'performance/getosaweekprocessrefuseinfo',
                payload: {
                  osaId: curData.osaId,
                  todoBusiId: '',
                },
              }).then((res) => {
                console.log('getosaweekprocessrefuseinfo:curData', curData);
                if (res) {
                  setRefuseinfo(res);
                }
              });
            }
          });
        }
      });
    }
  };

  const userInfoCloumns = [
    {
      title: '考核人',
      render: () => (
        <div
          style={{ color: '#999' }}
        >{`${curData.userInfo?.value}(${curData.userInfo?.extendData})`}</div>
      ),
    },
    {
      title: '考核周期',
      render: () => (
        <div
          style={{ color: '#999' }}
        >{`${curData.osaBeginTime} 至 ${curData.osaEndTime}`}</div>
      ),
    },
    {
      title: '考核截止时间',
      render: () => (
        <div style={{ color: '#999' }}>{`${moment(
          curData.osaScoreEndTime,
        ).format('YYYY-MM-DD HH:mm:ss')}`}</div>
      ),
    },
    {
      title: '满分',
      render: () => (
        <div style={{ color: '#999' }}>{`100（最高可上浮20%）`}</div>
      ),
    },
    {
      title: '考核类型',
      // render: () => <div style={{ color: '#999' }}>{`周计划`}</div>,
      render: () => {
        let strArr = {
          1: '周计划',
          3: '季度绩效',
        };
        return (
          <div style={{ color: '#999' }}>{strArr[curData.osaWeekType]}</div>
        );
      },
    },
  ];

  // 修改绩效数据
  const handleSave = (value) => {
    let curIndex = list.findIndex((item) => item.curnNode);
    let isLerder = list[curIndex + 1]?.nodeHandlerList.includes(localEmpId);
    if (value.hasOwnProperty('score')) {
      value[scoreKey[curData.curnNodeNumber]] = value.score;
      if (isLerder) value[scoreKey['SCORE_THREE_NODE']] = value.score;
      value.score = undefined;
    }
    if (value.hasOwnProperty('remark')) {
      value[scoreRemarkKey[curData.curnNodeNumber]] = value.remark;
      if (isLerder) value[scoreRemarkKey['SCORE_THREE_NODE']] = value.remark;
      value.remark = undefined;
    }

    saveCurData({
      ...curData,
      ...value,
      score: undefined,
    });
    // 后端保存数据
    dispatch({
      type: 'performance/updateosaemployeedata',
      payload: {
        ...curData,
        ...value,
      },
    });
  };

  let list =
    performance.osaProcessCache[curData.osaId]?.osaProcessDataList ?? [];
  let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[curData.osaId]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (backNodeId != null && list[index].processNodeId === backNodeId)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  const extraScoreCloumns = [
    {
      render() {
        return (
          <Tabs
            activeKey={scoreActiveKey}
            titleText="卡片标题"
            type="card"
            onChange={setScoreActiveKey}
          >
            {curData.curnNodeNumber == 'SCORE_WEEK_PLAN_ONE_NODE' && (
              <Tabs.TabPane
                tab="直属上级"
                key="SCORE_WEEK_PLAN_ONE_NODE"
              ></Tabs.TabPane>
            )}
            {curData.curnNodeNumber != 'SCORE_WEEK_PLAN_ONE_NODE' && (
              <>
                <Tabs.TabPane
                  tab="直属上级"
                  key="SCORE_TWO_NODE"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab="业务负责人"
                  key="SCORE_THREE_NODE"
                ></Tabs.TabPane>
              </>
            )}
          </Tabs>
        );
      },
    },
    {
      title: '加分分值',
      dataIndex: 'score',
      valueType: 'InputNumber',
      fieldProps: {
        style: { width: 200 },
        stepType: 'inside',
        disabled: !(
          list
            .find((item) => item.curnNode)
            ?.nodeHandlerList.includes(localEmpId) ||
          curData.curnNodeNumber == scoreActiveKey
        ),
        min: 0,
        max: 100,
        placeholder: '请输入分数:0~20',
      },
    },
    {
      title: '原因描述',
      dataIndex: 'remark',
      valueType: 'TextArea',
      fieldProps: {
        autoSize: { minRows: 2, maxRows: 6 },
        disabled: !(
          list
            .find((item) => item.curnNode)
            ?.nodeHandlerList.includes(localEmpId) ||
          curData.curnNodeNumber == scoreActiveKey
        ),
        placeholder: '请输入原因描述',
      },
    },
  ];

  const scoreRemarkChange = (e) => {
    let payload = {
      [scoreTypeKey[curData.curnNodeNumber]]: e.target.value,
    };
    handleSave(payload);
  };

  const editModalSave = async (value) => {
    /** 通过/拒绝原因 */
    let auditRemarkList = [
      ...(value.auditRemarkList ?? []),
      value.auditRemark,
    ]?.map((txt) => {
      let SUCCESSID = 88888888;
      let ERRORID = 99999999;
      let auditRmkDescribe =
        resultList.find((item) => item.value == txt)?.label || txt;
      let auditRmkDescribeId =
        resultList.find((item) => item.value == txt)?.value ??
        (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
      let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
      return {
        auditRmkDescribe,
        auditRmkDescribeId,
        auditRmkType,
      };
    });
    let payload = {
      ...value,
      auditRemarkList,
      osaId: curData.osaId,
      auditResult: auditResult.current,
    };
    if (curData?.curnNodeNumber == 'SCORE_TWO_NODE') payload.backNode = 1;
    let promiseIndex = 0;
    let hasSuccess = true;
    nextNodeIsMine(curData, curData.curnNodeNumber, (node) => promiseIndex++);
    if (auditResult.current == AUDITTYPE.ERROR) promiseIndex = 1;
    for (let i = 0; i < promiseIndex; i++) {
      await dispatch({
        type: 'performance/auditosaprocess',
        payload: {
          ...payload,
          isJumpNextNode: i != promiseIndex - 1,
        },
      }).then((res) => {
        if (!res) {
          hasSuccess = false;
        }
      });
    }

    if (hasSuccess) {
      setEditModalVisible(false);
      clearCache();
      goback();
    }
  };

  /** 上级查看的时候是否显示相关内容（其他人的评分） */
  const getSuperiorShow = (curData) => {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    let superEmp = curData.osaScoreList?.find((item) => item.osaScoreType == 2);
    if (superEmp?.osaScoreMuId == localEmpId) {
      return !!superEmp?.osaSeeOtherScore;
    }
    return true;
  };

  // 驳回信息
  const rejectInfoParams = [
    {
      title: `${refuseinfo?.backUserName}:`,
      render() {
        return <div>{refuseinfo?.backUserRemark}</div>;
      },
    },
  ];

  const edtaFormItem = [
    auditResult.current == AUDITTYPE.ERROR
      ? {
          label: '原因',
          name: 'auditRemarkList',
          type: 'Select',
          props: {
            mode: 'tags',
            options: resultList,
            placeholder: '请输入原因，按enter确定',
          },
        }
      : null,
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
    ...(() => {
      if (
        ['SCORE_THREE_NODE'].includes(
          // ['SCORE_ONE_NODE'].includes(
          curData.curnNodeNumber,
        ) &&
        auditResult.current == AUDITTYPE.ERROR
      ) {
        return [
          {
            label: '驳回到',
            name: 'backNode',
            required: '请选择驳回节点',
            type: 'RadioGroup',
            props: {
              options: [
                {
                  label: '自评',
                  value: 1,
                },
                {
                  label: '直属上级评分',
                  value: 2,
                },
              ].filter(Boolean),
            },
          },
        ];
      }
      return [];
    })(),
  ].filter(Boolean);

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const submitToAudit = () => {
    setPreviewScoreVisible(false);
    auditResult.current = AUDITTYPE.SUCCESS; // 通过按钮
    setEditModalVisible(true);
  };

  const handleConfim = () => {
    // 校验实际值
    let hasEmptyList = floatIndicators(performance.osaIndicators)
      .filter((item) => item.osaIndexItemModel == 1)
      .filter((item) => !item.osaIndexItemActualValue);
    if (curData.curnNodeNumber == 'SCORE_ONE_NODE') {
      let name = hasEmptyList.map((item) => item.osaIndexItemName).join();
      if (hasEmptyList.length > 0)
        return message.error(`${name}的实际值不能为空！`);
    }
    // 校验备注
    let key = {
      SCORE_ONE_NODE: 'osaIndexItemSelfScoreRemark', // 自评
      // SCORE_TWO_NODE: 'osaIndexItemDirectLeaderScoreRemark', // 上级评分
      // SCORE_THREE_NODE: 'osaIndexItemBusiLeaderScoreRemark', // 业务负责人评分
      // SCORE_WEEK_PLAN_ONE_NODE: 'osaIndexItemDirectLeaderScoreRemark', // 周计划-上级评分
    };
    const scoreTypeKey = {
      SUMMER_WEEK_PLAN_ONE_NODE: 'osaSelfRemark',
      SCORE_ONE_NODE: 'osaSelfRemark',
      // SCORE_WEEK_PLAN_ONE_NODE: 'osaDirectLeaderRemark',
      // SCORE_TWO_NODE: 'osaDirectLeaderRemark',
      // SCORE_THREE_NODE: 'osaBusiLeaderRemark',
    };
    if (Object.keys(key).includes(curData.curnNodeNumber)) {
      // 假如是评分环节
      let emptyList = floatIndicators(performance.osaIndicators).filter(
        (item) => {
          return item.type == 2 && !item[key[curData.curnNodeNumber]];
        },
      );
      if (emptyList.length > 0) {
        let codeStr =
          emptyList.length > 5
            ? ''
            : emptyList.map((item) => item.code).join(',') + '的'; // 备注为空的数量大于5条则不显示具体的code
        return message.error(`${codeStr}备注不能为空`);
      }
    }
    if (
      ['SCORE_TWO_NODE', 'SCORE_WEEK_PLAN_ONE_NODE'].includes(
        curData?.curnNodeNumber,
      )
    ) {
      let scoreMax = floatIndicators(performance.osaIndicators).filter(
        (item) => {
          return (
            item.type == 2 && item.osaIndexItemDirectLeaderScore > item.weight
          );
        },
      );
      if (scoreMax.length > 0) {
        return message.error(
          `编码为${scoreMax
            .map((item) => item.code)
            .join(',')}的上级评分超出权重`,
        );
      }
    }
    if (['SCORE_THREE_NODE'].includes(curData?.curnNodeNumber)) {
      let scoreMax = floatIndicators(performance.osaIndicators).filter(
        (item) => {
          return (
            item.type == 2 && item.osaIndexItemBusiLeaderScore > item.weight
          );
        },
      );
      if (scoreMax.length > 0) {
        return message.error(
          `编码为${scoreMax
            .map((item) => item.code)
            .join(',')}的业务负责人评分超出权重`,
        );
      }
    }
    if (
      scoreTypeKey[curData.curnNodeNumber] &&
      !curData[scoreTypeKey[curData.curnNodeNumber]]
    ) {
      return message.error(`工作评价不能为空！`);
    }
    if (curData?.curnNodeNumber != 'SUMMER_WEEK_PLAN_ONE_NODE') {
      setPreviewScoreVisible(true);
    } else {
      auditResult.current = AUDITTYPE.SUCCESS; // 通过按钮
      setEditModalVisible(true);
    }
  };

  let curNodeItem = list.find((item) => item.curnNode);
  const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  const HeaderButton = (
    <div className="fixed-right">
      {curNodeItem?.nodeHandlerList.includes(localEmpId) && (
        <Button type="primary" onClick={() => handleConfim()}>
          {curData?.curnNodeNumber == 'SUMMER_WEEK_PLAN_ONE_NODE'
            ? '确认总结'
            : '确认评分'}
        </Button>
      )}
      {curNodeItem?.nodeHandlerList.includes(localEmpId) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            modalRef.current?.form.setFieldsValue({ backNode: 2 });
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      <Button type="primary" onClick={goback}>
        返回
      </Button>
    </div>
  );
  let isWeek = curData.osaWeekType == 1;
  return (
    <div className="create-template-page">
      {HeaderButton}
      <Typography.Title level={4}>
        {curData.userInfo?.value}的{curData.osaName}评分
      </Typography.Title>
      <CollapseCard title="常规指标">
        <Indicators
          idKey="osaId"
          idValue={curData.osaId}
          superiorShow={getSuperiorShow(curData)}
          onScoreChange={(value) => saveCurData({ ...curData, ...value })}
          disabled={['SUMMER_WEEK_PLAN_ONE_NODE'].includes(
            curData.curnNodeNumber,
          )}
        />

        {curData.osaWeekType == 3 && (
          <CollapseCard title={<>月度策略</>}>
            <Template.MONTHSTRATEGY.default
              // dispatchApi={dispatchApi}
              idKey="osaId"
              osaWeekType={3}
              disabled={true}
              idValue={curData.osaId}
              newIdValue={curData.osaNewId}
              // showPublicTemplate={true}
            />
          </CollapseCard>
        )}

        {/* {getTotalScore(performance.osaIndicators)} */}
        {isWeek ? (
          ''
        ) : (
          <div key={1} style={{ lineHeight: 2 }}>
            <span style={{ fontWeight: 'bold' }}>自评总得分：</span>
            {curData.osaSelfTotalScore?.toFixed(2)}
            {/* {curData.osaIndexCatgSelfScore} */}
          </div>
        )}
        <div key={2} style={{ lineHeight: 2 }}>
          <span style={{ fontWeight: 'bold' }}>上级总得分：</span>
          {curData.osaDirectTotalScore?.toFixed(2)}
          {/* {curData.osaIndexCatgDirectLeaderScore} */}
        </div>
        {isWeek ? (
          ''
        ) : (
          <div key={3} style={{ lineHeight: 2 }}>
            <span style={{ fontWeight: 'bold' }}>业务负责人总得分：</span>
            {/* {osaIndexCatgBusiLeaderScore} */}
            {curData.osaBusiTotalScore?.toFixed(2)}
          </div>
        )}
      </CollapseCard>

      {Object.keys(scoreKey).includes(curData.curnNodeNumber) && (
        <CollapseCard
          title={
            <>
              加分项
              <span style={{ color: '#999', fontSize: 12 }}>
                （最高上浮20%)
              </span>
            </>
          }
          cloumns={extraScoreCloumns}
          onSave={handleSave}
          key={scoreActiveKey}
          defaultValue={{
            score: curData[scoreKey[scoreActiveKey]],
            remark: curData[scoreRemarkKey[scoreActiveKey]],
          }}
        />
      )}

      <JobEvaluation curData={curData} onChange={scoreRemarkChange} />

      <CollapseCard
        title={`${curData.userInfo?.value}(${curData.userInfo?.extendData})`}
        cloumns={userInfoCloumns}
      />

      {refuseinfo &&
        refuseinfo?.backUserRemark &&
        refuseinfo?.backUserRemark != 'null;' && (
          <CollapseCard
            visible="v"
            title="驳回信息"
            cloumns={Template.INFO.default({
              target: curData,
              arr: rejectInfoParams,
            })}
          />
        )}

      <CollapseCard
        title={
          <>
            流程图
            <span style={{ color: '#999', fontSize: 12 }}>
              （查看目前处于哪个环节)
            </span>
          </>
        }
      >
        <Timeline>
          {list.map((item, index) => (
            <Timeline.Item
              key={index}
              content={item.processDescribe}
              // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
              {...dots(index)}
            />
          ))}
        </Timeline>
      </CollapseCard>

      <ModalFormPro
        title="确认绩效"
        width={600}
        ref={modalRef}
        btnLoading={loadingEf['performance/auditosaprocess']}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
      <Preview
        visible={previewVisible}
        data={curData}
        dataSource={deepClone(performance.osaIndicators)}
        onCancel={() => setPreviewVisible(false)}
      />
      <PreviewScore
        visible={previewScoreVisible}
        data={curData}
        dataSource={deepClone(performance.osaIndicators)}
        onCancel={() => setPreviewScoreVisible(false)}
        onSave={submitToAudit}
      />
    </div>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(createTemplate);
