import { InputNumber, Button } from 'kenshin';
import { useEffect, useRef, useState } from 'react';
import styles from './styles.less';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

// 增加、删除按钮样式
const buttonStyle = {
  borderRadius: '16px',
  width: '32px',
  height: '32px',
  textAlign: 'center',
  padding: 0,
  marginLeft: 10,
};

const getDefaultItem = (beginYear) => ({
  beginYear,
  endYear: beginYear + 1,
  holidayDays: '',
  isContainBegin: true,
  isContainEnd: true,
  key: +new Date(),
});

let defauleArr = () => [
  {
    beginYear: 0,
    endYear: 1,
    holidayDays: '',
    key: 0,
    isContainBegin: true,
    isContainEnd: true,
  },
  {
    beginYear: 1,
    holidayDays: '',
    isContainBegin: true,
    key: 1,
    isContainEnd: false,
  },
];
const ComputedRule = ({ onChange, value, unit = "天" }) => {
  const [list, setList] = useState(defauleArr);
  let isSet = useRef(true);

  useEffect(() => {
    if (isSet.current && value) {
      setList(value.map((item, i) => ({ ...item, key: i })));
      isSet.current = false;
    }
  }, [value]);

  const setBeginYear = (i, endYear) => {
    list[i].beginYear = endYear;
    if (list[i].endYear <= endYear) {
      list[i].endYear = endYear + 1;
    }
    if (list[i + 1] && list[i + 1].beginYear != list[i].endYear) {
      setBeginYear(i + 1, endYear + 1);
    }
  };

  const handleToChange = (list) => {
    onChange?.(list.map((item) => ({ ...item, key: undefined })));
  };

  /** 输入框变更 */
  const handleChange = (item, newValue) => {
    Object.assign(item, newValue);
    if (newValue.hasOwnProperty('endYear')) {
      let i = list.findIndex((now) => now.key == item.key);
      if (i != -1) {
        setBeginYear(i + 1, newValue.endYear);
      }
    }
    setList([...list]);
    handleToChange([...list]);
  };

  const add = () => {
    setList((list) => {
      let lastItem = list[list.length - 2];
      let data = getDefaultItem(lastItem.endYear);
      list[list.length - 1].beginYear = data.endYear;
      list.splice(list.length - 1, 0, data);
      handleToChange([...list]);
      return [...list];
    });
  };
  const deleteItem = (i) => {
    setList((list) => {
      if (i == list.length - 2) {
        // 假如删除的是倒数第二个
        list[list.length - 1].beginYear = list[i - 1].endYear;
      }
      list.splice(i, 1);
      handleToChange([...list]);
      return [...list];
    });
  };

  const getBaseStr = (item, i) => {
    if (i == 0) {
      return <span>司龄 {'<'}</span>;
    } else if (i == list.length - 1) {
      return <span>司龄 ≥ {item.beginYear} </span>;
    } else {
      return (
        <span>
          {item.beginYear} {'≤'} 司龄 {'<'}
        </span>
      );
    }
  };

  const renderItem = (item, i) => {
    return (
      <div className={styles.flexBoxCenter} key={item.key}>
        {getBaseStr(item, i)}
        {i != list.length - 1 && (
          <InputNumber
            stepType="inside"
            min={item.beginYear + 1}
            precision={0}
            value={item.endYear}
            onChange={(val) => handleChange(item, { endYear: val })}
          />
        )}
        <span className="center">年时,享有</span>
        <InputNumber
          stepType="inside"
          value={item.holidayDays}
          onChange={(val) => handleChange(item, { holidayDays: val })}
        />
        <span>{unit}假期</span>
        {i == 0 ? (
          <Button type="primary" style={buttonStyle} onClick={add}>
            <PlusOutlined />
          </Button>
        ) : i != list.length - 1 ? (
          <Button
            type="danger"
            style={buttonStyle}
            onClick={() => deleteItem(i)}
          >
            <MinusOutlined />
          </Button>
        ) : null}
      </div>
    );
  };

  return <div className={styles.borderBox}>{list.map(renderItem)}</div>;
};

export default ComputedRule;
