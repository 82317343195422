import React, { useEffect, useImperativeHandle, useRef } from 'react';
import {
  MessageBox,
  Form,
  Radio,
  Input,
  Select,
  Button,
  message,
} from 'kenshin';
import { connect } from 'umi';
import UIType from '@/_util/UIType';
import omit from 'rc-util/lib/omit';

/**
 *
 * @param {String} title 表单对应的label
 * @param {String} dataIndex 表单对应的name
 * @param {Array} columns FormItem渲染数组
 * @param {Object} formItemProps FormItem属性设置
 * @param {String} valueType 表单组件类型
 * @returns
 */

const JsonForm = React.forwardRef(
  ({ data, dispatch, columns, formData, request, ...props }, ref) => {
    useImperativeHandle(ref, () => ({
      form: formRef,
    }));

    const FormProps5086669 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ...props,
    };

    const [formRef] = Form.useForm();

    // 默认render
    const render = (props, item) => {
      const Element = React.createElement(UIType[item.valueType], props);
      return Element;
    };

    // 默认formItemRender
    const formItemRender = (item, index) => {
      let formItemProps = {
        name: item.dataIndex,
        label: item.title,
        extra: item.extra,
        help: item.help,
        ...(item.formItemProps ?? {}),
      };
      if (item.required && !formItemProps.rules) {
        formItemProps.rules = [{ required: true, message: item.required }];
      }

      if (typeof item?.fieldProps?.disabled == 'function') {
        item.fieldProps.disabled = item.fieldProps.disabled?.(formData || {});
      }
      let props = item.fieldProps;
      if (typeof item.props == 'function') {
        props = item.fieldProps();
      }

      let Element;
      // 自定义组件
      if (item.component) {
        Element = React.cloneElement(item.component);
      } else {
        Element = item.render?.(item) || render(props, item);
      }

      return (
        <Form.Item key={index} {...formItemProps}>
          {Element}
        </Form.Item>
      );
    };

    const formItemListRender = (arr) => {
      return arr.map((item, index) => {
        let itemRender = item.formItemRender || formItemRender;
        return itemRender(item, index);
      });
    };
    return (
      <Form form={formRef} {...FormProps5086669}>
        {formItemListRender(columns)}
      </Form>
    );
  },
);
export default JsonForm;
