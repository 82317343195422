import { Checkbox, Select as KsSelect } from 'kenshin';
import { selectPinyinFilter } from '@/_util/componentsUtil';
import { useEffect, useMemo, useState } from 'react';
import { flat } from '@/_util/util';

function getOptionsKey(arr) {
  if (!Array.isArray(arr)) return [];
  return flat(
    arr.map((item) => {
      if (item.options) {
        return item.options.map((item) => item.value);
      }
      return item.value;
    }),
    2,
  );
}

function getDisabledKey(arr) {
  if (!Array.isArray(arr)) return [];
  return flat(
    arr.map((item) => {
      if (item.options) {
        return item.options
          .filter((item) => item.disabled)
          .map((item) => item.value);
      }
      return item.value;
    }),
    2,
  );
}

// checkAlldisabledKey 全选禁用的key，可作为前缀
const Select = ({ checkAlldisabledKey, ...props }) => {
  let [checked, setChecked] = useState(false);
  let [value, setValue] = useState(props.value || []);

  let multiple = props.mode == 'multiple'; // 是否多选

  const getAllKey = useMemo(() => {
    return getOptionsKey(props.options).filter(
      (item) => !item.toString().includes(checkAlldisabledKey),
    );
  }, [props.options]);

  const disabledKeys = useMemo(() => {
    return getDisabledKey(props.options);
  }, [props.options]);

  useEffect(() => {
    if (multiple) {
      if (props.value?.length >= getAllKey?.length) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
  }, [getAllKey]);

  useEffect(() => {
    // 多选功能
    if (multiple) {
      setValue(Array.from(new Set(props.value || [])));
    } else {
      setValue(props.value);
    }
  }, [props.value]);

  const checkedAll = (e) => {
    let value = e.target.checked;
    if (value) {
      props.onChange?.(
        Array.from(new Set([...(props.value || []), ...getAllKey])),
      );
      // props.onChange?.(getAllKey);
      setChecked(true);
    } else {
      // 绕过checkAlldisabledKey 相关
      props.onChange?.([
        ...(props.value || []).filter((item) =>
          item.toString().includes(checkAlldisabledKey),
        ),
        ...disabledKeys,
      ]);
      setChecked(false);
    }
  };

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div style={{ padding: 10 }}>
        <Checkbox onChange={checkedAll} checked={checked}>
          全选
        </Checkbox>
      </div>
    </>
  );
  return (
    <KsSelect
      showSearch
      // listHeight={document.body.offsetHeight - 200}
      listHeight={500}
      // allowClear
      filterOption={(input, option) => {
        // 拼音搜索
        return (
          selectPinyinFilter(option.label, input) ||
          option.label?.indexOf(input) >= 0
        );
      }}
      dropdownRender={multiple ? dropdownRender : undefined}
      {...props}
      value={value}
    />
  );
};

export default Select;
