// ***********************************常量*******************************
// 请假单位
export const UNITTYPE = {
  DAY: 2,
  HOURS: 1,
};
// 是否限额
export const ISLIMIT = {
  Y: 1,
  N: 2,
};
// 发放方式
export const SENDTYPE = {
  MONTH: 1,
  YEAR: 2,
  OTHER: 3,
};
// 发放时间
export const SENDTIME = {
  TIME: 1, // 入职日期
  MONTH: 2, // 按月
  YEAR: 3, // 按年
};
// 计算规则
export const COMPUTEDRULE = {
  AGE: 1, // 按司龄
  FIX: 2, // 固定
};
// 假期有效期
export const VALIDITY = {
  YEAR: 1, // 每年固定时间
  START: 2, // 发放之日起
};

// **********************************配置**********************************
// 请假单位
export const unitOptions = (state) =>
  [
    {
      label: '按天',
      value: UNITTYPE.DAY,
    },
    (state.vacationLimit == ISLIMIT.N ||
      state.vacationLimitRuleMethod == COMPUTEDRULE.FIX) && {
      label: '按小时',
      value: UNITTYPE.HOURS,
    },
  ].filter(Boolean);
// 是否限额
export const isLimitOptions = [
  {
    label: '是',
    value: ISLIMIT.Y,
  },
  {
    label: '否',
    value: ISLIMIT.N,
  },
];
// 发放方式
export const sendTypeOptions = [
  {
    label: '按月自动发放',
    value: SENDTYPE.MONTH,
  },
  {
    label: '按年自动发放',
    value: SENDTYPE.YEAR,
  },
  {
    label: '其他',
    value: SENDTYPE.OTHER,
  },
];
// 发放时间
export const sendTimeOptions = ({ vacationSendMethod }) => {
  let arr = [
    {
      label: '员工入职日期发放',
      value: SENDTIME.TIME,
    },
  ];

  if (vacationSendMethod != SENDTYPE.MONTH) {
    arr.push({
      label: '自然年',
      value: SENDTIME.YEAR,
    });
  }
  if (vacationSendMethod != SENDTYPE.YEAR) {
    arr.push({
      label: '自然月',
      value: SENDTIME.MONTH,
    });
  }
  return arr;
};
// 额度计算规则
export const computedRuleOptions = [
  {
    label: '按工龄发放',
    value: COMPUTEDRULE.AGE,
  },
  {
    label: '固定发放',
    value: COMPUTEDRULE.FIX,
  },
];
// 假期有效期
export const validityOptions = [
  {
    label: '每年固定时间清空',
    value: VALIDITY.YEAR,
  },
  {
    label: '自发放之日起清空',
    value: VALIDITY.START,
  },
];
