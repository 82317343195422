import React, { useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import {
  Button,
  message,
  MessageBox,
  Table,
  Progress,
  notification,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import ModalFormPro from '@/components/ModalFormPro';
import { dicLType } from '@/_util/keysType';
import { useCreation } from 'ahooks';
import { processapplyvalide } from '@/_serveice/admin/process';
import {
  SelectOptionsTransform,
  contractMaterialListOptions,
  findSelectTree,
  findCustomerOptions,
  fileOptions,
  batchErrorHandle,
  beforeShowEditChangeData,
  beforeSaveChangeData,
} from './util';
import { roleType } from '@/_util/keysType';
import {
  OSSImgUrl,
  downloadXlsx,
  splitParamsFoGet,
  downloadFileUrl,
} from '../../../_util/util';
import { exportContractList } from '@/_serveice/contract/list';
import { findContractNumUsed } from '../../../_serveice/contract/list';
import CollectBatch from './components/CollectBatch';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

const contractBelongYearOptions = [-3, -2, -1, 0, 1] // 归属年份暂定上三年到下一年这几个选项
  .map((item) => moment().add(item, 'years').format('YYYY'))
  .map((item) => ({
    label: item,
    value: item,
  }));

const ContractPage = ({ contract, dispatch, systemOrgani, system }) => {
  const modalRef = useRef();

  // useRef改变不会更新组件
  const customerOptions = useRef([]);

  const tableRef = useRef();

  useEffect(() => {
    // 部门树
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
    });
    // 所有员工
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    });
    // 组织架构
    // dispatch({
    //   type: 'systemOrgani/getAllEmployee',
    // });
    // 所有客户
    dispatch({
      type: 'customer/getAllCustomer',
    }).then((res) => {
      customerOptions.current = SelectOptionsTransform(res, null, {
        info: 'custCategory',
        datalist: 'customerList',
      });
    });
  }, []);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  // 打开编辑弹窗
  const showEditModal = (row = null) => {
    setEditModalVisible(true);
    if (row) {
      processapplyvalide({
        applyBusiId: row.contractId,
        applyBusiType: 2,
      }).then((res) => {
        if (!res.data) {
          setBtnDisabled(true);
          message.warn('当前合同存在未完成的流程变更!');
        }
      });
      let newData = beforeShowEditChangeData(row, { system, customerOptions });
      setEditModalData(newData);
    }
  };

  // 弹窗保存
  const editModalSave = (data) => {
    let payload = beforeSaveChangeData(data);
    let collectAmount = payload.contractExpectCollectList.reduce(
      (pre, cur) => pre + cur.collectAmount,
      0,
    );
    if (payload.contractAmount < collectAmount) {
      message.error('收款批次的收款金额之和不能大于合同金额！');
      return;
    }

    let hasEdit = false;
    if (editModalData?.contractId) {
      hasEdit = true;
      payload.contractId = editModalData.contractId;
    }

    dispatch({
      type: 'contract/editContract',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        setBtnDisabled(false);
      }
    });
  };

  const [fileSelect, setFileSelect] = useState({});
  // 表格折叠后的表格
  const expandedRowRender = ({
    contractMaterialList,
    contractNumber,
    contractId,
  }) => {
    const batchDownload = () => {
      if (!fileSelect[contractId]?.length) {
        message.warn('请选择需要下载的文件！');
        return;
      }
      fileSelect[contractId].map((item) => {
        downloadFileUrl(
          `${OSSImgUrl(item.contractMaterial)}`,
          item.contractMaterialName,
        );
      });
    };

    const optionTitleRender = (
      <>
        操作
        <Button type="text" onClick={batchDownload}>
          <VerticalAlignBottomOutlined />
          批量下载
        </Button>
      </>
    );

    const columns = [
      { title: '附件名称', dataIndex: 'contractMaterialName', width: '15%' },
      {
        title: '合同编号',
        width: '15%',
        key: 'contractNumber',
        render: () => contractNumber,
      },
      { title: '附件类型', dataIndex: 'contractMaterialSuff', width: '10%' },
      { title: '附件大小', dataIndex: 'contractMaterialSize', width: '15%' },
      {
        title: optionTitleRender,
        dataIndex: 'contractMaterial',
        width: '55%',
        render: (value) => {
          return (
            <Button type="text" onClick={() => window.open(OSSImgUrl(value))}>
              查看附件详情
            </Button>
          );
        },
      },
    ];

    const idMap = (item) =>
      `${item.contractMaterialType}-${item.contractMaterialSort}`;

    const rowSelection = {
      selectedRowKeys: fileSelect[contractId]?.map(idMap) || [],
      onChange: (idList, rowlist) => {
        fileSelect[contractId] = rowlist;
        setFileSelect({ ...fileSelect });
      },
    };
    return (
      <Table
        rowKey={idMap}
        columns={columns}
        dataSource={contractMaterialList}
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    );
  };

  // 删除
  const delItem = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'contract/deleteContract',
          payload,
        });
      },
    });
  };

  // 合同状态
  const contractStatusObj = {
    1: <div className="status-grey">未开启</div>,
    2: <div className="status-green animate">进行中</div>,
    3: <div className="status-grey">已完成</div>,
    4: <div className="status-red">终止</div>,
  };

  const A9_TableProProps3408393 = useCreation(() => ({
    columns: [
      {
        title: '合同名称',
        width: 150,
        fixed: 'left',
        dataIndex: 'contractName',
      },
      {
        title: '客户名称',
        width: 150,
        fixed: 'left',
        dataIndex: 'custCompname',
      },
      {
        title: '合同编号',
        width: 150,
        dataIndex: 'contractNumber',
      },
      {
        title: '归属年份',
        width: 150,
        dataIndex: 'contractBelongYear',
      },
      // {
      //   dataIndex: 'contractBusiList',
      //   width: 150,
      //   title: '商务',
      //   render:val => val?.map(item => <div>{item.busiAffairs}</div>)
      // },
      {
        dataIndex: 'projectName',
        width: 150,
        title: '项目名称',
      },
      {
        dataIndex: 'contractBusiList',
        width: 150,
        title: '商务负责人',
        render: (val) =>
          val?.map((item, i) => <div key={i}>{item.busiAffairs}</div>),
      },
      {
        dataIndex: 'contractAmount',
        width: 150,
        title: '合同金额含税',
        render: (val) => (val / 100).toFixed(2),
      },
      {
        dataIndex: 'contractTaxAmount',
        width: 180,
        title: '合同金额不含税',
        render: (val) => (val / 100).toFixed(2),
      },
      {
        dataIndex: 'contractTaxRate',
        width: 100,
        title: '税率',
        render: (val) => `${val}%`,
      },
      {
        dataIndex: 'contractCollectionAmount',
        width: 150,
        title: '已收款金额',
        render: (val) => (val / 100).toFixed(2),
      },
      {
        dataIndex: 'contractRemainAmount',
        width: 150,
        title: '剩余尾款',
        // render: (val) => (val / 100).toFixed(2),
        render: (val, row) => (
          <div>
            <span>{+(val / 100).toFixed(2)} </span>
            <Progress
              strokeWidth={6}
              percentage={(val * 100) / (+row.contractCollectionAmount + +val)}
              showText={false}
            />
          </div>
        ),
      },
      {
        dataIndex: 'contractCollectionPercent',
        width: 150,
        title: '合同收款进度',
        render: (val, row) => (
          <div>
            <span>{+val}% </span>
            <Progress strokeWidth={6} percentage={+val} showText={false} />
          </div>
        ),
      },
      {
        dataIndex: 'contractStatus',
        width: 150,
        title: '合同状态',
        render: (val) => contractStatusObj[val],
      },
      {
        key: 'options',
        width: 150,
        fixed: 'right',
        title: '操作',
        render: (row) => {
          return (
            <>
              {row.hasbtnList.includes(btnKeys.edit) && (
                <Button type="text" onClick={() => showEditModal(row)}>
                  编辑
                </Button>
              )}
              {row.hasbtnList.includes(btnKeys.delete) && (
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delItem({ contractId: row.contractId })}
                >
                  删除
                </Button>
              )}
              <div>
                {row.hasbtnList.includes(btnKeys.statusChange) &&
                  row.contractStatus != 3 &&
                  row.contractStatus != 4 && (
                    <Button
                      type="text"
                      style={{ margin: 0 }}
                      onClick={() => {
                        setStatusVisible(true);
                        setStatusData([row.contractId]);
                      }}
                    >
                      修改状态
                    </Button>
                  )}
              </div>
            </>
          );
        },
      },
    ],
  }));

  let num1 = 2800;
  let num2 = 1900;

  const uploadAccept = 'image/*,.doc,.docx,.pdf';
  const zipAccept = '';
  const edtaFormItem = [
    {
      label: '合同名称',
      name: 'contractName',
      type: 'Input',
      required: '请输入合同名称',
      props: {
        placeholder: '请输入合同名称',
        disabled: !!editModalData,
      },
    },
    {
      label: '合同编号',
      name: 'contractNumber',
      type: 'Input',
      props: {
        placeholder: '请输入编号',
        disabled: !!editModalData,
      },
      formItemProps: {
        validateTrigger: 'onBlur',
        rules: [
          { required: true, message: '请输入编号' },
          () => ({
            async validator(_, value) {
              if (value && !editModalData) {
                let isHas = await findContractNumUsed({
                  contractNumber: value,
                }).then((res) => res.success && res.data);
                if (isHas) {
                  return Promise.reject(new Error('当前合同编号已被使用'));
                }
                return Promise.resolve();
              }
            },
          }),
        ],
      },
    },
    {
      label: '合同金额',
      name: 'contractAmount',
      type: 'InputNumber',
      required: '请输入合同金额',
      help: '单位：元',
      props: {
        placeholder: '请输入金额',
        stepType: 'inside',
        step: 1000,
        disabled: !!editModalData,
        min: 0,
      },
    },
    {
      label: '合同税率',
      name: 'contractTaxRate',
      type: 'InputNumber',
      required: '请输入合同税率',
      // help: "单位：元",
      props: {
        placeholder: '请输入合同税率',
        stepType: 'inside',
        formatter: (value) => `${value}%`,
        max: 100,
        min: 0,
        disabled: !!editModalData,
      },
    },
    {
      label: '合同类型',
      name: 'contractType',
      type: 'Select',
      required: '请选择合同类型',
      props: {
        placeholder: '请选择合同类型',
        options: SelectOptionsTransform(
          system.dictypeListAll[dicLType.CONTRACTTYPE],
          null,
          { info: 'simpleData', datalist: 'dataList' },
        ),
      },
    },
    {
      label: '合同归属年份',
      name: 'contractBelongYear',
      type: 'Select',
      required: '请选择合同归属年份',
      props: {
        placeholder: '请选择合同归属年份',
        options: contractBelongYearOptions,
      },
    },
    {
      label: '收款批次',
      name: 'contractExpectCollectList',
      required: '请选择收款批次',
      render: () => <CollectBatch />,
    },
    {
      label: '客户名称',
      name: 'contractCustId',
      type: 'Select',
      required: '请选择客户',
      props: {
        placeholder: '请选择客户',
        options: customerOptions.current,
        disabled: !!editModalData,
      },
    },
    {
      label: '合同周期',
      name: 'contractTime',
      type: 'DatePickerRangePicker',
      required: '请选择合同周期',
      props: {
        disabled: !!editModalData,
      },
    },
    {
      label: '合同归属部门',
      name: 'contractBelongDept',
      type: 'ChooseDepartment',
      required: '请选择合同归属部门',
      props: {
        multiple: false,
      },
    },
    {
      label: '合同归属商务',
      name: 'contractBusiList',
      type: 'Select',
      required: '请选择合同归属商务',
      tooltip: '注意：只有归属商务能看到当前合同',
      props: {
        placeholder: '请选择合同归属商务',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.BUSINESSAFFAIRS]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
        disabled: !!editModalData,
      },
    },
    {
      label: '合同电子版',
      name: 'contractMaterialList1',
      type: 'UploadFile',
      required: '请选择合同电子版',
      props: {
        mode: 'multiple',
        accept: uploadAccept,
        dirUrl: '/employee/avatar',
        multiple: true,
      },
    },
    {
      label: '验收单电子版',
      name: 'contractMaterialList2',
      type: 'UploadFile',
      props: {
        mode: 'multiple',
        accept: uploadAccept,
        dirUrl: '/employee/avatar',
        multiple: true,
      },
    },
    {
      label: '合同备注',
      name: 'contractRemark',
      type: 'TextArea',
      props: {
        placeholder: '请输入合同备注',
      },
    },
  ];

  // 修改状态
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [selectKey, setSelectKey] = useState([]);

  const statusSave = (val) => {
    let projectStatusDTO = {
      contractIdList: Array.from(
        new Set(statusData.map((item) => item.split('-')[0])),
      ),
      contractStatus: val.projectStatus,
    };
    dispatch({
      type: 'contract/statusChange',
      payload: projectStatusDTO,
    }).then((res) => {
      if (res.data) {
        notification.open({
          message: '批量操作部分失败',
          description: batchErrorHandle(res.data),
        });
      }
      setStatusVisible(false);
      setStatusData(null);
    });
  };
  const statusFormItem = [
    {
      label: '状态',
      name: 'projectStatus',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        options: [
          {
            label: '完成',
            value: 3,
          },
          {
            label: '终止',
            value: 4,
          },
        ],
      },
    },
  ];
  const extra = () => [
    <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
      新增合同
    </Button>,
    <Button
      type="primary"
      key={btnKeys.statusChange}
      onClick={() => {
        if (selectKey?.length == 0) {
          message.warn('请选择批量操作的客户！');
          return;
        }
        setStatusVisible(true);
        setStatusData(selectKey);
      }}
    >
      批量修改状态
    </Button>,
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let { contractTimeLong, ...value } = tableRef.current.value;
        let payload = {
          contractAmountMax: value.contractAmountMax ?? '',
          contractAmountMin: value.contractAmountMin ?? '',
          contractBeginTime: value.contractBeginTime ?? '',
          contractEndTime: value.contractEndTime ?? '',
          contractStatus: value.contractStatus ?? '',
          key: value.key ?? '',
          busiAffairsIdList: value.busiAffairsIdList ?? [],
        };
        if (contractTimeLong) {
          payload.beginCooperTime = +moment(contractTimeLong[0]).startOf('d');
          payload.endCooperTime = +moment(contractTimeLong[1]).endOf('d');
        }
        let url = `${baseURL}${exportContractList}${splitParamsFoGet(payload)}`;
        downloadXlsx(url, '合同列表');
      }}
    >
      导出
    </Button>,
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '合同名称、编号',
      },
    },
    {
      name: 'contractTimeLong',
      type: 'DatePickerRangePicker',
      label: '合同周期',
      props: {},
    },
    {
      name: 'contractStatus',
      type: 'Select',
      label: '合同状态',
      props: {
        placeholder: '合同状态',
        options: [
          { label: '未开启', value: 1 },
          { label: '进行中', value: 2 },
          { label: '已完成', value: 3 },
          { label: '终止', value: 4 },
        ],
      },
    },
    {
      name: 'busiAffairsIdList',
      type: 'Select',
      label: '归属商务',
      props: {
        placeholder: '商务',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.BUSINESSAFFAIRS]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
    {
      name: 'contractAmountMin',
      type: 'InputNumber',
      label: '合同最小金额',
      props: {
        placeholder: '合同最小金额（不含税）',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      name: 'contractAmountMax',
      type: 'InputNumber',
      label: '合同最大金额',
      props: {
        placeholder: '合同最大金额（不含税）',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
  ];

  // 拦截搜索
  const tableSubmit = (val) => {
    if (val.contractTimeLong) {
      const [start, end] = val.contractTimeLong;
      val.contractBeginTimeLong = +moment(start).startOf('date');
      val.contractEndTimeLong = +moment(end).endOf('date');
    }
    val.contractTimeLong = undefined;
    if (!!val.contractAmountMax != !!val.contractAmountMin) {
      message.warning('缺少最小金额或最大金额条件！');
      return false;
    }

    val.contractAmountMax &&
      (val.contractAmountMax = val.contractAmountMax * 100);
    val.contractAmountMin &&
      (val.contractAmountMin = val.contractAmountMin * 100);
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (idList) => {
      setSelectKey(idList);
    },
  };

  return (
    <div>
      <TablePro
        params={{
          roleList: JSON.parse(localStorage.getItem('employeeDTO'))?.roleList,
        }}
        request="contract/getContractList"
        data={contract.contractList}
        searchCloumns={searchCloumns}
        rowKey={(row, index) => `${row.contractId}-${index}`}
        extra={extra}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={tableSubmit}
        expandable={{ expandedRowRender }}
        scrollX
        {...A9_TableProProps3408393}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      ></TablePro>

      <ModalFormPro
        title={editModalData ? '编辑' : '新增'}
        width="80%"
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        submitDisabled={btnDisabled}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
          setBtnDisabled(false);
        }}
      />

      <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ contract, systemOrgani, system }) => ({
  contract,
  systemOrgani,
  system,
}))(ContractPage);
