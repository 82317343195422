import request, { mock } from '@/_util/require';
let version = 'v1';

// 删除
export async function etlDelete(params) {
  return request(`/${version}/waper/erp/pc/etlsn/delete`, {
    method: 'GET',
    params,
  });
}

// 新增/修改
export async function etlAddModify(params) {
  return request(`/${version}/waper/erp/pc/etlsn/save`, {
    method: 'POST',
    data: params,
  });
}

// 分页查询
export async function etlGetSNPage(params) {
  return request(`/${version}/waper/erp/pc/etlsn/getsnpage`, {
    method: 'POST',
    data: params,
  });
}
