import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Alert,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Timeline,
  Tabs,
} from 'kenshin';
import { connect, history, useActivate } from 'umi';
import TablePro from '@/components/TablePro';
import renderProcessList from '../components/ProcessList';
import PreviewIndicators from '../components/PreviewIndicators';
import AlertToOsa from '../components/AlertToOSA';
import CollapseSearch from './components/CollapseSearch';
import {
  exportosaweekplanstatdata,
  exportosastatdata,
} from '../../../_serveice/performance/osa';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import ModalFormPro from '@/components/ModalFormPro';

const OSAWEEKOSATYPE = {
  WEEK: 1,
  OSA: 3,
};

const defaultTime = () => [
  moment().startOf('quarter'),
  moment().endOf('quarter'),
];

const Template = ({ dispatch, performance, loadingEf }) => {
  const [performanceType, setPerformanceType] = useState('0');
  const [osaTime, setOsaTime] = useState(defaultTime());
  // 排除导出的key
  const [selectKey, setSelectKey] = useState([]);
  const [selectRows, setSelectRows] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [osaEvaluationExportLoading, setOsaEvaluationExportLoading] =
    useState(false);
  const [exportModalFormVisible, setExportModalFormVisible] = useState(false);
  const [exportYearQuarter, setExportYearQuarter] = useState('2024-Q1');

  /** 表格ref */
  const tableRef = useRef();

  useActivate(() => {
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
  });
  useEffect(() => {
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
  }, []);

  // useEffect(() => {
  //   getManagerCount({}, true);
  //   tableDataSoure({}, true);
  // }, [performanceType])

  /** 获取搜索组件的所有数量 */
  const getManagerCount = (payload = {}, reset) => {
    dispatch({
      type: 'performance/osaquarterstat',
      tabsKey: performanceType,
      reset,
      payload: {
        osaStatBeginTime: osaTime[0]
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss'),
        osaStatEndTime: osaTime[1].endOf('q').format('YYYY-MM-DD HH:mm:ss'),
        ...payload,
      },
    });
  };

  const tableDataSoure = (payload = {}, reset) => {
    let payloads = {
      osaStatBeginTime: osaTime[0]
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss'),
      osaStatEndTime: osaTime[1].endOf('q').format('YYYY-MM-DD HH:mm:ss'),
      ...payload,
    };
    dispatch({
      type: 'performance/queryosaquarteremplist',
      reset,
      tabsKey: performanceType,
      payload: payloads,
    });
  };

  /** 获取当前流程数据 */
  const getProcessList = (osaId, visible) => {
    if (visible) {
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId,
          todoBusiId: '',
        },
      });
    }
  };

  /** 详情页 */
  const toDetail = (row) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: { ...row, title: row.userInfo.value, key: row.osaMuId },
        },
      },
    });
    history.push({
      pathname: '/performance/assessment/OSA/OSADetail',
    });
  };

  const renderIsOsaColumnsItem = (item) => {
    if (performanceType == '0') {
      return [item];
    }
    return [];
  };

  /** 表格表头配置 */
  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      fixed: 'left',
      width: 120,
    },
    ...renderIsOsaColumnsItem({
      dataIndex: 'osaType',
      title: '考核类型',
      width: 120,
      render(val) {
        const key = {
          1: '试用期转正评估',
          2: '绩效评估',
          3: '绩效改进评估',
          4: '个人发展计划',
        };
        return key[val] ?? '-';
      },
    }),
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      width: 120,
      render(value) {
        const key = {
          [OSAWEEKOSATYPE.WEEK]: '周计划',
          [OSAWEEKOSATYPE.OSA]: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaTime',
      title: '考核周期',
      width: 220,
      render(row) {
        const YMD = (time) => moment(time).format('YYYY-MM-DD');
        return [YMD(row.osaBeginTime), YMD(row.osaEndTime)].join(' ~ ');
      },
    },
    {
      dataIndex: 'osaId',
      title: '当前状态',
      width: 120,
      render(val) {
        return val ? (
          <span className="status-green">已提交</span>
        ) : (
          <span className="status-grey">未提交</span>
        );
      },
    },
    {
      dataIndex: 'busiLeader',
      title: '业务负责人',
      width: 120,
      render(busiLeader) {
        return busiLeader?.value;
      },
    },
    {
      dataIndex: 'directLeader',
      title: '主管(考核人)',
      width: 120,
      render(directLeader) {
        return directLeader?.value;
      },
    },
    {
      key: 'osaSumRemark',
      title: '自评/上级/业务负责人',
      width: 180,
      render: (row) => {
        // if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
        //   return <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>;
        // }
        // return [
        //   <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
        //   <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
        //   <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
        // ];

        // if(!row.osaDirectBonusItem) {
        //   row.osaDirectBonusItem = 0;
        // }

        // if(!row.osaDirectTotalScore) {
        //   row.osaDirectTotalScore = 0;
        // }

        // if(!row.osaBusiBonusItem) {
        //   row.osaBusiBonusItem = 0;
        // }

        // if(!row.osaBusiTotalScore) {
        //   row.osaBusiTotalScore = 0;
        // }

        // row.osaBusiTotalScore = 100;
        // row.osaDirectTotalScore = 120;

        if (performanceType != '0') return row.osaDirectTotalScore ?? '-';
        return `${row.osaSelfTotalScore + 0.0 ?? '-'} / ${
          row.osaDirectTotalScore + row.osaDirectBonusItem ?? '-'
        } / ${row.osaBusiTotalScore + row.osaBusiBonusItem ?? '-'}`;
      },
    },
    ...renderIsOsaColumnsItem({
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    }),
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
      render(val, row) {
        return (
          <Tooltip
            title={renderProcessList(row.osaId, performance)}
            onVisibleChange={(visible) => getProcessList(row.osaId, visible)}
            placement="left"
            trigger="click"
            overlayStyle={{
              color: '#333',
              maxWidth: 600,
              fontSize: 12,
              lineHeight: '24px',
            }}
            color="#fff"
          >
            <Button type="text">{val}</Button>
          </Tooltip>
        );
      },
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'options',
      render: (row) => {
        return (
          <>
            <PreviewIndicators row={row} />
            <Button type="text" onClick={() => toDetail(row)}>
              查看
            </Button>
            {/* {[
              'SCORE_ONE_NODE',
              'SUMMER_WEEK_PLAN_ONE_NODE',
              'SCORE_WEEK_PLAN_ONE_NODE',
            ].includes(row.curnNodeNumber) && (
              <Button
                type="text"
                onClick={() => {
                  history.push({
                    pathname: '/performance/selfosa/OSAEditAudit',
                    query: { osaId: row.osaId },
                  });
                }}
              >
                查看变更数据
              </Button>
            )} */}
          </>
        );
      },
    },
  ];

  const handleSubmit = (payload) => {
    if (!payload.deptId) {
      return false;
    }
  };

  let searchData = {
    osaTimeState: [osaTime, setOsaTime],
    getSearchCount: getManagerCount,
  };

  const handleExport = () => {
    setExportLoading(true);
    let excludeEmpList = [
      ...new Set(selectKey.map((item) => item.split('-')?.[1]).filter(Boolean)),
    ];
    let payload = {
      ...performance.managerPageListSearchForm,
      excludeEmpList,
      osaStatType: performanceType == '0' ? '2' : performanceType,
      osaStatusType: undefined,
      osaDirectScoreStatusType: undefined,
      osaType: undefined,
    };

    downloadXlsx(
      `${baseURL}${exportosaweekplanstatdata}`,
      '绩效管理.xlsx',
      'post',
      payload,
      function () {
        setExportLoading(false);
      },
    );
  };

  const handleOsaEvaluationExport = () => {
    setOsaEvaluationExportLoading(true);

    let payload = {
      quarter: '1',
      year: '2024',
    };

    if (exportYearQuarter) {
      let yearQuarter = exportYearQuarter.split('-');
      payload.quarter = yearQuarter[1].replace('Q', '');
      payload.year = yearQuarter[0];
    }

    let url = `${baseURL}${exportosastatdata}${splitParamsFoGet(payload)}`;

    downloadXlsx(url, '公司绩效统计数据.xlsx', 'get', {}, function () {
      setOsaEvaluationExportLoading(false);
    });

    setExportModalFormVisible(false);
  };

  const showOsaEvaluationSelect = () => {
    setExportModalFormVisible(true);
  };

  const extra = () => {
    return (
      <>
        <Button onClick={handleExport} type="primary" loading={exportLoading}>
          导出
        </Button>
        <Button
          onClick={showOsaEvaluationSelect}
          type="primary"
          loading={osaEvaluationExportLoading}
        >
          绩效评估表导出
        </Button>
        {selectRows?.length > 0 && (
          <span style={{ marginLeft: 10, color: '#999', fontSize: 12 }}>
            已排除人员：
            {selectRows.join('、')}
          </span>
        )}
      </>
    );
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList, rows) => {
      setSelectRows([...new Set(rows.map((item) => item.userInfo))]);
      setSelectKey(userIdList);
    },
  };

  const itemEditCloumn = [
    {
      label: '日期选择',
      name: 'osaIndexItemScoreMethod',
      type: 'DatePicker',
      required: '请选择考勤',
      props: {
        picker: 'quarter',
        onChange: (date, dateString) => {
          // let value = e.target.value;
          setExportYearQuarter(dateString);
          console.log('osaIndexItemScoreMethod...', date, dateString);
        },
      },
    },
  ];

  const onSave = () => {
    handleOsaEvaluationExport();
  };

  const onCancel = () => {
    setExportModalFormVisible(false);
  };

  return (
    <div>
      <Tabs activeKey={performanceType} onChange={setPerformanceType}>
        <Tabs.TabPane tab="绩效管理" key="0" />
        <Tabs.TabPane tab="周计划" key="1" />
      </Tabs>
      <CollapseSearch
        onChange={tableDataSoure}
        performanceType={performanceType}
        {...searchData}
      />
      {/* <Alert
        type="info"
        style={{ marginBottom: 10 }}
        showIcon
        title={alertCountText}
        closable={false}
      /> */}
      {/* <AlertToOsa payload={{  deptId: treeKey }} /> */}
      <TablePro
        rowKey={(row, i) => `${row.osaId}-${row.osaMuId}-${row.osaBeginTime}`}
        onSubmit={handleSubmit}
        columns={columns}
        extra={extra}
        curColumns
        scrollX
        loading={loadingEf['performance/queryosaquarteremplist']}
        dataSource={performance.managerPageList}
        actionRef={(ref) => (tableRef.current = ref)}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <ModalFormPro
        width={800}
        title={'绩效评估表导出'}
        visible={exportModalFormVisible}
        edtaFormItem={itemEditCloumn}
        // formData={editModalData}
        onSave={(value, value1) => onSave(value, value1)}
        onCancel={(val) => onCancel()}
      />
    </div>
  );
};

export default connect(({ employee_salary, performance, loading }) => {
  return { employee_salary, performance, loadingEf: loading.effects };
})(Template);
