import React, { useState, useEffect, useRef } from 'react';
import { Upload, MessageBox as Modal, Button, Icon } from 'kenshin';
import { CloseOutlined } from '@ant-design/icons';
// import { getSignature } from '@/services/otherApi';
import request from 'umi-request';
import { getOSSMsg } from '@/_serveice/user';
import './index.less';
import { OSSImgUrl, deepClone } from '../../_util/util';

let ossRootDirectoryBase = ossDirUrl;

const ImageUpload = (props) => {
  const [fileList, setFileList] = useState([]);
  const { value, getUrl, onChange, dirUrl, ...resPeops } = props;
  let fileUrl = `${ossRootDirectoryBase}${dirUrl}/${moment().format(
    'YYYY/MM/DD',
  )}`;

  if (!dirUrl && process.env.NODE_ENV == 'development') {
    return (
      <div style={{ color: 'red' }}>请在属性上添加dirUrl属性，例：/file</div>
    );
  }

  let once = useRef(true);
  useEffect(() => {
    if (fileList.length > 0 && once.current) {
      once.current = false;
      fileList.forEach((item) => {
        request.get(
          `${filrViewUrl}addTask?url=${encodeURIComponent(
            Base64.encode(item.url),
          )}`,
        );
      });
    }
  }, [fileList]);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setFileList(
        value?.map((item) => ({ ...item, url: OSSImgUrl(item.url) })) || [],
      );
    } else {
      setFileList([]);
    }
  }, [value]);

  let timeIndex = useRef(null);
  const handleChange = (file) => {
    let { fileList } = file;

    // console.log("fileList...",fileList);

    fileList.map((file) => {
      file.url ||= `/${fileUrl}/${file.name}`;
    });
    setFileList((list) => {
      // fileList = [...list,...fileList]
      fileList = fileList;
      return fileList;
    });
    // 这里函数会在每个进度时执行
    // 只拿最后一次的值
    if (timeIndex.current) {
      clearTimeout(timeIndex.current);
      timeIndex.current = null;
    }
    timeIndex.current = setTimeout(() => {
      onChange?.(fileList);
    }, 300);
  };

  const getExtraData = async (file) => {
    // 这里设置参数
    let name = file.name + `_${new Date().getTime()}`;

    const fileExtension = file?.name?.split('.').pop();
    //增加时间戳标识
    if (fileExtension) {
      name =
        file.name.replace(`.${fileExtension}`, `_${new Date().getTime()}`) +
        `.${fileExtension}`;
    }

    //同样的文件名，设置不同的链接
    file.url = `${fileUrl}/${name}`;

    // console.log("fileList...111",file);

    const ossInfo = await getOSSMsg().then((res) => res.data);
    const { policy, accessId, signature, host } = ossInfo;
    console.log('getExtraData', {
      key: `${fileUrl}/${name}`,
      policy,
      OSSAccessKeyId: accessId,
      signature,
    });
    return {
      key: `${fileUrl}/${name}`,
      policy,
      OSSAccessKeyId: accessId,
      signature,
    };
  };

  const handlePreview = (item) => {
    window.open(
      `${filrViewUrl}onlinePreview?url=${encodeURIComponent(
        Base64.encode(item.url),
      )}`,
    );
  };

  const handleDelete = (i, e) => {
    e.stopPropagation();
    fileList.splice(i, 1);
    onChange([...fileList]);
  };

  // const fileListRender = (_originNode,_file,fileList) => {
  //   console.log(fileList);
  //   return fileList.map((item, index) => (
  //     <div className="upload-file-list" onClick={() => handlePreview(item)} key={index}>
  //       <div className="file-name" key={index}>
  //         {item.name}
  //       </div>
  //       <CloseOutlined onClick={(e) => handleDelete(index, e)} />
  //     </div>
  //   ))
  // }

  return (
    <>
      <Upload
        {...resPeops}
        action={ossUrl}
        fileList={fileList}
        showUploadList={false}
        onChange={handleChange}
        data={getExtraData}
        // itemRender={fileListRender}
      >
        <Button disabled={resPeops.disabled}>
          <Icon type="upload" />
          上传文件
        </Button>
      </Upload>
      {fileList.map((item, index) => (
        <div
          className="upload-file-list"
          onClick={() => handlePreview(item)}
          key={index}
        >
          <div className="file-name" key={index}>
            {item.name}
          </div>
          <CloseOutlined onClick={(e) => handleDelete(index, e)} />
        </div>
      ))}
    </>
  );
};

export default ImageUpload;
