import { Card, Col, Row, Avatar, Tag } from 'kenshin';
import { OSSImgUrl } from '@/_util/util';
import { connect, history } from 'umi';
import TablePro from '@/components/TablePro';
import ChangeCard from './components/ChangeCard';
import { useEffect } from 'react';

const ChangeRecord = ({ usercenter, process, dispatch }) => {
  /** 获取卡片对比面板内容 */
  const rowsData = usercenter.createProcessData;

  const statusObj = {
    0: '审核中',
    1: '审核通过',
    2: '审核拒绝',
  };

  const columns = [
    {
      dataIndex: 'processAuditName',
      title: '审核人名称',
    },
    {
      dataIndex: 'gmtCreate',
      title: '创建日期',
      render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      dataIndex: 'processAuditRemark',
      title: '审核备注',
    },
    {
      dataIndex: 'processAuditStatus',
      title: '审核状态',
      render: (val) => statusObj[val],
    },
  ];
  return (
    <div className="change-record">
      <ChangeCard
        type={rowsData.todoItemBusiType}
        afterData={JSON.parse(rowsData.dataChange?.dataChangeAfter || '{}')}
        beforeData={JSON.parse(rowsData.dataChange?.dataChangeBefore || '{}')}
      />
      <TablePro
        params={{ completeItemId: rowsData.completeItemId }}
        request="process/getcompletechangelist"
        rowKey={(_, index) => index}
        dataSource={process.completeChangeList}
        columnsOptions={false}
        curColumns
        columns={columns}
      />
    </div>
  );
};

export default connect(({ usercenter, process }) => ({ usercenter, process }))(
  ChangeRecord,
);
