import { connect, history } from 'umi';
import { Button, MessageBox, Table, Tag, message } from 'kenshin';
import TablePro from '@/components/TablePro';
import { DownOutlined } from '@ant-design/icons';
import ModalFormPro from '@/components/ModalFormPro';
import { useEffect, useRef, useState } from 'react';
import { dicLType } from '@/_util/keysType';
import PageLoading from '@/components/pageLoading';
import { exportCustomerListUrl } from '@/_serveice/customer/list';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { editForm } from './_util/cloumns';
import { useReactive } from 'ahooks';
import {
  SelectOptionsTransform,
  findCustomerOptions,
} from '../../contract/list/util';
import WdpAvatar from '../../../components/Avatar';

export const custStatusObj = {
  1: <div className="status-green animate">合作中</div>,
  0: <div className="status-red">终止合作</div>,
};

const CaseList = ({ customer, dispatch, loading, system }) => {
  // 修改状态
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  const [selectKey, setSelectKey] = useState([]);

  const customerOptions = useRef([]);

  const state = useReactive({
    editVisible: false,
    editData: null,
  });

  const tableRef = useRef();

  useEffect(() => {
    dispatch({
      type: 'customer/getcaseindustrylist',
    });
    dispatch({
      type: 'customer/getcasetaglist',
    });
    // 所有客户
    dispatch({
      type: 'customer/getAllCustomer',
    }).then((res) => {
      customerOptions.current = SelectOptionsTransform(res, null, {
        info: 'custCategory',
        datalist: 'customerList',
      });
    });
  }, []);

  const editHandleSave = (val) => {
    console.log(val);
    if (val.caseCustId) {
      val.caseCustId = +val.caseCustId.split('-')?.[1] || null;
    }
    if (val.caseTagList) {
      val.caseTagList = val.caseTagList.map((item) => {
        let [tagId, tagName, tagFlag] = item.split('-');
        return { tagId, tagName, tagFlag };
      });
    }
    let payload = {
      ...(state.editData ?? {}),
      ...val,
    };
    dispatch({
      type: 'customer/addupdatecustcase',
      payload: payload,
    }).then((res) => {
      if (res) {
        state.editData = null;
        state.editVisible = false;
      }
    });
  };

  const editClick = (row) => {
    let caseTagList = row.caseTagList?.map((item) =>
      [item.tagId, item.tagName, item.tagFlag].join('-'),
    );

    let caseCustId = findCustomerOptions(
      customerOptions.current,
      row.caseCustId,
    );

    state.editData = {
      ...row,
      caseTagList,
      caseCustId,
    };
    state.editVisible = true;
  };

  const releaseClick = (row) => {
    let mapping = [1, 2, 1];
    dispatch({
      type: 'customer/puborcancelcustcase',
      payload: {
        caseId: row.caseId,
        // 操作类型：1：发布；2：取消发布；
        optType: mapping[row.caseStatus],
      },
    }).then((res) => {
      if (res) {
        let msg = mapping[row.caseStatus] == 1 ? '发布成功' : '已取消发布';
        message.success(msg);
      }
    });
  };

  // 删除
  const delItem = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'customer/deletecustcaselist',
          payload,
        });
      },
    });
  };

  // 表头数据
  const columnsObj = {
    columns: [
      {
        dataIndex: 'caseName',
        title: '案例名称',
      },
      {
        dataIndex: 'caseCover',
        title: '案例封面',
        render(src) {
          return <WdpAvatar shape="square" size={64} url={src} />;
        },
      },
      {
        dataIndex: 'caseBelongIndustryName',
        title: '行业',
      },
      {
        dataIndex: 'caseSort',
        title: '排序',
      },
      {
        dataIndex: 'caseBelongCompany',
        title: '公司名称',
      },
      {
        dataIndex: 'caseTitle',
        title: '案例标题',
      },
      {
        dataIndex: 'caseBriefDescribe',
        title: '案例简介描述',
      },
      {
        dataIndex: 'caseStatus',
        title: '案例状态',
        render(key) {
          let strObj = {
            0: '待发布',
            1: '已发布',
            2: '取消发布',
          };
          return strObj[key];
        },
      },
      {
        dataIndex: 'caseTagList',
        title: '案例标签列表',
        render(list) {
          return list.map((item) => (
            <Tag.MiniTag type="primary" key={item.tagId}>
              {item.tagName}
            </Tag.MiniTag>
          ));
        },
      },
      {
        key: 'options',
        title: '操作',
        render: (row) => {
          return (
            <>
              <Button type="text" onClick={() => editClick(row)}>
                编辑
              </Button>
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => delItem({ castIdList: [row.caseId] })}
              >
                删除
              </Button>
              <Button type="text" onClick={() => releaseClick(row)}>
                {row.caseStatus != 1 ? '发布' : '取消发布'}
              </Button>
            </>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '案例名称|标题',
      },
    },
    {
      name: 'custList',
      type: 'Select',
      props: {
        mode: 'multiple',
        placeholder: '客户',
        options: customerOptions.current,
      },
    },
    {
      name: 'industryList',
      type: 'Select',
      props: {
        mode: 'multiple',
        placeholder: '行业',
        options: customer.caseIndustryList?.map((item) => ({
          label: item.value,
          value: item.id,
        })),
      },
    },
    {
      name: 'tagList',
      type: 'Select',
      props: {
        mode: 'multiple',
        options: customer.caseTagList?.map((item) => ({
          label: item.value,
          value: [item.id, item.value, item.extendData].join('-'),
        })),
        placeholder: '标签',
      },
    },
  ];
  // 页面头部按钮
  const extra = () => {
    return (
      <>
        <Button
          type="primary"
          key={btnKeys.add}
          onClick={() => (state.editVisible = true)}
        >
          添加案例
        </Button>
      </>
    );
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList) => {
      setSelectKey(userIdList);
    },
  };

  const onSubmit = (payload) => {
    if (payload.custList) {
      payload.custList = payload.custList.map((item) => item.split('-')?.[1]);
    }
    if (payload.tagList) {
      payload.tagList = payload.tagList.map((item) => item.split('-')?.[0]);
    }
    // return false
  };

  return (
    <div>
      <TablePro
        params={{ caseStatus: -1 }}
        request="customer/getcustcasepage"
        searchCloumns={searchCloumns}
        rowKey={'caseId'}
        data={customer.caseList}
        extra={extra}
        curColumns
        // expandable={{ onExpand, expandedRowRender }}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={onSubmit}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        {...columnsObj}
      ></TablePro>

      <ModalFormPro
        title={'案例'}
        width={1200}
        formData={state.editData}
        visible={state.editVisible}
        edtaFormItem={editForm({ customer, customerOptions })}
        onSave={editHandleSave}
        onCancel={() => {
          state.editVisible = false;
          state.editData = null;
        }}
      />
    </div>
  );
};

export default connect(({ customer, loading, system }) => ({
  customer,
  system,
  loading: loading.effects,
}))(CaseList);
