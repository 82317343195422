import { Button, Card, Col, MessageBox, message, Tooltip } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { useEffect, useState } from 'react';
import renderProcessList from '../components/ProcessList';

const defaultTime = () => [
  moment().subtract(7, 'day').startOf('date'),
  moment().subtract(0, 'day').endOf('date'),
];

const ATTENDANCE_APPLY_TYPE = {
  OT: 10, // '加班申请'
  OUT: 11, // '外出申请'
  FORGET: 12, // '忘打卡申请'
  DELAY: 13, // '假期延期申请'
  CANCEL: 14, // '考勤撤销申请'
  VACATION: 15, // '假期申请'
  OT_VACATION: 17, // '加班调休申请'
  CHANGE: 16, // '变更申请'
};

let applyTypeStr = {
  [ATTENDANCE_APPLY_TYPE.OT]: '加班申请',
  [ATTENDANCE_APPLY_TYPE.OUT]: '外出申请',
  [ATTENDANCE_APPLY_TYPE.FORGET]: '忘打卡申请',
  [ATTENDANCE_APPLY_TYPE.DELAY]: '假期延期申请',
  [ATTENDANCE_APPLY_TYPE.CANCEL]: '考勤撤销申请',
  [ATTENDANCE_APPLY_TYPE.VACATION]: '假期申请',
  [ATTENDANCE_APPLY_TYPE.OT_VACATION]: '加班调休申请',
  [ATTENDANCE_APPLY_TYPE.CHANGE]: '变更申请',
};

//申请状态：-1:待提交;0：审核中；1：审核通过；2：审核拒绝；3：终止申请；

const ATTENDANCE_APPLY_STATUS = {
  NOSUBMIT: -1, // '待提交'
  AUDITING: 0, // '审核中'
  PASS: 1, // '审核通过'
  REJECT: 2, // '审核拒绝'
  STOP: 3, // '终止申请'
};

let applyStatusStr = {
  [ATTENDANCE_APPLY_STATUS.NOSUBMIT]: '待提交',
  [ATTENDANCE_APPLY_STATUS.AUDITING]: '审核中',
  [ATTENDANCE_APPLY_STATUS.PASS]: '审核通过',
  [ATTENDANCE_APPLY_STATUS.REJECT]: '审核拒绝',
  [ATTENDANCE_APPLY_STATUS.STOP]: '终止申请',
};
const MyAttendanceApply = ({ attendance, dispatch, systemOrgani }) => {
  const [applyTypeList, setApplyTypeList] = useState([]);
  const [applyTypeObj, setApplyTypeObj] = useState({});

  useEffect(() => {
    dispatch({
      type: 'attendance/getattendanceapplytypelist',
    }).then((applyTypeList) => {
      setApplyTypeList(applyTypeList || []);
      let holidayList =
        applyTypeList?.find((item) => item.id == 15)?.childList || [];
      let applyTypeObj = holidayList.reduce((obj, item) => {
        obj[item.id] = item.name;
        return obj;
      }, {});
      setApplyTypeObj(applyTypeObj);
    });
  }, []);

  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'attendance/deleteattendanceapply',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const changeItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认变更？',
      onOk: () => {
        dispatch({
          type: 'attendance/lunchattendanceapply',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('变更成功');

            setTimeout(() => {
              dispatch({
                type: 'attendance/getmyattendancepage',
                payload: { page: { pageNo: 1, pageSize: 10 } },
              }).then((res) => {
                if (res) {
                }
              });
            }, 1000);
          }
        });
      },
    });
  };

  const getProcessList = (processApplyId, visible) => {
    if (visible) {
      dispatch({
        type: 'attendance/getattendanceprocesstree',
        payload: {
          processApplyId,
        },
      });
    }
  };

  const showEdit = (row) => {
    if (row.applyBusiType == '16') {
      return false;
    }
    let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
    if (row.curnNodeSort < 2) {
      return row.applyMuId == empId && ![1, 3].includes(row.applyStatus);
    }
    if (row.curnNodeSort == 2) {
      return row.applyMuId == empId && row.curnNodeStatus == 0;
    }
    return false;
  };

  const columns = [
    {
      dataIndex: 'attendanceApplyName',
      title: '考勤名称',
      width: 250,
    },
    {
      dataIndex: 'applyBusiType',
      title: '申请类型',
      width: 150,
      render: (val) => applyTypeStr[val],
    },

    // {
    //   dataIndex: 'gmtCreate',
    //   title: '提交时间',
    //   width:150,
    //   render(time, row) {
    //     return `${moment(time).format('YYYY-MM-DD')}`;
    //   },
    // },

    {
      dataIndex: 'applyBeginTime',
      title: '申请时间',
      width: 400,
      render(applyBeginTime, row) {
        if (row.vacationList) {
          return row.vacationList.map((item) => (
            <div key={item.vacationSort}>
              <span
                style={{
                  display: 'inline-block',
                  width: '4em',
                  textAlign: 'right',
                }}
              >
                {applyTypeObj[item.vacationId]}：
              </span>
              {item.applyBeginTime} ~ {item.applyEndTime}
            </div>
          ));
        }
        if (applyBeginTime && row.applyEndTime) {
          return `${applyBeginTime} - ${row.applyEndTime}`;
        }
        // console.log("row,forgetClockApplyDate",row);
        if (row.forgetClockApplyDate) {
          return row.forgetClockApplyDate;
        }
        return '-';
      },
      // render: (applyBeginTime,row) => `${applyBeginTime} - ${row.applyEndTime}`,
    },

    {
      dataIndex: 'curnNodeName',
      title: '当前节点',
      width: 150,
      render(val, row) {
        return (
          <Tooltip
            title={renderProcessList({
              processApplyId: row.attendanceApplyId,
              attendance,
            })}
            onVisibleChange={(visible) =>
              getProcessList(row.attendanceApplyId, visible)
            }
            placement="left"
            trigger="click"
            overlayStyle={{
              color: '#333',
              maxWidth: 600,
              fontSize: 12,
              lineHeight: '24px',
            }}
            color="#fff"
          >
            <Button type="text">{val || '流程正在生成中，请稍后刷新'}</Button>
          </Tooltip>
        );
      },
      // render(val, row) {
      //   return (
      //     <Tooltip
      //       title={renderProcessList(row.osaId, performance)}
      //       onVisibleChange={(visible) => getProcessList(row.osaId, visible)}
      //       placement="left"
      //       trigger="click"
      //       overlayStyle={{
      //         color: '#333',
      //         maxWidth: 600,
      //         fontSize: 12,
      //         lineHeight: '24px',
      //       }}
      //       color="#fff"
      //     >
      //       <Button type="text">{val}</Button>
      //     </Tooltip>
      //   );
      // },
    },

    // {
    //   dataIndex: 'curnNodeStatus',
    //   title: '流程状态11',
    //   width: 150,
    // },
    {
      dataIndex: 'applyStatus',
      title: '流程状态',
      width: 150,
      render: (val) => applyStatusStr[val],
    },
    {
      key: 'options',
      title: '操作',
      width: 150,
      fixed: 'right',
      render: (row) => {
        let empId = JSON.parse(localStorage.getItem('employeeDTO')).empId;
        return (
          <>
            {[0, -1].includes(row.applyOvertimeType) ? (
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => handleToApply()}
              >
                材料补充
              </Button>
            ) : null}

            <Button
              type="text"
              onClick={() => {
                let id = row.attendanceApplyId;
                if (row.applyBusiType == '16') {
                  id = row.applyBusiId;
                }
                handleToApply({ id: id }, 'applyDetail');
              }}
            >
              详情
            </Button>

            {showEdit(row) ? (
              <Button
                type="text"
                onClick={() => {
                  let id = row.attendanceApplyId;
                  handleToApply({ id: id });
                }}
              >
                编辑
              </Button>
            ) : null}
            {row.applyMuId == empId &&
            row.curnNodeSort < 3 &&
            ![1, 3].includes(row.applyStatus) ? (
              <Button
                style={{ color: 'red' }}
                type="text"
                onClick={() =>
                  delItem({ processApplyId: row.attendanceApplyId })
                }
              >
                删除
              </Button>
            ) : null}

            {row.haveAuditChangeProcess == 0 &&
            row.applyStatus == 1 &&
            row.applyMuId ==
              JSON.parse(localStorage.getItem('employeeDTO')).empId &&
            ![13, 16].includes(row.applyBusiType) ? (
              <Button
                type="text"
                onClick={() => {
                  // console.log("['13', '16'].includes(row.applyBusiType)", [13, 16].includes(row.applyBusiType),row.applyBusiType);
                  // return
                  changeItem({
                    ...row,
                    attendanceApplyId: undefined,
                    applyBusiType: 16,
                    applyBeginTime: row.applyBeginTime,
                    applyEndTime: row.applyEndTime,
                    applyBusiId: row.attendanceApplyId
                      ? row.attendanceApplyId
                      : undefined,
                    attendanceApplyName: row.attendanceApplyName + '的变更申请',
                    partinList: [
                      {
                        partinId: JSON.parse(
                          localStorage.getItem('employeeDTO'),
                        ).empId,
                        partinSort: 1,
                      },
                    ],
                  });
                }}
              >
                变更
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  // const delItem = (row) => {
  //   MessageBox.confirm({
  //     title: '确认操作',
  //     content: '你确定要删除吗？',
  //     onOk: () => {
  //       dispatch({
  //         type: 'attendance/deletevacation',
  //         payload: row,
  //       }).then((success) => {
  //         if (success) {
  //           message.success('删除成功');
  //         }
  //       });
  //     },
  //   });
  // };

  const handleToApply = (row = {}, path = 'addApply') => {
    history.push({
      pathname: '/attendance/apply/' + path,
      query: {
        ...row,
      },
    });
  };

  const disabledDate = (current) => {
    return (
      (current && current > moment().endOf('day')) ||
      current < moment().subtract(30, 'day').endOf('day')
    );
  };

  const onSubmit = (payload) => {
    if (payload.statTime) {
      payload.applyBeginTime = payload.statTime[0]
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.applyEndTime = payload.statTime[1]
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.applyBusiType = payload.applyBusiType;
      payload.applyStatus = payload.applyStatus;
      payload.statTime = undefined;
    }
  };

  const searchCloumns = [
    {
      name: 'statTime',
      type: 'DatePickerRangePicker',
      props: {
        allowClear: false,
        // disabledDate,
      },
    },
    {
      name: 'applyBusiType',
      type: 'Select',
      props: {
        placeholder: '申请类型',
        options: applyTypeList?.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        allowClear: true,
      },
    },
    {
      name: 'applyStatus',
      type: 'Select',
      props: {
        placeholder: '流程状态',
        options: [
          { label: '全部', value: -2 },
          { label: '审核中', value: 0 },
          { label: '审核通过', value: 1 },
          { label: '审核拒绝', value: 2 },
          { label: '终止申请', value: 3 },
        ],
      },
    },
  ];

  const extra = () => {
    return (
      <Button type="primary" onClick={() => handleToApply()}>
        新增申请
      </Button>
    );
  };

  return (
    <div>
      <TablePro
        extra={extra}
        searchCloumns={searchCloumns}
        request="attendance/getmyattendancepage"
        rowKey={'attendanceApplyId'}
        scrollX
        onSubmit={onSubmit}
        data={attendance.myattendanceApplyList}
        columns={columns}
        curColumns
      ></TablePro>
    </div>
  );
};

export default connect(({ attendance, loading, systemOrgani }) => ({
  attendance,
  systemOrgani,
  loading: loading.effects,
}))(MyAttendanceApply);
