import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Dropdown,
  message,
  MessageBox,
  Switch,
  Tabs,
  Table,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { useSelector, useDispatch } from 'umi';
import { deepClone } from '@/_util';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { useReactive } from 'ahooks';

const DIR = 1; // 目录
const ITEM = 2; // 指标

function optionsTree(rows) {
  let keyObj = {}; //  存储以id为key的对象（目录）
  let arr = deepClone(rows);
  arr.forEach((item) => {
    if (item.type == DIR) {
      item.childList = [];
      keyObj[item.id] = item;
    }
  });

  let resArr = []; // 构建一颗新树返回
  arr.forEach((item) => {
    let prantId = item.osaIndexCatgPantId ?? item.osaIndexCatgId;
    if (prantId && keyObj[prantId]) {
      keyObj[prantId].childList.push(item);
    } else {
      resArr.push(item);
    }
  });
  return resArr;
}

// 统一设置code跟sort
function setCodeAndSort(arr, prefix, baseCode = 0) {
  arr.forEach((item, index) => {
    let code = [prefix, +baseCode + index + 1].filter(Boolean).join('.');
    item.code = code;
    item.sort = +baseCode + index + 1;
    if (item.childList) setCodeAndSort(item.childList, code);
  });
}

const indicators = ({
  visible,
  onCancel,
  onSave,
  baseCode,
  showPublicTemplate,
}) => {
  // console.log('showPublicTemplat12e', showPublicTemplate);

  const performance = useSelector(({ performance }) => performance);
  const [selectKey, setSelectKey] = useState([]);
  const [selectRow, setSelectRow] = useState([]);

  const [
    osaTemplateIndexTree = performance.publicTemplateList,
    setOsaTemplateIndexTree,
  ] = useState([]);

  const dispatch = useDispatch();

  const state = useReactive({
    tabKey: '1',
  });

  useEffect(() => {
    if (visible) {
      return () => {
        setSelectKey([]);
        setSelectRow([]);
      };
    }
  }, [visible]);


  const handlePublicTemplateListData = () => {
    performance.publicTemplateList?.map((item) => {
      item.childList = [];
      item.type = 0;
      item.status = 1;
      item.id = item.osaTemplateId;
    });
    console.log("处理------------------------",performance.publicTemplateList);
  };

  useEffect(() => {
    // handlePublicTemplateListData();
  }, [performance.publicTemplateList]);

  // 表格
  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      width: '60%',
      render: (row) => {
        if (row.osaTemplateName) return row.osaTemplateName;
        return (
          <EllipsisTooltip
            value={`${row.code} ${
              row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
            }`}
            width={200}
          />
        );
      },
    },
    // Table.EXPAND_COLUMN,
    {
      dataIndex: 'status',
      title: '状态',
      width: '10%',
      // render: (val, row) => row.osaTemplateId ?? (val ? '正常' : '禁用'),
      render: (val, row) => (val ? '-' : '禁用'),
    },
    // {
    //   dataIndex: 'remark',
    //   title: '指标描述',
    //   width: '30%',
    //   render: (remark) => {
    //     return <EllipsisTooltip value={remark} width={200} />;
    //   },
    // },
    {
      dataIndex: 'weight',
      title: '权重',
      width: '10%',
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: '10%',
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },

    {
      title: '操作',
      width: '10%',
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.childList?.length > 0 && (
              <Button
                type="text"
                onClick={() => {
                  console.log('全选');
                  setSelectKey((list) => {
                    let arr = [
                      ...new Set([
                        ...list,
                        ...row.childList
                          .filter((item) => item.status == 1)
                          .map((item) => `${item.type}-${item.id}`),
                      ]),
                    ];
                    return arr;
                  });
                  setSelectRow((list) => {
                    let arr = [
                      ...new Set([
                        ...list,
                        ...row.childList.filter((item) => item.status == 1),
                      ]),
                    ];
                    console.log('list:', list, 'row:', row, 'arr:', arr);
                    return arr;
                  });
                }}
              >
                全选
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const handleSave = () => {
    let tree = optionsTree(selectRow);
    setCodeAndSort(tree, null, baseCode);
    onSave?.(tree);
  };

  // 表格配置数据
  const tableConfig = {
    columnsOptions: false,
    pagination: false,
    columns: columns,
    curColumns: true,
    scrollY: 700,
    childrenColumnName: 'childList',
  };
  return (
    <MessageBox
      width={1000}
      title="添加指标"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSave}
    >
      {showPublicTemplate && (
        <Tabs
          activeKey={state.tabKey}
          type="mini"
          onChange={(v) => (state.tabKey = v)}
        >
          <Tabs.TabPane tab="个人" key="1" />
          <Tabs.TabPane tab="公共" key="2" />
        </Tabs>
      )}

      {state.tabKey == '1' && (
        <TablePro
          request="performance/getosaindexlibtree"
          rowKey={(row) => `${row.type}-${row.id}`}
          dataSource={performance.osaindexlibtree}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectKey,
            getCheckboxProps: (row) => ({ disabled: !row.status }),
            onChange: (keys, rows) => {
              setSelectKey(keys);
              setSelectRow(rows);
            },
          }}
          {...tableConfig}
        />
      )}
      {state.tabKey == '2' && (
        <TablePro
          request="performance/getcommonosatemplatelistwithchildList"
          rowKey={(row) => `${row.type}-${row.id}`}
          dataSource={performance.publicTemplateListWithchildList}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectKey,
            onChange: (keys, rows) => {
              setSelectKey(keys);
              setSelectRow(rows);

              // handlePublicTemplateListData(performance.publicTemplateList)
            },
            onSelect: (record, selected, selectedRows, nativeEvent) => {
              console.log('onSelect,', record, selected);
            },
          }}
          {...tableConfig}
          expandable={{
            // expandedRowRender: (record) => (
            //   <p style={{ margin: 0 }}>{}</p>
            // ),
            onExpand: (record, event) => {
              console.log(event);

              if (record && event.type === 0) {
                //公共模板展开回调

                dispatch({
                  type: 'performance/getosatemplateindextree_',
                  payload: { osaTemplateId: event.osaTemplateId },
                }).then((res) => {
                  if (res) {
                    console.log(
                      'publicTemplateList',
                      performance.publicTemplateListWithchildList,
                    );
                  }
                });
              }
            },
            rowExpandable: (record) => {
              return true;
            },
          }}
        />
      )}
    </MessageBox>
  );
};

export default indicators;
