import { createSse } from '../../../_serveice/openai';

export function createEventSource({ uid, onmessage, done, name = '新聊天' }) {
  let token = localStorage.getItem('token') ?? null;
  let pcDeviceCode = localStorage.getItem('pcDeviceCode') ?? null;
  let muId = localStorage.getItem('muId') ?? null;
  return new Promise((reslove, reject) => {
    let uuid_str = uid;
    let sse;
    let text = '';
    let createSseUrl = createSse(uid.replaceAll('-', ''), name);
    //创建sse
    const eventSource = new EventSourcePolyfill(createSseUrl, {
      headers: {
        platType: 'PC',
        deviceCode: pcDeviceCode,
        muId,
        accessToken: token,
      },
    });

    eventSource.onopen = (event) => {
      sse = event.target;
      reslove(sse);
    };
    eventSource.onmessage = (event) => {
      if (event.lastEventId == '[TOKENS]') {
        text = text + event.data;
        onmessage?.(text, uuid_str);
        text = '';
        return;
      }
      if (event.data == '[DONE]') {
        if (sse) {
          sse.close();
          done?.();
        }
        return;
      }
      let json_data = JSON.parse(event.data);
      if (json_data.content == null || json_data.content == 'null') {
        return;
      }
      text = text + json_data.content;
      onmessage?.(text, uuid_str);
    };
    eventSource.onerror = (event) => {
      console.log('onerror', event);
      console.log('服务异常请重试并联系开发者！');
      if (event.readyState === EventSource.CLOSED) {
        console.log('connection is closed');
      } else {
        console.log('Error occured', event);
      }
      event.target.close();
    };
  });
}

/** 获取uuid */
export function getUuid() {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  var uuid = s.join('');
  return uuid;
}

/** 设置服务端参数格式 */
export function createHistoryMsg(
  arr,
  sid,
  startTime = moment().format('YYYY-MM-DD HH:mm:ss'),
) {
  let [problem, answer] = arr;
  return [
    {
      msgContent: problem,
      msgDateTime: startTime,
      msgId: sid,
    },
    {
      msgContent: answer,
      msgDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      msgId: `${sid}:reply`,
    },
  ];
}
