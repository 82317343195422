import { useRef } from 'react';
import { useDrag, useDrop } from 'ahooks';
import { useState } from 'react';

export const DraggableBodyRow = ({
  onChange,
  current,
  onDragStart,
  onDragEnd,
  ...props
}) => {
  const [dragging, setDragging] = useState(false);
  const [hoverClass, setHoverClass] = useState(false);
  const ref = useRef(null);
  const dropType = useRef('');
  useDrag(props['data-row-key'], ref, {
    onDragStart: (e) => {
      //
      setDragging(true);
      onDragStart?.({
        top: ref.current.offsetTop,
      });
    },
    onDragEnd: () => {
      setDragging(false);
      onDragEnd?.();
    },
  });
  useDrop(ref, {
    onDom: (content, e) => {
      setHoverClass(false);
      onChange?.(content, props['data-row-key'], dropType.current);
    },
    onDragEnter: (e) => {
      let dropTop = ref.current.offsetTop;
      // dropType.current = dropTop < current.top ? 'dropUp' : 'dropDown';
      // dropType.current = "dropDown";
      dropType.current = 'droping';
      setHoverClass(dropType.current);
    },
    onDragLeave: () => setHoverClass(''),
  });
  return (
    <tr
      ref={ref}
      {...props}
      className={`${props.className} ${hoverClass} ${
        dragging ? 'dragging' : ''
      }`}
      style={{ cursor: 'move' }}
    />
  );
};
