import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button, Table, Tabs } from 'kenshin';
import TablePro from '@/components/TablePro';
import AnalyseCharts from '@/components/AnalyseCharts';
import { connect, useSelector, useDispatch } from 'umi';
import { useUpdateEffect } from 'ahooks';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';

const DataAnalysePage = forwardRef(
  (
    {
      dataRule,
      dispatchType,
      dataType,
      columns,
      rowTableColums,
      timeFormat = 'YYYY-MM-DD HH:mm:ss',
      tableKey,
      rowTableKey,
      searchCloumns: extraSearchCloumns = [],
      defaultTime: propsDefaultTime,
      picker,
      onExpand: onExpandProps,
      tableProps = {},
      otherParams = {},
      downloadUrl,
      downloadName,
      chartsProps = {},
    },
    ref,
  ) => {
    const dataAnalyse = useSelector(({ dataAnalyse }) => dataAnalyse);
    const dispatch = useDispatch();

    const defaultTime = propsDefaultTime || [
      moment().subtract(7, 'day').startOf('date'),
      moment().subtract().endOf('date'),
    ];
    const [timeArr, setTimeArr] = useState([...defaultTime]);
    // 控制表格展开
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    /** 表格ref */
    const tableRef = useRef();

    useUpdateEffect(
      () => {
        tableRef.current?.reload(otherParams);
        getChartsByTime({
          statBeginTime: moment(timeArr[0]).format(timeFormat),
          statEndTime: moment(timeArr[1]).endOf('d').format(timeFormat),
          ...otherParams,
        });
      },
      Object.keys(otherParams).map((item) => otherParams[item]),
    );

    useImperativeHandle(ref, () => ({
      ...(tableRef.current ?? {}),
    }));

    const getChartsByTime = (payload) => {
      dispatch({
        type: `dataAnalyse/${dispatchType.charts}`,
        dataRule,
        payload,
      });
    };

    // useEffect(() => {
    //   getChartsByTime({
    //     statBeginTime: defaultTime[0].format(timeFormat),
    //     statEndTime: defaultTime[1].format(timeFormat),
    //     ...otherParams,
    //   });
    // }, []);

    const extra = () => {
      return (
        <>
          <AnalyseCharts {...dataAnalyse[dataType.charts]} {...chartsProps} style={{ minWidth: 1000 }} />
          {downloadUrl && (
            <Button
              type="primary"
              onClick={() => {
                if (tableRef.current.value) {
                  let { searchTime } = tableRef.current.value;
                  searchTime ||= defaultTime;
                  const [start, end] = searchTime;
                  const statBeginTime = moment(start).format(timeFormat);
                  const statEndTime = moment(end).format(timeFormat);
                  let payload = {
                    statBeginTime,
                    statEndTime,
                    ...otherParams,
                    tabsKey: undefined,
                  };
                  let url = `${baseURL}${downloadUrl}${splitParamsFoGet(
                    payload,
                  )}`;
                  downloadXlsx(url, downloadName);
                }
              }}
            >
              导出数据
            </Button>
          )}
        </>
      );
    };

    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
    };
    const searchCloumns = [
      {
        name: 'searchTime',
        label: '日期范围',
        type: 'DatePickerRangePicker',
        props: {
          defaultValue: defaultTime,
          disabledDate,
          picker,
        },
        span: 8,
      },
      ...extraSearchCloumns,
    ];

    const expandedRowRender = (props) => {
      return (
        <Table
          rowKey={rowTableKey}
          columns={rowTableColums}
          dataSource={dataAnalyse[dataType.listById]?.[props[tableKey]] || []}
          pagination={false}
        />
      );
    };

    const onExpand =
      onExpandProps?.({ defaultTime }) ||
      ((expanded, props) => {
        if (expanded) {
          dispatch({
            type: `dataAnalyse/${dispatchType.listById}`,
            id: tableKey,
            payload: {
              [tableKey]: props[tableKey],
              statBeginTime: moment(timeArr[0]).format(timeFormat),
              statEndTime: moment(timeArr[1]).endOf('d').format(timeFormat),
              ...otherParams,
            },
          });
        }
      });

    const onSubmit = (params) => {
      let { statBeginTime, statEndTime } = params;
      if (params.searchTime) {
        const [start, end] = params.searchTime;
        params.statBeginTime = moment(start).format(timeFormat);
        params.statEndTime = moment(end).endOf('d').format(timeFormat);
        setTimeArr(params.searchTime);
      }
      params.searchTime = undefined;
      if (statBeginTime && statEndTime) {
        getChartsByTime({
          ...params,
          ...otherParams,
          page: undefined,
        });
      }
      setExpandedRowKeys([]);
      dispatch({
        type: 'dataAnalyse/save',
        payload: {
          customerListById: {},
          projectListById: {},
          collectListById: {},
          costListById: {},
        },
      });
    };
    return (
      <div>
        <TablePro
          params={{
            statBeginTime: defaultTime[0].format(timeFormat),
            statEndTime: defaultTime[1].format(timeFormat),
            ...otherParams,
          }}
          request={`dataAnalyse/${dispatchType.list}`}
          rowKey={tableKey}
          searchCloumns={searchCloumns}
          columns={columns}
          curColumns
          data={dataAnalyse[dataType.list]}
          extra={extra}
          columnsOptions={false}
          actionRef={(ref) => (tableRef.current = ref)}
          expandable={{
            expandedRowRender,
            onExpand,
            expandedRowKeys,
            onExpandedRowsChange: setExpandedRowKeys,
          }}
          onSubmit={onSubmit}
          {...tableProps}
        />
      </div>
    );
  },
);

export default DataAnalysePage;
