import { Col, Radio, Row } from 'kenshin';
import PieShade from '../../../components/charts/PieShade';
import AnalyseCharts from '@/components/AnalyseCharts';
import { useEffect, useState } from 'react';
import { useDispatch } from 'umi';
import styles from './index.less';
import { changeNumToHan } from '@/_util/util';

let datas = [
  {
    name: '正常',
    type: 'bar',
    data: [
      40.0, 40.9, 30.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 62.4, 3.3,
    ],
    color: '#42c697',
  },
  {
    type: 'bar',
    name: '旷工',
    data: [
      12.6, 15.9, 19.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 38.8, 62.0, 22.3,
      23.4, 23.0, 16.5, 12.0, 6.2,
    ],
    color: '#4389f9',
  },
  {
    type: 'bar',
    name: '迟到',
    data: [
      28.7, 70.7, 175.6, 182.2, 23.6, 51.9, 91.0, 26.4, 48.7, 18.8, 56.0, 23.3,
      182.2, 48.7, 18.8, 6.0, 2.3,
    ],
    color: '#fec416',
  },
];

const pieChartRadioOptions = [
  { label: '月度对比', value: 1 },
  { label: '季度对比', value: 2 },
  { label: '年底对比', value: 3 },
];

const lineChartRadioOptions = [
  { label: '月度对比', value: 1 },
  { label: '季度对比', value: 2 },
  { label: '年底对比', value: 3 },
  { label: '部门本年度对比', value: 4 },
];

const Personal = ({}) => {
  const dispatch = useDispatch();
  const [charts, setCharts] = useState({});
  useEffect(() => {
    dispatch({
      type: 'usercenter/getFinanceProdCharts',
    }).then((res) => {
      setCharts(res);
    });
  }, []);

  const xData = new Array(12)
    .fill('')
    .map((_, i) => `${changeNumToHan(i + 1)}月`);

  return (
    <>
      <div className={styles.chart}>
        <Row gutter={20}>
          <Col flex="410px">
            <div className="chart-box">
              <div className="filter-form">
                <Radio.Group
                  options={pieChartRadioOptions}
                  size="small"
                  optionType="button"
                />
              </div>
              <div className="shadow-charts">
                <PieShade data={charts} />
              </div>
            </div>
          </Col>
          <Col flex="auto">
            <div className="chart-box">
              <div className="filter-form">
                <Radio.Group
                  options={lineChartRadioOptions}
                  size="small"
                  optionType="button"
                />
              </div>
              <AnalyseCharts data={datas} xData={xData} showDataZoom={false} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Personal;
