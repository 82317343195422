import request, { mock } from '@/_util/require';
let version = 'v1';

export * as template from './template';

// ==================================================================指标库==================================================================

/** 查询绩效指标库的树结构 */
export async function getosaindexlibtree(params) {
  return request(`/${version}/waper/erp/pc/osa/indexlib/getosaindexlibtree`, {
    method: 'GET',
    params,
  });
}
/** 查询绩效指标库的目录树结构 */
export async function getosaindexcatgtree(params) {
  return request(`/${version}/waper/erp/pc/osa/indexlib/getosaindexcatgtree`, {
    method: 'GET',
    params,
  });
}
/**删除指标库条目 */
export async function deleteindexlibitem(params) {
  return request(`/${version}/waper/erp/pc/osa/indexlib/deleteindexlibitem`, {
    method: 'GET',
    params,
  });
}
/** 删除指标库目录 */
export async function deleteindexlibcatg(params) {
  return request(`/${version}/waper/erp/pc/osa/indexlib/deleteindexlibcatg`, {
    method: 'GET',
    params,
  });
}

/** 新增指标库目录 */
export async function addindexlibcatgdata(options) {
  return request(`/${version}/waper/erp/pc/osa/indexlib/addindexlibcatgdata`, {
    method: 'POST',
    data: options,
  });
}
/** 新增指标库条目 */
export async function addindexlibitemdata(options) {
  return request(`/${version}/waper/erp/pc/osa/indexlib/addindexlibitemdata`, {
    method: 'POST',
    data: options,
  });
}
/** 修改指标库目录 */
export async function updateindexlibcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/indexlib/updateindexlibcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改指标库条目 */
export async function updateindexlibitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/indexlib/updateindexlibitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 禁用或者启用指标目录 */
export async function enableordisableindexcatg(options) {
  return request(
    `/${version}/waper/erp/pc/osa/indexlib/enableordisableindexcatg`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 禁用或者启用指标 */
export async function enableordisableindexitem(options) {
  return request(
    `/${version}/waper/erp/pc/osa/indexlib/enableordisableindexitem`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 员工提交绩效统计查询 */
export async function osaempcommitstatlist(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/osaempcommitstatlist`, {
    method: 'POST',
    data: options,
  });
}

/** 季度绩效统计汇总 */
export async function osaquarterstat(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/osaquarterstat`, {
    method: 'POST',
    data: options,
  });
}
/** 周计划统计汇总 */
export async function osaweekplanstat(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/osaweekplanstat`, {
    method: 'POST',
    data: options,
  });
}

/** 查询所有员工季度绩效列表 */
export async function queryosaquarteremplist(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/queryosaquarteremplist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 查询所有员工周计划列表 */
export async function queryosaweekplanemplist(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/queryosaweekplanemplist`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// ==================================================================指标库end==================================================================
