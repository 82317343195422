import request, { mock } from '@/_util/require';
let version = 'v1';

/** 新增假期 */
export async function addvacation(options) {
  return request(`/${version}/waper/erp/pc/attendance/addvacation`, {
    method: 'POST',
    data: options,
  });
}
/** 删除假期 */
export async function deletevacation(params) {
  return request(`/${version}/waper/erp/pc/attendance/deletevacation`, {
    method: 'GET',
    params,
  });
}
/** 查询所有假期列表 */
export async function getallvacationlist(params) {
  return request(`/${version}/waper/erp/pc/attendance/getallvacationlist`, {
    method: 'GET',
    params,
  });
}
/** 分页查询员工假期余额列表 */
export async function getemployeevacationbalancepage(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/getemployeevacationbalancepage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 查询员工假期变动列表 */
export async function getemployeevacationchangelist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getemployeevacationchangelist`,
    {
      method: 'GET',
      params,
    },
  );
}
/** 查询员工假期详细列表 */
export async function getemployeevacationdetaillist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getemployeevacationdetaillist`,
    {
      method: 'GET',
      params,
    },
  );
}
/** 设置假期状态 */
export async function setvacationstatus(options) {
  return request(`/${version}/waper/erp/pc/attendance/setvacationstatus`, {
    method: 'POST',
    data: options,
  });
}
/** 修改员工假期余额 */
export async function updateempvacationremaindays(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/updateempvacationremaindays`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改假期 */
export async function updatevacation(options) {
  return request(`/${version}/waper/erp/pc/attendance/updatevacation`, {
    method: 'POST',
    data: options,
  });
}
/** 导入员工假期余额数据 */
// export const importemployeevacationdata = `/${version}/waper/erp/pc/attendance/importemployeevacationdata`;
export async function importemployeevacationdata(options, query) {
  return request(
    `/${version}/waper/erp/pc/attendance/importemployeevacationdata/${query}`,
    {
      method: 'POST',
      data: options,
    },
  );
}

export const downloadimportemployeevacationtemplate = `/${version}/waper/erp/pc/attendance/downloadimportemployeevacationtemplate`;

/** 分页查询员工考勤列表 */
export async function getemployeeattendancepage(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/getemployeeattendancepage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 修改员工考勤数据 */
export async function updateemployeeattendancedata(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/updateemployeeattendancedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 同步员工打卡数据 */
export async function asyncemployeeclockdata(params) {
  return request(`/${version}/waper/erp/pc/attendance/asyncemployeeclockdata`, {
    method: 'GET',
    params,
  });
}

// export async function exportemployeevacationlist(options) {
//   return request(
//     `/${version}/waper/erp/pc/attendance/exportemployeevacationlist`,
//     {
//       method: 'POST',
//       data: options,
//       isExport: true,
//       responseType: 'arraybuffer',
//     },
//   );
// }

/** 分页查询我的考勤列表 */
export async function getmyattendancepage(options) {
  return request(`/${version}/waper/erp/pc/attendance/getmyattendancepage`, {
    method: 'POST',
    data: options,
  });
}

/** 同步员工打卡数据 */
export async function getattendanceapplytypelist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendanceapplytypelist`,
    {
      method: 'GET',
      params,
    },
  );
}
/** 发起考勤申请 */
export async function lunchattendanceapply(options) {
  return request(`/${version}/waper/erp/pc/attendance/lunchattendanceapply`, {
    method: 'POST',
    data: options,
  });
}

/** 查询考勤申请详细数据 */
export async function getattendanceapplydetaildata(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendanceapplydetaildata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 考勤列表状态 */
export async function getempattendancestatuslist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getempattendancestatuslist`,
    {
      method: 'GET',
      params,
    },
  );
}
/** 查询考勤流程图信息 */
export async function getattendanceprocesstree(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendanceprocesstree`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询考勤审核系统原因列表 */
export async function getattendanceauditsystemdescribelist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendanceauditsystemdescribelist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 审核考勤流程 */
export async function auditattendanceprocess(options) {
  return request(`/${version}/waper/erp/pc/attendance/auditattendanceprocess`, {
    method: 'POST',
    data: options,
  });
}
/** 提交考勤证明材料 */
export async function commitattendanceprovematerial(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/commitattendanceprovematerial`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 查询考勤审核系统原因列表 */
export async function getattendancetimelistbytype(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendancetimelistbytype`,
    {
      method: 'GET',
      params,
    },
  );
}
/** 撤销考勤审核操作 */
export async function auditattendancerevoke(params) {
  return request(`/${version}/waper/erp/pc/attendance/auditattendancerevoke`, {
    method: 'GET',
    params,
  });
}
/** 删除考勤申请 */
export async function deleteattendanceapply(params) {
  return request(`/${version}/waper/erp/pc/attendance/deleteattendanceapply`, {
    method: 'GET',
    params,
  });
}

/** 分页查询所有的考勤列表 */
export async function getallattendancepage(options) {
  return request(`/${version}/waper/erp/pc/attendance/getallattendancepage`, {
    method: 'POST',
    data: options,
  });
}
/** 人事修改审核通过的考勤申请 */
export async function hrupdateattendanceapplyauditpass(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/hrupdateattendanceapplyauditpass`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 人事撤销审核通过的考勤申请 */
export async function hrrevokeattendanceapplyauditpass(options) {
  return request(
    `/${version}/waper/erp/pc/attendance/hrrevokeattendanceapplyauditpass`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 员工考勤打卡统计 */
export async function empclockstatistics(options) {
  return request(`/${version}/waper/erp/pc/attendance/empclockstatistics`, {
    method: 'POST',
    data: options,
  });
}
/** 查询员工假期可用天数 */
export async function getempvacationenabledays(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getempvacationenabledays`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 查询用户管理的所有员工列表 */
export async function getmanageallemplist(params) {
  return request(`/${version}/waper/erp/pc/attendance/getmanageallemplist`, {
    method: 'GET',
    params,
  });
}
/** 修正考勤申请时间 */
export async function fixattendanceapplytime(params) {
  return request(`/${version}/waper/erp/pc/attendance/fixattendanceapplytime`, {
    method: 'POST',
    data: params,
  });
}
/** 校验考勤申请时间 */
export async function validateattendanceapplytime(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/validateattendanceapplytime`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 查询考勤上下半天数据 */
export async function getattendanceupdowndaysquery(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendanceupdowndaysquery`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 分页查询员工外出打卡列表 */
export async function getemployeeoutclockpage(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getemployeeoutclockpage`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 查询我的已经审核通过的外出申请列表 */
export async function getmygooutapplyauditpasslist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getmygooutapplyauditpasslist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询假期默认延期时间 */
export async function getvacationdefaultdelaytime(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getvacationdefaultdelaytime`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询业务负责人管理的所有员工列表 */
export async function getbusimanageallemplist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getbusimanageallemplist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询员工可用假期明细列表 */
export async function getempvacationenabledetaillist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getempvacationenabledetaillist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询员工假期消耗明细列表 */
export async function getempvacationconsumedetaillist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getempvacationconsumedetaillist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询员工假期扣减明细列表 */
export async function getempvacationdeductdetaillist(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getempvacationdeductdetaillist`,
    {
      method: 'GET',
      params,
    },
  );
}
/** 导出员工假期列表 */
export const exportemployeevacationlist = `/${version}/waper/erp/pc/attendance/exportemployeevacationlist`;

/** 查询员工首页考勤统计信息 */
export async function getempindexattendancestatdata(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/statistics/getempindexattendancestatdata`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 查询员工首页考勤统计信息 */
export async function getindexattendancetotalstatdata(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/statistics/getindexattendancetotalstatdata`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 查询考勤时间(除了:加班|调休) */
export async function getattendancehourtimequery(params) {
  return request(
    `/${version}/waper/erp/pc/attendance/getattendancehourtimequery`,
    {
      method: 'POST',
      data: params,
    },
  );
}

/** 导出公司考勤统计数据 */
export const exportattendancestatdata = `/${version}/waper/erp/pc/attendance/statistics/exportattendancestatdata`;
