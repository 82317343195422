import Jsonform from '@/components/JsonForm';
import { Collapse, InputNumber } from 'kenshin';
import { useEffect, useRef, useState } from 'react';

const defaultKey = {
  osaScoreEndDaysBeforeAfter: 'osaScoreEndDaysBeforeAfter',
};

const Info = ({ onSave, defaultValue, propsKey = defaultKey, disabled }) => {
  const [days, setDays] = useState(defaultValue);
  useEffect(() => setDays(defaultValue), [defaultValue]);

  // 标识是否有修改数据
  const hasChange = useRef(false);

  const handleChange = (value) => {
    setDays(value);
    hasChange.current = true;
  };

  const handleLeave = () => {
    if (hasChange.current) {
      hasChange.current = false;
      onSave?.({ [propsKey.osaScoreEndDaysBeforeAfter]: days });
    }
  };

  return (
    <div className="flex-al-center border-card" onMouseLeave={handleLeave}>
      <span style={{ marginRight: '4%' }}>评分截止时间</span>
      每期结束前
      <InputNumber
        stepType="inside"
        value={days}
        disabled={disabled}
        placeholder="请输入天数"
        style={{ margin: '0 20px' }}
        onChange={handleChange}
      />
      天（负数代表到期后几天，到期会通知关联评分人进行评分)
    </div>
  );
};

export default Info;
