let size = 12;
export const pageDown = (
  <svg viewBox="0 0 1024 1024" width={size} height={size}>
    <path
      d="M597.333333 640V85.333333c0-23.68-19.285333-42.666667-43.093333-42.666666h-84.48A42.666667 42.666667 0 0 0 426.666667 85.333333v554.666667H341.717333c-47.232 0-62.805333 30.549333-34.56 68.266667l153.642667 204.8c28.501333 37.973333 74.112 37.717333 102.4 0l153.6-204.8c28.501333-37.973333 12.8-68.266667-34.517333-68.266667H597.333333z"
      fill="#fff"
    ></path>
  </svg>
);
export const pageUp = (
  <svg viewBox="0 0 1024 1024" width={size} height={size}>
    <path
      d="M426.752 344.149333v554.666667c0 23.68 19.285333 42.666667 43.093333 42.666667h84.48a42.666667 42.666667 0 0 0 43.093334-42.666667v-554.666667h84.949333c47.232 0 62.805333-30.549333 34.56-68.266666l-153.642667-204.8c-28.501333-37.973333-74.112-37.717333-102.4 0l-153.6 204.8c-28.501333 37.973333-12.8 68.266667 34.517334 68.266666h84.949333z"
      fill="#fff"
    ></path>
  </svg>
);
