import { Tooltip, Typography } from 'kenshin';
import styles from './index.less';

const EllipsisTooltip = ({ value, width, ...props }) => {
  let ellipsisStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: width ?? 'inherit',
  };

  return (
    <Tooltip
      title={<pre className={styles.preWrap}>{value}</pre>}
      overlayStyle={{ minWidth: 350, maxWidth: 450 }}
      {...props}
    >
      <div style={ellipsisStyle}>{value}</div>
      {/* <Typography.Paragraph ellipsis={true}>{value}</Typography.Paragraph> */}
    </Tooltip>
  );
};

export default EllipsisTooltip;
