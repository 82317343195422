import React, { useEffect, useState, useRef } from 'react';
import { Button, Dropdown, message, MessageBox, Switch } from 'kenshin';
import { getMeun } from '@/_serveice/menu';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import { dirCloumns, itemCloumns } from './_cloumns';
import { addosatempindexdata } from '../../../_serveice/performance';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import { getAllTreeKeys } from '../components/CollapseCard/template/indicators';

const DIR = 1; // 目录
const ITEM = 2; // 指标

function findNodeById(id, nodes) {
  for (let node of nodes) {
    if (node.type == DIR) {
      // 只找目录
      if (node.id === id) {
        return node;
      }

      if (node.childList) {
        let result = findNodeById(id, node.childList);
        if (result) {
          return result;
        }
      }
    }
  }

  return null;
}

// 默认的选择项
const defaultEdshowData = () => ({
  osaIndexItemModel: 1,
  osaIndexItemScoreMethod: 1,
  osaIndexItemActualValueSource: 1,
  osaIndexItemGoalValueSource: 1,
  osaIndexItemActualValueUnit: '0',
});
const indicators = ({ dispatch, performance, system, user }) => {
  // 目录
  const [editDirVisible, setEditDirVisible] = useState(false);
  // 指标
  const [editItemVisible, setEditItemVisible] = useState(false);
  // 弹窗数据
  const [editModalData, setEditModalData] = useState(null);
  // 剩余权重
  const [maxWeight, setMaxWeight] = useState(100);
  // 设置指标编辑的回显参数
  const [editShowData, setEditShowData] = useState(defaultEdshowData());

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const init = () => {
    setEditDirVisible(false);
    setEditItemVisible(false);
    setEditModalData(null);
    setEditShowData(defaultEdshowData());
    setMaxWeight(100);
  };
  // 目录弹窗配置
  const dirEditCloumn = dirCloumns({
    dirTree: performance.dirTree,
    maxWeight,
    setMaxWeight,
  });
  // 指标弹窗配置
  const itemEditCloumn = itemCloumns({
    dirTree: performance.dirTree,
    editShowData,
    setEditShowData,
    maxWeight,
    setMaxWeight,
  });

  // 获取商家目录树
  const getDirTree = () => {
    dispatch({
      type: 'performance/getosaindexcatgtree',
    });
  };

  useEffect(() => {
    getDirTree();
  }, []);

  // 点击编辑
  const showEditModal = (row, type) => {
    if (type == DIR) {
      // 目录
      setEditDirVisible(true);
    } else if (type == ITEM) {
      // 指标
      setEditItemVisible(true);
      row && setEditShowData(row); // 设置展开项
    }
    if (row) {
      setMaxWeight(
        findNodeById(
          row.osaIndexCatgPantId || row.osaIndexCatgId,
          performance.osaindexlibtree,
        )?.osaIndexRemainWeight || 0 + row.weight,
      ); // 设置展开项
    }
    setEditModalData(row || editShowData);
  };

  // 删除
  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        let type = 'performance/deleteindexlibcatg';
        let payload = {
          indexLibCatgId: row.id,
        };
        if (row.type != DIR) {
          type = 'performance/deleteindexlibitem';
          payload = {
            indexLibItemId: row.id,
          };
        }
        dispatch({
          type,
          payload,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
            getDirTree();
          }
        });
      },
    });
  };

  // 状态修改
  const handleStatusChange = (row) => {
    let type = 'performance/enableordisableindexcatg';
    let payload = {
      osaIndexCatgStatus: +!row.status,
      osaIndexCatgId: row.id,
    };
    if (row.type == ITEM) {
      type = 'performance/enableordisableindexitem';
      payload = {
        osaIndexCatgStatus: +!row.status,
        osaIndexItemList: [row.id],
      };
    }
    dispatch({
      type,
      payload,
    }).then((res) => {
      if (res) {
        message.success('状态修改成功');
        getDirTree();
      }
    });
  };

  // 表格
  const columns = [
    {
      key: 'osaIndexCatgName',
      title: 'KPI指标',
      width: '50%',
      render: (row) => {
        return `${row.code} ${
          row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        }`;
        // return (
        //   <EllipsisTooltip
        //     value={`${row.code} ${
        //       row.type == DIR ? row.osaIndexCatgName : row.osaIndexItemName
        //     }`}
        //     width={300}
        //   />
        // );
      },
    },
    {
      dataIndex: 'status',
      title: '状态',
      width: '10%',
      render: (val, row) => (
        <Switch checked={val} onChange={() => handleStatusChange(row)} />
      ),
    },
    // {
    //   dataIndex: 'remark',
    //   title: '指标描述',
    //   width: '25%',
    //   // render: (remark) => {
    //   //   return <EllipsisTooltip value={remark} width={300} />;
    //   // },
    // },
    {
      dataIndex: 'weight',
      title: '权重',
      width: '10%',
      render: (val) => `${val}%`,
    },
    {
      dataIndex: 'type',
      title: '类型',
      width: '10%',
      // osaIndexItemModel
      render: (val, row) => {
        let type = {
          1: '目录',
          2: '指标',
        };
        let osaIndexItemModelType = {
          1: '定量',
          2: '定性',
        };
        return [type[val], osaIndexItemModelType[row.osaIndexItemModel]]
          .filter(Boolean)
          .join('-');
      },
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: '10%',
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    {
      title: '操作',
      width: '15%',
      key: 'options',
      render: (row) => {
        return (
          <>
            {/* {row.hasbtnList.includes(btnKeys.edit) && ( */}
            <Button type="text" onClick={() => showEditModal(row, row.type)}>
              编辑
            </Button>
            {/*  )}
             {row.hasbtnList.includes(btnKeys.delete) && row.permId != 1 && !row.hasChild && ( */}
            <Button
              type="text"
              style={{ color: 'red' }}
              onClick={() => delItem(row)}
            >
              删除
            </Button>
            {/* )} */}
          </>
        );
      },
    },
  ];

  // 下拉菜单
  const dropdownMenu = (
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => showEditModal(null, DIR)}>
        新增目录
      </Dropdown.Item>
      <Dropdown.Item onClick={() => showEditModal(null, ITEM)}>
        新增指标
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const extra = () => (
    <>
      <Dropdown menu={dropdownMenu} trigger="click">
        <Button type="primary">新增</Button>
      </Dropdown>
      <Button
        style={{ marginLeft: 10 }}
        onClick={() => {
          setExpandedRowKeys(
            expandedRowKeys?.length == 0
              ? getAllTreeKeys(performance.osaindexlibtree)
              : [],
          );
        }}
      >
        {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
      </Button>
      {/* <Button
        onClick={() => {
          addosatempindexdata({
            indexDataJson: JSON.stringify(performance.osaindexlibtree),
            indexPantId: 0,
            osaTemplateId: 0,
          });
        }}
      >
        测试
      </Button> */}
    </>
  );

  // 保存：目录和指标
  const dirSave = (payload, saveType) => {
    payload.code = (performance.osaindexlibtree?.length || 0) + 1;
    const prantId = payload.osaIndexCatgPantId || payload.osaIndexCatgId;
    if (prantId) {
      let dir = findNodeById(prantId, performance.osaindexlibtree);
      let index = dir.childList?.length || 0;
      payload.code = `${dir.code}.${index + 1}`;
    }
    let hasEdit = false;
    if (editModalData?.id) {
      hasEdit = true; // 编辑
      // 旧的上级id
      let oldId =
        editModalData.osaIndexCatgPantId || editModalData.osaIndexCatgId;

      // 处理剩余权重
      // if (editModalData.osaIndexCatgPantId) {
      //   payload.osaIndexCatgPantId =
      //     editModalData.osaIndexCatgPantId +
      //     (payload.weight - editModalData.weight);
      // }

      payload.osaIndexRemainWeight =
        payload.weight -
        (editModalData.childList?.reduce((pre, cur) => pre + cur.weight, 0) ||
          0);
      payload = {
        ...editModalData,
        ...payload,
        childList: undefined,
      };
      if (oldId != prantId && maxWeight < payload.weight) {
        message.error('权重不能超过剩余权重！');
        return;
      }
      if (
        saveType == DIR &&
        editModalData.weight - payload.weight >
          editModalData.osaIndexRemainWeight
      ) {
        return message.error('权重不足与分配！');
      }
    }

    let type = 'performance/updateindexlibcatgdata';
    if (saveType == ITEM) {
      type = 'performance/updateindexlibitemdata';
      payload.osaIndexRemainWeight = undefined;
    }
    dispatch({
      type,
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        init();
        getDirTree();
      }
    });
  };
  return (
    <div>
      <TablePro
        request="performance/getosaindexlibtree"
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        extra={extra}
        curColumns
        childrenColumnName="childList"
        dataSource={performance.osaindexlibtree}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
      />

      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editDirVisible}
        edtaFormItem={dirEditCloumn}
        formData={editModalData}
        onSave={(val) => dirSave(val, DIR)}
        onCancel={init}
      />
      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editItemVisible}
        edtaFormItem={itemEditCloumn}
        formData={editModalData}
        onSave={(val) => dirSave(val, ITEM)}
        onCancel={init}
      />
    </div>
  );
};

export default connect(({ system, performance, loading, user }) => {
  return {
    user,
    system,
    performance,
    loading: loading.effects,
  };
})(indicators);
