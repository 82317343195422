import React, { useEffect, useState, useRef } from 'react';
import { Button, Table, MessageBox } from 'kenshin';
import TablePro from '@/components/TablePro';
import ModalFormPro from '@/components/ModalFormPro';
import { connect } from 'umi';
import { roleType } from '@/_util/keysType';

const ServCategory = ({ dispatch, systemOrgani, project, user }) => {
  // 弹窗操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  useEffect(() => {
    dispatch({
      type: 'project/getServcatgEasyList',
    });
  }, []);

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const statusElement = ({ proServCatgFinishNum, proServCatgNum }) => {
    if (proServCatgFinishNum < proServCatgNum) {
      return (
        <div className="status-red animate">
          未完成 &nbsp; {proServCatgFinishNum}/{proServCatgNum}
        </div>
      );
    }
    return (
      <div className="status-green">
        已完成 &nbsp; {proServCatgFinishNum}/{proServCatgNum}
      </div>
    );
  };

  const columns = [
    {
      dataIndex: 'projectName',
      title: '项目名称',
    },
    {
      dataIndex: 'contractNumber',
      title: '合同编号',
    },
    {
      dataIndex: 'custCompFullName',
      title: '客户名称',
    },
    {
      dataIndex: 'proServCatgList',
      title: '工作包名称',
      render: (val) =>
        val?.map((item) => (
          <div key={item.proServCatgId}>{item.servCatgName}</div>
        )),
    },
    {
      key: 'pageMangerStatus',
      title: '工作包状态',
      render: (row) => statusElement(row),
    },
    {
      dataIndex: 'lastUpdateTime',
      title: '最新更新时间',
      render: (val) => {
        return moment(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      dataIndex: 'totalWorkHour',
      title: '累计工时',
    },
    {
      title: '操作',
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.hasbtnList?.includes(btnKeys.closePack) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                处理
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '项目/客户/合同编号/工作包',
      },
    },
    {
      name: 'projectTime',
      label: '项目周期',
      type: 'DatePickerRangePicker',
      props: {},
    },
    {
      name: 'projectManagerList',
      label: '项目经理',
      type: 'Select',
      props: {
        placeholder: '项目经理',
        mode: 'multiple',
        options: systemOrgani.ProjectManagerAll[roleType.PROJECTMANAGER]?.map(
          (item) => ({
            label: item.value,
            value: item.id,
          }),
        ),
      },
    },
    {
      name: 'servCatgList',
      label: '服务类目',
      type: 'Select',
      props: {
        placeholder: '服务类目',
        mode: 'multiple',
        options: project.servcatgEasyList.map((item) => ({
          label: item.value,
          value: item.id,
        })),
      },
    },
  ];

  // 占位内容 ：  参与项目成员10位、实际上报6位，有4位未上报。
  const editModalSave = (val) => {
    MessageBox.confirm({
      title: '确认关闭？',
      content: `请确认关闭工作包，该工作包一共有${
        editModalData?.empRptWorkStatList?.length || 0
      }人上报、合计：${editModalData?.totalWorkHour || 0}小时,${
        '' /*占位 */
      }你确认关闭吗，一旦关闭将产生成本核算`,
      onOk: () => {
        if (editModalData?.projectId) {
          let payload = {
            muIdString: localStorage.getItem('muId'),
            projectId: editModalData?.projectId,
            ...val,
          };
          dispatch({
            type: 'project/delPackageManger',
            payload,
          }).then((res) => {
            if (res) {
              setEditModalData(null);
              setEditModalVisible(false);
            }
          });
        }
      },
    });
  };
  // 弹窗formItem
  const edtaFormItem = [
    {
      label: ' ',
      // required: '请输入服务类目名称',
      formItemProps: {
        colon: false,
      },
      render: () => (
        <div>
          工作包一共有
          <span style={{ color: 'red' }}>
            {editModalData?.proServCatgNum}个
          </span>
          工作清单，已关闭
          <span style={{ color: 'red' }}>
            {editModalData?.proServCatgFinishNum}个
          </span>
          ，剩余
          <span style={{ color: 'red' }}>
            {editModalData?.proServCatgNum -
              editModalData?.proServCatgFinishNum}
            个
          </span>
          ，请确认本次关闭的数量，该工作包一共有
          <span style={{ color: 'red' }}>
            {editModalData?.empRptWorkStatList?.length}位
          </span>
          同事上报工时，累计工时
          <span style={{ color: 'red' }}>
            {editModalData?.totalWorkHour ?? 0}小时
          </span>
          ！
        </div>
      ),
    },
    {
      label: ' ',
      name: 'proServCatgIdList',
      type: 'Select',
      // required: '请输入服务类目名称',
      formItemProps: {
        colon: false,
      },
      props: {
        placeholder: '请选择工作包',
        mode: 'multiple',
        allowClear: true,
        options: editModalData?.proServCatgList
          .filter((item) => item.servCatgStatus == 0)
          .map((item) => ({
            label: item.servCatgName,
            value: item.proServCatgId,
          })),
      },
    },
  ];

  const expandedRowRender = ({ empRptWorkStatList }) => {
    const columns = [
      { title: '同事名称', dataIndex: 'empName' },
      {
        title: '最新更新时间',
        dataIndex: 'gmtCreate',
        render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '累计工时',
        dataIndex: 'rptWorkTotalHours',
      },
      { title: '累计上报次数', dataIndex: 'rptCount' },
    ];
    return (
      <Table
        rowKey="empId"
        columns={columns}
        dataSource={empRptWorkStatList}
        pagination={false}
      />
    );
  };

  // 拦截搜索
  const tableSubmit = (val) => {
    if (val.projectTime) {
      const [start, end] = val.projectTime;
      val.projectBeginTime = +start;
      val.projectEndTime = +end;
    }
    val.projectTime = undefined;
  };

  return (
    <div>
      <TablePro
        request="project/getPackageMangerList"
        rowKey={'projectId'}
        columns={columns}
        onSubmit={tableSubmit}
        searchCloumns={searchCloumns}
        data={project.packageMangerList}
        expandable={{ expandedRowRender }}
      />
      <ModalFormPro
        title={`关闭《${editModalData?.custCompFullName}》项目工作包`}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani, user }) => {
  return {
    user,
    project,
    systemOrgani,
  };
})(ServCategory);
