import React, { useState, useEffect, useRef } from 'react';
import { MessageBox, Form, Input, Tree, Button, Col, Row } from 'kenshin';
import styles from './index.less';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'umi';
import { useMemoizedFn, useCreation } from 'ahooks';
import { manICon, girlIcon } from './icon';
import { debounce } from '../../_util';

const ChooseModal = ({
  visible,
  onCancel,
  onSave,
  value,
  onChange,
  systemOrgani,
}) => {
  const transformTreeData = useMemoizedFn((arr) => {
    return arr.map((item) => {
      return {
        title: item.empName,
        key: item.empId,
        icon: item.empSex == 1 ? manICon : girlIcon,
        ...item,
      };
    });
  });

  const defaultData = transformTreeData(systemOrgani.sysDeptUserList);

  const [selectKey, setSelectKey] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [data, setData] = useState();

  useEffect(() => {
    if (visible) {
      setData(defaultData);
      setSelectKey(() =>
        systemOrgani.sysDeptUserList
          .filter((item) => item.curnMuIsMang == 2)
          .map((item) => item.empId),
      );
      setSelectRow(() =>
        systemOrgani.sysDeptUserList
          .filter((item) => item.curnMuIsMang == 2)
          .map((item) => ({
            value: item.empName,
            id: item.empId,
            icon: item.empSex == 1 ? manICon : girlIcon,
            ...item,
          })),
      );
    }
  }, [visible]);

  const handleOk = () => {
    onSave?.(selectKey);
    onChange?.(selectRow);
  };

  const handleChange = (val, row) => {
    setSelectKey(val);
    setSelectRow(
      row.selectedNodes.map((item) => ({
        id: item.key,
        value: item.title,
        icon: item.icon,
      })),
    );
  };

  const handleCancel = () => {
    onCancel?.();
    setSelectKey(value ?? []);
  };

  const searchInput = (e) => {
    const { value } = e.target;
    let newData = filterTree([...defaultData], value);
    setData(newData);
  };

  const filterTree = (arr, value) => {
    return arr.filter((item) => {
      return item.title.includes(value);
    });
  };

  return (
    <MessageBox
      title="选择组织架构"
      width={800}
      bodyStyle={{ minHeight: 400, overflowY: 'auto' }}
      onCancel={handleCancel}
      destroyOnClose
      visible={visible}
      onOk={handleOk}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          确定
        </Button>,
      ]}
    >
      <Row>
        <Col span={20}>
          <Input
            style={{ marginBottom: 8 }}
            placeholder="Search"
            onChange={debounce(searchInput, 500)}
          />
        </Col>
        <Col span={9}>
          <div>选择权限功能</div>
          <div className={styles.borderBox}>
            <Tree
              treeData={data}
              selectedKeys={selectKey}
              onSelect={handleChange}
              multiple
              defaultExpandAll
            />
          </div>
        </Col>
        <Col span={2} className={styles.flexCenter}>
          &gt;
        </Col>
        <Col span={9}>
          <div>已选择</div>
          <div className={styles.borderBox}>
            {selectRow.map((item, index) => (
              <div key={item.id}>
                {item.icon}
                {item.value}
                <CloseOutlined
                  style={{ float: 'right', cursor: 'pointer' }}
                  onClick={() => {
                    setSelectKey((key) => {
                      key.splice(index, 1);
                      return [...key];
                    });
                    setSelectRow((row) => {
                      row.splice(index, 1);
                      return [...row];
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </MessageBox>
  );
};

export default connect(({ systemOrgani, loading, user }) => {
  return {
    systemOrgani,
  };
})(ChooseModal);
