import {
  getprocesspage,
  addprocess,
  deleteprocess,
  updateprocess,
  setprocessnode,
  setprocessstatus,
  getemplistbyrolekey,
  getprocessnodelist,
  getprocesstemplate,
  getsimpprocesslist,
  gettodoitempage,
  getcompleteitempage,
  getcompletechangelist,
  getprocessapplydetaildata,
  auditprocessapply,
  getprocessapplypage,
  getallprocessapplypage,
  closeprocessapply,
  completerevoke,
  getcompleteprocess,
  getosaweekplanprocessnodelist,
  getprocessapplytypelist,
} from '@/_serveice/admin/process';

export default {
  namespace: 'process',

  state: {
    processPageList: [],
    processPageListSearch: {},

    nodeList: {},
    templateData: null,
    emplist: {},
    simpProcessList: [],
    // 代办
    busPendingList: {},
    busPendingListSearch: {},

    // 已办
    busListLog: {},
    busListLogSearch: {},

    // 变更历史
    completeChangeList: [],
    // 流程申请的详细数据
    ProcessApplyPageList: [],
    ProcessApplyPageListSearch: [],

    // 程申请的详细数据
    processApplyDetailList: {},

    // 申请列表
    allProcessApplyPageList: {},
    allProcessApplyPageListSearch: {},

    // 审核节点信息
    completeprocessList: [],

    // 变更信息
    changeInfo: {},

    processTypeOptions: [],
    processTypeStrObj: {},
  },

  // 类似vue的actions，里面可用执行异步操作
  effects: {
    *getprocesspage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ process }) => process.processPageListSearch,
      );
      let processPageListSearch = { ...searchData, ...payload };
      if (reset) {
        processPageListSearch = payload;
      }
      const res = yield call(getprocesspage, processPageListSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            processPageList: res.data,
            processPageListSearch,
          },
        });
      }
    },
    *getprocessapplypage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ process }) => process.ProcessApplyPageListSearch,
      );
      let ProcessApplyPageListSearch = { ...searchData, ...payload };
      if (reset) {
        ProcessApplyPageListSearch = payload;
      }
      const res = yield call(getprocessapplypage, ProcessApplyPageListSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            ProcessApplyPageList: res.data,
            ProcessApplyPageListSearch,
          },
        });
      }
    },
    *updateprocess({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? updateprocess : addprocess, payload);
      if (res?.success) {
        yield put({
          type: 'getprocesspage',
        });
        return true;
      }
    },
    *deleteprocess({ payload }, { put, call }) {
      const res = yield call(deleteprocess, payload);
      if (res?.success) {
        yield put({
          type: 'getprocesspage',
        });
        return true;
      }
    },
    *setprocessstatus({ payload }, { put, call }) {
      const res = yield call(setprocessstatus, payload);
      if (res?.success) {
        yield put({
          type: 'getprocesspage',
        });
        return true;
      }
    },
    *setprocessnode({ payload }, { put, call }) {
      const res = yield call(setprocessnode, payload);
      if (res?.success) {
        yield put({
          type: 'getprocesspage',
        });
        yield put({
          type: 'save',
          payload: {
            nodeList: {},
          },
        });
        return true;
      }
    },
    // 流程模板
    *getprocesstemplate({ payload }, { put, call, select }) {
      const templateData = yield select(({ process }) => process.templateData);
      if (templateData) {
        return templateData;
      }
      const res = yield call(getprocesstemplate, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            templateData: res.data,
          },
        });
        return res.data;
      }
    },
    *getprocessnodelist({ payload, extra }, { put, call, select }) {
      const nodeList = yield select(({ process }) => process.nodeList);
      let id = payload.processId;
      if (!nodeList[id]) {
        const res = yield call(
          extra ? getosaweekplanprocessnodelist : getprocessnodelist,
          payload,
        );
        nodeList[id] = res.data;
        if (res?.success) {
          yield put({
            type: 'save',
            payload: {
              nodeList: { ...nodeList },
            },
          });
          return res.data;
        }
      }
    },
    *getemplistbyrolekey({ payload }, { put, call, select }) {
      const emplist = yield select(({ process }) => process.emplist);
      let id = payload.roleKey;
      if (!emplist[id]) {
        const res = yield call(getemplistbyrolekey, payload);
        emplist[id] = res.data;
        if (res?.success) {
          yield put({
            type: 'save',
            payload: {
              emplist: { ...emplist },
            },
          });
        }
      }
    },
    *getsimpprocesslist({ payload }, { put, call, select }) {
      const res = yield call(getsimpprocesslist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            simpProcessList: res.data,
          },
        });
        return res.data;
      }
      return [];
    },
    *gettodoitempage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ process }) => process.busPendingListSearch,
      );
      let busPendingListSearch = { ...searchData, ...payload };
      if (reset) {
        busPendingListSearch = payload;
      }
      const res = yield call(gettodoitempage, busPendingListSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            busPendingList: res.data,
            busPendingListSearch,
          },
        });
      }
    },
    *getcompleteitempage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ process }) => process.busListLogSearch,
      );
      let busListLogSearch = { ...searchData, ...payload };
      if (reset) {
        busListLogSearch = payload;
      }
      const res = yield call(getcompleteitempage, busListLogSearch);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            busListLog: res.data,
            busListLogSearch,
          },
        });
      }
    },
    *completerevoke({ payload }, { put, call }) {
      const res = yield call(completerevoke, payload);
      if (res?.success) {
        yield put({
          type: 'getcompleteitempage',
        });
        return true;
      }
    },
    *getcompletechangelist({ payload, reset }, { put, call, select }) {
      const res = yield call(getcompletechangelist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            completeChangeList: res.data,
          },
        });
      }
    },
    *getprocessapplydetaildata({ payload, reset }, { put, call, select }) {
      const res = yield call(getprocessapplydetaildata, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            processApplyDetailList: res.data,
          },
        });
      }
    },
    *auditprocessapply({ payload, reset }, { put, call, select }) {
      const res = yield call(auditprocessapply, payload);
      if (res?.success) {
        return true;
      }
      return false;
    },

    *getallprocessapplypage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ process }) => process.allProcessApplyPageListSearch,
      );
      let allProcessApplyPageListSearch = { ...searchData, ...payload };
      if (reset) {
        allProcessApplyPageListSearch = payload;
      }
      const res = yield call(
        getallprocessapplypage,
        allProcessApplyPageListSearch,
      );
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            allProcessApplyPageList: res.data,
            allProcessApplyPageListSearch,
          },
        });
      }
    },
    *closeprocessapply({ payload }, { put, call }) {
      const res = yield call(closeprocessapply, payload);
      if (res?.success) {
        yield put({
          type: 'getallprocessapplypage',
        });
        return true;
      }
    },
    *getcompleteprocess({ payload }, { put, call }) {
      const res = yield call(getcompleteprocess, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            completeprocessList: res.data,
          },
        });
        return true;
      }
    },
    *getprocessapplytypelist({ payload }, { put, call }) {
      const res = yield call(getprocessapplytypelist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            processTypeOptions: res.data.map((item) => ({
              label: item.value,
              value: item.id,
            })),
            processTypeStrObj: res.data.reduce((obj, item) => {
              obj[item.id] = item.value;
              return obj;
            }, {}),
          },
        });
        return true;
      }
    },
  },

  reducers: {
    // 每次保存数据的时候讲state再存一次，避免下一次数据存储的时候把原数据删了
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
