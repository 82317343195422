import { Input, Button } from 'kenshin';
import React, { useEffect, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useReactive } from 'ahooks';

// 增加、删除按钮样式
const buttonStyle = {
  borderRadius: '16px',
  width: '36px',
  height: '32px',
  textAlign: 'center',
  padding: 0,
  marginLeft: 10,
};

const BatchValue = ({ value, onChange, remark }) => {
  // const [data, setData] = useState(['']);

  const state = useReactive({
    idList: [{ ip: '', remark: '' }],
  });

  useEffect(() => {
    if (typeof value == 'string') {
      let list = value?.split(',') || [];
      let remarkList = [];
      try {
        remarkList = JSON.parse(remark);
      } catch (error) {}
      if (list.length > 0) {
        // setData([...list]);
        state.idList = [...list].map((item) => ({
          ip: item,
          remark: remarkList[item],
        }));
      }
    }
  }, [value]);

  const handleChange = (e, i, key) => {
    let value = e.target.value;
    state.idList[i][key] = value;
    exportChange();
    // onChange?.([...list]);
    // setData((list) => {
    //   list[i] = value;
    //   onChange?.([...list]);
    //   return [...list];
    // });
  };

  const add = () => {
    state.idList.push({ ip: '', remark: '' });
    exportChange();
    setData((list) => {
      onChange?.([...list, '']);
      return [...list, ''];
    });
  };
  const deleteItem = (i) => {
    state.idList.splice(i, 1);
    exportChange();
    // setData((list) => {
    //   list.splice(i, 1);
    //   onChange?.([...list]);
    //   return [...list];
    // });
  };

  const exportChange = () => {
    let ips = state.idList
      .map((item) => item.ip)
      .filter(Boolean)
      .join(',');
    let remark = Object.fromEntries(
      state.idList.map((item) => [[item.ip], item.remark]),
    );
    onChange({
      ips,
      remark: JSON.stringify(remark),
    });
  };
  return (
    <>
      {state.idList.map((item, i) => (
        <div key={i} style={{ display: 'flex', marginBottom: 10 }}>
          <div>
            <Input value={item.ip} onChange={(e) => handleChange(e, i, 'ip')} />
            <Input.TextArea
              value={item.remark}
              onChange={(e) => handleChange(e, i, 'remark')}
            />
          </div>
          {i == 0 ? (
            <Button type="primary" style={buttonStyle} onClick={add}>
              <PlusOutlined />
            </Button>
          ) : (
            <Button
              type="danger"
              style={buttonStyle}
              onClick={() => deleteItem(i)}
            >
              <MinusOutlined />
            </Button>
          )}
        </div>
      ))}
    </>
  );
};

export default BatchValue;
