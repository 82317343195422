import { useSelector } from 'umi';
import { MessageBox, Button } from 'kenshin';
import { useEffect, useState } from 'react';
import { useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import { getTableCloumns } from './_cloumns';
import {
  getempvacationconsumedetaillist,
  getempvacationdeductdetaillist,
} from '../../../../_serveice/attendance';

const subCloumns = [
  {
    dataIndex: 'deductTime',
    title: '扣减时间',
    render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    dataIndex: 'oldVacationChangeBefore',
    title: '变动前的值/天',
  },
  {
    dataIndex: 'oldVacationChangeAfter',
    title: '变动后的值/天',
  },
  {
    dataIndex: 'vacationChangeValue',
    title: '变动值/天',
    render: (val) => `扣减${val}天`,
  },
  {
    dataIndex: 'oldVacationChangeTime',
    title: '变动时间',
    render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    dataIndex: 'oldVacationChangeValue',
    title: '增加值/天',
    render: (val) => `增加${val}天`,
  },
  {
    dataIndex: 'oldVacationBelongYear',
    title: '所属年份',
  },
];
const addCloumns = [
  {
    dataIndex: 'consumeTime',
    title: '消耗时间',
    render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    dataIndex: 'oldVacationChangeBefore',
    title: '变动前的值/天',
  },
  {
    dataIndex: 'oldVacationChangeAfter',
    title: '变动后的值/天',
  },
  {
    dataIndex: 'vacationChangeValue',
    title: '变动值',
    render: (val) => `消耗了${val}天`,
  },
];

const HolidayChangeLog = ({ visible, onCancel, data }) => {
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [columns, setCloumns] = useState([]);

  useEffect(() => {
    if (visible && data.empVacationChangeId) {
      let funApi = getempvacationconsumedetaillist;
      // 区分是消耗还是扣减
      if (data.vacationChangeType != 1) {
        setCloumns(subCloumns);
        funApi = getempvacationdeductdetaillist;
      } else setCloumns(addCloumns);
      funApi({
        empVacationChangeId: data.empVacationChangeId,
      }).then((res) => {
        setDataSource(res.data || []);
      });
    }
  }, [visible]);

  return (
    <MessageBox
      title={`变动记录`}
      onCancel={onCancel}
      maskClosable={false}
      width="90%"
      zIndex="1100"
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      <TablePro
        rowKey={(row, i) => i}
        dataSource={dataSource}
        pagination={false}
        columns={columns}
        scrollX
        columnsOptions={false}
        curColumns
      ></TablePro>
    </MessageBox>
  );
};

export default HolidayChangeLog;
