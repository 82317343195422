import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataAnalysePage from '@/components/DataAnalysePage';
import { useDispatch } from 'umi';
import { Tabs } from 'kenshin';
import department from './department';
import employee from './employee';
import project from './project';

const dataAnalyseCost = () => {
  // 标签栏当前key
  const [curCheckedKey, setCurCheckedKey] = useState('1');

  const dataArr = useMemo(
    () => ({
      1: project,
      2: department,
      3: employee,
    }),
    [],
  );

  const dispatchType = {
    charts: 'getCostCharts',
    list: 'getCostList',
    listById: 'getCostListById',
  };
  const dataType = {
    charts: 'costChartData',
    list: 'costList',
    listById: 'costListById',
  };

  const tabsArr = useMemo(
    () => [
      {
        label: '项目',
        value: '1',
      },
      {
        label: '部门',
        value: '2',
      },
      {
        label: '人员',
        value: '3',
      },
    ],
    [],
  );
  const renderElement = useMemo(() => {
    return (
      <>
        <Tabs defaultActiveKey="1" type="card" onChange={setCurCheckedKey}>
          {tabsArr.map((item) => (
            <Tabs.TabPane tab={item.label} key={item.value}></Tabs.TabPane>
          ))}
        </Tabs>
        <DataAnalysePage
          key={curCheckedKey}
          dataType={dataType}
          dispatchType={dispatchType}
          otherParams={{
            tabsKey: curCheckedKey,
          }}
          {...(dataArr[curCheckedKey] || {})}
        />
      </>
    );
  }, [curCheckedKey]);

  return renderElement;
};

export default dataAnalyseCost;
