import {
  MessageBox,
  Button,
  Row,
  Col,
  DatePicker,
  Typography,
  Table,
} from 'kenshin';
import { useEffect, useState } from 'react';
import { empclockstatistics } from '../../../../_serveice/attendance';
import { gettableCloumns } from './_util';

const Item = ({ row }) => {
  //   console.log();
  let datas = gettableCloumns(row);
  let columns = [
    {
      title: '类型',
      width: 200,
      dataIndex: 'label',
      render(value, row, index) {
        let obj = {
          children: value,
          props: {},
        };
        if (datas.findIndex((item) => item.label == value) == index) {
          obj.props.rowSpan = datas.filter(
            (item) => item.label == value,
          ).length;
        } else {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
    {
      title: '分类',
      width: 200,
      dataIndex: 'type',
    },
    {
      title: '人员',
      dataIndex: 'value',
      render: (list) => list?.map((item) => item.value)?.join('、') || '-',
    },
    {
      title: '人数',
      width: 70,
      dataIndex: 'number',
      render: (_, row) => row.value?.length ?? 0,
    },
  ];
  return (
    <>
      <Typography.Title level={3}>{row.attendanceDate}</Typography.Title>
      <Table
        bordered
        pagination={false}
        rowKey={(_, i) => i}
        columns={columns}
        dataSource={datas}
      />
    </>
  );
};

const Statistical = ({ onCancel, visible }) => {
  const [list, setList] = useState([]);
  const [time, setTime] = useState([
    moment().subtract(1, 'd'),
    moment().subtract(1, 'd'),
  ]);
  useEffect(() => {
    if (visible) {
      getStaticData(time);
    }
  }, [visible]);

  const getStaticData = (payload) => {
    let beginDate = payload[0].startOf('d').format('YYYY-MM-DD HH:mm:ss');
    let endDate = payload[1].endOf('d').format('YYYY-MM-DD HH:mm:ss');
    empclockstatistics({
      beginDate,
      endDate,
    }).then((res) => {
      if (res.success) {
        setList(res.data ?? []);
      }
    });
  };

  const handleDate = (values) => {
    getStaticData(values);
    setTime(values);
  };

  return (
    <MessageBox
      title="考勤统计"
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      destroyOnClose
      visible={visible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          关闭
        </Button>,
      ]}
    >
      <Row>
        <Col span={20}>
          <DatePicker.RangePicker
            value={time}
            onChange={handleDate}
            allowClear={false}
          />
        </Col>
        <Col span={20}>
          {list.map((item) => (
            <Item row={item} key={item.attendanceDate} />
          ))}
        </Col>
      </Row>
    </MessageBox>
  );
};

export default Statistical;
