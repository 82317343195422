import React, { useEffect, useMemo, useRef } from 'react';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { exportproservcatgstat } from '@/_serveice/project';
import { downloadXlsx, splitParamsFoGet, toAmount } from '@/_util/util';
import { Button } from 'kenshin';

const ServCatgStat = ({ project, systemOrgani, dispatch }) => {
  const columns = [
    {
      dataIndex: 'servCatgName',
      title: '工作包名称',
    },
    {
      dataIndex: 'servCatgLeaderName',
      title: '负责人名称',
    },
    {
      key: 'servCatgCount',
      title: '数量',
      render(row) {
        return `${row.servCatgFinishNum}/${row.servCatgCount}`;
      },
    },
    {
      key: 'servCatgFinishAmount',
      title: '金额',
      render(row) {
        return `${toAmount(row.servCatgFinishAmount)}/${toAmount(
          row.servCatgDiscTotalAmount,
        )}`;
      },
    },
    {
      dataIndex: 'servCatgUnitPrice',
      title: '原单价',
      render: toAmount,
    },
    {
      dataIndex: 'servCatgOrginTotalAmount',
      title: '原总价',
      render: toAmount,
    },
    {
      dataIndex: 'servCatgHourDiscCost',
      title: '优惠后单价',
      render: toAmount,
    },
    {
      dataIndex: 'servCatgTeamList',
      title: '参与团队',
      render(list) {
        return list?.join(',');
      },
    },
    {
      dataIndex: 'totalRptWorkHours',
      title: '总上报工时',
      render: (val) => val ?? 0,
    },
  ];

  const extra = () => (
    <Button
      type="primary"
      onClick={() => {
        let payload = {
          projectId: history.location.query?.projectId ?? '',
        };
        let url = `${baseURL}${exportproservcatgstat}${splitParamsFoGet(
          payload,
        )}`;
        downloadXlsx(url, '工作包统计');
      }}
    >
      导出
    </Button>
  );

  return (
    <div>
      <TablePro
        params={{ projectId: history.location.query?.projectId }}
        request="project/getProServCatgstatList"
        rowKey="proServCatgId"
        columns={columns}
        pagination={false}
        extra={extra}
        columnsOptions={false}
        curColumns
        dataSource={project.proServCatgstatList}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani }) => {
  return {
    project,
    systemOrgani,
  };
})(ServCatgStat);
