import { Collapse, InputNumber } from 'kenshin';
/**
 * item:追加模板后面
 * list:合并项再模板后面
 * arr:覆盖全部
 */

const defaultKey = {
  osaWeekType: 'osaWeekType',
  osaScoreRule: 'osaScoreRule',
};

export default ({ item, list, arr: propsArr, propsKey = defaultKey } = {}) => {
  let arr = [
    {
      title: '考核周期',
      dataIndex: propsKey.osaWeekType,
      valueType: 'RadioGroup',
      fieldProps: {
        options: [
          {
            label: '周',
            value: 1,
          },
          // {
          //   label: '月度',
          //   value: 2,
          // },
          {
            label: '季度',
            value: 3,
          },
          // {
          //   label: '年度',
          //   value: 4,
          // },
        ],
      },
    },
    {
      title: '考核模式',
      dataIndex: propsKey.osaScoreRule,
      extra:
        '在既定时间内完成目标，且有多付出、多贡献、有特别亮点的项目则视情况给予附加分1-20分',
      valueType: 'Select',
      fieldProps: {
        placeholder: '请选择考核模式',
        options: [
          {
            label: '满分100分制（可向上浮动20%）',
            value: 1,
          },
        ],
      },
    },
  ];

  if (propsArr) {
    arr = propsArr;
  }
  if (item) {
    arr.push(item);
  }
  if (list) {
    arr.concat(list);
  }

  return arr;
};
