import { Button, Calendar, Select, Tag, Tooltip } from 'kenshin';
import { memo, useMemo, useState } from 'react';
import {
  ExclamationCircleFilled,
  CheckCircleFilled,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useCreation, useMemoizedFn } from 'ahooks';
import { useDispatch, history } from 'umi';
import styles from './styles.less';
import {
  LateIcon,
  OverTimeIcon,
  VavationApplyIcon,
  GooutIcon,
  LeaveEarlyIcon,
} from './util/icon';
import { getIconTootlip } from './util/data';

const WorkCalendar = memo(({ data, homeHoliday }) => {
  const [calendarTime, setCalendarTime] = useState(moment());

  // 以日期为key值的对象，{'时间戳':数据}
  const dateObj = useMemo(() => {
    let obj = {};
    data.map((item) => {
      let key = +moment(item.reportDate);
      obj[key] = item;
    });
    return obj;
  }, [data]);

  const dispatch = useDispatch();

  // 面板变化时请求当月数据
  const getCurDateData = (date) => {
    setCalendarTime(moment(date));
    dispatch({
      type: 'usercenter/getEmpWorkRptList',
      payload: {
        empIdString: localStorage.getItem('muId'),
        rptBeginTime: moment(date)
          .subtract(1, 'M')
          .startOf('M')
          .format('YYYY-MM-DD'),
        rptEndTime: moment(date).endOf('M').format('YYYY-MM-DD'),
      },
    });
  };

  const toRptAudit = (date) => {
    history.push({
      pathname: '/usercenter/rptAudit',
      query: {
        reportDate: date,
      },
    });
  };

  const renderProjectPop = (data) => {
    return data?.rptProServCatgList
      ?.sort((a, b) => a.workBeginTime - b.workBeginTime)
      .map((item, i) => {
        return (
          <div key={i}>
            {moment(item.workBeginTime).format('HH:mm:ss')} ~{' '}
            {moment(item.workEndTime).format('HH:mm:ss')}
            {'  '}
            {item.projectName}
            {'>'}
            {item.servCatgName}
            {'>'}
            {item.taskName}
            {' ： '}
            {item.rptRemark}
          </div>
        );
      });
  };

  /** 日历面包下的图标 */
  const renderDateIcon = (data) => {
    if (!data) return null;
    const { goout, overtime, late, leaveEarly, vacationApply } = data;
    const iconDesc = [
      [goout, GooutIcon],
      [overtime, OverTimeIcon],
      [late, LateIcon],
      [leaveEarly, LeaveEarlyIcon],
      [vacationApply, VavationApplyIcon],
    ].map(([icon, name], i) => {
      if (icon) {
        return (
          <div className="home-calendar-icon" key={i}>
            {name}
          </div>
        );
      }
      return null;
    });

    return iconDesc.filter(Boolean);
  };

  const dateRender = (date, dateToday) => {
    /** 渲染是否上报信息的icon */
    const renderIsRptIcon = () => {
      if (date < moment()) {
        if (curData?.rptProServCatgList) return <CheckCircleFilled />;
        if (hasShowicon()) return <ExclamationCircleFilled />;
      }
      return null;
    };
    let curData = dateObj[+moment(date).startOf('date')]; // 当前日期是否有上报记录
    let today = +moment(dateToday).startOf('date'); // 今天
    let dateStr = moment(date).format('YYYY-MM-DD'); // 时间年月格式
    let holidayOrNo = homeHoliday[dateStr]; // 节假日

    let hasShowicon = () => {
      if (holidayOrNo) {
        if (holidayOrNo.holiday) return false;
        return true;
      }
      if (moment(date).get('d') == 0 || moment(date).get('d') == 6)
        return false;
      return true;
    };

    let DataIcon = renderDateIcon(curData);
    return (
      <div className={`home-calendar ${!hasShowicon() ? 'reset-day' : ''}`}>
        <div className="date">{date.date()}</div>
        <div className="icon">
          {renderIsRptIcon()}
          {holidayOrNo && (
            <span className="holidayIcon">
              {holidayOrNo.holiday ? '休' : '班'}
            </span>
          )}
        </div>
        {curData?.rptProServCatgList && (
          <Tooltip
            title={
              curData?.rptProServCatgList ? renderProjectPop(curData) : null
            }
          >
            <div style={{ width: '100%' }}>
              <Tag.MiniTag type="success">
                {curData?.rptProServCatgNum}个工作包，
                {curData?.totalRptWorkHours}
                /时
              </Tag.MiniTag>
            </div>
          </Tooltip>
        )}
        {date < moment().startOf('date') && (
          <Button
            shape="simple"
            size="small"
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              toRptAudit(+date);
            }}
          >
            工作补报
          </Button>
        )}
        {moment(date).startOf('date') == today && (
          <Button
            size="small"
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              toRptAudit();
            }}
          >
            今日上报
          </Button>
        )}
        {DataIcon?.length > 0 && (
          <Tooltip
            title={getIconTootlip(curData)}
            overlayStyle={{
              color: '#333',
              fontSize: 12,
              lineHeight: '24px',
            }}
            color="#fff"
          >
            <div className="calendar-item-icon">{DataIcon}</div>
          </Tooltip>
        )}
      </div>
    );
  };

  const addCalendarTime = (diff, unit) => {
    if (diff == 0) {
      getCurDateData(moment());
    } else {
      getCurDateData(moment(calendarTime).add(diff, unit));
    }
  };

  /** 顶部描述 */
  const iconDesc = () => {
    return [
      [GooutIcon, '有外出'],
      [OverTimeIcon, '有加班'],
      [LateIcon, '有迟到'],
      [LeaveEarlyIcon, '有早退'],
      [VavationApplyIcon, '有请假'],
    ].map(([icon, name]) => {
      return (
        <div className="desc-item" key={name}>
          {icon}
          <span className="desc">{name}</span>
        </div>
      );
    });
  };

  const yearOptions = useCreation(() => {
    const currentYear = moment(calendarTime).year();
    const startYear = currentYear - 30;
    const endYear = currentYear + 30;
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year, value: year });
    }
    return years;
  }, [calendarTime]);

  const headerRender = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="home-calendar-header"
      >
        <div className="home-card-title">日历工作</div>
        <div style={{ display: 'flex' }}>
          <div className="icon-desc-box">{iconDesc()}</div>
          <Select
            options={yearOptions}
            value={calendarTime.year()}
            style={{ width: 120 }}
            onChange={(val) =>
              getCurDateData(moment(calendarTime).set('year', val))
            }
          />
          <Select
            options={new Array(12)
              .fill('')
              .map((_, i) => ({ label: `${i + 1}月`, value: i }))}
            value={calendarTime.month()}
            style={{ width: 120, margin: '0 10px' }}
            onChange={(val) =>
              getCurDateData(moment(calendarTime).set('month', val))
            }
          />
          <div
            className={styles.calenartBtn}
            onClick={() => addCalendarTime(-1, 'month')}
          >
            <LeftOutlined />
          </div>
          {/* <div className='flr' style={{ marginLeft: 10 }} onClick={() => addCalendarTime(0, "month")}>今天</div> */}
          <div
            className={styles.calenartBtn}
            onClick={() => addCalendarTime(1, 'month')}
          >
            <RightOutlined />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Calendar
      dateRender={dateRender}
      value={calendarTime}
      headerRender={headerRender}
      showToday={false}
      onPanelChange={getCurDateData}
      onChange={getCurDateData}
    />
  );
});

export default WorkCalendar;
