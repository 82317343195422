import moment from 'moment';
// const moment = require('moment');

function getWeekDates(year, month) {
  // debugger
  // 计算给定年份和月份的第一天是星期几
  const startOfMonth = moment([year, month]);

  // 计算给定年份和月份的总天数
  let endOfMonth = moment(startOfMonth).endOf('month');
  if (moment().year() > year) {
    endOfMonth = moment(startOfMonth).endOf('year');
  }
  const lastDayOfMonth = endOfMonth.date();
  // 初始化周数组
  const weeks = [];

  // 从第一天开始，每周添加一个新的周对象
  let currentWeek = {};
  let osaWeekPlanWeekOfMonth = 1;
  for (let i = 1; i <= lastDayOfMonth; i++) {
    if (!currentWeek.osaWeekPlanBeginDate) {
      currentWeek.osaWeekPlanBeginDate = moment([year, month, i]).add(2, 'd');
    }
    currentWeek.osaWeekPlanEndDate = moment([year, month, i]);
    if (
      currentWeek.osaWeekPlanEndDate > currentWeek.osaWeekPlanBeginDate &&
      (currentWeek.osaWeekPlanEndDate.day() === 5 || i === lastDayOfMonth)
    ) {
      currentWeek.osaWeekPlanBeginDate = moment(currentWeek.osaWeekPlanEndDate)
        .startOf('week')
        .add(1, 'd');
      if (i === lastDayOfMonth)
        currentWeek.osaWeekPlanEndDate =
          currentWeek.osaWeekPlanEndDate.endOf('week');
      currentWeek.osaWeekPlanBeginDate =
        currentWeek.osaWeekPlanBeginDate.format('YYYY-MM-DD HH:mm:ss');
      currentWeek.osaWeekPlanEndDate = currentWeek.osaWeekPlanEndDate
        .endOf('week')
        .add(1, 'd')
        .format('YYYY-MM-DD HH:mm:ss');
      currentWeek.osaWeekPlanWeekOfMonth = osaWeekPlanWeekOfMonth++;
      weeks.push(currentWeek);
      // }
      currentWeek = {};
    }
  }

  return weeks;
}

/** 获取一年1~12月的数组 */
function getMonth(y) {
  const year = moment(y).get('y');
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(+moment().year(year).month(i).format('MM'));
  }
  return months;
}

/** 周计划季度列表 */
export function getQuarterTime(year = moment().year()) {
  let res = getMonth().map((osaWeekPlanMonth) => {
    //  所有月份数据
    return {
      osaWeekPlanMonth,
      osaWeekDateList: getWeekDates(year, osaWeekPlanMonth - 1),
    };
  });
  // 存储周计划的年度数据的值
  let resArr = [];
  // 季度
  [3, 6, 9, 12].reduce((prev, next) => {
    resArr.push({
      osaWeekMonthList: res.slice(prev, next), //周计划月度列表
      osaWeekPlanQuarter: next / 3, // 周计划季度
    });
    return next;
  }, 0);
  return resArr;
}
