import React, { useState, useEffect } from 'react';
import { MessageBox, Input, Tabs, Button, Col, Row } from 'kenshin';
import { connect } from 'umi';
import * as AntIcon from '@ant-design/icons';
import './index.less';
import { debounce } from '@/_util';

const { TabPane } = Tabs;

const MenuItemEdit = ({
  data,
  visible,
  loading,
  onCancel,
  onSave,
  dispatch,
  ...props
}) => {
  const [keywords, setKeywords] = useState('');

  const handleOk = () => {};

  const search = (e) => {
    setKeywords(e.target.value || '');
  };

  const renderCol = (key, long = 500) => {
    return Object.keys(AntIcon)
      .filter((item) => key.test(item))
      .filter((item) =>
        item.toLocaleUpperCase().includes(keywords.toLocaleUpperCase()),
      )
      .slice(0, long)
      .map((item, index) => {
        const Icon = React.createElement(AntIcon[item], {
          onClick: (_) => {
            onSave?.(item);
          },
        });
        return (
          <Col key={item} span={4}>
            {Icon}
            {item}
          </Col>
        );
      });
  };
  return (
    <MessageBox
      {...props}
      onCancel={onCancel}
      width={900}
      destroyOnClose
      visible={visible}
      bodyStyle={{ maxHeight: '600px', overflow: 'auto' }}
      title="选择图标"
      onOk={handleOk}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        // <Button key="ok" type="primary" onClick={handleOk}>确定</Button>
      ]}
    >
      <Input placeholder="搜索图标" onChange={debounce(search, 500)} />
      <Tabs>
        <TabPane tab="线框风格" key="Outlined">
          <Row className="choose-icon-box">
            {renderCol(/Outlined$/)}
            {/* <Col span={4}>{Icon}</Col> */}
          </Row>
        </TabPane>
        <TabPane tab="实底风格" key="Filled">
          <Row className="choose-icon-box">
            {renderCol(/Filled$/)}
            {/* <Col span={4}>{Icon}</Col> */}
          </Row>
        </TabPane>
        <TabPane tab="双色风格" key="TwoTone">
          <Row className="choose-icon-box">
            {renderCol(/TwoTone$/, 50)}
            {/* <Col span={4}>{Icon}</Col> */}
          </Row>
        </TabPane>
      </Tabs>
    </MessageBox>
  );
};

export default connect(({ system, loading }) => {
  return {
    system,
    loading: loading.effects,
  };
})(MenuItemEdit);
