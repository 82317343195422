import {
  InputNumber,
  // Select,
  Switch,
  Input,
  Checkbox,
  AutoComplete,
  ColorPicker,
  DatePicker,
  Mentions,
  Radio,
  Rate,
  Slider,
  TreeSelect,
  TimePicker,
} from 'kenshin';

import ChooseDepartment from '../components/ChooseDepartment';
import UploadImg from '../components/UploadImg';
import UploadFile from '../components/UploadFile';
import AddressSelect from '../components/AddressSelect';
import ServCategoryList from '../components/ServCategoryList';
import DeptSelect from '../components/DeptSelect';
import Editor from '../components/Editor';

import Select from './components/Select';
import MyInput from './components/Input';

const customComponents = {
  ChooseDepartment,
  UploadImg,
  UploadFile,
  ServCategoryList,
  AddressSelect,
  DeptSelect,
  Editor,
};

export default {
  // Input: MyInput,
  Input,
  InputNumber,
  Number: InputNumber,
  Select,

  InputTextArea: Input.TextArea,
  'Input.TextArea': Input.TextArea,
  TextArea: Input.TextArea,

  Password: Input.Password,
  InputPassword: Input.Password,
  'Input.Password': Input.Password,

  Checkbox,
  CheckboxGroup: Checkbox.Group,
  Radio,
  RadioGroup: Radio.Group,
  AutoComplete,
  ColorPicker,

  TimePicker,
  DatePicker,
  DatePickerRangePicker: DatePicker.RangePicker,
  'DatePicker.RangePicker': DatePicker.RangePicker,

  Mentions,
  Rate,
  Slider,

  Switch,
  TreeSelect,
  ...customComponents,
};
