import crypto from 'crypto';
// const crypto = () => {}

export const getCode = () => {
  // 获取验证码
  const key = String(new Date().getTime()) + ~~(Math.random() * 100);
  return {
    key,
    src: `${captcha}?key=${key}`,
  };
};

export const keychain =
  '0,9,38,46,6,53,4,8,9,37,7,62,53,6,43,8,3,7,5,3,76,5, 0, 13, 0, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15';

/**
 * 加密
 * @param {Array} key 密钥
 */
export const encrypt = (data, key = keychain) => {
  let newKey = keychain.split(',');
  let cipher = crypto.createCipher('aes-128-ecb', Buffer.from(newKey));
  return Buffer.concat([cipher.update(data, 'utf8'), cipher.final()]).toString(
    'base64',
  );
};
/**
 * 解密
 * @param {Array} key 密钥
 */
export const decrypt = (data, key = keychain) => {
  let newKey = keychain.split(',');
  data ||= 'qmS+zyrHzvXggISMGD02GQ==';
  let decipher = crypto.createDecipher('aes-128-ecb', Buffer.from(newKey));
  return Buffer.concat([
    decipher.update(data, 'base64'),
    decipher.final(),
  ]).toString('utf8');
};

export default {
  getCode,
  encrypt,
  decrypt,
};
