import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Form, Row, Col, Button, Icon } from 'kenshin';
import { connect } from 'umi';
import UIType from '@/_util/UIType';
import styles from '../index.less';

const gutter = [16, 16];

const SearchForm = forwardRef((props, ref) => {
  const { columns, onSubmit, onReset, ...resProps } = props;

  const [formRef] = Form.useForm();
  const [collapseStatus, setCollapseStatus] = useState(false);

  const handleSubmit = () => {
    let values = formRef.getFieldsValue();
    onSubmit?.(values, false, true);
  };
  let ButtonProps = { flex: 'auto' };
  if ((columns.length - 3) % 4 == 0) {
    ButtonProps = { span: 5 };
  }
  useImperativeHandle(ref, () => ({
    value: formRef.getFieldsValue(),
    ...formRef,
  }));
  const formControlButtons = (
    <Col {...ButtonProps}>
      <Form.Item key="formControlButtons">
        <span className={styles.formControlButtons}>
          <Button type={'primary'} onClick={handleSubmit}>
            查询
          </Button>
          {onReset && (
            <Button
              onClick={() => {
                formRef.resetFields();
                onReset?.();
              }}
              style={{ marginLeft: 8 }}
            >
              重置
            </Button>
          )}
          {columns.length > 3 && (
            <Button
              type="text"
              onClick={() => {
                setCollapseStatus(!collapseStatus);
              }}
              style={{ paddingLeft: 8, paddingRight: 0 }}
            >
              {collapseStatus ? '收起' : '展开'}{' '}
              <Icon type={collapseStatus ? 'up' : 'down'} />
            </Button>
          )}
        </span>
      </Form.Item>
    </Col>
  );

  const FormItemRender = (item, index) => {
    const Element = React.createElement(UIType[item.type], item.props);
    return (
      <Col span={item.span || 5} key={index}>
        <Form.Item name={item.name} label={item.label}>
          {Element}
        </Form.Item>
      </Col>
    );
  };

  const renderType = () => {
    let arr = [...columns];
    let showItem = arr.splice(0, 3).map(FormItemRender);
    let hideItem = arr.map(FormItemRender);
    return (
      <>
        {showItem}
        {formControlButtons}
        {collapseStatus && hideItem}
      </>
    );
  };

  return (
    <>
      <Form form={formRef} onFinish={handleSubmit}>
        <Row gutter={gutter} key={collapseStatus}>
          {renderType()}
        </Row>
        <Form.Item hidden>
          <Button htmlType="submit" />
        </Form.Item>
      </Form>
    </>
  );
});

export default SearchForm;
