import { Card, Col, Row, Avatar, Tag } from 'kenshin';
import { OSSImgUrl } from '@/_util/util';
import busiTypeKey from '../../../_util/busiTypeKey';
import Util from '../../../createProcess/_unit';
const { WEEKEDIT } = busiTypeKey;

/** 忽略的属性 */
const excludeProps = [
  'contractMaterialList',
  'addTeamMemberList',
  'dbServCatgTeamMemberLis',
  'custLogo',
  'contractMaterial',
  'dbServCatgList',
  'expireDate',
];

/** 需要对金额进行转换的属性 */
const moneyArr = ['contractAmount', 'collectAmount'];
let i = 0;
export function diffStr(newDate, oldData) {
  if (typeof newDate != 'object' || typeof oldData != 'object') {
    return { newDate, oldData };
  }
  for (const key in newDate) {
    let newValue = newDate[key];
    let oldValue = oldData[key];
    if (moneyArr.includes(key)) {
      newValue = (+(newValue / 100).toFixed(2)).toString();
      oldValue = (+(oldValue / 100).toFixed(2)).toString();
    }
    if (!excludeProps.includes(key)) {
      if (
        ['string', 'number'].includes(typeof newValue) &&
        newValue != oldValue
      ) {
        newDate[key] = (
          <span className="diff" key={newValue + i}>
            {newValue}
          </span>
        );
        oldData[key] = (
          <span className="diff" key={oldValue + i}>
            {oldValue}
          </span>
        );
      }
      if (
        oldValue &&
        typeof newValue == 'object' &&
        Array.isArray(newValue) &&
        typeof newValue[0] == 'object'
      ) {
        let obj = newValue.map((item, i) => diffStr(item, oldValue[i]).newDate);
        newValue = obj.newDate;
        oldValue = obj.oldData;
      }
    }
  }
  i++;
  return {
    newDate,
    oldData,
  };
}

// const typeChnage = {
//   [CUSTOMER]: getCustomerCard,
//   [CONTRACT]: getContractCard,
//   [PROJECT]: getProjectCard,
//   [TASK]: getTaskCard,
//   [ROLE]: getRoleCard,
// };

const typeChnage = Object.fromEntries([
  ...Object.values(Util).map((item) => [item.key, item.getCard]),
  [WEEKEDIT, Util.OsaEditUtil.getCard],
]);

const ChangeCard = ({ afterData, beforeData, type }) => {
  const diffData = diffStr(
    afterData?.newData || afterData,
    beforeData?.newData || beforeData,
  );
  // return 123
  return (
    <div className="change-record">
      <Row gutter={20}>
        <Col span={10} className="old-data-box">
          <div className="box-title">变更前</div>
          {typeChnage[type]?.(diffData.oldData)}
        </Col>
        <Col span={10}>
          <div className="box-title">变更后</div>
          {typeChnage[type]?.(diffData.newDate)}
        </Col>
      </Row>
    </div>
  );
};

export default ChangeCard;
