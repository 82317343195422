import { extend } from 'umi-request';
import { notification } from 'kenshin';
import { history } from 'umi';

export let baseUrl = 'http://39.108.128.144:7860';
// export let baseUrl = "http://192.168.1.101:8000";

// 白名单：3000
// 500：服务器异常
// 1000：用户未登录
// USER_IS_NOT_LOGIN("105050", "用户未登陆"),
// ACCESSTOKNE_EXPIRE_RELOGIN("105051", "token过期请重新登录！"),
// ACCESSTOKNE_ERROR_RELOGIN("105052", "token错误请重新登录！"),

function errorHandler(error) {
  const codeMap = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
  };

  if (error.response) {
    // 请求已发送但服务端返回状态码非 2xx 的响应
    console.log(error?.response?.status);
    console.log(error?.response?.headers);
    console.log(error?.data);
    console.log(error?.request);
    console.log(codeMap[error.data.status]);
    console.log(error);
  } else {
    // 请求初始化时出错或者没有响应返回的异常
    console.log(error.message);
  }
  notification.error({
    // message: error.response.status,
    message: codeMap[error?.data?.status] || '请求错误',
    description: error?.data?.message || error.message,
  });

  // throw error; // 如果throw. 错误将继续抛出.

  // 如果return, 则将值作为返回. 'return;' 相当于return undefined, 在处理结果时判断response是否有值即可.
  // return {some: 'data'};
}

const request = extend({
  prefix: baseUrl,
  headers: {},
  errorHandler,
});

export const mock = extend({
  prefix: '',
  timeout: 5000,
  headers: {},
});

// get封装
export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    request
      .get(url, params)
      .then((res) => {
        // 错误处理
        if (res.code != 200) {
          notification.error({
            message: codeMessage[res.code],
            description: (
              <>
                {res.message?.split(',').map((item) => (
                  <div>{item}</div>
                ))}
              </>
            ),
            duration: 15,
          });
          reject(res);
        }
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const post = (url, params = {}, options = {}) => {
  // params.userId = USER_ID
  return new Promise((resolve, reject) => {
    request
      .post(url, { data: params, ...options })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default request;
