import Jsonform from '@/components/JsonForm';
import { Collapse, InputNumber, Checkbox, Button, Input } from 'kenshin';
import { useEffect, useRef, useState } from 'react';
import Select from '@/_util/components/Select';
import { deepClone } from '@/_util/util';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

const defaultList = () => ({
  osaScoreWeight: 0,
  osaScoreName: '',
  osaSeeOtherScore: false,
});

const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

const Info = ({ defaultValue, onSave, disabled }) => {
  const [graderList, setGraderList] = useState([defaultList()]);
  const hasChange = useRef(false);

  useEffect(() => {
    if (defaultValue) {
      setGraderList(deepClone(defaultValue));
    }
  }, [defaultValue]);

  const handleChange = (item, value) => {
    Object.assign(item, value);
    setGraderList([...graderList]);
    hasChange.current = true;
  };

  const handleLeave = () => {
    if (hasChange.current) {
      onSave?.({ osaScoreList: graderList });
      hasChange.current = false;
    }
  };

  return (
    <div onMouseLeave={handleLeave}>
      {graderList.map((item, index) => (
        <div
          key={index}
          className="flex-al-center"
          style={{
            marginBottom: 20,
            // paddingLeft: 180,
          }}
        >
          <div
            style={{
              display: 'inline-block',
              width: '20%',
              textAlign: 'right',
            }}
          >
            评分人：
          </div>
          {/* <Select
            placeholder="请选择评分人"
            options={options}
            style={{ width: 200, marginRight: 20 }}
          /> */}
          <Input
            disabled
            placeholder="请选择评分人"
            value={item.osaScoreName}
            style={{ width: 200, marginRight: 20 }}
          />
          评分权重：
          <InputNumber
            onChange={(val) => handleChange(item, { osaScoreWeight: val })}
            value={item.osaScoreWeight}
            stepType="inside"
            disabled={disabled}
            placeholder="请输入分数"
            style={{ marginRight: 20 }}
          />
          <Checkbox
            checked={!!item.osaSeeOtherScore}
            disabled={disabled || item.osaScoreType != 2}
            onChange={(e) =>
              handleChange(item, { osaSeeOtherScore: +e.target.checked })
            }
          >
            有权限查看他人评分
          </Checkbox>
          {/* {index == 0 && (
            <Button type="primary" onClick={add}>
              添加评分人
            </Button>
          )}
          {index != 0 && (
            <Button type="danger" onClick={() => deleteItem(item)}>
              删除
            </Button>
          )} */}
          {/* <PlusOutlined /> */}
        </div>
      ))}
    </div>
  );
};

export default Info;
