import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox } from 'kenshin';
import { getMeun } from '@/_serveice/menu';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import MenuItemEdit from './components/MenuItemEdit';

const menuConfig = ({ dispatch, loading, system, user }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const showEditModal = ({ children, ...row }) => {
    setEditModalVisible(true);
    setEditModalData(row);
  };

  const delMenuItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'system/delmenu',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const permTypeArr = {
    1: '菜单',
    2: <div style={{ color: '#fe7300' }}>按钮</div>,
    3: <div style={{ color: 'skyblue' }}>表头</div>,
  };

  const columns = [
    {
      title: '菜单名称',
      dataIndex: 'permName',
      fixed: 'left',
      key: 'permName',
      width: 200,
    },
    {
      title: '平台',
      width: 100,
      dataIndex: 'permPlate',
      key: 'permPlate',
      render: (permPlate) => {
        let strKey = {
          1: 'PC端',
          2: 'App',
        };
        return strKey[permPlate] ?? '-';
      },
    },
    {
      title: '类型',
      width: 100,
      dataIndex: 'permType',
      key: 'permType',
      render: (type) => permTypeArr[type],
    },
    // {
    //   title: '菜单id',
    //   dataIndex: 'permId',
    //   key: 'permId',
    // },
    {
      title: '路径',
      width: 240,
      dataIndex: 'permKey',
      key: 'permKey',
    },
    {
      title: '接口路径',
      width: 400,
      dataIndex: 'permValue',
      key: 'permValue',
    },
    {
      title: '权限级别',
      width: 120,
      dataIndex: 'permLevel',
      key: 'permLevel',
    },
    {
      title: '排序',
      width: 100,
      dataIndex: 'permSort',
      key: 'permSort',
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.hasbtnList.includes(btnKeys.add) && row.permType == 1 && (
              <Button
                type="text"
                onClick={() => {
                  showEditModal({
                    permId: row.permId,
                    permSort: row.children
                      ? row.children[row.children.length - 1]?.permSort + 1
                      : 1,
                  });
                }}
              >
                新增
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.edit) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                编辑
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.delete) &&
              row.permId != 1 &&
              !row.hasChild && (
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delMenuItem({ menuPermId: row.permId })}
                >
                  删除
                </Button>
              )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <TablePro
        request="system/getsysfunctiontree"
        version="system"
        rowKey={'permId'}
        pagination={false}
        columns={columns}
        scrollX
        dataSource={system.menuList}
        onChange={() => {}}
      />

      <MenuItemEdit
        visible={editModalVisible}
        data={editModalData}
        onCancel={() => {
          setEditModalData({});
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

menuConfig.title = '菜单配置';

export default connect(({ system, loading, user }) => {
  return {
    user,
    system,
    loading: loading.effects,
  };
})(menuConfig);
