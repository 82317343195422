import { InputNumber, Typography } from 'kenshin';
import './index.less';
import { useEffect, useRef } from 'react';
import { history, connect, useHistory } from 'umi';

import CollapseCard, { Template } from '../../components/CollapseCard';
import AutoSend from '../../components/AutoSend';


const InfoKey = {
  osaName: 'osaTemplateName',
  osaModel: 'osaTemplateModel',
};
const baseKey = {
  osaWeekType: 'osaTemplateWeekType',
  osaScoreRule: 'osaTemplateScoreRule',
};
const offTimeKey = {
  osaScoreEndDaysBeforeAfter: 'osaTemplateScoreEndDaysBeforeAfter',
};

const createTemplate = ({ dispatch, performance }) => {
  const templateId = useRef();
  const history = useHistory();

  useEffect(() => {
    if (!history.location.query.osaTemplateId) {
      dispatch({
        type: 'performance/initosatemplatedata',
        payload: {
          ...history.location.query,
        },
      }).then((id) => {
        if (id) {
          history.replace({
            query: {
              ...history.location.query,
              osaTemplateId: id,
            },
          });
          templateId.current = id;
          dispatch({
            type: 'performance/save',
            payload: {
              templateEditor: {
                osaTemplateId: id,
                osaTemplateName: '',
                osaTemplateDeptId: history.location.query.deptId,
                osaTemplateDeptName: history.location.query.deptName,
                osaTemplateModel: 1,
                osaTemplateWeekType: 0,
                osaTemplateNoticeDaysBeforeAfter: 1,
                osaTemplateScoreEndDaysBeforeAfter: 7,
                osaTemplateScoreRule: 1,
                osaTemplateEffectiveDate: +moment().startOf('date'),
                osaTemplateExpiryDate: +moment().endOf('date'),
                processId: null,
              },
            },
          });
        }
      });
    }
  }, []);

  const handleSave = (value) => {
    dispatch({
      type: 'performance/save',
      payload: {
        templateEditor: {
          ...performance.templateEditor,
          ...value,
        },
      },
    });
    dispatch({
      type: 'performance/updateosatemplatedata',
      payload: {
        ...performance.templateEditor,
        ...value,
      },
    });
  };

  const baseItem = {
    title: '自动分发时间',
    dataIndex: 'osaTemplateNoticeDaysBeforeAfter',
    render() {
      return <AutoSend />;
    },
    // valueType: 'InputNumber',
    // fieldProps: {
    //   placeholder: '自动分发时间',
    //   stepType: 'inside',
    // },
  };

  return (
    <div className="create-template-page">
      <Typography.Title level={4}>绩效考核模版设置</Typography.Title>
      <CollapseCard
        expandIconPosition="end"
        title="基本设置"
        cloumns={Template.INFO.default({
          propsKey: InfoKey,
          osaNameDisabled: false,
        })}
        defaultValue={performance.templateEditor}
        onSave={handleSave}
      />
      <CollapseCard title="常规指标">
        <Template.INDICATORS.default />
      </CollapseCard>
      <CollapseCard
        cloumns={Template.BASE.default({ propsKey: baseKey })}
        title="方案基本设置"
        defaultValue={performance.templateEditor}
        onSave={handleSave}
      />
      <Template.OFFTIME.default
        defaultValue={
          performance.templateEditor.osaTemplateScoreEndDaysBeforeAfter
        }
        propsKey={offTimeKey}
        onSave={handleSave}
      />
      <Template.VALIDITY.default
        defaultValue={performance.templateEditor}
        onSave={handleSave}
      />
    </div>
  );
};

export default connect(({ performance }) => {
  return { performance };
})(createTemplate);
