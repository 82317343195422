import React, { useEffect, useState, useRef } from 'react';
import { Button, message, MessageBox, Tag, Tooltip, Typography } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';

const ServCategory = ({ dispatch, loading, project }) => {
  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState(['1']);
  // useEffect(() => {
  //   dispatch({
  //     type: 'project/getServcatgEasyList',
  //   });
  // }, []);

  const modalRef = useRef({});

  const showEditModal = (row) => {
    setEditModalVisible(true);
    if (row?.pantIdList) {
      row.pantListId = row.pantIdList;
    }
    setEditModalData(row);
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'project/deleteServcatg',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = (value) => {
    const { pantListId, ...resValue } = value;
    // let pantList = project.servcatgEasyList.filter((item) =>
    //   pantListId?.includes(item.id),
    // );
    let payload = { ...resValue };

    let hasEdit = false;
    if (editModalData?._type == 'edit') {
      hasEdit = true;
      payload.servCatgId = editModalData.servCatgId;
      payload.pantList = [
        {
          id: editModalData.pantIdList[0],
        },
      ];
    } else {
      payload.pantList = [
        {
          id: editModalData.servCatgId,
          value: editModalData.servCatgName,
        },
      ];
    }
    dispatch({
      type: 'project/editServcatg',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
        message.success('操作成功');
      }
    });
  };

  const columns = [
    {
      dataIndex: 'servCatgName',
      title: '类目名称',
    },
    // {
    //   dataIndex: 'pantList',
    //   title: '上级类目',
    //   render: (pantIdList) =>
    //   pantIdList?.map((item) => <div key={item.id}>{item.value}</div>),
    // },
    {
      dataIndex: 'servCatgHourCost',
      title: '工时费单价/元',
    },
    {
      dataIndex: 'servCatgCount',
      title: '数量',
    },
    {
      dataIndex: 'servCatgUnit',
      title: '单位',
    },
    {
      dataIndex: 'servCatgWorkPeriod',
      title: '工期(天)',
    },
    {
      dataIndex: 'servCatgRemark',
      title: '描述',
      width: 300,
      render: (val) => (
        <Tooltip overlayStyle={{ maxWidth: 400 }} title={val}>
          <div className="ellipsis-text" style={{ width: 300 }}>
            {val}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '操作',
      width: 150,
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.hasbtnList.includes(btnKeys.add) && (
              <Button
                type="text"
                onClick={() => showEditModal({ ...row, _type: 'add' })}
              >
                新增
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.edit) && (
              <Button
                type="text"
                onClick={() => showEditModal({ ...row, _type: 'edit' })}
              >
                编辑
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.delete) &&
              row.servCatgId != '1' && (
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delRole({ serviceCategoryId: row.servCatgId })}
                >
                  删除
                </Button>
              )}
          </>
        );
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    // {
    //   label: '服务类目类型',
    //   name: 'servCatgType',
    //   type: 'RadioGroup',
    //   required: '请输入服务类目名称',
    //   props: {
    //     options:[
    //       {label:"标准工时",value:1},
    //       {label:"非标准工时",value:2},
    //     ],
    //     defaultValue:1
    //   },
    // },
    {
      label: '服务类目名称',
      name: 'servCatgName',
      type: 'Input',
      required: '请输入服务类目名称',
      props: {
        placeholder: '请输入服务类目名称',
      },
    },
    // {
    //   label: '上级类目',
    //   name: 'pantListId',
    //   type: 'Select',
    //   required: '请选择上级类目',
    //   props: {
    //     placeholder: '请选择上级类目',
    //     mode: 'multiple',
    //     options: project.servcatgEasyList.map((item) => ({
    //       label: item.value,
    //       value: item.id,
    //     })),
    //   },
    // },
    {
      label: '工时费单价',
      name: 'servCatgHourCost',
      type: 'InputNumber',
      // required: '请输入工时费单价',
      props: {
        placeholder: '请输入工时费单价',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      label: '数量',
      name: 'servCatgCount',
      type: 'InputNumber',
      // required: '请输入数量',
      props: {
        placeholder: '请输入数量',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      label: '单位',
      name: 'servCatgUnit',
      type: 'Input',
      // required: '请输入单位',
      props: {
        placeholder: '请输入单位',
      },
    },
    {
      label: '工期',
      name: 'servCatgWorkPeriod',
      type: 'InputNumber',
      // required: '请输入工期',
      props: {
        placeholder: '请输入工期',
        stepType: 'inside',
        style: { width: '100%' },
        min: 0,
      },
    },
    {
      label: '描述',
      name: 'servCatgRemark',
      type: 'TextArea',
      props: {
        placeholder: '请输入描述',
      },
    },
    // {
    //   label: '测试按钮',
    //   render: () => (
    //     <Button
    //       onClick={() => {
    //         const { form } = modalRef.current;
    //         let data = {
    //           pantListId: ['1'],
    //           servCatgCount: 22,
    //           servCatgHourCost: 11,
    //           servCatgName: '服务类目名称',
    //           servCatgRemark: '描述',
    //           servCatgUnit: '元',
    //           servCatgWorkPeriod: 33,
    //         };
    //         form.setFieldsValue(data);
    //       }}
    //     >
    //       测试
    //     </Button>
    //   ),
    // },
  ];

  // const extra = (
  //   <Button type="primary" key={btnKeys.add} onClick={() => showEditModal()}>
  //     新增类目
  //   </Button>
  // );

  const onExpandedRowsChange = (keys) => {
    setExpandedRowKeys(keys);
  };

  return (
    <div>
      <TablePro
        request="project/getServcatgList"
        rowKey={(row, i) => row.servCatgId || i}
        columns={columns}
        dataSource={[project.servcatgList]}
        // extra={extra}
        childrenColumnName="child"
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange,
        }}
        pagination={false}
      />

      <ModalFormPro
        width={600}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData?._type == 'edit' ? editModalData : {}}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ project, loading }) => {
  return {
    project,
    loading: loading.effects,
  };
})(ServCategory);
