import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { pageDown, pageUp } from '@/_assets/icon';
import { useSelector, useDispatch } from 'umi';
import { getRoleType } from '@/_util/util';

const empWorkList = () => [
  {
    label: '本周工时（时）',
    props: 'weekWorkHour',
    type: '同比上周',
    imgUrl: require('@/_assets/weekWorkHour-bg.png'),
  },
  {
    label: '参与项目（个）',
    props: 'partinProject',
    type: '同比上年',
    imgUrl: require('@/_assets/partinProject-bg.png'),
  },
  {
    label: '参与工作包（个）',
    props: 'partinWorkPackage',
    type: '同比上月',
    imgUrl: require('@/_assets/partinWorkPackage-bg.png'),
  },
  {
    // 留空
  },
];
const financeWorkList = () => [
  {
    callback: (data) => {
      return {
        label: '昨日上报人数',
        style: {
          backgroundImage: `url(${require('@/_assets/finance1.png')})`,
        },
        value: data.yesterdayRptEmpNum,
        icon: 'noRptWork-icon',
        desc: `实际${data.companyEmpNum || 0}人，上报${
          data.yesterdayRptEmpNum || 0
        }（缺${data.companyEmpNum - data.yesterdayRptEmpNum || 0}人）`,
      };
    },
  },
  {
    label: '缺失上报(人次)',
    props: 'noRptMonthCount',
    type: '同比上月',
    imgUrl: require('@/_assets/finance2.png'),
    icon: 'noRptWork-icon',
  },
  {
    label: '年度合同金额',
    props: 'contYearAmount',
    step: 100,
    type: '同比上年',
    imgUrl: require('@/_assets/noRptWork-bg.png'),
    icon: 'finance-icon',
  },
  {
    label: '年度项目消耗金额',
    props: 'consumerYearAmount',
    step: 100,
    type: '同比上年',
    imgUrl: require('@/_assets/finance4.png'),
    icon: 'finance-icon',
  },
  {
    label: '年度实际工时成本',
    props: 'costYearActualAmount',
    step: 100,
    type: '同比上年',
    imgUrl: require('@/_assets/finance5.png'),
    icon: 'finance-icon',
  },
];

const HomeCard = memo(() => {
  const dispatch = useDispatch();
  const usercenter = useSelector(({ usercenter }) => usercenter);

  const aMountDiv = useRef([]);

  useEffect(() => {
    let empId = localStorage.getItem('muId');
    dispatch({
      type: 'usercenter/getEmpHomeWork',
      payload: {
        empId,
      },
    });
  }, []);

  const getRoleOptions = useMemo(() => {
    if (getRoleType() == 1) {
      return financeWorkList();
    }
    if (getRoleType() == 2) {
      let res = empWorkList();
      res[3] = {
        label: '缺失上报（人次）',
        props: 'noRptWork',
        type: '同比上月',
        imgUrl: require('@/_assets/noRptWork-bg.png'),
      };
      return res;
    }
    return empWorkList();
  }, []);

  const createDataForRole = useCallback((roleOptions) =>
    roleOptions.map((item) => {
      let step = item.step || 1;
      if (item.callback) {
        return item.callback(usercenter.homeWork);
      }
      return {
        percentChange: 0,
        className: item.props,
        style: {
          backgroundImage: `url(${item.imgUrl})`,
        },
        ...item,
        ...(usercenter.homeWork?.[item.props] || {}),
        value:
          +(usercenter.homeWork?.[item.props]?.value / step).toFixed(2) || 0,
      };
    }),
  );

  let data = createDataForRole(getRoleOptions);

  const changeIcon = useCallback(
    (num) => {
      if (num > 0) return pageUp;
      if (num < 0) return pageDown;
      return null;
    },
    [data],
  );

  return (
    <div className={`home-card ${getRoleType() == 1 ? 'finance-card' : ''}`}>
      {data.map((item, i) => {
        return (
          <div
            className={`card-item ${item.className}`}
            key={i}
            style={item.style}
          >
            {item.label && (
              <>
                <div
                  className={`img-box ${item.icon || item.className + '-icon'}`}
                ></div>
                <div className="card-content">
                  <div className="label">{item.label}</div>
                  <div
                    className="value"
                    ref={(ref) => (aMountDiv.current[i] = ref)}
                  >
                    {item.value}
                  </div>
                  <div className="fairly">
                    <span className="num">
                      {item.desc || (
                        <>
                          {changeIcon(item.percentChange)}
                          {+item.percentChange}%
                        </>
                      )}
                    </span>
                    <span className="date">{item.type}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
});

export default HomeCard;
