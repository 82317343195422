

export default {
  namespace: 'admin',

  state: {
    user:"admin",
    tabsList:[]
  },

  // 类似vue的actions，里面可用执行异步操作
  effects: {

    *setProps({ payload }, { put, select }) {
      let componentList = yield select(state => state.home.componentList)
      let currentElement = yield select(state => state.home.currentElement)
      if(payload.propsName){
        currentElement[payload.propsName] = payload.value
      }else if (payload.key === "children") {
        currentElement.children = payload.value
      } else {
        currentElement.props[payload.key] = payload.value
      }
      // const i = componentList.findIndex(item => item.key == currentElement.key);

      let findRes = findComponent(componentList, currentElement);
      findRes.parent.splice(findRes.indexs[findRes.indexs.length - 1], 1, currentElement);
      yield put({
        type: 'save',
        payload: {
          currentElement,
          componentList,
        },
      })
    }
  },

  reducers: {
    // 每次保存数据的时候讲state再存一次，避免下一次数据存储的时候把原数据删了
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
