import EllipsisTooltip from '@/components/EllipsisTooltip';
import React from 'react';
import { Card, Col, Row } from 'kenshin';
import busiTypeKey from '../../_util/busiTypeKey';
import CollapseSearch from './components/CollapseSearch';
import { history } from 'umi';
/** 表格表头 */
const cloumns = () => {
  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      width: 120,
      fixed: 'left',
      render(userInfo) {
        return userInfo?.value;
      },
    },
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      width: 120,
      fixed: 'left',
      render(value) {
        const key = {
          1: '周计划',
          3: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaSumRemark',
      title: '评分',
      width: 180,
      render: (row) => {
        if (row.osaWeekType == 1) {
          return <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>;
        }
        return [
          <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
          <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
          <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
        ];
      },
    },
    {
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
    },
  ];
  return columns;
};
/** 搜索数据 */
const searchCloumns = ({}) => {
  const searchCloumns = [];
  return searchCloumns;
};

/** 编辑数据 */
const formCloumns = ({}) => [];

/** 首次保存 */
const firstSave = (values, { process, getAfterStr }) => {};

/** 再次保存 */
const reSave = (values, { process, getAfterStr }) => {};

/** 创建后 */
const created = ({ dispatch, type = 8 }) => {
  dispatch({ type: 'performance/getmychangeosastatdata', payload: { type } });
};

/** 预览card */
const getCard = (data) => {
  if (Object.keys(data).length == 0) {
    return null;
  }
  return (
    <Card className="process-card-box">
      <Row gutter={[5, 20]}>
        <Col span={20}>
          <span className="label">任务名称：</span>
          {data.taskName}
        </Col>
        <Col span={20}>
          <span className="label">项目：</span>
          {data.projectName}
        </Col>
        <Col span={20}>
          <span className="label">服务类目：</span>
          {data.proServCatgName}
        </Col>
      </Row>
    </Card>
  );
};

const tableOptions = ({ performance, dispatch, type = 8, tableRef }) => {
  const extra = () => {
    return (
      <CollapseSearch
        onChange={(payload) => {
          tableRef?.reload(payload, true);
          // dispatch({ type: 'performance/getmychangeosapage', payload:{...payload,page:{pageNo:1,pageSize:10}} });
        }}
      />
    );
  };
  return {
    extra,
    request: 'performance/getmychangeosapage',
    data: performance.osaChangeList,
    columns: cloumns(),
    rowKey: 'osaId',
    key: 'osaEdit',
    params: {
      type,
    },
  };
};

const onSubmit = ({ curData, dispatch }) => {
  return () => {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    let curnNodeNumber;
    if (curData.current?.osaMuId == localEmpId)
      curnNodeNumber = 'OSA_CHANGE_UPDATE_OSA_NODE';
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          rows: {
            ...curData.current,
            oldOsaProcessNodeNumber: curData.current.curnNodeNumber,
            curnNodeNumber,
            processApplyMuId: localEmpId,
          },
        },
      },
    });
    history.push({
      pathname: '/performance/selfosa/changosa',
      query: {},
    });
  };
};

export default {
  tableOptions,
  chooseId: 'osaId',
  propsName: (data) => data['osaName'],
  onSubmit,
  cloumns,
  searchCloumns,
  formCloumns,
  firstSave,
  reSave,
  onShow: created,
  getCard,
  key: busiTypeKey.OSAEDIT,
};
