import { history } from 'umi';

/** 判断节点是否连续操作 */
export function nextNodeIsMine(curData, node, callback) {
  const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  let nodeList = {
    OSA_BEGIN_NODE: false, // ("开始节点"),
    CREATE_OSA_NODE: false, // ("创建绩效"),
    CONFIRM_OSA_NODE: false, // ("确认绩效(被考核人)"),
    AUDIT_OSA_ONE_NODE: curData.busiLeader?.id == localEmpId, // ("审批绩效-1(直属上级)"),
    AUDIT_OSA_TWO_NODE: false, // ("审批绩效-2(业务负责人)"),
    AUDIT_OSA_THREE_NODE: false, // ("审批绩效-3(人事预审)"),
    AUDIT_OSA_FOUR_NODE: false, // ("审批绩效-4(人事入档)"),
    SCORE_ONE_NODE: false, // ("评分-1(自评)"),
    SCORE_TWO_NODE: curData.busiLeader?.id == localEmpId, // ("评分-2(直属上级)"),
    SCORE_THREE_NODE: false, // ("评分-3(业务负责人)"),
    GRADE_NODE: false, // ("评级(业务负责人)"),
    HR_PRE_CONFIRM_NODE: false, // ("人事预确认(入库)"),
    HR_CONFIRM_NODE: false, // ("人事确认(入库)"),
    OSA_END_NODE: false, // ("结束节点");
  };
  let nodeKey = Object.keys(nodeList);
  let hasNext = nodeList[node];
  let nodeIndex = nodeKey.indexOf(node);
  callback?.(node);
  if (hasNext && nodeKey[nodeIndex + 1]) {
    nextNodeIsMine(curData, nodeKey[nodeIndex + 1], callback);
  }
  // return nodeList[node];
}

/** 审核类型 */
export const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

/** 目标类型是否为编辑 */
export const targetTYPE = {
  edit: 'E',
};

let quarter = moment().quarter();
let year = moment().year();
export const defaliltOsaYearQuarter = `${year}${quarter}`;

export const osaBeginTime = moment().startOf('Q').format('YYYY-MM-DD HH:mm:ss');
export const osaEndTime = moment().endOf('Q').format('YYYY-MM-DD HH:mm:ss');

const curMuId = localStorage.getItem('muId');

// 是否显示确认绩效按钮
export const isShowConfirmBtn = (row) => {
  if (row) {
    const localEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
    let node = {
      CREATE_OSA_NODE: true,
      AUDIT_OSA_ONE_NODE: localEmpId == row.directLeader?.id,
      AUDIT_OSA_TWO_NODE: localEmpId == row.busiLeader?.id,
      AUDIT_OSA_THREE_NODE: true,
      AUDIT_OSA_FOUR_NODE: true,
      OSA_BEGIN_NODE: true,
      CONFIRM_OSA_NODE: !row.osaMuEncryptId || row.osaMuEncryptId == curMuId,
    };
    return node[row.curnNodeNumber];
  }
  if (history.location.query.busiId) {
    return true;
  }
};

// 是否显示驳回按钮
export const hasShowReturnBtn = (rows) => {
  const cuEmpId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;
  if (!rows) {
    return false;
  }
  if (
    rows.curnNodeNumber == 'AUDIT_OSA_ONE_NODE' &&
    rows.directLeader?.id == cuEmpId
  ) {
    return true;
  }
  return [
    'AUDIT_OSA_TWO_NODE',
    'AUDIT_OSA_THREE_NODE',
    'AUDIT_OSA_FOUR_NODE',
  ].includes(rows?.curnNodeNumber);
};
// 状态管理的actions
export const dispatchApi = {
  GETTREE: 'performance/getosauserindextree',
  GETDIRTREE: 'performance/getosauserindexcatgtree',
  UPDATEDIR: 'performance/updateosaemployeeindexcatgdata',
  UPDATEITEM: 'performance/updateosaemployeeindexitemdata',
  DELETEDIR: 'performance/deleteosaemployeeindexcatgdata',
  DELETEITEM: 'performance/deleteosaemployeeindexitemdata',
  ADDTREE: 'performance/addosaemployeeindexdata',
  MOVEDIR: 'performance/moveosaemployeeindexcatgdata',
  MOVEITEM: 'performance/moveosaemployeeindexitemdata',
  IMPOERSHEET: 'performance/importosaempindexdata',
  COPYHISTORY: 'performance/copyhistoryosaindexdata',
};

/** 是否禁用 */
export const isDisabled = (curData) => {
  console.log(
    '绩效isDisabled',
    ![
      'OSA_BEGIN_NODE',
      'CREATE_OSA_NODE',
      'CONFIRM_OSA_NODE',
      'AUDIT_OSA_ONE_NODE',
    ].includes(curData.curnNodeNumber),
    curData.curnNodeNumber,
  );

  if (curData.curnNodeNumber) {
    return ![
      'OSA_BEGIN_NODE',
      'CREATE_OSA_NODE',
      'CONFIRM_OSA_NODE',
      'AUDIT_OSA_ONE_NODE',
    ].includes(curData.curnNodeNumber);
  }

  return history.location.query.osaId ?? history.location.query.busiId;
};

/** 指标类型 */
export const indicatorsKey = {
  DIR: 1,
  ITEM: 2,
};

/** 获取当前季度 */
export function getQuarter(time, step) {
  if (step == -1) {
    return [
      moment(time).subtract(1, 'quarter').startOf('quarter'),
      moment(time).subtract(1, 'quarter').endOf('quarter'),
    ];
  } else {
    return [
      moment(time).add(1, 'quarter').startOf('quarter'),
      moment(time).add(1, 'quarter').endOf('quarter'),
    ];
  }
}

/** 递归校验目录必须含有一个或多个指标 */
export const indicatorsValidate = (list) =>
  list.every((item) => {
    if (item.type == indicatorsKey.DIR && !(item.childList?.length > 0)) {
      return false;
    }
    if (Array.isArray(item.childList)) {
      return indicatorsValidate(item.childList);
    }
    return true;
  });

// 根据季度获取开始跟结束时间
export function getStartAEnd(quarter) {
  if (quarter) {
    let Y = quarter.slice(0, 4); // 年
    let Q = quarter.slice(4);
    let date = moment().set({ year: Y, quarter: Q }).startOf('quarter');
    let osaBeginTime = date.format('YYYY-MM-DD');
    let osaEndTime = date.endOf('quarter').format('YYYY-MM-DD');
    return { osaBeginTime, osaEndTime };
  }
  return {};
}

/** 扁平化指标 */
export function floatIndicators(tree) {
  if (!Array.isArray(tree)) return [];
  return tree.reduce((prev, curr) => {
    if (Array.isArray(curr.childList)) {
      prev.unshift(curr);
      return prev.concat(floatIndicators(curr.childList));
    }
    return prev.concat(curr);
  }, []);
}
