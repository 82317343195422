import { Steps, Row, Col, Tooltip } from 'kenshin';
import { connect, history } from 'umi';
import TablePro from '@/components/TablePro';
import { memo } from 'react';

const ProjectInfo = memo(({ data }) => {
  return (
    <>
      <div className="title">项目信息</div>
      <div className="proj-info">
        <Row>
          <Col span={6}>
            <div>
              <span>负责人：</span>
              {data?.projectManagerName}
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <div>
              <span>项目周期：</span>
              {data.projectBeginTime} - {data.projectEndTime}
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <div>
              <span>所属部门：</span>
              {data.belongDept?.value}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <div>
              <span>实际金额：</span>
              {data.projectAmount / 100}
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={13}>
            <div>
              <span>项目描述：</span>
              {data.projectDescribtion}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
});

const Progress = memo(({ process }) => {
  const proNodeDealResultString = {
    1: '待处理',
    2: '处理成功',
    3: '处理失败',
  };
  const customDot = (dot, { index }) => {
    const content = (
      <>
        <div>
          {process[index]?.proNodeDealUserName}
          <div className="flr">
            {proNodeDealResultString[process[index]?.proNodeDealResult]}
          </div>
        </div>
        <div className="tip-text">
          创建时间：
          {moment(process[index]?.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </>
    );

    if (!process[index]) {
      return null;
    }

    return (
      <Tooltip
        title={content}
        overlayStyle={{
          color: '#333',
          width: 200,
          maxWidth: 200,
          fontSize: 12,
          lineHeight: '24px',
        }}
        color="#fff"
      >
        <span>{dot}</span>
      </Tooltip>
    );
  };

  const proNodeObj = {
    1: '创建项目',
    2: '项目提交',
    3: '部门审核',
    4: '财务审核',
    5: '项目生效',
  };

  const proNodeDealResultObj = {
    1: 'wait',
    2: 'finish',
    3: 'error',
  };

  return (
    <>
      <div className="title">处理进度</div>
      <Steps
        // current={1}
        progressDot={customDot}
        style={{ margin: '20px 0 40px' }}
      >
        {process?.map((item) => (
          <Steps.Step
            key={item.proNodeId}
            title={proNodeObj[item.proNode]}
            status={proNodeDealResultObj[item.proNodeDealResult]}
          />
        ))}
        {/* <Steps.Step title="完成" status={proNodeDealResultObj[process[process.length - 1]?.proNodeDealResult]} /> */}
      </Steps>
    </>
  );
});

const InfoDetail = memo(({ data, list, process }) => {
  const resType = {
    1: '通过',
    2: '驳回',
    3: '已完成',
  };
  const optionsType = {
    1: '创建项目',
    2: '项目提交',
    3: '部门审核',
    4: '财务审核',
    5: '项目生效',
  };
  const columns = [
    {
      dataIndex: 'proOperationType',
      title: '操作类型',
      render: (val) => optionsType[val],
    },
    {
      dataIndex: 'proOperationUserName',
      title: '操作人',
    },
    {
      dataIndex: 'proOperationResult',
      title: '执行结果',
      render: (val) => resType[val],
    },
    {
      dataIndex: 'gmtCreate',
      title: '操作时间',
      render: (val) => moment(val).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  return (
    <>
      <Progress process={process} />

      <ProjectInfo data={data} />

      <div className="title">操作日志</div>
      <TablePro
        params={{
          projectId: history.location.query?.projectId,
        }}
        request="project/getProjectLogList"
        rowKey="proOperationLogId"
        columns={columns}
        curColumns
        pagination={false}
        dataSource={list}
      />
    </>
  );
});

export default InfoDetail;
