import { connect, history } from 'umi';
import { Button, message, Table } from 'kenshin';
import TablePro from '@/components/TablePro';
import { DownOutlined } from '@ant-design/icons';
import ModalFormPro from '@/components/ModalFormPro';
import styles from './index.less';
import { useEffect, useRef, useState } from 'react';
import { dicLType } from '@/_util/keysType';
import PageLoading from '@/components/pageLoading';
import { exportCustomerListUrl } from '@/_serveice/customer/list';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';

export const custStatusObj = {
  1: <div className="status-green animate">合作中</div>,
  0: <div className="status-red">终止合作</div>,
};

const CustomerPage = ({ customer, dispatch, loading, system }) => {
  // 修改状态
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  const [selectKey, setSelectKey] = useState([]);

  const tableRef = useRef();

  const statusFormItem = [
    {
      label: '状态',
      name: 'custStatus',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        options: [
          {
            label: '终止合作',
            value: 0,
          },
          {
            label: '合作中',
            value: 1,
          },
        ],
      },
    },
  ];

  const statusSave = (val) => {
    let custInfoStatusDTO = {
      custContIdList: statusData,
      custStatus: val.custStatus,
    };
    dispatch({
      type: 'customer/customerSatus',
      payload: custInfoStatusDTO,
    }).then((res) => {
      if (res) {
        setStatusVisible(false);
        setStatusData(null);
      }
    });
  };

  const editClick = (row) => {
    row.custContactsList = row.custContactsList?.map((item) => ({
      ...item,
      contTag: item.contTag?.split(','),
    }));
    dispatch({
      type: 'customer/save',
      payload: {
        editData: row,
      },
    });
    history.push({
      pathname: '/customer/newCustomer',
      query: { custId: row.custId },
    });
  };

  // 表头数据
  const columnsObj = {
    columns: [
      { title: '客户名称', dataIndex: 'custCompFullName' },
      {
        title: '客户级别',
        dataIndex: 'custLevel',
        render: (val) =>
          system.dictypeListAll[dicLType.CUSTOMERTYPE]?.find(
            (item) => item.simpleData.id == val,
          )?.simpleData.value,
      },
      {
        title: '合作年限',
        dataIndex: 'custCooperTimeList',
        render: (val) =>
          val?.map((item, i) => {
            return (
              <div key={i}>
                {moment(item.beginTime).format('YYYY-MM-DD')} ~{' '}
                {item.endTime
                  ? moment(item.endTime).format('YYYY-MM-DD')
                  : '至今'}
              </div>
            );
          }),
      },
      // {
      //   title: '合作年限',
      //   dataIndex: 'custCooperTime',
      //   render: (val) => getCustCooperTimeToString(val),
      // },
      { title: '公司电话', dataIndex: 'custCompLandLine' },
      {
        dataIndex: 'formalProjectNum',
        title: '项目情况',
        render: (val) => (!val ? '暂无项目' : `${val}个项目进行中`),
      },
      {
        dataIndex: 'custStatus',
        title: '客户状态',
        render: (val) => custStatusObj[val],
      },
      {
        key: 'options',
        title: '操作',
        render: (row) => {
          return (
            <>
              {row.hasbtnList?.includes(btnKeys.edit) && (
                <Button type="text" onClick={() => editClick(row)}>
                  编辑
                </Button>
              )}
              {row.hasbtnList?.includes(btnKeys.statusChange) && (
                <Button
                  type="text"
                  onClick={() => {
                    setStatusVisible(true);
                    setStatusData([row.custId]);
                  }}
                >
                  修改状态
                </Button>
              )}
            </>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'customerName',
      type: 'Input',
      props: {
        placeholder: '客户名称',
      },
    },
    {
      name: 'custStatus',
      type: 'Select',
      props: {
        placeholder: '状态',
        options: Object.keys(custStatusObj).map((item) => ({
          value: item,
          label: custStatusObj[item],
        })),
      },
    },
    {
      name: 'custLevel',
      type: 'Select',
      props: {
        placeholder: '客户级别',
        options: system.dictypeListAll[dicLType.CUSTOMERTYPE]?.map((item) => ({
          label: item?.simpleData.value,
          value: item?.simpleData.id,
        })),
      },
    },
    {
      name: 'custCooperTimeDate',
      type: 'DatePickerRangePicker',
      label: '合作年限',
      props: {
        placeholder: ['开始时间', '结束时间'],
      },
    },
  ];
  // 页面头部按钮
  const extra = [
    <Button
      type="primary"
      key={btnKeys.add}
      onClick={() => {
        history.push({
          pathname: '/customer/newCustomer',
        });
      }}
    >
      添加客户
    </Button>,
    <Button
      type="primary"
      key={btnKeys.statusChange}
      onClick={() => {
        if (selectKey?.length == 0) {
          message.warn('请选择批量操作的客户！');
          return;
        }
        setStatusVisible(true);
        setStatusData(selectKey);
      }}
    >
      批量修改状态
    </Button>,
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let { custCooperTimeDate, ...value } = tableRef.current.value;
        let payload = {
          beginCooperTime: value.beginCooperTime ?? '',
          custLevel: value.custLevel ?? '',
          custStatus: value.custStatus ?? '',
          customerName: value.customerName ?? '',
          endCooperTime: value.endCooperTime ?? '',
          // ...value,
        };
        if (custCooperTimeDate) {
          payload.beginCooperTime = moment(custCooperTimeDate[0])
            .startOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
          payload.endCooperTime = moment(custCooperTimeDate[1])
            .endOf('d')
            .format('YYYY-MM-DD HH:mm:ss');
        }
        let url = `${baseURL}${exportCustomerListUrl}${splitParamsFoGet(
          payload,
        )}`;
        downloadXlsx(url, '客户列表');
      }}
    >
      导出
    </Button>,
  ];

  const onExpand = (expanded, { custId }) => {
    if (expanded && !customer.projectLByCustIdList[custId]) {
      dispatch({
        type: 'customer/getProjectLByCustId',
        payload: {
          custId,
        },
      });
    }
  };

  const projectStatusObj = {
    0: '未开始',
    1: '正常',
    2: '停止',
  };

  // 表格折叠后的表格
  const expandedRowRender = ({ custId }) => {
    if (loading['customer/getProjectLByCustId']) {
      return <PageLoading />;
    }
    const columns = [
      { title: '项目名称', dataIndex: 'projectName' },
      { title: '合同编号', dataIndex: 'contractNumber' },
      {
        title: '项目周期',
        key: 'custCooperTime',
        render: (row) =>
          row.projectBeginTime
            ? `${moment(row.projectBeginTime).format('YYYY-MM-DD')} ~ ${moment(
                row.projectEndTime,
              ).format('YYYY-MM-DD')}`
            : '',
      },
      {
        title: '项目成员',
        dataIndex: 'proTeamNum',
        // render: (val) => `${val}人` ,
        render: (val) =>  val? `${val}人` : '-',
      },
      {
        title: '项目状态',
        dataIndex: 'projectStatus',
        render: (val) => projectStatusObj[val],
      },
    ];
    return (
      <Table
        rowKey="projectId"
        columns={columns}
        dataSource={customer.projectLByCustIdList[custId] ?? []}
        pagination={false}
      />
    );
  };

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList) => {
      setSelectKey(userIdList);
    },
  };

  const onSubmit = (payload) => {
    if (payload.custCooperTimeDate) {
      const [start, end] = payload.custCooperTimeDate;
      payload.beginCooperTime = moment(start)
        .startOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
      payload.endCooperTime = moment(end)
        .endOf('d')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    payload.custCooperTimeDate = undefined;
    // return false
  };

  return (
    <div className={styles.customerList}>
      <TablePro
        request="customer/getCustomerList"
        searchCloumns={searchCloumns}
        rowKey={'custId'}
        data={customer.customerList}
        extra={extra}
        expandable={{ onExpand, expandedRowRender }}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={onSubmit}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        {...columnsObj}
      ></TablePro>

      <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ customer, loading, system }) => ({
  customer,
  system,
  loading: loading.effects,
}))(CustomerPage);
