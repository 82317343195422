import request, { mock } from '@/_util/require';
let version = 'v1';

// ==================================================================OSA==================================================================

/** 新增用户绩效模板指标数据 */
export async function addosaemployeeindexdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/addosaemployeeindexdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 新增用户绩效模板指标目录数据 */
export async function addosaemployeeindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/addosaemployeeindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 新增用户绩效模板指标条目数据 */
export async function addosaemployeeindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/addosaemployeeindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 删除用户绩效模板指标目录数据 */
export async function deleteosaemployeeindexcatgdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/deleteosaemployeeindexcatgdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 删除用户绩效模板指标条目数据 */
export async function deleteosaemployeeindexitemdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/deleteosaemployeeindexitemdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询员工用户绩效汇总数据 */
export async function getosaempstatdata(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/getosaempstatdata`, {
    method: 'GET',
    params,
  });
}

/** 查询用户绩效模板的指标目录树结构 */
export async function getosauserindexcatgtree(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getosauserindexcatgtree`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询用户用户绩效指标树结构 */
export async function getosauserindextree(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/getosauserindextree`, {
    method: 'GET',
    params,
  });
}

/** 查询绩效月度策略 */
export async function getosamonthstraget(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/getosamonthstraget`, {
    method: 'GET',
    params,
  });
}

/** 查询绩效历史月度策略 */
export async function gethistoryosamonthstraget(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/gethistoryosamonthstraget`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 新增|修改绩效月度策略 */
export async function addupdateosamonthstraget(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/addupdateosamonthstraget`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 新增|修改变更绩效月度策略 */
export async function addupdateosachangemonthstraget(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/addupdateosachangemonthstraget`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 查询变更绩效月度策略 */
export async function getosachangemonthstraget(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getosachangemonthstraget`,
    {
      method: 'GET',
      params,
    },
  );
}

//----------------------------------------------------------------

/** 初始化用户绩效模板数据 */
export async function initosaemployeedata(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/initosaemployeedata`, {
    method: 'POST',
    data: options,
  });
}
/** 移动用户绩效模板指标目录数据 */
export async function moveosaemployeeindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/moveosaemployeeindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 移动用户绩效模板指标条目数据 */
export async function moveosaemployeeindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/moveosaemployeeindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改用户绩效模板数据 */
export async function updateosaemployeedata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosaemployeedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改用户绩效模板指标目录数据 */
export async function updateosaemployeeindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosaemployeeindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改用户绩效模板指标条目数据 */
export async function updateosaemployeeindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosaemployeeindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 修改员工绩效通知数据 */
export async function updateosaemployeenoticedata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosaemployeenoticedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 修改员工绩效评分 */
export async function updateosaemployeescore(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosaemployeescore`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 分页查询员工绩效列表 */
export async function getosaemployeepage(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/getosaemployeepage`, {
    method: 'POST',
    data: options,
  });
}

/** 分页查询我的绩效列表 */
export async function getosamypage(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/getosamypage`, {
    method: 'POST',
    data: options,
  });
}

/** 查询我的绩效汇总数据 */
export async function getmyosastatdata(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/getmyosastatdata`, {
    method: 'GET',
    params,
  });
}

/** 查询用户绩效流程列表 */
export async function getosauserprocesslist(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getosauserprocesslist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询员工绩效数据 */
export async function getosaemployeedata(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/getosaemployeedata`, {
    method: 'GET',
    params,
  });
}

/** 审核绩效流程 */
export async function auditosaprocess(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/auditosaprocess`, {
    method: 'POST',
    data: options,
  });
}

/** 删除员工绩效 */
export async function deleteosaemployee(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/deleteosaemployee`, {
    method: 'GET',
    params,
  });
}

/** 审核绩效流程 */
export async function osaindexscore(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/osaindexscore`, {
    method: 'POST',
    data: options,
  });
}

/** 查询绩效评级A的可用个数 */
export async function getosagradeaenablenum(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getosagradeaenablenum`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询员工绩效审核人、评分人数据 */
export async function getosaemployeeauditscoredata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getosaemployeeauditscoredata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 分页查询所有员工绩效列表 */
export async function getallosaemployeepage(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getallosaemployeepage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 查询员工周计划数据 */
export async function queryempweekplandata(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/queryempweekplandata`, {
    method: 'POST',
    data: options,
  });
}

/** 发起绩效或者周计划变更申请 */
export async function launchosaweekplanchangeapply(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/launchosaweekplanchangeapply`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 发起绩效或者周计划变更申请 */
export async function getosachangeprocesslist(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getosachangeprocesslist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 通过业务id查询员工绩效变更数据(待办处调用) */
export async function getosachangeemployeedata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getosachangeemployeedata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 删除绩效变更数据 */
export async function deleteosachangedata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/deleteosachangedata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 新增绩效变更指标目录数据 */
export async function addosachangeindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/addosachangeindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 新增绩效变更指标数据 */
export async function addosachangeindexdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/addosachangeindexdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 新增绩效变更指标条目数据 */
export async function addosachangeindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/addosachangeindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 移动绩效变更指标目录数据 */
export async function moveosachangeindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/moveosachangeindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 移动绩效变更指标条目数据 */
export async function moveosachangeindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/moveosachangeindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 修改绩效变更指标目录数据 */
export async function updateosachangeindexcatgdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/updateosachangeindexcatgdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 修改绩效变更指标条目数据 */
export async function updateosachangeindexitemdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/updateosachangeindexitemdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 删除绩效变更指标目录数据 */
export async function deleteosachangeindexcatgdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/deleteosachangeindexcatgdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 删除绩效变更指标条目数据 */
export async function deleteosachangeindexitemdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/deleteosachangeindexitemdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询绩效变更指标树结构 */
export async function getosachangeindextree(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getosachangeindextree`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询绩效变更指标目录树结构 */
export async function getosachangeindexcatgtree(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getosachangeindexcatgtree`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询最新的绩效变更数据 */
export async function getlastosachangedata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getlastosachangedata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 绩效|周计划是否有正在进行中的变更流程 */
export async function haveosachangeprocess(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/haveosachangeprocess`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 绩效|周计划是否有正在进行中的变更流程 */
export async function osaChangeAuditosaprocess(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/auditosaprocess`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 初始化周计划的年度日期数据 */
export async function initweekplanyeardatedata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/weekplan/date/initweekplanyeardatedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 新增或者修改周计划日期数据 */
export async function addupdateweekplandatedata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/weekplan/date/addupdateweekplandatedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 绩效|周计划是否有正在进行中的变更流程 */
export async function deleteweekplandatedata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/weekplan/date/deleteweekplandatedata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 绩效|查询指定年份的周计划日期列表 */
export async function getyearweekplandatedata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/weekplan/date/getyearweekplandatedata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 绩效|查询指定年份的周计划日期列表 */
export async function updateosaempindexactucalvalue(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosaempindexactucalvalue`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 导出员工绩效指标数据 */
export const exportemposaindexdata = `/${version}/waper/erp/pc/osa/employee/exportemposaindexdata`;

/** 导出绩效变更指标数据 */
export const exportosachangeindexdata = `/${version}/waper/erp/pc/osa/employee/change/exportosachangeindexdata`;

/** 导入员工绩效指标数据 */
export async function importosaempindexdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/importosaempindexdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 复制历史绩效指标数据 */
export async function copyhistoryosaindexdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/copyhistoryosaindexdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 导入绩效变更指标数据 */
export async function importosachangeindexdata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/importosachangeindexdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 复制历史绩效指标数据 */
export async function copyhistoryosachangeindexdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/copyhistoryosachangeindexdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询用户绩效：直属上级、业务负责人是否评分、评级 */
export async function queryosadirectbusiscoregrade(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/queryosadirectbusiscoregrade`,
    {
      method: 'POST',
      data: options,
    },
  );
}
/** 分页查询我可以发起绩效|周计划变更的绩效列表(新起流程使用) */
export async function getmychangeosapage(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/getmychangeosapage`, {
    method: 'POST',
    data: options,
  });
}

/** 查询我可以发起绩效|周计划变更的汇总数据(新起流程使用) */
export async function getmychangeosastatdata(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getmychangeosastatdata`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 修改绩效变更数据 */
export async function updateosachangedata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/updateosachangedata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 修改绩效变更数据 */
export async function osachangeindexscore(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/osachangeindexscore`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 修改绩效变更指标实际值 */
export async function updateosachangeindexactucalvalue(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/updateosachangeindexactucalvalue`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 撤销绩效(变更)|周计划(变更)操作 */
export async function osaweekplanrevoke(params) {
  return request(`/${version}/waper/erp/pc/osa/employee/osaweekplanrevoke`, {
    method: 'GET',
    params,
  });
}

export const exportosaweekplanstatdata = `/${version}/waper/erp/pc/osa/statistics/exportosaweekplanstatdata`;
export const exportosastatdata = `/${version}/waper/erp/pc/osa/statistics/exportosastatdata`;

/** 绩效一键评分 */
export async function osaindexscorebatch(options) {
  return request(`/${version}/waper/erp/pc/osa/employee/osaindexscorebatch`, {
    method: 'POST',
    data: options,
  });
}

/** 撤销绩效(变更)|周计划(变更)操作 */
export async function getosaauditsystemdescribelist(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getosaauditsystemdescribelist`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 修改绩效指标目录评分数据 */
export async function updateosacatgscoredata(options) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/updateosacatgscoredata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

/** 查询绩效|周计划流程拒绝信息 */
export async function getosaweekprocessrefuseinfo(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/getosaweekprocessrefuseinfo`,
    {
      method: 'GET',
      params,
    },
  );
}

/** 查询绩效|周计划变更流程拒绝信息 */
export async function getosaweekchangeprocessrefuseinfo(params) {
  return request(
    `/${version}/waper/erp/pc/osa/employee/change/getosaweekchangeprocessrefuseinfo`,
    {
      method: 'GET',
      params,
    },
  );
}

// ==================================================================OSA指标end==================================================================
