import { VideoCameraAddOutlined } from '@ant-design/icons';
import { useReactive } from 'ahooks';
import { Tooltip, Tabs, Input, Button, message } from 'kenshin';
import FileUpload from '../UploadFile';
import styles from './styles.less';
import { EditorState, AtomicBlockUtils } from 'draft-js';
import { OSSImgUrl } from '../../_util/util';

const VideoItem = (props) => {
  const { dirUrl } = props;
  const state = useReactive({
    key: '1',
    url: '',
    visible: false,
  });

  const handleClick = () => {
    if (!state.url) return message.error('请上传地址或者输入视频地址！');
    const editorState = props.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'VIDEO',
      'IMMUTABLE',
      {
        // src: 'https://oss.waperidea.cn/legrand-ebook.waper2.com/prod/罗格朗样板-杭州.mp4?time=1691634958290',
        src: state.url,
      }, // Replace with the actual image URL
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      {
        currentContent: contentStateWithEntity,
      },
      'create-entity',
    );
    // let content = newEditorState.getCurrentContent();
    const newContentState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' ',
    );
    // const newEditorStateWithImage = EditorState.push(
    //   newEditorState,
    //   newContentState,
    //   'insert-fragment',
    // );
    state.visible = false;
    state.key = '1';
    state.url = '';
    props.onChange(newContentState);
  };

  /** 监听是否打开浮窗 */
  const handleChange = (visible) => {
    console.log('visible', visible);
    state.visible = visible;
    if (visible) {
      state.key = '1';
      state.url = '';
    }
  };

  /** 浮窗显示内容 */
  const title = (
    <div className={styles.titleBox}>
      <Tabs
        activeKey={state.key}
        onChange={(key) => (state.key = key)}
        type="mini"
      >
        <Tabs.TabPane tab="上传" key="1" />
        <Tabs.TabPane tab="地址" key="2" />
      </Tabs>
      {state.key == '1' && (
        <FileUpload
          key={state.visible}
          style={{ width: '100%' }}
          accept="video/*"
          dirUrl={dirUrl}
          onChange={(list) => {
            // console.log(url);
            state.url = OSSImgUrl(list[0]?.url);
            console.log(list);
          }}
        />
      )}
      {state.key == '2' && (
        <Input
          value={state.url}
          onChange={(e) => (state.url = e.target.value)}
          placeholder="视频地址"
        />
      )}

      <Button style={{ marginTop: 20 }} onClick={handleClick}>
        Add
      </Button>
    </div>
  );

  /** 图标样式 */
  let iconStyle = {
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #eee',
    marginBottom: '5px',
    cursor: 'pointer',
  };
  return (
    <Tooltip
      visible={state.visible}
      onVisibleChange={handleChange}
      title={title}
      trigger="click"
      placement="bottom"
      overlayStyle={{
        color: '#333',
        minWidth: 250,
        minHeight: 250,
        fontSize: 12,
        lineHeight: '24px',
      }}
      color="#fff"
      noArrows
    >
      <VideoCameraAddOutlined style={iconStyle} />
    </Tooltip>
  );
};

export default VideoItem;
