import React, { useEffect, useRef, useState } from 'react';
import { connect, history } from 'umi';
import { Button, message, MessageBox } from 'kenshin';
import JsonForm from '@/components/JsonForm';
import CustomerInfo from '@/components/CustomerInfo';
import { dicLType } from '@/_util/keysType';
import { deepClone } from '../../../_util/util';
import './index.less';
import { addCache, delCache, getCache } from '@/_serveice/admin/util';
import {
  firstaddprocessapply,
  firstaddprocessapplycommit,
  updateprocessapply,
  updatecommitprocessapply,
  processapplyvalide,
} from '@/_serveice/admin/process';

const TypeKey = {
  PROCESS: 'process',
  EDITPROCESS: 'editProcess',
};

const NewCustomerPage = ({ dispatch, customer, system, usercenter }) => {
  const ref = useRef();
  const cacheId = useRef();

  const enterType = history.location.query.type;

  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    const { form } = ref.current;
    form.setFieldsValue({
      ...deepClone(customer.editData),
      key: customer.editData.custId,
      custFirstCooperTime: moment(customer.editData.custFirstCooperTime),
    });
    // customerTagList
    dispatch({
      type: 'customer/getCustomerTagList',
    });

    const custId = history.location.query.custId;
    if (custId && !enterType) {
      processapplyvalide({ applyBusiId: custId, applyBusiType: 1 }).then(
        (res) => {
          if (!res.data) {
            setBtnDisabled(true);
            message.warn('当前客户务存在未完成的流程变更!');
          }
        },
      );
    }

    setCacheToForm();

    return () => {
      dispatch({
        type: 'customer/save',
        payload: {
          editData: {},
        },
      });
      dispatch({
        type: 'usercenter/save',
        payload: {
          createProcessData: {},
        },
      });
    };
  }, []);

  const setCacheToForm = () => {
    getCache().then((res) => {
      if (res.success) {
        if (res.data) {
          cacheId.current = res.data.cacheId;
          MessageBox.confirm({
            title: '系统提示',
            content: '当前页面有缓存数据，是否应用缓存的数据',
            onOk: () => {
              const { form } = ref.current;
              let data = JSON.parse(res.data.params);
              data.custFirstCooperTime = moment(data.custFirstCooperTime);
              form.setFieldsValue({ ...data });
              // message.success('已成功删除！');
            },
          });
        }
      }
    });
  };

  const uploadAccept = 'image/*';
  const edtaFormItem = [
    {
      title: '客户公司全称',
      dataIndex: 'custCompFullName',
      valueType: 'Input',
      required: '请输入客户公司全称',
      fieldProps: {
        placeholder: '请输入客户公司全称',
      },
    },
    {
      title: '客户公司简称',
      dataIndex: 'custCompShortName',
      valueType: 'Input',
      required: '请输入客户公司简称',
      fieldProps: {
        placeholder: '请输入客户公司简称',
      },
    },
    {
      title: '公司电话',
      dataIndex: 'custCompLandLine',
      valueType: 'Input',
      required: '请输入公司电话',
      fieldProps: {
        placeholder: '请输入公司电话',
      },
    },
    {
      title: '客户级别',
      dataIndex: 'custLevel',
      valueType: 'Select',
      required: '请选择客户级别',
      fieldProps: {
        placeholder: '请选择客户级别',
        options: system.dictypeListAll[dicLType.CUSTOMERTYPE]?.map((item) => ({
          label: item?.simpleData.value,
          value: item?.simpleData.id,
        })),
      },
    },
    {
      title: '客户Logo',
      dataIndex: 'custLogo',
      valueType: 'UploadImg',
      fieldProps: {
        dirUrl: '/customer/logo',
        accept: uploadAccept,
      },
    },
    {
      title: '首次合作时间',
      dataIndex: 'custFirstCooperTime',
      valueType: 'DatePicker',
      required: '请选择首次合作时间',
      fieldProps: {},
    },
    {
      title: '公司详细描述',
      dataIndex: 'custCompDes',
      valueType: 'TextArea',
      fieldProps: {
        placeholder: '请输入公司详细描述',
        maxLength: 120,
      },
    },
    {
      title: '对接人信息',
      dataIndex: 'custContactsList',
      required: '请输入对接人信息',
      component: (
        <CustomerInfo
          onChange={() => {
            const { form } = ref.current;
            let cacheData = form.getFieldsValue();
            cacheData.custFirstCooperTime = moment(
              cacheData.custFirstCooperTime,
            ).format('YYYY-MM-DD HH:mm:ss');
            addCache(JSON.stringify(cacheData), cacheId.current).then((res) => {
              if (!cacheId.current) {
                // 新增缓存后存储缓存ID作为下一次修改的ID
                cacheId.current = res.data;
              }
            });
          }}
        />
      ),
    },
  ];

  /** 修改values里面的等级喝标签为中文 */
  const getStrForId = (values) => {
    let obj = {};
    customer.customerTagList.map((item) => {
      obj[item.id] = item.value;
    });
    values.custLevelStr = system.dictypeListAll[dicLType.CUSTOMERTYPE].find(
      (item) => item.simpleData.id == values.custLevel,
    )?.simpleData.value;

    values?.custContactsList.map((item) => {
      item.tagIdListStr = item.tagIdList?.map((item) => obj[item]) || [];
    });
  };

  /** 再次保存跟提交 */
  const saveOrSumbit = (values) => {
    values.custFirstCooperTime = moment(values.custFirstCooperTime).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    getStrForId(values);

    let params = {
      ...usercenter.createProcessData,
      dataChangeAfter: JSON.stringify({
        ...JSON.parse(usercenter.createProcessData?.dataChangeBefore),
        ...values,
      }),
      busiType: undefined,
    };
    return params;
  };

  //  首次保存跟提交
  const saveOrSumbitFirst = (values) => {
    values.custFirstCooperTime = moment(values.custFirstCooperTime).format(
      'YYYY-MM-DD HH:mm:ss',
    );

    getStrForId(customer.editData);
    getStrForId(values);

    // 获取等级字符串
    values.custLevelStr = system.dictypeListAll[dicLType.CUSTOMERTYPE].find(
      (item) => item.simpleData.id == values.custLevel,
    )?.simpleData.value;

    values?.custContactsList.map((item) => {
      item.tagIdListStr = item.tagIdList;
    });

    let params = {
      ...usercenter.createProcessData,
      processApplyDataChangeDTO: {
        dataChangeAfter: JSON.stringify({ ...customer.editData, ...values }),
        dataChangeBefore: JSON.stringify(customer.editData),
      },
      busiType: undefined,
    };
    return params;
  };

  /** 保存 */
  const saveTypeFn = {
    [TypeKey.PROCESS](values) {
      // 首次
      // saveOrSumbitFirst(values);
      firstaddprocessapply(saveOrSumbitFirst(values)).then((res) => {
        if (res.success) {
          message.success('保存成功');
          delCache();
          handleCancel();
        }
      });
    },
    [TypeKey.EDITPROCESS](values) {
      // 再次
      updateprocessapply(saveOrSumbit(values)).then((res) => {
        if (res.success) {
          message.success('保存成功');
          delCache();
          handleCancel();
        }
      });
    },
  };

  const handleSave = () => {
    const { form } = ref.current;
    form.validateFields().then((values) => {
      // 流程申请
      if (enterType) {
        return saveTypeFn[enterType](values);
      }
      let { custContactsList, custFirstCooperTime, ...resValue } = values;
      custContactsList = custContactsList.map((item) => {
        const { key, time, cont, contTag, ...resItem } = item;
        let contCountry, contProvince, contArea;
        if (cont) {
          [contCountry = null, contProvince = null, contArea = null] = cont;
        }
        return {
          ...resItem,
          contCountry,
          contProvince,
          contBorn: undefined,
          contArea,
          contTag: Array.isArray(contTag) ? item.contTag?.join(',') : contTag,
        };
      });
      let hasEdit = false;
      let payload = {
        ...resValue,
        custFirstCooperTime: moment(custFirstCooperTime)
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss'),
        custContactsList,
      };
      if (history.location.query?.custId) {
        hasEdit = true;
        payload.custId = history.location.query?.custId;
      }

      dispatch({
        type: 'customer/customerEdit',
        hasEdit,
        payload,
      }).then((res) => {
        if (res) {
          message.success('保存成功！');
          delCache();
          handleCancel();
        }
      });
    });
  };

  const handleCancel = () => {
    if (enterType == TypeKey.PROCESS || enterType == TypeKey.EDITPROCESS) {
      history.go(-1);
    } else {
      history.push('/customer/list');
    }
  };

  const handleSubmit = () => {
    const { form } = ref.current;
    form.validateFields().then((values) => {
      if (enterType == TypeKey.PROCESS) {
        // saveOrSumbitFirst(values)
        firstaddprocessapplycommit(saveOrSumbitFirst(values)).then((res) => {
          if (res.success) {
            message.success('提交成功');
            delCache();
            handleCancel();
          }
        });
      } else {
        updatecommitprocessapply(saveOrSumbit(values)).then((res) => {
          if (res.success) {
            message.success('提交成功');
            delCache();
            handleCancel();
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="form_header">
        <h3>{history.location.query?.custId ? '编辑' : '新增'}客户</h3>
        <div>
          {(enterType == TypeKey.PROCESS ||
            enterType == TypeKey.EDITPROCESS) && (
            <Button type="primary" onClick={handleSubmit}>
              提交
            </Button>
          )}
          <Button type="primary" onClick={handleSave} disabled={btnDisabled}>
            保存
          </Button>
          <Button onClick={handleCancel}>返回</Button>
        </div>
      </div>
      <JsonForm columns={edtaFormItem} ref={ref} />
    </div>
  );
};

export default connect(({ customer, system, usercenter }) => ({
  customer,
  system,
  usercenter,
}))(NewCustomerPage);
