import pinyin4js from 'pinyin4js';
const RESTSTART = '12:00:00';
const RESTEND = '13:30:00';
const WORKSTART = '09:00:00';
const WORKEND = '18:00:00';

const getMoment = (time, hour) => {
  if (!time.format) {
    time = moment(time);
  }
  return moment(`${time.format('YYYY-MM-DD')} ${hour}`);
};

/**
 * 判断是否包含在休息时间内的时间
 * @param {moment} start 开始时间
 * @param {moment} end 结束时间
 * @returns
 */
const hasSub = (start, end) =>
  (start.get('hours') >= 13 && end.get('hours') >= 13) ||
  (start.get('hours') <= 12 && end.get('hours') <= 12);

/**
 * 当开始时间小时大于结束时间小时，也就是小于一天时差多少天
 * @param {moment} start
 * @param {moment} end
 * @returns
 */
function subTime(start, end) {
  let restTime = 1.5;
  // 开始时间大于12点并且小于13点半，则将开始时间设为13点半
  if (
    start > getMoment(start, RESTSTART) &&
    start < getMoment(start, RESTEND)
  ) {
    start = getMoment(start, RESTEND);
  }
  // 开始时间大于12点并且小于13点半，则将结束时间设为12点
  if (end > getMoment(start, RESTSTART) && end < getMoment(start, RESTEND)) {
    end = getMoment(start, RESTSTART);
  }
  // =======2022-3-19 14:00:00 ========两者间========2022-3-20-12:00:00======
  // 定义开始结束时间起点跟终点,9点上班，18点下班
  let startTime = getMoment(end, WORKSTART);
  let endTime = getMoment(start, WORKEND);
  let diffStart = endTime.diff(start, 'minute') / 60; // 结束时间距离9点之间数值
  diffStart =
    start < getMoment(start, RESTEND) ? diffStart - restTime : diffStart;
  // let diffEnd = end.get('hours') - startTime.get('hours');
  let diffEnd = end.diff(startTime, 'minute') / 60; // 开始时间距离18时间之前的数值
  diffEnd = end.get('hours') > 12 ? diffEnd - restTime : diffEnd;

  return diffStart + diffEnd;
}

/**
 * 上班下班时间天数计算小时差
 * @param {date} startTime
 * @param {date} endTime
 * @returns
 */
function hoursTime(startTime, endTime, type, decimal = 2) {
  // 休息时间范围内：12-13.5之间的时间段应为0
  if (
    moment(startTime).isSame(moment(endTime), 'day') &&
    moment(startTime) >= moment(startTime).set('h', 12).set('m', 0) &&
    moment(endTime) <= moment(endTime).set('h', 13).set('m', 30)
  ) {
    console.log('休息时间范围内：12-13.5之间的时间段应为0');
    return 0;
  }
  let start = moment(startTime);
  let end = moment(endTime);
  let days = end.get('date') - start.get('date'); // 天数差
  // let days = end.diff(start, 'd'); // 天数差
  let hoursDiff = end.get('hours') - start.get('hours'); // 小时差
  let minuteDiff = (end.get('minute') - start.get('minute')) / 60; // 分钟差 --转化为小时类型 30 分钟即 0.5 小时
  let delTime = hasSub(start, end) ? 0 : 1.5; // 减去午休时间
  let res = 0;
  if (hoursDiff >= 0) {
    res = days + (hoursDiff + minuteDiff - delTime) / 7.5;
  } else {
    res = days - 1 + subTime(start, end) / 7.5;
    // res = days + subTime(start, end) / 7.5;
  }

  // console.log("startTime,endTime:",days,subTime(start, end)/ 7.5,res);

  if (type == 'hours') {
    res = (days - 1) * 7.5 + subTime(start, end);
    // console.log("type == 'hours'",res);
    return res < 0 ? 0 : +res.toFixed(2);
  }

  return +res.toFixed(decimal);
}
/**
 * 上班下班时间小时（天数）差，会自动去除午休时间
 * @param {date} time 时间区间，[开始时间,结束时间]
 * @param {date} type 查询类型，hours小时，date天，默认小时
 * @returns
 */
export const timeDiff = (time, type = 'hours', decimal = 2) => {
  console.log('startTime,endTime:', time);
  if (!time) {
    return 0;
  }
  const start = moment(time[0]);
  const end = moment(time[1]);
  return hoursTime(start, end, type, decimal);
};

// 树遍历查找
export const findTree = (fildTree, id) => {
  for (let i = 0; i < fildTree.length; i++) {
    if (fildTree[i].deptId == id) {
      return fildTree[i];
    }
    if (fildTree[i].child) {
      return findTree(fildTree[i].child, id);
    }
  }
};

/**
 *
 * @param {String} label 搜索项的label
 * @param {String} input 输入数据
 * @returns Boolean
 */
export const selectPinyinFilter = (bseLlabel, baseInput) => {
  let input = baseInput.toLowerCase();
  let label = bseLlabel.toLowerCase();
  if (label && input) {
    let resPinyinAll = pinyin4js.convertToPinyinString(
      label,
      '',
      pinyin4js.WITHOUT_TONE,
    );
    let resPinyinSimple = pinyin4js.getShortPinyin(label);

    return (
      resPinyinAll.indexOf(input) != -1 || resPinyinSimple.indexOf(input) != -1
    );
  }
};
/**
 *  树形组件遍历搜索，含有children和title属性
 * @param {Array} arr
 * @param {string} value
 * @returns
 */
export const filterTree = (arr, value) => {
  return arr.filter((item) => {
    if (item.children) {
      let children = filterTree(item.children, value);
      item.children = children;
      if (children.length > 0) {
        return true;
      }
    }
    return item.title.includes(value) || selectPinyinFilter(item.title, value);
  });
};

/**
 * 【开始，结束】时间转成YY-MM-DD格式展示的字符串
 * @param {Array} timer 开始结束时间
 * @returns
 */
export const timerYMD = (timer) => {
  const [start, end] = timer;
  if (start && end) {
    return `${moment(start).format('YYYY-MM-DD')} ~ ${moment(end).format(
      'YYYY-MM-DD',
    )}`;
  }
  return '';
};
