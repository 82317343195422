import { memo, useEffect } from 'react';
import HomeCard from './HomeCard';
import AnalyseCharts from '@/components/AnalyseCharts';
import TablePro from '@/components/TablePro';
import './index.less';
import { Row, Col } from 'kenshin';
import { useSelector, useDispatch } from 'umi';
// import { dataRule } from '../dataAnalyse/cost/department';
import FinanceCharts from './FinanceCharts';

const Employee = memo((props) => {
  const dispatch = useDispatch();
  const dataAnalyse = useSelector(({ dataAnalyse }) => dataAnalyse);

  // useEffect(() => {
  //   dispatch({
  //     type: `dataAnalyse/${dispatchType.charts}`,
  //     dataRule,
  //     payload,
  //   });
  // }, []);

  const columns = [
    {
      title: '名称',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    {
      title: '编码',
      dataIndex: 'projectCode',
      key: 'projectCode',
    },
    {
      title: '排序',
      dataIndex: 'projectSort',
      key: 'projectSort',
    },
  ];

  return (
    <div>
      <div className="card-box">
        <div className="home-card-title">个人工作台</div>
        <HomeCard />
      </div>
      <div className="card-box">
        {/* <div className="home-card-title">合同成本利润分析</div> */}
        <div className="home-card-title">成本分析</div>
        {/* <AnalyseCharts {...dataAnalyse[dataType.charts]} /> */}
        <FinanceCharts />
      </div>
      {/* <Row gutter={20} style={{ marginTop: 20 }}>
        <Col span={10}>
          <div className="card-box">
            <div className="home-card-title">项目执行情况</div>
            <TablePro columns={columns} dataSource={[]} pagination={false} />
          </div>
        </Col>
        <Col span={5}>
          <div className="card-box">
            <div className="shadow-charts">
              <PieShade />
            </div>
          </div>
        </Col>
        <Col span={5}>
          <div className="card-box">
            <div className="shadow-charts">
              <PieShade />
            </div>
          </div>
        </Col>
      </Row> */}
    </div>
  );
});

export default Employee;
