import React from 'react';
import busiTypeKey from '../../_util/busiTypeKey';
import { Card, Col, Row, Avatar, Tag, Table, Button } from 'kenshin';
import { OSSImgUrl } from '@/_util/util';
import { history } from 'umi';

/** 表格表头 */
const cloumns = () => {
  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      width: 120,
      fixed: 'left',
      render(userInfo) {
        return userInfo?.value;
      },
    },
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      width: 120,
      fixed: 'left',
      render(value) {
        const key = {
          1: '周计划',
          3: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaSumRemark',
      title: '评分',
      width: 180,
      render: (row) => {
        if (row.osaWeekType == 1) {
          return <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>;
        }
        return [
          <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
          <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
          <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
        ];
      },
    },
    {
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
    },
  ];
  return columns;
};
/** 搜索数据 */
const searchCloumns = ({}) => {
  const searchCloumns = [];
  return searchCloumns;
};

/** 编辑数据 */
const formCloumns = ({}) => [];

/** 首次保存 */
const firstSave = (values, { process, getAfterStr }) => {};

/** 再次保存 */
const reSave = (values, { process, getAfterStr }) => {};

/** 创建后 */
const created = ({ dispatch }) => {};

/** 预览card */
const getCard = (data) => {
  if (Object.keys(data).length == 0) {
    return null;
  }
  return (
    <Card className="process-card-box">
      <Row gutter={20} wrap={false}>
        <Col flex="100px">
          <Avatar
            shape="square"
            size={130}
            style={{ backgroundColor: '#3d6cb7' }}
            src={OSSImgUrl(data.custLogo)}
          >
            {data.projectName?.[0]}
          </Avatar>
        </Col>
        <Col flex="auto">
          <Row gutter={[5, 20]}>
            <Col span={20}>
              <div className="title1">{data.custCompFullName}</div>
            </Col>
            <Col span={10}>
              <span className="label">公司简称：</span>
              {data.custCompShortName}
            </Col>
            <Col span={10}>
              <span className="label">公司电话：</span>
              {data.custCompLandLine}
            </Col>
            <Col span={10}>
              <span className="label">客户级别：</span>
              {data.custLevelStr}
            </Col>
            <Col span={10}>
              <span className="label">首次合作时间：</span>
              {data.custFirstCooperTime}
            </Col>
            <Col>
              <Row wrap={false}>
                <Col className="label" flex="60px">
                  公司详情：
                </Col>
                <Col flex="auto">{data.custCompDes}</Col>
              </Row>
            </Col>

            <Col span={20}>
              <div className="label">对接人：</div>
              {data.custContactsList.map((item, i) => (
                <Card key={i} style={{ marginTop: 10 }}>
                  <Row gutter={[0, 20]} align="middle">
                    <Col span={20} className="user-box">
                      <span>{item.contName}</span>
                      {[item.contMobile, item.contEmail]
                        .filter(Boolean)
                        .join('、')}
                    </Col>
                    <Col span={6}>
                      <span className="label">职位：</span>
                      {item.contJob}
                    </Col>
                    <Col span={6}>
                      <span className="label">直属上司：</span>
                      {item.contImedtSuper}
                    </Col>
                    <Col span={8}>
                      <span className="label">人物标签：</span>
                      {item.tagIdListStr?.map((tag, i) => (
                        <Tag key={i} style={{ marginRight: 4 }} type="primary">
                          {tag}
                        </Tag>
                      ))}
                    </Col>
                    <Col span={20}>
                      <Row wrap={false}>
                        <Col className="label" flex="60px">
                          功能描述：
                        </Col>
                        <Col flex="auto">{item.contRemark}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

/** 确定 */
const onSubmit = ({ dispatch, curData, setCreateBtnLoading }) => {
  return (params) => {
    dispatch({
      type: 'usercenter/save',
      payload: {
        createProcessData: params,
      },
    });
    dispatch({
      type: 'customer/save',
      payload: {
        editData: curData.current,
      },
    });
    history.push({
      pathname: '/customer/newCustomer',
      query: {
        custId: curData.current.custId,
        type: 'process',
      },
    });
    setCreateBtnLoading(false);
  };
};

export default {
  key: busiTypeKey.CUSTOMER,
  chooseId: 'custId',
  propsName: (data) => data['custCompFullName'],
  cloumns,
  searchCloumns,
  formCloumns,
  firstSave,
  reSave,
  created,
  getCard,
  onSubmit,
};
