import { useEffect } from 'react';
import { dicLType, roleType } from '@/_util/keysType';

export default (dispatch) => {
  dispatch({
    type: 'user/getAddressList',
  });

  dispatch({
    type: 'process/getprocessapplytypelist',
  });

  // 字典类型
  let btnPer = localStorage.getItem('buttonPermissionList');
  // /waper/erp/pc/sysdictype/getsysdictypetree
  dispatch({
    type: 'system/getDictypeList',
  }).then(async (res) => {
    if (res) {
      for (let key of Object.keys(dicLType)) {
        await dispatch({
          type: 'system/getDictypeAll',
          payload: {
            dicTypeKey: dicLType[key],
          },
        });
      }
      for (let key of Object.keys(roleType)) {
        await dispatch({
          type: 'systemOrgani/getRoleKeyObj',
          payload: {
            roleKey: roleType[key],
          },
        });
      }
    }
  });
};
