import { message } from 'kenshin';

/** 合同拦截 */
export const contractSubmit = (val) => {
  if (val.contractTimeLong) {
    const [start, end] = val.contractTimeLong;
    val.contractBeginTimeLong = +moment(start).startOf('date');
    val.contractEndTimeLong = +moment(end).endOf('date');
  }
  val.contractTimeLong = undefined;
  if (!!val.contractAmountMax != !!val.contractAmountMin) {
    message.warning('缺少最小金额或最大金额条件！');
    return false;
  }

  val.contractAmountMax &&
    (val.contractAmountMax = val.contractAmountMax * 100);
  val.contractAmountMin &&
    (val.contractAmountMin = val.contractAmountMin * 100);
  return val;
};

export const taskSubmit = (payload, { employeeAll }) => {
  if (employeeAll?.length == 0) {
    return false;
  }
  if (payload.taskTime) {
    const [start, end] = payload.taskTime;
    payload.taskBeginTime = moment(start)
      .startOf('d')
      .format('YYYY-MM-DD HH:mm:ss');
    payload.taskEndTime = moment(end).endOf('d').format('YYYY-MM-DD HH:mm:ss');
  }
  payload.taskTime = undefined;
  if (payload.memberList) {
    payload.memberList = payload.memberList.map((item) => item.split('-')[1]);
  }
  return payload;
};

export const customerSubmit = (payload) => {
  if (payload.custCooperTimeDate) {
    const [start, end] = payload.custCooperTimeDate;
    payload.beginCooperTime = moment(start)
      .startOf('d')
      .format('YYYY-MM-DD HH:mm:ss');
    payload.endCooperTime = moment(end)
      .endOf('d')
      .format('YYYY-MM-DD HH:mm:ss');
  }
  payload.custCooperTimeDate = undefined;
  return payload;
};
