import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
} from 'kenshin';
import { connect, history, useActivate } from 'umi';
import TablePro from '@/components/TablePro';
import { OSATYPEKEY } from '../assessment/_keys';
import renderProcessList from '../components/ProcessList';
import PreviewIndicators from '../components/PreviewIndicators';
import CollapseSearch from '../components/CollapseSearch';

const OSAWEEKOSATYPE = {
  WEEK: 1,
  OSA: 3,
};

const getEditBtn = (row) => {
  if (row.osaWeekType == OSAWEEKOSATYPE.OSA) {
    const showKey = [
      'CREATE_OSA_NODE',
      'CONFIRM_OSA_NODE',
      'AUDIT_OSA_ONE_NODE',
    ];
    return showKey.includes(row.curnNodeNumber);
  } else if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
    const showKey = [
      'CREATE_WEEK_PLAN_NODE',
      'CONFIRM_WEEK_PLAN_NODE',
      'AUDIT_WEEK_PLAN_ONE_NODE',
    ];
    return showKey.includes(row.curnNodeNumber);
  }
  return false;
};

const Template = ({ dispatch, employee_salary, performance }) => {
  let employeeDTO = JSON.parse(localStorage.getItem('employeeDTO'));

  function getHasBusi() {
    if (!employeeDTO.empBusiLeaderId) {
      MessageBox.confirm({
        title: '系统提示',
        content: '当前用户暂无业务负责人，请联系管理员添加业务负责人',
      });
      return false;
    }
    return true;
  }

  const [osaTime, setOsaTime] = useState([]);
  const tableRef = useRef();

  useActivate(() => {
    dispatch({
      type: 'performance/getmyosastatdata',
    });
    dispatch({
      type: 'performance/getosamypage',
    });
  });

  useEffect(() => {
    dispatch({
      type: 'performance/getmyosastatdata',
    });
  }, []);

  const getProcessList = (osaId, visible) => {
    if (visible) {
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId,
          todoBusiId: '',
        },
      });
    }
  };

  let searchTimer = null;

  /** 删除  */
  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '你确定要删除吗？',
      onOk: () => {
        dispatch({
          type: 'performance/deleteosaemployee',
          action: 'getosamypage',
          payload: row,
        }).then((success) => {
          if (success) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  /** 详情页 */
  const toDetail = () => {
    history.push({
      pathname: '/performance/assessment/OSA/OSADetail',
    });
  };
  /** 跳转编辑页面 */
  const handleEdit = (row, disabled) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: { ...row, title: row.userInfo.value, key: row.osaMuId },
        },
      },
    });
    if (disabled) {
      return toDetail(row);
    }
    let type = row.osaWeekType == OSAWEEKOSATYPE.OSA ? 1 : 2;
    if (type == OSATYPEKEY.OSA) {
      history.push({
        pathname: '/performance/assessment/OSA/OSAsetting',
        query: {
          muId: row.osaMuId,
          osaYearQuarter: row.osaYearQuarter,
          target: 'E',
        },
      });
    } else if (type == OSATYPEKEY.WEEK) {
      if (row.osaMuId && row.osaWeekOfYear && row.osaWeekOfMonth) {
        history.push({
          pathname: '/performance/assessment/weeklyPlan',
          query: {
            muId: row.osaMuId,
            osaWeekOfYear: `${row.osaYear}${row.osaWeekOfYear}`,
            osaWeekOfMonth: row.osaWeekOfMonth,
            target: 'E',
          },
        });
      } else {
        message.error('当前数据有问题，请联系管理员');
        localStorage.setItem('errorData', JSON.stringify(row));
      }
    }
  };

  const handleAudit = async (row, query) => {
    let hasChange = await checkHasTodo(row);
    let next = true;
    if (!hasChange && !query) {
      next = await new Promise((resolve, reject) => {
        MessageBox.confirm({
          title: '确认操作',
          content: '确认开始变更申请吗？',
          onOk: () => {
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          },
        });
      });
    }
    if (!next) return;
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: { ...row, title: row.userInfo.value, key: row.osaMuId },
        },
      },
    });
    history.push({
      pathname: '/performance/selfosa/OSAEditAudit',
      query,
    });
  };

  /** 评分 */
  const toScoreSet = (row, path) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: row,
        },
      },
    });
    history.push({
      pathname: path,
      query: {},
    });
  };

  const checkHasTodo = async (row) => {
    let hasChange = false;
    if (
      [
        'SCORE_ONE_NODE',
        'SUMMER_WEEK_PLAN_ONE_NODE',
        'SCORE_WEEK_PLAN_ONE_NODE',
      ].includes(row.curnNodeNumber)
    ) {
      hasChange = await dispatch({
        type: 'performance/haveosachangeprocess',
        payload: {
          osaId: row.osaId,
          todoBusiId: '',
        },
      });
    }
    return hasChange;
  };

  const handleScore = async (row) => {
    let hasChange = await checkHasTodo(row);
    if (hasChange) {
      message.error('该绩效有正在进行的变更修改，无法执行当前操作！');
      return;
    }
    // if (moment() > moment(row.osaBeginTime)) {
    toScoreSet(row, '/performance/assessment/scoreSetting');
    // } else {
    //   message.error('暂未到评分时间！');
    // }
  };

  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      width: 120,
      fixed: 'left',
      render(userInfo) {
        return userInfo?.value;
      },
    },
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      width: 120,
      fixed: 'left',
      render(value) {
        const key = {
          1: '周计划',
          3: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaTime',
      title: '考核周期',
      width: 220,
      render(row) {
        const YMD = (time) => moment(time).format('YYYY-MM-DD');
        return [YMD(row.osaBeginTime), YMD(row.osaEndTime)].join(' ~ ');
      },
    },
    {
      dataIndex: 'busiLeader',
      title: '业务负责人',
      width: 120,
      render(busiLeader) {
        return busiLeader?.value;
      },
    },
    {
      dataIndex: 'directLeader',
      title: '主管(考核人)',
      width: 120,
      render(directLeader) {
        return directLeader?.value;
      },
    },
    {
      dataIndex: 'osaType',
      title: '考核类型',
      width: 120,
      render(val) {
        const key = {
          1: '试用期转正评估',
          2: '绩效评估',
          3: '绩效改进评估',
          4: '个人发展计划',
        };
        return key[val];
      },
    },
    {
      key: 'osaSumRemark',
      title: '评分',
      width: 180,
      render: (row) => {

        // row.osaBusiTotalScore = null;

        if(!row.osaDirectBonusItem) {
          row.osaDirectBonusItem = 0;
        }

        if(!row.osaDirectTotalScore) {
          row.osaDirectTotalScore = 0;
        }

        if(!row.osaBusiBonusItem) {
          row.osaBusiBonusItem = 0;
        }

        if(!row.osaBusiTotalScore) {
          row.osaBusiTotalScore = 0;
        }

        // row.osaBusiTotalScore = 100;

        if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
          return <div key={2}>上级评分：{(row.osaDirectTotalScore + row.osaDirectBonusItem) ?? '-'}</div>;
        }
        return [
          <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
          <div key={2}>上级评分：{(row.osaDirectTotalScore + row.osaDirectBonusItem) ?? '-'}</div>,
          <div key={3}>业务负责人：{row.osaBusiTotalScore+row.osaBusiBonusItem ?? '-'}</div>,
        ];
      },
    },
    {
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
      render(val, row) {
        return (
          <Tooltip
            title={renderProcessList(row.osaId, performance)}
            onVisibleChange={(visible) => getProcessList(row.osaId, visible)}
            placement="left"
            trigger="click"
            overlayStyle={{
              color: '#333',
              maxWidth: 600,
              fontSize: 12,
              lineHeight: '24px',
            }}
            color="#fff"
          >
            <Button type="text">{val}</Button>
          </Tooltip>
        );
      },
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'options',
      render: (row) => {
        return (
          <>
            {/* {row.hasbtnList.includes(btnKeys.add) && row.permType == 1 && ( */}
            {[
              'SCORE_ONE_NODE',
              'SUMMER_WEEK_PLAN_ONE_NODE',
              'SCORE_TWO_NODE',
            ].includes(row.curnNodeNumber) && (
              <Button type="text" onClick={() => handleScore(row)}>
                {row.curnNodeNumber == 'SUMMER_WEEK_PLAN_ONE_NODE'
                  ? '总结'
                  : '自评'}
              </Button>
            )}
            {/* {[
              'SCORE_ONE_NODE',
              'SUMMER_WEEK_PLAN_ONE_NODE',
              'SCORE_WEEK_PLAN_ONE_NODE',
            ].includes(row.curnNodeNumber) && (
              <Button type="text" onClick={() => handleAudit(row)}>
                变更
              </Button>
            )} */}
            <PreviewIndicators row={row} />
            <Button type="text" onClick={() => handleEdit(row, true)}>
              查看
            </Button>
            {getEditBtn(row) && (
              <Button type="text" onClick={() => handleEdit(row)}>
                编辑
              </Button>
            )}
            {(!row.curnNodeNumber ||
              [
                'WEEK_PLAN_BEGIN_NODE',
                'CREATE_WEEK_PLAN_NODE',
                'CONFIRM_WEEK_PLAN_NODE',
                'AUDIT_WEEK_PLAN_ONE_NODE',
                'OSA_BEGIN_NODE',
                'CREATE_OSA_NODE',
                'CONFIRM_OSA_NODE',
                'AUDIT_OSA_ONE_NODE',
              ].includes(row.curnNodeNumber)) && (
              <Button
                type="text"
                style={{ color: 'red' }}
                onClick={() => delItem({ osaId: row.osaId })}
              >
                删除
              </Button>
            )}
            {/* )} */}
            {/* {[
              'SCORE_ONE_NODE',
              'SUMMER_WEEK_PLAN_ONE_NODE',
              'SCORE_WEEK_PLAN_ONE_NODE',
            ].includes(row.curnNodeNumber) && (
              <Button
                type="text"
                onClick={() => handleAudit(row, { osaId: row.osaId })}
              >
                查看变更数据
              </Button>
            )} */}
          </>
        );
      },
    },
  ];

  const extra = (hasbtnList) => (
    <>
      <Button
        type="primary"
        key={'osa'}
        style={{ marginRight: 8 }}
        onClick={() => {
          if (!getHasBusi()) {
            return;
          }
          history.push({
            pathname: '/performance/assessment/OSA',
            query: {
              muId: JSON.parse(localStorage.getItem('employeeDTO')).empId,
            },
          });
        }}
      >
        添加OSA绩效
      </Button>
      <Button
        type="primary"
        key={btnKeys.import}
        style={{ marginRight: 8 }}
        onClick={() => {
          if (!getHasBusi()) {
            return;
          }
          history.push({
            pathname: '/performance/assessment/weeklyPlan',
            query: {
              muId: JSON.parse(localStorage.getItem('employeeDTO')).empId,
            },
          });
        }}
      >
        添加周计划
      </Button>
    </>
  );

  return (
    <div>
      <CollapseSearch onChange={tableRef.current?.reload} />
      <TablePro
        request="performance/getosamypage"
        rowKey={'osaId'}
        columns={columns}
        curColumns
        scrollX
        data={performance.osaselfData}
        extra={extra}
        actionRef={(ref) => (tableRef.current = ref)}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
      />
    </div>
  );
};

export default connect(({ employee_salary, performance }) => {
  return { employee_salary, performance };
})(Template);
