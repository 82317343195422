export const gettableCloumns = (data) => {
  let dataKeyList = {
    clockAbsenteenismStatData: '旷工',
    clockAskforLeaveStatData: '请假',
    clockForgetStatData: '忘打卡',
    clockHolidayStatData: '调休',
    clockLateBeginStatData: '迟到',
    clockLeaveEarlyStatData: '早退',
    clockNormalStatData: '正常打卡',
    clockOutStatData: '外出',
  };
  let splitDataKeys = {
    clockAbsenteeismEmpList: '旷工',
    overtimedayClockAbsenteeismAMEmpList: '加班日上午旷工',
    overtimedayClockAbsenteeismPMEmpList: '加班日下午旷工',
    workdayClockAbsenteeismAMEmpList: '工作日上午旷工',
    workdayClockAbsenteeismPMEmpList: '工作日下午旷工',

    clockAskforLeaveEmpList: '请假',
    overtimedayClockAskforLeaveAMEmpList: '加班日上午请假',
    overtimedayClockAskforLeavePMEmpList: '加班日下午请假',
    workdayClockAskforLeaveAMEmpList: '工作日上午请假',
    workdayClockAskforLeavePMEmpList: '工作日上午请假',

    clockForgetEmpList: '忘打卡',
    overtimedayClockForgetBeginEmpList: '加班日上班忘打卡',
    overtimedayClockForgetEndEmpList: '加班日下班忘打卡',
    workdayClockForgetBeginEmpList: '工作日上班忘打卡',
    workdayClockForgetEndEmpList: '工作日下班忘打卡',

    clockHolidayEmpList: '调休',
    overtimedayClockHolidayAMEmpList: '加班日上午调休',
    overtimedayClockHolidayPMEmpList: '加班日下午调休',
    workdayClockHolidayAMEmpList: '工作日上午调休',
    workdayClockHolidayPMEmpList: '工作日下午调休',

    clockLateEmpList: '上班迟到',
    overtimedayClockLateBeginEmpList: '加班日上班迟到',
    workdayClockLateBeginEmpList: '工作日上班迟到',

    clockLeaveEarlyEmpEndList: '下班早退',
    overtimedayClockLeaveEarlyEndEmpList: '加班日下班早退',
    workdayClockLeaveEarlyEndEmpList: '工作日下班早退',

    clockNormalEmpList: '打卡正常',
    clockNormalBeginEmpList: '上班打卡正常',
    clockNormalEndEmpList: '下班打卡正常',

    overtimedayClockOutErrorBeginEmpList: '加班日上班外出打卡异常',
    overtimedayClockOutErrorEndEmpList: '加班日下班外出打卡异常',
    workdayClockOutErrorBeginEmpList: '工作日上班外出打卡异常',
    workdayClockOutErrorEndEmpList: '工作日下班外出打卡异常',
    clockOutEmpList: '外出打卡',
  };

  const getValue = (arr) => {
    if (!Array.isArray(arr)) return null;
    return arr.map((item) => item.value).join('、');
  };

  const handleDataLevel1 = (data) => {
    return Object.keys(data).map((key) => {
      if (typeof data[key] == 'object' && dataKeyList.hasOwnProperty(key)) {
        return handleDataLevel2(data[key], dataKeyList[key]);
      }
    });
  };

  const handleDataLevel2 = (data, label) => {
    return Object.keys(data).map((key) => {
      if (typeof data[key] == 'object' && splitDataKeys.hasOwnProperty(key)) {
        let type = splitDataKeys[key];
        let value = data[key];
        return { label, type, value };
      }
    });
  };

  return handleDataLevel1(data).flat(2).filter(Boolean);
};
