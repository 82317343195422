import Charts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import * as echarts from 'echarts';

const defaultData = (item) => {
  return [
    {
      name: '滞后',
      value: item.projectDelaylNum,
      percent: item.projectDelayPercent,
    },
    {
      name: '超前',
      value: item.projectLeadlNum,
      percent: item.projectLeadPercent,
    },
    {
      name: '正常',
      value: item.projectNormalNum,
      percent: item.projectNormalPercent,
    },
  ];
};

const PieShade = (props) => {
  const { data, legend = {} } = props;
  const [chartsData, setChartsData] = useState([]);
  const center = ['22%', '50%'];
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setChartsData(defaultData(data));
    }
  }, [data]);

  let options = {
    backgroundColor: 'transparent',
    title: [
      {
        text: `{a|${data?.projectTotalNum || 0}\n项目（个）}`,
        top: 'center',
        left: center[0],
        itemGap: -10, //主副标题间距
        textAlign: 'center',
        textStyle: {
          fontWeight: 'normal',
          rich: {
            a: {
              padding: [0, 0, 8, 0],
              fontWeight: 'normal',
              fontSize: 14,
              color: '#000',
              textShadow: [5, 5, 10, '#7ff06fff'],
            },
            b: {
              fontWeight: 'normal',
              fontSize: 20,
              padding: [0, 0, 5, 3],
              color: '#646C73',
              textShadowBlur: 5,
            },
          },
        },
      },
    ],
    grid: {
      bottom: 0,
      left: 0,
    },
    tooltip: {
      formatter: (param) => {
        return `${param.marker} ${param.name} ${+param.percent || 0}% ${+param.value || 0
          }个`;
      },
    },
    legend: {
      right: '20%',
      top: 'center',
      orient: 'vertical',
      data: chartsData.map((item) => item.name),
      formatter: (param) => {
        let obj = chartsData.find((item) => item.name == param);
        return `${obj.name} ${+obj.percent || 0}% ${+obj.value || 0}个`;
      },
      ...legend,
    },
    series: [
      // 主要展示层的
      {
        radius: ['65%', '80%'],
        center,
        type: 'pie',
        // hoverAnimation: false,
        itemStyle: {
          normal: {
            // color: function (params) {
            //   return {
            //     type: 'line',
            //     x: 1,
            //     y: 0,
            //     x2: 0,
            //     y2: 0,
            //     colorStops: [
            //       { offset: 0, color: color1[params.dataIndex][0] },
            //       { offset: 1, color: color1[params.dataIndex][1] },
            //     ],
            //   };
            // },
            shadowBlur: 22,
            shadowColor: 'rgba(14,73,206,0.23)',
            // borderColor: '#f5f5f5',
          },
        },
        label: {
          show: false,
        },
        data: chartsData,
      },
      // 外边设置
      {
        radius: ['0%', '55%'],
        center,
        type: 'pie',
        hoverAnimation: false,
        zlevel: 99,
        label: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
          },
        },
        labelLine: {
          normal: {
            show: true,
          },
          emphasis: {
            show: false,
          },
        },
        animation: false,
        tooltip: {
          show: false,
        },
        itemStyle: {
          normal: {
            color: 'rgba(206,213,225,0.01)',
          },
        },
        data: [1],
      },
      {
        type: 'pie',
        z: -2000,
        radius: [0, '50%'],
        center,
        itemStyle: {
          borderWidth: 0,
          shadowBlur: 8,
          shadowColor: '#cccccc',
        },
        data: [
          {
            value: 100,
            itemStyle: {
              color: '#fff',
            },
          },
        ],
      },
    ],
  };

  let size = 200;

  return (
    <div className="shadow-charts">
      <Charts
        notMerge={false}
        option={options}
        lazyUpdate={true}
        style={{ height: size, width: size + 210, ...(props.style || {}) }}
      />
    </div>
  );
};

export default PieShade;
