import React, { useEffect, useMemo, useRef } from 'react';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { exportprojectrptlist } from '@/_serveice/project';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { Button } from 'kenshin';

const getTreeData = (list) => {
  return list.map((item) => {
    let children;
    if (item.child) {
      children = getTreeData(item.child);
    }
    return {
      title: item.deptName,
      value: item.deptId,
      children,
    };
  });
};

const RptInfo = ({ project, systemOrgani, dispatch }) => {
  useEffect(() => {
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
  }, []);

  const departmentList = useMemo(
    () => getTreeData([systemOrgani.departmentList]),
    [systemOrgani.departmentList],
  );

  const rptAuditResultStatus = {
    1: <div className="status-green animate">通过</div>,
    2: <div className="status-red">驳回</div>,
  };

  const columns = [
    {
      dataIndex: 'empName',
      title: '员工姓名',
    },
    {
      dataIndex: 'deptList',
      title: '部门',
      render: (val) => val?.map((item, i) => <div key={i}>{item}</div>),
    },
    {
      dataIndex: 'projectName',
      title: '项目名称',
    },
    {
      dataIndex: 'servCatgName',
      title: '工作包',
    },
    {
      dataIndex: 'taskName',
      title: '任务',
    },
    {
      dataIndex: 'rptDate',
      title: '上报日期',
      // render:val => moment(val).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      dataIndex: 'rptRemark',
      title: '上报备注',
    },
    {
      dataIndex: 'rptWorkHours',
      title: '上报的标准工时',
    },
    {
      dataIndex: 'rptWorkOvertimeHours',
      title: '上报的加班工时',
    },
    {
      dataIndex: 'rptWorkTotalHours',
      title: '上报的总工时',
    },
    {
      dataIndex: 'rptAuditResult',
      title: '上报审核结果',
      render: (val) => rptAuditResultStatus[val],
    },
  ];

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '员工/工作包',
      },
    },
    {
      label: '部门',
      name: 'deptIdList',
      type: 'TreeSelect',
      props: {
        placeholder: '部门',
        multiple: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
        treeDefaultExpandAll: true,
        maxTagCount: 'responsive',
        dropdownStyle: { maxHeight: 600, overflow: 'auto' },
        treeData: departmentList,
      },
    },
  ];

  let tableRef = useRef();
  const extra = () => (
    <Button
      type="primary"
      onClick={() => {
        let value = tableRef.value;
        let payload = {
          projectId: history.location.query?.projectId ?? '',
          deptIdList: value.deptIdList ?? '',
          key: value.key ?? '',
        };
        let url = `${baseURL}${exportprojectrptlist}${splitParamsFoGet(
          payload,
        )}`;
        downloadXlsx(url, '项目上报明细');
      }}
    >
      导出
    </Button>
  );

  return (
    <div>
      <TablePro
        params={{ projectId: history.location.query?.projectId }}
        request="project/projectDetailLog"
        rowKey="rptDetailId"
        columns={columns}
        extra={extra}
        columnsOptions={false}
        actionRef={(ref) => (tableRef = ref)}
        searchCloumns={searchCloumns}
        curColumns
        data={project.projectDetailLogList}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani }) => {
  return {
    project,
    systemOrgani,
  };
})(RptInfo);
