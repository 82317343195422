import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, message, MessageBox, Row, Tag } from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import TreeData from './components/TreeData';
import { useCreation } from 'ahooks';
import BatchValue from './components/batchValue';

const disabledDel = ['USER:WHITE:LIST'];

const dictPage = ({ dispatch, loading, system, user }) => {
  let tableRef = useRef();
  let modalRef = useRef();
  // 字典类型key
  let updataKey = useRef('CONTRACTTYPE');
  // 当前字典类型是否含有子字典类型
  let typeHasChild = useRef(false);

  // 菜单操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const [dicType, setDicType] = useState(() => {
    return {
      dicName: '',
      dicTypeId: system.dictypeList?.child?.[0].dicTypeId,
    };
  });

  useEffect(() => {
    if (system.dictypeList?.child?.[0].dicTypeId) {
      treeHandleChange(
        [system.dictypeList?.child?.[0].dicTypeId],
        system.dictypeList?.child?.[0],
      );
    }
  }, [system.dictypeList]);

  const showEditModal = (row) => {
    setEditModalVisible(true);
    setEditModalData(row);
    if (!row) {
      modalRef.current.form.setFieldsValue({
        dicSort: system.dicList[system.dicList.length - 1]?.dicSort + 1,
      });
    }
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'system/deleteDictionary',
          updataKey: updataKey.current,
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const editModalSave = (value) => {
    let payload = {
      dicTypeId: dicType?.dicTypeId,
      ...value,
    };
    let hasEdit = false;
    if (editModalData?.dicId) {
      hasEdit = true;
      payload.dicTypeId = editModalData?.dicTypeId;
      payload.dicId = editModalData?.dicId;
    }
    // if(Array.isArray(payload.dicValue)){
    //   payload.dicValue = payload.dicValue.filter(Boolean).join(",")
    // }
    console.log(JSON.parse(JSON.stringify(payload)));
    if (typeof payload.dicValue == 'object' && payload.dicValue.ips) {
      payload.dicRemark = payload.dicValue.remark;
      payload.dicValue = payload.dicValue.ips;
    }
    console.log('payload', payload);
    dispatch({
      type: 'system/editDictionary',
      hasEdit,
      updataKey: updataKey.current,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const columns = useCreation(() => [
    {
      title: '#',
      width: 50,
      key: 'index',
      render: (_, _value, index) => index + 1,
    },
    {
      title: 'key',
      width: 150,
      dataIndex: 'dicKey',
      key: 'dicKey',
    },
    // {
    //   title: '字典类型',
    //   dataIndex: 'dt_id',
    //   key: 'dt_id',
    // },
    {
      title: '排序',
      width: 100,
      dataIndex: 'dicSort',
      textWrap: 'word-break',
      key: 'dicSort',
    },
    {
      title: '字典值',
      width: 350,
      dataIndex: 'dicValue',
      textWrap: 'word-break',
      key: 'dicValue',
    },
    {
      title: '备注',
      width: 350,
      dataIndex: 'dicRemark',
      textWrap: 'word-break',
      key: 'dicRemark',
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'options',
      render: (row) => {
        return (
          <>
            {row.hasbtnList.includes(btnKeys.edit) && (
              <Button type="text" onClick={() => showEditModal(row)}>
                编辑
              </Button>
            )}
            {row.hasbtnList.includes(btnKeys.delete) &&
              !disabledDel.includes(row.dicKey) && (
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delRole({ dicId: row.dicId })}
                >
                  删除
                </Button>
              )}
          </>
        );
      },
    },
  ]);

  const searchCloumns = [
    {
      name: 'dicName',
      type: 'Input',
      props: {
        placeholder: '字典值',
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '排序',
      name: 'dicSort',
      type: 'InputNumber',
      required: '请输入排序',
      props: {
        placeholder: '请输入排序',
        stepType: 'inside',
        min: 0,
      },
    },
    {
      label: '字典key值',
      name: 'dicKey',
      type: 'Input',
      required: '请输入key',
      props: {
        disabled: editModalData?.dicId,
        placeholder: '请输入key',
      },
    },
    {
      label: '字典value值',
      name: 'dicValue',
      type: 'Input',
      required: '请输入value',
      props: {
        placeholder: '请输入value',
      },
      // render:disabledDel.includes(editModalData?.dicKey) ? () => <BatchValue value={editModalData?.dicValue} /> : null
      render: disabledDel.includes(editModalData?.dicKey)
        ? () => (
            <BatchValue
              value={editModalData?.dicValue}
              remark={editModalData?.dicRemark}
            />
          )
        : null,
    },
    !disabledDel.includes(editModalData?.dicKey)
      ? {
          label: '备注',
          name: 'dicRemark',
          type: 'InputTextArea',
          props: {
            placeholder: '请输入备注',
          },
        }
      : null,
  ].filter(Boolean);

  const extra = (
    <Button
      type="primary"
      key={btnKeys.add}
      disabled={typeHasChild.current}
      onClick={() => showEditModal()}
    >
      新增字典
    </Button>
  );

  const treeHandleChange = (keys, row) => {
    const child = row.child || row.node?.child;
    typeHasChild.current = !!+child?.length;
    if (row) {
      updataKey.current = row.node?.dicTypeKey || row.dicTypeKey;
    }
    if (keys.length > 0) {
      setDicType((val) => {
        val.dicTypeId = keys[0];
        return { ...val };
      });
      tableRef.reload({ dicTypeId: keys[0] });
    }
  };

  const tableSubmit = (val) => {
    val.dicName = val.dicName ?? '';
    if (!val.dicTypeId) {
      return false;
    }
    if (dicType?.dicTypeId == '') {
      return false;
    }
  };

  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData
            onChange={treeHandleChange}
            defaultSelect={dicType?.dicTypeId}
          />
        </Col>
        <Col span={16}>
          <TablePro
            params={dicType}
            request={
              system.dictypeList?.child?.[0].dicTypeId
                ? 'system/getDictionaryList'
                : ''
            }
            version="system"
            rowKey={'dicId'}
            scrollX
            columns={columns}
            searchCloumns={searchCloumns}
            dataSource={system.dicList}
            pagination={false}
            extra={extra}
            onSubmit={tableSubmit}
            actionRef={(ref) => (tableRef = ref)}
          />
        </Col>
      </Row>

      <ModalFormPro
        title={editModalData?.dicId ? '编辑' : '新增'}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        ref={modalRef}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </>
  );
};

export default connect(({ system, loading, user }) => {
  return {
    user,
    system,
    loading: loading.effects,
  };
})(dictPage);
