import { useEffect, useState, useRef } from 'react';
import {
  PlusOutlined,
  EditOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { Col, DatePicker, Loading, Row, message, Typography } from 'kenshin';
import { history, connect } from 'umi';

const CardBox = ({
  muId,
  dispatch,
  performance,
  loadingEf,
  dispatchType = 'performance/getosatemplatelist',
}) => {
  const [quarterTime, setQuarterTime] = useState(moment());
  const querterRef = useRef(`${moment().year()}${moment().quarter()}`);
  useEffect(() => {
    dispatch({
      type: 'performance/getosatemplatelist',
    });
    dispatch({
      type: 'performance/getcommonosatemplatelist',
    });
  }, []);

  const toCteateTemplate = (templateData) => {
    if (!muId) {
      message.error('请选择考核成员');
      return;
    }
    if (templateData) {
      // dispatch({
      //   type: 'performance/save',
      //   payload: {
      //     templateEditor: templateData,
      //   },
      // });
    }
    history.push({
      pathname: '/performance/assessment/OSA/OSAsetting',
      // search: `?deptId=${deptId}`,
      query: {
        muId,
        osaTemplateId: templateData?.osaTemplateId,
        osaYearQuarter: querterRef.current,
      },
    });
  };

  const quarterChange = (date) => {
    if (!date) {
      querterRef.current = ``;
      setQuarterTime('');
      return;
    }
    console.log('quarterChange', date);
    let year = date.year();
    let quarter = date.quarter();
    // let startTime = date.format('YYYY-MM-DD');
    // let endTime = date.endOf('quarter').format('YYYY-MM-DD');
    // console.log({ year, quarter, startTime, endTime });
    querterRef.current = `${year}${quarter}`;
    setQuarterTime(date);
  };

  const getCard = (list, showAdd = false) => {
    return (
      <Row className="card-box" gutter="20">
        {showAdd && (
          <Col span={5}>
            <div
              className="template-item empty"
              onClick={() => toCteateTemplate()}
            >
              <div className="template-item-icon">
                <PlusOutlined />
              </div>
              <div className="template-item-title">创建考核方案</div>
            </div>
          </Col>
        )}
        {list?.map((item) => (
          <Col span={5} key={item.osaTemplateId}>
            <div
              className="template-item"
              onClick={(e) => toCteateTemplate(item)}
            >
              <div className="template-item-icon">
                <CheckCircleTwoTone twoToneColor="#11aa66" />
              </div>
              <div className="template-item-title">{item.osaTemplateName}</div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Loading loading={loadingEf['performance/getosatemplatelist']}>
      <DatePicker
        value={quarterTime}
        style={{ marginBottom: 20 }}
        onChange={quarterChange}
        picker="quarter"
      />
      {getCard(performance.templateList, true)}
      <Typography.Title level={3}>公共模板</Typography.Title>
      {getCard(performance.publicTemplateList)}
    </Loading>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(CardBox);
