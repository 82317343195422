import { Avatar, Button } from 'kenshin';
import styles from './index.less';
import { FolderFilled } from '@ant-design/icons';
import TablePro from '@/components/TablePro';
import { useSelector, useDispatch, history } from 'umi';
import WdpAvatar from '@/components/Avatar';
import { useEffect, useState } from 'react';
import EditHoliday from './components/EditHoliday';
import HolidayChangeLog from './components/HolidayChangeLog';
import DetailListModal from './components/DetailListModal';

const Detail = ({}) => {
  const dispatch = useDispatch();
  const attendance = useSelector(({ attendance }) => attendance);
  let { holidayDetail } = attendance;

  const [editVisible, setEditVisible] = useState(false);
  const [rowData, setRowData] = useState(null);

  const [changeVisible, setChangeVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);

  /** 修改假期 */
  const showEditHoliday = (row) => {
    setEditVisible(true);
    setRowData({ ...holidayDetail, ...history.location.query, ...row });
  };
  /** 修改假期 */
  const showChangeLog = (row) => {
    setChangeVisible(true);
    setRowData({ ...holidayDetail, ...history.location.query, ...row });
  };
  /** 修改假期 */
  const showDetailLog = (row) => {
    setDetailVisible(true);
    setRowData({ ...holidayDetail, ...history.location.query, ...row });
  };

  useEffect(() => {
    // if(!holidayDetail?.empId){
    //   history.go(-1)
    // }
    return () => {
      if (holidayDetail) {
        dispatch({
          type: 'attendance/save',
          payload: {
            holidayDetail: {},
          },
        });
      }
    };
  }, []);

  const columns = [
    {
      dataIndex: 'vacationName',
      title: '类型',
      width: '100px',
      fixed: 'left',
    },
    {
      dataIndex: 'vacationTotalSendDays',
      title: '总发放时长',
      // render: (val) => val ?? '-',
      render: (val, row) => {
        return row.vacationName == '年假' ? (
          val ?? '-'
        ) : (
          <div>
            上半天 : {row.vacationTotalSendUpDays}
            <br />
            下半天 : {row.vacationTotalSendDownDays}
          </div>
        );
      },
      width: '150px',
    },
    {
      dataIndex: 'vacationTotalUsedDays',
      title: '总使用时长',
      render: (val) => val ?? '-',
      width: '150px',
    },
    {
      dataIndex: 'vacationAnnualSendDays',
      title: '本年度发放时长',
      render: (val) => val ?? '-',
      width: '150px',
    },
    {
      dataIndex: 'vacationAnnualUsedDays',
      title: '本年度使用时长',
      render: (val) => val ?? '-',
      width: '150px',
    },
    {
      dataIndex: 'vacationRemainEnableDays',
      title: '剩余时长',
      width: '200px',
      render: (val, row) => {
        let txtArr = [
          row.vacationLastYearEnableDays
            ? `${row.vacationLastYear}：${row.vacationLastYearEnableDays}`
            : null,
          row.vacationThisYearEnableDays
            ? `${row.vacationThisYear}：${row.vacationThisYearEnableDays}`
            : null,
        ].filter(Boolean);
        let yearTxt = txtArr.length > 0 ? `(${txtArr.join(',')})` : '';
        if (val) {
          return (
            <div style={{ textAlign: 'center' }}>
              {val}
              <br />
              {yearTxt}
            </div>
          );
        }
        return 0;
      },
    },
    {
      dataIndex: 'vacationRemainEnableLockDays',
      title: '锁定时长',
      width: '150px',
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'vacationRemainEnableExpireDays',
      title: '过期时长',
      width: '150px',
      render: (val) => val ?? '-',
    },
    {
      key: 'options',
      title: '操作',
      width: '150px',
      fixed: 'right',
      render: (row) => {
        return (
          <div>
            <Button type="text" onClick={() => showEditHoliday(row)}>
              修改假期
            </Button>
            <Button type="text" onClick={() => showChangeLog(row)}>
              查看记录
            </Button>
            <Button type="text" onClick={() => showDetailLog(row)}>
              可用列表
            </Button>
          </div>
        );
      },
    },
  ];

  const editHolidaySave = (payload) => {
    dispatch({
      type: 'attendance/updateempvacationremaindays',
      payload,
    }).then((success) => {
      if (success) {
        setEditVisible(false);
        setRowData(null);
      }
    });
  };

  return (
    <div className={styles.vacationbalance}>
      <div className="avatar-header">
        <WdpAvatar
          size={100}
          className="avatar-img"
          url={holidayDetail.empHeadImg}
        >
          {holidayDetail?.empName ?? '-'}
        </WdpAvatar>
        <div className="avatar-content">
          <div className="avatar-name">{holidayDetail?.empName}</div>
          <div className="avatar-dept">
            <FolderFilled />
            {holidayDetail.deptName}
          </div>
        </div>
      </div>
      <TablePro
        params={history.location.query}
        request="attendance/getemployeevacationdetaillist"
        rowKey={'vacationId'}
        dataSource={attendance.vacationDetailList}
        pagination={false}
        columns={columns}
        columnsOptions={false}
        scrollX
        curColumns
      ></TablePro>
      <EditHoliday
        data={rowData}
        visible={editVisible}
        onSave={editHolidaySave}
        onCancel={() => {
          setEditVisible(false);
          setRowData(null);
        }}
      />
      <HolidayChangeLog
        visible={changeVisible}
        data={rowData}
        onCancel={() => {
          setChangeVisible(false);
          setRowData(null);
        }}
      />
      <DetailListModal
        visible={detailVisible}
        data={rowData}
        onCancel={() => {
          setDetailVisible(false);
          setRowData(null);
        }}
      />
    </div>
  );
};

export default Detail;
