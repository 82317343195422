import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Button,
  Col,
  message,
  MessageBox,
  Row,
  Avatar,
  Collapse,
  Input,
  Radio,
  DatePicker,
  Tooltip,
  Timeline,
} from 'kenshin';
import { connect, history } from 'umi';
import TreeData from './components/TreeData';
import TablePro from '@/components/TablePro';
import renderProcessList from '../components/ProcessList';
import PreviewIndicators from '../components/PreviewIndicators';
import AlertToOsa from '../components/AlertToOSA';
import CollapseSearch from '../components/CollapseSearch';

const OSAWEEKOSATYPE = {
  WEEK: 1,
  OSA: 3,
};

let searchTimer = null;
let roleList = JSON.parse(localStorage.getItem('employeeDTO'))?.roleList?.map(
  (item) => item.extendData,
);

const Template = ({ dispatch, performance }) => {
  const [treeKey, setTreeKay] = useState('');

  /** 表格ref */
  const tableRef = useRef();
  // 树组件
  const treeHandleChange = (keys) => {
    setTreeKay(keys[0]);
  };

  useEffect(() => {
    if (treeKey) {
      tableRef.current.reload({ deptId: treeKey });
      dispatch({
        type: 'performance/getosaempstatdata',
        payload: {
          deptId: treeKey,
        },
      });
    }
  }, [treeKey]);

  /** 获取当前流程数据 */
  const getProcessList = (osaId, visible) => {
    if (visible) {
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId,
          todoBusiId: '',
        },
      });
    }
  };

  /** 详情页 */
  const toDetail = (row) => {
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: { ...row, title: row.userInfo.value, key: row.osaMuId },
        },
      },
    });
    history.push({
      pathname: '/performance/assessment/OSA/OSADetail',
    });
  };

  /** 表格表头配置 */
  const columns = [
    {
      dataIndex: 'userInfo',
      title: '被考核人',
      fixed: 'left',
      width: 120,
      render(userInfo) {
        return userInfo?.value;
      },
    },
    {
      dataIndex: 'osaWeekType',
      title: '信息类别',
      fixed: 'left',
      width: 120,
      render(value) {
        const key = {
          [OSAWEEKOSATYPE.WEEK]: '周计划',
          [OSAWEEKOSATYPE.OSA]: 'OSA季度绩效',
        };
        return key[value];
      },
    },
    {
      dataIndex: 'osaName',
      title: '绩效名称',
      width: 150,
    },
    {
      key: 'osaTime',
      title: '考核周期',
      width: 220,
      render(row) {
        const YMD = (time) => moment(time).format('YYYY-MM-DD');
        return [YMD(row.osaBeginTime), YMD(row.osaEndTime)].join(' ~ ');
      },
    },
    {
      dataIndex: 'busiLeader',
      title: '业务负责人',
      width: 120,
      render(busiLeader) {
        return busiLeader?.value;
      },
    },
    {
      dataIndex: 'directLeader',
      title: '主管(考核人)',
      width: 120,
      render(directLeader) {
        return directLeader?.value;
      },
    },
    {
      dataIndex: 'osaType',
      title: '考核类型',
      width: 120,
      render(val) {
        const key = {
          1: '试用期转正评估',
          2: '绩效评估',
          3: '绩效改进评估',
          4: '个人发展计划',
        };
        return key[val];
      },
    },
    {
      key: 'osaSumRemark',
      title: '评分',
      width: 180,
      render: (row) => {
        if (row.osaWeekType == OSAWEEKOSATYPE.WEEK) {
          return <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>;
        }
        return [
          <div key={1}>自评：{row.osaSelfTotalScore ?? '-'}</div>,
          <div key={2}>上级评分：{row.osaDirectTotalScore ?? '-'}</div>,
          <div key={3}>业务负责人：{row.osaBusiTotalScore ?? '-'}</div>,
        ];
      },
    },
    {
      dataIndex: 'osaGrade',
      title: '评级',
      width: 120,
      render: (val) => val ?? '-',
    },
    {
      dataIndex: 'curnNode',
      title: '当前环节',
      width: 180,
      render(val, row) {
        return (
          <Tooltip
            title={renderProcessList(row.osaId, performance)}
            onVisibleChange={(visible) => getProcessList(row.osaId, visible)}
            placement="left"
            trigger="click"
            overlayStyle={{
              color: '#333',
              maxWidth: 600,
              fontSize: 12,
              lineHeight: '24px',
            }}
            color="#fff"
          >
            <Button type="text">{val}</Button>
          </Tooltip>
        );
      },
    },
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      key: 'options',
      render: (row) => {
        return (
          <>
            <PreviewIndicators row={row} />
            <Button type="text" onClick={() => toDetail(row)}>
              查看
            </Button>
            {/* {[
              'SCORE_ONE_NODE',
              'SUMMER_WEEK_PLAN_ONE_NODE',
              'SCORE_WEEK_PLAN_ONE_NODE',
            ].includes(row.curnNodeNumber) && (
              <Button
                type="text"
                onClick={() => {
                  history.push({
                    pathname: '/performance/selfosa/OSAEditAudit',
                    query: { osaId: row.osaId },
                  });
                }}
              >
                查看变更数据
              </Button>
            )} */}
          </>
        );
      },
    },
  ];

  const handleSubmit = (payload) => {
    if (!payload.deptId) {
      return false;
    }
  };

  const searchData = useRef({
    deptId: treeKey,
  });
  const CollapseSearchChange = (data = {}) => {
    Object.assign(searchData.current, data);
    tableRef.current?.reload(data);
  };
  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        <Col span={4}>
          <TreeData
            onChange={treeHandleChange}
            // defaultId={roleList.includes('PERSONNEL') ? '1' : undefined}
            defaultId="1"
          />
        </Col>
        <Col span={16}>
          {/* {SearchRender()} */}
          <CollapseSearch onChange={CollapseSearchChange} />
          <AlertToOsa payload={{ ...searchData.current, deptId: treeKey }} />
          <TablePro
            params={{ deptId: treeKey }}
            request="performance/getallosaemployeepage"
            rowKey={'osaId'}
            onSubmit={handleSubmit}
            columns={columns}
            curColumns
            scrollX
            data={performance.osaSubAssessment}
            actionRef={(ref) => (tableRef.current = ref)}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(({ employee_salary, performance }) => {
  return { employee_salary, performance };
})(Template);
