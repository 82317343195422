import HolidayOptions from './components/HolidayOptions';
import { deepClone, debounce } from '@/_util/util';
import {
  isTimeArrOverlap,
  divideTimeIntoHalfDays,
  getattendanceupdowndays,
  getattendancehourtime,
} from './_util';
import {
  getempvacationenabledays,
  validateattendanceapplytime,
} from '../../../_serveice/attendance';
import { timeDiff } from '@/_util';
import { message, Col } from 'kenshin';
import { history } from 'umi';

/** 编辑的时候补回时间 */
const fillEnableDays = (detailData, list) => {
  let numObj = detailData.vacationList.reduce((obj, item) => {
    // 将原来的请假天数变成对象，每一项是 {[id]:num}
    obj[item.applyType.split('-')[0]] = +(
      timeDiff(item.applyTime) / 7.5
    ).toFixed(2);
    return obj;
  }, {});
  list.flat(2).map((item) => {
    // 将之前的天数补回去
    if (numObj[item.vacationId]) {
      item.vacationEnableDays += numObj[item.vacationId];
    }
    return item;
  });
};

/** 校验申请时间 */
const validatApplytime = async (ref, values, attendanceApplyId = undefined) => {
  console.log('校验申请时间', values);
  let empIdListParams = ref?.form.getFieldValue('partinList');
  if (empIdListParams?.length == 0) {
    empIdListParams = [JSON.parse(localStorage.getItem('employeeDTO')).empId];
  }
  if (values) {
    for (let i = 0; i < values.length; i++) {
      // 使用for循环替代map，因为map返回的是一个数组而不是Promise
      const item = values[i];
      if (!item.applyTime) {
        continue; // 跳过没有applyTime的项
      }
      console.log('校验申请时间...,map1', item);
      //除了哺乳假，其他假期都要校验是否为可申请状态
      if (item.applyType?.split('-')[0] != '10') {
        let payload = {
          applyBusiType: 15,
          vacationCode: item.applyType?.split('-')[1],
          empIdList: empIdListParams,
          applyBeginTime: moment(item.applyTime[0]).format(
            'YYYY-MM-DD HH:mm:ss',
          ),
          applyEndTime: moment(item.applyTime[1]).format('YYYY-MM-DD HH:mm:ss'),
          attendanceApplyId: history.location?.query?.id,
        };
        if (attendanceApplyId) {
          payload.attendanceApplyId = attendanceApplyId;
        }
        let res = await validateattendanceapplytime(payload).then((res) => {
          return Object.values(res.data || {}).some((item) => !item);
        });
        console.log('校验申请时间...,map', res);
        if (res) {
          return res;
        }
      }
    }
  }
  // 所有检查通过后返回
  return false;
};

/** 假期类型的时间校验 */
const dateValidator = async ({
  value,
  list,
  empIdList,
  vacationIdList,
  isEndDisabled,
  detailData,
}) => {
  console.log('value...', value);

  let numList = [];
  if (vacationIdList?.length > 0) {
    numList = Object.values(
      (await getempvacationenabledays({
        empIdList: [JSON.parse(localStorage.getItem('employeeDTO')).empId],
        vacationIdList,
      }).then((res) => {
        console.log('numList...1111', res.data);
        return res.data;
      })) || {},
    );
    console.log('numList...', numList);
    if (history.location.query.id) {
      // fillEnableDays(detailData, numList);
    }
  }
  let numObj = numList?.flat(2).reduce((obj, item) => {
    obj[`${item.employeeId}-${item.vacationId}`] = item.vacationEnableDays;
    return obj;
  }, {});
  for (let i = 0; i < value?.length; i++) {
    let row = value[i];
    // let multipNum = value.filter(item => item.applyType == row.applyType)?.length||0
    if (row?.applyType && row.applyTime) {
      let curData = list.find((item) => item.value == row.applyType);
      let vacationLimit = curData?.vacationLimit;
      if (vacationLimit == 1) {
        let vacationId = row.applyType.split('-')[0];

        // let diffTimeDay = +(timeDiff(row.applyTime) / 7.5).toFixed(2);
        let diffTimeDay = value
          .filter((item) => item.applyType == row.applyType)
          // .map((item) => +(timeDiff(item.applyTime) / 7.5).toFixed(2))
          .map((item) => +(item.applyTimeHour / 7.5).toFixed(2))
          .reduce((count, num) => count + num, 0);

        let resNumArr = Object.keys(numObj)
          .filter((item) => item.split('-')[1] == vacationId)
          .map((key) => numObj[key]);

        console.log(
          'diffTimeDay..',
          diffTimeDay,
          '.resNum',
          resNumArr,
          'numList...',
          numList,
        );

        if (
          !isEndDisabled &&
          resNumArr.some((resNum) => diffTimeDay > resNum)
        ) {
          message.error(
            `当前假期额度不足`,
            // ${curData.label}最多剩余：${Math.min(
            //   ...resNumArr,
            // )}天`,
          );
          return true;
        }
      }
    }
  }
  return false;
};

export const getEditColumns = ({
  getOptions,
  typeTitle,
  attendance,
  state,
  disabled = false,
  disabledWithoutTime = false,
  employeeList,
  employeeListAll,
  typeSelectChange,
  isEndDisabled = false, // 人事编辑，只允许编辑部分数据
  holidayDisabledTime,
  applyTimeDisabled,
  getOldApplyTime,
  isdateValidator = true,
  disabledDate,
  disabledTime,
  clockApplyTypeChange,
  putOffEmpList,
  outDateDisabled,
  partinChange,
  detailData,
  ref,
  attendanceApplyId,
}) => {
  console.log(
    'attendance.applyTypeList',
    attendance.applyTypeList.filter((child) => child.id != '16'),
  );
  const edtaFormItem = [
    {
      title: '申请类型',
      dataIndex: 'applyBusiType',
      valueType: 'Select',
      required: '请选择申请类型',
      fieldProps: {
        options: getOptions(
          attendance.applyTypeList.filter((child) => child.id != '16'),
          state,
        ),
        placeholder: '请选择申请类型',
        onChange: typeSelectChange,
        disabled: disabled || isEndDisabled || disabledWithoutTime,
      },
    },
    ['15'].includes(state.type) && {
      title: '假期类型',
      dataIndex: 'vacationList',
      required: '请选择假期类型',
      render: () => (
        <HolidayOptions
          list={state.list}
          disabled={disabled}
          isEndDisabled={isEndDisabled}
          disabledWithoutTime={disabledWithoutTime}
          disabledTime={holidayDisabledTime}
        />
      ),
      formItemProps: {
        rules: [
          {
            required: true,
            message: '请选择假期类型',
          },
          () => ({
            async validator(_, value) {
              console.log(
                'partinList...',
                ref?.form.getFieldValue('partinList'),
              );

              let empIdList = ref?.form.getFieldValue('partinList') || [
                JSON.parse(localStorage.getItem('employeeDTO')).empId,
              ];
              let vacationIdList = value
                .filter((item) => item?.applyType && item.applyTime)
                .map((item) => item.applyType?.split('-')?.[0])
                .filter(Boolean);
              if (isdateValidator) {
                let isExceed = await dateValidator({
                  value,
                  list: state.list,
                  empIdList,
                  vacationIdList,
                  isEndDisabled,
                  detailData,
                });
                if (isExceed)
                  return Promise.reject(new Error('当前假期额度不足'));
              }

              //调取接口校验日期是否为可申请
              let res = await validatApplytime(ref, value, attendanceApplyId);
              console.log('校验申请时间...,res', res);
              if (res) return Promise.reject(new Error('当前时间存在冲突'));

              return Promise.resolve();
            },
          }),
        ],
      },
    },
    ['15'].includes(state.type) && {
      title: '是否需要提交证明材料',
      dataIndex: 'vacationApplyNeedEvidence',
      valueType: 'RadioGroup',
      required: '请选择',
      fieldProps: {
        disabled: disabled || isEndDisabled,
        options: [
          { label: '是', value: 1, remark: '' },
          { label: '否', value: 0, remark: '' },
        ],
      },
    },
    ['10', '17'].includes(state.type) && {
      title: '加班类型',
      dataIndex: 'applyOvertimeType',
      valueType: 'RadioGroup',
      required: '请选择加班类型',
      fieldProps: {
        disabled: disabled || isEndDisabled,
        options: [
          { label: '平常', value: 1, remark: '' },
          { label: '公休日', value: 2, remark: '' },
          { label: '法定节日', value: 3, remark: '' },
        ],
        onChange: (value) => {
          state.applyOvertimeType = value.target.value;
          if (value.target.value == 1) {
            state.overtimeFreeType = 1;
          }
          console.log('applyOvertimeTypevalue...', value.target.value);
        },
      },
    },
    ['10', '17'].includes(state.type) &&
      state.applyOvertimeType != 1 && {
        title: '对应类型',
        dataIndex: 'overtimeFreeType',
        valueType: 'RadioGroup',
        required: '请选择加班类型',
        fieldProps: {
          disabled: disabled || isEndDisabled,
          options: [
            state.type !== '17' && { label: '正常', value: 0, remark: '' },
            { label: '临时', value: 1, remark: '' },
          ].filter(Boolean),
          onChange: (value) => {
            ref?.form.setFieldsValue({
              applyTime: [],
            });
          },
        },
      },
    ['10', '17'].includes(state.type) &&
      employeeList?.length > 0 && {
        title: '申请方式',
        dataIndex: 'processApplyMethod',
        valueType: 'Select',
        required: '请选择申请方式',
        fieldProps: {
          disabled: disabled || isEndDisabled,
          options: [
            { label: '自己申请', value: 1 },
            { label: '代他人申请', value: 2 },
          ].filter(Boolean),
          onChange: (value) => (state.applyType = value),
          placeholder: '请选择申请类型',
        },
      },
    ['13'].includes(state.type) && {
      title: '参与人',
      dataIndex: 'partinList',
      valueType: 'Select',
      required: '请选择参与人',
      fieldProps: {
        onBlur: () => {
          getOldApplyTime?.();
        },
        onChange: debounce(partinChange, 500),
        disabled,
        placeholder: '请选择参与人',
        mode: 'multiple',
        checkAlldisabledKey: '999999',
        maxTagCount: 'responsive',
        options: deepClone(putOffEmpList),
      },
    },
    ['10', '15', '17'].includes(state.type) &&
      state.applyType == 2 && {
        title: '参与人',
        dataIndex: 'partinList',
        valueType: 'Select',
        required: '请选择参与人',
        fieldProps: {
          onBlur: () => {
            getOldApplyTime?.();
          },
          disabled,
          placeholder: '请选择参与人',
          mode: 'multiple',
          checkAlldisabledKey: '999999',
          maxTagCount: 'responsive',
          options: deepClone(employeeList),
        },
      },
    ['10', '11', '17'].includes(state.type) && {
      title: state.type == '17' ? '加班时间' : '申请时间',
      dataIndex: 'applyTime',
      valueType: 'DatePickerRangePicker',
      required: '请选择申请时间',
      fieldProps: {
        disabled: !(!disabled || applyTimeDisabled),
        // disabledDate,
        // disabledTime,
        showTime: {
          defaultValue: [
            moment().set({ h: 9, m: 0, s: 0 }),
            moment().set({ h: 18, m: 0, s: 0 }),
          ],
        },
      },
      formItemProps: {
        rules: [
          {
            required: true,
            message: '请选择申请时间',
          },
          () => ({
            async validator(_, value) {
              let objData = ref?.form.getFieldsValue();
              if (Array.isArray(value) && value[0] && value[1]) {
                let applyBeginTime = moment(value[0]).format(
                  'YYYY-MM-DD HH:mm:ss',
                );
                let applyEndTime = moment(value[1]).format(
                  'YYYY-MM-DD HH:mm:ss',
                );

                // let h = timeDiff([applyBeginTime, applyEndTime], 'hours', 4);
                // state.applyTimeHour = h;
                if (state.type == '11') {
                  //外出要计算申请时长
                  let res = await getattendancehourtime({
                    applyBusiType: 11,
                    applyBeginTime,
                    applyEndTime,
                  });
                  state.applyTimeHour = res.data ? res.data.timeHour : 0;
                }

                // console.log("state.applyTimeHour...", h, state.type);

                //跨年处理
                if (objData?.applyBusiType) {
                  let empIdList = objData.partinList?.map(
                    (item) => item.split('-')[item.split('-').length - 1],
                  );
                  let payload = {
                    applyBusiType: objData.applyBusiType,
                    empIdList: empIdList || [
                      JSON.parse(localStorage.getItem('employeeDTO')).empId,
                    ],
                    applyBeginTime,
                    applyEndTime,
                    attendanceApplyId: history.location.query.id,
                  };
                  // 加入是外出，只校验自己的时间
                  if (objData?.applyBusiType == 11) {
                    payload.empIdList = [
                      JSON.parse(localStorage.getItem('employeeDTO')).empId,
                    ];
                  }

                  //加班、调休根据传入时间获取啥上下半天数据
                  //根据传入的时间，获取上下半天
                  if (
                    objData?.applyBusiType == 10 ||
                    objData?.applyBusiType == 15 ||
                    objData?.applyBusiType == 17
                  ) {
                    // console.log('校验加班参数...', objData);
                    if (objData.applyBusiType == 17) {
                      objData.applyBusiType = 10;
                    }
                    let res = await getattendanceupdowndays({
                      ...objData,
                      applyBeginTime,
                      applyEndTime,
                    });
                    if (res) {
                      state.applyUpDays = res.data.upDays;
                      state.applyDownDays = res.data.downDays;

                      state.applyTimeHour =
                        state.applyUpDays * 3 + state.applyDownDays * 4.5;
                      console.log('计算申请时长...', state.applyTimeHour);
                    }
                  }

                  //加班选择时间时加入校验
                  if (objData?.applyBusiType == 10) {
                    console.log('对应类型...', objData);
                    let startTimeStr = `${value[0].hours()}:${value[0].minutes()}`;
                    let endTimeStr = `${value[1].hours()}:${value[1].minutes()}`;
                    if (
                      objData.overtimeFreeType == 0 ||
                      objData.overtimeFreeType == undefined
                    ) {
                      //正常
                      if (objData.applyOvertimeType == 1) {
                        //只限制为开始时间大于22点以后
                        let startTime_1_split = startTimeStr?.split(':');
                        if (startTime_1_split && startTime_1_split.length > 1) {
                          if (startTime_1_split[0] < 22) {
                            return Promise.reject(
                              new Error('申请时间应从22:00开始'),
                            );
                          }
                        } else {
                          return Promise.reject(
                            new Error('申请时间应从22:00开始'),
                          );
                        }
                      } else {
                        //休息日
                        console.log(
                          '加班校验...',
                          startTimeStr,
                          '...',
                          endTimeStr,
                        );
                        if (
                          startTimeStr === '9:0' ||
                          startTimeStr === '13:30'
                        ) {
                          if (endTimeStr != '12:0' && endTimeStr != '18:0') {
                            return Promise.reject(
                              new Error(
                                '申请时间从9:00/13:30开始，12:00/18:00结束',
                              ),
                            );
                          }
                        } else {
                          return Promise.reject(
                            new Error(
                              '申请时间从9:00/13:30开始，12:00/18:00结束',
                            ),
                          );
                        }
                      }
                    } else {
                      console.log('计算申请时长...临时', state.applyTimeHour);
                      //临时
                      if (objData.applyOvertimeType != 1) {
                        //休息日
                        // if (state.applyTimeHour === 4.5) {
                        //   //结束时间-开始时间=4.5H(开始时间大于:13:30)
                        //   let startTimeStr = `${value[0].hours()}.${value[0].minutes()}`;
                        //   // console.log('加班校验...', startTimeStr);
                        //   if (startTimeStr < 13.3) {
                        //     return Promise.reject(
                        //       new Error(
                        //         '临时加班时间=4.5H,加班开始时间要大于13:30',
                        //       ),
                        //     );
                        //   }
                        // }
                      }
                    }
                    // alert("加班校验")
                  }
                  if (attendanceApplyId) {
                    payload.attendanceApplyId = attendanceApplyId;
                  }
                  let res = await validateattendanceapplytime(payload).then(
                    (res) => {
                      return Object.values(res.data || {}).some(
                        (item) => !item,
                      );
                    },
                  );
                  if (res) return Promise.reject(new Error('当前时间存在冲突'));
                }
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },

    // 申请时长
    ['10', '11', '17'].includes(state.type) && {
      title: state.type == '17' ? '加班时长' : '申请时长',
      dataIndex: 'applyTimeHour',
      // fieldProps: {
      //   disabled,
      //   disabledDate: outDateDisabled,
      // },
      render: () => (
        <div
          style={{
            position: 'relative',
            background: 'rgba(67, 137, 249, 0.1)',
            borderRadius: '2px',
            padding: '5px 8px',
            color: '#4389F9',
            display: 'inline-block',
            // minWidth:"50px"
          }}
        >
          {['10', '11'].includes(state.type) && <>{state.applyTimeHour}h</>}

          {state.type == '17' && (
            <>
              {state.applyUpDays}个上半天、
              {state.applyDownDays}个下半天
            </>
          )}
        </div>
      ),
    },

    //=======================
    ['17'].includes(state.type) && {
      title: '调休时间',
      dataIndex: 'freeApplyTime',
      valueType: 'DatePickerRangePicker',
      required: '请选择申请时间',
      fieldProps: {
        disabled: !(!disabled || applyTimeDisabled),
        // disabledDate,
        // disabledTime,
        showTime: {
          defaultValue: [
            moment().set({ h: 9, m: 0, s: 0 }),
            moment().set({ h: 18, m: 0, s: 0 }),
          ],
        },
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: '请选择申请时间',
          },
          () => ({
            async validator(_, value) {
              let objData = ref?.form.getFieldsValue();
              if (Array.isArray(value) && value[0] && value[1]) {
                let applyBeginTime = moment(value[0]).format(
                  'YYYY-MM-DD HH:mm:ss',
                );
                let applyEndTime = moment(value[1]).format(
                  'YYYY-MM-DD HH:mm:ss',
                );

                let h = timeDiff([applyBeginTime, applyEndTime], 'hours', 4);

                state.freeApplyTimeHour = h;

                //跨年处理
                if (objData?.applyBusiType) {
                  let empIdList = objData.partinList?.map(
                    (item) => item.split('-')[item.split('-').length - 1],
                  );
                  let payload = {
                    applyBusiType: objData.applyBusiType,
                    empIdList: empIdList || [
                      JSON.parse(localStorage.getItem('employeeDTO')).empId,
                    ],
                    applyBeginTime,
                    applyEndTime,
                    attendanceApplyId: history.location.query.id,
                  };
                  // 加入是外出，只校验自己的时间
                  if (objData?.applyBusiType == 11) {
                    payload.empIdList = [
                      JSON.parse(localStorage.getItem('employeeDTO')).empId,
                    ];
                  }

                  //加班、调休根据传入时间获取啥上下半天数据
                  //根据传入的时间，获取上下半天
                  if (
                    objData?.applyBusiType == 10 ||
                    objData?.applyBusiType == 17
                  ) {
                    // console.log('校验加班参数...', objData);
                    if (objData.applyBusiType == 17) {
                      objData.applyBusiType = 15;
                    }

                    if (applyBeginTime == applyEndTime) {
                      state.freeApplyTimeHour = 0;
                      state.freeApplyUpDays = 0;
                      state.freeApplyDownDays = 0;
                    } else {
                      let res = await getattendanceupdowndays({
                        ...objData,
                        applyBeginTime,
                        applyEndTime,
                      });
                      if (res) {
                        state.freeApplyUpDays = res.data.upDays;
                        state.freeApplyDownDays = res.data.downDays;

                        state.freeApplyTimeHour =
                          state.freeApplyUpDays * 3 +
                          state.freeApplyDownDays * 4.5;
                        console.log('计算申请时长...', state.freeApplyTimeHour);
                      }
                    }
                  }

                  //加班选择时间时加入校验
                  if (objData?.applyBusiType == 10) {
                    console.log('对应类型...', objData);
                    let startTimeStr = `${value[0].hours()}:${value[0].minutes()}`;
                    let endTimeStr = `${value[1].hours()}:${value[1].minutes()}`;
                    if (
                      objData.overtimeFreeType == 0 ||
                      objData.overtimeFreeType == undefined
                    ) {
                      //正常
                      if (objData.applyOvertimeType == 1) {
                        //只限制为开始时间大于22点以后
                        let startTime_1_split = startTimeStr?.split(':');
                        if (startTime_1_split && startTime_1_split.length > 1) {
                          if (startTime_1_split[0] < 22) {
                            return Promise.reject(
                              new Error('申请时间应从22:00开始'),
                            );
                          }
                        } else {
                          return Promise.reject(
                            new Error('申请时间应从22:00开始'),
                          );
                        }
                      } else {
                        //休息日
                        console.log(
                          '加班校验...',
                          startTimeStr,
                          '...',
                          endTimeStr,
                        );
                        if (
                          startTimeStr === '9:0' ||
                          startTimeStr === '13:30'
                        ) {
                          if (endTimeStr != '12:0' && endTimeStr != '18:0') {
                            return Promise.reject(
                              new Error(
                                '申请时间从9:00/13:30开始，12:00/18:00结束',
                              ),
                            );
                          }
                        } else {
                          return Promise.reject(
                            new Error(
                              '申请时间从9:00/13:30开始，12:00/18:00结束',
                            ),
                          );
                        }
                      }
                    } else {
                      console.log('计算申请时长...临时', state.applyTimeHour);
                      //临时
                      if (objData.applyOvertimeType != 1) {
                        //休息日
                        // if (state.applyTimeHour === 4.5) {
                        //   //结束时间-开始时间=4.5H(开始时间大于:13:30)
                        //   let startTimeStr = `${value[0].hours()}.${value[0].minutes()}`;
                        //   // console.log('加班校验...', startTimeStr);
                        //   if (startTimeStr < 13.3) {
                        //     return Promise.reject(
                        //       new Error(
                        //         '临时加班时间=4.5H,加班开始时间要大于13:30',
                        //       ),
                        //     );
                        //   }
                        // }
                      }
                    }
                    // alert("加班校验")
                  }
                  if (attendanceApplyId) {
                    payload.attendanceApplyId = attendanceApplyId;
                  }
                  let res = await validateattendanceapplytime(payload).then(
                    (res) => {
                      return Object.values(res.data || {}).some(
                        (item) => !item,
                      );
                    },
                  );
                  if (res) return Promise.reject(new Error('当前时间存在冲突'));
                }
              }
              return Promise.resolve();
            },
          }),
        ],
      },
    },
    //=======================
    // 申请时长
    ['17'].includes(state.type) && {
      // state.freeApplyUpDays = res.data.upDays;
      // state.freeApplyDownDays = res.data.downDays;

      // state.freeApplyTimeHour =

      title: '调休申请时长',
      dataIndex: 'freeApplyTimeHour',
      // fieldProps: {
      //   disabled,
      //   disabledDate: outDateDisabled,
      // },
      render: () => (
        // <div>{state.applyTimeHour}</div>

        <div
          style={{
            position: 'relative',
            background: 'rgba(67, 137, 249, 0.1)',
            borderRadius: '2px',
            padding: '5px 8px',
            color: '#4389F9',
            display: 'inline-block',
            // minWidth:"50px"
          }}
        >
          <>
            {state.freeApplyUpDays}个上半天、
            {state.freeApplyDownDays}个下半天
          </>
        </div>
      ),
    },

    // 假期延期时间
    ['13'].includes(state.type) &&
      state.showDelayTime && {
        title: '延期时间',
        dataIndex: 'applyDelayTime',
        valueType: 'DatePicker',
        required: '请选择延期时间',
        fieldProps: {
          disabled,
          disabledDate: outDateDisabled,
        },
      },
    // 忘打卡申请
    // ['12'].includes(state.type) && {
    //   title: '申请日期',
    //   dataIndex: 'forgetClockApplyDate',
    //   valueType: 'DatePicker',
    //   required: '请选择申请时间',
    //   fieldProps: {
    //     disabledDate: (current) => current > moment(),
    //     disabled,
    //   },
    // },
    ['12'].includes(state.type) && {
      title: '应打卡时间',
      dataIndex: 'forgetClockActualTime',
      valueType: 'DatePicker',
      required: '请选择打卡时间',
      fieldProps: {
        disabled,
        disabledDate: (current) => current > moment(),
        showTime: {
          defaultValue: moment().set({ h: 9, m: 0, s: 0 }),
        },
        onChange: clockApplyTypeChange,
      },
    },
    ['12'].includes(state.type) && {
      title: '忘打卡时段',
      dataIndex: 'forgetClockApplyType',
      valueType: 'Select',
      required: '请选择忘打卡时段',
      fieldProps: {
        disabled,
        placeholder: '请选择忘打卡时段',
        options: [
          { label: '上班忘打卡', value: 1 },
          { label: '下班忘打卡', value: 2 },
          { label: '外出忘打卡', value: 3 },
        ],
        onChange: clockApplyTypeChange,
      },
    },
    ['12'].includes(state.type) &&
      state.outType == 3 && {
        title: '已申请的外出',
        dataIndex: 'forgetClock',
        valueType: 'Select',
        required: '请选择已申请的外出',
        fieldProps: {
          disabled,
          placeholder: '请选择已申请的外出',
          options: state.outList,
        },
      },
    ['12'].includes(state.type) && {
      title: '证明人',
      dataIndex: 'forgetClockProver',
      valueType: 'Select',
      fieldProps: {
        disabled,
        placeholder: '请选择忘打卡的证明人',
        options: deepClone(employeeListAll),
      },
    },
    // 忘打卡结束
    // 外出申请
    ['11'].includes(state.type) && {
      title: '外出公司',
      dataIndex: 'applyOutCompany',
      valueType: 'Input',
      required: '请输入外出公司',
      fieldProps: {
        disabled,
        placeholder: '请输入外出公司',
      },
    },
    ['11'].includes(state.type) && {
      title: '外出地',
      dataIndex: 'applyOutArea',
      valueType: 'AddressSelect',
      required: '请输入外出地',
      fieldProps: {
        disabled,
        placeholder: '请输入外出地',
      },
    },
    // ['11'].includes(state.type) && {
    //   title: '外出地(城市|区域)',
    //   dataIndex: 'applyOutTown',
    //   valueType: 'Input',
    //   required: '请输入外出地(城市|区域)',
    //   fieldProps: {
    //     disabled,
    //     placeholder: '请输入外出地',
    //   },
    // },
    ['11'].includes(state.type) && {
      title: '协同人',
      dataIndex: 'partinList',
      valueType: 'Select',
      required: '请选择协同人',
      fieldProps: {
        disabled,
        placeholder: '请选择协同人',
        mode: 'multiple',
        checkAlldisabledKey: '999999',
        maxTagCount: 'responsive',
        options: deepClone(employeeListAll),
      },
    },
    // 外出结束

    {
      title: '申请说明',
      dataIndex: 'applyRemark',
      valueType: 'TextArea',
      fieldProps: {
        disabled: disabled || isEndDisabled,
        placeholder: '请输入申请说明',
      },
    },
    {
      // title: '申请附件',
      title: '证明材料上传',
      dataIndex: 'attachmentList',
      valueType: 'UploadFile',
      fieldProps: {
        disabled: disabled || isEndDisabled,
        mode: 'multiple',
        // accept: uploadAccept,
        dirUrl: '/attendance/apply',
        multiple: true,
      },
    },
  ].filter(Boolean);
  return edtaFormItem;
};
