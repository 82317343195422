export const getTableCloumns = () => {
  return [
    {
      dataIndex: 'vacationChangeTime',
      title: '时间',
      width: '200px',
      render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      dataIndex: 'vacationBelongYear',
      title: '归属年份',
      width: '100px',
      render: (val, row) => {
        if (val && row.vacationChangeType == 1) {
          return val;
        }
        return '-';
      },
    },
    {
      dataIndex: 'vacationUsed',
      title: '已使用',
      width: '80px',
    },
    {
      dataIndex: 'vacationLastEnable',
      title: '剩余可用',
      width: '100px',
    },
    {
      dataIndex: 'vacationChangeValue',
      title: '变动',
      width: '130px',
      render: (num, row) => {
        let typeObj = {
          1: '增加',
          2: '减少',
        };
        let className =
          row.vacationChangeType == 1 ? 'status-green' : 'status-red';
        return (
          <div className={className}>
            {typeObj[row.vacationChangeType]} {num}
          </div>
        );
      },
    },
    {
      dataIndex: 'vacationLastEnableLock',
      title: '已锁定',
      width: '150px',
    },
    {
      dataIndex: 'vacationLastEnableExpire',
      title: '已过期',
      width: '150px',
    },
    {
      dataIndex: 'vacationChangeExpireTime',
      title: '过期时间',
      width: '150px',
      render: (val, row) => {
        if (val && row.vacationChangeType == 1) {
          return moment(val).format('YYYY-MM-DD');
        }
        return '-';
      },
    },
    {
      dataIndex: 'vacationStatus',
      title: '状态',
      width: '150px',
      render: (key, row) => {
        if (row.vacationChangeType != 1) return '-';
        let typeObj = {
          0: '待消耗',
          1: '消耗中',
          2: '消耗完',
          3: '已过期',
        };
        return typeObj[key];
      },
    },
    {
      dataIndex: 'vacationChangeOptType',
      title: '操作',
      width: '150px',
      render: (key) => {
        let typeObj = {
          1: '系统操作',
          2: '人工操作',
        };
        return typeObj[key];
      },
    },
    {
      dataIndex: 'vacationChangeDescription',
      title: '描述',
      width: '250px',
    },
  ];
};
