import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  BackTop,
  Button,
  Form,
  Loading,
  message,
  MessageBox,
  Progress,
  Tag,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import {
  getTableCloumns,
  getEditCloumns,
  getSearchCloumns,
  getEditCloumns1,
} from './cloumnsData';
import { findTree } from '@/components/ServCategoryList';
import { moundSetData, servCatgOnceOption } from './detailDataTransform';
import ServCategoryList from '@/components/ServCategoryList';
import { processapplyvalide } from '@/_serveice/admin/process';

import {
  etlDelete,
  etlAddModify,
  etlGetSNPage,
} from '@/_serveice/etl/index.js';
import etlmodel from '../../models/etlmodel';

let hasShowEdit = false; // 控制弹窗请求，打开弹窗，防止多次点击

const ServCategory = ({ dispatch, etlmodel, systemOrgani }) => {
  // 编辑框
  const [editVisible, setEditVisible] = useState(false);

  // 编辑框
  const [editVisible1, setEditVisible1] = useState(false);

  const rowData = useRef(null);
  const servcateRef = useRef();
  const proServCatgDiscUnitPirceChange = useRef(false); // 项目工作包清单优惠后的单价是否发生变更
  const detailData = useRef();

  const tableRef = useRef();

  useEffect(() => {
    console.log('1111123445');
    // 公司全部人员
    dispatch({
      type: 'etl/etlGetSNPage',
    });
  }, []);

  console.log('list = ', etlmodel.etlDataList);

  const delRole = (row) => {
    console.log('row = ', row);
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'etlmodel/etlDelete',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  const handleEdit = (row) => {
    console.log('row = ', row);
    rowData.current = row;
    setEditVisible(true);
  };

  const columns = getTableCloumns({
    delRole,
    handleEdit,
  });

  console.log('columns = ', columns);

  // 搜索json
  const searchCloumns = getSearchCloumns({ systemOrgani });

  // 编辑JSON
  const editCloumns = getEditCloumns({
    rowData: rowData.current,
  });

  const editCloumns1 = getEditCloumns1({
    rowData: rowData.current,
  });

  // 编辑框提交
  const editSave = (data) => {
    if (editVisible1) {
      setEditVisible1(false);
      rowData.current = null;
      return;
    }

    dispatch({
      type: 'etlmodel/etlAddModify',
      payload: data,
    }).then((res) => {
      if (res) {
        // handleCancel();
        console.log('res = ', res);
        rowData.current = res.data;
        message.success('保存成功');
        setEditVisible(false);
        setEditVisible1(true);
        // setBtnDisabled(false);
      }
    });
  };

  const extra = [
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        setEditVisible(true);
      }}
    >
      新增
    </Button>,
  ];

  return (
    <div>
      <TablePro
        request="etlmodel/etlGetSNPage"
        // scrollX
        columns={columns}
        rowKey={'snNo'}
        actionRef={(ref) => (tableRef.current = ref)}
        searchCloumns={searchCloumns}
        data={etlmodel.etlDataList}
        extra={extra}
        curColumns
        scrollX
      />
      {/* <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      /> */}
      <ModalFormPro
        title={rowData.current?.snId ? '确认' : '新增'}
        visible={editVisible}
        edtaFormItem={editCloumns}
        width={1000}
        onSave={editSave}
        forceRender={true}
        // submitDisabled={btnDisabled}
        formData={rowData.current}
        onCancel={() => {
          setEditVisible(false);
          rowData.current = null;
          setEditVisible1(false);
        }}
      />

      <ModalFormPro
        title={'确认'}
        visible={editVisible1}
        width={1000}
        edtaFormItem={editCloumns1}
        onSave={editSave}
        forceRender={true}
        // submitDisabled={btnDisabled}
        formData={rowData.current}
        onCancel={() => {
          setEditVisible1(false);
          rowData.current = null;
        }}
      />

      {/* <ModalFormPro
        title="工作包清单"
        formProps={{
          labelCol: { span: 0 },
          wrapperCol: { span: 20 },
        }}
        width="80%"
        visible={serVcateVisible}
        ref={servcateRef}
        // formData={rowData.current}
        edtaFormItem={servCategoryCloumns}
        onCancel={() => {
          setSerVcateVisible(false);
          rowData.current = null;
        }}
      /> */}
    </div>
  );
};

export default connect(({ etlmodel, systemOrgani, contract, loading }) => {
  return {
    etlmodel,
    systemOrgani,
    contract,
    loading: loading.effects,
  };
})(ServCategory);
