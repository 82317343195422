import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  BackTop,
  Button,
  Form,
  Loading,
  message,
  MessageBox,
  Progress,
  Tag,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import ModalFormPro from '@/components/ModalFormPro';
import {
  getTableCloumns,
  getEditCloumns,
  getSearchCloumns,
  getServCateProps,
} from './cloumnsData';
import { getContractMount } from '@/_serveice/contract/list/index';
import { findTree } from '@/components/ServCategoryList';
import { moundSetData, servCatgOnceOption } from './detailDataTransform';
import ServCategoryList from '@/components/ServCategoryList';
import { downloadXlsx, splitParamsFoGet } from '@/_util/util';
import { exportProjectList } from '@/_serveice/project';
import EmpWorkHourList from './empWorkHourList';
import { processapplyvalide } from '@/_serveice/admin/process';

// 项目状态：0：未开始；1：正常；2：停止；3：删除（不会出现在列表）；4：项目结束
export const projectStatus = {
  0: <div className="status-grey">未开始</div>,
  1: <div className="status-green animate">正常</div>,
  2: <div className="status-red">停止</div>,
  4: <div className="status-green">项目结束</div>,
  5: <div className="status-red">终止</div>,
};

let hasShowEdit = false; // 控制弹窗请求，打开弹窗，防止多次点击

const ServCategory = ({
  dispatch,
  project,
  systemOrgani,
  contract,
  loading,
}) => {
  // 修改状态
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  // 编辑框
  const [serVcateVisible, setSerVcateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  // const [rowData, setRowData] = useState(null);
  const rowData = useRef(null);
  const servcateRef = useRef();
  const proServCatgDiscUnitPirceChange = useRef(false); // 项目工作包清单优惠后的单价是否发生变更
  const detailData = useRef();

  // 合同剩余金额
  const [contractMoney, setContractMoney] = useState(0);
  // 表格多选key
  const [selectKey, setSelectKey] = useState([]);
  const [workHourVisible, setWorkHourVisible] = useState(false);
  const [workHourData, setWorkHourData] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [employeeAll, setEmployeeAll] = useState([]);
  const tableRef = useRef();

  useEffect(() => {
    // 合同
    dispatch({
      type: 'contract/getEasyContractList',
    });
    // 工作包清单
    dispatch({
      // type: 'project/getServcatgEasyList',
      type: 'project/getServcatgList',
    });
    // 公司全部人员
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    }).then((res) => {
      setEmployeeAll(res);
    });
  }, []);

  // 根据合同编号查询合同金额
  const contractChange = (key) => {
    if (key) {
      let contractId = key.split('-')?.[0];
      getContractMount({ contractId }).then((res) => {
        if (res?.success) {
          setContractMoney(res.data);
        }
      });
    }
  };

  const delRole = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'project/deleteProject',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  // 打开编辑框
  const showEditModal = ({ projectId, proServCatgDiscTotalAmount }) => {
    processapplyvalide({ applyBusiId: projectId, applyBusiType: 3 }).then(
      (res) => {
        if (!res.data) {
          setBtnDisabled(true);
          message.warn('当前项目存在未完成的流程变更!');
        }
      },
    );
    if (!hasShowEdit) {
      hasShowEdit = true;
      dispatch({
        type: 'project/getProjectDetail',
        payload: {
          projectId,
        },
      }).then((res) => {
        if (res) {
          res.projectTime = [
            moment(res.projectBeginTime),
            moment(res.projectEndTime),
          ];
          res.contractId = `${res.contractId}-${res.contractNumber}`;
          contractChange(res.contractId);
          res.projectAmount = +(res.projectAmount / 100).toFixed(2);
          res.proServCatgDiscTotalAmount = +(
            (proServCatgDiscTotalAmount || 0) / 100
          ).toFixed(2);
          rowData.current = res;
          setEditVisible(true);
          hasShowEdit = false;
        }
      });
    }
  };
  const getServCateList = (projectId) => {
    dispatch({
      type: 'project/getProservcatgList',
      payload: {
        projectId,
      },
    }).then((res) => {
      const { form } = servcateRef.current;
      let dbServCatgList = moundSetData(res || [], {
        employeeAll: systemOrgani.employeeAll,
        findTree,
      });
      detailData.current = dbServCatgList;
      form.resetFields();
      form.setFieldsValue({ dbServCatgList: [...dbServCatgList] });
    });
  };
  // 打开工作包清单弹窗
  const showServCateModal = (row) => {
    setSerVcateVisible(true);
    rowData.current = row;
    getServCateList(row.projectId);
  };

  /** 打开员工上报弹窗 */
  const showWordHour = (row) => {
    // type == 1 项目
    // type == 2 工作包
    setWorkHourData({
      id: row.projectId,
      type: 1,
    });
    setWorkHourVisible(true);
  };

  const columns = getTableCloumns({
    showEditModal,
    projectStatus,
    showServCateModal,
    setStatusVisible,
    setStatusData,
    showWordHour,
    delRole,
  });

  // 搜索json
  const searchCloumns = getSearchCloumns({ systemOrgani });

  const statusFormItem = [
    {
      label: '状态',
      name: 'projectStatus',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        options: [
          {
            label: '重启',
            value: 1,
          },
          {
            label: '停止',
            value: 2,
          },
        ],
      },
    },
  ];

  // 编辑JSON
  const editCloumns = getEditCloumns({
    contractMoney,
    contractChange,
    easyContractList: contract.easyContractList,
    systemOrgani,
    rowData: rowData.current,
  });

  const servCateProps = getServCateProps({
    servCatgOnceOption,
    proServCatgDiscUnitPirceChange,
    dispatch,
    getServCateList,
    rowData,
  });

  // 工作包清单JSON
  const servCategoryCloumns = [
    {
      // type: 'ServCategoryList',
      // props: ,
      formItemRender: () => (
        <Loading loading={loading['project/getProservcatgList']} key="loading">
          <Form.Item name="dbServCatgList">
            <ServCategoryList {...servCateProps} />
          </Form.Item>
        </Loading>
      ),
    },
  ];
  // 修改状态提交
  const statusSave = (val) => {
    let projectStatusDTO = {
      projectIdList: statusData,
      projectStatus: val.projectStatus,
    };
    dispatch({
      type: 'project/restProject',
      payload: projectStatusDTO,
    }).then((res) => {
      if (res) {
        setStatusVisible(false);
        setStatusData(null);
      }
    });
  };

  // 编辑框提交
  const editSave = (data) => {
    const { dbServCatgList, projectTime, ...values } = data;
    // if (rowData.current && projectTime && rowData.current?.projectStatus == 0) {
    //   if (!(+projectTime[1] > moment() && +projectTime[0] < moment())) {
    //     message.error('进行中的项目时间必须包含当前日期！');
    //     return;
    //   }
    // }
    if (projectTime) {
      // 某些状态下修改日期是有限制的

      values.projectBeginTime = moment(projectTime[0])
        .startOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
      values.projectEndTime = moment(projectTime[1])
        .endOf('date')
        .format('YYYY-MM-DD HH:mm:ss');
    }

    if (values.contractId) {
      let contractNumberArr = values.contractId.split('-');
      contractNumberArr.shift();
      values.contractNumber = contractNumberArr.join('-');
      values.contractId = values.contractId.split('-')[0];
    }

    if (rowData.current?.projectId) {
      values.projectId = rowData.current?.projectId;
    }
    // if (contractMoney < values.projectAmount) {
    //   message.warn('项目金额不能超过合同剩余金额');
    //   return;
    // }

    //增加参与团队
    if (values.proPreallocationMemberList) {
      console.log(
        'values.proPreallocationMemberList,,,',
        values.proPreallocationMemberList,
      );
      values.proPreallocationMemberList = values.proPreallocationMemberList.map(
        (item, index) => {
          item = item.value ? item.value : item;
          return { teamMemberId: item.split('-')[1], teamMemberSort: index };
        },
      );
    }

    values.projectAmount &&
      (values.projectAmount = +(values.projectAmount * 100).toFixed(2));
    dispatch({
      type: 'project/editProject',
      payload: values,
    }).then((res) => {
      if (res) {
        // handleCancel();
        message.success('保存成功');
        setEditVisible(false);
        setBtnDisabled(false);
        rowData.current = null;
      }
    });
  };

  const extra = [
    <Button
      type="primary"
      key={btnKeys.add}
      onClick={() => {
        setContractMoney(0);
        setEditVisible(true);
      }}
    >
      新增项目
    </Button>,
    <Button
      type="primary"
      key={btnKeys.statusChange}
      onClick={() => {
        if (selectKey?.length == 0) {
          message.warn('请选择批量操作的客户！');
          return;
        }
        setStatusVisible(true);
        setStatusData(selectKey);
      }}
    >
      批量修改状态
    </Button>,
    <Button
      type="primary"
      key="noKeys"
      onClick={() => {
        let value = tableRef.current.value;
        let payload = {
          key: value.key ?? '',
          projectConsumProgress: value.projectConsumProgress ?? '',
          projectManager: value.projectManager ?? '',
          projectStatus: value.projectStatus ?? '',
          sortFields: value.sortFields
            .map((item) => `${item.name}_${item.value}`)
            .join(','),
        };
        let url = `${baseURL}${exportProjectList}${splitParamsFoGet(payload)}`;
        downloadXlsx(url, '项目列表');
      }}
    >
      导出
    </Button>,
  ];

  const rowSelection = {
    selectedRowKeys: selectKey,
    onChange: (userIdList) => {
      setSelectKey(userIdList);
    },
  };
  return (
    <div>
      <TablePro
        params={{
          roleList: JSON.parse(localStorage.getItem('employeeDTO'))?.roleList,
        }}
        request="project/getProjectList"
        rowKey={'projectId'}
        scrollX
        columns={columns}
        actionRef={(ref) => (tableRef.current = ref)}
        searchCloumns={searchCloumns}
        data={project.projectList}
        extra={extra}
        // curColumns
        scrollY
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <ModalFormPro
        title={'修改状态'}
        visible={statusVisible}
        edtaFormItem={statusFormItem}
        onSave={statusSave}
        onCancel={() => {
          setStatusData([]);
          setStatusVisible(false);
        }}
      />
      <ModalFormPro
        title={rowData.current?.projectId ? '编辑' : '新增'}
        visible={editVisible}
        edtaFormItem={editCloumns}
        onSave={editSave}
        submitDisabled={btnDisabled}
        formData={rowData.current}
        onCancel={() => {
          setEditVisible(false);
          setBtnDisabled(false);
          rowData.current = null;
        }}
      />
      <ModalFormPro
        title="工作包清单"
        formProps={{
          labelCol: { span: 0 },
          wrapperCol: { span: 20 },
        }}
        width="80%"
        visible={serVcateVisible}
        ref={servcateRef}
        // formData={rowData.current}
        edtaFormItem={servCategoryCloumns}
        onCancel={() => {
          setSerVcateVisible(false);
          rowData.current = null;
        }}
      />
      <EmpWorkHourList
        visible={workHourVisible}
        data={workHourData}
        onCancel={() => {
          setWorkHourVisible(false);
          setWorkHourData(null);
        }}
      />
    </div>
  );
};

export default connect(({ project, systemOrgani, contract, loading }) => {
  return {
    project,
    systemOrgani,
    contract,
    loading: loading.effects,
  };
})(ServCategory);
