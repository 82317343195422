export const busiTypeOpitons = [
  {
    label: '客户管理',
    value: 1,
  },
  {
    label: '合同管理',
    value: 2,
  },
  {
    label: '项目管理',
    value: 3,
  },
  {
    label: '任务管理',
    value: 4,
  },
  {
    label: '角色管理',
    value: 5,
  },
  {
    label: '绩效管理',
    value: 6,
  },
  {
    label: '周计划管理',
    value: 7,
  },
  {
    label: '绩效变更',
    value: 8,
  },
  {
    label: '周计划变更',
    value: 9,
  },
  {
    label: '加班申请',
    value: 10,
  },
  {
    label: '外出申请',
    value: 11,
  },
  {
    label: '忘打卡申请',
    value: 12,
  },
  {
    label: '假期延期申请',
    value: 13,
  },
  {
    label: '考勤撤销申请',
    value: 14,
  },
  {
    label: '假期申请',
    value: 15,
  },
];

export const todoItemBusiTypeObj = {
  1: '客户管理',
  2: '合同管理',
  3: '项目管理',
  4: '任务管理',
  5: '角色管理',
  6: '绩效管理',
  7: '周计划管理',
  8: '绩效修改变更',
  9: '周计划修改变更',
  10: '加班申请',
  11: '外出申请',
  12: '忘打卡申请',
  13: '假期延期申请',
  14: '考勤撤销申请',
  15: '假期申请',
};
