import { Avatar } from 'kenshin';

const WdpAvatar = ({ url, ...props }) => {
  return (
    <Avatar
      size="large"
      style={{ marginRight: 10 }}
      src={url && (url?.startsWith('http') ? url : `${ossUrl}/${url}`)}
      {...props}
    >
      {props.children}
    </Avatar>
  );
};

export default WdpAvatar;
