import { Button, Input, Popconfirm } from 'kenshin';
import React, { useEffect, useState } from 'react';
import {
  CommentOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { clearSessionList, updateSessionName } from '../../../_serveice/openai';
import { useReactive } from 'ahooks';

const SessionList = (props) => {
  const { list } = props;

  const state = useReactive({
    editSid: '',
    sessionList: [],
  });

  useEffect(() => {
    if (Array.isArray(list)) {
      state.sessionList = [...list].reverse();
    }
  }, [list]);

  const handleSessionClick = (sid) => {
    props.onChange(sid);
  };

  const handleAddSession = () => {
    props.add();
  };

  const handleClearSessions = () => {
    console.log('清空');
    props.clear();
  };

  const update = (e, { sid, sname }) => {
    e.stopPropagation();
    state.editSid = '';
    updateSessionName(sid, sname);
  };

  const renderName = (session) => {
    if (session.sid == state.editSid) {
      return (
        <Input
          autoFocus
          value={session.sname}
          onChange={(e) => (session.sname = e.target.value)}
          onBlur={(e) => update(e, session)}
        />
      );
    }
    return session.sname;
  };

  const sessionDelete = (sid) => {
    clearSessionList([sid]).then((res) => {
      if (res?.success) {
        let i = state.sessionList.findIndex((item) => item.sid == sid);
        if (i != -1) state.sessionList.splice(i, 1);
        props.add();
      }
    });
  };

  const renderSessionList = () => {
    return state.sessionList.map((session) => (
      <div
        key={session.sid}
        onClick={() => handleSessionClick(session.sid)}
        className="session-item"
      >
        <img src={require('../../../_assets/chat-item-icon.svg')} />
        {'  '}
        <span className="s-name">{renderName(session)} </span>
        <div className="options-icon" onClick={(e) => e.stopPropagation()}>
          {state.editSid != session.sid && (
            <EditOutlined onClick={() => (state.editSid = session.sid)} />
          )}{' '}
          {state.editSid != session.sid && (
            <Popconfirm
              title="确认删除？"
              okText="是"
              cancelText="否"
              onConfirm={() => sessionDelete(session.sid)}
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="session-menu">
      <Button onClick={handleAddSession} className="add-session-btn">
        新增会话
      </Button>
      <div className="list">{renderSessionList()}</div>
      <Popconfirm
        title="确认清空？"
        okText="是"
        cancelText="否"
        onConfirm={handleClearSessions}
      >
        <Button className="add-session-btn">清空所有会话</Button>
      </Popconfirm>
    </div>
  );
};

export default SessionList;
