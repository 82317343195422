import { deepClone } from '@/_util/util';
import { split } from '@umijs/deps/compiled/lodash';

/*

  项目编辑逻辑：
  新增的服务类目需要存放在 thisNewAddServCatgList
  旧服务类目存放在  thisOldServCatgList（编辑）
  删除的服务类目存放在 thisRemoveServCatgList

  参与人员：只存在 thisOldServCatgList里面，新增的全部为新增
  新增的参与人员：thisNewAddServCatgTeamMemberList
  旧参与人员：thisOldServCatgTeamMemberList
  删除的参与人员：thisRemoveServCatgTeamMemberList


*/

// 新增需要删除的属性
const delPropertyForAdd = (obj) => {
  let arr = [
    'proServCatgId',
    'proServCatgPantId',
    'servCatgDbcode',
    'servCatgLevel',
    'servCatgStatus',
  ];
  arr.map((item) => {
    delete obj[item];
  });
};

// 有children要删除的属性或父级不能存在金额数据，防止后端统计误差
const delPropertyForHasChildren = (obj) => {
  let arr = [
    'servCatgCount',
    'servCatgUnit',
    'servCatgWorkPeriod',
    'servCatgHourOrginCost',
    'servCatgHourDiscCost',
    'servCatgHourDisc',
    '$top',
  ];
  // 有子类目，父级类目不能存在干扰统计的数据，统统设置为0
  // arr.map((item) => {
  //   obj[item] = 0;
  // });
  delete obj.$top;
  // obj.servCatgUnit = '';
};

// 统一设置排序跟编码，注：后端需要
const setSortAndCode = (arr, code) => {
  arr.map((item, index) => {
    item.servCatgCode = `${code ? `${code}.` : ''}${index + 1}`;
    item.servCatgSort = index + 1;
    delete item.$top;
    if (item.childList) {
      [item].map(delPropertyForHasChildren);
      setSortAndCode(item.childList, item.servCatgCode);
    }
  });
};

// 渲染回显数据
export const moundSetData = (
  list,
  { findTree, employeeAll }, // 树遍历方法与遍历需要的员工列表
  isTop = true,
) => {
  if (Array.isArray(list) && employeeAll) {
    list?.map((item) => {
      if (item.childList) {
        item.childList = moundSetData(
          item.childList,
          {
            findTree,
            employeeAll,
          },
          false,
        );
      }
      if (isTop) item.$top = true;

      item.key = item.proServCatgId;
      if (item.servCatgBeginTime && item.servCatgEndTime) {
        item.servCatgTime = [
          moment(item.servCatgBeginTime),
          moment(item.servCatgEndTime),
        ];
      }
      // moneyTransform(item, 'servCatgHourDiscCost', false);
      // moneyTransform(item, 'servCatgHourOrginCost', false);
      // moneyTransform(item, 'servCatgOrginTotalAmount', false);
      // moneyTransform(item, 'servCatgDiscTotalAmount', false);
      // moneyTransform(item, 'servCatgHourDisc', false);

      moneyTransform(item, 'servCatgUnitPrice', false);
      moneyTransform(item, 'servCatgHourDiscCost', false);
      moneyTransform(item, 'servCatgHourOrginCost', false);
      moneyTransform(item, 'servCatgOrginTotalAmount', false);
      moneyTransform(item, 'servCatgDiscTotalAmount', false);
      moneyTransform(item, 'servCatgHourDisc', false);
      moneyTransform(item, 'servCatgTotalDisc', false);

      let servCatgLeaderObj = findTree(employeeAll, item.servCatgLeader);
      item.servCatgLeader = servCatgLeaderObj?.value;
      item.addTeamMemberList = Array.from(
        new Set(
          item.dbServCatgTeamMemberList?.map(
            (member) => findTree(employeeAll, member.teamMemberId)?.value,
          ),
        ),
      );
      item.dbServCatgList = servCatgListOptions(item.dbServCatgTeamMemberList);
    });
  }
  return list;
};

// 金额转换成分 100元 = 100 00分
const moneyTransform = (obj, key, isToCents = true) => {
  if (obj[key]) {
    if (isToCents) obj[key] = obj[key] * 100;
    else obj[key] = +(obj[key] / 100).toFixed(2);
  }
};

// 只处理数据，不处理参与人员，默认新增
const servCatgListOptions = (data, $add = false) => {
  if (!Array.isArray(data)) {
    return null;
  }
  return data.map((item, index) => {
    const {
      teamMemberList,
      addTeamMemberList,
      dbServCatgTeamMemberList,
      servCatgTime,
      key,
      time,
      ...resItem
    } = item;
    if (resItem.childList) {
      // 为了不影响统计数据，有子类的这些属性要删
      [resItem].map(delPropertyForHasChildren);

      if ($add) {
        resItem.childList = servCatgListOptions(resItem.childList, true);
        resItem.childList.map(delPropertyForAdd);
      } else {
        resItem.childList = memberListFilter(resItem.childList);
      }
    }

    // 类目周期需要格式：2022-04-01 00:00:00
    servCatgTime?.[0] &&
      (resItem.servCatgBeginTime = moment(servCatgTime[0]).format(
        'YYYY-MM-DD HH:mm:ss',
      ));
    servCatgTime?.[1] &&
      (resItem.servCatgEndTime = moment(servCatgTime[1]).format(
        'YYYY-MM-DD HH:mm:ss',
      ));

    // 部分金额不需要传给后端，只需要回显，可通过公式计算出来
    // delete resItem.servCatgUnitPrice;
    // delete resItem.DiscountAmount;
    resItem.DiscountAmount = undefined;
    // 总优惠折扣，前端计算给后端保存
    resItem.servCatgTotalDisc =
      +(
        resItem.servCatgDiscTotalAmount / resItem.servCatgOrginTotalAmount
      ).toFixed(2) || 0;
    // 金额相关单位需乘以100,1元=100，为后端数据库存储用
    moneyTransform(resItem, 'servCatgUnitPrice');
    moneyTransform(resItem, 'servCatgHourDiscCost');
    moneyTransform(resItem, 'servCatgHourOrginCost');
    moneyTransform(resItem, 'servCatgOrginTotalAmount');
    moneyTransform(resItem, 'servCatgDiscTotalAmount');
    moneyTransform(resItem, 'servCatgHourDisc');
    moneyTransform(resItem, 'servCatgTotalDisc');

    // 遍历数据需要部门id，所以数据格式目前是   部门ID-员工ID（分类Select不允许分类id跟选择项id重复，但这边就有这种情况，需要特殊处理）
    item.servCatgLeader &&
      (resItem.servCatgLeader = item.servCatgLeader.split('-')[1]);

    // 参与人员
    // let thisNewAddServCatgTeamMemberList = addTeamMemberList?.map(
    //   (item, index) => ({
    //     teamMemberId: item.split('-')[1],
    //     teamMemberSort: index + 1,
    //   }),
    // );

    // 参与人员
    let thisNewAddServCatgTeamMemberList = {}
    if(addTeamMemberList?.every(item => typeof item === 'object')) {
       thisNewAddServCatgTeamMemberList = addTeamMemberList?.map(
        (item, index) => ({
          teamMemberId: item.value.split('-')[1],
          teamMemberSort: index + 1,
        }),
      );
    } else {
       thisNewAddServCatgTeamMemberList = addTeamMemberList?.map(
      (item, index) => ({
        teamMemberId: item.split('-')[1],
        teamMemberSort: index + 1,
      }),
    );
    }
    

  

    // 新增的成员列表
    resItem.thisNewAddServCatgTeamMemberList = thisNewAddServCatgTeamMemberList;

    // 成员数量
    resItem.servCatgTeamMemberNum =
      thisNewAddServCatgTeamMemberList?.length || 0;
    return resItem;
  });
};

export const menberListOptions = (item) => {
  // 表单获取的key
  // let keys =
  //   item.addTeamMemberList
  //     ?.filter(Boolean)
  //     .map((item) => item.toString?.().split('-')?.[1]) || [];


  let keys = []
   if(item.addTeamMemberList?.every(item => typeof item === 'object')) {
    keys =
    item.addTeamMemberList
      ?.filter(Boolean)
      .map((item) => item.value.toString?.().split('-')?.[1]) || [];
   } else {
    keys =
    item.addTeamMemberList
      ?.filter(Boolean)
      .map((item) => item.toString?.().split('-')?.[1]) || [];
   }


  // 数据返回的key 作为对比数据
  let oldKeys =
    item.dbServCatgTeamMemberList?.map((item) => item.teamMemberId) || [];

  let thisOldServCatgTeamMemberList =
    item.dbServCatgTeamMemberList?.filter((item) =>
      keys.includes(item.teamMemberId),
    ) || [];
  let thisRemoveServCatgTeamMemberList =
    item.dbServCatgTeamMemberList?.filter(
      (item) => !keys.includes(item.teamMemberId),
    ) || [];


  // console.log("thisNewAddServCatgTeamMemberList=====",keys);
  let thisNewAddServCatgTeamMemberList = keys
    .filter((item) => !oldKeys.includes(item))
    ?.map((item, index) => ({
      teamMemberId: item,
      teamMemberSort: thisOldServCatgTeamMemberList.length + index + 1,
    }));

  item.servCatgTeamMemberNum =
    thisOldServCatgTeamMemberList.length +
    thisNewAddServCatgTeamMemberList.length;

  let resObj = {
    thisNewAddServCatgTeamMemberList,
    thisOldServCatgTeamMemberList,
    thisRemoveServCatgTeamMemberList,
  };
  return resObj;
};

// 处理数据的同时处理参与人员
export const memberListFilter = (list) => {
  if (!Array.isArray(list)) {
    return null;
  }

  return list.map((item, index) => {
    Object.assign(item, ...servCatgListOptions([item], false));

    const {
      addTeamMemberList,
      dbServCatgTeamMemberList,
      key,
      servCatgTime,
      ...resItem
    } = item;

    let resObj = menberListOptions(item);

    Object.assign(resItem, resObj);
    return resItem;
  });
};

// 新增叶子节点属性memberListFilter
const addLeafProps = (arr) => {
  arr.map((item) => {
    if (item.childList) {
      addLeafProps(item.childList);
    }
    item.servCatgIsLeaf = item.childList ? 0 : 1;
  });
};

export const filterServCatgList = (arr, detailData) => {
  if (!Array.isArray(arr)) {
    return null;
  }
  addLeafProps(arr);
  setSortAndCode(arr);
  return memberListFilter(arr);
};

// 新版本的服务类目单独设置数据
export const servCatgOnceOption = (data) => {
  let res = filterServCatgList([data], []);
  return res[0];
};
