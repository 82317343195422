import styles from './styles.less';
import {
  LateIcon,
  OverTimeIcon,
  VavationApplyIcon,
  GooutIcon,
  LeaveEarlyIcon,
} from './icon';
import { Col, Row } from 'kenshin';

/** 时间格式化 */
const TimeFormat = (time, type = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(time).format(type);
};

const getCardObj = ({ title, num, unit, icon }, tooltipTitle) => {
  return {
    title,
    unit,
    num: num || 0,
    icon: require(`@/_assets/${icon || 'person'}.svg`),
    size: 'large',
    tooltipTitle,
  };
};

const createKanbanPopup = ({ title, icon, arr }) => {
  return (
    <div className={styles.kanbanPopup}>
      <div className="title">{title}</div>
      <div className="list">
        {arr.map((item, i) => (
          <div className="item" key={i}>
            <div className="item-title">
              {item.title}
              <div className="title-icon">{icon}</div>
            </div>
            <div className="item-cell">
              {item.list.map(([key, value]) => (
                <div key={value}>
                  <span>{key}</span>
                  {value}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const lateTotalNumRender = (data) => {
  if (!Array.isArray(data?.lateTotalDetailList)) return null;
  let title = '迟到明细';
  let icon = <div style={{ background: '#f95520' }}>{LateIcon}迟到</div>;
  let arr = data.lateTotalDetailList.map((item) => {
    return {
      title: item.empDeptName,
      list: [
        ['异常人员', item.empName],
        ['打卡时间', TimeFormat(item.empClockTimeList[0])],
      ],
    };
  });
  return createKanbanPopup({ title, icon, arr });
};

/** 早退 */
const leaveEarlyTotalNumRender = (data) => {
  if (!Array.isArray(data?.leaveEarlyTotalDetailList)) return null;
  let title = '早退明细';
  let icon = <div style={{ background: '#FEB64D' }}>{LeaveEarlyIcon}早退</div>;
  let arr = data.leaveEarlyTotalDetailList.map((item) => {
    return {
      title: item.empDeptName,
      list: [
        ['异常人员', item.empName],
        ['打卡时间', TimeFormat(item.empClockTimeList[1])],
      ],
    };
  });
  return createKanbanPopup({ title, icon, arr });
};

/** 旷工 */
const absenteeismTotalNumRender = (data) => {
  if (!Array.isArray(data?.absenteeismTotalDetailList)) return null;
  let title = '旷工明细';
  let arr = data.absenteeismTotalDetailList
    .map((item) => {
      return item.empAbsenteeismList.map((out) => {
        return {
          title: item.empDeptName,
          list: [
            ['异常人员', item.empName],
            ['旷工时段', out.describe],
          ],
        };
      });
    })
    .flat(2);
  return createKanbanPopup({ title, arr });
};

/** 外出 */
const gooutTotalNumRender = (data) => {
  if (!Array.isArray(data?.gooutTotalDetailList)) return null;
  let title = '外出明细';
  let icon = <div style={{ background: '#6370DE' }}>{GooutIcon}外出</div>;
  let arr = data.gooutTotalDetailList
    .map((item) => {
      return item.empGooutList.map((out) => {
        return {
          title: item.empDeptName,
          list: [
            ['外出人员', item.empName],
            [
              '申请时间',
              `${TimeFormat(out.gooutBeginTime)} ~ ${TimeFormat(
                out.gooutEndTime,
              )}`,
            ],
            ['申请地点', out.applyOutCompany],
            ['打卡地点', out.applyOutTown],
          ],
          sort: out.gooutBeginTime, //控制排序
        };
      });
    })
    .flat(2)
    .sort((a, b) => b.sort - a.sort);
  return createKanbanPopup({ title, icon, arr });
};

/** 请假 */
const vacationApplyTotalNumRender = (data) => {
  if (!Array.isArray(data?.vacationApplyTotalDetailList)) return null;
  let title = '请假明细';
  let icon = (
    <div style={{ background: '#23C2DB' }}>{VavationApplyIcon}请假</div>
  );
  let arr = data.vacationApplyTotalDetailList
    .map((item) => {
      return item.empVacationApplyList.map((out) => {
        return {
          title: item.empDeptName,
          list: [
            ['申请人员', item.empName],
            [
              '申请时间',
              `${TimeFormat(out.applyBeginTime)} ~ ${TimeFormat(
                out.applyEndTime,
              )}`,
            ],
            ['假期类型', out.vacationName],
          ],
          sort: out.applyBeginTime, //控制排序
        };
      });
    })
    .flat(2)
    .sort((a, b) => b.sort - a.sort);
  return createKanbanPopup({ title, icon, arr });
};

/** 创建看板元素 */
const createKanban = (key, data) => {
  const chooseKey = {
    // normalTotalNum: normalTotalNumRender,
    lateTotalNum: lateTotalNumRender,
    leaveEarlyTotalNum: leaveEarlyTotalNumRender,
    absenteeismTotalNum: absenteeismTotalNumRender,
    gooutTotalNum: gooutTotalNumRender,
    vacationApplyTotalNum: vacationApplyTotalNumRender,
  };
  return chooseKey[key]?.(data);
};

/** 考勤情况面板 */
export const getPersonnelList = (data) => {
  let resArr = [
    '正常-normalTotalNum-人-person',
    '迟到-lateTotalNum-人-down',
    '早退-leaveEarlyTotalNum-人-alarm',
    '旷工-absenteeismTotalNum-人-location',
    '外出-gooutTotalNum-人-out',
    '请假-vacationApplyTotalNum-人-apply',
  ];

  return resArr.map((item) => {
    const [title, key, unit, icon] = item.split('-');
    return getCardObj(
      { title, num: data[key], unit, icon },
      createKanban(key, data),
    );
  });
};

export const getInfoList = (data) => {
  let resArr = [
    '迟到-lateCount-次-down',
    '早退-leaveEarlyCount-次-alarm',
    '旷工-absenteeismCount-次-location',
    '补卡-forgetClockApplyCount-次-scan',
    '外出-gooutApplyCount-次-out',
    '请假-vacationApplyCount-次-apply',
  ];

  return resArr.map((item) => {
    const [title, key, unit, icon] = item.split('-');
    return getCardObj({ title, num: data[key], unit, icon });
  });
};

export const getUsercenterList = (data) => {
  let resArr = [
    '本周工时-weekWorkHourNum-小时-clock',
    '参与项目-partinWorkPackageNum-个-project',
    '参与工作包-partinProjectNum-个-pack',
    '剩余调休时间-holidayEnableDayThisYear-天-calendar',
    '剩余年假时间-annualEnableDayThisYear-天-calendar',
    '本月加班时长-overtimeMonthDays-天-calendar',
  ];

  return resArr.map((item) => {
    const [title, key, unit, icon] = item.split('-');
    return getCardObj({ title, num: data[key], icon, unit });
  });
};

// 日历区块的每一项浮窗---------------------------------------------
/** 创建 */
const createStatusCard = ({ arr, icon, color, name }, key) => {
  return (
    <div className={styles.statusCard} key={key}>
      <div className="s-title">
        {icon}
        <span style={{ color }}>{name}</span>
      </div>
      <div className="row-card">
        {arr.map(([key, value]) => (
          <div key={value}>
            <span>{key}</span>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};
/** 请假 */
const vacationApplyPopop = (list) => {
  if (!Array.isArray(list)) return null;
  let arr = list.map((item) => {
    return [
      item.vacationName,
      `${TimeFormat(item.applyBeginTime)}~${TimeFormat(item.applyEndTime)}`,
    ];
  });
  let title = { icon: VavationApplyIcon, color: '#23C2DB', name: '请假' };
  return createStatusCard({ arr, ...title }, 'vacationName');
};

/** 加班 */
const overTImePopop = (list) => {
  if (!Array.isArray(list)) return null;
  let arr = list.map((item) => {
    return [
      '申请时间',
      `${TimeFormat(item.applyBeginTime)}~${TimeFormat(item.applyEndTime)}`,
    ];
  });
  let title = {
    icon: OverTimeIcon,
    color: '#468AF8',
    name: `加班：${list[0].overTimeHour}小时`,
  };
  return createStatusCard({ arr, ...title }, 'overTImePopop');
};
/** 迟到 */
const latePopop = (list) => {
  if (!Array.isArray(list)) return null;
  let arr = list.map((item) => {
    return ['打卡时间', TimeFormat(item, 'HH:mm:ss')];
  });
  let timeDiff = moment(list[0]).diff(
    moment(list[0]).set({ h: 9, m: 0, s: 0 }),
    'minute',
  );
  let title = {
    icon: LateIcon,
    color: '#F95520',
    name: `迟到：${timeDiff}分钟`,
  };
  return createStatusCard({ arr, ...title }, 'latePopop');
};
/** 早退 */
const leaveEarlyPopop = (list) => {
  if (!Array.isArray(list)) return null;
  let arr = list.map((item) => {
    return ['打卡时间', TimeFormat(item, 'HH:mm:ss')];
  });
  let timeDiff = moment(list[0])
    .set({ h: 18, m: 0, s: 0 })
    .diff(moment(list[0]), 'minute');
  let title = {
    icon: LeaveEarlyIcon,
    color: '#FEB64D',
    name: `早退：${timeDiff}分钟`,
  };
  return createStatusCard({ arr, ...title }, 'leaveEarlyPopop');
};
/** 外出 */
const goOutPopop = (list) => {
  if (!Array.isArray(list)) return null;
  let item = list[0];
  if (!item) return null;
  let arr = [
    [
      '申请时间',
      `${TimeFormat(item.gooutBeginTime)}~${TimeFormat(item.gooutEndTime)}`,
    ],
    ['外出申请地点', item.applyOutCompany],
    ['实际打卡地点', item.applyOutTown],
  ];
  let title = {
    icon: GooutIcon,
    color: '#6370DE ',
    name: `外出：${item.applyTimeHour}小时`,
  };
  return createStatusCard({ arr, ...title }, 'overTImePopop');
};

export const getIconTootlip = (data) => {
  let compomentArr = [];
  //外出
  if (data.goout)
    compomentArr.push(goOutPopop(data.gooutDetailData?.empGooutList));
  //加班
  if (data.overtime)
    compomentArr.push(
      overTImePopop(data.overtimeDetailData?.empOvertimeApplyPassList),
    );
  // 迟到
  if (data.late)
    compomentArr.push(latePopop(data.lateDetailData?.empClockTimeList));
  // 早退
  if (data.leaveEarly)
    compomentArr.push(
      leaveEarlyPopop(data.leaveEarlyDetailData?.empClockTimeList),
    );
  //请假
  if (data.vacationApply)
    compomentArr.push(
      vacationApplyPopop(data.vacationApplyDetailData?.empVacationApplyList),
    );
  return compomentArr;
};

// 获取当前月时间段：
export const getmounthString = (data) => {
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth() + 1; // 月份从0开始，所以要加1
  var currentMonth = year + '年' + month + '月';
  // console.log(currentMonth);
  return currentMonth;
};

// 获取当前周的日期时间段：
export const getWeekDayString = (data) => {
  var today = new Date();
  var currentDayOfWeek = today.getDay(); // 获取当前星期几，0表示星期日，1表示星期一，以此类推
  var startDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - currentDayOfWeek + 1,
  );
  var endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + (6 - currentDayOfWeek) + 1,
  );

  var startDateString =
    startDate.getFullYear() +
    '年' +
    (startDate.getMonth() + 1) +
    '月' +
    startDate.getDate() +
    '号';
  var endDateString = endDate.getDate() + '号';

  var result = startDateString + '-' + endDateString;

  return result;
};

// 获取今天的日期时间：
export const getToDayString = (data) => {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1; // 月份从0开始，所以要加1
  var day = date.getDate();
  // console.log(year + '年' + month + '月' + day + '日');

  return year + '年' + month + '月' + day + '日';
};

// 获取昨天的日期时间：
export const getLastDayString = (data) => {
  var today = new Date();
  today.setDate(today.getDate() - 1);
  var year = today.getFullYear();
  var month = today.getMonth() + 1; // 月份从0开始，所以要加1
  var day = today.getDate();
  // console.log(year + "年" + month + "月" + day + "日");
  return year + '年' + month + '月' + day + '日';
};

// ------------------------------------------------------------日历区块结束------------------------------------
