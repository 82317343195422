import { connect, history } from 'umi';
import { Button, message, Switch, Table, MessageBox } from 'kenshin';
import TablePro from '@/components/TablePro';
import ModalFormPro from '@/components/ModalFormPro';
import { useEffect, useRef, useState } from 'react';
import { dicLType } from '@/_util/keysType';
import PageLoading from '@/components/pageLoading';
// import { exportCustomerListUrl } from '@/_serveice/process/list';
import { downloadXlsx, splitParamsFoGet, deepClone } from '@/_util/util';
import StepModel from './components/StepModel';
import KEY from '../_util/busiTypeKey';

/** 不需要设置步骤的类型 */
let ignoreType = [KEY.OSA, KEY.WEEK, KEY.OSAEDIT, KEY.WEEKEDIT];
const ProcessList = ({ process, dispatch, loading, system }) => {
  // 修改状态
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState([]);

  // 新增编辑
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);
  const modalRef = useRef();

  const [stepModelVisible, setStepModelVisible] = useState(false);
  const [stepModelData, setStepModelData] = useState(null);

  const [selectKey, setSelectKey] = useState([]);

  const tableRef = useRef();

  const editClick = (row) => {
    setEditModalData(row);
    setEditModalVisible(true);
  };

  const handleStatusChange = (row) => {
    dispatch({
      type: 'process/setprocessstatus',
      payload: {
        processId: row.processId,
        processStatus: +!row.processStatus,
      },
    }).then((res) => {
      if (res) {
        message.success('状态修改成功');
      }
    });
  };

  /**删除 */
  const delItem = (payload) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'process/deleteprocess',
          payload,
        });
      },
    });
  };

  // 打开设置步骤弹窗
  const showStepModel = async (row) => {
    let list = process.nodeList[row.processId];
    if (!list) {
      list = await onExpand(true, row);
    }
    await setStepModelData({ ...row, processNodeList: list });
    setStepModelVisible(true);
  };

  // 表头数据
  const columnsObj = {
    columns: [
      {
        dataIndex: 'processName',
        title: '流程名称',
      },
      {
        dataIndex: 'processBusiType',
        title: '业务类型',
        render: (val) => {
          return process.processTypeStrObj[val];
        },
      },
      {
        dataIndex: 'userName',
        title: '创建人',
      },
      {
        dataIndex: 'processStatus',
        title: '流程状态',
        render: (val, row) => (
          <Switch checked={val} onChange={() => handleStatusChange(row)} />
        ),
      },
      {
        dataIndex: 'processRemark',
        title: '流程备注',
      },
      {
        key: 'options',
        title: '操作',
        render: (row) => {
          return (
            <>
              {row.hasbtnList?.includes(btnKeys.edit) && (
                <Button type="text" onClick={() => editClick(row)}>
                  编辑
                </Button>
              )}
              {row.hasbtnList?.includes(btnKeys.edit) &&
                !ignoreType.includes(row.processBusiType) && (
                  <Button type="text" onClick={() => showStepModel(row)}>
                    设置步骤
                  </Button>
                )}
              {row.hasbtnList.includes(btnKeys.delete) && (
                <Button
                  type="text"
                  style={{ color: 'red' }}
                  onClick={() => delItem({ processId: row.processId })}
                >
                  删除
                </Button>
              )}
            </>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'processName',
      type: 'Input',
      props: {
        placeholder: '流程名称',
      },
    },
    {
      name: 'processBusiType',
      type: 'Select',
      props: {
        placeholder: '业务类型',
        options: process.processTypeOptions,
      },
    },
    {
      name: 'processStatus',
      type: 'Select',
      props: {
        placeholder: '流程状态',
        options: [
          {
            label: '无效',
            value: 0,
          },
          {
            label: '有效',
            value: 1,
          },
        ],
      },
    },
  ];
  // 页面头部按钮
  const extra = [
    <Button
      type="primary"
      key={btnKeys.add}
      onClick={() => {
        setEditModalVisible(true);
      }}
    >
      新增流程
    </Button>,
    <Button
      type="primary"
      key={btnKeys.statusChange}
      onClick={() => {
        if (selectKey?.length == 0) {
          message.warn('请选择批量操作的客户！');
          return;
        }
        setStatusVisible(true);
        setStatusData(selectKey);
      }}
    >
      批量修改状态
    </Button>,
    // <Button
    //   type="primary"
    //   key="noKeys"
    //   onClick={() => {
    //     let { custCooperTimeDate, ...value } = tableRef.current.value;
    //     let payload = {
    //       beginCooperTime: value.beginCooperTime ?? '',
    //       custLevel: value.custLevel ?? '',
    //       custStatus: value.custStatus ?? '',
    //       customerName: value.customerName ?? '',
    //       endCooperTime: value.endCooperTime ?? '',
    //       // ...value,
    //     };
    //     if (custCooperTimeDate) {
    //       payload.beginCooperTime = moment(custCooperTimeDate[0])
    //         .startOf('d')
    //         .format('YYYY-MM-DD HH:mm:ss');
    //       payload.endCooperTime = moment(custCooperTimeDate[1])
    //         .endOf('d')
    //         .format('YYYY-MM-DD HH:mm:ss');
    //     }
    //     // let url = `${baseURL}${exportCustomerListUrl}${splitParamsFoGet(
    //     //   payload,
    //     // )}`;
    //     // downloadXlsx(url, '客户列表');
    //   }}
    // >
    //   导出
    // </Button>,
  ];

  const onExpand = (expanded, { processId, processBusiType }) => {
    if (expanded && !process.nodeList[processId]) {
      let extra = false;
      if (ignoreType.includes(processBusiType)) {
        extra = true;
      }
      return dispatch({
        type: 'process/getprocessnodelist',
        extra,
        payload: {
          processId,
        },
      });
    }
  };

  // 表格折叠后的表格
  const expandedRowRender = ({ processId }) => {
    if (loading['process/getprocessnodelist']) {
      return <PageLoading />;
    }
    const columns = [
      {
        dataIndex: 'processNodeName',
        title: '流程节点名称',
        render: (val, row) => val ?? row.value,
      },
      {
        dataIndex: 'processNodeCount',
        title: '流程节点人数',
        render: (val) => val ?? '-',
      },
      {
        dataIndex: 'processNodeMinAuditPassNum',
        title: '最小通过人数',
        render: (val) => val ?? '-',
      },
      {
        dataIndex: 'dealUserList',
        title: '处理人',
        render: (list) => list?.map((item) => item.value).join('，') ?? '-',
      },
    ];
    return (
      <Table
        rowKey="processNodeId"
        columns={columns}
        dataSource={process.nodeList[processId] ?? []}
        pagination={false}
      />
    );
  };

  /** 表格多选 */
  // const rowSelection = {
  //   selectedRowKeys: selectKey,
  //   onChange: (userIdList) => {
  //     setSelectKey(userIdList);
  //   },
  // };

  /** 表格拦截 */
  const onSubmit = (payload) => {
    payload.processBusiType ??= -1;
    payload.processStatus ??= -1;
  };

  /** 编辑框保存 */
  const editModalSave = (val) => {
    let payload = val;
    let hasEdit = false;
    if (editModalData?.processId) {
      hasEdit = true;
      payload.processId = editModalData?.processId;
    }
    dispatch({
      type: 'process/updateprocess',
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  /** 编辑框JSON */
  const edtaFormItem = [
    {
      name: 'processBusiType',
      label: '流程业务类型',
      type: 'Select',
      required: '请输入流程业务类型',
      props: {
        placeholder: '请输入流程业务类型',
        options: process.processTypeOptions,
        disabled: !!editModalData,
      },
    },
    {
      name: 'processName',
      label: '流程名称',
      type: 'Input',
      required: '请输入流程名称',
      props: {
        placeholder: '请输入流程名称',
      },
    },
    {
      name: 'processRemark',
      label: '流程备注',
      type: 'InputTextArea',
      props: {
        placeholder: '请输入流程备注',
      },
    },
  ];

  /** 步骤弹框JSON */
  const stepFormItemJson = [
    {
      name: 'processNodeList',
      render: () => <StepModel />,
    },
  ];

  /** 步骤保存 */
  const stepSave = (data) => {
    let payload = deepClone(data);
    let processNodeList = payload.processNodeList
      .map((item, i) => {
        if (!item.select) {
          return null;
        }
        item.processNodeSort = i + 1;
        item.processNodeCount = item.dealList?.length;
        item.required = undefined;
        item.roleKey = undefined;
        item.select = undefined;
        return item;
      })
      .filter(Boolean);
    payload.processNodeList = processNodeList;
    payload.processId = stepModelData?.processId;
    dispatch({
      type: 'process/setprocessnode',
      payload,
    }).then((res) => {
      setStepModelData(null);
      setStepModelVisible(false);
    });
  };
  return (
    <div>
      <TablePro
        params={{ processStatus: -1, processBusiType: -1 }}
        request="process/getprocesspage"
        searchCloumns={searchCloumns}
        rowKey={'processId'}
        data={process.processPageList}
        extra={extra}
        expandable={{ onExpand, expandedRowRender }}
        actionRef={(ref) => (tableRef.current = ref)}
        onSubmit={onSubmit}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        // curColumns
        {...columnsObj}
      ></TablePro>

      <ModalFormPro
        title={editModalData ? '编辑' : '新增'}
        width="600px"
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        ref={modalRef}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />

      <ModalFormPro
        title={'流程设置'}
        formProps={{
          labelCol: { span: 0 },
          wrapperCol: { span: 20 },
        }}
        width="800px"
        formData={stepModelData}
        visible={stepModelVisible}
        edtaFormItem={stepFormItemJson}
        onSave={stepSave}
        onCancel={() => {
          setStepModelData(null);
          setStepModelVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ process, loading, system }) => ({
  process,
  system,
  loading: loading.effects,
}))(ProcessList);
