import { Form, Row, Col, Button } from 'kenshin';
import { PureComponent, useState, Component, useMemo } from 'react';
import CollapseHoc from '../CollapseHoc';
import {
  findTree,
  dataListFind,
  getPrice,
} from '@/components/ServCategoryList';
import { timeDiff, deepClone } from '@/_util';
import ClassifyMenu from './ClassifyMenu';

@CollapseHoc
class itemDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      detailData: props.data || {},
    };
  }
  componentDidUpdate(props) {
    let resData = dataListFind([props.data], this.state.detailData?.key);
    this.setState({
      detailData: resData,
    });
  }
  render() {
    const {
      data,
      employeeAll,
      addItem,
      editItem,
      copyItem,
      onChange,
      disabled,
      setDataList,
      servcatgEasyList,
      // 自定义渲染编辑元素
      renderEdit: propsRenderEdit,
      extra,
    } = this.props;

    // 删除-查找父亲
    const findParent = (list, key) => {
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.childList) {
          let resData = findParent(item.childList, key);
          if (resData) return resData;
        }
        if (item.key == key) {
          return list;
        }
      }
    };
    // 给当前节点追加key,map
    const addKeyForNode = (propsData, codePrefix) => {
      // 必须深度克隆，复制的项可能含有childList
      let parent = { ...deepClone(propsData), proServCatgId: undefined };
      // 可能在1ms之内做完全部的复制，加上随机数避免key重复
      parent.key = +new Date() + parseInt(Math.random() * 1000);
      parent.servCatgCode = codePrefix;
      parent.childList = parent.childList?.map((item, i) =>
        addKeyForNode(item, `${parent.servCatgCode}.${i + 1}`),
      );
      return parent;
    };
    // 面板界面的复制
    const handleCopy = (propsData) => {
      setDataList((list) => {
        let parent = dataListFind(list, propsData.key, true);
        let data = addKeyForNode(
          propsData,
          `${parent.servCatgCode}.${parent.childList.length + 1}`,
        );
        parent.childList.push(data);
        copyItem?.([...list], data);
        return [...list];
      });
    };

    const renderEdit =
      propsRenderEdit ||
      ((data) => (
        <Row justify="end">
          <Button type="text" onClick={() => addItem(data)}>
            新增
          </Button>
          <Button type="text" onClick={() => editItem(data)}>
            编辑
          </Button>
          <Button type="text" onClick={() => handleCopy(data)}>
            复制
          </Button>
          <Button
            type="text"
            style={{ color: 'red' }}
            onClick={() => {
              this.props.setDataList((list) => {
                let parent = findParent(list, data.key);
                let i = parent.findIndex((item) => item.key == data.key);
                if (i != -1) parent.splice(i, 1);
                return [...list];
              });
              this.props.deleteItem?.(data);
            }}
          >
            删除
          </Button>
        </Row>
      ));
    const detailPlan = (data) => (
      <>
        <Row>
          <Col span={6}>
            <Form.Item label="服务类目">
              {servcatgEasyList.find((item) => item.id == data.servCatgId)
                ?.value || data.servCatgName}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="负责人">
              {findTree(employeeAll, data.servCatgLeader)?.label}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="参与团队">
              {data.addTeamMemberList
                ?.map((id) => findTree(employeeAll, id)?.label)
                .join(',')}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item label="单位">{data.servCatgUnit}</Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="数量">{data.servCatgCount}</Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="工时费">
              {data.servCatgHourOrginCost} / 天
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item label="总价">{data.servCatgOrginTotalAmount}</Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="优惠后总价">
              {data.servCatgDiscTotalAmount}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="所用工期/天（工时数）">
              {data.servCatgWorkPeriod} / 天
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item label="单价">{data.servCatgUnitPrice} / 元</Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="优惠后单价">
              {data.servCatgHourDiscCost}
            </Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="优惠折扣">
              {data.servCatgHourDisc * 100}%
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label="总优惠金额">{data.DiscountAmount}</Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="工作包别名">{data.servCatgName}</Form.Item>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item label="工作包周期">
              {data.servCatgTime && data.servCatgTime[0] && (
                <>
                  {moment(data.servCatgTime[0]).format('YYYY-MM-DD HH:mm:ss')}~
                  {moment(data.servCatgTime[1]).format('YYYY-MM-DD HH:mm:ss')}
                </>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={20}>功能详情描述：{data.servCatgRemark}</Col>
        </Row>
        {!disabled && !data.$top && renderEdit(data)}
        {extra?.(data)}
      </>
    );

    return (
      <Row wrap={false}>
        {data.childList?.length > 0 && (
          <Col flex="200px">
            <ClassifyMenu
              addItem={addItem}
              data={data.childList}
              onChange={(_key, data) => this.setState({ detailData: data })}
              setTreeData={(newChildren) => {
                setDataList((list) => {
                  let parent = dataListFind(list, data.key);
                  parent.childList = newChildren;
                  onChange?.([...list]);
                  return [...list];
                });
              }}
            />
          </Col>
        )}
        <Col flex="auto">
          {this.state.detailData &&
            detailPlan(
              {
                ...this.state.detailData,
                ...getPrice(this.state.detailData),
              },
              data.childList,
            )}
        </Col>
      </Row>
    );
  }
}

export default itemDetail;
