import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataAnalysePage from '@/components/DataAnalysePage';
import { useDispatch } from 'umi';
import { Tabs } from 'kenshin';
import { downloadForCollect } from '../../../_serveice/dataAnalyse';

const dataAnalyseCollect = () => {
  const dispatch = useDispatch();

  const dataRule = useCallback((arr) => {
    if (!arr) {
      return {};
    }
    const money = (type, step = 100) =>
      arr[type].map((item) => +(item.yaxis / step).toFixed(2));
    const xData = arr.collectList.map((item) => item.xaxis);
    return {
      xData,
      data: [
        {
          name: '已收款',
          type: 'bar',
          color: '#4389fc',
          data: money('collectList'),
        },
        {
          name: '累计收款',
          type: 'bar',
          color: '#41c797',
          data: money('totalCollectList'),
        },
        {
          name: '待收款',
          type: 'bar',
          color: '#fc8a8a',
          data: money('waitCollectList'),
        },
      ],
    };
  }, []);
  const dispatchType = {
    charts: 'getCollectCharts',
    list: 'getCollectList',
    listById: 'getCollectListById',
  };
  const dataType = {
    charts: 'collectChartData',
    list: 'collectList',
    listById: 'collectListById',
  };

  const columns = [
    {
      dataIndex: 'statTime',
      title: '统计时间',
      // render
    },
    {
      dataIndex: 'collectAmount',
      title: '已收款金额',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'waitCollectAmount',
      title: '待收款金额',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'totalCollectAmount',
      title: '累计收款金额',
      render: (val) => +(val / 100).toFixed(2),
    },
    {
      dataIndex: 'growthPercent',
      title: '环比增长率',
      render: (val) => `${val}%`,
    },
  ];
  const rowTableColums = [
    {
      dataIndex: 'contractName',
      title: '合同名称',
    },
    {
      dataIndex: 'contractNumber',
      title: '合同编号',
    },
    {
      dataIndex: 'collectAmount',
      title: '本次收款金额',
      render: (val) => +(val / 100 || 0).toFixed(2),
    },
    {
      dataIndex: 'beforeContractAmount',
      title: '收款前的合同金额',
      render: (val) => +(val / 100 || 0).toFixed(2),
    },
    {
      dataIndex: 'afterContractAmount',
      title: '收款后合同金额',
      render: (val) => +(val / 100 || 0).toFixed(2),
    },
    {
      dataIndex: 'contractAmount',
      title: '合同金额',
      render: (val) => +(val / 100 || 0).toFixed(2),
    },
    {
      dataIndex: 'contractTaxAmount',
      title: '合同税后金额',
      render: (val) => +(val / 100 || 0).toFixed(2),
    },
    {
      dataIndex: 'projectName',
      title: '项目名称',
    },
    {
      dataIndex: 'collectDate',
      title: '收款日期',
      render: (val) => moment(val).format('YYYY-MM-DD'),
    },
  ];

  // 标签栏当前key
  const [curCheckedKey, setCurCheckedKey] = useState('1');

  const checkedKeyObj = useMemo(
    () => ({
      1: 'statYearMonth',
      2: 'statYearQuarter',
      3: 'statYear',
    }),
    [],
  );

  const onExpand = ({ defaultTime }) => {
    return (expanded, props) => {
      if (expanded) {
        dispatch({
          type: `dataAnalyse/${dispatchType.listById}`,
          payload: {
            [checkedKeyObj[curCheckedKey]]: props.statTime,
            statType: curCheckedKey,
            statBeginTime: defaultTime[0].format('YYYY'),
            statEndTime: defaultTime[1].format('YYYY'),
          },
        });
      }
    };
  };

  const tabsArr = useMemo(
    () => [
      {
        label: '月度',
        value: '1',
      },
      {
        label: '季度',
        value: '2',
      },
      {
        label: '年度',
        value: '3',
      },
    ],
    [],
  );

  return (
    <>
      <Tabs defaultActiveKey="1" type="card" onChange={setCurCheckedKey}>
        {tabsArr.map((item) => (
          <Tabs.TabPane tab={item.label} key={item.value}></Tabs.TabPane>
        ))}
      </Tabs>
      <DataAnalysePage
        dataRule={dataRule}
        dataType={dataType}
        dispatchType={dispatchType}
        columns={columns}
        rowTableColums={rowTableColums}
        timeFormat="YYYY"
        tableKey={'statTime'}
        rowTableKey="proCollectId"
        // searchCloumns={searchCloumns}
        picker="year"
        defaultTime={[moment().subtract(1, 'y'), moment()]}
        onExpand={onExpand}
        otherParams={{
          statType: curCheckedKey,
        }}
        tableProps={{
          pagination: false,
        }}
        downloadUrl={downloadForCollect}
        downloadName="合同收款分析"
      />
    </>
  );
};

export default dataAnalyseCollect;
