import request, { mock } from '@/_util/require';
let version = 'v1';

// 服务类目-----------------
// 列表
// export async function getServcatgList(options) {
//   return request(`/${version}/waper/erp/pc/servcatg/getservicecategorypage`, {
//     method: 'POST',
//     data: options,
//   });
// }
// 服务类目树结构
export async function getServcatgList(params) {
  return request(`/${version}/waper/erp/pc/servcatg/getservicecategorytree`, {
    method: 'GET',
    params,
  });
}

// 新增
export async function addServcatg(options) {
  return request(`/${version}/waper/erp/pc/servcatg/addservicecategory`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editServcatg(options) {
  return request(`/${version}/waper/erp/pc/servcatg/updateservicecategory`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteServcatg(params) {
  return request(`/${version}/waper/erp/pc/servcatg/deleteservicecategory`, {
    method: 'GET',
    params,
  });
}

// 分页查询服务类目列表
export async function getServcatgDetailList(params) {
  return request(`/${version}/waper/erp/pc/servcatg/getservicecategorydetail`, {
    method: 'GET',
    params,
  });
}
// 查询简单服务类目列表
export async function getServcatgEasyList(params) {
  return request(`/${version}/waper/erp/pc/servcatg/getsimpservcategorylist`, {
    method: 'GET',
    params,
  });
}

// 服务类目end--------------------------------

// 项目管理-----------------
// 列表
export async function getProjectList(options) {
  return request(`/${version}/waper/erp/pc/project/getprojectpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addProject(options) {
  return request(`/${version}/waper/erp/pc/project/addproject`, {
    method: 'POST',
    data: options,
  });
}
// 编辑
export async function editProject(options) {
  // return request(`/${version}/waper/erp/pc/project/updateproject`, {
  return request(`/${version}/waper/erp/pc/project/addupdateproject`, {
    method: 'POST',
    data: options,
  });
}
// 查询项目服务类目列表
export async function getProservcatgList(params) {
  return request(`/${version}/waper/erp/pc/project/getproservcatglist`, {
    method: 'GET',
    params,
  });
}
// 修改项目服务类目排序
export async function updataServcat(options) {
  return request(`/${version}/waper/erp/pc/project/addupdateproservcatg`, {
    method: 'POST',
    data: options,
  });
}
// 删除项目服务类目
export async function delServcat(options) {
  return request(`/${version}/waper/erp/pc/project/deleteproservcatg`, {
    method: 'POST',
    data: options,
  });
}
// 删除项目服务类目
export async function sortServcat(options) {
  return request(`/${version}/waper/erp/pc/project/updateproservcatgsort`, {
    method: 'POST',
    data: options,
  });
}

// 删除
export async function deleteProject(params) {
  return request(`/${version}/waper/erp/pc/project/deleteproject`, {
    method: 'GET',
    params,
  });
}

// 删除
export async function getProjectDetail(params) {
  return request(`/${version}/waper/erp/pc/project/getprojectdetail`, {
    method: 'GET',
    params,
  });
}

// 删除校验
export async function delServcatgRule(options) {
  return request(`/${version}/waper/erp/pc/project/projectservcatgdelvalid`, {
    method: 'POST',
    data: options,
  });
}

// 删除团队成员校验
export async function delTeamRule(options) {
  return request(
    `/${version}/waper/erp/pc/project/projectservcatgteammemberdelvalid`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 新增团队成员校验
export async function addTeamRule(options) {
  return request(
    `/${version}/waper/erp/pc/project/addpropreallocationmemberdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 重启/停止项目
export async function restProject(options) {
  return request(`/${version}/waper/erp/pc/project/restartstopproject`, {
    method: 'POST',
    data: options,
  });
}

// 查询项目审核流程
export async function getAuditProgress(params) {
  return request(
    `/${version}/waper/erp/pc/project/auditprogress/getauditprogresslist`,
    {
      method: 'GET',
      params,
    },
  );
}
// 查询项目审核流程
export async function getProjectLogList(params) {
  return request(
    `/${version}/waper/erp/pc/project/operationlog/getprooperationloglist`,
    {
      method: 'GET',
      params,
    },
  );
}

// 查询项目审核流程
export async function getProjectLById(params) {
  return request(
    `/${version}/waper/erp/pc/project/getsimpleprojectlistbycontractid`,
    {
      method: 'GET',
      params,
    },
  );
}
// 分页查询项目上报列表
export async function projectDetailLog(options) {
  return request(`/${version}/waper/erp/pc/project/getprojectrptlistpage`, {
    method: 'POST',
    data: options,
  });
}
// 项目管理end--------------------------------

// 工作包管理
export async function getPackageMangerList(options) {
  return request(
    `/${version}/waper/erp/pc/project/workpackage/getprojectworkpackagepage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 查询项目审核流程
export async function delPackageManger(options) {
  return request(
    `/${version}/waper/erp/pc/project/workpackage/closeprojectworkpackage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 查询项目审核流程
export async function addProprealLocationMemberData(options) {
  console.log('options = ', options);
  console.log('options = ', JSON.stringify(options));
  return request(
    `/${version}/waper/erp/pc/project/addpropreallocationmemberdata`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 工作包管理end--------------------------------

// 任务管理
export async function getTaskPage(options) {
  return request(`/${version}/waper/erp/pc/task/gettaskpage`, {
    method: 'POST',
    data: options,
  });
}

// 新增
export async function addTask(data) {
  return request(`/${version}/waper/erp/pc/task/addtask`, {
    method: 'POST',
    data,
  });
}
// 修改
export async function updateTask(data) {
  return request(`/${version}/waper/erp/pc/task/updatetask`, {
    method: 'POST',
    data,
  });
}
// 删除
export async function deleteTask(params) {
  return request(`/${version}/waper/erp/pc/task/deletetask`, {
    method: 'GET',
    params,
  });
}
// 修改任务状态
export async function taskStatus(data) {
  return request(`/${version}/waper/erp/pc/task/taskstatuschange`, {
    method: 'POST',
    data,
  });
}
// 查询简单项目列表
export async function getSimpProjectList(params) {
  return request(`/${version}/waper/erp/pc/project/getsimpprojectlist`, {
    method: 'GET',
    params,
  });
}
// 查询用户简单项目列表
export async function getusersimpprojectlist(params) {
  return request(`/${version}/waper/erp/pc/project/getusersimpprojectlist`, {
    method: 'GET',
    params,
  });
}
// 查询项目服务类目树
export async function getProServcatgTree(params) {
  return request(`/${version}/waper/erp/pc/project/getproservcatgtree`, {
    method: 'GET',
    params,
  });
}

export const exportTaskList = `/${version}/waper/erp/pc/task/exporttasklist`;
// 任务管理end--------------------------------

// 项目列表导出
export const exportProjectList = `/${version}/waper/erp/pc/project/exportprojectlist`;

// 导出项目上报列表
export const exportprojectrptlist = `/${version}/waper/erp/pc/project/exportprojectrptlist`;

// 修改
export async function getEmpWorkList(data) {
  return request(
    `/${version}/waper/erp/pc/project/getempproworkpkworkhourlist`,
    {
      method: 'POST',
      data,
    },
  );
}

/** 查询项目工作包统计列表 */
export async function getProServCatgstatList(params) {
  return request(`/${version}/waper/erp/pc/project/getproservcatgstatlist`, {
    method: 'GET',
    params,
  });
}

/** 导出工作包统计列表 */
export const exportproservcatgstat = `/{version}/waper/erp/pc/project/exportproservcatgstat`;

/** 查询项目工作包统计列表 */
export async function getsimptasklist(params) {
  return request(`/${version}/waper/erp/pc/task/getsimptasklist`, {
    method: 'GET',
    params,
  });
}

// 分页查询关闭工作包列表
export async function getworkpkgclosepage(options) {
  return request(
    `/${version}/waper/erp/pc/project/workpackage/getworkpkgclosepage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 删除关闭工作包列表
export async function deleteworkpkgcloselist(params) {
  return request(
    `/${version}/waper/erp/pc/project/workpackage/deleteworkpkgcloselist`,
    {
      method: 'GET',
      params,
    },
  );
}

// 修改关闭的工作包
export async function updateworkpkgclose(options) {
  return request(
    `/${version}/waper/erp/pc/project/workpackage/updateworkpkgclose`,
    {
      method: 'POST',
      data: options,
    },
  );
}
