import request from '@/_util/require';
let version = 'v1';
// 分页查询流程列表
export async function getprocesspage(options) {
  return request(`/${version}/waper/erp/pc/process/getprocesspage`, {
    method: 'POST',
    data: options,
  });
}

// 新增流程
export async function addprocess(options) {
  return request(`/${version}/waper/erp/pc/process/addprocess`, {
    method: 'POST',
    data: options,
  });
}

// 删除流程
export async function deleteprocess(params) {
  return request(`/${version}/waper/erp/pc/process/deleteprocess`, {
    method: 'GET',
    params,
  });
}

// 修改流程
export async function updateprocess(options) {
  return request(`/${version}/waper/erp/pc/process/updateprocess`, {
    method: 'POST',
    data: options,
  });
}

// 设置流程节点
export async function setprocessnode(options) {
  return request(`/${version}/waper/erp/pc/process/setprocessnode`, {
    method: 'POST',
    data: options,
  });
}

// 设置流程状态
export async function setprocessstatus(options) {
  return request(`/${version}/waper/erp/pc/process/setprocessstatus`, {
    method: 'POST',
    data: options,
  });
}

// 查询指定角色的用户列表
export async function getemplistbyrolekey(params) {
  return request(`/${version}/waper/erp/pc/process/getemplistbyrolekey`, {
    method: 'GET',
    params,
  });
}

// 查询流程节点列表
export async function getprocessnodelist(params) {
  return request(`/${version}/waper/erp/pc/process/getprocessnodelist`, {
    method: 'GET',
    params,
  });
}
// 查询流程节点列表（OSA、周计划）
export async function getosaweekplanprocessnodelist(params) {
  return request(
    `/${version}/waper/erp/pc/process/getosaweekplanprocessnodelist`,
    {
      method: 'GET',
      params,
    },
  );
}

// 查询流程模板
export async function getprocesstemplate(params) {
  return request(`/${version}/waper/erp/pc/process/getprocesstemplate`, {
    method: 'GET',
    params,
  });
}
// 查询流程模板
export async function getsimpprocesslist(params) {
  return request(`/${version}/waper/erp/pc/process/getsimpprocesslist`, {
    method: 'GET',
    params,
  });
}

// (首次)新增流程申请
export async function firstaddprocessapply(options) {
  return request(
    `/${version}/waper/erp/pc/process/apply/firstaddprocessapply`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// (首次)新增流程申请
export async function firstaddprocessapplycommit(options) {
  return request(
    `/${version}/waper/erp/pc/process/apply/firstaddprocessapplycommit`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 从待办中再次修改流程申请信息
export async function updateprocessapply(options) {
  return request(`/${version}/waper/erp/pc/process/apply/updateprocessapply`, {
    method: 'POST',
    data: options,
  });
}
// 从待办中再次修改提交流程申请信息
export async function updatecommitprocessapply(options) {
  return request(
    `/${version}/waper/erp/pc/process/apply/updatecommitprocessapply`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 分页查询用户待办列表
export async function gettodoitempage(options) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/gettodoitempage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 分页查询用户已办列表
export async function getcompleteitempage(options) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/getcompleteitempage`,
    {
      method: 'POST',
      data: options,
    },
  );
}
// 查询已办事项的变更列表
export async function getcompletechangelist(params) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/getcompletechangelist`,
    {
      method: 'GET',
      params,
    },
  );
}

// 审核
export async function auditprocessapply(options) {
  return request(`/${version}/waper/erp/pc/process/apply/auditprocessapply`, {
    method: 'POST',
    data: options,
  });
}

// 分页查询用户流程申请列表
export async function getprocessapplypage(options) {
  return request(`/${version}/waper/erp/pc/process/apply/getprocessapplypage`, {
    method: 'POST',
    data: options,
  });
}

// 查询流程申请的详细数据
export async function getprocessapplydetaildata(params) {
  return request(
    `/${version}/waper/erp/pc/process/apply/getprocessapplydetaildata`,
    {
      method: 'GET',
      params,
    },
  );
}

// 分页查询所有流程申请列表
export async function getallprocessapplypage(options) {
  return request(
    `/${version}/waper/erp/pc/process/apply/getallprocessapplypage`,
    {
      method: 'POST',
      data: options,
    },
  );
}

// 流程申请校验
export async function processapplyvalide(options) {
  return request(`/${version}/waper/erp/pc/process/apply/processapplyvalide`, {
    method: 'POST',
    data: options,
  });
}

// 关闭流程申请
export async function closeprocessapply(params) {
  return request(`/${version}/waper/erp/pc/process/apply/closeprocessapply`, {
    method: 'GET',
    params,
  });
}

// 撤销已办
export async function completerevoke(params) {
  return request(
    `/${version}/waper/erp/pc/process/todocomplete/completerevoke`,
    {
      method: 'GET',
      params,
    },
  );
}
// 撤销已办
export async function getcompleteprocess(params) {
  return request(`/${version}/waper/erp/pc/process/apply/getcompleteprocess`, {
    method: 'GET',
    params,
  });
}
// 查询所有的流程申请类型列表
export async function getprocessapplytypelist(params) {
  return request(
    `/${version}/waper/erp/pc/process/apply/getprocessapplytypelist`,
    {
      method: 'GET',
      params,
    },
  );
}
