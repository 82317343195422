import { sortConfig } from '.';

// 获取按钮权限
export const getHasbtnList = () => {
  let curMenuId = localStorage.getItem('curMenuId'); // 当前菜单对应的菜单id
  let buttonPermissionList =
    JSON.parse(localStorage.getItem('buttonPermissionList')) || []; // 服务端返回的按钮权限列表
  let list =
    buttonPermissionList
      .find((item) => item.menuId == curMenuId)
      ?.permissionFlagList.map((item) => item.split(':')[1]) || []; // 找到对应菜单id对应的按钮权限
  return list;
};

/** 获取处理后的cloumns */
export const getColumnsPerm = (columns, autoColumns) => {
  let tableHeadPermissionList =
    JSON.parse(localStorage.getItem('tableHeadPermissionList')) || []; // 服务端返回的表头权限
  let curMenuId = localStorage.getItem('curMenuId');
  let hasbtnList = getHasbtnList();

  const getOptions = (options) => {
    let render = (row) => {
      let resRow = { ...row, hasbtnList };
      Object.defineProperty(resRow, 'hasbtnList', {
        enumerable: false,
        value: hasbtnList,
      });
      return options.render(resRow);
    };
    return {
      ...options,
      render,
    };
  };

  // 获取权限配置后的Colums
  let resColumns =
    tableHeadPermissionList
      .find((item) => item.menuId == curMenuId)
      ?.permissionFlagList // .filter((item) => {
      //   if (
      //     item.split(':')[2] == 2 &&
      //     // moment() > moment('2022-11-02 11:26:00')
      //     moment() > moment(item.split(":")[3])
      //   ) {
      //     return false;
      //   }
      //   return true;
      // })
      ?.map((item) => {
        const [title, dataIndex] = item.split(':');
        let renderItem = columns.find(
          (item) => (item.dataIndex || item.key) == dataIndex,
        );
        if (renderItem) {
          //自定义渲染
          return renderItem;
        }
        if (autoColumns) {
          //
          return {
            title,
            dataIndex,
          };
        }
        return null;
      })
      .filter(Boolean) || [];

  if (columns.find((item) => item.key == 'options')) {
    let optionsItem = columns.find((item) => item.key == 'options');
    resColumns.push(getOptions(optionsItem));
  }
  // return resColumns;
  return resColumns;
};

export const getTableColums = ({
  curColumns,
  columns,
  sortChange,
  sortFieldsRef,
  autoColumns,
}) => {
  return sortConfig(
    curColumns ? columns : getColumnsPerm(columns, autoColumns),
    sortChange,
    sortFieldsRef.current,
  );
};

export const createCustomColumsChange = ({ getColums, setTableColumns }) => {
  return (key) => {
    let columns = getColums
      ?.map((item) => [item, item.children])
      .flat(2) // 把children也输出
      .filter(Boolean);
    //  定制列触发修改
    const allkey = columns.map((item) => item.key || item.dataIndex);
    let newColumns = getColums
      .map((item) => {
        let newObj = item;
        if (item.children) {
          newObj.children = item.children.filter((item) =>
            key.includes(item.key || item.dataIndex),
          );
        }
        if (key.includes(item.key || item.dataIndex)) {
          return newObj;
        }
        return null;
      })
      .filter(Boolean);
    // 根据key（选中的列）设置columns
    setTableColumns(newColumns);
    // 拿到对应菜单id和当前菜单的非选择列
    let tableProCloumnsStr = localStorage.getItem('tableProCloumns');
    let curMenuId = localStorage.getItem('curMenuId');
    const tableProCloumns = tableProCloumnsStr
      ? JSON.parse(tableProCloumnsStr)
      : {};
    // 每次设置都存本地存储，排除法（只保存没选择的列，避免新菜单添加的列每次都要过来手动选中）
    tableProCloumns[curMenuId] = Array.from(
      new Set([...(tableProCloumns?.[curMenuId] ?? []), ...allkey]),
    ).filter((item) => {
      return !key.includes(item);
    });

    localStorage.setItem('tableProCloumns', JSON.stringify(tableProCloumns));
  };
};
