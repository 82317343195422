import {
  getCustomerList,
  customerAdd,
  customerSatus,
  customerEdit,
  getAllCustomer,
  getEasyCustomer,
  getProjectLByCustId,
  getCustomerTagList,
  getcustcasepage,
  addupdatecustcase,
  getcaseindustrylist,
  getcasetaglist,
  puborcancelcustcase,
  deletecustcaselist,
} from '@/_serveice/customer/list/index';

export default {
  namespace: 'customer',

  state: {
    customerList: {},
    customerListSearchForm: {},

    editData: {},

    allCustomer: [],

    easyCustomer: [],
    projectLByCustIdList: {},
    customerTagList: [],
    caseList: {},
    caseListSearchForm: {},
    caseIndustryList: [],
    caseTagList: [],
  },

  effects: {
    // 页面列表--------------------------
    *getCustomerList({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ customer }) => customer.customerListSearchForm,
      );
      let customerListSearchForm = { ...searchData, ...payload };
      if (reset) {
        customerListSearchForm = payload;
      }
      const res = yield call(getCustomerList, customerListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            customerList: res.data,
            customerListSearchForm,
          },
        });
      }
    },
    *customerEdit({ payload, hasEdit }, { put, call }) {
      const res = yield call(hasEdit ? customerEdit : customerAdd, payload);
      if (res?.success) {
        yield put({
          type: 'getCustomerList',
        });
        return true;
      }
    },
    *customerSatus({ payload }, { put, call }) {
      const res = yield call(customerSatus, payload);
      if (res?.success) {
        yield put({
          type: 'getCustomerList',
        });
        return true;
      }
    },
    *getAllCustomer({ payload }, { put, call }) {
      const res = yield call(getAllCustomer, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            allCustomer: res.data,
          },
        });
        return res.data;
      }
    },
    *getEasyCustomer({ payload }, { put, call }) {
      const res = yield call(getEasyCustomer, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            easyCustomer: res.data,
          },
        });
        return res.data;
      }
    },

    *getProjectLByCustId({ payload }, { put, call, select }) {
      const projectLByCustIdList = yield select(
        ({ customer }) => customer.projectLByCustIdList,
      );
      const res = yield call(getProjectLByCustId, payload);
      projectLByCustIdList[payload.custId] = res.data;
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            projectLByCustIdList: { ...projectLByCustIdList },
          },
        });
        return res;
      }
    },
    *getCustomerTagList({ payload }, { put, call }) {
      const res = yield call(getCustomerTagList, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            customerTagList: res.data,
          },
        });
        return res.data;
      }
    },
    // 页面列表---------------end-----------
    // 客户案例---------------start
    *getcustcasepage({ payload, reset }, { put, call, select }) {
      const searchData = yield select(
        ({ customer }) => customer.caseListSearchForm,
      );
      let caseListSearchForm = { ...searchData, ...payload };
      if (reset) {
        caseListSearchForm = payload;
      }
      const res = yield call(getcustcasepage, caseListSearchForm);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            caseList: res.data,
            caseListSearchForm,
          },
        });
      }
    },
    *addupdatecustcase({ payload }, { put, call }) {
      const res = yield call(addupdatecustcase, payload);
      if (res?.success) {
        yield put({
          type: 'getcustcasepage',
        });
        return true;
      }
    },
    *deletecustcaselist({ payload }, { put, call }) {
      const res = yield call(deletecustcaselist, payload);
      if (res?.success) {
        yield put({
          type: 'getcustcasepage',
        });
        return true;
      }
    },
    *puborcancelcustcase({ payload }, { put, call }) {
      const res = yield call(puborcancelcustcase, payload);
      if (res?.success) {
        yield put({
          type: 'getcustcasepage',
        });
        return true;
      }
    },
    *getcaseindustrylist({ payload }, { put, call }) {
      const res = yield call(getcaseindustrylist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            caseIndustryList: res.data,
          },
        });
        return res.data;
      }
    },
    *getcasetaglist({ payload }, { put, call }) {
      const res = yield call(getcasetaglist, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            caseTagList: res.data,
          },
        });
        return res.data;
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
