import { Tooltip } from 'kenshin';
import styles from './index.less';

const StatisticsCard = ({
  size = 'small',
  title,
  desc,
  num,
  icon,
  type = 'primary',
  unit = '天',
  tooltipTitle,
}) => {
  let content = (
    <div className={`${styles.card} statistics-card ${size}`}>
      <div className="icon">
        <img src={icon} />
      </div>
      <div className="statistics-content">
        <div className="title">{title}</div>
        <div className="msg">
          <span className={`num ${type}`}>{num}</span>
          <span className="unit">{unit}</span>
          <span className="desc">{desc}</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {tooltipTitle && (
        <Tooltip
          arrowPointAtCenter
          // visible={true}
          title={tooltipTitle}
          overlayStyle={{
            color: '#333',
          }}
          color="#fff"
        >
          {content}
        </Tooltip>
      )}
      {!tooltipTitle && content}
    </>
  );
};

export default StatisticsCard;
