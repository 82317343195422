import Jsonform from '@/components/JsonForm';
import { Collapse, InputNumber, Checkbox, Button, Input } from 'kenshin';
import { useEffect, useRef, useState } from 'react';
import Select from '@/_util/components/Select';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

const osaAuditTypeKey = {
  1: '被考核人',
  2: '被考核人直属上级',
  3: '被考核人业务负责人',
  5: '人事预审核',
  4: '人事负责人',
};

const defaultList = () => ({
  score: 0,
  osaAuditName: '',
  osaAuditType: -1,
  permissions: false,
});
const Info = ({ defaultValue }) => {
  const [graderList, setGraderList] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      setGraderList(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      {Array.isArray(graderList) &&
        graderList?.map((item, index) => (
          <div
            key={index}
            className="flex-al-center"
            style={{
              marginBottom: 20,
              // paddingLeft: 180,
            }}
          >
            <div
              style={{
                display: 'inline-block',
                width: '120px',
                // textAlign: 'right',
              }}
            >
              {osaAuditTypeKey[item.osaAuditType]}：
            </div>
            {/* <Select
            placeholder="请选择评分人"
            options={options}
            style={{ width: 200, marginRight: 20 }}
          /> */}
            <Input
              disabled
              placeholder="请输入评分人"
              value={item.osaAuditName}
              style={{ width: 200, marginRight: 20 }}
            />
            {/* {index == 0 && (
            <Button type="primary" onClick={add}>
              添加评分人
            </Button>
          )}
          {index != 0 && (
            <Button type="danger" onClick={() => deleteItem(item)}>
              删除
            </Button>
          )} */}
            {/* <PlusOutlined /> */}
          </div>
        ))}
    </>
  );
};

export default Info;
