// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from 'D:/dev/workspace/wdp-pc/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.js').default,
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": require('@/pages/404.tsx').default,
        "title": "404"
      },
      {
        "path": "/admin/lowCode/projectManger",
        "exact": true,
        "component": require('@/pages/admin/lowCode/projectManger/index.js').default
      },
      {
        "path": "/admin/lowCode/projectPage",
        "exact": true,
        "component": require('@/pages/admin/lowCode/projectPage/index.js').default
      },
      {
        "path": "/admin/lowCode/template",
        "exact": true,
        "component": require('@/pages/admin/lowCode/template/index.js').default
      },
      {
        "path": "/admin/lowCode/temptype",
        "exact": true,
        "component": require('@/pages/admin/lowCode/temptype/index.js').default
      },
      {
        "path": "/admin/lowCodeTest/page1",
        "exact": true,
        "component": require('@/pages/admin/lowCodeTest/page1/index.js').default
      },
      {
        "path": "/admin/system/dict",
        "exact": true,
        "component": require('@/pages/admin/system/dict/index.js').default
      },
      {
        "path": "/admin/system/dict-type",
        "exact": true,
        "component": require('@/pages/admin/system/dict-type/index.js').default
      },
      {
        "path": "/admin/system/loginLogList",
        "exact": true,
        "component": require('@/pages/admin/system/loginLogList/index.js').default
      },
      {
        "path": "/admin/system/logList",
        "exact": true,
        "component": require('@/pages/admin/system/logList/index.js').default
      },
      {
        "path": "/admin/system/lowCodeIframe",
        "exact": true,
        "component": require('@/pages/admin/system/lowCodeIframe/index.js').default
      },
      {
        "path": "/admin/system/organizational",
        "exact": true,
        "component": require('@/pages/admin/system/organizational/index.js').default
      },
      {
        "path": "/admin/system/permission",
        "exact": true,
        "component": require('@/pages/admin/system/permission/index.js').default
      },
      {
        "path": "/admin/system/role",
        "exact": true,
        "component": require('@/pages/admin/system/role/index.js').default
      },
      {
        "path": "/admin/system/tagManage",
        "exact": true,
        "component": require('@/pages/admin/system/tagManage/index.js').default
      },
      {
        "path": "/attendance/alllist/addApply",
        "exact": true,
        "component": require('@/pages/attendance/alllist/addApply.js').default
      },
      {
        "path": "/attendance/alllist",
        "exact": true,
        "component": require('@/pages/attendance/alllist/index.js').default
      },
      {
        "path": "/attendance/apply/addApply",
        "exact": true,
        "component": require('@/pages/attendance/apply/addApply.js').default
      },
      {
        "path": "/attendance/apply/applyDetail",
        "exact": true,
        "component": require('@/pages/attendance/apply/applyDetail.js').default
      },
      {
        "path": "/attendance/apply",
        "exact": true,
        "component": require('@/pages/attendance/apply/index.js').default
      },
      {
        "path": "/attendance/exemption",
        "exact": true,
        "component": require('@/pages/attendance/exemption/index.js').default
      },
      {
        "path": "/attendance/list",
        "exact": true,
        "component": require('@/pages/attendance/list/index.js').default
      },
      {
        "path": "/attendance/list/outClockTable",
        "exact": true,
        "component": require('@/pages/attendance/list/outClockTable.js').default
      },
      {
        "path": "/attendance/statistics/attendance",
        "exact": true,
        "component": require('@/pages/attendance/statistics/attendance/index.js').default
      },
      {
        "path": "/attendance/statistics/personal",
        "exact": true,
        "component": require('@/pages/attendance/statistics/personal/index.js').default
      },
      {
        "path": "/attendance/vacationbalance/detail",
        "exact": true,
        "component": require('@/pages/attendance/vacationbalance/detail.js').default
      },
      {
        "path": "/attendance/vacationbalance",
        "exact": true,
        "component": require('@/pages/attendance/vacationbalance/index.js').default
      },
      {
        "path": "/attendance/vacationmanage",
        "exact": true,
        "component": require('@/pages/attendance/vacationmanage/index.js').default
      },
      {
        "path": "/attendance/vacationmanage/updatevacation",
        "exact": true,
        "component": require('@/pages/attendance/vacationmanage/updatevacation.js').default
      },
      {
        "path": "/contract/collection/columns",
        "exact": true,
        "component": require('@/pages/contract/collection/columns.js').default
      },
      {
        "path": "/contract/collection",
        "exact": true,
        "component": require('@/pages/contract/collection/index.js').default
      },
      {
        "path": "/contract/list",
        "exact": true,
        "component": require('@/pages/contract/list/index.js').default
      },
      {
        "path": "/contract/list/util",
        "exact": true,
        "component": require('@/pages/contract/list/util.js').default
      },
      {
        "path": "/customer/caselist",
        "exact": true,
        "component": require('@/pages/customer/caselist/index.js').default
      },
      {
        "path": "/customer/caselist/test",
        "exact": true,
        "component": require('@/pages/customer/caselist/test.js').default
      },
      {
        "path": "/customer/list",
        "exact": true,
        "component": require('@/pages/customer/list/index.js').default
      },
      {
        "path": "/customer/newCustomer",
        "exact": true,
        "component": require('@/pages/customer/newCustomer/index.js').default
      },
      {
        "path": "/dataAnalyse/allreported",
        "exact": true,
        "component": require('@/pages/dataAnalyse/allreported/index.js').default
      },
      {
        "path": "/dataAnalyse/allreported/reporteDetail",
        "exact": true,
        "component": require('@/pages/dataAnalyse/allreported/reporteDetail/index.js').default
      },
      {
        "path": "/dataAnalyse/attendanceAnalyse",
        "exact": true,
        "component": require('@/pages/dataAnalyse/attendanceAnalyse/index.js').default
      },
      {
        "path": "/dataAnalyse/attendanceAnalyse/search",
        "exact": true,
        "component": require('@/pages/dataAnalyse/attendanceAnalyse/search.js').default
      },
      {
        "path": "/dataAnalyse/collection",
        "exact": true,
        "component": require('@/pages/dataAnalyse/collection/index.js').default
      },
      {
        "path": "/dataAnalyse/cost",
        "exact": true,
        "component": require('@/pages/dataAnalyse/cost/index.js').default
      },
      {
        "path": "/dataAnalyse/customer",
        "exact": true,
        "component": require('@/pages/dataAnalyse/customer/index.js').default
      },
      {
        "path": "/dataAnalyse/osaAnalyse",
        "exact": true,
        "component": require('@/pages/dataAnalyse/osaAnalyse/index.js').default
      },
      {
        "path": "/dataAnalyse/osaAnalyse/search",
        "exact": true,
        "component": require('@/pages/dataAnalyse/osaAnalyse/search.js').default
      },
      {
        "path": "/dataAnalyse/project",
        "exact": true,
        "component": require('@/pages/dataAnalyse/project/index.js').default
      },
      {
        "path": "/dataAnalyse/reported",
        "exact": true,
        "component": require('@/pages/dataAnalyse/reported/index.js').default
      },
      {
        "path": "/dataAnalyse/reported/reporteDetail",
        "exact": true,
        "component": require('@/pages/dataAnalyse/reported/reporteDetail/index.js').default
      },
      {
        "path": "/employee/salaryManger",
        "exact": true,
        "component": require('@/pages/employee/salaryManger/index.js').default
      },
      {
        "path": "/etl/AddProject",
        "exact": true,
        "component": require('@/pages/etl/AddProject.js').default
      },
      {
        "path": "/etl/cloumnsData",
        "exact": true,
        "component": require('@/pages/etl/cloumnsData.js').default
      },
      {
        "path": "/etl",
        "exact": true,
        "component": require('@/pages/etl/index.js').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.js').default,
        "title": "财务"
      },
      {
        "path": "/openai",
        "exact": true,
        "component": require('@/pages/openai/index.js').default
      },
      {
        "path": "/performance/assessment/levelSetting",
        "exact": true,
        "component": require('@/pages/performance/assessment/levelSetting/index.js').default
      },
      {
        "path": "/performance/assessment/OSA",
        "exact": true,
        "component": require('@/pages/performance/assessment/OSA/index.js').default
      },
      {
        "path": "/performance/assessment/OSA/OSADetail",
        "exact": true,
        "component": require('@/pages/performance/assessment/OSA/OSADetail/index.js').default
      },
      {
        "path": "/performance/assessment/OSA/OSADetail/indicators",
        "exact": true,
        "component": require('@/pages/performance/assessment/OSA/OSADetail/indicators.js').default
      },
      {
        "path": "/performance/assessment/OSA/OSAsetting",
        "exact": true,
        "component": require('@/pages/performance/assessment/OSA/OSAsetting/index.js').default
      },
      {
        "path": "/performance/assessment/scoreSetting",
        "exact": true,
        "component": require('@/pages/performance/assessment/scoreSetting/index.js').default
      },
      {
        "path": "/performance/assessment/weeklyPlan",
        "exact": true,
        "component": require('@/pages/performance/assessment/weeklyPlan/index.js').default
      },
      {
        "path": "/performance/assessmentList",
        "exact": true,
        "component": require('@/pages/performance/assessmentList/index.js').default
      },
      {
        "path": "/performance/indicators",
        "exact": true,
        "component": require('@/pages/performance/indicators/index.js').default
      },
      {
        "path": "/performance/manage",
        "exact": true,
        "component": require('@/pages/performance/manage/index.js').default
      },
      {
        "path": "/performance/publicTemplate/createTemplate",
        "exact": true,
        "component": require('@/pages/performance/publicTemplate/createTemplate/index.js').default
      },
      {
        "path": "/performance/publicTemplate",
        "exact": true,
        "component": require('@/pages/performance/publicTemplate/index.js').default
      },
      {
        "path": "/performance/selfosa/changosa",
        "exact": true,
        "component": require('@/pages/performance/selfosa/changosa/index.js').default
      },
      {
        "path": "/performance/selfosa",
        "exact": true,
        "component": require('@/pages/performance/selfosa/index.js').default
      },
      {
        "path": "/performance/selfosa/OSAEditAudit",
        "exact": true,
        "component": require('@/pages/performance/selfosa/OSAEditAudit/index.js').default
      },
      {
        "path": "/performance/subAssessment",
        "exact": true,
        "component": require('@/pages/performance/subAssessment/index.js').default
      },
      {
        "path": "/performance/template/createTemplate",
        "exact": true,
        "component": require('@/pages/performance/template/createTemplate/index.js').default
      },
      {
        "path": "/performance/template",
        "exact": true,
        "component": require('@/pages/performance/template/index.js').default
      },
      {
        "path": "/performance/weekTimeConfig",
        "exact": true,
        "component": require('@/pages/performance/weekTimeConfig/index.js').default
      },
      {
        "path": "/process/applylist",
        "exact": true,
        "component": require('@/pages/process/applylist/index.js').default
      },
      {
        "path": "/process/businessLog/ChangeRecord",
        "exact": true,
        "component": require('@/pages/process/businessLog/ChangeRecord/index.js').default
      },
      {
        "path": "/process/businessLog",
        "exact": true,
        "component": require('@/pages/process/businessLog/index.js').default
      },
      {
        "path": "/process/createProcess/changePage",
        "exact": true,
        "component": require('@/pages/process/createProcess/changePage/index.js').default
      },
      {
        "path": "/process/createProcess/detail",
        "exact": true,
        "component": require('@/pages/process/createProcess/detail/index.js').default
      },
      {
        "path": "/process/createProcess",
        "exact": true,
        "component": require('@/pages/process/createProcess/index.js').default
      },
      {
        "path": "/process/pendingBusiness/auditPage",
        "exact": true,
        "component": require('@/pages/process/pendingBusiness/auditPage/index.js').default
      },
      {
        "path": "/process/pendingBusiness",
        "exact": true,
        "component": require('@/pages/process/pendingBusiness/index.js').default
      },
      {
        "path": "/process/processList",
        "exact": true,
        "component": require('@/pages/process/processList/index.js').default
      },
      {
        "path": "/project/list/AddProject",
        "exact": true,
        "component": require('@/pages/project/list/AddProject.js').default
      },
      {
        "path": "/project/list/cloumnsData",
        "exact": true,
        "component": require('@/pages/project/list/cloumnsData.js').default
      },
      {
        "path": "/project/list/empWorkHourList",
        "exact": true,
        "component": require('@/pages/project/list/empWorkHourList/index.js').default
      },
      {
        "path": "/project/list",
        "exact": true,
        "component": require('@/pages/project/list/index.js').default
      },
      {
        "path": "/project/list/projDetail",
        "exact": true,
        "component": require('@/pages/project/list/projDetail/index.js').default
      },
      {
        "path": "/project/packageManger",
        "exact": true,
        "component": require('@/pages/project/packageManger/index.js').default
      },
      {
        "path": "/project/servCategory",
        "exact": true,
        "component": require('@/pages/project/servCategory/index.js').default
      },
      {
        "path": "/project/taskManage/cloumnsData",
        "exact": true,
        "component": require('@/pages/project/taskManage/cloumnsData.js').default
      },
      {
        "path": "/project/taskManage",
        "exact": true,
        "component": require('@/pages/project/taskManage/index.js').default
      },
      {
        "path": "/user/Login",
        "exact": true,
        "component": require('@/pages/user/Login/index.js').default
      },
      {
        "path": "/usercenter/business",
        "exact": true,
        "component": require('@/pages/usercenter/business/index.js').default
      },
      {
        "path": "/usercenter/departmentRpt",
        "exact": true,
        "component": require('@/pages/usercenter/departmentRpt/index.js').default
      },
      {
        "path": "/usercenter/project",
        "exact": true,
        "component": require('@/pages/usercenter/project/index.js').default
      },
      {
        "path": "/usercenter/rptAudit",
        "exact": true,
        "component": require('@/pages/usercenter/rptAudit/index.js').default
      },
      {
        "path": "/usercenter/StableDiffusion",
        "exact": true,
        "component": require('@/pages/usercenter/StableDiffusion/index.js').default
      },
      {
        "path": "/usercenter/workLog/cloumns",
        "exact": true,
        "component": require('@/pages/usercenter/workLog/cloumns.js').default
      },
      {
        "path": "/usercenter/workLog",
        "exact": true,
        "component": require('@/pages/usercenter/workLog/index.js').default
      },
      {
        "path": "/userList",
        "exact": true,
        "component": require('@/pages/userList/index.js').default,
        "title": "用户列表"
      },
      {
        "component": require('@/pages/404.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
