import React, { useRef, useEffect, useState, memo, useMemo } from 'react';
import { Checkbox, Table, Tooltip, Button } from 'kenshin';
import { connect } from 'umi';
import SearchForm from './components/SearchForm';
import PageLoading from '@/components/pageLoading';
import './index.less';
import { useCreation, useReactive, useUpdate } from 'ahooks';
import { SettingOutlined } from '@ant-design/icons';
import { sortConfig } from './util';
import { deepClone } from '../../_util/util';
import {
  getTableColums,
  getColumnsPerm,
  getHasbtnList,
  createCustomColumsChange,
} from './util/cloumns';
import {
  createChange,
  createLoadTable,
  createReset,
  createSortChange,
} from './util/request';
import { DraggableBodyRow } from './util/sortRow';

const TablePro = (props) => {
  const {
    // 无权限控制的列数据或自定义的列数据
    columns,
    data,
    actionRef: propsActionRef,
    // 表格数据的dva  type
    request, // 请求的actions
    effectLoading,
    loading,
    dispatch,
    params: propsParams = {}, // 默认开始请求携带的参数
    isRequest = true, // 是否进入页面就请求，可中断
    onChange,
    extra, // 扩展：搜索框与表格之间的扩展
    searchCloumns = [], // 搜索项
    onSubmit, // 搜索拦截
    /** 控制定制列 */
    columnsOptions = true,
    curColumns = false, // 采用的数据是当前数据，也就是不需要权限控制
    scrollX = false, // 是否可以左右滚动
    // 没有自定义的columns则使用后端返回的表格，默认开启
    autoColumns = true,
    onReset: propsOnReset, // 重置拦截
    searchData, // 搜索框默认值
    drag = false, // 开启拖拽
    onDrop,
    scrollY = true,
    ...resProps
  } = props;
  /** 表格的ref */
  const actionRef = useRef();
  /** 存放排序字段的属性 */
  const sortFieldsRef = useRef([]);
  let defaultPageSize = useMemo(() => 10, []);
  const [current, setCurrent] = useState(1); //当前页
  const [rowCount, setRowCount] = useState(defaultPageSize); //一页显示数据
  const searchRef = useRef({});
  const [tableColumns, setTableColumns] = useState([]);

  let getScrollY = useReactive({ y: 500 });
  useEffect(() => {
    if (searchData) {
      searchRef.current.setFieldsValue(searchData);
    }
    // 自动请求
    isRequest && loadTable({}, true);
    if (typeof scrollY == 'boolean') {
      setTimeout(() => {
        let table = document.querySelector('table');
        if (!table) return;
        let info = table.getBoundingClientRect();
        let y = window.innerHeight - info.top - info.height - 30 - 40;
        getScrollY.y = y < 500 ? 500 : y;
      }, 500);
    }
  }, []);

  useEffect(() => {
    let curMenuId = localStorage.getItem('curMenuId');
    let tableProCloumnsStr = localStorage.getItem('tableProCloumns');
    if (tableProCloumnsStr && JSON.parse(tableProCloumnsStr)[curMenuId]) {
      const tableProCloumns = JSON.parse(tableProCloumnsStr);
      let key = getColums
        ?.map((item) => [item, item.children])
        .flat(3) // 把children也输出
        .filter(Boolean)
        .filter(
          (item) =>
            !tableProCloumns[curMenuId]?.includes(item.key || item.dataIndex),
        )
        .map((item) => item.key || item.dataIndex);
      customColumsChange(key);
    } else {
      setTableColumns(getColums);
    }
  }, [columns]);

  /** 加载 */
  const loadTable = createLoadTable({
    request,
    setCurrent,
    sortFieldsRef,
    searchRef,
    propsParams,
    props,
    current,
    rowCount,
    onSubmit,
    dispatch,
  });

  // 使用connect后无法转发ref，使用actionRef代替ref，暴露出重新加载函数，用于关联数据查询
  actionRef.current = {
    reload: loadTable,
    value: {
      ...searchRef.current.value,
      sortFields: sortFieldsRef.current,
    },
    search: searchRef.current,
  };

  const handleChange = createChange({
    setRowCount,
    setCurrent,
    onChange,
    loadTable,
    sortFieldsRef,
  });

  if (typeof propsActionRef === 'object') {
    propsActionRef.current = actionRef.current;
  }
  useEffect(() => {
    if (typeof propsActionRef === 'function') {
      propsActionRef(actionRef.current);
    }
  }, [propsActionRef]);

  /** 重置拦截 */
  const onReset = createReset({
    setCurrent,
    setRowCount,
    sortFieldsRef,
    propsParams,
    props,
    propsOnReset,
    dispatch,
    request,
    defaultPageSize,
    page: { pageNo: current, pageSize: rowCount },
  });

  // 按钮权限列表
  const hasbtnList = getHasbtnList();

  /** 排序组件触发的change事件 */
  const sortChange = createSortChange({ sortFieldsRef, loadTable });

  /** 默认的cloumns处理 */
  const getColums = getTableColums({
    curColumns,
    columns,
    sortChange,
    sortFieldsRef,
    autoColumns,
  });

  /** 自动计算横向滚动条的宽度 */
  const getScrollX = useMemo(() => {
    if (tableColumns.every((column) => typeof column.width === 'number')) {
      return { x: tableColumns.reduce((pre, cur) => pre + cur.width, 0) };
    }
    return {};
  }, [tableColumns]);

  const extraConfig = () => {
    if (!extra) {
      return null;
    }
    // noKeys无权限值，作为无权限而显示的按钮
    let extraArr = [extra];
    if (Array.isArray(extra)) extraArr = extra;
    return extraArr.filter((item) =>
      ['noKeys', ...hasbtnList].includes(item.key),
    );
  };

  // 定制列change事件
  const customColumsChange = createCustomColumsChange({
    // getColums: deepClone(getColums),
    getColums: [...getColums],
    setTableColumns,
  });

  const extraTooltipRender = // 定制列弹窗内容
    (
      <Checkbox.Group
        className="table-extra-checkbox"
        options={getColums
          ?.map((item) => [item, item.children])
          .flat(2) // 把children也输出
          .filter(Boolean)
          .map((item) => ({
            label: item.title,
            value: item.key || item.dataIndex,
          }))}
        value={tableColumns
          ?.map((item) => [item, item.children])
          .flat(2) // 把children也输出
          .filter(Boolean)
          .map((item) => item.key || item.dataIndex)}
        onChange={customColumsChange}
      />
    );

  const customColums = // 定制列浮窗
    (
      <Tooltip
        title={extraTooltipRender}
        trigger="click"
        placement="leftBottom"
      >
        <Button type="primary">
          <SettingOutlined style={{ marginRight: 2 }} />
          定制列
        </Button>
      </Tooltip>
    );

  const dataSource =
    curColumns || getColumnsPerm(columns, autoColumns)?.length > 1
      ? data?.datas
      : [];

  const sortRowChange = (startId, endId, type) => {
    onDrop?.(startId, endId, type);
  };

  /** 存储拖拽中的数据 */
  const draggingRef = useRef({});
  const components = {
    body: {
      row: (props) => (
        <DraggableBodyRow
          onChange={sortRowChange}
          current={draggingRef.current}
          onDragStart={(ref) => Object.assign(draggingRef.current, ref)}
          {...props}
        />
      ),
    },
  };

  const scroll = {
    y: scrollY,
  };
  if (typeof scrollX == 'number') Object.assign(scroll, { y: scrollY });
  else Object.assign(scroll, getScrollX);
  if (scrollY) Object.assign(scroll, getScrollY);
  return (
    <>
      {searchCloumns.length > 0 && (
        <SearchForm
          columns={searchCloumns}
          onSubmit={loadTable}
          onReset={onReset}
          ref={searchRef}
        />
      )}
      <div
        className="table-pro-extra"
        style={extra ? {} : { flexDirection: 'row-reverse' }}
      >
        {extra && (
          // style={{ display: 'flex', justifyContent: 'end', marginRight: 8 }}
          <div className="extra-left">
            {typeof extra == 'function' ? extra(hasbtnList) : extraConfig()}
          </div>
        )}
        {columnsOptions && <div className="extra-tooltip">{customColums}</div>}
      </div>
      <Table
        // columns={columns}
        columns={tableColumns}
        loading={{
          loading: loading ?? effectLoading[request],
          render: <PageLoading />,
        }}
        scroll={scroll}
        onChange={handleChange}
        pagination={{
          total: data?.total || data?.totalCount,
          current,
          showTotal: (total, range) => (
            <div className="table-pro-total">
              共{total}条记录&emsp;每页显示
              {[10, 20, 30, 40, 50].map((item) => (
                <span
                  key={item}
                  onClick={() => handleChange({ current, pageSize: item })}
                  className={`size-change-span ${
                    (data?.pageSize || rowCount) == item ? 'select' : ''
                  }`}
                >
                  {item}
                </span>
              ))}
              条
            </div>
          ),
          showSizeChanger: true,
          pageSize: data?.pageSize || rowCount,
          size: 'small',
          showQuickJumper: true,
        }}
        dataSource={dataSource}
        components={drag ? components : null}
        {...resProps}
      />
    </>
  );
};

export default connect(({ loading }) => ({ effectLoading: loading.effects }))(
  TablePro,
);
