/**
 * https://timor.tech/api/holiday/year/${date}
 * 查询节假日：当前年份2022
 * holiday：是否是节假日，true代表是节假日，false代表补班
 * name：节假日中文名
 * wage：薪资倍数
 * date：节假日的日期
 */

export let holiday2022 = {
  '2022-01-01': {
    holiday: true,
    name: '元旦',
    wage: 3,
    date: '2022-01-01',
  },
  '2022-01-02': {
    holiday: true,
    name: '元旦',
    wage: 2,
    date: '2022-01-02',
  },
  '2022-01-03': {
    holiday: true,
    name: '元旦',
    wage: 2,
    date: '2022-01-03',
  },
  '2022-01-29': {
    holiday: true,
    name: '春节前补班',
    wage: 1,
    target: '春节',
    date: '2022-01-29',
  },
  '2022-01-30': {
    holiday: true,
    name: '春节前补班',
    wage: 1,
    target: '春节',
    date: '2022-01-30',
  },
  '2022-01-31': {
    holiday: true,
    name: '除夕',
    wage: 2,
    date: '2022-01-31',
  },
  '2022-02-01': {
    holiday: true,
    name: '初一',
    wage: 3,
    date: '2022-02-01',
  },
  '2022-02-02': {
    holiday: true,
    name: '初二',
    wage: 3,
    date: '2022-02-02',
  },
  '2022-02-03': {
    holiday: true,
    name: '初三',
    wage: 3,
    date: '2022-02-03',
  },
  '2022-02-04': {
    holiday: true,
    name: '初四',
    wage: 2,
    date: '2022-02-04',
  },
  '2022-02-05': {
    holiday: true,
    name: '初五',
    wage: 2,
    date: '2022-02-05',
  },
  '2022-02-06': {
    holiday: true,
    name: '初六',
    wage: 2,
    date: '2022-02-06',
  },
  '2022-02-07': {
    holiday: true,
    name: '初七',
    wage: 2,
    date: '2022-02-07',
  },
  '2022-04-02': {
    holiday: false,
    name: '清明节前补班',

    wage: 1,
    target: '清明节',
    date: '2022-04-02',
  },
  '2022-04-03': {
    holiday: true,
    name: '清明节',
    wage: 2,
    date: '2022-04-03',
  },
  '2022-04-04': {
    holiday: true,
    name: '清明节',
    wage: 2,
    date: '2022-04-04',
  },
  '2022-04-05': {
    holiday: true,
    name: '清明节',
    wage: 3,
    date: '2022-04-05',
  },
  '2022-04-24': {
    holiday: false,
    name: '劳动节前补班',

    wage: 1,
    target: '劳动节',
    date: '2022-04-24',
  },
  '2022-04-30': {
    holiday: true,
    name: '劳动节',
    wage: 2,
    date: '2022-04-30',
  },
  '2022-05-01': {
    holiday: true,
    name: '劳动节',
    wage: 3,
    date: '2022-05-01',
  },
  '2022-05-02': {
    holiday: true,
    name: '劳动节',
    wage: 2,
    date: '2022-05-02',
  },
  '2022-05-03': {
    holiday: true,
    name: '劳动节',
    wage: 2,
    date: '2022-05-03',
  },
  '2022-05-04': {
    holiday: true,
    name: '劳动节',
    wage: 2,
    date: '2022-05-04',
  },
  '2022-05-07': {
    holiday: false,
    name: '劳动节后补班',

    wage: 1,
    target: '劳动节',
    date: '2022-05-07',
  },
  '2022-06-03': {
    holiday: true,
    name: '端午节',
    wage: 3,
    date: '2022-06-03',
  },
  '2022-06-04': {
    holiday: true,
    name: '端午节',
    wage: 2,
    date: '2022-06-04',
  },
  '2022-06-05': {
    holiday: true,
    name: '端午节',
    wage: 2,
    date: '2022-06-05',
  },
  '2022-09-10': {
    holiday: true,
    name: '中秋节',
    wage: 3,
    date: '2022-09-10',
  },
  '2022-09-11': {
    holiday: true,
    name: '中秋节',
    wage: 2,
    date: '2022-09-11',
  },
  '2022-09-12': {
    holiday: true,
    name: '中秋节',
    wage: 2,
    date: '2022-09-12',
  },
  '2022-10-01': {
    holiday: true,
    name: '国庆节',
    wage: 3,
    date: '2022-10-01',
  },
  '2022-10-02': {
    holiday: true,
    name: '国庆节',
    wage: 3,
    date: '2022-10-02',
  },
  '2022-10-03': {
    holiday: true,
    name: '国庆节',
    wage: 3,
    date: '2022-10-03',
  },
  '2022-10-04': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2022-10-04',
  },
  '2022-10-05': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2022-10-05',
  },
  '2022-10-06': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2022-10-06',
  },
  '2022-10-07': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2022-10-07',
  },
  '2022-10-08': {
    holiday: false,

    wage: 1,
    name: '国庆节后补班',
    target: '国庆节',
    date: '2022-10-08',
  },
  '2022-10-09': {
    holiday: false,

    wage: 1,
    name: '国庆节后补班',
    target: '国庆节',
    date: '2022-10-09',
  },
};
export let holiday2023 = {
  '2023-01-01': {
    holiday: true,
    name: '元旦',
    wage: 3,
    date: '2023-01-01',
  },
  '2023-01-02': {
    holiday: true,
    name: '元旦',
    wage: 2,
    date: '2023-01-02',
  },
  '2023-01-19': {
    holiday: true,
    name: '调休',
    wage: 1,
    date: '2023-01-19',
  },
  '2023-01-20': {
    holiday: true,
    name: '调休',
    wage: 1,
    date: '2023-01-20',
  },
  '2023-01-21': {
    holiday: true,
    name: '除夕',
    wage: 3,
    date: '2023-01-21',
    rest: 18,
  },
  '2023-01-22': {
    holiday: true,
    name: '初一',
    wage: 3,
    date: '2023-01-22',
  },
  '2023-01-23': {
    holiday: true,
    name: '初二',
    wage: 3,
    date: '2023-01-23',
  },
  '2023-01-24': {
    holiday: true,
    name: '初三',
    wage: 3,
    date: '2023-01-24',
  },
  '2023-01-25': {
    holiday: true,
    name: '初四',
    wage: 2,
    date: '2023-01-25',
  },
  '2023-01-26': {
    holiday: true,
    name: '初五',
    wage: 2,
    date: '2023-01-26',
  },
  '2023-01-27': {
    holiday: true,
    name: '初六',
    wage: 2,
    date: '2023-01-27',
  },
  '2023-01-28': {
    holiday: true,
    name: '调休',
    wage: 1,
    date: '2023-01-28',
  },
  '2023-01-29': {
    holiday: false,
    name: '春节后补班',
    wage: 1,
    after: true,
    target: '春节',
    date: '2023-01-29',
  },
  '2023-04-05': {
    holiday: true,
    name: '清明节',
    wage: 3,
    date: '2023-04-05',
  },
  '2023-04-23': {
    holiday: false,
    name: '劳动节前补班',
    wage: 1,
    target: '劳动节',
    after: false,
    date: '2023-04-23',
  },
  '2023-04-29': {
    holiday: true,
    name: '劳动节',
    wage: 2,
    date: '2023-04-29',
  },
  '2023-04-30': {
    holiday: true,
    name: '劳动节',
    wage: 2,
    date: '2023-04-30',
  },
  '2023-05-01': {
    holiday: true,
    name: '劳动节',
    wage: 3,
    date: '2023-05-01',
  },
  '2023-05-02': {
    holiday: true,
    name: '劳动节',
    wage: 3,
    date: '2023-05-02',
  },
  '2023-05-03': {
    holiday: true,
    name: '劳动节',
    wage: 3,
    date: '2023-05-03',
  },
  '2023-05-06': {
    holiday: false,
    name: '劳动节后补班',
    after: true,
    wage: 1,
    target: '劳动节',
    date: '2023-05-06',
  },
  '2023-06-22': {
    holiday: true,
    name: '端午节',
    wage: 3,
    date: '2023-06-22',
  },
  '2023-06-23': {
    holiday: true,
    name: '端午节',
    wage: 3,
    date: '2023-06-23',
  },
  '2023-06-24': {
    holiday: true,
    name: '端午节',
    wage: 2,
    date: '2023-06-24',
  },
  '2023-06-25': {
    holiday: false,
    name: '端午节后补班',
    wage: 1,
    target: '端午节',
    after: true,
    date: '2023-06-25',
  },
  '2023-09-29': {
    holiday: true,
    name: '中秋节',
    wage: 3,
    date: '2023-09-29',
  },
  '2023-09-30': {
    holiday: true,
    name: '中秋节',
    wage: 3,
    date: '2023-09-30',
  },
  '2023-10-01': {
    holiday: true,
    name: '国庆节',
    wage: 3,
    date: '2023-10-01',
  },
  '2023-10-02': {
    holiday: true,
    name: '国庆节',
    wage: 3,
    date: '2023-10-02',
  },
  '2023-10-03': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2023-10-03',
  },
  '2023-10-04': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2023-10-04',
  },
  '2023-10-05': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2023-10-05',
  },
  '2023-10-06': {
    holiday: true,
    name: '国庆节',
    wage: 2,
    date: '2023-10-06',
  },
  '2023-10-07': {
    holiday: false,
    after: true,
    wage: 1,
    name: '国庆节后补班',
    target: '国庆节',
    date: '2023-10-07',
  },
  '2023-10-08': {
    holiday: false,
    after: true,
    wage: 1,
    name: '国庆节后补班',
    target: '国庆节',
    date: '2023-10-08',
  },
  '2023-12-31': {
    holiday: true,
    name: '元旦',
    wage: 2,
    date: '2023-12-31',
  },
};

// let res = Object.keys(holiday2023).map((item => {
//   return `${holiday2023[item].name}:${item}`
// }))
// console.log(res);

export let holiday = {
  ...holiday2022,
  ...holiday2023,
};
let getHoliday = (data) => {
  let date = moment(data);
  let hasHoliday = holiday[date.format('YYYY-MM-DD')];
  let week = date.get('day');
  if (!hasHoliday && (week == 6 || week == 0)) {
    return {
      holiday: true,
      wage: 1,
      name: '周末',
      date: date.format('YYYY-MM-DD'),
    };
  }
  return hasHoliday;
};

export default getHoliday;
