import SortTitle from '../components/SortTitle';
// 重写sort排序规则
export function sortConfig(cloumns, onChange, baseArr) {
  let newCloumns = cloumns.map((item) => {
    const { sort, ...data } = item;
    // cloumns排序字段，优先级为name>dataIndex>key
    let name = data.sortKey || data.dataIndex || data.key;
    let value = baseArr.find((item) => item.name == name)?.value ?? -1;
    if (sort) {
      data.title = (
        <SortTitle name={name} onChange={onChange} value={value}>
          {data.title}
        </SortTitle>
      );
    }
    return data;
  });
  return newCloumns;
}
