import { connect, history } from 'umi';
import { Button, message, Switch, Table, MessageBox, Row, Col } from 'kenshin';
import TablePro from '@/components/TablePro';
import { useEffect, useRef, useState } from 'react';
import {
  closeprocessapply,
  getcompleteprocess,
} from '@/_serveice/admin/process';
import RecordModal from './components/RecordModal';

import busiTypeKey from '../_util/busiTypeKey';
const {
  CUSTOMER,
  CONTRACT,
  PROJECT,
  TASK,
  ROLE,
  OSA,
  WEEK,
  OSAEDIT,
  WEEKEDIT,
} = busiTypeKey;

const scoreList = [
  'SCORE_ONE_NODE',
  'SCORE_TWO_NODE',
  'SCORE_THREE_NODE',
  'SUMMER_WEEK_PLAN_ONE_NODE',
  'SCORE_WEEK_PLAN_ONE_NODE',
  'HR_CONFIRM_WEEK_PLAN_NODE',
  'HR_PRE_CONFIRM_WEEK_PLAN_NODE',
];
const levelList = ['GRAD_NODE'];

const showTodoBtn = (row) => {
  let shouldShowKey = [OSA, WEEK, OSAEDIT, WEEKEDIT];
  if (shouldShowKey.includes(row.todoItemBusiType)) {
    return true;
  }
  return (
    row.processApplyBeginNodeId == row.processApplyNodeId &&
    row.commitMuIdStr == localStorage.getItem('muId')
  );
};

const showAuditBtn = (row) => {
  let shouldHideKey = [OSA, WEEK, OSAEDIT, WEEKEDIT];
  if (shouldHideKey.includes(row.todoItemBusiType)) {
    return false;
  }
  return row.processApplyBeginNodeId != row.processApplyNodeId;
};

const ProcessList = ({ process, dispatch }) => {
  const tableRef = useRef();
  const curData = useRef();
  const [recordVisible, setRecordVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'systemOrgani/getAllEmployee',
    });
    // getcompleteprocess({ processApplyId: 17 });
  }, []);

  const publicGo = (params, newData) => {
    dispatch({
      type: 'process/save',
      payload: {
        changeInfo: { data: newData, params },
      },
    });
    history.push({
      pathname: '/process/createProcess/changePage',
      query: {
        type: 'editProcess',
      },
    });
  };

  const osaWeekToDetail = (row) => {
    history.push({
      pathname: '/performance/assessment/OSA/OSADetail',
      query: {
        busiId: row.todoItemBusiId,
      },
    });
  };
  /** 绩效周计划的处理 */
  const osaOrWeekTodo = (row, path) => {
    if (
      [
        'HR_PRE_CONFIRM_WEEK_PLAN_NODE',
        'HR_CONFIRM_WEEK_PLAN_NODE',
        'HR_CONFIRM_NODE',
        'AUDIT_WEEK_PLAN_TWO_NODE',
        'AUDIT_WEEK_PLAN_THREE_NODE',
        'HR_PRE_CONFIRM_NODE',
      ].includes(row.processApplyNodeNumber)
    ) {
      osaWeekToDetail(row);
      return;
    }
    history.push({
      pathname: path,
      query: {
        busiId: row.todoItemBusiId,
      },
    });
  };

  /** 评分之前校验 */
  const checkHasChangeOSA = async (row) => {
    let hasChange = false;
    if (
      [
        'SCORE_ONE_NODE',
        'SUMMER_WEEK_PLAN_ONE_NODE',
        'SCORE_WEEK_PLAN_ONE_NODE',
      ].includes(row.processApplyNodeNumber)
    ) {
      hasChange = await dispatch({
        type: 'performance/haveosachangeprocess',
        payload: {
          osaId: '',
          todoBusiId: row.todoItemBusiId,
        },
      });
    }
    if (hasChange) {
      message.error('该绩效有正在进行的变更修改，无法执行当前操作！');
    }
    return hasChange;
  };

  /** 根据不同的业务类型进行不同的处理 */
  const typeOption = {
    [CUSTOMER](params, newData) {
      dispatch({
        type: 'usercenter/save',
        payload: {
          createProcessData: params,
        },
      });
      dispatch({
        type: 'customer/save',
        payload: {
          editData: newData,
        },
      });
      history.push({
        pathname: '/customer/newCustomer',
        query: {
          custId: newData.custId,
          type: 'editProcess',
        },
      });
    },
    [CONTRACT]: publicGo,
    [PROJECT](params, res) {
      publicGo(params, res.newData);
    },
    [TASK]: publicGo,
    [ROLE]: publicGo,
    async [OSA](_params, _newData, row) {
      let hasChange = await checkHasChangeOSA(row);
      if (hasChange) {
        return;
      }
      let path = '/performance/assessment/OSA/OSAsetting';
      // 评分
      if (scoreList.includes(row.processApplyNodeNumber)) {
        path = '/performance/assessment/scoreSetting';
      }
      if (
        [
          'GRADE_NODE',
          'HR_CONFIRM_NODE',
          'HR_PRE_CONFIRM_NODE',
          'AUDIT_WEEK_PLAN_THREE_NODE',
          'HR_PRE_CONFIRM_WEEK_PLAN_NODE',
          'HR_CONFIRM_WEEK_PLAN_NODE',
          'AUDIT_WEEK_PLAN_THREE_NODE',
        ].includes(row.processApplyNodeNumber)
      ) {
        path = '/performance/assessment/levelSetting';
      }
      osaOrWeekTodo(row, path);
    },
    async [WEEK](_params, _newData, row) {
      let hasChange = await checkHasChangeOSA(row);
      if (hasChange) {
        return;
      }
      let path = '/performance/assessment/weeklyPlan';
      if (scoreList.includes(row.processApplyNodeNumber)) {
        path = '/performance/assessment/scoreSetting';
      }
      osaOrWeekTodo(row, path);
    },
    [OSAEDIT](_params, _newData, row) {
      history.push({
        pathname: '/performance/selfosa/OSAEditAudit',
        query: {
          busiId: row.todoItemBusiId,
        },
      });
    },
    [WEEKEDIT](_params, _newData, row) {
      history.push({
        pathname: '/performance/selfosa/OSAEditAudit',
        query: {
          busiId: row.todoItemBusiId,
        },
      });
    },
    // [ROLE](params,res){
    //   console.log(params,res);
    //   // publicGo
    // },
  };

  /** 处理 */
  const handleTodo = (row) => {
    let params = {
      processApplyId: row.dataChange?.processApplyId,
      todoItemId: row.todoItemId,
      dataChangeId: row.dataChange?.dataChangeId,
      dataChangeBefore: row.dataChange?.dataChangeBefore,
      busiType: row.todoItemBusiType,
    };
    const newData = JSON.parse(row.dataChange?.dataChangeAfter ?? '{}');
    typeOption[row.todoItemBusiType]?.(params, newData, row);
  };

  /** 审核 */
  const handleAudit = (row) => {
    dispatch({
      type: 'usercenter/save',
      payload: {
        createProcessData: row,
      },
    });
    history.push({
      pathname: '/process/pendingBusiness/auditPage',
      query: {},
    });
  };

  const closeApply = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认关闭？关闭后无法恢复',
      onOk: () => {
        closeprocessapply(row).then((res) => {
          if (res.success) {
            tableRef.current.reload();
            message.success('关闭成功');
          }
        });
      },
    });
  };

  const columnsObj = {
    columns: [
      {
        dataIndex: 'todoItemName',
        title: '业务名称',
      },
      {
        dataIndex: 'todoItemBusiType',
        title: '业务类型',
        render: (val) => process.processTypeStrObj[val],
      },
      {
        dataIndex: 'gmtCreate',
        title: '提交时间',
        render: (time) => moment(time).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        dataIndex: 'commitMuname',
        title: '提交人',
      },
      {
        key: 'options',
        width: 100,
        title: '操作',
        render: (row) => {
          return (
            <Row>
              {showTodoBtn(row) && (
                <Col span={10}>
                  <Button type="text" onClick={() => handleTodo(row)}>
                    处理
                  </Button>
                </Col>
              )}
              {showAuditBtn(row) && (
                <Col span={10}>
                  <Button type="text" onClick={() => handleAudit(row)}>
                    审核
                  </Button>
                </Col>
              )}
              <Col span={10}>
                <Button
                  type="text"
                  onClick={() => {
                    curData.current = row;
                    setRecordVisible(true);
                  }}
                >
                  记录
                </Button>
              </Col>
              {row.commitMuIdStr == localStorage.getItem('muId') &&
                row.processApplyBeginNodeId == row.processApplyNodeId &&
                row.applyStatus == -1 && (
                  <Col span={10}>
                    <Button
                      type="text"
                      style={{ color: 'red' }}
                      onClick={() =>
                        closeApply({
                          processApplyList: [row.dataChange?.processApplyId],
                        })
                      }
                    >
                      关闭
                    </Button>
                  </Col>
                )}
            </Row>
          );
        },
      },
    ],
  };

  const searchCloumns = [
    {
      name: 'key',
      type: 'Input',
      props: {
        placeholder: '关键词',
      },
    },
    {
      name: 'busiType',
      type: 'Select',
      props: {
        placeholder: '业务类型',
        options: process.processTypeOptions,
      },
    },
  ];

  return (
    <div>
      <TablePro
        request="process/gettodoitempage"
        searchCloumns={searchCloumns}
        rowKey={'todoItemId'}
        data={process.busPendingList}
        actionRef={(ref) => (tableRef.current = ref)}
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        curColumns
        {...columnsObj}
      ></TablePro>
      <RecordModal
        visible={recordVisible}
        row={curData.current}
        processApplyId={curData.current?.dataChange?.processApplyId}
        onCancel={() => {
          setRecordVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ process }) => ({
  process,
}))(ProcessList);
