const createSearchItem = (arr) => {
  return arr.map(([label, value]) => ({ label, value }));
};

export const INFOKEY = {
  OSA: 1,
  WEEK: 2,
};
export const DATATYPEKEY = {
  ALL: 1,
  DEPT: 2,
  EMP: 3,
};

/** 季度、半年、一年选项卡 */
export const statTypeOptions = (type) => {
  const result = [
    { label: '季度', value: '1' },
    { label: '半年', value: '2' },
    { label: '一年', value: '3' },
  ];
  if (type == INFOKEY.WEEK) {
    result.unshift({ label: '周', value: '4' }, { label: '月', value: '5' });
  }
  return result;
};
/** 全部、部门、人员选项卡 */
export const dataTypeOptions = [
  { label: '全部', value: DATATYPEKEY.ALL },
  { label: '部门', value: DATATYPEKEY.DEPT },
  { label: '人员', value: DATATYPEKEY.EMP },
];
/** 日期范围 */
export const timeOptions = [
  { label: '半年', value: '6-month' },
  { label: '一年', value: '0-year' },
  { label: '两年', value: '1-year' },
  { label: '三年', value: '3-year' },
  { label: '五年', value: '5-year' },
];
/** 信息类别 */
export const infoOptions = createSearchItem([
  ['全部', -1],
  ['正常出勤', 1],
  ['旷工', 2],
  ['迟到', 3],
  ['早退', 4],
  ['请假', 5],
  ['加班', 6],
  ['外出', 7],
  ['已补卡', 8],
]);

/** 人员分组 */
export const groupByPeopleOptions = createSearchItem([
  ['公司', 1],
  ['部门', 2],
  ['员工', 3],
]);
/** 时间分组 */
export const groupByTimeOptions = createSearchItem([
  ['按月', 1],
  ['季度', 2],
  ['半年', 3],
  ['年', 4],
]);

/** 图表数据转换 */
export const chartTransform = (data) => {
  if (!Array.isArray(data)) return [];
  data.map((item) => {});
};

/** 固定存在的表头数据 */
let exitColumns = {
  姓名: {
    title: '姓名',
    width: 100,
    fixed: 'left',
    dataIndex: 'empName',
  },
  所在部门: {
    title: '所在部门',
    width: 300,
    fixed: 'left',
    dataIndex: 'deptName',
  },
};

/** 创建人数、平均分、升降率表头 */
export const createDeptColumns = (baseTime, infoChecked, isEmpName) => {
  let columns = [
    {
      title: '升降率',
      dataIndex: `${baseTime}upDownPercent`,
      render: (val) => val ?? '-',
    },
  ];
  if (isEmpName) {
    let arr = [
      {
        title: '评分',
        dataIndex: `${baseTime}avgScore`,
        render: (val) => val ?? '-',
      },
      {
        title: '评级',
        dataIndex: `${baseTime}grade`,
        render: (val) => val ?? '-',
      },
    ];
    if (infoChecked == INFOKEY.WEEK) arr.pop();
    columns.unshift(...arr);
  } else {
    columns.unshift(
      ...[
        {
          title: '人数',
          dataIndex: `${baseTime}count`,
        },
        {
          title: '平均分',
          dataIndex: `${baseTime}avgScore`,
        },
      ],
    );
  }

  return columns;
};

/** 根据服务端返回的表头进行渲染 */
export const createTableHeader = (baseList, { dataType, infoChecked }) => {
  if (!Array.isArray(baseList)) return [];
  let columns = baseList.map((item) => {
    if (exitColumns[item]) {
      return exitColumns[item];
    }
    return {
      title: item,
      key: item,
      width: 300,
      children: createDeptColumns(
        item,
        infoChecked,
        dataType == DATATYPEKEY.EMP,
      ),
    };
  });
  return columns;
};

/** 为每个item数据的key加上时间前缀 */
const addBaseStatKey = (item) => {
  let obj = [];
  Object.keys(item).forEach((key) => {
    obj[`${item.statTime}${key}`] = item[key];
  });
  return obj;
};

/** 将数组中的对象合并成一个对象 */
const mergeObj = (arr) => {
  if (!Array.isArray(arr) && arr.length == 0) return [];
  let obj = arr.reduce((pre, cur) => ({ ...pre, ...cur }), {});
  return obj;
};

/** 改造服务端返回的数据源 */
export const createDataSource = (dataSource) => {
  if (!Array.isArray(dataSource) && dataSource.length == 0) return [];
  let result = dataSource.map((item) => {
    if (item.statItemList.length > 0) {
      return mergeObj(
        item.statItemList.map((child) => ({
          ...addBaseStatKey(child),
          ...item,
          statItemList: undefined,
        })),
      );
    }
    return item;
  });
  return result;
};

const getChildrenNum = (arr) => {
  if (!Array.isArray(arr)) return 1;
  let sum = arr.reduce((sum, item) => {
    if (item.children) {
      return (sum += getChildrenNum(item.children));
    }
    return sum + 1;
  }, 0);
  return sum;
};

/** 根据服务端获取表头 */
export const getTableColumns = (list) => {
  if (!Array.isArray(list)) return [];
  let index = 0;
  const modifyData = (data) => {
    return data.map((item) => {
      const { name, childName, childList } = item;
      if (childList && Array.isArray(childList)) {
        return {
          title: childName || name,
          children: modifyData(childList),
        };
      } else {
        const dataIndex = index++;
        return {
          title: childName || name,
          dataIndex: dataIndex.toString(),
        };
      }
    });
  };
  return modifyData(list).map((item, i) => ({
    ...item,
    width: i == 0 ? 200 : getChildrenNum(item.children) * 100,
    // width: getChildrenNum(item.children) * 100,
  }));
};
