import {
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Radio,
  notification,
} from 'kenshin';
import React, { useCallback, useState, memo, PureComponent } from 'react';
import CollapseHoc from '@/components/CollapseHoc';
import { timeDiff, debounce } from '@/_util';
import { history } from 'umi';
import { getEmpCurTimesLotList } from '@/_serveice/usercenter';
import holiday from '@/_assets/json/holiday';

// 获取标准工时跟加班工时
export const getTimeAOverTime = (val) => {
  let [start, end] = val;
  let overtime = 0; // 加班占比
  const maxEnd = moment(
    moment(start).set('h', 18).startOf('h').format('YYYY-MM-DD HH:mm:ss'),
  ); // 当天18点后算加班时间
  const minStart = moment(
    moment(start).set('h', 9).startOf('h').format('YYYY-MM-DD HH:mm:ss'),
  ); // 当天9点前算加班时间
  if (end > maxEnd) {
    overtime = timeDiff([start > maxEnd ? start : maxEnd, end]);
    end = maxEnd;
  }
  if (start < minStart) {
    overtime += timeDiff([start, end < minStart ? end : minStart]);
    start = minStart;
  }
  let time = timeDiff([start, end]);
  return {
    time,
    overtime,
  };
};
@CollapseHoc
class ItemDetail extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      repTime: [],
    };
  }

  timeChange = (repTime) => {
    this.setState({
      repTime,
    });
  };

  onValuesChange = () => {
    let params = this.formRef.current.getFieldsValue?.();
    if (params.workTime) {
      params.workBeginTime = params.workTime[0]
        .startOf('m')
        .format('YYYY-MM-DD HH:mm:ss');
      params.workEndTime = params.workTime[1]
        .startOf('m')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    params.workTime = undefined;
    this.props.onChange?.({ ...params });
  };

  componentDidMount() {
    this.props.getRef?.(this.formRef.current);
    this.formRef.current.validateFields();
    this.onValuesChange();
  }

  render() {
    const workType = [
      {
        label: '正常工作日',
        value: 1,
      },
      {
        label: '有申请加班调休',
        value: 2,
      },
    ];
    const rptworkType = [
      { label: '正常上报', value: 1 },
      { label: '补报', value: 2 },
    ];

    const timeChange = (val) => {
      if (!val) {
        return;
      }
      const { time, overtime } = getTimeAOverTime(val);
      this.formRef.current.setFieldsValue({
        rptWorkHours: time,
        rptWorkOvertimeHours: overtime,
      });
      this.onValuesChange();
    };

    let reportDate = +history.location.query.reportDate || undefined;
    const disabledDate = (current) => {
      return (
        current < moment(reportDate).startOf('day') ||
        current > moment(reportDate).endOf('day')
      );
    };

    function range(start, end, step = 10) {
      const result = [];
      for (let i = start; i <= end; i += step) {
        result.push(i);
      }
      return result;
    }

    // 获取时间禁用的分钟，分钟禁用数量长度超过5则禁用小时（0,10,20,30,40,50）
    const disabledTimeObj = (h) => {
      // debugger
      let start = {},
        end = {};
      const getM = (item) => {
        let sh = moment(item.beginDate).get('h');
        let eh = moment(item.endDate).get('h');
        if (eh > sh) {
          return 60;
        }
        return moment(item.endDate).get('m');
      };
      this.props.disabledTime?.map((item) => {
        let sh = moment(item.beginDate).get('h');
        let eh = moment(item.endDate).get('h');
        // 对于11-14小时之前超出60分钟的需特殊处理，取11-14之间的12、13覆盖，即12|13 = [0,...,60]
        if (eh - sh > 1) {
          let arr = range(sh, eh, 1);
          arr.slice(1, arr.length - 1).map((item) => {
            start[item] = range(0, 60);
            end[item] = range(0, 60);
          });
        }
        start[+sh] ||= [];
        end[+sh] ||= [];
        let timeArr = range(moment(item.beginDate).get('m'), getM(item));
        start[+sh] = [...start[+sh], ...timeArr.slice(0, timeArr.length - 1)];
        end[+sh] = [...end[+sh], ...timeArr.slice(1, timeArr.length - 1)];
      });

      const disabledHours = (obj) => () =>
        Object.keys(obj)
          .map((item) => {
            if (obj[item].length >= 5) {
              return +item;
            }
          })
          .filter(Boolean);

      return {
        start: {
          disabledHours: disabledHours(start),
          disabledMinutes: () => start[h],
        },
        end: {
          disabledHours: disabledHours(end),
          disabledMinutes: () => end[h],
        },
      };
    };
    const disabledTime = (date, type) => {
      let curDate = moment(date);
      return disabledTimeObj(curDate.get('h'))[type];
    };

    const hasTimeSection = (SectionArr, times) => {
      if (times?.length == 0 || SectionArr?.length == 0) return false;
      for (let i = 0; i < SectionArr?.length; i++) {
        const item = [SectionArr[i].beginDate, SectionArr[i].endDate];
        if (
          (times[0] >= item[0] && times[0] < item[1]) ||
          (times[1] > item[0] && times[1] <= item[1])
        )
          return true; // 选中的时间与禁用的时间存在交集
      }
      return false;
    };

    const _that = this;
    const getWorkTime = () => {
      const maxDate =
        this.props.disabledTime?.length > 0
          ? Math.max(
              ...(this.props.disabledTime?.map((item) =>
                moment(item.endDate),
              ) ?? []),
            )
          : null;
      const maxHours = maxDate ? moment(maxDate).get('h') : 9;
      const endHours = maxHours >= 18 ? maxHours + 1 : 18;
      let result = [
        moment(reportDate).set('h', maxHours).startOf('h'),
        moment(reportDate).set('h', endHours).startOf('h'),
      ];
      return result;
    };
    return (
      <Form
        className="rptAudit-form"
        layout="vertical"
        ref={this.formRef}
        initialValues={{
          workHoursType: holiday(reportDate)?.holiday ? 2 : 1,
          rptType: reportDate ? 2 : 1,
          rptWorkHours: getTimeAOverTime(getWorkTime()).time,
          rptWorkOvertimeHours: getTimeAOverTime(getWorkTime()).overtime,
          workTime: getWorkTime(),
        }}
      >
        <Row gutter={16}>
          <Col span={7}>
            <Form.Item
              label="本次上报工时"
              name="workTime"
              rules={[
                {
                  required: true,
                  message: '请输入上报工时',
                },
                () => ({
                  async validator(_, value) {
                    console.log('value', value, _that.props.disabledTime);
                    if (value) {
                      if (hasTimeSection(_that.props.disabledTime, value)) {
                        return Promise.reject(new Error('上报的时间重叠'));
                      }
                      let time = moment(value[0]).format('YYYY-MM-DD');
                      let resList = await getEmpCurTimesLotList?.({
                        empId: localStorage.getItem('muId'),
                        reportDate: time,
                      }).then((res) => res.data);
                      let hasTimeDiff = false;
                      resList?.map((item) => {
                        if (
                          (+value[0] >= +item.beginDate &&
                            value[0] < +item.endDate) ||
                          (+value[1] > +item.beginDate &&
                            value[1] <= +item.endDate)
                        ) {
                          hasTimeDiff = true;
                        }
                      });
                      if (!hasTimeDiff) {
                        return Promise.resolve();
                      }
                      notification.error({
                        message: '存在上报时间段',
                        duration: 5,
                        description: resList
                          .map(
                            (item) =>
                              `${moment(item.beginDate).format(
                                'YYYY-MM-DD HH:mm:ss',
                              )}-${moment(item.endDate).format(
                                'YYYY-MM-DD HH:mm:ss',
                              )}`,
                          )
                          .join('\n'),
                      });
                      return Promise.reject(new Error('当前时间段已上报'));
                    }
                  },
                }),
              ]}
            >
              <DatePicker.RangePicker
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                onChange={timeChange}
                allowClear={false}
                showTime={{
                  format: 'HH:mm',
                  defaultValue: [moment('00', 'ss'), moment('00', 'ss')],
                  minuteStep: 10,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="标准上班工时" name="rptWorkHours">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="加班工时" name="rptWorkOvertimeHours">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="工作类型" name="workHoursType">
              <Radio.Group options={workType} onChange={this.onValuesChange} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="上报类型" name="rptType">
              <Radio.Group
                options={rptworkType}
                onChange={this.onValuesChange}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <Form.Item label="补充说明" name="rptRemark">
              <Input.TextArea onChange={this.onValuesChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <Form.Item label="功能详细描述:">
              {_that.props?.data?.taskRemark}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ItemDetail;
