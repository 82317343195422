import { useEffect, useState } from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Loading, Row } from 'kenshin';
import { history, connect } from 'umi';

const CardBox = ({ deptId, deptName, dispatch, performance, loadingEf }) => {
  useEffect(() => {
    dispatch({
      type: 'performance/getosatemplatelist',
    });
  }, []);

  const toCteateTemplate = (templateData) => {
    if (templateData) {
      window.data = templateData;
      dispatch({
        type: 'performance/save',
        payload: {
          templateEditor: templateData,
        },
      });
    }
    history.push({
      pathname: '/performance/template/createTemplate',
      // search: `?deptId=${deptId}`,
      query: {
        deptId,
        deptName,
        osaTemplateId: templateData?.osaTemplateId,
      },
    });
  };

  const delItem = (e, { osaTemplateId }) => {
    e.stopPropagation();
    dispatch({
      type: 'performance/deleteosatemplate',
      payload: { osaTemplateId },
    });
  };

  return (
    <Loading loading={loadingEf['performance/getosatemplatelist']}>
      <Row className="card-box" gutter="20">
        <Col span={5}>
          <div
            className="template-item empty"
            onClick={() => toCteateTemplate()}
          >
            <div className="template-item-icon">
              <PlusOutlined />
            </div>
            <div className="template-item-title">创建考核方案</div>
          </div>
        </Col>
        {performance.templateList?.map((item) => (
          <Col span={5} key={item.osaTemplateId}>
            <div className="del-icon" onClick={(e) => delItem(e, item)}>
              <DeleteOutlined />
            </div>
            <div
              className="template-item"
              onClick={(e) => toCteateTemplate(item)}
            >
              <div className="template-item-icon">
                <EditOutlined />
              </div>
              <div className="template-item-title">{item.osaTemplateName}</div>
            </div>
          </Col>
        ))}
      </Row>
    </Loading>
  );
};

export default connect(({ performance, loading }) => {
  return { performance, loadingEf: loading.effects };
})(CardBox);
