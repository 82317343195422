import { useEffect, useState, useMemo } from 'react';
import { Dropdown, MessageBox, Tree, message, Input } from 'kenshin';
import '@/components/TreeData/index.less';
import { connect } from 'umi';
import { useMemoizedFn } from 'ahooks';
import ModalFormPro from '@/components/ModalFormPro';
import ChooseDePartment from '@/components/ChooseManager/ChooseModal';
import {
  MoreOutlined,
  FolderTwoTone,
  FolderOpenTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { debounce, deepClone, filterTree } from '@/_util';
import { selectPinyinFilter } from '@/_util/componentsUtil';

const findTree = (fildTree, id = 3) => {
  for (let i = 0; i < fildTree.length; i++) {
    if (fildTree[i].deptId == id) {
      return {
        pre: fildTree[i - 1],
        cur: fildTree[i],
        next: fildTree[i + 1],
      };
    }
    if (fildTree[i].child) {
      let resData = findTree(fildTree[i].child, id);
      if (resData) {
        return resData;
      }
    }
  }
};

// 自定义图标
const getSwitcherIcon = (props) => {
  if (props.isLeaf) {
    return null;
  }
  const { expanded, loading } = props;
  let icon = <FolderTwoTone />;
  if (loading) {
    icon = <LoadingOutlined />;
  } else if (expanded) {
    icon = <FolderOpenTwoTone />;
  }
  return icon;
};

const TreeData = (props) => {
  const { dispatch, systemOrgani, onChange } = props;
  const { departmentList } = systemOrgani;

  const [treeData, setTreeData] = useState([]);

  const transformTreeData = useMemoizedFn((arr) => {
    return arr.map((item) => {
      let children = null;
      if (item.child) {
        children = transformTreeData(item.child);
      }
      return {
        children,
        title: item.deptName,
        key: item.deptId,
        switcherIcon: getSwitcherIcon,
        ...item,
      };
    });
  });

  useEffect(() => {
    setTreeData(transformTreeData([departmentList]));
  }, [departmentList]);

  // 编辑新增操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  useEffect(() => {
    dispatch({
      type: 'systemOrgani/getDepartmentPagelist',
      payload: {},
    });
  }, []);

  const TreeOptions = (e, row, type) => {
    e.stopPropagation();
    setEditModalData({ ...row, type });
    setEditModalVisible(true);
  };

  const editModalSave = (val) => {
    let hasEdit = false;
    let params = {
      ...val,
      deptPantId: editModalData.deptId,
    };
    if (editModalData.type != 'add') {
      hasEdit = true;
      params = {
        ...val,
        deptPantId: editModalData.deptPantId,
        deptId: editModalData.deptId,
      };
    }

    dispatch({
      type: 'systemOrgani/editDepartment',
      hasEdit,
      payload: params,
    }).then((res) => {
      if (res) {
        setEditModalData(null);
        setEditModalVisible(false);
      }
    });
  };

  const delTreeData = (e, row) => {
    e.stopPropagation();
    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        dispatch({
          type: 'systemOrgani/deleteDepartment',
          payload: row,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
          }
        });
      },
    });
  };

  // type:1上移，0下移
  const moveDepartment = (e, row, type) => {
    e.stopPropagation();
    let res = findTree([departmentList], row.deptId);
    if (type == 1 && !res?.pre) {
      message.error('无法上移，已是最上层');
      return;
    }
    if (type == 0 && !res.next) {
      message.error('无法下移，已是最下层');
      return;
    }
    let payload = {
      moveDeptId: res.cur.deptId,
      destDeptId: type == 1 ? res.pre.deptId : res.next.deptId,
    };
    dispatch({
      type: 'systemOrgani/moveDepartment',
      payload,
    }).then((res) => {
      if (res) {
        message.success('操作成功');
      }
    });
  };

  // 选择上级部门
  const [chooseVisible, setChooseVisible] = useState(false);
  const [chooseId, setChooseId] = useState('');

  const setDeptmanager = (e, row) => {
    e.stopPropagation();
    setChooseId(row.deptId);
    dispatch({
      type: 'systemOrgani/searchSysDeptUser',
      payload: {
        deptId: row.deptId,
      },
    }).then((res) => {
      setChooseVisible(true);
    });
  };

  const handleChooseSave = (managerIdList) => {
    dispatch({
      type: 'systemOrgani/setDeptmanager',
      payload: {
        deptId: chooseId,
        managerIdList,
      },
    }).then((res) => {
      if (res) {
        setChooseVisible(false);
        setChooseId('');
      }
    });
  };

  const optionsMenu = (row) => (
    <Dropdown.Menu>
      <Dropdown.Item>
        <div onClick={(e) => TreeOptions(e, row, 'add')}>添加部门</div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div onClick={(e) => TreeOptions(e, row, 'edit')}>修改部门</div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div onClick={(e) => setDeptmanager(e, { deptId: row.deptId })}>
          设置上级
        </div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div onClick={(e) => delTreeData(e, { deptId: row.deptId })}>删除</div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div onClick={(e) => moveDepartment(e, row, 1)}>上移</div>
      </Dropdown.Item>
      <Dropdown.Item>
        <div onClick={(e) => moveDepartment(e, row, 0)}>下移</div>
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const defaultTreeData = useMemo(
    () => deepClone(transformTreeData([systemOrgani.departmentList])),
    [systemOrgani.departmentList],
  );
  const searchInput = (e) => {
    const { value } = e.target;
    setTreeData(filterTree(deepClone(defaultTreeData), value));
  };

  const edtaFormItem = [
    {
      label: '名称',
      name: 'deptName',
      type: 'Input',
      required: '请输入名称',
      props: {
        placeholder: '请输入名称',
      },
    },
  ];

  const handleChange = (keys) => {
    keys.length > 0 && onChange?.(keys);
  };

  return (
    <div className="treeData">
      <Input placeholder="请输入关键词" onChange={debounce(searchInput, 500)} />

      <Tree
        defaultExpandAll
        defaultSelectedKeys={['1']}
        treeData={treeData}
        blockNode
        onSelect={handleChange}
        titleRender={(node) => {
          return (
            <div>
              {node.title}
              <div style={{ float: 'right' }}>
                <Dropdown menu={optionsMenu(node)}>
                  <MoreOutlined />
                </Dropdown>
              </div>
            </div>
          );
        }}
      />

      <ModalFormPro
        title={editModalData?.type == 'add' ? '新增' : '编辑'}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        isSetValue={editModalData?.type != 'add'}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />

      <ChooseDePartment
        visible={chooseVisible}
        onCancel={() => setChooseVisible(false)}
        onSave={handleChooseSave}
      />

      {/* <ChooseDePartment /> */}
    </div>
  );
};

export default connect(({ systemOrgani }) => ({ systemOrgani }))(TreeData);
