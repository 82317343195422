import { useEffect } from 'react';
import { connect, history } from 'umi';

import { Steps } from 'kenshin';
import busiTypeKey from '../../_util/busiTypeKey';
const { CUSTOMER, CONTRACT, PROJECT, TASK, ROLE } = busiTypeKey;
import Util from '../_unit';

const typeChnage = Object.fromEntries(
  Object.values(Util).map((item) => [item.key, item.getCard]),
);

const Detail = ({ process, dispatch }) => {
  // useEffect
  const { processApplyId } = history.location.query;

  const pageData = process.processApplyDetailList;
  const curIndex = pageData.processNodeList?.findIndex(
    (item) =>
      item.processApplyNodeId == pageData.curnProcessNode.processApplyNodeId,
  );

  useEffect(() => {
    dispatch({
      type: 'process/getprocessapplydetaildata',
      payload: {
        processApplyId,
      },
    });
    if (pageData?.applyBusiType == PROJECT) {
      dispatch({
        type: 'systemOrgani/getAllEmployee',
      });
    }
  }, []);

  const getDesc = (row) => {
    if (row.processApplyNodeHandlerList) {
      return row?.processApplyNodeHandlerList?.map((item) => (
        <div key={item.processNodeHandler}>
          处理人：{item.processNodeHandlerName}
        </div>
      ));
    }
    return null;
  };
  if (pageData?.processApplyDataChangeDTO?.dataChangeAfter) {
    console.log(
      JSON.parse(pageData?.processApplyDataChangeDTO?.dataChangeAfter),
    );
  }
  return (
    <div>
      <div style={{ padding: '40px 40px 80px' }}>
        <Steps current={curIndex}>
          {pageData?.processNodeList?.map((item, index) => (
            <Steps.Step
              key={item.processApplyNodeId}
              title={item.processNodeName}
              description={getDesc(item)}
            />
          ))}
        </Steps>
      </div>
      {pageData?.processApplyDataChangeDTO?.dataChangeAfter &&
        typeChnage[pageData?.applyBusiType]?.(
          JSON.parse(pageData.processApplyDataChangeDTO.dataChangeAfter),
        )}
    </div>
  );
};

export default connect(({ process }) => ({ process }))(Detail);
