import { downloadForEmp } from '@/_serveice/dataAnalyse';
const dataRule = (arr) => {
  const money = (type, step = 100) =>
    arr.map((item) => +(item[type] / step).toFixed(2));
  const xData = arr.map((item) => item.empName);
  return {
    xData,
    data: [
      {
        name: '工时成本（实际）',
        type: 'bar',
        color: '#40c898',
        data: money('actualCostAmount'),
      },
      {
        name: '工时成本（虚拟）',
        type: 'bar',
        color: '#8ed0fb',
        data: money('virtualCostAmount'),
      },
    ],
  };
};
const columns = [
  {
    dataIndex: 'empNumber',
    title: '员工工号',
  },
  {
    dataIndex: 'empName',
    title: '员工姓名',
  },
  {
    dataIndex: 'actualCostAmount',
    title: '实际工时成本',
    render: (val) => +(val / 100 || 0).toFixed(2),
  },
  {
    dataIndex: 'virtualCostAmount',
    title: '虚拟工时成本',
    render: (val) => +(val / 100 || 0).toFixed(2),
  },
];
const rowTableColums = [
  {
    dataIndex: 'contractNumber',
    title: '合同编号',
  },
  {
    dataIndex: 'projectName',
    title: '项目名称',
  },
  {
    dataIndex: 'actualCostAmount',
    title: '实际工时成本',
    render: (val) => +(val / 100 || 0).toFixed(2),
  },
  {
    dataIndex: 'virtualCostAmount',
    title: '虚拟工时成本',
    render: (val) => +(val / 100 || 0).toFixed(2),
  },
];

export default {
  dataRule,
  columns,
  rowTableColums,
  tableKey: 'empId',
  rowTableKey: 'projectId',
  downloadUrl: downloadForEmp,
  downloadName: '员工分析',
};
