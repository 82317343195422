import React, { useState, useEffect } from 'react';
import {
  MessageBox,
  Form,
  Radio,
  Input as KsInput,
  InputNumber,
  Button,
  message,
} from 'kenshin';
import Input from '@/_util/components/Input';
import { connect } from 'umi';
import IconChoose from './IconChoose';
import * as AntIcon from '@ant-design/icons';
import { useReactive } from 'ahooks';
const MenuItemEdit = ({
  data,
  visible,
  loading,
  onCancel,
  dispatch,
  ...props
}) => {
  const [iconChooseVisible, setIconChooseVisible] = useState(false);
  const [iconValue, setIconValue] = useState('');
  // const [permTypeValue, setPermTypeValue] = useState(1);

  const state = useReactive({
    permTypeValue: '1',
    permPlate: '1',
  });

  const FormProps5086669 = { labelCol: { span: 4 }, wrapperCol: { span: 14 } };

  // 菜单的类型
  const typeObj = {
    1: '菜单',
    2: '功能',
    3: '表头',
  };

  const keyNameObj = {
    1: '菜单路径',
    2: '功能key',
    3: '表头key',
  };

  const RadioGroupProps5096637 = {
    options: Object.keys(typeObj)
      .filter((_, i) => (state.permPlate == 2 ? i < 2 : true))
      .map((value) => ({
        value: +value,
        label: typeObj[value],
      })),
    onChange: (e) => {
      // setPermTypeValue(e.target.value);
      state.permTypeValue = e.target.value;
    },
  };

  const InputProps5192547 = { placeholder: '菜单名称' };

  const InputProps5624186 = { placeholder: '菜单路径' };

  const InputNumberProps5635291 = { stepType: 'inside', placeholder: '排序' };

  const InputProps6325843 = { placeholder: '接口路径' };

  const [formRef] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (data.permIcon && Object.keys(AntIcon).includes(data.permIcon)) {
        setIconValue(data.permIcon);
      }
      // data.permType && setPermTypeValue(+data.permType);
      data.permType && (state.permTypeValue = +data.permType);
      formRef.resetFields();
      if (data.permPlate) state.permPlate = data.permPlate;
      if (data.permType) state.permTypeValue = data.permType;
      formRef.setFieldsValue({ ...state, permType: 1, ...data });
      return () => {
        setIconValue('');
        setIconChooseVisible(false);
        state.permTypeValue = '1';
        state.permPlate = '1';
      };
    }
  }, [visible, data]);

  const handleOk = () => {
    formRef.validateFields().then((value, err) => {
      if (err) {
        return;
      }
      let sortChange = 0;
      if (data.permSort != value.permSort) {
        sortChange = 1;
      }
      let hasEdit = true;
      // 去除多余的值
      const { permStatus, hasChild, expireTime, empPermType, ...resData } =
        data;

      let params = {
        ...resData,
        ...value,
        permIcon: iconValue,
        sortChange,
      };
      if (!data.permName) {
        hasEdit = false;
        // 新增
        params = {
          permPantId: data.permId,
          permIcon: iconValue,
          ...value,
        };
      }

      params.permRemark = params.permRemark || params.permName;
      dispatch({
        type: 'system/editmenu',
        hasEdit,
        payload: params,
      }).then((res) => {
        if (res) {
          message.success('操作成功');
          onCancel();
        }
      });
    });
  };

  const renderIcon = () => {
    if (iconValue) {
      const Icon = React.createElement(AntIcon[iconValue], {});
      return Icon;
    }
    return null;
  };

  return (
    <MessageBox
      {...props}
      onCancel={onCancel}
      destroyOnClose
      visible={visible}
      title={data.permName ? '编辑' : '新增'}
      onOk={handleOk}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk}>
          确定
        </Button>,
      ]}
    >
      <Form form={formRef} {...FormProps5086669}>
        <Form.Item
          name="permPlate"
          label="平台"
          rules={[{ required: true, message: '请选择平台!' }]}
        >
          <Radio.Group
            options={[
              { label: 'PC端', value: '1' },
              { label: 'APP', value: '2' },
            ]}
            value={state.permPlate}
            onChange={(e) => (state.permPlate = e.target.value)}
          ></Radio.Group>
        </Form.Item>
        <Form.Item
          name="permType"
          label="类型"
          rules={[{ required: true, message: '请选择类型!' }]}
        >
          <Radio.Group
            disabled={!!data.permName}
            {...RadioGroupProps5096637}
          ></Radio.Group>
        </Form.Item>
        <Form.Item
          name="permName"
          label={`${state.permTypeValue == 1 ? '菜单' : '权限'}名称`}
          rules={[{ required: true, message: '请输入菜单名称!' }]}
        >
          <Input {...InputProps5192547}></Input>
        </Form.Item>
        {state.permTypeValue == 1 && (
          <Form.Item label="图标">
            <Button onClick={() => setIconChooseVisible(true)}>
              {renderIcon() ? renderIcon() : '选择图标'}
            </Button>
            {/* {iconValue} */}
            {iconValue && (
              <AntIcon.CloseOutlined
                style={{ marginLeft: 8 }}
                onClick={() => setIconValue('')}
              />
            )}
          </Form.Item>
        )}
        <Form.Item name="permKey" label={keyNameObj[state.permTypeValue]}>
          <Input disabled={data?.permType == 3} {...InputProps5624186}></Input>
        </Form.Item>
        <Form.Item
          name="permSort"
          label="排序"
          rules={[{ required: true, message: '请输入排序!' }]}
        >
          <InputNumber {...InputNumberProps5635291}></InputNumber>
        </Form.Item>
        <Form.Item
          name="permValue"
          label={state.permTypeValue == 1 ? '接口路径' : '权限value'}
        >
          <Input {...InputProps6325843}></Input>
        </Form.Item>
        <Form.Item name="permRemark" label="备注">
          <KsInput.TextArea
            placeholder={`默认是${
              state.permTypeValue == 1 ? '菜单' : '权限'
            }名称`}
          ></KsInput.TextArea>
        </Form.Item>
      </Form>

      <IconChoose
        visible={iconChooseVisible}
        onSave={(val) => {
          setIconValue(val);
          setIconChooseVisible(false);
        }}
        onCancel={() => {
          setIconChooseVisible(false);
        }}
      />
    </MessageBox>
  );
};

export default connect(({ system, loading }) => {
  return {
    system,
    loading: loading.effects,
  };
})(MenuItemEdit);
