import React from 'react';
import { connect } from 'umi';
import {} from 'kenshin';
import TablePro from '@/components/TablePro';

const Demo = (props) => {
  const A9_TableProProps3408393 = {
    columns: [
      {
        title: '客户名称',
        dataIndex: 'contract_cust_name',
      },
      {
        title: '合同编号',
        dataIndex: 'contract_number',
      },
      {
        dataIndex: 'contract_type',
        title: '合同类型',
      },
      {
        dataIndex: 'gmt_create',
        title: '创建时间',
      },
      {
        dataIndex: 'contract_amount',
        title: '合同金额',
      },
      {
        dataIndex: 'contract_status',
        title: '合同状态',
      },
      {
        dataIndex: 'options',
        title: '操作',
      },
    ],
    data: 'eval(props.undefined)',
  };

  return (
    <>
      <TablePro {...A9_TableProProps3408393}></TablePro>
    </>
  );
};

export default connect((props) => props)(Demo);
