import {
  Button,
  Card,
  Col,
  Input,
  Switch,
  Row,
  message,
  MessageBox,
} from 'kenshin';
import TablePro from '@/components/TablePro';
import { connect, history } from 'umi';
import { useEffect, useRef, useState } from 'react';
import { getempattendancestatuslist } from '../../../_serveice/attendance';

const defaultTime = () => [moment().startOf('date'), moment().endOf('date')];

const VacationManage = ({ attendance }) => {
  const [statTime, setStatTime] = useState(defaultTime());
  const [typeList, setTypeList] = useState([]);

  let statusStr = typeList?.reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
  }, {});

  useEffect(() => {
    getempattendancestatuslist().then((res) => {
      if (res.success) {
        let typeList = Object.keys(res.data).map((item) => ({
          label: res.data[item],
          value: +item,
        }));
        setTypeList(typeList);
      }
    });
  }, []);

  const columns = [
    {
      dataIndex: 'attendanceDate',
      title: '考勤日期',
      fixed: 'left',
      width: 200,
      render: (val, row) =>
        `${moment(val).format('YYYY-MM-DD')} ${row.attendanceWeek}`,
    },
    {
      dataIndex: 'empName',
      title: '员工姓名',
      fixed: 'left',
      width: 100,
    },
    {
      dataIndex: 'deptName',
      title: '部门名称',
      width: 200,
    },
    {
      dataIndex: 'attendanceCheckinTime',
      title: '考勤打卡时间',
      width: 150,
      render: (time, row) => {
        return (
          <div>
            {time ? moment(time).format('HH:mm:ss') : '-'}
            <div>
              {row.attendancePatchClockTime &&
                `(补)${moment(row.attendancePatchClockTime).format(
                  'HH:mm:ss',
                )}`}
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: 'attendanceException',
      title: '上班打卡异常信息',
      width: 200,
    },
    {
      dataIndex: 'attendanceLocationDetail',
      title: '打卡详细位置',
      width: 200,
    },
    {
      dataIndex: 'attendanceLocationTitle',
      title: '打卡位置标题',
      width: 200,
    },
    {
      dataIndex: 'attendanceRemark',
      title: '考勤打卡备注',
      width: 200,
    },
    {
      dataIndex: 'attendanceStatus',
      title: '打卡状态',
      width: 100,
      render: (val) => statusStr[val] ?? '-',
    },
    {
      dataIndex: 'gooutClockType',
      title: '数据来源',
      width: 200,
      render: (val) => {
        let typeStr = {
          1: '同步打卡数据',
          2: '外出忘打卡数据',
        };
        return typeStr[val];
      },
    },
  ];

  const disabledDate = (current) => {
    return (
      (current && current > moment().endOf('day')) ||
      current < moment().subtract(30, 'day').endOf('day')
    );
  };

  const searchCloumns = [
    {
      name: 'empName',
      type: 'Input',
      props: {
        placeholder: '请输入姓名',
      },
    },
    {
      name: 'statTime',
      type: 'DatePickerRangePicker',
      props: {
        defaultValue: statTime,
        allowClear: false,
        onChange: setStatTime,
        disabledDate,
      },
    },
    {
      name: 'attendanceBeginStatus',
      type: 'Select',
      props: {
        placeholder: '请选择状态',
        allowClear: true,
        mode: 'multiple',
        options: typeList,
      },
    },
    {
      name: 'deptIdList',
      type: 'DeptSelect',
      props: {
        defaultValue: ['1'],
      },
    },
  ];

  const onSubmit = (payload) => {
    if (!payload.deptIdList) {
      payload.deptIdList = ['1'];
    }
    if (payload.statTime) {
      payload.statBeginTime = payload.statTime[0].format('YYYY-MM-DD HH:mm:ss');
      payload.statEndTime = payload.statTime[1].format('YYYY-MM-DD HH:mm:ss');
      payload.statTime = undefined;
    }
  };

  const handleReset = (payload) => {
    setStatTime(defaultTime());
    payload.statTime = defaultTime();
    if (payload.statTime) {
      payload.statBeginTime = payload.statTime[0].format('YYYY-MM-DD HH:mm:ss');
      payload.statEndTime = payload.statTime[1].format('YYYY-MM-DD HH:mm:ss');
      payload.statTime = undefined;
    }
  };

  return (
    <div>
      <TablePro
        params={{
          deptIdList: ['1'],
          statBeginTime: statTime[0].format('YYYY-MM-DD HH:mm:ss'),
          statEndTime: statTime[1].format('YYYY-MM-DD HH:mm:ss'),
        }}
        scrollX
        scrollY
        columnsOptions={false}
        onReset={handleReset}
        request="attendance/getemployeeoutclockpage"
        searchCloumns={searchCloumns}
        rowKey="gooutClockId"
        // pagination={false}
        bordered
        onSubmit={onSubmit}
        data={attendance.outClockLick}
        columns={columns}
        curColumns
      ></TablePro>
    </div>
  );
};

export default connect(({ attendance, loading, systemOrgani }) => ({
  attendance,
  systemOrgani,
  loadingEf: loading.effects,
}))(VacationManage);
