import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  MessageBox,
  Button,
  Table,
  message,
  Avatar,
  Steps,
  Row,
  Col,
  Tabs,
  Tooltip,
  Tag,
  Loading,
  DatePicker,
} from 'kenshin';
import { history, useSelector } from 'umi';
import TablePro from '@/components/TablePro';
import ModalFormPro from '@/components/ModalFormPro';
import moment from 'moment';

const CloseDetailModal = ({
  dispatch,
  onCancel,
  visible,
  data = {},
  ...props
}) => {
  const proworkpkgclosepage = useSelector(
    ({ project }) => project.proworkpkgclosepage,
  );

  let tableRef = useRef();

  console.log(' data = ', data);

  let proServCatgIdList = data?.dbServCatgList?.map(
    (item) => item.proServCatgId,
  );
  let projectIdList = data?.dbServCatgList?.map((item) => item.projectId);

  proServCatgIdList = [data?.proServCatgId];
  projectIdList = [data?.projectId];

  console.log('proServCatgIdList = ', proServCatgIdList);
  console.log('projectIdList = ', projectIdList);

  // 弹窗操作
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editModalData, setEditModalData] = useState(null);

  const onDelete = (row) => {
    console.log('row = ', row);

    MessageBox.confirm({
      title: '确认操作',
      content: '确认删除？删除后无法恢复',
      onOk: () => {
        var param = {
          proServCatgClRcdIdList: [row?.proServCatgClRcd],
        };
        dispatch({
          type: 'project/deleteworkpkgcloselist',
          payload: param,
        }).then((res) => {
          if (res) {
            message.success('删除成功');
            tableRef.current.reload();
          }
        });
      },
    });
  };

  const onModify = (row) => {
    console.log('row = ', row);
    row.closeDate = moment(row.closeDate);
    setEditModalData(row);
    setEditModalVisible(true);
  };

  // 关闭工作包保存
  const editModalSave = (val) => {
    if (editModalData?.proServCatgClRcd) {
      let payload = {
        closeCount: val?.closeNum,
        closeDate: moment(val.closeDate).format('YYYY-MM-DD'),
        proServCatgClRcd: editModalData.proServCatgClRcd,
      };
      //   if (payload.closeDate) {
      //     payload.closeDate = moment(payload.closeDate).format('YYYY-MM-DD');
      //   }
      dispatch({
        type: 'project/updateworkpkgclose',
        payload,
      }).then((res) => {
        console.log('res = ', res);
        if (res) {
          if (res) {
            message.success('操作成功！');
            tableRef.current.reload();
            setEditModalData(null);
            setEditModalVisible(false);
          }
        }
      });
    }
  };

  const columns = [
    {
      dataIndex: 'closeDate',
      title: '关闭日期',
    },
    {
      dataIndex: 'closeCount',
      title: '关闭数量',
    },
    {
      dataIndex: '',
      title: '操作',
      render(row) {
        return (
          <>
            <Button type="text" onClick={() => onModify(row)}>
              修改
            </Button>
            <Button
              type="text"
              style={{ color: '#F6364D' }}
              onClick={() => onDelete(row)}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  // 弹窗formItem
  const edtaFormItem = [
    {
      label: '关闭日期',
      name: 'closeDate',
      type: 'DatePicker',
      // required: '请输入服务类目名称',
      props: {
        placeholder: '关闭日期',
      },
    },
    {
      label: '关闭数量',
      name: 'closeNum',
      type: 'InputNumber',
      required: '请输入关闭数量',
      // default: editModalData?.servCatgFinishNum,
      //   extra: `剩余关闭数量：${
      //     editModalData?.servCatgCount - editModalData?.servCatgFinishNum
      //   }`,
      props: {
        placeholder: '请输入关闭数量',
        defaultValue: editModalData?.closeCount,
        stepType: 'inside',
        max:
          editModalData?.servCatgCount -
          (editModalData?.servCatgFinishNum - editModalData?.closeCount),
        min: 0,
      },
    },
  ];

  return (
    <>
      <MessageBox
        title="工作包关闭修改"
        width={500}
        onCancel={onCancel}
        maskClosable={false}
        bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
        destroyOnClose
        visible={visible}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            关闭
          </Button>,
        ]}
        {...props}
      >
        <TablePro
          params={{
            page: { pageNo: 1, pageSize: 9999, sortFields: [] },
            proServCatgIdList: proServCatgIdList,
            projectIdList: projectIdList,
          }}
          request="project/getworkpkgclosepage"
          rowKey="projectId"
          columns={columns}
          curColumns
          columnsOptions={false}
          center
          bordered
          pagination={false}
          actionRef={(ref) => (tableRef.current = ref)}
          data={proworkpkgclosepage}
        />
      </MessageBox>

      <ModalFormPro
        title={`修改已关闭的工作包`}
        width={400}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        formData={editModalData}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalData(null);
          setEditModalVisible(false);
        }}
      />
    </>
  );
};

export default CloseDetailModal;
